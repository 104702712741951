export class Timer {
  start() {
    this.initialTime = new Date();
    return this.initialTime;
  }
  stop() {
    const now = new Date();
    return {
      initialTime: this.initialTime,
      finalTime: now,
      result: `${(now - this.initialTime) / 1000} segundos`,
    };
  }
}
