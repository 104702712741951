import React from "react";
import ButtonForAdd from "../Buttons/ButtonForAdd";
import Header from "../HeaderForAdd/Header";
import {
  Grid,
  withStyles,
  TextField,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SelectInput from "../Inputs/SelectInput";

const styles = (theme) => ({
  inputsList: {
    paddingBottom: "40px",
  },
  inputContainer: {
    backgroundColor: "#fff",
    position: "relative",
    top: "10px",
    paddingTop: "20px",
  },
  body: {
    backgroundColor: "#F5F5F5",
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 4,
  },
  button: {
    position: "relative",
    top: "30px",
  },
  title: {
    fontSize: "1rem",
    fontFamily: "Roboto, sans-serif",
    wordBreak: "break-all",
  },
});

/**
 * Componente do formulário de cadastrar bairro.
 */
class NeighborhoodForm extends React.Component {
  /**
   * @param {object} props.neighborhoodContext Contexto de bairros provido pelo componente pai.
   * @param {function} props.handleToggleNeighborhoodForm Função responsável pela alternância de exibição deste componente.
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {string} this.state.fuel Propriedade onde será armazenado a cidade.
   * @property {string} this.state.neighborhood Propriedade onde será armazenado o bairro.
   * @property {string} this.state.state Propriedade onde será armazenado o estado.
   */
  constructor(props) {
    super(props);
    this.state = {
      city: { value: null, error: false },
      estado: { value: null, error: false },
      neighborhood: { value: null, error: false },
    };
    this.checkFiels = this.checkFiels.bind(this);
    this.saveData = this.saveData.bind(this);
    this.cidadeMapper = this.cidadeMapper.bind(this);
    this.estadoMapper = this.estadoMapper.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    if (this.props.formData) {
      this.handleInputChange("estado", this.props.formData.state);
      this.handleInputChange("city", this.props.formData.city);
      // this.setState({
      //     city:{value:this.props.formData.city,error:false},
      //     state:{value:this.props.formData.state,error:false},
      // })
    }
  }

  /**Função responsável pela validação do formulário e setar os erros.
   * @function
   */
  checkFiels() {
    let check = true;

    if (
      this.state.estado.value === null ||
      this.state.estado.value.length < 1
    ) {
      this.setState({ state: { ...this.state.state, error: true } });
      check = false;
    } else {
      this.setState({ state: { ...this.state.state, error: false } });
    }

    if (
      this.state.neighborhood.value === null ||
      this.state.neighborhood.value.length < 1
    ) {
      this.setState({
        neighborhood: { ...this.state.neighborhood, error: true },
      });
      check = false;
    } else {
      this.setState({
        neighborhood: { ...this.state.neighborhood, error: false },
      });
    }

    if (this.state.city.value === null) {
      this.setState({ city: { ...this.state.city, error: true } });
      check = false;
    } else {
      this.setState({ city: { ...this.state.city, error: false } });
    }

    if (check) this.saveData();
  }

  /**Função responsável por salvar os dados no banco.
   * @function
   */
  async saveData() {
    try {
      const createdNeighborhoodId = {
        nome: this.state.neighborhood.value,
        cidade: this.state.city.value,
        estado: this.state.estado.value,
      };
      createdNeighborhoodId.DATABASE_ID =
        await this.props.neighborhoodContext.createNeighborhood(
          createdNeighborhoodId
        );
      this.props.handleToggleNeighborhoodForm(createdNeighborhoodId);
    } catch (error) {
      console.error(error);
    }
  }

  cidadeMapper(data, index) {
    return (
      <MenuItem
        key={index}
        value={data.cidade}
        selected={data.cidade == this.state.city.value}
      >
        {data.cidade}
      </MenuItem>
    );
  }

  estadoMapper(data, index) {
    return (
      <MenuItem
        key={index}
        value={data.nome}
        selected={data.nome == this.state.estado.value}
      >
        {data.nome}
      </MenuItem>
    );
  }

  handleInputChange(stateName, value) {
    let state = { ...this.state };

    state[stateName].value = value;

    if (stateName == "neighborhood") {
      state[stateName].value = value.target.value;
    }
    this.setState({ state });
  }

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.body}>
        <Header
          func={this.props.handleToggleNeighborhoodForm}
          icon={<CloseIcon />}
        >
          Criar bairro
        </Header>
        <Grid container justify="center" className={classes.inputContainer}>
          <Grid item xs={9} className={classes.inputsList}>
            <FormControl fullWidth style={{ paddingBottom: "10px" }}>
              <SelectInput
                label="Selecione o estado"
                value={this.state.estado.value}
                required
                onChange={(e) => this.handleInputChange("estado", e)}
                error={this.state.estado.error}
                menuItem={this.props.neighborhoodContext.states}
                mapper={this.estadoMapper}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
            <FormControl fullWidth style={{ paddingBottom: "10px" }}>
              <SelectInput
                label="Selecione a cidade"
                value={this.state.city.value}
                required
                onChange={(e) => this.handleInputChange("city", e)}
                error={this.state.city.error}
                menuItem={this.props.neighborhoodContext.cities}
                mapper={this.cidadeMapper}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>

            <TextField
              label="Nome do bairro"
              fullWidth
              error={this.state.neighborhood.error}
              helperText={
                this.state.neighborhood.error && "Informe o nome do bairro!"
              }
              required
              onChange={(e) => this.handleInputChange("neighborhood", e)}
            />
          </Grid>
        </Grid>
        <Grid container justify="center" className={classes.button}>
          <ButtonForAdd func={this.checkFiels}>Salvar Bairro</ButtonForAdd>
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(NeighborhoodForm);
