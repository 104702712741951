import DataAccess from "../DataAccessStrategy";
import { Cliente } from "../models";
import moment from "moment";
import * as R from "ramda";

const cliente = new Cliente();

export default class ClienteAgendamento extends DataAccess {
  constructor() {
    super("ClienteAgendamento", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      dataRecebimento: Date,
      tipo: String,
      ativo: Boolean,
      dataCriacao: Date,
      editado: Boolean,
      ClienteId: Number,
      ClienteVendaId: Number,
      ClienteRecebimentoId: Number,
      hashRotaAbertura: String,
      hash: String,
    });
    this.fetchSchedulingsHash = this.fetchSchedulingsHash.bind(this);
    this.getAllClientScheduling = this.getAllClientScheduling.bind(this);
    this.getSchedulingsId = this.getSchedulingsId.bind(this);
    this.getLastCreationDate = this.getLastCreationDate.bind(this);
  }

  async fetchSchedulingsHash(startDate, endDate, offset) {
    startDate = moment(startDate).startOf("day").toDate();
    endDate = moment(endDate).endOf("day").toDate();
    if (!startDate) startDate = moment(new Date()).startOf("day").toDate();
    if (!endDate) endDate = moment(new Date()).endOf("day").toDate();

    let schedulings = await this.model
      .where("dataRecebimento")
      .between(startDate, endDate, true, true)
      .and((a) => a.ativo)
      .offset(offset)
      .limit(10)
      .sortBy("dataRecebimento", (el) => {
        return el.map((el2) => {
          return {
            dataRecebimento: el2.dataRecebimento,
            ClienteId: el2.ClienteId,
            tipo: el2.tipo,
          };
        });
      });

    if (schedulings.length > 0) {
      const schedulingsFiltered = R.uniqWith((a, b) => {
        const dateA = a.dataRecebimento.toLocaleDateString();
        const dateB = b.dataRecebimento.toLocaleDateString();
        return dateA == dateB && a.ClienteId == b.ClienteId;
      }, schedulings);

      let initialDate = schedulingsFiltered[0].dataRecebimento.toString();
      let count = 0;
      let dates = schedulingsFiltered.map((el, index) => {
        el.dataRecebimento = moment(el.dataRecebimento).toDate();
        el.dataRecebimento = el.dataRecebimento.toString();
        if (moment(el.dataRecebimento).isAfter(initialDate, "day")) {
          initialDate = el.dataRecebimento;
          count++;
          return {
            date: moment(el.dataRecebimento).format("DD MMM"),
            index: index + count,
          };
        }
        return undefined;
      });

      let data = await Promise.all(
        schedulingsFiltered.map(async (el, index) => {
          let client = await cliente.getAll("id", el.ClienteId);
          return {
            hash: client[0].hash,
            dataRecebimento: el.dataRecebimento,
            cardHash: window.DATABASE.generateUniqId(),
          };
        })
      );

      if (offset == 0)
        data.unshift(
          moment(schedulingsFiltered[0].dataRecebimento.toString()).format(
            "DD MMM"
          )
        );

      dates.forEach((el) => {
        if (el != undefined) data.splice(el.index, 0, el.date);
      });

      return data;
    } else {
      return [];
    }
  }

  async countSchedulesByInterval(startDate, endDate) {
    const defaultState = {
      venda: 0,
      troca: 0,
      recebimento: 0,
    };
    startDate = moment(startDate).startOf("day").toDate();
    endDate = moment(endDate).endOf("day").toDate();
    if (!startDate) startDate = moment(new Date()).startOf("day").toDate();
    if (!endDate) endDate = moment(new Date()).endOf("day").toDate();

    const schedulings = await this.model
      .where("dataRecebimento")
      .between(startDate, endDate, true, true)
      .and((a) => a.ativo)
      .toArray();
    if (schedulings.length > 0) {
      const schedulingCount = schedulings.reduce(
        (memo, schedule) => {
          const type = schedule.tipo.toLowerCase();
          if (!memo[type]) memo[type] = 0;
          memo[type]++;
          return memo;
        },
        { ...defaultState }
      );

      return schedulingCount;
    } else {
      return defaultState;
    }
  }

  getClientByHash = async (hash, receivementDate = {}) => {
    let { startDate, endDate } = receivementDate;

    if (!startDate && !endDate) {
      startDate = moment("1/1/1970", "DD/MM/YYYY").startOf("day");
      endDate = moment("12/12/3000", "DD/MM/YYYY").endOf("day");
    } else {
      startDate = moment(startDate).startOf("day");
      endDate = moment(endDate).endOf("day");
    }

    const client = await cliente.findByHash(hash);

    const schedules = await this.model
      .where("ClienteId")
      .equals(client.id)
      .filter(
        (schedule) =>
          schedule.ativo &&
          moment(schedule.dataRecebimento).isBetween(startDate, endDate)
      )
      .toArray();

    const defaultState = {
      venda: 0,
      troca: 0,
      recebimento: 0,
    };

    const schedulingCount = schedules.reduce(
      (memo, schedule) => {
        const type = schedule.tipo.toLowerCase();
        if (!memo[type]) memo[type] = 0;
        memo[type]++;
        return memo;
      },
      { ...defaultState }
    );

    return {
      name: client.nome,
      address: client.logradouro,
      number: client.numero,
      balance: client.saldoAtual,
      hash: client.hash,
      type: {
        receive: schedulingCount.recebimento > 0 ? true : false,
        exchanges: schedulingCount.troca > 0 ? true : false,
        sell: schedulingCount.venda > 0 ? true : false,
      },
    };
  };

  async getAllClientScheduling(hash) {
    let clientId = await cliente.findByHash(hash);

    let result = await this.model
      .where("ClienteId")
      .equals(clientId.id)
      .filter((a) => a.ativo != false)
      .sortBy("dataRecebimento");
    return result;
  }

  getScheduleByInteval(minDate, maxDate) {
    minDate = moment(minDate, "DD/MM/YYYY").startOf("day").toDate();
    maxDate = moment(maxDate, "DD/MM/YYYY").endOf("day").toDate();

    return this.model
      .where("dataRecebimento")
      .between(minDate, maxDate, true, true)
      .and((a) => a.ativo != false)
      .toArray();
  }

  async getSchedulingsId(id) {
    return await this.model
      .where("ClienteId")
      .equals(id)
      .and((a) => a.ativo != false)
      .toArray();
  }

  async getLastCreationDate(clientHash) {
    let schedulings = await this.model
      .where("ClienteId")
      .equals(clientHash)
      .reverse()
      .sortBy("createdAt");
    return schedulings.createdAt;
  }
}
