import React from "react";
import Skeleton from "../Skeleton/CardSkeleton";
import Card from "../CardComponents/CardOfClient/CardsDailyList/ClientCard";
import { ClientContext } from "../../contexts/ClientContext";
import { buildClientData } from "../../service/daily";
import * as moment from "moment";

class ClientCardWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      client: null,
      cardState: "minicard",
    };
    this.setOnChangeView = this.setOnChangeView.bind(this);
  }
  setOnChangeView(state) {
    this.setState({
      cardState: state,
    });
  }

  componentDidMount() {
    this.context
      .getClientInfoToRenderCard(this.props.clientId)
      .then((info) => {
        const clientInfo = this.filterDataFromContext(info);
        this.setState({
          client: clientInfo,
          isLoading: false,
        });
      })
      .catch(console.error);
  }

  filterDataFromContext(client) {
    client.schedules = client.schedules.map((schedule) => ({
      hash: schedule.hash,
      id: schedule.id,
      type: schedule.tipo,
      createdAt: moment(schedule.createdAt),
    }));
    client.telefones = client.telephones;
    client.BairroId = client?.neighborhood?.id;
    client.bairro = client?.neighborhood?.nome;
    return buildClientData(client, 0);
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Skeleton />
        ) : (
          <Card
            Client={this.state.client}
            cardState={this.state.cardState}
            onChangeView={this.setOnChangeView}
            aboutScheduylingText="Todos os agendamentos:"
            repasseBtn={false}
            pularBtn={false}
            veacoBtn={false}
            onSync={this.props.onSync}
          />
        )}
      </>
    );
  }
}

ClientCardWrapper.contextType = ClientContext;
export default ClientCardWrapper;
