import Dexie from "dexie";
/**
 * @description Classe que imita o comportamento do localStorage default de window mas que utiliza
 * IndexedDB por baixo dos panos. Nesta classe foi implementado o padrão de projeto sigleton
 * logo é possivel obter um referencia para o "localStorage" diretamente da referencia desta classe
 * vide o exemplo
 *
 * @example
 * import LocalStorage from "./LocalStorage"
 *
 * const localStorage = LocalStorage.instance
 *
 * let data = await localStorage.getItem(SOME_CONST_KEY)
 */
export default class LocalStorage {
  static #instance;
  static get instance() {
    if (!LocalStorage.#instance) {
      LocalStorage.#instance = new LocalStorage();
    }
    return LocalStorage.#instance;
  }

  static _overrideDeleteMethod(table, db) {
    const instanceDelete = table.delete.bind(table);
    table.delete = function () {
      const mode = "rw";
      const args = [...arguments];
      return db.transaction(mode, table, () => instanceDelete(...args));
    };
  }

  static #subscribes = [];
  static subscribe(keys, func) {
    if (!keys) return () => console.log("No Keys passed");
    if (!Array.isArray(keys)) return () => console.log("No Keys as arrays");
    const id = window.crypto.randomUUID();
    LocalStorage.#subscribes.push({
      id,
      keys,
      handler: func,
    });
    return () => {
      LocalStorage.#subscribes = LocalStorage.#subscribes.filter(
        (item) => item.id !== id
      );
    };
  }

  constructor() {
    if (LocalStorage.#instance) {
      throw new Error(
        "Alredy have a instance of this class (LocalStorage), please use the static attr instance"
      );
    }
    const DATABASE = new Dexie("localStorage");

    DATABASE.version(1.0).stores({
      storage: "++DATABASE_ID, &name",
    });

    DATABASE.storage.defineClass({
      name: String,
      data: String,
    });
    this.localStorage = DATABASE.storage;
    window.LocalStorage = this;
    LocalStorage._overrideDeleteMethod(DATABASE.storage, DATABASE);
  }

  /**
   * @description Setter, Caso esta função seja invocada com uma key que já exista,
   * o valor será sobreescrito
   * @param {String} itemName Chave que deseja settar
   * @param {*} data Dados que deseja armazenar na chave
   */
  async setItem(itemName, data) {
    const item = await this.getItem(itemName);
    if (item) {
      await this.removeItem(itemName);
    }
    await this.localStorage.put(
      {
        name: itemName,
        data: JSON.stringify(data),
      },
      itemName
    );

    const subs = LocalStorage.#subscribes.filter((item) =>
      item.keys.includes(itemName)
    );
    const proms = subs.map((handle) => {
      return handle.handler();
    });
    await Promise.all(proms);
  }

  /**
   * @description Getter
   * @param {String} itemName Chave identificadora que deseja recuperar
   */
  async getItem(itemName) {
    let item = await this.localStorage
      .where("name")
      .equalsIgnoreCase(itemName)
      .toArray();
    if (!item[0]) return;
    if (item[0].data) {
      try {
        item = JSON.parse(item[0].data);
        return item;
      } catch (err) {
        console.log("error getItem", err);
        return;
      }
    }
    return;
  }

  /**
   * @description Remove um item especifico do armazenamento
   * @param {String} itemName Chave que deseja remover
   */
  async removeItem(itemName) {
    const itemToRemove = await this.localStorage
      .where("name")
      .equalsIgnoreCase(itemName)
      .toArray();
    await Promise.all(
      itemToRemove.map(async (item) => {
        return await this.localStorage.delete(item["DATABASE_ID"]);
      })
    );
  }

  /**
   * @description Limpa todo o armazenamento
   */
  async clear() {
    await this.localStorage.clear();
  }
}
