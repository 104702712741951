import ServiceNotification from "../serviceNotification";

/**
 * @description Singleton que provê atraves do atributo instance uma referencia a um serviço de subscribe com a finalidade principal
 * de serem notificadas quando um erro acontece. Isso se dá devido ao fato de que todos os errors implementados em src/errorsDefinition
 * notificam a unica instancia desta classe e esta por sua vez notifica todos os event listeners adicionados em tempo de execução.
 * É importante salientar que para que não exista memory lake é necessario chamar a função unsubscribe no momento em que o listener
 * não foir mais necessario como por exemplo no metodo de ciclo de vida componentWillUnmount do React.Component.
 * Para mais detalhes vide ServiceNotification.
 *
 *@example
 *
 *import ErrosNotificationCenter from './src/service/errosNotificationCenter'
 *
 *class YourClass {
 *
 * constructor(){ this.unsubscribe = ErrosNotificationCenter.instance.subscribe(this.observable)}
 *
 * observable(){// codigo que deve ser executado quando houver o evento que é esperado}
 *
 * onObjectDead(){this.unsubscribe()}
 *
 *}
 */
export default class ErrosNotificationCenter extends ServiceNotification {
  static #instance;
  static get instance() {
    if (!ErrosNotificationCenter.#instance) {
      ErrosNotificationCenter.#instance = new ErrosNotificationCenter();
    }
    return ErrosNotificationCenter.#instance;
  }
}
