import React, { createContext } from "react";
import { Bairro } from "../repository/models";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import { AbstractContext } from "./AbstractContext";
import LocalStorage from "../utils/localStorage";

const bairro = new Bairro();

const SortContext = createContext({
  sort: [],
});
const OBSERVED_MODELS = ["Bairro"];

class SortContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.updateSelf = this.updateSelf.bind(this);
    this.checkRouteOpen = this.checkRouteOpen.bind(this);
    this.getAllNeighborhoods = this.getAllNeighborhoods.bind(this);
    this.getNeighborhoodsById = this.getNeighborhoodsById.bind(this);
    this.observable = this.observable.bind(this);
    this.handleSaveClientsOrder = this.handleSaveClientsOrder.bind(this);

    this.state = {
      isLoading: false,
      neighborhoods: [],
      getNeighborhoodsById: this.getNeighborhoodsById,
      checkRouteOpen: this.checkRouteOpen,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.updateSelf();
  }

  async updateSelf() {}

  async checkRouteOpen() {
    let rotaAbertura = await LocalStorage.instance.getItem("route-opening");
    return rotaAbertura;
  }

  async getAllNeighborhoods() {
    return (await bairro.getAll()).filter((bairro) => bairro.ativo);
  }

  async getNeighborhoodsById(arrayIds) {
    this.setState({ isLoading: true });
    let neighborhoodIDs = await bairro.getAll(
      "id",
      arrayIds.map((id) => parseInt(id))
    );
    this.setState({ neighborhoods: neighborhoodIDs, isLoading: false });
  }

  observable() {
    bairro
      .getAll()
      .then((bairros) => this.setState({ neighborhoods: bairros }))
      .catch(console.error);
  }

  async handleSaveClientsOrder() {}

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <SortContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </SortContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { SortContext, SortContextProvider };
