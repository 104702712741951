import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

const styles = (theme) => ({
  buttonYes: {
    backgroundColor: "#f44336",
    color: "#fff",
    height: "100%",
    border: 0,
    outline: 0,
    textAlign: "center",
    width: `100%`,
  },
  buttonNot: {
    backgroundColor: "#e0e0e0",
    height: "100%",
    border: 0,
    outline: 0,
    width: `100%`,
    fontWeight: `bold`,
  },
  text: {
    fontSize: ".85rem",
    lineHeight: 1.3,
    padding: `0 6px`,
  },
  wrapper: {
    position: "absolute",
    height: "100%",
    right: 0,
    overflow: "hidden",
    zIndex: 2,
  },
  darkBackground: {
    height: "100%",
    width: "100%",
    position: "absolute",
    background: "#03040787",
    zIndex: 0,
  },
  innerContainer: {
    backgroundColor: "#fff",
    zIndex: 1,
  },
  hidden: {
    display: "none",
  },
});
/**
 * Componente que abre com uma transição horizontal, onde é utilizado na exclusão de algo importante
 * O componente utiliza este meio de CSS acima.
 */
class ConfirmDelete extends React.Component {
  /**
   * @param {function} props.confirmDelete Função acionada no 'click' ao confirmar a exclusão do item
   * @param {function} props.cancelDelete Função acionada no 'click' ao cancelar a exclusão do item
   * @param {object} props.classes Classes do css vindas do withStyles
   * @param {boolean} props.transitionStatus Estado onde decide se ocorre ou não a transição
   */
  constructor(props) {
    super(props);
  }
  render() {
    let { classes, transitionStatus } = this.props;

    /**
     * Classe onde ocorre a trainsição
     */
    let classConfirmDelete = clsx({
      [classes.darkBackground]: true,
      [classes.confirmDelete_on]: transitionStatus,
      [classes.confirmDelete_off]: !transitionStatus,
    });
    let classInnerContainer = clsx({
      [classes.innerContainer]: true,
      [classes.innerContainer_on]: transitionStatus,
      [classes.innerContainer_off]: !transitionStatus,
    });

    return (
      <Grid
        item
        container
        xs={12}
        className={classes.wrapper}
        alignItems="stretch"
        justify="flex-end"
      >
        <Grid
          item
          container
          xs={10}
          className={classInnerContainer}
          wrap="nowrap"
          alignItems="stretch"
          justify="space-between"
        >
          <Grid item container xs={7} alignItems="center">
            <Typography className={classes.text} data-testid="confirm-text">
              {this.props.confirmText
                ? this.props.confirmText
                : "Desejo mesmo deletar esse produto?"}
            </Typography>
          </Grid>
          <Grid item container xs={5} alignItems="stretch" wrap="nowrap">
            <Grid item xs={4}>
              <button
                onClick={this.props.confirmDelete}
                className={classes.buttonYes}
                data-testid="confirm-delete"
              >
                SIM
              </button>
            </Grid>
            <Grid item xs={8}>
              <button
                onClick={this.props.cancelDelete}
                className={classes.buttonNot}
                data-testid="cancel-delete"
              >
                NÃO
              </button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          className={classConfirmDelete}
          onClick={this.props.cancelDelete}
        ></Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(ConfirmDelete);
