import { Grid, withStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React from "react";
import NeighborhoodForm from "../../components/Forms/NeighborhoodForm";
import Header from "../../components/HeaderForAdd/Header";
import AddPictures from "../../components/Pictures/AddPictures";
import Signature from "../../components/Signature/Signature";
import UserProfileForm from "../../components/User/UserProfile";
import { NeighborhoodContext } from "../../contexts/NeighborhoodContext";
import { ViewProfileContext } from "../../contexts/ViewProfileContext";
import { resetOrientation } from "../../service/Pictures/Pictures";

const styles = (theme) => ({
  hidden: {
    display: "none",
  },
  show: {
    height: "500px",
    width: "100%",
  },
});

/**
 * Tela de ver perfil do Usuário.
 */
class ViewProfile extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {boolean} this.state.signature Boleano que definirá a alternância de exibição entre o componente de assinatura e de formulário
   * @property {boolean} this.state.redirect  Boleano que indicará um possivel redirecionamento de página
   * @property {string} this.state.sigImage  Estado que armazerará a imagem da assinatura que estará em BASE64
   * @property {object} this.state.image  Estado que armazerará a imagem de perfil do usuário que estará em BASE64
   * @property {number} this.state.newClient  Estado que armazerará os dados do novo cliente para assim então salvar no banco
   */
  constructor(props) {
    super(props);
    this.state = {
      signatureIsOpen: false,
      sigImage: null,
      image: null,
      employee: null,
      neighborhoodFormIsOpen: false,
      isLoading: false,
    };
    this.saveEmployee = this.saveEmployee.bind(this);
    this.getEmployeeData = this.getEmployeeData.bind(this);
    this.getTrimmedDataURL = this.getTrimmedDataURL.bind(this);
    this.clickDeleteImage = this.clickDeleteImage.bind(this);
    this.handleToggleNeighborhoodForm =
      this.handleToggleNeighborhoodForm.bind(this);
    this.onUploadImage = this.onUploadImage.bind(this);
    this.handleToggleSignature = this.handleToggleSignature.bind(this);
    this.handleSignatureImage = this.handleSignatureImage.bind(this);
  }

  /**Função salvará os dados no banco.
   * @function
   * @param {object} data Respectivos dados a serem salvos no banco
   */
  async saveEmployee(data) {
    data.assinatura = this.state.sigImage;
    data.foto = this.state.image;
    let employee = Object.assign(this.state.employee, data);
    await this.context.updateEmployee(employee);
  }

  async getEmployeeData(hash) {
    let employeeData = await this.context.getEmployeeData();

    if (employeeData) {
      this.setState({ employee: employeeData }, () => {
        this.handleSignatureImage(this.state.employee.assinatura);
        this.setState({ image: this.state.employee.foto });
      });
    }
  }

  componentDidMount() {
    this.getEmployeeData();
    this.context.updateSelf();
  }

  /**Função irá  armazenar a imagem da assinatura do cliente.
   * @function
   * @param {string} image Imagem em base64 da assinatura
   */
  getTrimmedDataURL(image) {
    this.setState({ sigImage: image }, () => this.state.sigImage);
  }

  /**Função irá  apagar a imagem de perfil do usuário.
   * @function
   */
  clickDeleteImage() {
    this.setState({ image: null });
  }

  /**Função irá  alternar a exibição do formulário de criação de bairro.
   * @function
   */
  handleToggleNeighborhoodForm() {
    this.setState({
      neighborhoodFormIsOpen: !this.state.neighborhoodFormIsOpen,
    });
  }

  /**Função irá armazenar a imagem de perfil do cliente.
   * @function
   * @param {string} imageBASE64 Imagem em base64
   */
  onUploadImage(imageBASE64) {
    resetOrientation(imageBASE64).then((image) => {
      this.setState({ image });
    });
  }

  /**Função irá alterar o valor do estado responsável por exibir o componente de assinatuea.
   * @function
   */
  handleToggleSignature() {
    this.setState({ signatureIsOpen: !this.state.signatureIsOpen });
  }

  /**Função irá  armazenar a imagem da assinatura do cliente.
   * @function
   */
  handleSignatureImage(image) {
    this.setState({ sigImage: image }, () => this.state.sigImage);
  }

  render() {
    let { classes } = this.props;
    let classPrincipalPage = clsx({
      [classes.hidden]:
        this.state.signatureIsOpen || this.state.neighborhoodFormIsOpen,
      [classes.show]:
        !this.state.signatureIsOpen && !this.state.neighborhoodFormIsOpen,
    });

    return (
      <>
        <NeighborhoodContext.Consumer>
          {(neighborhood) => (
            <>
              {this.state.signatureIsOpen && (
                <Signature
                  getTrimmedDataURL={this.getTrimmedDataURL}
                  handleToggleSignature={this.handleToggleSignature}
                />
              )}

              <Grid container className={classPrincipalPage}>
                <Header link="/" icon={<ExpandMoreIcon fontSize="large" />}>
                  Meu perfil
                </Header>
                <AddPictures
                  onUploadImage={this.onUploadImage}
                  clickDeleteImage={this.clickDeleteImage}
                  image={this.state.image}
                />

                <UserProfileForm
                  handleToggleSignature={this.handleToggleSignature}
                  signatureImage={this.state.sigImage}
                  neighborhoodContext={neighborhood}
                  saveClient={this.saveEmployee}
                  image={this.state.image}
                  handleToggleNeighborhoodForm={
                    this.handleToggleNeighborhoodForm
                  }
                  context={this.context}
                  clientData={this.state.employee}
                />
              </Grid>

              {this.state.neighborhoodFormIsOpen && (
                <NeighborhoodForm
                  handleToggleNeighborhoodForm={
                    this.handleToggleNeighborhoodForm
                  }
                  neighborhoodContext={neighborhood}
                />
              )}
            </>
          )}
        </NeighborhoodContext.Consumer>
      </>
    );
  }
}
ViewProfile.contextType = ViewProfileContext;
export default withStyles(styles)(ViewProfile);
