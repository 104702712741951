import React from "react";
import { Dialog } from "@material-ui/core";
import MovementReport from "../Report/MovementReport";
import Slide from "@material-ui/core/Slide";
import { PrinterContext } from "../../contexts/PrinterContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

class PrinterDialog extends React.Component {
  render() {
    return (
      <>
        <Dialog
          fullScreen
          maxWidth="100%"
          open={this.context.open}
          onClose={this.context.handleClose}
          TransitionComponent={Transition}
        >
          <MovementReport
            title={this.context.title}
            headerMainText={this.context.headerMainText}
            onClickCloseIcon={this.context.onClickCloseIcon}
            onClickBackToInit={this.context.onClickBackToInit}
            disableSellerButton={this.context.disableSellerButton}
            disableClientButton={this.context.disableClientButton}
            onClickSellerButton={this.context.onClickSellerButton}
            onClickClientButton={this.context.onClickClientButton}
            headerSecondaryText={this.context.headerSecondaryText}
          >
              <div style={{
                backgroundColor: "rgb(245, 245, 245)",
                width: "90%",
                border: "none",
                margin: "0px",
                padding: "15px",
                display: "grid",
                position: "absolute",
                resize: "block",
                height: "calc(100vh - 250px)",
                overflowY: "scroll",
              }}>
              <textarea
              style={{
                border: "none",
                transform: "scaleX(0.9)",
                height: "inherit",
                }
              }
              value={this.context.content}
              disabled
            />

            <div style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}>
            {this.context.imagemLogo && (
              <img
              src={this.context.imagemLogo}
              alt="Print invoice"
              style={{
                width: "56%",

              }}
            ></img>
            )}
            </div>
            </div>
          </MovementReport>
        </Dialog>
      </>
    );
  }
}
PrinterDialog.contextType = PrinterContext;
export default PrinterDialog;
