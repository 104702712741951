import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";
import { If } from "../If";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export function NotificationSnackbar({
  message,
  severity,
  progression,
  open,
  handleClose,
  showProgressLoader,
}) {
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          severity={severity}
          action={
            <>
              <If condition={showProgressLoader}>
                <CircularProgressWithLabel
                  value={progression}
                  size={44}
                  thickness={3.1}
                  color="inherit"
                />
              </If>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={(evt) => handleClose(evt, "userClick")}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
