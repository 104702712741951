import React from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import GreenButton from "../../components/Buttons/GreenButton";
import PrintIcon from "@material-ui/icons/Print";
const styles = (theme) => ({
  button_next: {
    paddingLeft: "5px",
  },
});

class PrintOutAndNext extends React.Component {
  /**
   * @param {function} props.onClickGoNext Função responsável por ir para a cena seguinte.
   * @param {string} props.this.props.title  Texto do segundo botão.
   * @param {object} props.classes Classes do css vindas do withStyles.
   * @param {icon} props.icon Icone utilizado no segundo botão.
   */
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Grid container className={classes.buttons} justify="center">
          <Grid item xs={11}>
            <Grid container>
              <Grid item xs={6}>
                <GreenButton
                  onClickButton={this.props.onClickPrint}
                  backgroundColor="#DCDCDC"
                >
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    data-testid="print-button"
                  >
                    <PrintIcon />
                    &nbsp;
                    <Typography data-testid="print-text">IMPRIMIR</Typography>
                  </Grid>
                </GreenButton>
              </Grid>
              <Grid item xs={6} className={classes.button_next}>
                <GreenButton onClickButton={this.props.onClickGoNext}>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    data-testid="next-button"
                  >
                    {this.props.title ? (
                      <Typography data-testid="next-title">
                        {this.props.title}
                      </Typography>
                    ) : (
                      <Typography data-testid="next-text">PRÓXIMO</Typography>
                    )}
                    &nbsp;
                    {this.props.icon ? this.props.icon : null}
                  </Grid>
                </GreenButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withStyles(styles)(PrintOutAndNext);
