import React from "react";
import DocumentForm from "../../components/DocumentsList/DocumentForm";
import history from "../../router/History";

import { DocumentContext } from "../../contexts/DocumentContext";

class WindowForEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentData: { nome: "" },
      isLoading: false,
    };
    this.getDocumentData = this.getDocumentData.bind(this);
    this.saveData = this.saveData.bind(this);
  }

  getDocumentData() {
    let document = this.context.document.find(
      (a) => a.hash == this.props.match.params.hash
    );
    if (document) this.setState({ documentData: document });
  }
  componentDidMount() {
    this.getDocumentData();
  }

  componentDidUpdate() {
    if (this.state.isLoading != this.context.isLoading) {
      this.getDocumentData();
      this.setState({ isLoading: this.context.isLoading });
    }
  }
  /**Função salva os dados no banco .
   * @function
   * @param {string} data Dado vindo do filho
   */
  async saveData(data) {
    try {
      let document = { ...this.state.documentData };
      document.nome = data;
      await this.context.update(document);
      history.push("/documents");
    } catch (error) {}
  }

  render() {
    return (
      <DocumentForm
        saveData={this.saveData}
        documentData={this.state.documentData}
        titleHeader="Editar Documento"
      />
    );
  }
}
WindowForEdit.contextType = DocumentContext;
export default WindowForEdit;
