import AbstractError from "./AbstractError";
import { AppStorageError } from "../repository/errorsModels";

export default class StorageError extends AbstractError {
  constructor(helperData = {}) {
    helperData = Object.assign(
      {
        message: "Houve um problema ao armazenar os dados no seu dispositivo",
        title: "Oops, houve um problema",
        type: "error",
      },
      helperData
    );

    super(helperData, new AppStorageError());
  }
}
