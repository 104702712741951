import ServiceNotification from "../serviceNotification";
/**
 * @description O funcionamento desda classe é identico À ErrorsNotificationsCenter, vide a descrição
 */
export default class ClientNotificationCenter extends ServiceNotification {
  static #instance;
  static get instance() {
    if (!ClientNotificationCenter.#instance) {
      ClientNotificationCenter.#instance = new ClientNotificationCenter();
    }
    return ClientNotificationCenter.#instance;
  }
}
