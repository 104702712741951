/* eslint-disable eqeqeq */
import models from "../../../repository/models";
import AbstractService, { fetchPollyfill } from "../../AbstractService";
import moment from "moment";
import { uploadClientDocumentPicture } from "../../file";
import RouteService from "../../route";
import { getUser, checkEmployeeToken } from "../../authentication";
import apiErrorResponse from "../../../errorDefinition/ApiErrorResponse";
import { buildClientDataChanged, uploadClientePicture } from "../../client";
import ClientError from "../../../errorDefinition/ClientError.js";
export default class SyncSend extends AbstractService {
  constructor() {
    super();
    this.routeService = new RouteService();
  }

  /**
   * @description Envio de despesas.
   * Models envolvidas:
   * ManutencaoVeiculo
   * DespesaVeiculo
   * DespesaCombustivel
   * Veiculo
   * DespesaDeleted
   * AdiantamentoFuncionario
   * DespesaRota
   */
  async sendExpenses() {
    const user = await getUser();
    const filterFunc = (a) => !!a.shouldSync;

    const manutencaoVeiculo = await models.ManutencaoVeiculo.filter(filterFunc);
    const despesaVeiculo = await models.DespesaVeiculo.filter(filterFunc);
    const despesaRota = await models.DespesaRota.filter(filterFunc);
    const despesaCombustivel = await models.DespesaCombustivelVeiculo.filter(
      filterFunc
    );
    const adiantamentoFuncionario = await models.AdiantamentoFuncionario.filter(
      filterFunc
    );
    const despesasDeleteds = await models.DespesaDeleted.filter(filterFunc);

    let despesas = {
      manutencaoVeiculo: manutencaoVeiculo,
      despesaVeiculo: despesaVeiculo,
      despesaRota: despesaRota,
      despesaCombustivel: despesaCombustivel,
      adiantamentoFuncionario: adiantamentoFuncionario,
      despesasDeleteds: despesasDeleteds,
    };
    const URL = `${user.service}/api/despesas?token=${user.sessao.token}`;
    const response = await this.httpPost(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        despesas: despesas,
      }),
    });

    const data = await this.resolveFetchResponse(response);

    for (let adiantamento of data.data.adiantamento) {
      let despesasExistente = await models.AdiantamentoFuncionario.getAll(
        "id",
        adiantamento.oldId
      );

      if (despesasExistente && despesasExistente[0]) {
        despesasExistente[0].id = adiantamento.newId;
        despesasExistente[0].shouldSync = false;
        await models.AdiantamentoFuncionario.put(despesasExistente[0], false);
      }
    }

    for (let desp of data.data.despesaRota) {
      let despesasExistente = await models.DespesaRota.getAll("id", desp.oldId);
      if (despesasExistente && despesasExistente[0]) {
        despesasExistente[0].id = desp.newId;
        despesasExistente[0].shouldSync = false;
        await models.DespesaRota.put(despesasExistente[0], false);
      }
    }

    for (let desp of data.data.despesaVeiculo) {
      let despesasExistente = await models.DespesaVeiculo.getAll(
        "id",
        desp.oldId
      );

      if (despesasExistente && despesasExistente[0]) {
        despesasExistente[0].id = desp.newId;
        despesasExistente[0].shouldSync = false;
        await models.DespesaVeiculo.put(despesasExistente[0], false);
      }
    }

    for (let desp of data.data.manutencao) {
      let despesasExistente = await models.ManutencaoVeiculo.getAll(
        "id",
        desp.oldId
      );

      if (despesasExistente && despesasExistente[0]) {
        despesasExistente[0].id = desp.newId;
        despesasExistente[0].shouldSync = false;
        await models.ManutencaoVeiculo.put(despesasExistente[0], false);
      }
    }

    for (let desp of data.data.combustivel) {
      let despesasExistente = await models.DespesaCombustivelVeiculo.getAll(
        "id",
        desp.oldId
      );

      if (despesasExistente && despesasExistente[0]) {
        despesasExistente[0].id = desp.newId;
        despesasExistente[0].shouldSync = false;
        await models.DespesaCombustivelVeiculo.put(despesasExistente[0], false);
      }
    }
  }

  async sendNeighborhood() {
    let user = await getUser();
    let route = await this.routeService.mainRoute();

    const bairrosUpload = await models.Bairro.filter((neighborhood) => {
      return neighborhood.id <= 0 && neighborhood.shouldSync;
    });

    for (let neighborhood of bairrosUpload) {
      const bairroNegativeId = neighborhood.id;

      const URL = `${user.service}/api/bairro?token=${user.sessao.token}`;
      const response = await this.httpPost(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bairro: neighborhood,
          rotaId: route.id,
        }),
      });
      delete neighborhood.id;

      const data = await this.resolveFetchResponse(response);

      let newNeighborhood = data.data.bairro;

      let clientsNeighborhood = await models.ClienteBairro.filter(
        (a) => a.BairroId == bairroNegativeId
      );

      let bairroOnDBExists = await models.Bairro.getAll(
        "id",
        newNeighborhood.id
      );

      if (!bairroOnDBExists[0]) {
        neighborhood.id = newNeighborhood.id;
        neighborhood.shouldSync = false;
        await models.Bairro.put(neighborhood, false);
      } else {
        await models.Bairro.delete(neighborhood["DATABASE_ID"]);
      }

      for (let clientNeighborhood of clientsNeighborhood) {
        clientNeighborhood.BairroId = newNeighborhood.id;
        clientNeighborhood.shouldSync = true;
        await models.ClienteBairro.put(clientNeighborhood, false);

        let client = await models.Cliente.getAll(
          "id",
          clientNeighborhood.ClienteId
        );

        if (client && client[0]) {
          client = client[0];
          client.bairroIdCache = newNeighborhood.id;
          client.bairro = newNeighborhood.id;
          client.shouldSync = true;
          await models.Cliente.put(client, false);
        }
      }
    }
  }

  async sendBasketsProducts(callback) {
    let newBaskets = [];
    let editedBaskets = [];

    let newProducts = [];
    let editedProducts = [];

    let newBasketItems = [];
    let editedBasketItems = [];

    const basketSend = await models.Cesta.filter((cesta) => {
      if (cesta.id < 0) {
        delete cesta.DATABASE_ID;
        delete cesta.shouldSync;
        newBaskets.push({ ...cesta });
        return true;
      }
      return false;
    });
    const editedBasketSend = await models.Cesta.filter((cesta) => {
      if (cesta.id >= 0 && cesta.shouldSync) {
        editedBaskets.push({ ...cesta });
        return true;
      }
      return false;
    });
    const productsSend = await models.Produto.filter((prod) => {
      if (prod.id < 0) {
        delete prod.DATABASE_ID;
        delete prod.shouldSync;
        newProducts.push({ ...prod });
        return true;
      }
      return false;
    });
    const editedProductsSend = await models.Produto.filter((prod) => {
      if (prod.id >= 0 && prod.shouldSync) {
        delete prod.shouldSync;
        editedProducts.push({ ...prod });
        return true;
      }
      return false;
    });
    const basketItemsSend = await models.CestaItens.filter((cestaItem) => {
      if (cestaItem.id < 0) {
        delete cestaItem.DATABASE_ID;
        delete cestaItem.shouldSync;
        newBasketItems.push({ ...cestaItem });
        return true;
      }
      return false;
    });
    const editedBasketItemsSend = await models.CestaItens.filter(
      (cestaItem) => {
        if (cestaItem.id >= 0 && cestaItem.shouldSync) {
          delete cestaItem.shouldSync;
          editedBasketItems.push({ ...cestaItem });
          return true;
        }
        return false;
      }
    );

    let user = await getUser();
    const URL = `${user.service}/api/cestasprodutos?token=${user.sessao.token}`;
    const response = await this.httpPost(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newBaskets: basketSend,
        editedBaskets: editedBasketSend,
        newProducts: productsSend,
        editedProducts: editedProductsSend,
        newBasketItems: basketItemsSend,
        editedBasketItems: editedBasketItemsSend,
      }),
    });

    const data = await this.resolveFetchResponse(response);
    if (!data) return;

    const apiCreatedBaskets = data.baskets ? data.baskets : [];
    const apiCreatedProducts = data.products ? data.products : [];
    const apiCreatedBasketItems = data.basketItems ? data.basketItems : [];

    return {
      newBaskets,
      editedBaskets,
      apiCreatedBaskets,
      newProducts,
      editedProducts,
      apiCreatedProducts,
      newBasketItems,
      editedBasketItems,
      apiCreatedBasketItems,
    };
  }

  async sendProduct() {
    let newProducts = [];
    let editedProducts = [];

    const productsSend = await models.Produto.filter((prod) => {
      if (prod.id < 0 && prod.shouldSync) {
        delete prod.DATABASE_ID;
        newProducts.push({ ...prod });
        delete prod.id;
        return true;
      }
      return false;
    });
    const editedProductsSend = await models.Produto.filter((prod) => {
      if (prod.id >= 0 && prod.shouldSync) {
        editedProducts.push({ ...prod });
        return true;
      }
      return false;
    });

    let user = await getUser();
    const URL = `${user.service}/api/produtos?token=${user.sessao.token}`;
    const response = await this.httpPost(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newProducts: productsSend,
        editedProducts: editedProductsSend,
      }),
    });

    const data = await this.resolveFetchResponse(response);

    const apiCreatedProducts = data.products || [];
    return { data, apiCreatedProducts, newProducts, editedProducts };
  }

  async sendBasketItems(callback) {
    let newBasketItems = [];
    let editedBasketItems = [];

    const basketItemsSend = await models.CestaItens.filter((cesta) => {
      if (cesta.id < 0) {
        delete cesta.DATABASE_ID;
        newBasketItems.push({ ...cesta });
        delete cesta.id;
        return true;
      }
      return false;
    });
    const editedBasketItemsSend = await models.CestaItens.filter((cesta) => {
      if (cesta.id >= 0 && cesta.shouldSync) {
        editedBasketItems.push({ ...cesta });
        return true;
      }
      return false;
    });

    let user = await getUser();
    const URL = `${user.service}/api/cestaItens?token=${user.sessao.token}`;
    const response = await this.httpPost(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newBasketItems: basketItemsSend,
        editedBasketItems: editedBasketItemsSend,
      }),
    });

    const data = await this.resolveFetchResponse(response);
    const apiCreatedBasketItems = data.basketItems || [];

    return { data, apiCreatedBasketItems, newBasketItems, editedBasketItems };
  }

  async sendFuel(callback) {
    let newFuels = [];

    const fuelSend = await models.Combustivel.filter((fuel) => {
      if (fuel.shouldSync) {
        delete fuel.DATABASE_ID;
        newFuels.push({ ...fuel });
        delete fuel.id;
        return true;
      }
      return false;
    });

    let user = await getUser();
    const URL = `${user.service}/api/combustivel?token=${user.sessao.token}`;
    const response = await this.httpPost(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newFuels: fuelSend,
        editedFuels: [],
      }),
    });

    const data = await this.resolveFetchResponse(response);
    const apiCreatedFuels = data.combustiveis || [];

    return { data, apiCreatedFuels, newFuels };
  }

  async sendVehicle(callback) {
    let newVehicles = [];
    let apiCreatedVehicles = [];

    const vehicleSend = await models.Veiculo.filter((vehicle) => {
      if (vehicle.shouldSync) {
        delete vehicle.DATABASE_ID;
        newVehicles.push({ ...vehicle });
        delete vehicle.id;
        return true;
      }
      return false;
    });

    let user = await getUser();
    const URL = `${user.service}/api/veiculos?token=${user.sessao.token}`;
    const response = await this.httpPost(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newVehicles: vehicleSend,
        editedVehicles: [],
      }),
    });
    const data = await this.resolveFetchResponse(response);

    apiCreatedVehicles = data.veiculos || [];

    return { data, apiCreatedVehicles, newVehicles };
  }

  /**
   * @description Envio de clientes ao servidor, Recupera todos os clientes cujo
   * alguma das models relacionadas ou os próprios dados tenham sofrido
   * alguma alteração (Utilizao attr shouldSync)
   */
  async sendClients(callback, clientsArray) {
    const user = await getUser();
    const clients = !!clientsArray
      ? clientsArray
      : await models.Cliente.filter(
        (client) => client.shouldSync || parseInt(client.id) <= 0
      );

    const mainRoute = await this.routeService.mainRoute();
    const team = await this.routeService.team();

    if (mainRoute && mainRoute.id) {
      let index = 0;
      for (const client of clients) {
        const oldId = client.id;

        const newId = await this.postClientToServer(
          {
            client,
            service: user.service,
            token: user.sessao.token,
            mainRoute,
            team,
          },
          callback,
          {
            current: index,
            total: clients.length,
          }
        );

        client.shouldSync = false;
        client.id = newId;
        await models.Cliente.put(client, false);
        await models.ClienteBairro.updateClienteBairroModels(oldId, newId);

        index++;
      }
    }
  }
  /**
   *
   * @description Envia um cliente ao servidor
   * @param {*} Options Define qual o cliente, a url de serviço, token de acesso, dados da rota principal
   * @param {Function} callback Invocada para demonstrar o progresso do envio
   * @param {*} status Contem informações sobre o status da sincronização atual
   */
  async postClientToServer(
    { client, service, token, mainRoute },
    callback,
    status
  ) {
    callback &&
      callback("client-send", {
        nome: client.nome,
        hash: client.hash,
        DATABASE_ID: client.DATABASE_ID,
        status,
        type: 0,
      });
    client = await buildClientDataChanged(client);
    try {
      const oldId = client.oldId;
      const { vendas: sales } = client;
      const { telefones: telephones } = client;
      const { agendamentos: schedules } = client;
      const { clienteEdit: clientEdit } = client;
      const { trocasAvulso: exchanges } = client;
      const { recebimentos: receivement } = client;
      const { agendamentosEditados: schedulesToEdit } = client;
      const foto = client.foto;
      delete client.foto;
      delete client.oldId;

      callback &&
        callback("client-send", {
          nome: client.nome,
          hash: client.hash,
          DATABASE_ID: client.DATABASE_ID,
          status,
          type: 1,
        });

      const URL = `${service}/api/rota/${mainRoute.id}/cliente?token=${token}`;

      let response = await this.httpPost(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(client),
      });

      response = await this.resolveFetchResponse(response);

      callback &&
        callback("client-send", {
          nome: client.nome,
          hash: client.hash,
          DATABASE_ID: client.DATABASE_ID,
          status,
          type: 2,
        });

      try {
        if (client.shouldUploadFoto && foto) {
          await uploadClientePicture(
            {
              fotoUrl: foto.foto,
              userId: response.cliente.id,
            },
            token,
            service
          );
        }

        let clientDocFoto = await models.ClienteDocumento.filter(
          (a) => a.ClienteId == client.id && a.ativo == true
        );
        const docPhotoProms = [];
        for (const clientDoc of clientDocFoto) {
          if (clientDoc.shouldUploadFoto && clientDoc.foto) {
            const fotoData = await uploadClientDocumentPicture(
              {
                fotoUrl: clientDoc.foto,
                ClienteId: client.id,
                DocumentoId: clientDoc.DocumentoId,
                hash: clientDoc.hash,
              },
              { token, service }
            );
            clientDoc.foto = fotoData;
            clientDoc.shouldUploadFoto = false;
            docPhotoProms.push(models.ClienteDocumento.put(clientDoc));
          }
        }

        await Promise.all(docPhotoProms);

        function updateCall(collectionEntity) {
          return function (a) {
            a.ClienteId = response.cliente.id;
            a.shouldSync = false;
            return collectionEntity.put(a, false);
          };
        }

        if (schedules.length) {
          let promschedules = schedules.map(
            updateCall(models.ClienteAgendamento)
          );
          await Promise.all(promschedules);
        }

        if (schedulesToEdit.length) {
          let promschedulesEditados = schedulesToEdit.map(
            updateCall(models.ClienteAgendamento)
          );
          await Promise.all(promschedulesEditados);
        }

        if (parseInt(oldId) < 0) {
          if (response.cliente.id) {
            if (client.shouldUploadFoto && foto) {
              await updateCall(models.ClienteFoto)(foto);
            }
            if (sales.length) {
              let promVenda = sales.map(updateCall(models.ClienteVenda));
              await Promise.all(promVenda);
            }

            if (receivement.length) {
              let promrecebimentos = receivement.map(
                updateCall(models.ClienteRecebimento)
              );
              await Promise.all(promrecebimentos);
            }

            if (clientEdit.length) {
              let promClientesEditados = clientEdit.map(
                updateCall(models.ClienteEdit)
              );
              await Promise.all(promClientesEditados);
            }

            if (exchanges.length) {
              let promClientesTrocasAvulso = exchanges.map(
                updateCall(models.ClienteTrocaAvulso)
              );
              await Promise.all(promClientesTrocasAvulso);
            }

            if (telephones.length) {
              let promtelephones = telephones.map(
                updateCall(models.ClienteTelefone)
              );
              await Promise.all(promtelephones);
            }
          } else {
            throw new apiErrorResponse({
              message:
                "Tente novamente mais tarde, caso o problema persista entre em contato com o suporte",
              title: "Oops, houve um problema",
              type: "error",
              hint: "Sem ID retornado pela api",
              method: "postClientToServer",
            });
          }
        }

        return response.cliente.id;
      } catch (error) {
        throw new ClientError({
          clientName: client.nome,
          id: client.id,
          hash: client.hash,
          DATABASE_ID: client.DATABASE_ID,
          error,
          method: "postClientToServer",
        });
      }
    } catch (error) {
      client.shouldSync = true;
      await models.Cliente.put(client, false);
      throw error;
    }
  }

  /**
   * @description Envia abertura e especifica se os dados de abertura devem ou não ser retirados
   * @param {Boolean} removeRouteOpening Indica se os dados de abertura devem ou não ser removidos, caso TRUE será removida
   */
  async sendRouteOpening() {
    const user = await getUser();
    const route = await this.routeService.mainRoute();
    if (route && route.id) {
      const routeOpening = await this.routeService.routeOpening();
      if (!routeOpening) {
        return;
      }

      routeOpening.listaDiariaInicio = moment(
        routeOpening.listaDiariaInicio,
        "DD/MM/YYYY"
      ).format("DD/MM/YYYY");
      routeOpening.listaDiariaFinal = moment(
        routeOpening.listaDiariaFinal,
        "DD/MM/YYYY"
      ).format("DD/MM/YYYY");
      const URL = `${user.service}/api/rota-abertura?token=${user.sessao.token}`;

      const response = await this.httpPost(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          rotaAbertura: routeOpening,
        }),
      });
      const data = await this.resolveFetchResponse(response);

      const routeOpeningId = data.data.id;
      const routeOpeningHash = data.data.hash;
      await this.localStorage.setItem("route-opening", {
        ...routeOpening,
        hash: routeOpeningHash ? routeOpeningHash : routeOpening.hash,
        id: routeOpeningId,
      });

      return data.data;
    }
  }
  async sendDailyMap() {
    // eslint-disable-next-line no-unreachable
    let routeOpening;
    try {
      routeOpening = await this.routeService.routeOpening();
      if (!routeOpening) return;
    } catch (error) {
      return;
    }
    const map = await this.localStorage.getItem("dailyMap");
    if (routeOpening) {
      const user = await getUser();
      const URL = `${user.service}/api/diaria/map/${routeOpening.hash}?token=${user.sessao.token}`;

      console.warn("Enviado ao servidor: ", map);
      let response = await this.httpPost(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(map),
      });

      await this.resolveFetchResponse(response);
    }
    return map;
  }

  async sendDocuments() {
    const user = await getUser();
    const allDocuments = await models.Documento.getAll();
    const documentsToCreate = allDocuments.filter((doc) => doc.id < 0);
    const documentsToEdit = allDocuments.filter(
      (doc) => doc.shouldSync && doc.id > 0
    );

    const URL = `${user.service}/api/documento?token=${user.sessao.token}`;
    const body = JSON.stringify({
      documentsToCreate,
      documentsToEdit,
    });

    const response = await fetchPollyfill(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });

    const data = await this.resolveFetchResponse(response);

    return {
      oldDocumentsToCreate: documentsToCreate,
      oldDocumentsToEdit: documentsToEdit,
      editedDocs: data.editedDocs,
      createdDocs: data.createdDocs,
    };
  }

  /**
   * @description Utilitario para recuperar o corpo da resposta e verificar se houve erros no server side
   * @param {*} response Retorno da função nativa do js fetch()
   * @return {Promise<any>} Campo body da requisição fetch
   */
  async resolveFetchResponse(response) {
    const data = await response.json();
    if (data.error) {
      await checkEmployeeToken(data, response.abort);
      throw new apiErrorResponse({
        message: data.message || data.msg,
        title: "Oops, houve um problema",
        type: "error",
        error: new Error(),
      });
    }
    return data;
  }

  //   async sendOneClient(clientId) {
  //     const client = await models.Cliente.getAll("id", clientId);
  //     if (!client) throw new Error("ClienteNão encontrado");
  //     const oldId = clientId;

  //     const [user, mainRoute, team] = await Promise.all([
  //       getUser(),
  //       this.routeService.mainRoute(),
  //       this.routeService.team(),
  //     ]);

  //     const newId = await this.postClientToServer({
  //       client,
  //       service: user.service,
  //       token: user.sessao.token,
  //       mainRoute,
  //       team,
  //     });

  //     client.shouldSync = false;
  //     client.id = newId;
  //     await models.Cliente.put(client, false);
  //     await models.ClienteBairro.updateClienteBairroModels(oldId, newId);
  //   }
}
