import { Component, createContext } from "react";
import RequirementsService from "../service/requirements";
import moment from "moment";
import ListRequirementsDialog from "../components/Dialogs/ListRequirementsDialog";

const RequirementsContext = createContext({
  get requirementsMessages() {
    return [];
  },
});

class RequirementsContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      requirementsMessages: [],
    };

    this.requirementsService = new RequirementsService();
  }

  componentDidMount() {
    if (localStorage.getItem("lastExecution") === null) {
      localStorage.setItem("lastExecution", moment().format("DD/MM/YYYY"));
      return this.setState({
        requirementsMessages:
          this.requirementsService.getRequirementsMessages(),
        dialog: this.requirementsService.getRequirementsMessages().length > 0,
      });
    }

    const isBefore = moment().isBefore(
      moment(localStorage.getItem("lastExecution"), "DD/MM/YYYY").add(
        30,
        "days"
      )
    );

    if (!isBefore) {
      localStorage.setItem("lastExecution", moment().format("DD/MM/YYYY"));
      return this.setState({
        requirementsMessages:
          this.requirementsService.getRequirementsMessages(),
        dialog: this.requirementsService.getRequirementsMessages().length > 0,
      });
    }
  }

  render() {
    return (
      <RequirementsContext.Provider
        value={{ requirementsMessages: this.state.requirementsMessages }}
      >
        {this.props.children}
        {this.state.dialog && (
          <ListRequirementsDialog
            isOpen={this.state.dialog}
            onClose={() => this.setState({ dialog: false })}
            title="Aviso, sem esses requisitos o sistema não irá funcionar corretamente!"
            subTitle="Para que seu aplicativo funcione corretamente, você deve ter essas funcionalidades habilitadas: "
            message={this.state.requirementsMessages}
            actions={[
              {
                label: "Ok",
                onClick: () => this.setState({ dialog: false }),
              },
              {
                label: "Cancelar",
                onClick: () => this.setState({ dialog: false }),
              },
            ]}
          />
        )}
      </RequirementsContext.Provider>
    );
  }
}

export { RequirementsContext, RequirementsContextProvider };
