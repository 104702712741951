import React from "react";
import ProductsForm from "../../components/Products/ProductsForm2";
import { ProductContext } from "../../contexts/ProductsContext";

/**
 * Tela de editar um produto.
 */
class ProductView extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   */
  constructor(props) {
    super(props);
    this.state = {};
    this.saveProduct = this.saveProduct.bind(this);
  }

  /**Função irá salvar os dados no banco.
   * @function
   */
  async saveProduct(data) {
    this.props.match.params.id
      ? await this.context.updateBasket(data)
      : await this.context.createBasket(data);

    this.props.history.push("/products", {
      ...this.props.history.location.state,
      prevUrl: this.props.history.location.pathname,
    });
  }

  render() {
    return (
      <>
        <ProductsForm
          history={this.props.history}
          productId={this.props.match.params.id}
          add={this.props.match.params.id ? false : true}
          saveProduct={this.saveProduct}
        />
      </>
    );
  }
}
ProductView.contextType = ProductContext;

export default ProductView;
