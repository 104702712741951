import React from "react";
import {
  withStyles,
  Grid,
  Slide,
  Dialog,
  AppBar,
  ListItem,
  List,
  Divider,
  Typography,
  Toolbar,
  IconButton,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Radio from "@material-ui/core/Radio";
import SearchBar from "../SearchBar/SearchBar";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const styles = (theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    fontSize: "1.2rem",
  },
  pointAmount: {
    display: "inline",
    fontSize: ".7rem",
    marginLeft: theme.spacing(2),
  },
  label: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "1rem",
    "& span": {
      fontWeight: "bold",
    },
  },
  buttonSave: {
    border: "1px solid #fff",
  },
  "fade-enter": {
    opacity: 0,
  },
  "fade-enter-active": {
    opacity: 1,
    transition: "opacity 500ms",
  },
  "fade-exit": {
    opacity: 1,
  },
  "fade-exit-active": {
    opacity: 0,
    transition: "opacity 300ms",
  },
  radioChecked: {
    "&$checked": {
      color: "purple",
    },
  },
  modalLabelContainer: {
    flex: 1,
  },
});

class DialogSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      multSelect: [],
    };

    this.Trasition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });

    this.onChange = this.onChange.bind(this);
    this.calculatePoints = this.calculatePoints.bind(this);
    this.saveItemSelect = this.saveItemSelect.bind(this);
    this.onSearchItem = this.onSearchItem.bind(this);
    this.onAddMultSelect = this.onAddMultSelect.bind(this);
    this.onClickSaveToolbar = this.onClickSaveToolbar.bind(this);
  }

  onChange(data) {
    this.props.onSearchItem(data);
  }

  calculatePoints(product) {
    const pointsString = ` - ${this.props.getPoints(product)} pts`;
    return pointsString;
  }

  multSelectTotalPoints() {
    const somatory = this.state.multSelect.reduce((memo, el) => {
      const productPoint = this.props.getPoints(el);
      memo += productPoint;
      return memo;
    }, 0);
    return somatory;
  }

  saveItemSelect(data) {
    this.props.onClickSaveItems({ ...data, quantidade: 1 });
    this.props.onClose();
  }

  onSearchItem(data) {
    this.props.onSearchItem(data);
  }

  onAddMultSelect(data) {
    const position = this.state.multSelect.findIndex((el) => el == data);
    if (position >= 0) {
      const clone = [...this.state.multSelect];
      clone.splice(position, 1);
      this.setState({ multSelect: clone });
      return;
    }
    this.setState({ multSelect: this.state.multSelect.concat([data]) });
  }

  onClickSaveToolbar() {
    const itemsList = this.state.multSelect.map((el) => {
      const clone = { ...el };
      clone.quantidade = 1;
      return clone;
    });

    this.props.onClickSaveItems(itemsList);
    this.setState({ multSelect: [] });
    this.props.onClose();
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.props.onClose}
          TransitionComponent={this.Trasition}
        >
          <AppBar position="fixed">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.props.onClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <div className={classes.modalLabelContainer}>
                <Typography className={classes.title}>
                  Selecionar Itens
                </Typography>
                {this.props.shouldBlockItems && (
                  <Typography className={classes.pointAmount}>
                    Total de pontos: {this.props.pointsAvaliable}
                  </Typography>
                )}
              </div>
              <Button
                edge="end"
                color="inherit"
                onClick={this.onClickSaveToolbar}
                aria-label="close"
              >
                Adicionar
              </Button>
            </Toolbar>
          </AppBar>

          <SearchBar getInput={this.props.onSearchItem} />
          <List disablePadding={true} style={{ paddingTop: "30px" }}>
            <TransitionGroup className={classes.prodcutsList} component={null}>
              {this.props.searchedItems.map((el, index) => {
                let points = null;
                if (el.valor && this.props.shouldShowPoints)
                  points = this.calculatePoints(el);
                const isActive = this.state.multSelect.find(
                  (item) => item == el
                );

                let isEnabled = true;
                if (this.props.shouldBlockItems && !isActive) {
                  const selectedItemsPoints = this.multSelectTotalPoints();
                  const productPoints = this.props.getPoints(el);
                  isEnabled =
                    this.props.pointsAvaliable >=
                    selectedItemsPoints + productPoints;
                }

                return (
                  <CSSTransition
                    timeout={{
                      enter: 300,
                      exit: 500,
                    }}
                    classNames={{
                      enter: classes["fade-enter"],
                      enterActive: classes["fade-enter-active"],
                      exit: classes["fade-exit"],
                      exitActive: classes["fade-exit-active"],
                    }}
                    key={index}
                  >
                    <>
                      <ListItem
                        onClick={() => this.onAddMultSelect(el)}
                        button
                        disabled={!isEnabled}
                      >
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                        >
                          <Typography className={classes.label}>
                            <span>{el.identificador}</span>{" "}
                            {this.props.shouldShowPoints ? points : ""}
                          </Typography>
                          <Radio
                            color="primary"
                            value={el}
                            checked={!!isActive}
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "C" }}
                            classes={{
                              root: classes.radioChecked,
                              colorPrimary: classes.radioChecked,
                            }}
                          />
                        </Grid>
                      </ListItem>
                      <Divider />
                    </>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </List>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(DialogSearch);
