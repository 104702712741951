import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Typography, Grid, Divider } from "@material-ui/core";
import RoundButton from "../../Buttons/RoundButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import CloseIcon from "@material-ui/icons/Close";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  itemName: {
    fontWeight: "bold",
    fontSize: "1.1rem",
  },
});
/**
 * Drawer que sobe do inferior da tela, utilizado em telas para ordenação de lista, exemplo: 'Ordenar Cliente' e 'Ordenar bairro'
 */
class DrawerBottom extends React.Component {
  /**
   * @param {string} props.name Prop que indica o nome do card selecionado
   * @param {string} props.type Tipo de lista que está sendo exibida, exemplo: cliente, bairro...
   * @param {function} props.toggleDrawer Função que ativa e desativa o drawer
   * @param {function} props.onClickInsertTop Função que insere o card selecionado no topo da lista
   * @param {function} props.onClickInsertAfter Função que insere o  primeiro card selecionado depois do segundo card selecionado
   * @param {function} props.onClickInsertNext Função que insere os cards depois do outro
   * @param {object} props.classes Objeto com todas as classes do CSS
   */
  constructor() {
    super();
    this.state = {};
    this.fullList = this.fullList.bind(this);
  }

  /**Função que retorna todo o html do componente
   * @function
   */
  fullList() {
    return (
      <div
        className={this.props.classes.fullList}
        role="presentation"
        onClick={null}
        onKeyDown={() => this.props.toggleDrawer()}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            wrap="nowrap"
            container
            style={{ padding: "5px 10px 20px" }}
          >
            <Grid item xs={11}>
              <Typography
                style={{ color: "#4a4a4a", textTransform: "uppercase" }}
              >
                Ordenar {this.props.type}:
              </Typography>
              <Typography className={this.props.classes.itemName}>
                {this.props.name}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Grid container style={{ height: "100%" }} alignItems="center">
                <CloseIcon
                  fontSize="default"
                  onClick={() => this.props.toggleDrawer()}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" color="" />
          <List style={{ width: "100%" }} aria-label="Order clients actions">
            <ListItem button onClick={this.props.onClickInsertTop}>
              <ListItemIcon>
                <RoundButton
                  color="#3670bd"
                  colorIcon="#fff"
                  icon={<ArrowUpwardIcon />}
                />
              </ListItemIcon>
              <ListItemText primary="Inserir no topo" />
            </ListItem>
            <Divider variant="fullWidth" />
            <ListItem button onClick={this.props.onClickInsertAfter}>
              <ListItemIcon>
                <RoundButton
                  color="#31bb26"
                  colorIcon="#fff"
                  icon={<ArrowForwardIcon />}
                />
              </ListItemIcon>
              <ListItemText primary="Inserir depois de ..." />
            </ListItem>
            <Divider variant="fullWidth" />
            <ListItem button onClick={this.props.onClickInsertNext}>
              <ListItemIcon>
                <RoundButton
                  color="#e4dc00"
                  colorIcon="#fff"
                  icon={<ArrowDownwardIcon />}
                />
              </ListItemIcon>
              <ListItemText primary={`Inserir próximo ${this.props.refCard}`} />
            </ListItem>
          </List>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <Drawer
        anchor="bottom"
        open={this.props.open}
        onClose={() => this.props.toggleDrawer()}
      >
        {this.fullList()}
      </Drawer>
    );
  }
}
export default withStyles(styles)(DrawerBottom);
