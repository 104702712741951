import React from "react";
import {
  Grid,
  Button,
  Slider,
  Typography,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
const applyStyles = withStyles((theme) => ({
  sliderConteiner: {
    width: "80vw",
    display: "flex",
    flex: "1",
    alignItems: "center",
  },
  sliderLabel: {
    minWidth: 65,
    fontWeight: 500,
    color: "#EEE",
    paddingRight: theme.spacing(2),
  },
  rootSlider: {
    padding: "22px 0px",
  },
}));

const InprovedSlider = withStylesSlider();

class CropPictureActionBar extends React.Component {
  static propTypes = {
    rotation: PropTypes.number,
    onClickBack: PropTypes.func,
    onClickDone: PropTypes.func,
    zoom: PropTypes.number,
    onSlideZoomChange: PropTypes.func,
    onSlideRotationChange: PropTypes.func,
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" direction="column">
            <Grid item className={classes.sliderConteiner} xs={12}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
                className={classes.sliderLabel}
              >
                Zoom
              </Typography>
              <InprovedSlider
                value={this.props.zoom}
                min={1}
                max={3}
                step={0.1}
                valueLabelDisplay="auto"
                onChange={(event, value) => this.props.onSlideZoomChange(value)}
              />
            </Grid>
            <Grid item className={classes.sliderConteiner} xs={12}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
                className={classes.sliderLabel}
              >
                Rotação
              </Typography>
              <InprovedSlider
                value={this.props.rotation}
                min={0}
                max={180}
                step={5}
                valueLabelDisplay="auto"
                onChange={(event, value) =>
                  this.props.onSlideRotationChange(value)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="space-evenly">
            <Grid item>
              <Button color="secondary" onClick={this.props.onClickBack}>
                Tirar outra foto
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={this.props.onClickDone}>
                Concluir
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default applyStyles(CropPictureActionBar);

function withStylesSlider() {
  const boxShadow =
    "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
  return withStyles({
    root: {
      color: "#3880ff",
      height: 2,
      padding: "22px 0px",
      marginLeft: 16,
    },
    thumb: {
      height: 20,
      width: 20,
      backgroundColor: "#fff",
      boxShadow: boxShadow,
      marginTop: -10,
      marginLeft: -10,
      "&:focus, &:hover, &$active": {
        boxShadow:
          "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          boxShadow: boxShadow,
        },
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 5px)",
      top: -22,
    },
    track: {
      height: 2,
    },
    rail: {
      height: 2,
      opacity: 0.5,
      backgroundColor: "#bfbfbf",
    },
    mark: {
      backgroundColor: "#bfbfbf",
      height: 8,
      width: 1,
      marginTop: -3,
    },
    markActive: {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  })(Slider);
}
