export default class ShareService {
  static async shareImage(blobFile, fileName) {
    const file = new File([blobFile], fileName, {
      type: "image/png",
      lastModified: new Date(),
    });

    if (!!navigator.canShare && !!navigator.share) {
      await navigator.canShare({ files: [file] });
      await navigator.share({ files: [file] });
    } else {
      console.log(file);
    }
  }
}
