import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import Header from "../../components/HeaderForAdd/Header";
import AddPictures from "../../components/Pictures/AddPictures";
import FormAddClient from "../../components/Client/ClientForm";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Signature from "../../components/Signature/Signature";
import NeighborhoodForm from "../../components/Forms/NeighborhoodForm";
import clsx from "clsx";
import History from "../../router/History";
import Moeda from "../../utils/Moeda";
import { resetOrientation } from "../../service/Pictures/Pictures";
import { NeighborhoodContext } from "../../contexts/NeighborhoodContext";
import { ClientContext } from "../../contexts/ClientContext";

const styles = (theme) => ({
  hidden: {
    display: "none",
  },
  show: {
    height: "500px",
    width: "100%",
  },
});

/**
 * Tela de cadastrar clientes.
 */
class NewClient extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {boolean} this.state.signature Boleano que definirá a alternância de exibição entre o componente de assinatura e de formulário
   * @property {boolean} this.state.redirect  Boleano que indicará um possivel redirecionamento de página
   * @property {string} this.state.sigImage  Estado que armazerará a imagem da assinatura que estará em BASE64
   * @property {object} this.state.image  Estado que armazerará a imagem de perfil do usuário que estará em BASE64
   * @property {number} this.state.newClient  Estado que armazerará os dados do novo cliente para assim então salvar no banco
   */
  constructor() {
    super();
    this.state = {
      signatureIsOpen: false,
      sigImage: null,
      image: null,
      newClient: null,
      neighborhoodFormIsOpen: false,
      clientAddress: {},
      validators: [],
      isLoading: false,
      // getAllCamposObrigatorios: () => {},
    };

    this.handleToggleSignature = this.handleToggleSignature.bind(this);
    this.handleToggleNeighborhoodForm =
      this.handleToggleNeighborhoodForm.bind(this);
    this.getTrimmedDataURL = this.getTrimmedDataURL.bind(this);
    this.clickDeleteImage = this.clickDeleteImage.bind(this);
    this.saveClient = this.saveClient.bind(this);
  }

  componentDidMount() {
    this.context.updateSelf();
    this.getRouteOpening();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.memoScrollY &&
      (prevState.neighborhoodFormIsOpen || prevState.signatureIsOpen)
    ) {
      window.scrollTo(0, prevState.memoScrollY);
    }
  }
  async getRouteOpening(prevProps, prevState) {
    let isRouteOpen = await this.context.getRouteOpening();
    if (!isRouteOpen) {
      History.goBack();
    }
  }
  /**Função irá armazenar a imagem de perfil do cliente.
   * @function
   * @param {string} imageBASE64 Imagem em base64
   */
  onUploadImage = (imageBASE64) => {
    this.setState({ isLoading: true });
    resetOrientation(imageBASE64, 1).then((image) => {
      this.setState({ image, isLoading: false });
    });
  };

  /**Função irá alterar o valor do estado responsável por exibir o componente de assinatuea.
   * @function
   */
  handleToggleSignature() {
    this.setState({
      signatureIsOpen: !this.state.signatureIsOpen,
      memoScrollY: window.scrollY,
    });
  }

  handleToggleNeighborhoodForm(clientAddress) {
    this.setState({
      neighborhoodFormIsOpen: !this.state.neighborhoodFormIsOpen,
      clientAddress: clientAddress,
      memoScrollY: window.scrollY,
    });
  }

  /**Função irá  armazenar a imagem da assinatura do cliente.
   * @function
   * @param {string} image Imagem em base64 da assinatura
   */
  getTrimmedDataURL(image) {
    this.setState({ sigImage: image });
  }

  /**Função irá  apagar a imagem de perfil do usuário.
   * @function
   */
  clickDeleteImage() {
    this.setState({ image: null });
  }

  /**Função irá  salvar os dados do novo cliente no banco.
   * @function
   * @param {object} data Dados para criação do cliente
   */
  async saveClient(data) {
    data.saldoAtual = Moeda.create(0).mount();
    data.veaco = false;

    const clientHash = await this.context.createClient(data);

    if (clientHash) {
      History.push(`/sale/create/${clientHash}`, History.state);
    }
  }

  /**Função irá  armazenar a imagem da assinatura do cliente.
   * @function
   * @param {object} data Dados para criação
   */

  render() {
    let { classes } = this.props;
    let classPrincipalPage = clsx({
      [classes.hidden]:
        this.state.signatureIsOpen || this.state.neighborhoodFormIsOpen,
      [classes.show]:
        !this.state.signatureIsOpen && !this.state.neighborhoodFormIsOpen,
    });
    return (
      <>
        <NeighborhoodContext.Consumer>
          {(neighborhood) => (
            <>
              {this.state.signatureIsOpen && (
                <Signature
                  getTrimmedDataURL={this.getTrimmedDataURL}
                  handleToggleSignature={this.handleToggleSignature}
                />
              )}

              <Grid container className={classPrincipalPage}>
                <Header link="/" icon={<ExpandMoreIcon fontSize="large" />}>
                  Novo Cliente
                </Header>
                <AddPictures
                  onUploadImage={this.onUploadImage}
                  clickDeleteImage={this.clickDeleteImage}
                  image={this.state.image}
                  isLoading={this.state.isLoading}
                />
                <FormAddClient
                  handleToggleSignature={this.handleToggleSignature}
                  signatureImage={this.state.sigImage}
                  neighborhoodContext={neighborhood}
                  saveClient={this.saveClient}
                  image={this.state.image}
                  handleToggleNeighborhoodForm={
                    this.handleToggleNeighborhoodForm
                  }
                  context={this.context}
                />
              </Grid>

              {this.state.neighborhoodFormIsOpen && (
                <NeighborhoodForm
                  handleToggleNeighborhoodForm={
                    this.handleToggleNeighborhoodForm
                  }
                  neighborhoodContext={neighborhood}
                  formData={this.state.clientAddress}
                />
              )}
            </>
          )}
        </NeighborhoodContext.Consumer>
      </>
    );
  }
}
NewClient.contextType = ClientContext;
export default withStyles(styles)(NewClient);
