import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import Card from "../../components/CardComponents/CardNeighborhood/Card";
import DrawerBottom from "../../components/Drawers/DrawersForSort/DrawerBottom";
import SelectInput from "../../components/Inputs/SelectInput";
import LocalStorage from "../../utils/localStorage";
import { DailyListContext } from "../../contexts/DailyListContext";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import { SortContext } from "../../contexts/SortContext";
import Header from "../../components/HeaderForAdd/Header";
import CloseIcon from "@material-ui/icons/Close";
import HeaderMenu from "../../components/HeaderForAdd/HeaderMenu";

import FlipMove from "react-flip-move";
import { NotificationSnackbar } from "../../components/Snack/notification";

const styles = (theme) => ({
  body: {
    backgroundColor: "#f5f5f5",
    height: "100vh",
    position: "absolute",
    width: "100%",
  },
  container: {
    padding: "20px 15px 5px 15px",
    backgroundColor: "#fff",
  },
  select: {
    paddingTop: "10px",
  },
  listCards: {
    paddingTop: "20px",
    "& > div": {
      paddingBottom: "13px",
    },
  },
  saveButton: {
    paddingTop: 0,
  },
});

/**
 * Tela de ordenar cliente.
 */
class SortClientsConsumer extends React.Component {
  /**
   * @param {array} props.selectedNeighborhood Lista com todos os bairros cadastrados
   * @param {array} props.clientes Lista com todos os clientes do bairro selecionado
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {number} this.state.selected Estado que armazena o índice do card selecionado
   * @property {number} this.state.secondSelected  Estado que armazena o índice do segundo card selecionado
   * @property {boolean} this.state.drawer Boleano que define a exibição do drawer
   * @property {boolean} this.state.enableInsertAfter  Boleano que define se o Inserir depois está ativado, para assim pode selecionar o segundo card
   * @property {array} this.state.modified Lista que guarda as id de cada card para sa saber se eles foram alterados
   * @property {refCard} this.state.refCard Estado que guarda o próximo índice da função 'Próximo'
   * @property {boolean} this.state.enableSave Boleano que se algum item da lista foi alterado, assim habilitando o salvamento
   */
  constructor(props) {
    super(props);
    this.state = {
      neighborhoodsIds: [],
      clients: [],
      selected: null,
      secondSelected: null,
      drawer: false,
      enableInsertAfter: false,
      refCard: null,
      modified: [],
      enableSave: false,
      selectedNeighborhood: "",
      routeIsOpen: false,
      isSnackBarOpen: false,
    };
    this.checkRouteOpen = this.checkRouteOpen.bind(this);
    this.getNeighborhoodIDs = this.getNeighborhoodIDs.bind(this);
    this.enableSave = this.enableSave.bind(this);
    this.handleInsertAfter = this.handleInsertAfter.bind(this);
    this.handleInsertTop = this.handleInsertTop.bind(this);
    this.handleInsertNext = this.handleInsertNext.bind(this);
    this.handleSelectCard = this.handleSelectCard.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.getNeighborhoodClients = this.getNeighborhoodClients.bind(this);
    this.checkCardIsModified = this.checkCardIsModified.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleSnackClose = this.handleSnackClose.bind(this);
    this.onOrderSave = this.onOrderSave.bind(this);
    this.onClickHeaderIcon = this.onClickHeaderIcon.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.dailyListContext.neighborhoodInfo !=
      this.props.dailyListContext.neighborhoodInfo
    ) {
      this.getNeighborhoodIDs(this.props.dailyListContext.neighborhoodInfo);
    }
    if (prevProps.sortContext != this.props.sortContext) {
      this.checkRouteOpen();
    }
  }

  async checkRouteOpen() {
    let checkRoute = await this.props.sortContext.checkRouteOpen();
    if (checkRoute) {
      this.setState({ routeIsOpen: true });
    } else {
      this.setState({ routeIsOpen: false });
    }
  }

  // componentWillUnmount() {
  //   this.unlisten();
  // }

  componentDidMount() {
    this.getNeighborhoodIDs(this.props.dailyListContext.neighborhoodInfo);
    this.checkRouteOpen();
  }

  getNeighborhoodIDs(ids) {
    let arrayIDs = [];
    for (let id in ids) {
      arrayIDs.push(Number(id));
    }
    this.props.sortContext.getNeighborhoodsById(arrayIDs);
  }

  /**Função responsável por habilitar o botão de salvar.
   * @function
   */
  async enableSave() {
    this.setState({ enableSave: true });
    await this.handleOnSave();
  }

  /**Função para inserir o card clicado após o segundo card selecionado
   * @function
   */
  handleInsertAfter() {
    if (!this.state.enableInsertAfter) {
      this.setState({ enableInsertAfter: true, drawer: false });
      return;
    }

    if (this.state.enableInsertAfter && this.state.secondSelected != null) {
      let client = [...this.state.clients];
      let aux = client[this.state.selected];
      let newRefCard = null;
      client.splice(this.state.secondSelected + 1, 0, aux);
      if (this.state.secondSelected >= this.state.selected) {
        newRefCard = this.state.secondSelected;
        client.splice(this.state.selected, 1);
      } else {
        newRefCard = this.state.secondSelected + 1;
        client.splice(this.state.selected + 1, 1);
      }
      this.setState(
        {
          clients: client,
          selected: null,
          enableInsertAfter: false,
          secondSelected: null,
          refCard: newRefCard,
          modified: [...this.state.modified, aux.id],
        },
        this.enableSave
      );
    }
  }

  /**Função de inserir no topo da lista
   * @function
   */
  handleInsertTop() {
    let clients = [...this.state.clients];
    let aux = clients[this.state.selected];
    this.setState({ modified: [...this.state.modified, aux.id] });
    clients.splice(this.state.selected, 1);
    clients.unshift(aux);
    this.setState(
      {
        clients: clients,
        selected: null,
        drawer: false,
        refCard: 0,
        enableInsertAfter: false,
      },
      this.enableSave
    );
  }

  /**Função de inserir no próximo
   * @function
   */
  handleInsertNext() {
    let clients = [...this.state.clients];
    let aux = this.state.clients[this.state.selected];
    this.setState({ modified: [...this.state.modified, aux.id] });
    this.setState({ refCard: this.state.selected, drawer: false });
    // } else {
    if (this.state.refCard >= this.state.selected) {
      clients.splice(this.state.refCard + 1, 0, aux);
      clients.splice(this.state.selected, 1);
    } else {
      clients.splice(this.state.refCard + 1, 0, aux);
      clients.splice(this.state.selected + 1, 1);
      this.setState({ refCard: this.state.refCard + 1 });
    }
    this.setState(
      {
        clients: clients,
        selected: null,
        drawer: false,
        enableInsertAfter: false,
      },
      this.enableSave
    );

    // }
  }

  // /**Função que seta o índice do card que for clicado no 'Inserir depois de'
  //  * @function
  //  * @param {number} index Índice do segundo card selecionado
  //  */
  // = (index) {
  //   if (this.state.enableInsertAfter) {
  //     this.setState({ secondSelected: index });
  //   }
  // };

  /**Função que seta o índice do card selecionado
   * @function
   * @param {number} index Índice do card selecionado
   */
  handleSelectCard(index) {
    if (!this.state.drawer && !this.state.enableInsertAfter) {
      this.setState({ drawer: true, selected: index });
      return;
    }
    if (this.state.enableInsertAfter && !this.state.secondSelected) {
      this.setState({ secondSelected: index }, this.handleInsertAfter);
    }
  }

  /**Função que alterna a exibição do drawer
   * @function
   */
  toggleDrawer() {
    this.setState({ drawer: !this.state.drawer });
  }

  async getNeighborhoodClients(neighborhoodId) {
    let rotaAbertura = await LocalStorage.instance.getItem("route-opening");

    if (!rotaAbertura) {
      return;
    }
    let clients = await this.props.dailyListContext.orderedClients.filter(
      (a) => a.BairroId == neighborhoodId
    );
    let neighborhood = await this.props.sortContext.neighborhoods.find(
      (a) => a.id == neighborhoodId
    );
    this.setState({
      clients: clients,
      selectedNeighborhood: neighborhood.id,
      refCard: 0,
    });
  }

  checkCardIsModified(clientId) {
    const modified = this.state.modified.some((id) => id == clientId);
    return modified;
  }

  handleSnackClose() {
    this.setState({ isSnackBarOpen: false });
  }

  async handleOnSave() {
    await this.onOrderSave();
    this.setState({ isSnackBarOpen: true });
    setTimeout(this.handleSnackClose, 3000);
  }

  async onOrderSave() {
    const orderedClients = [...this.state.clients];

    const lowerPosition = this.state.clients.reduce((memo, client) => {
      if (!memo) {
        return client.position;
      }
      if (memo > client.position) {
        return client.position;
      }
      return memo;
    }, false);
    let index = lowerPosition;
    for (let client of orderedClients) {
      client.position = index;
      index++;
    }

    return await this.props.dailyListContext.updateDailyListOrder(
      orderedClients
    );
  }

  onClickHeaderIcon() {
    this.setState({
      selected: null,
      secondSelected: null,
      enableInsertAfter: false,
      drawer: false,
    });
  }
  getOrderedNeighborhoods() {
    const sortedArray = this.props.sortContext.neighborhoods.sort((a, b) => {
      if (a.nome > b.nome) {
        return 1;
      }
      if (a.nome < b.nome) {
        return -1;
      }

      return 0;
    });

    return sortedArray;
  }

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.body}>
        {this.state.enableInsertAfter ? (
          <Header icon={<CloseIcon />} func={this.onClickHeaderIcon}>
            Ordenar clientes
          </Header>
        ) : (
          <HeaderMenu>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Typography
                  style={{
                    fontSize: "1.2rem",
                    fontFamily: "Roboto, sans-serif",
                  }}
                >
                  Ordenar clientes
                </Typography>
              </Grid>
            </Grid>
          </HeaderMenu>
        )}

        <Grid
          container
          className={classes.container}
          style={{ marginTop: "15px" }}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Ordem de todos os clientes:</Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              label="Bairro"
              onChange={this.getNeighborhoodClients}
              required={false}
              disabled={!this.state.routeIsOpen}
              error={!this.state.routeIsOpen}
              helperText={
                !this.state.routeIsOpen
                  ? "Você precisa abrir a rota para ordenar os clientes!"
                  : null
              }
              value={this.state.selectedNeighborhood}
              menuItem={this.getOrderedNeighborhoods()}
              variant="standard"
            />
          </Grid>
          <hr />
          <Grid
            container
            alignItems="stretch"
            direction="column"
            justify="space-evenly"
            className={classes.listCards}
          >
            <FlipMove>
              {this.state.clients.map((client, index) => {
                return (
                  <Grid
                    container
                    key={client.hash}
                    style={{ marginBottom: 10 }}
                  >
                    <Card
                      data={client}
                      index={index}
                      onCardClick={this.handleSelectCard}
                      enableSecondClick={this.state.enableInsertAfter}
                      modified={this.checkCardIsModified(client.id)}
                      selected={this.state.selected == index}
                      refCard={this.state.refCard == index}
                    />
                  </Grid>
                );
              })}
            </FlipMove>

            {/* {
            (!this.state.clients.length && this.state.selectedNeighborhood != "") && 
            <span style={{display: 'block', color: '#9c9c9c', fontSize: '1.3rem', textAlign: 'center'}}>Não há nenhum cliente nesse bairro.</span>
            } */}
          </Grid>

          <DrawerBottom
            toggleDrawer={this.toggleDrawer}
            open={this.state.drawer}
            name={
              this.state.selected != null
                ? this.state.clients[this.state.selected].nome
                : null
            }
            refCard={
              this.state.refCard != null
                ? this.state.clients.length
                  ? this.state.clients[this.state.refCard].nome
                  : null
                : null
            }
            onClickInsertTop={this.handleInsertTop}
            onClickInsertAfter={this.handleInsertAfter}
            onClickInsertNext={this.handleInsertNext}
            type="cliente"
          />
        </Grid>
        <NotificationSnackbar
          message="Ordem de clientes salva com sucesso!"
          open={this.state.isSnackBarOpen}
          severity="success"
          handleClose={this.handleSnackClose}
        />
      </div>
    );
  }
}

SortClientsConsumer.contextType = MessageDialogContext;

class SortClients extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <SortContext.Consumer>
        {(sortContext) => (
          <SortClientsConsumer
            {...this.props}
            dailyListContext={this.context}
            sortContext={sortContext}
          />
        )}
      </SortContext.Consumer>
    );
  }
}
SortClients.contextType = DailyListContext;
export default withStyles(styles)(SortClients);
