import AbstractDexieClass from "../abstractClasses/AbstractDexieErrorLog";
import { getUser, getCompany } from "../../service/authentication";
import { team, mainRoute } from "../../service/route";
import * as moment from "moment";
import firestore, { collection, setDoc, doc } from "../../service/firestore";

// Função para salvar o log
const saveLog = async (
  href,
  teamId,
  timeStamp,
  routeId,
  eventData,
  companyId,
  eventType,
  eventName,
  employeeId,
  eventLocation
) => {
  try {
    const isValidTimeStamp = moment(timeStamp).isValid();
    if (!isValidTimeStamp) {
      throw new Error("Invalid time value");
    }

    for (let key in eventData) {
      eventData[key] = eventData[key] || null;
    }

    const logData = {
      href,
      teamId,
      timeStamp,
      routeId,
      eventData,
      companyId,
      eventType,
      eventName,
      employeeId,
      eventLocation,
    };

    const empresaDoc = doc(
      collection(firestore, "EMPRESAS"),
      String(companyId)
    );
    const rotaDoc = doc(collection(empresaDoc, "ROTAS"), String(routeId));
    const equipeDoc = doc(collection(rotaDoc, "EQUIPES"), String(teamId));
    const funcDoc = doc(
      collection(equipeDoc, "FUNCIONARIOS"),
      String(employeeId)
    );
    const logDoc = doc(collection(funcDoc, "securePAPLogs"));

    await setDoc(logDoc, logData);
  } catch (error) {
    console.log("Erro ao salvar log: ", error);
  }
};

export default class EventLog extends AbstractDexieClass {
  constructor() {
    super("EventLog", {
      href: String,
      teamId: String,
      timeStamp: Date,
      routeId: String,
      eventData: Object,
      companyId: String,
      eventType: String,
      eventName: String,
      employeeId: String,
      eventLocation: String,
    });

    window.EventLog = this;
    this.getLocation = this.getLocation.bind(this);
    this.getDefaultProperties = this.getDefaultProperties.bind(this);
    this.registerEvent = this.registerEvent.bind(this);
    this.getEventsOfDay = this.getEventsOfDay.bind(this);
  }

  getLocation() {
    return window.location.pathname;
  }

  async getDefaultProperties() {
    try {
      const now = new Date();
      const { id: teamId } = await team();
      const location = this.getLocation();
      const { id: routeId } = await mainRoute();
      const { id: employeeId } = await getUser();
      const { nome: name } = await getCompany();

      const defaultProperties = {
        href: location,
        teamId: teamId,
        timeStamp: now,
        routeId: routeId,
        companyId: name,
        employeeId: employeeId,
      };

      return defaultProperties;
    } catch (error) {
      return {};
    }
  }

  async registerEvent(eventName, eventLocation, eventType, eventData) {
    try {
      const event = await this.getDefaultProperties();
      event.eventData = eventData;
      event.eventType = eventType;
      event.eventName = eventName;
      event.eventLocation = eventLocation;

      const DATABASE_ID = await this.model.add(event);

      const href = event.href;
      const teamId = event.teamId;
      const timeStamp = event.timeStamp.toISOString();
      const routeId = event.routeId;
      const companyId = event.companyId;
      const employeeId = event.employeeId;

      saveLog(
        href,
        teamId,
        timeStamp,
        routeId,
        eventData,
        companyId,
        eventType,
        eventName,
        employeeId,
        eventLocation
      );

      return DATABASE_ID;
    } catch (error) {
      console.log("Erro ao registrar evento: ", error);
      return null;
    }
  }
  async getEventsOfDay(date) {
    const startDate = moment(date, "DD/MM/YYYY").startOf("day").toDate();
    const endDate = moment(date, "DD/MM/YYYY").endOf("day").toDate();

    const allEvents = await this.model
      .where("timeStamp")
      .between(startDate, endDate, true, true)
      .toArray();

    return this._toLogString(allEvents);
  }

  _toLogString(events) {
    const eventsStream = events.map(this._convertLine);
    return eventsStream.join("\n");
  }

  _convertLine(event) {
    const { timeStamp, eventName, eventLocation, eventType, href, eventData } =
      event;
    const parsedData = JSON.stringify(eventData);
    const parsedDate = moment(timeStamp).format("HH:MM:ss");
    return `[${parsedDate}] [${eventName}] [${eventLocation}] [${eventType}] [${href}] - ${parsedData}`;
  }
}
