import React, { createContext } from "react";
import { Cliente, Produto, ClienteTrocaAvulso } from "../repository/models";
import { createExchange } from "../service/movements/index";
import Moeda from "../utils/Moeda";
import AppStorageError from "../errorDefinition/AppStorageError";
import BusinessError from "../errorDefinition/BusinessError";
import { AbstractContext } from "./AbstractContext";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import { getSchedulesByTypeAndClient } from "../service/schedulings";
import ClientnotificationCenter from "../service/notifications/clientNotificationCenter";
import { WorkerDownloadClients } from "../utils/workerDownloadClients";
import { getUser } from "../service/authentication";
import SyncNotificationCenter from "../service/notifications/syncNotificationCenter";

const eventEmitter = ClientnotificationCenter.instance;

const success = (context, message) =>
  context.addAsyncDialog({
    message: message,
    title: "Sucesso!",
    type: "success",
    hasCloseButton: false,
  });

const produto = new Produto();
const cliente = new Cliente();
const clienteTrocaAvulso = new ClienteTrocaAvulso();

const ExchangeContext = createContext({
  exchanges: [],
});
const OBSERVED_MODELS = ["Cliente", "ClienteTrocaAvulso"];
/**
 * Contexto utilizado na pagina de trocas.
 */

class ExchangeContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.realizeExchange = this.realizeExchange.bind(this);
    this.observable = this.observable.bind(this);
    this.getAll = this.getAll.bind(this);
    this.createExchange = this.createExchange.bind(this);
    this.getClientByHash = this.getClientByHash.bind(this);
    this.state = {
      exchanges: [],
      getAll: this.getAll,
      createExchange: this.createExchange,
      getClientByHash: this.getClientByHash,
      realizeExchange: this.realizeExchange,
      getClientExchange: this.getClientExchange.bind(this),
    };
    this.lastClientRequired = null;
  }

  async realizeExchange(hash, clientHash) {
    try {
      const [exchange] = await clienteTrocaAvulso.getAll("hash", hash);
      const [client] = await cliente.getAll("hash", clientHash);
      exchange.dataTrocaRealizada = new Date();
      exchange.shouldSync = true;
      client.shouldSync = true;
      const exchangeSchedules = await getSchedulesByTypeAndClient(
        client.hash,
        "troca"
      );
      await exchangeSchedules.delete();
      await clienteTrocaAvulso.put(exchange);
      await cliente.put(client);

      await eventEmitter.notifyAll("exchangeRealized", client.hash);
      await this.observable();
      return true;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async observable() {
    if (this.lastClientRequired) {
      await this.getClientExchange(this.lastClientRequired);
    }
  }

  async getClientExchange(clientHash) {
    this.lastClientRequired = clientHash;
    const client = await cliente.findByHash(clientHash);
    const allExchanges = await clienteTrocaAvulso.getAll(
      "ClienteId",
      client.id
    );

    this.setState({ exchanges: allExchanges, client: client });
  }

  /**Função responsável buscar todos os produtos.
   * @function
   * @returns {array} Dados dos produtos.
   */
  async getAll() {
    let products = await produto.filter((a) => a.ativo != false);
    return products.map((el) => {
      return {
        id: el.id,
        identificador: el.identificador,
        valor: Moeda.create(el.valor).mount(),
        quantidade: 1,
      };
    });
  }

  /**Função responsável por criar uma nova troca.
   * @function
   * @param {string} clientHash Hash do cliente
   * @param {object} data Dados da nova troca
   * @param {Date} receivingDate Data de recebimento para o novo agendamento de troca
   */
  async createExchange(clientHash, data, receivingDate) {
    try {
      data.shouldSync = true;
      const exchangeHash = await createExchange(
        clientHash,
        data,
        receivingDate
      );
      await success(this.context, "Troca realizada com sucesso!");
      await eventEmitter.notifyAll("exchangeCreated", clientHash);
      return exchangeHash;
    } catch (error) {
      if (!error instanceof BusinessError) {
        new AppStorageError({
          message: error.message,
          title: "Falha ao realizar troca!",
          type: "error",
          method: "createExchange",
        });
      }
      throw error;
    }
  }

  /**Função responsável por buscar os dados do cliente pela hash.
   * @function
   * @param {string} hash Hash do cliente que será feita a troca.
   */
  async getClientByHash(hash) {
    let client = await cliente.findByHash(hash);

    if (client) {
      return client.nome;
    }
  }

  async clientBackgroundSync() {
    try {
      const channel = new MessageChannel();
      const dbVersion = parseFloat(window.DATABASE.verno) * 10;
      const user = await getUser();
      const route = await window.LocalStorage.getItem("main-route");

      const options = {
        dbVersion,
        routeId: route.id,
        baseUrl: user.service,
        token: user.sessao.token,
      };

      channel.port1.onmessage = (event) => {
        if (["success", "error"].includes(event.data.status)) {
          SyncNotificationCenter.instance.notifyAll("activeSync", ["Cliente"]);
        }
        SyncNotificationCenter.instance.notifyAll(
          "client-background-sync",
          event.data
        );
      };

      WorkerDownloadClients(options, channel.port2);
    } catch (error) {
      console.error(error);
      //Não lança erro pra quem o chama, somente exibe na tela que hove problemas
    }
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <ExchangeContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </ExchangeContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { ExchangeContext, ExchangeContextProvider };
