import React from "react";
import { withStyles, Grid, Typography, Container } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroller";
import SeachBar from "../SearchBar/SearchBarWithFilter";
import { DailyListContext } from "../../contexts/DailyListContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "./ClientCardWraper";
import NeighborhoodCard from "../CardComponents/CardNeighborhood/DailyListCard";

const styles = (theme) => ({
  totalClients: {
    borderBottom: "1px solid #A9A9A9",
    paddingTop: "10px",
  },
  textTotalClients: {
    fontSize: ".9rem",
    fontFamily: "Roboto, sans-serif",
  },
  containerTotalClients: {
    paddingBottom: "20px",
  },
  neighborhoodDiv: {
    fontSize: "1.4rem",
    padding: "7px 0 10px 0",
    fontWeight: 500,
  },
});
const BLOCK_SIZE = 10;

/**
 * Componente responsável por renderizar uma lista de cards com infinite scroll.
 */
class ListClients extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {ref} this.inputTakeImage Referência para o input de tirar uma imagems
   * @property {ref} this.inputUploadImage  Referência para o input fazer upload de uma imagem
   */
  constructor(props) {
    super(props);

    this.state = {
      clients: [],
      hasMore: false,
      total: 0,
      orderedClients: [],
    };
    this.nextStep = 0;

    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.getInput = this.getInput.bind(this);
  }

  onFetchBlockComplete(data) {
    const newData = this.state.clients.concat(data.slice);
    if (data.step == this.nextStep) {
      this.nextStep++;
      if (data.slice.length < BLOCK_SIZE) {
        this.setState({ hasMore: false, clients: newData, isLoading: false });
        return;
      }
      this.setState({
        clients: newData,
        hasMore: true,
        isLoading: false,
      });
    }
  }

  fetchMoreData(page) {
    this.setState({ hasMore: false, isLoading: true });
    this.context
      .getClientsBlock(this.nextStep, BLOCK_SIZE)
      .then(this.onFetchBlockComplete.bind(this));
  }

  addSkeletons(arr) {
    const skeltonArray = new Array(4).fill(true).map((e, index) => {
      const element = <Card key={`skeleton-${index}`} isLoading={true} />;
      return element;
    });

    arr.push(...skeltonArray);
  }

  componentDidUpdate(oldprops, oldstate) {
    if (this.context.orderedClients != oldstate.orderedClients) {
      if (this.context.total === 0) {
        this.setState({
          hasMore: false,
          total: 0,
          clients: [],
          orderedClients: this.context.orderedClients,
        });
        return;
      }
      this.setState({
        hasMore: true,
        total: this.context.total,
        orderedClients: this.context.orderedClients,
      });
    }
  }

  getInput(data) {
    this.setState({ search: data });
  }

  render() {
    let { classes } = this.props;
    let neighborhoodId;

    const items = this.state.clients.map((Client) => {
      const shouldPrintNeighborhood = neighborhoodId != Client.BairroId;
      neighborhoodId = Client.BairroId;

      return (
        <Container
          key={`${Client.hash}-daily-card`}
          maxWidth="sm"
          style={{ padding: "10px 4vw 0 4vw" }}
        >
          {shouldPrintNeighborhood ? (
            <NeighborhoodCard
              key={`${Client.bairro}-${Client.BairroId}`}
              mainText={Client.bairro}
              secondaryText={`${
                this.context.neighborhoodInfo[Client.BairroId]
              } cliente${
                this.context.neighborhoodInfo[Client.BairroId] > 1 ? "s" : ""
              }`}
            />
          ) : null}
          <Card
            Client={Client}
            onClickRepass={this.context.addClientToRepass}
          />
        </Container>
      );
    });

    if (this.state.hasMore) {
      this.addSkeletons(items);
    }

    return (
      <>
        <SeachBar getInput={this.getInput} />

        <Grid
          container
          justify="center"
          className={classes.containerTotalClients}
        >
          <Grid item xs={11} className={classes.totalClients}>
            <Typography className={classes.textTotalClients}>
              {`Total:${this.context.total} clientes`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.fetchMoreData}
            hasMore={this.state.hasMore}
            style={{ width: "100%" }}
            loader={<LinearProgress key="loader-daily-list" />}
          >
            {items}
          </InfiniteScroll>
        </Grid>
      </>
    );
  }
}

ListClients.contextType = DailyListContext;
export default withStyles(styles)(ListClients);
