import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import { withStyles } from "@material-ui/core";

const styles = (themes) => ({
  dialogContentClass: {},
});

/** Componente que renderiza um único dialog, tendo suas props como principal fonte de dados.
 * Apesar de ter acesso ao contexto que disponibiliza os styles e tipos existentes para diferentes renderizações desse componente */
class MessageDialog extends React.Component {
  /** @param {Object} props.dialogData Objeto que contém todos os dados necessários para a renderização desse MessageDialog (popup)  
    *  @property {array} context.dialogsTypes Array que disponibiliza os styles e tipos existentes para diferentes renderizações desse componente
    *   @param {boolean} [props.dialogData.disableBackdropClick=true] Desabilita clique na área escura da tela (fora do popup)
        @param {boolean} [props.dialogData.openState=true] Atual estado do popup (true = aberto, false = fechado)
        @param {string} [props.dialogData.title=''] Título do popup
        @param {srting} [props.dialogData.maxWidth="md"] Max width do popup ('xs', 'sm', 'md', 'lg', 'xl')
        @param {boolean} [props.dialogData.fullWidth=true] Funciona em conjunto com maxWidth. Caso falso, o popup terá seu maxWidth = 'sm'
        @param {string} [props.dialogData.type=null] Severidade do popup. Usado apensas para renderizar o style e ícone correto. 
        @param {boolean} [props.dialogData.noIcon=false] Caso true, nenhum ícone aparecerá ao lado do título
        @param {string} [props.dialogData.message=''] Mensagem do popup
        @param {boolean} [props.dialogData.reverseActionButtons=true] Reverter a direção dos action buttons (Cancelar e Ok)
        @param {string} [props.dialogData.okText="OK"] Texto do botão de confirmação
        @param {boolean} [props.dialogData.hasCloseButton=true] Renderiza o botão de cancelamento
        @param {string} [props.dialogData.cancelText="Cancelar"] Texto do botão de cancelamento
        @param {func} [props.dialogData.handleConfirm=context.popDialog] Função ao confirmar o popup
        @param {func} [props.dialogData.handleClose=context.popDialog] Função ao cancelar o popup
    */
  constructor(props) {
    super(props);
  }

  render() {
    let context = this.context;
    let { classes } = this.props.dialogData;

    if (!classes) classes = {};
    return (
      <Dialog
        fullWidth={this.props.dialogData.fullWidth}
        maxWidth={this.props.dialogData.maxWidth}
        disableBackdropClick={this.props.dialogData.disableBackdropClick}
        open={this.props.dialogData.openState}
        onClose={this.props.dialogData.handleClose}
        aria-labelledby="message-dialog-title"
        aria-describedby="message-dialog-description"
      >
        {this.props.dialogData.title && (
          <DialogTitle
            id="message-dialog-title"
            style={
              this.props.dialogData.type && {
                backgroundColor:
                  context.dialogsTypes[this.props.dialogData.type]
                    .backgroundColor,
                color: context.dialogsTypes[this.props.dialogData.type].color,
              }
            }
          >
            {!this.props.dialogData.noIcon &&
              this.props.dialogData.type &&
              context.dialogsTypes[this.props.dialogData.type].icon}
            {!this.props.dialogData.noIcon && this.props.dialogData.type && (
              <span>&nbsp;</span>
            )}

            {this.props.dialogData.title}
          </DialogTitle>
        )}
        <DialogContent className={classes.dialogContentClass}>
          {typeof this.props.dialogData.message === "object" ? (
            this.props.dialogData.message
          ) : (
            <DialogContentText id="message-dialog-description">
              {this.props.dialogData.message}
            </DialogContentText>
          )}
        </DialogContent>

        {this.props.dialogData.dialogActions ? (
          this.props.dialogData.dialogActions
        ) : (
          <DialogActions
            style={
              this.props.dialogData.reverseActionButtons && {
                flexDirection: "row-reverse",
                justifyContent: "flex-start",
              }
            }
          >
            <Button
              onClick={this.props.dialogData.handleConfirm}
              color="primary"
              autoFocus
            >
              {this.props.dialogData.okText || "OK"}
            </Button>
            {this.props.dialogData.hasCloseButton && (
              <Button
                onClick={this.props.dialogData.handleClose}
                color="primary"
              >
                {this.props.dialogData.cancelText || "FECHAR"}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

MessageDialog.contextType = MessageDialogContext;

export default withStyles(styles)(MessageDialog);
