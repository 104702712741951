import React from "react";
import {
  ListItem,
  IconButton,
  Paper,
  Collapse,
  ListItemIcon,
  ListItemText,
  Grid,
  Button,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

const getStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  button: {
    fontSize: ".6rem",
  },
  spacing: {
    margin: theme.spacing(2, 1),
  },

  containerCollapsable: { padding: theme.spacing(2) },
}));

function CardDays({
  icon,
  mainText,
  secondaryText,
  onClickFechamento,
  onClickTroca,
  onExpand,
  expanded,
  index,
}) {
  const classes = getStyles();

  const handleExpandClick = React.useCallback(
    function () {
      onExpand(index);
    },
    [onExpand, index]
  );

  const cardAction = (
    <IconButton
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
      onClick={handleExpandClick}
      aria-expanded={expanded}
      aria-label="Ver mais"
    >
      <ExpandMoreIcon />
    </IconButton>
  );

  return (
    <li className={classes.spacing}>
      <Paper elevation={2}>
        <Grid container>
          <Grid item xs={12}>
            <ListItem>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={mainText} secondary={secondaryText} />
              <ListItemSecondaryAction>{cardAction}</ListItemSecondaryAction>
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Grid
                container
                className={classes.containerCollapsable}
                justify="space-evenly"
                alignItems="center"
              >
                <Button
                  className={classes.button}
                  onClick={onClickFechamento}
                  color="primary"
                >
                  Relatório fechamento
                </Button>
                <Button
                  className={classes.button}
                  onClick={onClickTroca}
                  color="primary"
                >
                  Relatório troca
                </Button>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Paper>
    </li>
  );
}

CardDays.propTypes = {};

export default CardDays;
