import React from "react";
import { withStyles } from "@material-ui/core";
import {
  Grid,
  Typography,
  TextField,
  List,
  ListItem,
  IconButton,
  Switch,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import AddProductsDialog from "../Products/AllItemsDialog";
import { ProductContext } from "../../contexts/ProductsContext";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Moeda from "../../utils/Moeda";

import Drawer from "@material-ui/core/Drawer";

import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from "@material-ui/icons/Edit";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import CloseIcon from "@material-ui/icons/Close";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";

import AmountMask from "../MaskedInputs/AmountMask";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import { getConfigKey } from "../../service/config";

const styles = (theme) => ({
  addProdutctBtn: {
    borderRadius: 0,
    textAlign: "center",
  },
  sectionTitle: {
    fontWeight: 400,
    paddingBottom: "20px",
  },

  inputs: {
    paddingBottom: "20px",
  },
});

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Grid
          container
          alignItems="stretch"
          wrap="nowrap"
          justify="space-between"
        >
          <Grid item xs>
            <Grid
              container
              alignItems="center"
              justify="flex-start"
              direction="row"
              wrap="nowrap"
            >
              <Grid item>
                <Typography>
                  <span
                    style={{
                      fontSize: "1.3rem",
                      color: "#222",
                      fontWeight: 300,
                    }}
                  >
                    {this.props.itemData.quantidade}
                  </span>
                  <span
                    style={{
                      fontSize: "1.3rem",
                      color: "#797979",
                      fontWeight: 300,
                    }}
                  >
                    x
                  </span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    fontWeight: 300,
                    color: "#222",
                    fontSize: "1.1rem",
                    lineHeight: 1,
                    paddingLeft: "10px",
                  }}
                >
                  {this.props.itemData.item.nome ||
                    this.props.itemData.item.identificador ||
                    this.props.itemData.item.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs
            container
            alignItems="center"
            direction="row"
            justify="flex-end"
            wrap="nowrap"
          >
            <Grid item>
              <Typography noWrap>
                {Moeda.create(this.props.itemData.item.valor).format()}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton
                size="small"
                onClick={() =>
                  this.props.onItemDetailsClick(this.props.itemData)
                }
                edge="end"
              >
                <MoreVertIcon style={{ color: "#cecece" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

class ProductsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBitboxEmployee: true,
      filteredProducts: [],
      allProducts: [],
      showAddProductsDialog: false,
      newProduct: {
        salePrice: {
          error: false,
          value: "",
        },
        name: {
          error: false,
          value: "",
        },
        contaNoIdeal: false,
        hash: -1,
      },
    };

    this.toggleAddProductsDialog = this.toggleAddProductsDialog.bind(this);
    this.checkFields = this.checkFields.bind(this);
    this.toggleNewProductDialog = this.toggleNewProductDialog.bind(this);
    this.renderNewProductDialog = this.renderNewProductDialog.bind(this);
    this.onAddItemclick = this.onAddItemclick.bind(this);
    this.onRemoveItemClick = this.onRemoveItemClick.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.onSaveNewProduct = this.onSaveNewProduct.bind(this);
    this.handleToggleItemDetails = this.handleToggleItemDetails.bind(this);
    this.renderItemDetailsDrawer = this.renderItemDetailsDrawer.bind(this);
  }

  componentDidMount() {
    getConfigKey("isBitboxEmployee").then((value) => {
      this.setState({
        isBitboxEmployee: !!value,
      });
    });
  }

  toggleAddProductsDialog() {
    this.setState({ showAddProductsDialog: !this.state.showAddProductsDialog });
  }

  checkFields() {
    let newProductObj = { ...this.state.newProduct };
    let foundErrors = false;
    if (
      this.state.newProduct.name.value == null ||
      this.state.newProduct.name.value == undefined ||
      this.state.newProduct.name.value == ""
    ) {
      newProductObj = {
        ...newProductObj,
        name: {
          ...newProductObj.name,
          error: true,
        },
      };
      foundErrors = true;
    } else {
      newProductObj = {
        ...newProductObj,
        name: {
          ...newProductObj.name,
          error: false,
        },
      };
    }

    if (Moeda.create(newProductObj.salePrice.value).isZero()) {
      newProductObj = {
        ...newProductObj,
        salePrice: {
          ...newProductObj.salePrice,
          error: true,
        },
      };
      foundErrors = true;
    } else {
      newProductObj = {
        ...newProductObj,
        salePrice: {
          ...newProductObj.salePrice,
          error: false,
        },
      };
    }

    this.setState({ newProduct: newProductObj });

    return foundErrors;
  }

  onSaveNewProduct() {
    let formHasErrors = this.checkFields();
    if (!formHasErrors) this.props.onSaveNewProduct(this.state.newProduct);
  }

  toggleNewProductDialog() {
    // Clear data
    this.setState({
      newProduct: {
        salePrice: {
          error: false,
          value: "",
        },
        name: {
          error: false,
          value: "",
        },
        contaNoIdeal: false,
        hash: -1,
      },
    });
    this.props.onToggleNewProductDialog();
  }

  renderNewProductDialog() {
    const classes = { ...this.props };

    let newProductDialog = (
      <Dialog
        // fullWidth={this.props.dialogData.fullWidth}
        maxWidth={"md"}
        disableBackdropClick={true}
        open={this.props.newProductDialogOpenState}
        onClose={this.toggleNewProductDialog}
        aria-labelledby="message-dialog-title"
        aria-describedby="message-dialog-description"
      >
        {
          // this.props.dialogData.title &&
          <DialogTitle id="message-dialog-title">
            Criando novo produto
          </DialogTitle>
        }
        <DialogContent className={classes.dialogContentClass}>
          <Grid container justify="center" className={classes.inputContainer}>
            <Grid item xs={12}>
              <Grid item xs>
                <TextField
                  fullWidth
                  className={classes.inputs}
                  label="Nome do produto"
                  value={this.state.newProduct.name.value}
                  error={this.state.newProduct.name.error}
                  helperText={
                    this.state.newProduct.name.error
                      ? "Preencha o campo de nome!"
                      : null
                  }
                  required
                  onChange={(e) =>
                    this.setState({
                      newProduct: {
                        ...this.state.newProduct,
                        name: {
                          ...this.state.newProduct.name,
                          value: e.target.value,
                        },
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  className={classes.inputs}
                  label="Preço de venda"
                  error={this.state.newProduct.salePrice.error}
                  placeholder="0,00"
                  value={this.state.newProduct.salePrice.value}
                  helperText={
                    this.state.newProduct.salePrice.error
                      ? "Defina o preço de venda"
                      : null
                  }
                  onChange={(e, e2) =>
                    this.setState({
                      newProduct: {
                        ...this.state.newProduct,
                        salePrice: {
                          ...this.state.newProduct.salePrice,
                          value: e2,
                        },
                      },
                    })
                  }
                  required
                  InputProps={{
                    startAdornment: <AttachMoneyIcon color="action" />,
                    inputComponent: AmountMask,
                  }}
                />
              </Grid>
              <Grid item xs>
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  className={classes.inputs}
                >
                  <Typography>Produto conta no ideal</Typography>
                  <Switch
                    checked={this.state.newProduct.contaNoIdeal}
                    onChange={(e) =>
                      this.setState({
                        newProduct: {
                          ...this.state.newProduct,
                          contaNoIdeal: !this.state.newProduct.contaNoIdeal,
                        },
                      })
                    }
                    value="checkedA"
                    color="primary"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* </DialogContentText> */}
        </DialogContent>

        <DialogActions>
          <Button onClick={this.toggleNewProductDialog} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={this.onSaveNewProduct}
            color="primary"
            variant="contained"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    );

    return newProductDialog;
  }

  onAddItemclick(product) {
    this.props.onClickAddProduct(product);
  }

  onRemoveItemClick(product, canDelete = false) {
    this.props.handleDecreaseProductQnt(product, canDelete);
  }

  handleEditItem(product) {
    this.props.handleEditItem(product);
  }

  handleToggleItemDetails(product) {
    this.props.onItemDetailsClick(product);
  }

  renderItemDetailsDrawer() {
    return (
      <Drawer
        anchor="bottom"
        open={this.props.itemDetailsData != null}
        onClose={this.handleToggleItemDetails}
      >
        <Grid
          container
          alignItems="center"
          direction="column"
          style={{ padding: "20px 15px" }}
        >
          <div
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={this.handleToggleItemDetails}
          >
            <CloseIcon />
          </div>
          <Grid item xs={12}>
            <AddShoppingCartIcon
              style={{ fontSize: "5rem", color: "#c6c6c6" }}
            ></AddShoppingCartIcon>
          </Grid>

          <Grid item xs={12}>
            <Typography
              style={{ color: "#555", fontSize: "1.2rem", textAlign: "center" }}
            >
              {this.props.itemDetailsData.item &&
                this.props.itemDetailsData.item.nome}
            </Typography>
            <Typography
              style={{ color: "#888", fontSize: ".9rem", textAlign: "center" }}
            >
              {this.props.itemDetailsData.item &&
                Moeda.create(this.props.itemDetailsData.item.valor).format()}
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ padding: "15px 0 0" }}>
            <Typography>
              Quantidade:
              <Button
                onClick={() =>
                  this.onRemoveItemClick(this.props.itemDetailsData)
                }
                disabled={
                  this.props.itemDetailsData.quantidade > 1 ? false : true
                }
              >
                <RemoveIcon />
              </Button>
              {this.props.itemDetailsData.quantidade}
              <Button
                onClick={() => this.onAddItemclick(this.props.itemDetailsData)}
              >
                <AddIcon />
              </Button>
            </Typography>
          </Grid>

          <Grid
            item
            container
            direction="row"
            xs={12}
            justify="space-evenly"
            alignItems="center"
            style={{ padding: "20px 0" }}
          >
            <Grid item>
              <Button
                startIcon={<RemoveShoppingCartIcon />}
                size="small"
                color="default"
                style={{ color: "#f44336" }}
                onClick={() =>
                  this.onRemoveItemClick(this.props.itemDetailsData, true)
                }
              >
                Remover item
              </Button>
            </Grid>
            {!this.state.isBitboxEmployee && (
              <Grid item>
                <Button
                  startIcon={<EditIcon />}
                  size="small"
                  color="default"
                  style={{ color: "#00acc1" }}
                  onClick={() =>
                    this.handleEditItem(this.props.itemDetailsData)
                  }
                >
                  Editar item
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Drawer>
    );
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.sectionTitle}>
              PRODUTOS
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ padding: "0 0 20px" }}>
            <List>
              {this.props.saleProducts.length ? (
                this.props.saleProducts.map((p, index) => {
                  return (
                    <ListItem key={index} divider>
                      <ProductCard
                        itemData={p}
                        classes={classes}
                        onItemDetailsClick={this.handleToggleItemDetails}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <Typography style={{ color: "#acacac", textAlign: "center" }}>
                  Nenhum produto foi adicionado ainda.
                </Typography>
              )}
            </List>
          </Grid>
          <Grid container>
            <Grid item xs={12} container justify="center">
              <Button
                color="primary"
                variant="contained"
                fullWidth={false}
                className={classes.addProdutctBtn}
                onClick={this.toggleAddProductsDialog}
              >
                Adicionar um produto
              </Button>
            </Grid>
            {}
            <Grid item xs={12} container justify="center">
              <Button
                color="primary"
                variant="text"
                fullWidth={false}
                style={{ marginTop: "10px" }}
                disabled={!this.props.canCreateNew}
                onClick={this.toggleNewProductDialog}
              >
                <AddIcon /> Criar um novo
              </Button>
            </Grid>
          </Grid>

          <AddProductsDialog
            allItems={this.props.allProductsWQ}
            dialogTitle="Todos os produtos"
            loading={this.props.loadingAllProducts}
            canCreateNew={this.props.canCreateNew}
            createNewItemBtnText="novo produto"
            onCreateNewItemClick={this.toggleNewProductDialog}
            onAddItemclick={this.onAddItemclick}
            onRemoveItemClick={this.onRemoveItemClick}
            onClose={(e) => {
              this.setState({ showAddProductsDialog: false });
            }}
            open={this.state.showAddProductsDialog}
          />
        </Grid>

        {this.props.itemDetailsData != null && this.renderItemDetailsDrawer()}

        {this.renderNewProductDialog()}
      </>
    );
  }
}

ProductsSection.contextType = ProductContext;

export default withStyles(styles)(ProductsSection);
