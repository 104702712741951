import React, { createContext } from "react";
import { UnknowError } from "../repository/errorsModels/";
import { Bairro, Cliente, ClienteDiaria } from "../repository/models";
import history from "../router/History";
import { toggleClientDefaulter } from "../service/client";
import {
  calculateOneClientDailyList,
  removeClientFromDailyList,
  updateClientDataOnDailyList,
} from "../service/daily";
import { getAllDailyMap, storeDailyMap } from "../service/daily/index";
import ClientnotificationCenter from "../service/notifications/clientNotificationCenter";
import { eventListenerBuilder } from "../service/notifications/eventListenerBuilder";
import RotaService from "../service/route/index";
import { jumpClient } from "../service/schedulings/index";
import { AbstractContext } from "./AbstractContext";
const rotaService = new RotaService();
const cliente = new Cliente();
const clienteDiaria = new ClienteDiaria();
const bairroModel = new Bairro();

const clientEventEmitter = ClientnotificationCenter.instance;

const DailyListContext = createContext({
  orderedClients: [],
});

const OBSERVED_MODELS = [
  "Cliente",
  "ClienteAgendamento",
  "Bairro",
  "DailyOrder",
];
class DailyListContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.addClientToRepass = this.addClientToRepass.bind(this);
    this.handleToggleVeaco = this.handleToggleVeaco.bind(this);
    this.observable = this.observable.bind(this);
    this.state = {
      total: 0,
      isLoading: true,
      dataCached: false,
      orderedClients: [],
      neighborhoodInfo: {},
      jumpClient: this.jumpClient.bind(this),
      handleToggleVeaco: this.handleToggleVeaco,
      addClientToRepass: this.addClientToRepass.bind(this),
      calculateDailyList: this.calculateDailyList.bind(this),
      updateDailyListOrder: this.updateDailyListOrder.bind(this),
      fetchElementsByNeighborhoodOrder: this.fetchElementsByNeighborhoodOrder,
      updateDailyListNeighborhoodOrder:
        this.updateDailyListNeighborhoodOrder.bind(this),
    };
  }

  async jumpClient(clientHash) {
    try {
      const scheduleType = await jumpClient(clientHash);

      if (!!scheduleType) {
        console.log("Entrou no bloco if. Redirecionando...");
        history.push(
          `/schedulings/create/${clientHash}${
            scheduleType ? `?tipo=${scheduleType}` : ""
          }`
        );
        return;
      }

      await this.onClientChange(null, clientHash);
    } catch (error) {
      console.error("Erro no bloco catch:", error);
      console.error("Mensagem de erro:", error.message);
      console.error("Empilhamento de chamadas:", error.stack);

      throw new UnknowError({
        message:
          "Houve um problema ao recarregar a lista, por favor, tente novamente",
        type: "error",
        method: "jumpClient",
      });
    }
  }

  async componentDidMount() {
    await this.calculateDailyList();
    super.componentDidMount();
    this.unsubscribeClientEvents = clientEventEmitter.subscribe(
      eventListenerBuilder(
        [
          "addReceivement",
          "addSale",
          "exchangeRealized",
          "exchangeCreated",
          "scheduleCreated",
          "scheduleEdit",
          "clientEdit",
          "scheduleDeleted",
        ],
        this.onClientChange.bind(this)
      )
    );
  }

  async addClientToRepass(clientHash) {
    const clientIndexOnList = this.state.orderedClients.findIndex(
      (client) => client.hash == clientHash
    );
    const client = this.state.orderedClients[clientIndexOnList];
    client.isRepass = true;
    const clonedArray = [...this.state.orderedClients];
    clonedArray.splice(clientIndexOnList, 0, client);
    const clonedInfo = { ...this.state.neighborhoodInfo };
    clonedInfo[client.BairroId]--;
    if (clonedInfo[client.BairroId] == 0) {
      delete clonedInfo[client.BairroId];
    }

    await clienteDiaria.put(client, false);
    this.setState({
      orderedClients: clonedArray,
      neighborhoodInfo: clonedInfo,
      total: clonedArray.length,
    });
  }

  async onClientChange(eventNanme, clientHash) {
    if (!clientHash || typeof clientHash !== "string");
    const clientDailyData = await calculateOneClientDailyList(clientHash);

    const clonedDailyList = [...this.state.orderedClients];
    if (clientDailyData.shouldRemoveFromDailyList) {
      const clientIndexOnList = clonedDailyList.findIndex(
        (client) => client.hash == clientHash
      );
      if (clientIndexOnList === -1) {
        return;
      }

      const removedClient = clonedDailyList.splice(clientIndexOnList, 1);
      const clonedNeighborhoodInfo = { ...this.state.neighborhoodInfo };

      clonedNeighborhoodInfo[removedClient[0].BairroId]--;
      if (clonedNeighborhoodInfo[removedClient[0].BairroId] == 0) {
        delete clonedNeighborhoodInfo[removedClient[0].BairroId];
      }
      this.setState({
        orderedClients: clonedDailyList,
        total: this.state.total - 1,
        neighborhoodInfo: clonedNeighborhoodInfo,
      });
      await removeClientFromDailyList(clientHash);
      removedClient[0].removed = true;
      return removedClient[0];
    } else {
      const clientIndexOnList = clonedDailyList.findIndex(
        (client) => client.hash == clientHash
      );
      if (clientIndexOnList === -1) {
        const clonedNeighborhoodInfo = { ...this.state.neighborhoodInfo };
        clonedDailyList.push(clientDailyData);

        if (clonedNeighborhoodInfo[clientDailyData.BairroId]) {
          clonedNeighborhoodInfo[clientDailyData.BairroId]++;
        } else {
          clonedNeighborhoodInfo[clientDailyData.BairroId] = 1;
        }

        clonedDailyList.sort(
          (clientA, clientB) => clientA.position - clientB.position
        );
        this.setState({
          orderedClients: clonedDailyList,
          total: this.state.total + 1,
          neighborhoodInfo: clonedNeighborhoodInfo,
        });
        await updateClientDataOnDailyList(clientDailyData);
        clientDailyData.added = true;
        return clientDailyData;
      }
      const oldClientData = clonedDailyList.splice(
        clientIndexOnList,
        1,
        clientDailyData
      );
      this.setState({ orderedClients: clonedDailyList });
      oldClientData[0].removed = false;
      await updateClientDataOnDailyList(clientDailyData);
    }
  }

  async updateDailyListNeighborhoodOrder(neighborhoods) {
    const shouldLog = false;
    await Promise.all(neighborhoods.map((b) => bairroModel.put(b, shouldLog)));
    const shouldForce = true,
      shouldSaveDailyMap = true,
      isNeighborhoodOrder = true;
    await this.calculateDailyList(
      shouldForce,
      shouldSaveDailyMap,
      isNeighborhoodOrder
    );
  }

  async updateDailyListOrder(updatedClients) {
    const indexOfClientInNeighborhood = this.state.orderedClients.findIndex(
      (client) => client.BairroId == updatedClients[0].BairroId
    );
    const clonedArray = [...this.state.orderedClients];
    clonedArray.splice(
      indexOfClientInNeighborhood,
      updatedClients.length,
      ...updatedClients
    );

    this.setState({ orderedClients: clonedArray });
    await Promise.all(
      updatedClients.map((client) => clienteDiaria.put(client, false))
    );
    await storeDailyMap(clonedArray);
  }

  componentWillUnmount() {
    this.unsubscribe();
    rotaService
      .routeOpening()
      .then((routeOpening) => this.setState({ routeIsOpen: !!routeOpening }));
  }

  async calculateDailyList(
    forceRecalculate = false,
    shouldSaveDailyMap,
    isNeighborhoodOrder = false
  ) {
    this.setState({ isLoading: true });
    const routeOpening = await rotaService.routeOpening();
    if (!routeOpening) {
      this.setState({
        isLoading: false,
        total: 0,
        orderedClients: [],
        neighborhoodInfo: {},
        routeIsOpen: false,
      });
      return;
    }
    const info = await getAllDailyMap(
      routeOpening,
      forceRecalculate,
      shouldSaveDailyMap,
      isNeighborhoodOrder
    );

    this.setState({
      isLoading: false,
      dataCached: true,
      total: info.total,
      neighborhoodInfo: info.neighborhoodCount,
      orderedClients: info.orderedClients,
      routeIsOpen: true,
    });
  }

  async handleToggleVeaco(hash) {
    let client = await cliente.findByHash(hash);
    if (client) {
      await toggleClientDefaulter(hash);
      this.onClientChange(null, hash);
    }
  }

  render() {
    return (
      <DailyListContext.Provider value={this.state}>
        {typeof this.props.children === "function"
          ? this.props.children()
          : this.props.children}
      </DailyListContext.Provider>
    );
  }

  async observable(modelName, helperData) {
    await this.calculateDailyList(true);
  }
}

export { DailyListContext, DailyListContextProvider };
