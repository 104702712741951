import React from "react";
import Header from "../HeaderForAdd/Header";
import CloseIcon from "@material-ui/icons/Close";

import moment from "moment";
import {
  Divider,
  TextField,
  Grid,
  Typography,
  withStyles,
  List,
  ListItem,
  Paper,
} from "@material-ui/core";
import GreenButton from "../Buttons/GreenButton";
import history from "../../router/History";
import Slide from "@material-ui/core/Slide";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Moeda from "../../utils/Moeda";
import { OpenRouteContext } from "../../contexts/OpenRouteContext";
import PictureGetter from "../Client/PictureGetter";
import { verifyClientShouldUpload } from "../../utils/verifyClientShouldUpload";

const styles = (theme) => ({
  main_details: {
    padding: "20px 0 20px",
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
    },
    "& > div": {
      paddingTop: "5px",
    },
    "& > div > svg": {
      width: "45px",
      height: "45px",
    },
  },
  button: {
    paddingTop: "50px",
    paddingBottom: "30px",
    "& .MuiTypography-body1": {
      fontFamily: "Roboto, sans-serif",
    },
  },

  clientPaperClass: {
    padding: "10px 10px 10px 15px",
    marginBottom: "10px",
    // borderLeft: '2px solid #4e76e3',
  },

  clientName: {
    fontSize: "0.85rem",
    fontWeight: "600",
    lineHeight: "1.30",
    marginBottom: "5px",
  },
  clientAddress: {
    color: "#a1a1a1",
    fontSize: "0.8rem",
  },
  clientReference: {
    color: "#a1a1a1",
    fontSize: "0.8rem",
  },

  receivementClientCard: {
    "& .movPrice": {
      textAlign: "right",
      fontSize: ".9rem",
      fontWeight: "600",
      color: "#21a94f",
      marginBottom: 0,
    },
    "& .movPrice_text": {
      textAlign: "right",
      fontSize: ".8rem",
      fontWeight: "400",
      color: "#777",
    },
  },
  saleClientCard: {
    "& .movPrice": {
      textAlign: "right",
      fontSize: ".9rem",
      fontWeight: "600",
      color: "#f3c631",
      marginBottom: 0,
    },
    "& .movPrice_text": {
      textAlign: "right",
      fontSize: ".8rem",
      fontWeight: "400",
      color: "#777",
    },
  },
});

class MovementDetails extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {function} props.provideNextScene Função responsável por prover para a próxima cena.
   * @param {string} props.slideDirection Direção em que o slide irá se movimentar.
   */
  constructor() {
    super();
    this.state = {
      routeData: {
        sales: [],
        saleValue: [],
        saleClients: [],
        receivements: [],
        receivementValue: [],
        receivementClients: [],
      },
      isLoading: true,
    };
    this.getRouteData = this.getRouteData.bind(this);
    this.nextScene = this.nextScene.bind(this);
    this.getFontColor = this.getFontColor.bind(this);
  }
  async componentDidMount() {
    await this.getRouteData();
    const updatedClients = await verifyClientShouldUpload(
      window.DATABASE.verno * 10
    );
  }

  /**Função responsável por pegar os dados da rota.
   * @function
   */
  async getRouteData() {
    try {
      let data = await this.context.getCloseRouteData();
      this.setState({ routeData: data, isLoading: false });
    } catch (error) {
      console.log(error);
      history.push("/");
    }
  }

  nextScene() {
    try {
      this.props.provideNextScene({ slideDirection: "left" });
    } catch (e) {
      console.log(e);
      history.push("/");
    }
    this.props.provideNextScene({ slideDirection: "left" });
  }

  getFontColor(mov) {
    if (this.state.routeData.receivementClients.find(mov)) {
      return "#21a94f";
    } else {
      return "#f3c631";
    }
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Slide
          direction={this.props.slideDirection}
          in={true}
          mountOnEnter
          unmountOnExit
        >
          <Grid container>
            <Header
              link="/"
              icon={<CloseIcon fontSize="large" />}
              name={moment(new Date()).format("DD/MM/YYYY H:mm")}
            >
              Fechar Rota
            </Header>

            <Divider style={{ width: "100%" }} />

            <Grid item xs={12} style={{ padding: "0 10px" }}>
              <Grid container className={classes.main_details}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Typography display="inline">
                    MOVIMENTAÇÕES DESSA ABERTURA
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: 30 }}>
                <Grid item xs={12} container alignItems="center">
                  <i
                    style={{ color: "#21a94f" }}
                    className="fas fa-money-bill-wave fa-2x"
                  />
                  &nbsp;&nbsp;
                  <Typography display="inline" style={{ fontSize: "0.9rem" }}>
                    RECEBIMENTOS
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="filled"
                    label="Qnt de recebimentos"
                    value={
                      (this.state.routeData &&
                        this.state.routeData.receivementCount) ||
                      "0"
                    }
                    InputLabelProps={{ shrink: true }}
                    disabled
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="filled"
                    label="Valor de recebimentos"
                    InputLabelProps={{ shrink: true }}
                    value={
                      (this.state.routeData &&
                        this.state.routeData.receivementValue) ||
                      "0"
                    }
                    disabled
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {this.state.isLoading && (
                    <Grid
                      item
                      container
                      justify="center"
                      style={{ padding: "15px 0" }}
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                  <List>
                    {this.state.routeData.receivementClients.map(
                      (mov, index) => {
                        return (
                          <ListItem
                            key={index}
                            style={{ padding: 0 }}
                            className={classes.receivementClientCard}
                          >
                            <Paper
                              className={classes.clientPaperClass}
                              elevation={2}
                              style={{ width: "100%", position: "relative" }}
                            >
                              <Grid item xs={12} container alignItems="center">
                                <Grid item xs={2}>
                                  <PictureGetter
                                    id={mov && mov.client && mov.client.id}
                                  >
                                    {(picture) => (
                                      <Avatar
                                        className={classes.avatar}
                                        src={picture}
                                      ></Avatar>
                                    )}
                                  </PictureGetter>
                                </Grid>
                                <Grid item xs={7}>
                                  <Typography className={classes.clientName}>
                                    {mov.client.nome}
                                  </Typography>
                                  <Typography className={classes.clientAddress}>
                                    {mov.client.logradouro},{mov.client.numero},
                                    {mov.client.bairro}, {mov.client.cidade},
                                    {mov.client.estado}
                                  </Typography>
                                  <Typography
                                    className={classes.clientReference}
                                  >
                                    {mov.client.reference}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography className={"movPrice"}>
                                    {Moeda.create(mov.valor).format()}
                                  </Typography>
                                  <Typography className={"movPrice_text"}>
                                    {mov.meioPagamento}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Paper>
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginBottom: 30 }}>
                <Grid item xs={12} container alignItems="center">
                  <i
                    style={{ color: "#f3c631" }}
                    className="fas fa-shopping-cart fa-2x"
                  />
                  &nbsp;&nbsp;
                  <Typography display="inline" style={{ fontSize: "0.9rem" }}>
                    VENDAS
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="filled"
                    label="Qnt de vendas"
                    value={
                      (this.state.routeData &&
                        this.state.routeData.saleCount) ||
                      "0"
                    }
                    InputLabelProps={{ shrink: true }}
                    disabled
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="filled"
                    label="Valor de vendas"
                    InputLabelProps={{ shrink: true }}
                    value={
                      (this.state.routeData &&
                        this.state.routeData.saleValue) ||
                      "0"
                    }
                    disabled
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {this.state.isLoading && (
                    <Grid
                      item
                      container
                      justify="center"
                      style={{ padding: "15px 0" }}
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                  <List>
                    {this.state.routeData.saleClients
                      .filter((mov) => mov && mov.client)
                      .map((mov, index) => {
                        return (
                          <ListItem
                            key={index}
                            style={{ padding: 0 }}
                            className={classes.saleClientCard}
                          >
                            <Paper
                              className={classes.clientPaperClass}
                              elevation={2}
                              style={{ width: "100%", position: "relative" }}
                            >
                              <Grid item xs={12} container alignItems="center">
                                <Grid item xs={2}>
                                  <PictureGetter
                                    id={mov && mov.client && mov.client.id}
                                  >
                                    {(picture) => (
                                      <Avatar
                                        className={classes.avatar}
                                        src={picture}
                                      ></Avatar>
                                    )}
                                  </PictureGetter>
                                </Grid>
                                <Grid item xs={7}>
                                  <Typography className={classes.clientName}>
                                    {mov && mov.client && mov.client.nome}
                                  </Typography>
                                  <Typography className={classes.clientAddress}>
                                    {mov && mov.client && mov.client.logradouro}
                                    ,{mov && mov.client && mov.client.numero},
                                    {mov && mov.client && mov.client.bairro},{" "}
                                    {mov && mov.client && mov.client.cidade},
                                    {mov && mov.client && mov.client.estado}
                                  </Typography>
                                  <Typography
                                    className={classes.clientReference}
                                  >
                                    {mov && mov.client && mov.client.reference}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography className={"movPrice"}>
                                    {Moeda.create(mov.valor).format()}
                                  </Typography>
                                  <Typography className={"movPrice_text"}>
                                    {mov.formaPagamento}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Paper>
                          </ListItem>
                        );
                      })}
                  </List>
                </Grid>
              </Grid>

              <Grid item xs className={classes.button}>
                <GreenButton onClickButton={this.nextScene}>
                  {/* <Typography> */}
                  PRÓXIMO
                  {/* </Typography> */}
                </GreenButton>
              </Grid>
            </Grid>
          </Grid>
        </Slide>
      </>
    );
  }
}
MovementDetails.contextType = OpenRouteContext;
export default withStyles(styles)(MovementDetails);
