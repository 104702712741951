import React from "react";
import { Typography, withStyles, Grid } from "@material-ui/core";
import Expansion from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({});
/**
 * Componente que renderiza o ExopansionPanel do MaterialUi
 */
class ExpansionPanel extends React.Component {
  /**
   * @param {string} props.expanded Prop que indica qual Panel foi clicado
   * @param {string} props.reference Prop indica o Panel do componente
   * @param {function} props.handleChange Função que altera o estado de exibição do drawer
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {icon} props.iconTitle Prop que tem como valor um Ícone
   * @param {string} props.children Prop que tem como valor o texto que ficará dentro do panel
   */
  render() {
    let { classes } = this.props;
    const invalidColor = "#ff0000";
    const validColor = "#3c82f1";

    return (
      <>
        {!this.props.custom ? (
          <Expansion
            expanded={this.props.expanded}
            onChange={() =>
              this.props.handleChange(this.props.reference, this.props.expanded)
            }
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon fontSize="large" />}
            >
              {this.props.iconTitle}
              &nbsp;
              <Typography className={classes.heading}>
                {this.props.title}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{this.props.children}</ExpansionPanelDetails>
          </Expansion>
        ) : (
          <Expansion
            expanded={this.props.expanded}
            onChange={() => this.props.handleChange(this.props.expanded)}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon fontSize="large" />}
            >
              {this.props.iconTitle}
              &nbsp;
              <Typography className={classes.heading}>
                {this.props.title}{" "}
                <span
                  style={{
                    color: this.props.isValid ? validColor : invalidColor,
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {this.props.expensesCount != 0 && (
                    <>&nbsp;{this.props.expensesCount}</>
                  )}
                </span>
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ flexDirection: "column" }}>
              {this.props.children}
              <Grid container justify="center" style={{ marginTop: 15 }}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={this.props.disableAddBtn}
                    onClick={this.props.onAddExpense}
                  >
                    <AddIcon /> despesa
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </Expansion>
        )}
      </>
    );
  }
}
Expansion.propTypes = {
  iconTitle: PropTypes.object,
};
export default withStyles(styles)(ExpansionPanel);
