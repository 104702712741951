import React from "react";
import Header from "../HeaderForAdd/Header";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import moment from "moment";
import { Divider, Grid, Typography, withStyles } from "@material-ui/core";
import Expenses from "../../pages/Expenses/index";
import BackNextButton from "../Buttons/BackNextButton";
import Slide from "@material-ui/core/Slide";

const styles = (theme) => ({
  buttons: {
    position: "fixed",
    bottom: "20px",
    backgroundColor: "none",
  },
  body: {
    paddingBottom: "12vh",
  },
  title: {
    paddingBottom: "10px",
    paddingTop: "10px",
    "& .MuiTypography-body1": {
      fontSize: "1.2rem",
      color: "#303013",
    },
  },
});

/**
 * Componente que renderiza a página de adicionar despesas, no fechamento da rota.
 */
class AddExpenses extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {function} props.providePreviousScene Função responsável por voltar para a cena anterior.
   * @param {function} props.provideNextScene Função responsável por prover para a próxima cena.
   * @param {string} props.slideDirection Direção em que o slide irá se movimentar.
   */
  constructor() {
    super();
    this.state = {};
    this.onClickGoBack = this.onClickGoBack.bind(this);
    this.onClickGoNext = this.onClickGoNext.bind(this);
  }

  /**Função responsável por voltar para a cena anterior.
   * @function
   */
  onClickGoBack() {
    this.props.providePreviousScene({ slideDirection: "right" });
  }

  /**Função responsável por prover a próxima cena.
   * @function
   */
  onClickGoNext() {
    this.props.provideNextScene({ slideDirection: "left" });
  }

  render() {
    let { classes } = this.props;

    return (
      <Slide
        direction={this.props.slideDirection}
        in={true}
        mountOnEnter
        unmountOnExit
      >
        <Grid container style={{ minHeight: "100%" }}>
          <Grid item xs={12} className={classes.body}>
            <Header
              func={this.onClickGoBack}
              icon={<ArrowBackIcon fontSize="large" />}
              name={moment(new Date()).format("DD/MM/YYYY H:mm")}
            >
              Fechar Rota
            </Header>

            <Divider />

            <Grid container justify="center">
              <Grid item xs={11} className={classes.title}>
                <Typography>ADICIONAR DESPESAS</Typography>
              </Grid>
            </Grid>
            <Expenses headerIsHidden />
          </Grid>

          <Grid container className={classes.buttons}>
            <BackNextButton
              onClickGoBack={this.onClickGoBack}
              onClickGoNext={this.onClickGoNext}
            />
          </Grid>
        </Grid>
      </Slide>
    );
  }
}
export default withStyles(styles)(AddExpenses);
