import React, { createContext } from "react";
import {
  ClienteAgendamento,
  Cliente,
  ClienteTrocaAvulso,
} from "../repository/models";
import {
  createScheduling,
  editScheduling,
  validateSchedule,
  getSchedulesByTypeAndClient,
} from "../service/schedulings/index";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import BusinessError from "../errorDefinition/BusinessError";
import AppStorageError from "../errorDefinition/AppStorageError";
import ClientnotificationCenter from "../service/notifications/clientNotificationCenter";
import { AbstractContext } from "./AbstractContext";
import LocalStorage from "../utils/localStorage";
import * as syncService from "../service/sync/activeSync";

const localStorage = LocalStorage.instance;
const clienteAgendamento = new ClienteAgendamento();
const clienteTrocaAvulso = new ClienteTrocaAvulso();
const cliente = new Cliente();
const eventEmitter = ClientnotificationCenter.instance;
// const success = (context,message)=>( context.addAsyncDialog({
//   message: message,
//   title: 'Sucesso!',
//   type: 'success',
//   hasCloseButton: false,
// }))

const SchedulingsContext = createContext({
  config: [],
});

const defaultSchedulings = [
  {
    nome: "Recebimento",
    id: "RECEBIMENTO",
  },
  {
    nome: "Troca",
    id: "TROCA",
  },
  {
    nome: "Venda",
    id: "VENDA",
  },
];

const ALLOWED_MODELS = ["schedulesType"];
class SchedulingsContextProvider extends AbstractContext {
  constructor(props) {
    super(props, ALLOWED_MODELS);
    this.observable = this.observable.bind(this);
    this.syncData = this.syncData.bind(this);
    this.fetchSchedulingsHash = this.fetchSchedulingsHash.bind(this);
    this.getClientByHash = this.getClientByHash.bind(this);
    this.getAllClientScheduling = this.getAllClientScheduling.bind(this);
    this.validateScheduleDate = this.validateScheduleDate.bind(this);
    this.getClientNameByHash = this.getClientNameByHash.bind(this);
    this.createScheduling = this.createScheduling.bind(this);
    this.editScheduling = this.editScheduling.bind(this);
    this.deleteScheduling = this.deleteScheduling.bind(this);
    this.getSchedulingsType = this.getSchedulingsType.bind(this);
    this.getClientByhash = this.getClientByhash.bind(this);

    this.state = {
      offset: 0,
      schedulingsHash: [],
      schedulings: [],
      fetchSchedulingsHash: this.fetchSchedulingsHash,
      getClientByHash: this.getClientByHash,
      getClientByName: this.getClientByName,
      getClientNameByHash: this.getClientNameByHash,
      getAllClientScheduling: this.getAllClientScheduling,
      getSchedulingsType: this.getSchedulingsType,
      createScheduling: this.createScheduling,
      editScheduling: this.editScheduling,
      deleteScheduling: this.deleteScheduling,
      validateScheduleDate: this.validateScheduleDate,
      scheduleTypes: defaultSchedulings,
      schedulingCount: {
        troca: 0,
        venda: 0,
        recebimento: 0,
      },
    };
  }
  async componentDidMount() {
    super.componentDidMount();
    this.observable();
  }

  async observable() {
    const scheduleTypes = await localStorage.getItem("scheduleTypes");
    this.setState({ scheduleTypes });
  }

  async syncData() {
    // Check if user is working online, and if so, send expenses.
    let localConfig = await localStorage.getItem("local-config");
    if (
      localConfig &&
      navigator &&
      (!localConfig.OnlineOffline || !navigator.onLine)
    ) {
      return;
    }
    await syncService.sendClients();
    // await syncService.syncClients(false);
    console.log("Clients have been sent");
  }

  /**Função responsável por buscar as hashs dos agendamentos de clientes que estão entre as datas
   * informadas.
   * @function
   * @param {Date} startDate Data inicial da busca.
   * @param {Date} endDate Data fianl da busca.
   * @param {boolean} reset Boleano que caso true limpara o estado que armazena as hashs.
   */
  async fetchSchedulingsHash(startDate, endDate, reset) {
    if (reset) this.setState({ schedulingsHash: [], offset: 0 });
    try {
      clienteAgendamento
        .countSchedulesByInterval(startDate, endDate)
        .then((schedulingCount) => this.setState({ schedulingCount }))
        .catch(console.error);

      let fetchResult = await clienteAgendamento.fetchSchedulingsHash(
        startDate,
        endDate,
        this.state.offset
      );

      fetchResult = this.state.schedulingsHash.concat(fetchResult);
      this.setState({
        schedulingsHash: fetchResult,
        offset: this.state.offset + 10,
      });
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao buscar hashs dos clientes!",
        type: "error",
        method: "fetchSchedulingsHash",
      });
    }
  }

  /**Função responsável por buscar  os dados de um cliente exibidos no card na página de Agendamentos com base na data informada.
   * @function
   * @param {Date} receivementDate Data usada para a busca .
   * @returns {object} Dados do cliente
   */
  async getClientByHash(hash, receivementDate) {
    try {
      return await clienteAgendamento.getClientByHash(hash, receivementDate);
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao buscar dado do cliente!",
        type: "error",
        method: "getClientByHash",
      });
    }
  }

  /**Função responsável por buscar  todos os agendamentos de um determinado cliente.
   * @function
   * @param {String} hash Hash do cliente .
   */
  async getAllClientScheduling(hash) {
    try {
      this.setState({
        schedulings: await clienteAgendamento.getAllClientScheduling(hash),
      });
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao buscar todos os agentementos do cliente!",
        type: "error",
        method: "getAllClientScheduling",
      });
    }
  }

  async validateScheduleDate(scheduleDate) {
    if (!scheduleDate) return true;

    const { error, message } = await validateSchedule({
      dataRecebimento: scheduleDate,
    });
    if (error) {
      throw new BusinessError({
        message: message,
        type: "warning",
        title: "Não foi possível criar este agendamento!",
        method: "ScheduligsContextProvide.validateScheduleDate",
      });
    }
    return;
  }

  /**Função responsável por o nome do cliente pela hash.
   * @function
   * @param {string} hash Hash do cliente .
   * @returns {string} Nome do cliente
   */
  async getClientNameByHash(hash) {
    let client = await cliente.findByHash(hash);
    if (client != undefined) return client.nome;
  }

  /**Função responsável por criar um novo agendamento.
   * @function
   *
   * @param {string} clientHash Hash do cliente .
   * @param {string} hash Dado usado para a criação do agendamento.
   */
  async createScheduling(clientHash, data) {
    try {
      await this.isRouteOpen();
      data.ativo = true;
      data.shouldSync = true;
      await createScheduling(clientHash, data);
      await this.syncData();
      await eventEmitter.notifyAll("scheduleCreated", clientHash);
    } catch (error) {
      if (!error instanceof BusinessError) {
        new AppStorageError({
          message: error.message,
          title: "Falha ao criar um novo agendamento!",
          type: "error",
          method: "createScheduling",
          error,
        });
      }
    }
  }

  /**Função responsável por atualizar um agendamento existente.
   * @function
   * @param {string} clientHash Hash do cliente .
   * @param {string} schedulingHash Hash do agendamento.
   * @param {object} data Dado usado na atualização do agendamento.
   */
  async editScheduling(clientHash, schedulingHash, data) {
    await this.isRouteOpen();
    await editScheduling(clientHash, schedulingHash, data);

    await this.syncData();
    await eventEmitter.notifyAll("scheduleEdit", clientHash);
  }

  async _handleDeleteExchageSchedule(client) {
    const exchanges = await clienteTrocaAvulso.getAll("ClienteId", client.id);

    const hasOpenedExchange = exchanges.some((el) => !el.dataTrocaRealizada);
    if (hasOpenedExchange) {
      throw new Error(
        "Você deve realizar a(s) trocas para apagar este agendamento"
      );
    }
    const clientSchedules = await getSchedulesByTypeAndClient(
      client.hash,
      "troca"
    );
    await clientSchedules.delete();
  }

  /**Função responsável por atualizar um agendamento existente.
   * @function
   * @param {object} data Dados do agendamento .
   * @param {string} clientHash Hash do cliente.
   */
  async deleteScheduling(data, clientHash) {
    try {
      await this.isRouteOpen();
      const [client] = await cliente.getAll("hash", [clientHash]);
      client.shouldSync = true;

      if (data.tipo.toLocaleLowerCase() === "troca") {
        await this._handleDeleteExchageSchedule(client);
      } else {
        /*if (client.saldoAtual < 0) {
          throw new Error(
            "Não é possível exlcuir o agendamento, pois o cliente tem saldo negativo"
          );
        }*/
        data.ativo = false;
        data.editado = true;
        data.shouldSync = true;
        await clienteAgendamento.put(data);
      }

      await cliente.put(client);
      await this.syncData();
      eventEmitter.notifyAll("scheduleDeleted", clientHash);
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao deletar um agendamento!",
        type: "error",
        method: "deleteScheduling",
      });
    }
  }

  /**Função responsável por buscar o tipo de um determinado agendamento.
   * @function
   * @param {string} hash Hash do cliente.
   * @returns {string} Tipo do agendamento
   */
  async getSchedulingsType(hash) {
    const result = await clienteAgendamento.findByHash(hash);
    return result;
  }

  /**Função responsável por buscar todos os dados de um determinado cliente.
   * @function
   * @param {string} hash Hash do cliente.
   * @returns {Object} Dados do cliente
   */
  async getClientByhash(hash) {
    return await cliente.findByHash(hash);
  }

  render() {
    return (
      <SchedulingsContext.Provider value={this.state}>
        {typeof this.props.children === "function"
          ? this.props.children()
          : this.props.children}
      </SchedulingsContext.Provider>
    );
  }
}

SchedulingsContext.contextType = MessageDialogContext;
export { SchedulingsContext, SchedulingsContextProvider };
