/* eslint-disable eqeqeq */
import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Fab,
  TextField,
  IconButton,
} from "@material-ui/core";
import CardPhone from "../CardComponents/TelefoneInputCard/index";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CpfMask from "../MaskedInputs/CpfMask";
import CepMask from "../MaskedInputs/CepMask";
import CancelOrSave from "../Buttons/CancelOrSave";
import SelectInput from "../Inputs/SelectInput";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AmountMask from "../MaskedInputs/AmountMask";
import { UserContext } from "../../contexts/UserContext";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const styles = (theme) => ({
  helperText: {
    fontSize: ".78rem",
    margin: 2,
  },
  signature: {
    height: "200px",
    border: "1px solid #000",
  },
  text: {
    fontSize: "0.7rem",
  },
  input: {
    paddingTop: "10px",
  },
  backgroundPage: {
    backgroundColor: "#F5F5F5",
    paddingBottom: "15px",
  },
  cardInputs: {
    backgroundColor: "#fff",
    padding: "20px",
    marginTop: "15px",
  },
  cancelOrSave: {
    position: "relative",
  },
  sigImage: {
    backgroundSize: "200px 50px",
    width: "100%",
    height: "100%",
  },
  recomended: {
    color: "#4682B4",
    fontFamily: "Roboto, sans-serif",
  },
  addNeighborhood: {
    height: "100%",
    color: "#1473cc",
    "& :active": {
      color: "#6495ED",
    },
  },
  documentCard: {
    paddingBottom: "5px",
    "& > div": {
      border: "1px solid #696969",
      borderRadius: "8px",
      padding: "5px",
    },
  },

  sectionTitle: {
    fontWeight: 500,
    fontSize: "1.1rem",
    color: "#141414",
    textTransform: "uppercase",
  },
});

/**
 * Conjunto de inputs usados no formulário de cliente
 */
class UserProfile extends React.Component {
  /**
   * @param {string} props.image Imagem de perfil do cliente em BASE64
   * @param {string} props.signatureImage Imagem da assinatura do cliente em BASE64
   * @param {function} props.saveClient Função que salvará no componente pai os dados digitados no input
   * @param {function} props.handleToggleSignature Função que altera a exibição do componente de assinatura
   * @param {boolean} props.redirect Boleano que definirá o redirecionamento da página
   * @param {object} props.classes Classes do CSS
   * @property {string} this.state.nome Estado que armazena o nome digitado
   * @property {string} this.state.cpf Estado que armazena o cpf digitado
   * @property {string} this.state.estadoCivil Estado que armazena o estadoCivil selecionado
   * @property {string} this.state.sexo Estado que armazena o sexo digitado
   * @property {Date} this.state.nascimento Estado que armazena a data de nascimento informada
   * @property {array} this.state.telefone Estado que armazena a lista de telefones
   * @property {string} this.state.cep Estado que armazena o cep digitado
   * @property {string} this.state.estado Estado que armazena o estado selecionado
   * @property {string} this.state.cidade Estado que armazena o cep selecionado
   * @property {string} this.state.bairro Estado que armazena o cep selecionado
   * @property {string} this.state.logradouro Estado que armazena o logradouro digitado
   * @property {string} this.state.numero Estado que armazena o numero digitado
   * @property {array} this.state.documentos Estado que armazena os documentos e suas respectivas imagens
   * @property {string} this.state.referencia Estado que armazena a referencia digitada.
   */
  constructor(props) {
    super(props);
    this.state = {
      nome: { value: "", error: false },
      cpf: { value: "", error: false },
      email: { value: "", error: false },
      userName: { value: "", error: false },
      dataAdimissao: { value: "", error: false },
      valeMes: { value: 0, error: false },
      // estadoCivil:{value:'',error:false},
      // sexo:{value:'',error:false},
      // nascimento:{value:'',error:false},
      telefone: { value: [], error: [] },
      cep: { value: "", error: false },
      estado: { value: "", error: false },
      cidade: { value: "", error: false },
      bairro: { value: "", error: false },
      logradouro: { value: "", error: false },
      numero: { value: "", error: false },
      documentos: { value: [], error: false },
      referencia: { value: "", error: false },
      // countPhoneNumber: [1],
      popUpDocuments: false,
      required: [],
      recomended: [],
      cartaoCredito: { numero: "", validade: "", cvc: "" },
      plano: { value: "Basico", error: false },

      removedDocs: [],
      removedTelephones: [],
      alteredTelephones: [],

      mainDataIsOpen: true,
      addressDataIsOpen: true,
      paymentDataIsOpen: true,

      showVoucherValue: false,
    };

    this.setClientData = this.setClientData.bind(this);
    this.saveInDataBase = this.saveInDataBase.bind(this);
    this.setPhoneNumber = this.setPhoneNumber.bind(this);
    this.onClickAddPhone = this.onClickAddPhone.bind(this);
    this.clickRemovePhoneNumber = this.clickRemovePhoneNumber.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.toggleVoucher = this.toggleVoucher.bind(this);
    this.checkRequiredAndRecomendItems =
      this.checkRequiredAndRecomendItems.bind(this);
    this.controllerRecomended = this.controllerRecomended.bind(this);
    this.countNumberInString = this.countNumberInString.bind(this);
    this.checkFields = this.checkFields.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
  }

  componentDidMount() {
    if (this.props.clientData) {
      this.setClientData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clientData != this.props.clientData) {
      this.setClientData();
    }
  }

  setClientData() {
    let state = { ...this.state };
    let clientData = this.props.clientData;
    state.nome.value = clientData.nome;
    state.cpf.value = clientData.cpf;
    state.email.value = clientData.email || "";
    state.userName.value = clientData.login;
    state.dataAdimissao.value = clientData.dataAdimissao;
    state.valeMes.value = clientData.valeMes;
    // state.estadoCivil.value = clientData.estadoCivil
    // state.sexo.value = clientData.sexo
    // state.nascimento.value = clientData.nascimento
    state.cep.value = clientData.cep;
    state.estado.value = clientData.estado;
    state.documentos.value = clientData.documentos || [];
    state.cidade.value = clientData.cidade;
    state.bairro.value = clientData.bairro;
    state.logradouro.value = clientData.logradouro;
    state.numero.value = clientData.numero;
    state.referencia.value = clientData.referencia;
    // state.countPhoneNumber = clientData.telefone.length ? [clientData.telefone] : [1]

    // if(clientData.telefone.length && clientData.telefone.map){
    //     state.countPhoneNumber = [...clientData.telefone]
    // } else {
    //     state.countPhoneNumber = [clientData.telefone];
    // }

    if (typeof clientData.telefone == "object") {
      state.telefone.value = clientData.telefone;
    } else {
      state.telefone.value = [clientData.telefone];
    }

    this.setState({ state });
    // this.setState({ countPhoneNumber: state.countPhoneNumber })
  }

  /**Função que envia um objeto com os dados digitados no formulário para o elemento pai, aonde será salvo no bancoe redirecionado em seguida
   * @function
   * @param {string} number número a ser adicionado.
   */
  async saveInDataBase() {
    let clientData = {
      ...this.props.clientData,
      nome: this.state.nome.value,
      cpf: this.state.cpf.value,
      email: this.state.email.value,
      cep: this.state.cep.value,
      estado: this.state.estado.value,
      cidade: this.state.cidade.value,
      logradouro: this.state.logradouro.value,
      numero: this.state.numero.value,
      documentos: this.state.documentos.value,
      referencia: this.state.referencia.value,
      foto: this.props.image,
      assinatura: this.props.signatureImage,
      telefone: this.state.telefone.value,
      bairro: this.state.bairro.value,
    };
    await this.props.saveClient(clientData);
  }

  /**Função que salva os numeros de telefones adicionados
   * @function
   * @param {string} number número a ser adicionado.
   * @param {number} index índice da lista em que será adicionado o número.
   */
  setPhoneNumber(number, index) {
    let telefone = { ...this.state.telefone };
    let alteredTelephones = [...this.state.alteredTelephones];
    let i = null;

    if (telefone.value[index] && telefone.value[index].id) {
      let hasBeenAltered = alteredTelephones.find((tel, index) => {
        if (tel.id == telefone.value[index].id) {
          i = index;
          return true;
        }
        return false;
      });
      if (hasBeenAltered) {
        alteredTelephones[i].numero = number;
      } else {
        alteredTelephones.push(telefone.value[index]);
      }
      telefone.value[index].numero = number;
    } else {
      telefone.value[index] = number;
    }
    this.setState({ telefone: telefone, alteredTelephones });
  }

  /**Função que renderiza mais um campo para adicionar novo número de telefone
   * @function
   */
  onClickAddPhone(e) {
    let index = this.state.telefone.value.length - 1;
    if (
      this.state.telefone.value[index] != null &&
      this.state.telefone.value[index] != ""
    ) {
      this.setState({
        telefone: {
          ...this.state.telefone,
          value: [...this.state.telefone.value, ""],
          error: [...this.state.telefone.error, false],
        },
      });
    } else if (index == -1) {
      this.setState({ telefone: [""] });
    }
  }

  /**Função que remove um numero de telefone selecionado da lista de telefones
   * @function
   * @param {number} index índice da lista em que será removido o telefone.
   */
  clickRemovePhoneNumber(index) {
    let removedTelephones = [...this.state.removedTelephones];
    let telefone = { ...this.state.telefone };

    let removedTel = telefone.value.splice(index, 1);
    if (typeof removedTel[0] != "object") {
      removedTel = [];
    }

    removedTelephones = removedTelephones.concat(removedTel);

    this.setState({
      telefone,
      removedTelephones,
    });
  }

  handleChangeInput(stateName, value) {
    let state = { ...this.state };
    state[stateName].value = value;
    this.setState({ state });
  }

  toggleVoucher() {
    this.setState({ showVoucherValue: !this.state.showVoucherValue });
  }

  /**
   * Checa se um campo é recomentado ou obrigatório, fazendo  uma busca sequencial pela array passada
   *
   * @example
   * checkRequiredAndRecomendItems (array,name){
   *   let result = false
   *   array.forEach(el=>{
   *       if(el.attributeName == name)
   *           result = true
   *   })
   *   return result
   *}
   */
  checkRequiredAndRecomendItems(array, name) {
    let result = false;
    array.forEach((el) => {
      if (el.attributeName == name) result = true;
    });
    return result;
  }

  /**Função onde é feito o controle da customizaçãod dos campos recomendados
   * Importante: Se ocorrer um erro em algum campo, a customização será cancelada e o erro permanescerá
   * @function
   * @param {array} array lista .
   * @param {string} name nome do campo a ser verificado.
   * @param {boolean} error boleano que indica se ocorreu algum erro na validação.
   */
  controllerRecomended(array, name, error) {
    if (this.checkRequiredAndRecomendItems(array, name) && error == false)
      return true;
    else return false;
  }

  /**Função onde é feito a contagem de numeros em um campo, por exemplo o campo de CPF
   * @function
   * @param {string} data String a passar pela contagem .
   * @param {number} length Número mínimo de numeros na string.
   * @returns {boolean} Retorna true ou false, true se o numero de numéros for igual ou maior doque o parametro length passado
   * e false se for menor
   */
  countNumberInString(data, length) {
    if (data != "" && data != undefined) {
      let string = data.split("");
      let i = 0,
        j = 0;
      string.forEach((el) => {
        for (let cont = 0; cont < 10; cont++) {
          if (parseInt(el) == cont) i += 1;
          else j += 1;
        }
      });
      if (i < length) {
        if (i == 0 && j == 0) return true;
        else return false;
      } else return true;
    }
  }

  /**Função que verifica se os campos de CPF,CEP E Telefone que não forem vázios estão preenchidos corretamente,
   * @function
   * @returns {boolean} Retorna true ou false, true se não ocorrer erro na validação e false se occorrer
   */
  checkFields() {
    let check = true;
    if (this.state.cpf.value != "" && this.state.cpf.value != undefined) {
      if (!this.countNumberInString(this.state.cpf.value, 11)) {
        this.setState({ cpf: { ...this.state.cpf, error: true } });
        check = false;
      } else this.setState({ cpf: { ...this.state.cpf, error: false } });
    }

    if (
      this.state.telefone.value != "" &&
      this.state.telefone.value != undefined
    ) {
      this.state.telefone.value.forEach((el, index) => {
        if (!this.countNumberInString(el, 11)) {
          let telefone = { ...this.state.telefone };
          telefone.error[index] = true;
          this.setState({ telefone: telefone });
          check = false;
        } else {
          let telefone = { ...this.state.telefone };
          telefone.error[index] = false;
          this.setState({ telefone: telefone });
        }
      });
    }

    if (this.state.cep.value != "" && this.state.cep.value != undefined) {
      if (!this.countNumberInString(this.state.cep.value, 8)) {
        this.setState({ cep: { ...this.state.cep, error: true } });
        check = false;
      } else this.setState({ cep: { ...this.state.cep, error: false } });
    }

    if (this.state.email.value != "" && this.state.email.value != undefined) {
      if (
        !this.state.email.value.toLowerCase().includes("@") ||
        !this.state.email.value.toLowerCase().includes(".com")
      ) {
        this.setState({ email: { ...this.state.email, error: true } });
        check = false;
      }
    } else this.setState({ email: { ...this.state.email, error: false } });

    return check;
  }

  /**Função que verifica todos os campos obrigatórios estão preenchidos corretamente.
   * @function
   */
  async checkRequiredFields() {
    let check = this.checkFields();

    if (
      this.checkRequiredAndRecomendItems(
        this.props.context.requiredFields,
        "nome"
      )
    ) {
      if (this.state.nome.value == "" || this.state.nome.value == undefined) {
        this.setState({ nome: { ...this.state.nome, error: true } });
        check = false;
      } else this.setState({ nome: { ...this.state.nome, error: false } });
    }

    if (
      this.checkRequiredAndRecomendItems(
        this.props.context.requiredFields,
        "cpf"
      )
    ) {
      if (
        this.state.cpf.value == "" ||
        this.state.cpf.value == undefined ||
        !this.countNumberInString(this.state.cpf.value, 11)
      ) {
        this.setState({ cpf: { ...this.state.cpf, error: true } });
        check = false;
      } else this.setState({ cpf: { ...this.state.cpf, error: false } });
    }

    if (
      this.checkRequiredAndRecomendItems(
        this.props.context.requiredFields,
        "email"
      )
    ) {
      if (
        this.state.email.value == "" ||
        this.state.email.value == undefined ||
        !this.state.email.value.toLowerCase().includes("@") ||
        !this.state.email.value.toLowerCase().includes(".com")
      ) {
        this.setState({ email: { ...this.state.email, error: true } });
        check = false;
      } else this.setState({ email: { ...this.state.email, error: false } });
    }
    if (
      this.checkRequiredAndRecomendItems(
        this.props.context.requiredFields,
        "telefone"
      )
    ) {
      if (this.state.telefone.value.length > 0) {
        this.state.telefone.value.forEach((el, index) => {
          if (
            el == "" ||
            el == undefined ||
            !this.countNumberInString(el, 11)
          ) {
            let telefone = { ...this.state.telefone };
            telefone.error[index] = true;
            this.setState({ telefone: telefone });
            check = false;
          } else {
            let telefone = { ...this.state.telefone };
            telefone.error[index] = false;
            this.setState({ telefone: telefone });
          }
        });
      } else {
        let telefone = { ...this.state.telefone };
        telefone.error[0] = true;
        this.setState({ telefone: telefone });
        check = false;
      }
    }

    if (
      this.checkRequiredAndRecomendItems(
        this.props.context.requiredFields,
        "cep"
      )
    ) {
      if (
        this.state.cep.value == "" ||
        this.state.cep.value == undefined ||
        !this.countNumberInString(this.state.cep.value, 8)
      ) {
        this.setState({ cep: { ...this.state.cep, error: true } });
        check = false;
      } else {
        this.setState({ cep: { ...this.state.cep, error: false } });
      }
    }

    if (
      this.checkRequiredAndRecomendItems(
        this.props.context.requiredFields,
        "estado"
      )
    ) {
      if (
        this.state.estado.value == "" ||
        this.state.estado.value == undefined
      ) {
        this.setState({ estado: { ...this.state.estado, error: true } });
        check = false;
      } else this.setState({ estado: { ...this.state.estado, error: false } });
    }

    if (
      this.checkRequiredAndRecomendItems(
        this.props.context.requiredFields,
        "cidade"
      )
    ) {
      if (
        this.state.cidade.value == "" ||
        this.state.cidade.value == undefined
      ) {
        this.setState({ cidade: { ...this.state.cidade, error: true } });
        check = false;
      } else this.setState({ cidade: { ...this.state.cidade, error: false } });
    }

    if (
      this.checkRequiredAndRecomendItems(
        this.props.context.requiredFields,
        "bairro"
      )
    ) {
      if (
        this.state.bairro.value == "" ||
        this.state.bairro.value == undefined
      ) {
        this.setState({ bairro: { ...this.state.bairro, error: true } });
        check = false;
      } else this.setState({ bairro: { ...this.state.bairro, error: false } });
    }

    if (
      this.checkRequiredAndRecomendItems(
        this.props.context.requiredFields,
        "logradouro"
      )
    ) {
      if (
        this.state.logradouro.value == "" ||
        this.state.logradouro.value == undefined
      ) {
        this.setState({
          logradouro: { ...this.state.logradouro, error: true },
        });
        check = false;
      } else
        this.setState({
          logradouro: { ...this.state.logradouro, error: false },
        });
    }

    if (
      this.checkRequiredAndRecomendItems(
        this.props.context.requiredFields,
        "numero"
      )
    ) {
      if (
        this.state.numero.value == "" ||
        this.state.numero.value == undefined
      ) {
        this.setState({ numero: { ...this.state.numero, error: true } });
        check = false;
      } else this.setState({ numero: { ...this.state.numero, error: false } });
    }

    // if(this.checkRequiredAndRecomendItems(this.props.context.requiredFields,'documentos')){
    //     if(this.state.documentos.value.length < 1){
    //         this.setState({documentos:{...this.state.documentos, error:true}})
    //         check = false
    //     }else
    //         this.setState({documentos:{...this.state.documentos, error:false}})
    // }

    if (
      this.checkRequiredAndRecomendItems(
        this.props.context.requiredFields,
        "referencia"
      )
    ) {
      if (
        this.state.referencia.value == "" ||
        this.state.referencia.value == undefined
      ) {
        this.setState({
          referencia: { ...this.state.referencia, error: true },
        });
        check = false;
      } else
        this.setState({
          referencia: { ...this.state.referencia, error: false },
        });
    }

    if (check) await this.saveInDataBase();
  }

  render() {
    let { classes } = this.props;

    return (
      <Grid container className={classes.backgroundPage}>
        <Grid
          container
          direction="column"
          className={classes.cardInputs}
          alignItems="stretch"
          justify="center"
        >
          <SectionHeader
            classes={{ sectionTitle: classes.sectionTitle }}
            isOpen={this.state.mainDataIsOpen}
            onChange={() => {
              this.setState({ mainDataIsOpen: !this.state.mainDataIsOpen });
            }}
          >
            Dados
          </SectionHeader>
          <Grid item className={classes.input}>
            <TextField
              fullWidth={true}
              label="Nome Completo"
              onChange={(e) => this.handleChangeInput("nome", e.target.value)}
              value={this.state.nome.value}
              error={this.state.nome.error}
              InputLabelProps={{
                className: this.controllerRecomended(
                  this.props.context.recommendedFields,
                  "nome",
                  this.state.nome.error
                )
                  ? classes.recomended
                  : null,
              }}
              helperText={
                this.state.nome.error
                  ? "Preencha o campo de nome corretamente"
                  : null
              }
              required={this.checkRequiredAndRecomendItems(
                this.props.context.requiredFields,
                "nome"
              )}
            />
          </Grid>

          <Grid item className={classes.input}>
            <TextField
              fullWidth
              label="Email"
              onChange={(e) => this.handleChangeInput("email", e.target.value)}
              value={this.state.email.value}
              required={this.checkRequiredAndRecomendItems(
                this.props.context.requiredFields,
                "email"
              )}
              error={this.state.email.error}
              helperText={
                this.state.email.error
                  ? "Preencha o campo de email corretamente"
                  : null
              }
              type="email"
              InputLabelProps={{
                className: this.controllerRecomended(
                  this.props.context.recommendedFields,
                  "email",
                  this.state.email.error
                )
                  ? classes.recomended
                  : null,
              }}
            />
          </Grid>

          <Grid item className={classes.input}>
            <CpfMask
              fullWidth
              label="CPF"
              InputLabelProps={{
                className: this.controllerRecomended(
                  this.props.context.recommendedFields,
                  "cpf",
                  this.state.cpf.error
                )
                  ? classes.recomended
                  : null,
              }}
              error={this.state.cpf.error}
              helperText={
                this.state.cpf.error
                  ? "Preencha o campo de cpf corretamente"
                  : null
              }
              onChange={(e) => this.handleChangeInput("cpf", e.target.value)}
              value={this.state.cpf.value}
              required={this.checkRequiredAndRecomendItems(
                this.props.context.requiredFields,
                "cpf"
              )}
            />
          </Grid>

          <Grid item className={classes.input}>
            <TextField
              fullWidth={true}
              label="Nome de usuário"
              value={this.state.userName.value}
              disabled
            />
          </Grid>

          <Grid item className={classes.input}>
            <TextField
              fullWidth={true}
              label="Data adimissão"
              value={this.state.dataAdimissao.value || "Sem data"}
              type="text"
              disabled
            />
          </Grid>

          <Grid item className={classes.input}>
            {this.state.telefone.value.map((a, index) => {
              return (
                <CardPhone
                  showclosebutton={index != 0}
                  key={index}
                  index={index}
                  onRemovePhoneNumber={this.clickRemovePhoneNumber}
                  onSetPhoneNumber={this.setPhoneNumber}
                  required={this.checkRequiredAndRecomendItems(
                    this.props.context.requiredFields,
                    "telefone"
                  )}
                  error={this.state.telefone.error[index]}
                  value={this.state.telefone.value[index]}
                  recomended={this.controllerRecomended(
                    this.props.context.recommendedFields,
                    "telefone",
                    this.state.telefone.error
                  ).toString()}
                  className={classes.recomended}
                />
              );
            })}
          </Grid>
          <Grid container justify="flex-end">
            <Fab
              size="medium"
              aria-label="add"
              color="primary"
              onClick={this.onClickAddPhone}
              disabled={
                this.state.telefone.value[
                  this.state.telefone.value.length - 1
                ] == "" ||
                !(
                  this.countNumberInString(
                    this.state.telefone.value[
                      this.state.telefone.value.length - 1
                    ],
                    11
                  ) ||
                  this.countNumberInString(
                    this.state.telefone.value[
                      this.state.telefone.value.length - 1
                    ],
                    10
                  )
                )
              }
            >
              <AddCircleIcon />
            </Fab>
          </Grid>

          <Grid item className={classes.input}>
            <TextField
              fullWidth
              InputProps={{
                inputComponent: AmountMask,
                startAdornment: `R$`,
                endAdornment: (
                  <>
                    <IconButton onClick={this.toggleVoucher}>
                      {this.state.showVoucherValue ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </>
                ),
              }}
              label="Vale Mês"
              value={` ${
                this.state.showVoucherValue ? this.state.valeMes.value : "-----"
              }`}
              disabled
            />
          </Grid>
        </Grid>

        {!this.context.isBitboxEmployee && (
          <>
            <Grid
              container
              direction="column"
              className={classes.cardInputs}
              alignItems="stretch"
              justify="center"
            >
              <SectionHeader
                classes={{ sectionTitle: classes.sectionTitle }}
                isOpen={this.state.addressDataIsOpen}
                onChange={() => {
                  this.setState({
                    addressDataIsOpen: !this.state.addressDataIsOpen,
                  });
                }}
              >
                Endereço
              </SectionHeader>
              {this.state.addressDataIsOpen && (
                <>
                  <Grid
                    item
                    className={classes.input}
                    container
                    direction="row"
                    justify="space-between"
                  >
                    <Grid item xs={3}>
                      <SelectInput
                        fullWidth={true}
                        label="Estado"
                        value={this.state.estado.value}
                        onChange={(e) => this.handleChangeInput("estado", e)}
                        required={this.checkRequiredAndRecomendItems(
                          this.props.context.requiredFields,
                          "estado"
                        )}
                        menuItem={this.props.neighborhoodContext.states}
                        error={this.state.estado.error}
                        recomended={this.controllerRecomended(
                          this.props.context.recommendedFields,
                          "estado",
                          this.state.estado.error
                        ).toString()}
                        classe={classes.recomended}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <SelectInput
                        fullWidth={true}
                        label="Cidade"
                        value={this.state.cidade.value}
                        onChange={(e) => this.handleChangeInput("cidade", e)}
                        required={this.checkRequiredAndRecomendItems(
                          this.props.context.requiredFields,
                          "cidade"
                        )}
                        menuItem={this.props.neighborhoodContext.cities}
                        error={this.state.cidade.error}
                        recomended={this.controllerRecomended(
                          this.props.context.recommendedFields,
                          "cidade",
                          this.state.cidade.error
                        ).toString()}
                        classe={classes.recomended}
                      />
                    </Grid>
                  </Grid>

                  <Grid item className={classes.input}></Grid>

                  <Grid item className={classes.input}>
                    <TextField
                      fullWidth
                      label="CEP"
                      onChange={(e) =>
                        this.handleChangeInput("cep", e.target.value)
                      }
                      value={this.state.cep.value}
                      InputProps={{
                        inputComponent: CepMask,
                      }}
                      InputLabelProps={{
                        className: this.controllerRecomended(
                          this.props.context.recommendedFields,
                          "cep",
                          this.state.cep.error
                        )
                          ? classes.recomended
                          : null,
                      }}
                      required={this.checkRequiredAndRecomendItems(
                        this.props.context.requiredFields,
                        "cep"
                      )}
                      error={this.state.cep.error}
                      helperText={
                        this.state.cep.error
                          ? "Preencha o campo de cep corretamente"
                          : null
                      }
                    />
                  </Grid>

                  <Grid item className={classes.input}>
                    <Grid container wrap="nowrap" justify="space-between">
                      <Grid item xs={10}>
                        <SelectInput
                          label="Bairro"
                          fullWidth
                          value={this.state.bairro.value}
                          onChange={(e) => this.handleChangeInput("bairro", e)}
                          required={this.checkRequiredAndRecomendItems(
                            this.props.context.requiredFields,
                            "bairro"
                          )}
                          menuItem={this.props.neighborhoodContext.neighborhood}
                          error={this.state.bairro.error}
                          recomended={this.controllerRecomended(
                            this.props.context.recommendedFields,
                            "bairro",
                            this.state.bairro.error
                          ).toString()}
                          classe={classes.recomended}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Grid
                          container
                          justify="flex-end"
                          alignItems="flex-end"
                          className={classes.addNeighborhood}
                        >
                          <AddIcon
                            fontSize="large"
                            onClick={this.props.handleToggleNeighborhoodForm}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item className={classes.input}>
                    <TextField
                      fullWidth={true}
                      label="Logradouro"
                      onChange={(e) =>
                        this.handleChangeInput("logradouro", e.target.value)
                      }
                      value={this.state.logradouro.value}
                      required={this.checkRequiredAndRecomendItems(
                        this.props.context.requiredFields,
                        "logradouro"
                      )}
                      error={this.state.logradouro.error}
                      helperText={
                        this.state.logradouro.error
                          ? "Preencha o campo de logradouro corretamente"
                          : null
                      }
                      InputLabelProps={{
                        className: this.controllerRecomended(
                          this.props.context.recommendedFields,
                          "logradouro",
                          this.state.logradouro.error
                        )
                          ? classes.recomended
                          : null,
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    className={classes.input}
                    container
                    direction="row"
                    justify="space-between"
                  >
                    <Grid item xs={8}>
                      <TextField
                        fullWidth={true}
                        label="Referência"
                        onChange={(e) =>
                          this.handleChangeInput("referencia", e.target.value)
                        }
                        value={this.state.referencia.value}
                        required={this.checkRequiredAndRecomendItems(
                          this.props.context.requiredFields,
                          "referencia"
                        )}
                        error={this.state.referencia.error}
                        helperText={
                          this.state.referencia.error
                            ? "Preencha o campo de referencia corretamente"
                            : null
                        }
                        InputLabelProps={{
                          className: this.controllerRecomended(
                            this.props.context.recommendedFields,
                            "referencia",
                            this.state.referencia.error
                          )
                            ? classes.recomended
                            : null,
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth={true}
                        label="Número"
                        onChange={(e) =>
                          this.handleChangeInput("numero", e.target.value)
                        }
                        value={this.state.numero.value}
                        required={this.checkRequiredAndRecomendItems(
                          this.props.context.requiredFields,
                          "numero"
                        )}
                        error={this.state.numero.error}
                        helperText={
                          this.state.numero.error
                            ? "Preencha o campo de numero corretamente"
                            : null
                        }
                        InputLabelProps={{
                          className: this.controllerRecomended(
                            this.props.context.recommendedFields,
                            "logradouro",
                            this.state.nome.error
                          )
                            ? classes.recomended
                            : null,
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid
              container
              direction="column"
              alignItems="stretch"
              className={classes.cardInputs}
            >
              <Grid item className={classes.input}>
                <SectionHeader
                  classes={{ sectionTitle: classes.sectionTitle }}
                  isOpen={this.state.paymentDataIsOpen}
                  onChange={() => {
                    this.setState({
                      paymentDataIsOpen: !this.state.paymentDataIsOpen,
                    });
                  }}
                >
                  Cartão e Pagamento
                </SectionHeader>
              </Grid>
              {this.state.paymentDataIsOpen && (
                <>
                  <Grid item className={classes.input}>
                    <TextField
                      fullWidth={true}
                      label="Número do cartão"
                      value={this.state.cartaoCredito.numero}
                      disabled
                    />
                  </Grid>

                  <Grid
                    item
                    className={classes.input}
                    container
                    direction="row"
                    justify="space-between"
                  >
                    <Grid item xs={8}>
                      <TextField
                        fullWidth={true}
                        label="Validade"
                        value={this.state.cartaoCredito.validade}
                        disabled
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        fullWidth={true}
                        label="CVC"
                        value={this.state.cartaoCredito.cvc}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid item className={classes.input}>
                    <TextField
                      fullWidth={true}
                      label="Meu plano atual"
                      value={this.state.plano.value}
                      disabled
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}

        <Grid container className={classes.cancelOrSave}>
          <CancelOrSave
            showCancelButton
            enableSave
            onClickSave={this.checkRequiredFields}
          />
        </Grid>
      </Grid>
    );
  }
}

class SectionHeader extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <>
        <Grid item>
          <Grid container justify="space-between" direction="row">
            <Grid item>
              <Typography className={classes.sectionTitle}>
                {this.props.children}
              </Typography>
            </Grid>
            {this.props.isOpen ? (
              <Grid item>
                <ExpandLessIcon
                  fontSize="large"
                  style={{ color: "#bbb" }}
                  onClick={this.props.onChange}
                />
              </Grid>
            ) : (
              <Grid item>
                <ExpandMoreIcon
                  fontSize="large"
                  style={{ color: "#333" }}
                  onClick={this.props.onChange}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

UserProfile.contextType = UserContext;
export default withStyles(styles)(UserProfile);
