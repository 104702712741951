import React from "react";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import { Grid, Typography } from "@material-ui/core";

const styles = (theme) => ({
  search: {
    backgroundColor: "#F8F8FF",
    height: "60px",
    paddingLeft: 10,
  },
  input: {
    border: 0,
    backgroundColor: "transparent",
    height: "100%",
    outline: "none",
    fontSize: "1rem",
  },
  textFilter: {
    fontSize: ".9rem",
    color: "#808080",
    fontFamily: "Roboto,sans-serif",
  },
  filter: {
    color: "#808080",
  },
});

/**
 * Componente renderiza uma barra de pesquisa com filtro.
 */
class SearchBarWithFilter extends React.Component {
  /**
   * @param {boolean} props.getInput Função em que seta no elemento pai o valor digitado no input
   * @property {string} this.state.input  Boleano que altera a exibição do menu lateral
   */
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  /**Função que seta no estado 'input' o texto escrito no input.
   * @function
   * @param {event} e evento gerado pelo onChange
   */
  handleInputChange(e) {
    this.setState({ input: e.target.value });
    setTimeout(() => this.props.getInput(this.state.input), 1000);
  }

  render() {
    let { classes } = this.props;
    return (
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className={classes.search}
      >
        <Grid item xs={9}>
          <Grid container alignItems="center">
            <SearchIcon />
            <input
              type="search"
              onChange={this.handleInputChange}
              placeholder="Buscar em todos os itens"
              className={classes.input}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.filter}
          >
            <FilterListOutlinedIcon fontSize="large" />
            <Typography className={classes.textFilter}>Filtro</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SearchBarWithFilter);
