import React from "react";
import { makeStyles, Button, ButtonGroup } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import ChatIcon from "@material-ui/icons/Chat";
import { clearSiteCache, unregisterAllSw } from "../../utils/clearSiteCache";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import LayersClearIcon from "@material-ui/icons/LayersClear";
import { getUser } from "../../service/authentication";

const getStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  buttonGroup: {
    color: "red",
    "& > a span": {
      textAlign: "center",
    },
  },
}));
const SUPPORT_CONTACT = "tel://027996461801";

export function SuportBottomGroupButton() {
  const classes = getStyles();
  const { addAsyncDialog } = React.useContext(MessageDialogContext);
  const [service, setService] = React.useState({ token: "", baseUrl: "" });
  React.useEffect(function () {
    getUser().then((user) =>
      setService({ token: user.sessao.token, baseUrl: user.service })
    );
  }, []);

  const handleClearCache = React.useCallback(
    async function () {
      const shouldClearCache = await addAsyncDialog({
        message: "Deseja mesmo limpar o cache?",
        title: "Você está prestes a limpar o cache",
        type: "warning",
        okText: "Sim",
        cancelText: "Não",
      });

      if (!shouldClearCache) return;

      const [isCacheReseted, isSwRemoved] = await Promise.all([
        clearSiteCache(),
        unregisterAllSw(),
      ]);
      if (isCacheReseted || isSwRemoved) {
        window.location.pathname = "/";
      }
    },
    [addAsyncDialog]
  );

  return (
    <div className={classes.footer}>
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
        className={classes.buttonGroup}
        fullWidth
      >
        <Button startIcon={<PhoneIcon />} href={SUPPORT_CONTACT}>
          LIGAR
        </Button>
        <Button
          onClick={handleClearCache}
          startIcon={<LayersClearIcon />}
          color="secondary"
        >
          LIMPAR CACHE
        </Button>
        <Button
          target="_blank"
          startIcon={<ChatIcon />}
          href={`${service.baseUrl}/suporte/app?session=${service.token}`}
        >
          CHAT
        </Button>
      </ButtonGroup>
    </div>
  );
}
