import React from "react";
import Cropper from "react-easy-crop";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { getCroppedImg } from "../../service/Pictures/Pictures";
import CropPictureActionBar from "./CropPictureActionBar";

const applyStyle = withStyles((theme) => ({
  cropContainer: {
    position: "relative",
    width: "100%",
    height: "80vh",
    background: "#333",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    background: "#191919",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  controls: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    display: "block",
    alignItems: "center",
    padding: theme.spacing(1),
    background: "#1e1e1e",
    borderTopLeftRadius: "25px",
    borderTopRightRadius: "25px",
  },
}));

class CropPicture extends React.Component {
  static propTypes = {
    imageSrc: PropTypes.string.isRequired,
    onClickBlock: PropTypes.func.isRequired,
    onClickDone: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 4 / 3,
      rotation: 0,
      croppedAreaPixels: null,
      croppedImage: null,
    };
    this.onCropComplete = this.onCropComplete.bind(this);
    this.showCroppedImage = this.showCroppedImage.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.onClickDone = this.onClickDone.bind(this);
    this.setCrop = this.setCrop.bind(this);
    this.setRotation = this.setRotation.bind(this);
    this.setZoom = this.setZoom.bind(this);
  }

  onCropComplete(croppedArea, croppedAreaPixels) {
    this.setState({ croppedAreaPixels });
  }

  async showCroppedImage() {
    try {
      const croppedImage = await getCroppedImg(
        this.props.imageSrc,
        this.state.croppedAreaPixels,
        this.state.rotation,
        "image/jpeg"
      );
      this.setState({ croppedImage });
    } catch (error) {
      this.props.errorHandler(error);
    }
  }

  onClickBack() {
    this.props.onClickBack();
  }
  async onClickDone() {
    try {
      const croppedImage = await getCroppedImg(
        this.props.imageSrc,
        this.state.croppedAreaPixels,
        this.state.rotation,
        "image/jpeg"
      );

      this.setState({ croppedImage });
      this.props.onClickDone(croppedImage);
    } catch (error) {
      this.props.errorHandler(error);
    }
  }

  setCrop(crop) {
    return this.setState({ crop });
  }
  setRotation(rotation) {
    return this.setState({ rotation });
  }
  setZoom(zoom) {
    return this.setState({ zoom });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.cropContainer}>
          <div>
            <Cropper
              image={this.props.imageSrc}
              crop={this.state.crop}
              rotation={this.state.rotation}
              zoom={this.state.zoom}
              aspect={this.state.aspect}
              onCropChange={this.setCrop}
              onRotationChange={this.setRotation}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.setZoom}
            />
          </div>
        </div>
        <div className={classes.controls}>
          <CropPictureActionBar
            rotation={this.state.rotation}
            zoom={this.state.zoom}
            onClickBack={this.onClickBack}
            onClickDone={this.onClickDone}
            onSlideZoomChange={this.setZoom}
            onSlideRotationChange={this.setRotation}
          />
        </div>
      </div>
    );
  }
}
export default applyStyle(CropPicture);
