import React from "react";
import Moeda from "../../../../utils/Moeda";
// import { Container } from './styles';

function usePoints(factor, { itemsEnter, itemsOut }) {
  const [totalAdded, setTotalAdded] = React.useState(0);
  const [totalRemoved, setTotalRemoved] = React.useState(0);

  const calculateTotal = React.useCallback(
    function (productList) {
      const total = productList.reduce((memo, product) => {
        memo = memo.add(Moeda.create(product.valor).mul(product.quantidade));
        return memo;
      }, Moeda.create(0));
      const mountedValue = total.mount();
      const finalValue = Math.ceil(Number(mountedValue) * factor);
      return finalValue;
    },
    [factor]
  );

  const calculateUnitaryValue = React.useCallback(
    function (product) {
      const value = Moeda.create(product.valor).mount();
      return Math.ceil(Number(value) * factor);
    },
    [factor]
  );

  React.useEffect(
    function () {
      const added = calculateTotal(itemsEnter);
      const out = calculateTotal(itemsOut);
      setTotalAdded(added);
      setTotalRemoved(out);
    },
    [itemsEnter, itemsOut, calculateTotal]
  );

  return [totalAdded, totalRemoved, calculateUnitaryValue, calculateTotal];
}

export default usePoints;
