import DateFnsUtils from "@date-io/date-fns";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import deLocale from "date-fns/locale/pt";
import React from "react";
import Moeda from "../../../utils/Moeda";
import ButtonForAdd from "../../Buttons/ButtonForAdd";
import Backdrop from "../../Dialogs/BackdropLoader";
import RouteDialog from "../../Dialogs/RouteDialog";
import Header from "../../HeaderForAdd/Header";
import SelectInput from "../../Inputs/SelectInput";

const styles = (theme) => ({
  inputsList: {
    paddingBottom: "40px",
  },
  inputContainer: {
    backgroundColor: "#fff",
    position: "relative",
    top: "10px",
    paddingTop: "20px",
  },
  body: {
    backgroundColor: "#F5F5F5",
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 4,
  },
  button: {
    position: "relative",
    top: "30px",
  },
  title: {
    fontSize: "1rem",
    fontFamily: "Roboto, sans-serif",
    wordBreak: "break-all",
  },
});

class SchedulingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schedulingType: { value: "", error: false },
      date: { value: null, error: false },
      isLoading: false,
      open: false,
    };
    this.saveDate = this.saveDate.bind(this);
    this.validate = this.validate.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  async saveDate() {
    this.setState({ isLoading: true, open: true });
    let data = {
      tipo: this.state.schedulingType.value || this.props.type,
      dataRecebimento: this.state.date.value,
    };
    if (this.props.type) data.tipo = this.props.type;
    try {
      await this.props.func(data);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ open: true, isLoading: false });
    }
  }

  componentDidMount() {
    if (!!this.props.history?.location.search) {
      const query = new URLSearchParams(this.props.history.location.search);
      const stateClone = this.state.schedulingType;
      if (!!query.get("tipo")) {
        if (
          !stateClone.value ||
          stateClone.value.toLocaleUpperCase() !=
          query.get("tipo").toLocaleUpperCase()
        ) {
          stateClone.value = this.props.schedulesTypes.find(
            (e) =>
              e.id.toLocaleUpperCase() == query.get("tipo").toLocaleUpperCase()
          )?.id;
          this.setState({ ...stateClone });
        }
      }
    }
  }

  componentDidUpdate(props, state) {
    if (this.props.data.dataRecebimento != props.data.dataRecebimento) {
      this.setState({
        date: {
          value: this.props.data.dataRecebimento,
          error: false,
        },
      });
    }
  }

  async validate() {
    let isValid = true;

    isValid = isValid && this.checkInputs();
    isValid = isValid && (await this.validateBusinessRules());

    if (isValid) await this.saveDate();
  }

  checkInputs() {
    let dateIsValid = true;
    let schedulingTypeIsValid = true;

    if (!this.state.date.value) dateIsValid = false;
    if (!this.state.schedulingType.value && !this.props.type)
      schedulingTypeIsValid = false;

    this.setState({
      date: { ...this.state.date, error: !dateIsValid },
      schedulingType: {
        ...this.state.schedulingType,
        error: !schedulingTypeIsValid,
      },
    });
    return dateIsValid && schedulingTypeIsValid;
  }

  async validateBusinessRules() {
    const isDebtor = Moeda.create(this.props.data.clientBalance).isNegative();
    const scheduleCreatedType =
      this.state.schedulingType.value || this.props.type;

    if (!isDebtor && scheduleCreatedType.toLowerCase() === "recebimento") {
      this.props.messageDialogContext.addDialog({
        message:
          "Não é possível adicionar um agendamento de recebimento para um cliente quitado",
        type: "warning",
        title: "Houve um problema",
        okText: "Ok",
        hasCloseButton: false,
      });
      return false;
    }

    if (!this.props.edit) {
      const map = {
        RECEBIMENTO: "receive",
        TROCA: "exchanges",
        VENDA: "sell",
      };
      const key = map[scheduleCreatedType];
      const hasScheduleSameType = this.props.data.schedules[key];

      if (hasScheduleSameType) {
        const resp = await this.props.messageDialogContext.addAsyncDialog({
          message:
            "Já existe um agendamento deste tipo: " + scheduleCreatedType,
          type: "warning",
          title: "Tem certeza que deseja sobrescrever o agendamento antigo?",
          okText: "Sim",
          cancelText: "Não",
        });

        if (!resp) {
          // O usuário escolheu não sobrescrever o agendamento antigo
          return false;
        }

        // Remova o agendamento antigo
        delete this.props.data.schedules[key];
      }
    }

    return true;
  }

  async onClose() {
    if (this.state.date.value || this.state.schedulingType.value) {
      const resp = await this.props.messageDialogContext.addAsyncDialog({
        message: "Você não finalizou o processo de agendamento.",
        type: "info",
        title: "Deseja voltar a pagina anterior ?",
        okText: "Sim",
        cancelText: "Não",
      });
      if (!resp) return;
    }

    if (!this.props.edit) {
      const schedulesTypes = Object.keys(this.props.data.schedules);
      const clientHasSchedule = schedulesTypes.reduce((memo, key) => {
        return memo || !!this.props.data.schedules[key];
      }, false);

      if (!clientHasSchedule) {
        const resp = await this.props.messageDialogContext.addAsyncDialog({
          message: "Este cliente não possui agendamentos.",
          type: "info",
          title: "Tem certeza que deseja voltar a pagina anterior?",
          okText: "Sim",
          cancelText: "Não",
        });
        if (!resp) return;
      }
    }
    this.props.history.goBack();
  }

  render() {
    let { classes } = this.props;

    return (
      <div className={classes.body}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid container>
            <Header func={this.onClose} icon={<CloseIcon />}>
              {this.props.titleHeader}
            </Header>
          </Grid>
          <Grid
            container
            justifyContent="center"
            className={classes.inputContainer}
          >
            <Grid item xs={11}>
              <Typography className={classes.title}>
                Selecione a data e o tipo desse agendamento:
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.inputsList}>
              {this.props.edit ? (
                <TextField
                  value={this.props.type}
                  disabled
                  fullWidth
                  inputProps={{
                    style: { textAlign: "center", color: "#808080" },
                  }}
                />
              ) : (
                <FormControl fullWidth style={{ paddingBottom: "10px" }}>
                  <SelectInput
                    label="Tipo de agendamento"
                    value={this.state.schedulingType.value}
                    required={true}
                    onChange={(e) =>
                      this.setState({
                        schedulingType: {
                          ...this.state.schedulingType,
                          value: e,
                        },
                      })
                    }
                    error={this.state.schedulingType.error}
                    menuItem={this.props.schedulesTypes}
                  />
                </FormControl>
              )}
              <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                <DatePicker
                  fullWidth
                  style={{ paddingBottom: "7px" }}
                  label="Data de realização"
                  format="dd/MM/yyyy"
                  required={true}
                  value={this.state.date.value}
                  onChange={(e) =>
                    this.setState({ date: { ...this.state.date, value: e } })
                  }
                  InputProps={{
                    endAdornment: <DateRangeIcon color="action" />,
                  }}
                  disablePast
                  error={this.state.date.error}
                  helperText={
                    this.state.date.error ? "Selecione a data!" : null
                  }
                  margin="dense"
                />
              </MuiPickersUtilsProvider>
              <Grid item xs={12} style={{ paddingTop: "10px" }}>
                <TextField
                  value={this.props.data.clientName}
                  disabled
                  fullWidth
                  inputProps={{
                    style: { textAlign: "center", color: "#808080" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" className={classes.button}>
            <ButtonForAdd func={this.validate}>
              {this.props.edit ? "Salvar agendamento" : "Criar agendamento"}
            </ButtonForAdd>
          </Grid>
          <RouteDialog open={this.state.open} />
        </Grid>
        <Backdrop
          secondaryText="Salvando nova data do agendamento"
          open={this.props.isLoading || this.state.isLoading}
        />
      </div>
    );
  }
}
export default withStyles(styles)(SchedulingForm);
