import React from "react";
import { Typography, Grid, withStyles } from "@material-ui/core";
import Card from "../../components/CardComponents/CardNeighborhood/Card";
import DrawerBottom from "../../components/Drawers/DrawersForSort/DrawerBottom";

import { DailyListContext } from "../../contexts/DailyListContext";
import { SortContext } from "../../contexts/SortContext";
import { NeighborhoodContext } from "../../contexts/NeighborhoodContext";

import HeaderMenu from "../../components/HeaderForAdd/HeaderMenu";
import FlipMove from "react-flip-move";
import Header from "../../components/HeaderForAdd/Header";
import CloseIcon from "@material-ui/icons/Close";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import { NotificationSnackbar } from "../../components/Snack/notification";

const styles = (theme) => ({
  body: {
    backgroundColor: "#f5f5f5",
    height: "100vh",
    position: "absolute",
    width: "100%",
  },
  container: {
    padding: "20px 15px 20px 15px",
    backgroundColor: "#fff",
    "& > div": {
      paddingTop: "15px",
      paddingBottom: "0px",
      // "& > div": {
      //   paddingBottom: "13px",
      // },
    },
  },
});

/**
 * Tela de ordenar bairro.
 */
class SortNeighborhoodsConsumer extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * @param {array} props.neighborhood Lista com todos os bairros
       * @param {object} props.classes Objeto com todas as classes do CSS
       * @property {number} this.state.selected Estado que armazena o índice do card selecionado
       * @property {number} this.state.secondSelected  Estado que armazena o índice do segundo card selecionado
       * @property {boolean} this.state.drawer Boleano que define a exibição do drawer
       * @property {boolean} this.state.enableInsertAfter  Boleano que define se o Inserir depois está ativado, para assim pode selecionar o segundo card
       * @property {array} this.state.modified Lista que guarda as hashs de cada card para sa saber se eles foram alterados
       * @property {next} this.state.next Estado que guarda o próximo índice da função 'Próximo'
       * @property {boolean} this.state.enableSave Boleano que se algum item da lista foi alterado, assim habilitando o salvamento
       */
      neighborhood: [],
      // selected: null, //State do card que abriu o Drawer
      // secondSelected: null, //State do card clicado após o 'Inserir depois de' for clicado
      // drawer: false,
      // enableInsertAfter: false, //State para abilidar o 'Inserir depois de'
      next: null, //State que guarda o próximo índice da função 'Próximo'
      // modified: [], //State onde guarda os ids de cada card, para saber se eles foram alterados
      isLoading: false,

      allNeighborhoods: [],
      clients: [],
      selected: null,
      secondSelected: null,
      drawer: false,
      enableInsertAfter: false,
      refCard: null,
      modified: [],
      enableSave: false,
      selectedNeighborhood: "",
      routeIsOpen: false,
      isSnackBarOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.dailyListContext.neighborhoodInfo !=
      this.props.dailyListContext.neighborhoodInfo
    ) {
      this.setNeighborhoodData();
    }

    if (prevProps.sortContext != this.props.sortContext) {
      this.checkRouteOpen();
    }
  }

  componentDidMount() {
    this.setNeighborhoodData();
    this.checkRouteOpen();
  }

  handleSnackClose = () => {
    this.setState({ isSnackBarOpen: false });
  };

  /**Função responsável por habilitar o botão de salvar.
   * @function
   */
  enableSave = async () => {
    this.setState({ enableSave: true });
    await this.handleOnSave(true);
  };

  setNeighborhoodData = async () => {
    await this.getNeighborhoodIDs(this.props.dailyListContext.neighborhoodInfo);
    this.setState({
      allNeighborhoods: this.props.sortContext.neighborhoods.sort(
        (a, b) => a.index - b.index
      ),
      // isLoading: this.context.isLoading,
    });
  };

  checkRouteOpen = async () => {
    let checkRoute = await this.props.sortContext.checkRouteOpen();
    if (checkRoute) {
      this.setState({ routeIsOpen: true });
    } else {
      this.setState({ routeIsOpen: false });
    }
  };

  getNeighborhoodIDs = async (ids) => {
    let arrayIDs = [];
    for (let id in ids) {
      arrayIDs.push(Number(id));
    }

    await this.props.sortContext.getNeighborhoodsById(arrayIDs);
  };

  /**Função que habilita o 'inserir depois de', no caso para funcionar o 'secondSelect'.
   * @function
   */
  clickEnableInsertAfter = () => {
    this.setState({ enableInsertAfter: !this.state.enableInsertAfter }, () => {
      this.toggleDrawer();
      this.enableSave();
    });
  };

  /**Função para inserir o card clicado após o segundo card selecionado
   * @function
   */
  handleInsertAfter = () => {
    if (!this.state.enableInsertAfter) {
      this.setState({ enableInsertAfter: true, drawer: false });
      return;
    }

    if (this.state.enableInsertAfter && this.state.secondSelected != null) {
      let neighborhoods = [...this.state.allNeighborhoods];
      let aux = neighborhoods[this.state.selected];
      let newRefCard = null;
      neighborhoods.splice(this.state.secondSelected + 1, 0, aux);
      if (this.state.secondSelected >= this.state.selected) {
        newRefCard = this.state.secondSelected;
        neighborhoods.splice(this.state.selected, 1);
      } else {
        newRefCard = this.state.secondSelected + 1;
        neighborhoods.splice(this.state.selected + 1, 1);
      }
      this.setState(
        {
          allNeighborhoods: neighborhoods,
          selected: null,
          enableInsertAfter: false,
          secondSelected: null,
          refCard: newRefCard,
          modified: [...this.state.modified, aux.id],
        },
        this.enableSave
      );
    }
  };

  /**Função de inserir no topo da lista
   * @function
   */
  handleInsertTop = () => {
    let neighborhoods = [...this.state.allNeighborhoods];
    let aux = neighborhoods[this.state.selected];
    this.setState({ modified: [...this.state.modified, aux.id] });
    neighborhoods.splice(this.state.selected, 1);
    neighborhoods.unshift(aux);
    this.setState(
      {
        allNeighborhoods: neighborhoods,
        selected: null,
        drawer: false,
        refCard: 0,
        enableInsertAfter: false,
      },
      this.enableSave
    );
  };

  checkCardIsModified = (bairroId) => {
    const modified = this.state.modified.some((id) => id == bairroId);
    return modified;
  };

  /**Função de inserir no próximo
   * @function
   */
  handleInsertNext = () => {
    let neighborhoods = [...this.state.allNeighborhoods];
    let aux = this.state.allNeighborhoods[this.state.selected];
    this.setState({ modified: [...this.state.modified, aux.id] });
    this.setState({ refCard: this.state.selected, drawer: false });
    if (this.state.refCard >= this.state.selected) {
      neighborhoods.splice(this.state.refCard + 1, 0, aux);
      neighborhoods.splice(this.state.selected, 1);
    } else {
      neighborhoods.splice(this.state.refCard + 1, 0, aux);
      neighborhoods.splice(this.state.selected + 1, 1);
      this.setState({ refCard: this.state.refCard + 1 });
    }
    this.setState(
      {
        allNeighborhoods: neighborhoods,
        selected: null,
        drawer: false,
        enableInsertAfter: false,
      },
      this.enableSave
    );
  };

  /**Função que seta o índice do card que for clicado no 'Inserir depois de'
   * @function
   * @param {number} index Índice do segundo card selecionado
   */
  setSecondSelected = (index) => {
    if (this.state.enableInsertAfter) {
      this.setState({ secondSelected: index });
    }
  };

  /**Função que seta o índice do card selecionado
   * @function
   * @param {number} index Índice do card selecionado
   */
  handleSetSelect = (index) => {
    if (!this.state.drawer && !this.state.enableInsertAfter) {
      this.setState({ drawer: true, selected: index });
      return;
    }
    if (this.state.enableInsertAfter && !this.state.secondSelected) {
      this.setState({ secondSelected: index }, this.handleInsertAfter);
    }
  };

  /**Função que seta o índice do card selecionado
   * @function
   * @param {number} index Índice do card selecionado
   */
  handleSelectCard = (index) => {
    if (!this.state.drawer && !this.state.enableInsertAfter) {
      this.setState({ drawer: true, selected: index });
      return;
    }
    if (this.state.enableInsertAfter && !this.state.secondSelected) {
      this.setState({ secondSelected: index }, this.handleInsertAfter);
    }
  };

  /**Função que alterna a exibição do drawer
   * @function
   * @param {number} index Índice do card selecionado
   */
  toggleDrawer = () => {
    this.setState({ drawer: !this.state.drawer });
  };

  handleOnSave = async () => {
    await this.onOrderSave();
    this.setState({ isSnackBarOpen: true });
    setTimeout(this.handleSnackClose, 3000);
  };

  onOrderSave = async () => {
    const orderedItems = [...this.state.allNeighborhoods];
    const lowerIndex = this.state.allNeighborhoods.reduce(
      (memo, neighborhood) => {
        if (!memo) {
          return neighborhood.index;
        }
        if (memo > neighborhood.index) {
          return neighborhood.index;
        }
        return memo;
      },
      false
    );
    let index = lowerIndex;
    for (let neighbor of orderedItems) {
      neighbor.index = index;
      index++;
    }

    await this.props.dailyListContext.updateDailyListNeighborhoodOrder(
      orderedItems
    );
  };

  onClickHeaderIcon = () => {
    this.setState({
      selected: null,
      secondSelected: null,
      enableInsertAfter: false,
      drawer: false,
    });
  };

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.body}>
        {this.state.enableInsertAfter ? (
          <Header icon={<CloseIcon />} func={this.onClickHeaderIcon}>
            Ordenar bairros
          </Header>
        ) : (
          <HeaderMenu>Ordenar bairros</HeaderMenu>
        )}

        <Grid
          // ref={this.unselectAll}
          container
          className={classes.container}
          style={{ marginTop: "15px" }}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Ordem de todos os bairros:</Typography>
          </Grid>
          <Grid
            container
            alignItems="stretch"
            direction="column"
            justify="space-evenly"
            className={classes.listCards}
          >
            {this.state.routeIsOpen ? (
              <FlipMove>
                {this.state.allNeighborhoods.map((client, index) => {
                  return (
                    <Grid
                      container
                      key={client.id}
                      style={{ marginBottom: 10 }}
                    >
                      <Card
                        data={client}
                        index={index}
                        onCardClick={this.handleSelectCard}
                        enableSecondClick={this.state.enableInsertAfter}
                        modified={this.checkCardIsModified(client.id)}
                        selected={this.state.selected == index}
                        refCard={this.state.refCard == index}
                      />
                    </Grid>
                  );
                })}
              </FlipMove>
            ) : (
              <Typography color="error">
                Você deve abrir a rota para poder ordenar os bairros.
              </Typography>
            )}
          </Grid>

          <DrawerBottom
            toggleDrawer={this.toggleDrawer}
            open={this.state.drawer}
            name={
              this.state.selected != null
                ? this.state.allNeighborhoods.length
                  ? this.state.allNeighborhoods[this.state.selected].nome
                  : null
                : null
            }
            refCard={
              this.state.refCard != null
                ? this.state.allNeighborhoods[this.state.refCard].nome
                : null
            }
            onClickInsertTop={this.handleInsertTop}
            onClickInsertAfter={this.handleInsertAfter}
            onClickInsertNext={this.handleInsertNext}
            type="cliente"
          />
        </Grid>
        <NotificationSnackbar
          message="Ordem dos bairros salva com sucesso!"
          open={this.state.isSnackBarOpen}
          severity="success"
          handleClose={this.handleSnackClose}
        />
      </div>
    );
  }
}
SortNeighborhoodsConsumer.contextType = MessageDialogContext;

class SortNeighborhoods extends React.Component {
  render() {
    return (
      <NeighborhoodContext.Consumer>
        {(neighborhoodContext) => (
          <DailyListContext.Consumer>
            {(dailyList) => (
              <SortContext.Consumer>
                {(sortContext) => (
                  <SortNeighborhoodsConsumer
                    {...this.props}
                    dailyListContext={dailyList}
                    sortContext={sortContext}
                    neighborhoodContext={neighborhoodContext}
                  />
                )}
              </SortContext.Consumer>
            )}
          </DailyListContext.Consumer>
        )}
      </NeighborhoodContext.Consumer>
    );
  }
}
export default withStyles(styles)(SortNeighborhoods);
