import React from "react";
import { withStyles, Grid, Divider, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import Header from "../HeaderForAdd/Header";
import GreenButton from "../Buttons/GreenButton";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";

const applyStyles = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  content: {
    width: "100%",
    maxHeight: "60vh",
    minHeight: "40vh",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    padding: "10px 0px",
  },

  title: {
    fontWeight: 425,
    fontSize: "1.6rem",
    color: theme.palette.text.primary,
  },

  buttonText: {
    fontSize: ".6rem !important",
  },
  gearContainer: {
    backgroundColor: "#444",
    color: "#fff",
    height: "100%",
    padding: "5px 0",
    borderRadius: "3px",
  },
  backButton: {
    backgroundColor: "#777",
    marginTop: theme.spacing(1),
    color: "#fff",
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
      paddingTop: "5px",
      paddingBottom: "5px",
      fontFamily: "Roboto,sans-serif",
      transition: ".7s",
    },
    "& :active": {
      borderColor: "#228B22",
      "& .MuiTypography-body1": {
        fontSize: "1rem",
        paddingTop: "4px",
        paddingBottom: "4px",
      },
    },
  },
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    marginBottom: theme.spacing(1),
  },
}));

class MovementReport extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    headerMainText: PropTypes.string,
    onClickCloseIcon: PropTypes.func,
    onClickBackToInit: PropTypes.func,
    disableSellerButton: PropTypes.bool,
    disableClientButton: PropTypes.bool,
    onClickSellerButton: PropTypes.func,
    onClickClientButton: PropTypes.func,
    headerSecondaryText: PropTypes.string,
  };

  static defaultProps = {
    disableSellerButton: false,
    disableClientButton: false,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Header
          name={this.props.headerSecondaryText}
          icon={<CloseIcon fontSize="large" />}
          func={this.props.onClickCloseIcon}
        >
          {this.props.headerMainText}
        </Header>
        <div className={classes.root}>
          <Divider />
          <Box flexDirection="column" alignItems="stretch" display="flex">
            <Typography className={classes.title} align="center">
              {this.props.title}
            </Typography>
            <Divider />
            <Box className={classes.content}>{this.props.children}</Box>
            <Grid className={classes.footer} container direction="column">
              <Grid item xs={12}>
                <Grid spacing={1} container alignItems="center">
                  <Grid item xs={2}>
                    <Grid
                      display="flex"
                      container
                      justify="center"
                      alignItems="center"
                      className={classes.gearContainer}
                    >
                      <SettingsIcon />
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <GreenButton
                      onClickButton={this.props.onClickSellerButton}
                      disable={this.props.disableSellerButton}
                    >
                      <Typography className={classes.buttonText} noWrap>
                        VIA DO VENDEDOR
                      </Typography>
                    </GreenButton>
                  </Grid>
                  <Grid item xs={5}>
                    <GreenButton
                      onClickButton={this.props.onClickClientButton}
                      disable={this.props.disableClientButton}
                    >
                      <Typography className={classes.buttonText} noWrap>
                        VIA DO CLIENTE
                      </Typography>
                    </GreenButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                onClick={this.props.onClickBackToInit}
                item
                xs={12}
                className={classes.backButton}
              >
                <Typography align="center">VOLTAR AO INICIO</Typography>
              </Grid>
            </Grid>
          </Box>
        </div>
      </>
    );
  }
}

export default applyStyles(MovementReport);
