import React from "react";
import { withStyles, List, ListItem, Divider } from "@material-ui/core";
import AppBar from "../../components/MenuSideBar/AppBar";
import SearchBar from "../../components/SearchBar/SearchBar";
import ProductCard from "../../components/Products/ProductCard";
import AddButton from "../../components/Buttons/AddButton";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { ProductContext } from "../../contexts/ProductsContext";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  AddButton: {
    position: "fixed",
    bottom: "20px",
    right: "10px",
  },
  "fade-enter": {
    opacity: 0,
  },
  "fade-enter-active": {
    opacity: 1,
    transition: "opacity 500ms",
  },
  "fade-exit": {
    opacity: 1,
  },
  "fade-exit-active": {
    opacity: 0,
    transition: "opacity 300ms",
  },
});

/**
 * Tela de listagem de produtos.
 */
class Products extends React.Component {
  /**
   * @property {string} this.state.search Estado que armazenará oque for digitado no SearchBar
   * @property {array} this.state.allItems Lista contendo todos os produtos da lista
   * @property {array} productsFiltered Lista contendo os produtos filtrados pelo que for digitado no SearchBar
   * @property {object} this.state.cardOpen  Estado que armazena a hash do card selecionado e seus estados de transição
   */
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      productsFiltered: [],
      cardOpen: { hash: null, showEditRemove: null, showRemoveConfirm: null },
    };
    this.onSearchBarChange = this.onSearchBarChange.bind(this);
    this.handleRemoveProduct = this.handleRemoveProduct.bind(this);
    this.handleToggleViewActions = this.handleToggleViewActions.bind(this);
    this.handleCardOpen = this.handleCardOpen.bind(this);
    this.handleToggleRemoveConfirm = this.handleToggleRemoveConfirm.bind(this);
  }

  onSearchBarChange(word) {
    this.setState({ search: word.trim() });
  }

  /**Função responsável por remover um produto selecionado das listas .
   * @function
   * @param {number} hash Hash do card selecionado, usada para identificação única do mesmo
   */
  handleRemoveProduct(hash) {
    this.context.deleteBasket(hash);
  }

  /**Função responsável por alterar a exibição dos do componente EditRemove.
   * @function
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {number} hash Hash do card selecionado, usada para identificação única do mesmo
   * @param {number} showEditRemove Indica o estado de exibição atual do componente EditRemove
   */
  handleToggleViewActions(hash, showEditRemove) {
    let newCardOpen = { ...this.state.cardOpen };
    if (showEditRemove) {
      newCardOpen.showEditRemove = false;
      newCardOpen.showRemoveConfirm = false;
    } else {
      newCardOpen.showEditRemove = true;
      newCardOpen.showRemoveConfirm = false;
    }
    newCardOpen.hash = hash;
    this.setState({ cardOpen: newCardOpen });
  }

  handleCardOpen(hash) {
    let showEditRemove, showRemoveConfirm;
    if (this.state.cardOpen.hash === hash) {
      showEditRemove = this.state.cardOpen.showEditRemove;
      showRemoveConfirm = this.state.cardOpen.showRemoveConfirm;
    }
    return {
      showEditRemove: showEditRemove,
      showRemoveConfirm: showRemoveConfirm,
    };
  }

  getFilteredBaskets() {
    if (this.state.search == "") {
      return this.context.storedBaskets;
    }
    return this.context.storedBaskets.filter((basket) =>
      basket.nome
        .toLocaleLowerCase()
        .includes(this.state.search.toLocaleLowerCase())
    );
  }

  /**Função responsável por alterar a exibição dos do componente EditRemove.
   * @function
   * @param {number} hash Hash do card selecionado, usada para identificação única do mesmo
   * @param {number} showEditRemove Indica o estado de exibição atual do componente EditRemove
   */
  handleToggleRemoveConfirm(hash, showRemoveConfirm) {
    let newCardOpen = { ...this.state.cardOpen };
    if (showRemoveConfirm) {
      newCardOpen.showEditRemove = true;
      newCardOpen.showRemoveConfirm = false;
    } else {
      newCardOpen.showEditRemove = false;
      newCardOpen.showRemoveConfirm = true;
    }
    newCardOpen.hash = hash;
    this.setState({ cardOpen: newCardOpen });
  }

  render() {
    let { classes } = this.props;
    const filteredBaskets = this.getFilteredBaskets();
    return (
      <>
        <AppBar search={false} title="Produtos" />
        {this.state.isLoading ? (
          <Grid
            container
            style={{ height: "calc(100% - 62px)" }}
            alignItems="center"
            justify="center"
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <SearchBar getInput={this.onSearchBarChange} />

            <List disablePadding={true}>
              <TransitionGroup
                className={classes.prodcutsList}
                component={null}
              >
                {filteredBaskets.map((card, index) => {
                  let cardIsOpen = this.handleCardOpen(card.hash);
                  card.itens = card.itens.filter((item) => item.ativo);
                  return (
                    <CSSTransition
                      timeout={{
                        enter: 300,
                        exit: 500,
                      }}
                      classNames={{
                        enter: classes["fade-enter"],
                        enterActive: classes["fade-enter-active"],
                        exit: classes["fade-exit"],
                        exitActive: classes["fade-exit-active"],
                      }}
                      key={index}
                    >
                      <ListItem disableGutters={true} style={{ padding: "0" }}>
                        <ProductCard
                          data={card}
                          showEditRemove={cardIsOpen.showEditRemove}
                          showRemoveConfirm={cardIsOpen.showRemoveConfirm}
                          handleToggleViewActions={this.handleToggleViewActions}
                          handleToggleRemoveConfirm={
                            this.handleToggleRemoveConfirm
                          }
                          handleRemoveProduct={this.handleRemoveProduct}
                        />
                        <Divider />
                      </ListItem>
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            </List>
          </>
        )}
        <span className={classes.AddButton}>
          <Link to="/products/create">
            <AddButton icon={<AddIcon fontSize="large" />} />
          </Link>
        </span>
      </>
    );
  }
}

Products.contextType = ProductContext;
export default withStyles(styles)(Products);
