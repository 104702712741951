import {
  Button,
  Divider,
  Grid,
  List,
  Typography,
  withStyles,
} from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import * as moment from "moment";
import * as R from "ramda";
import React from "react";
import CalendarPicker from "../../components/Calendar/HeaderDate";
import CardDays from "../../components/CardComponents/CardDaysRouteOpenAndFinish/CardDays";
import Backdrop from "../../components/Dialogs/BackdropLoader";
import AppBar from "../../components/MenuSideBar/AppBar.js";
import { MovementsContext } from "../../contexts/MovementsContext";
import { PrinterContext } from "../../contexts/PrinterContext";
import { ProductContext } from "../../contexts/ProductsContext";
import {
  MAIN_ROUTE,
  generateRouteOpeningExchangeReport,
  listMonthOpeningList,
  rotaIdeal,
} from "../../service/route";
import Moeda from "../../utils/Moeda";
import LocalStorage from "../../utils/localStorage";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  labels: {
    fontSize: ".9rem",
    wordWrap: "break-word",
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingBottom: "4px",
    paddingTop: "4px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "bold",
  },
  data: {
    fontSize: 12,
    wordWrap: "break-word",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "bold",
  },
  spacingCards: {
    backgroundColor: "#F5F5F5",
    padding: "20px",
  },
  cards: {
    position: "relative",
    top: "10px",
  },
  divider2: {
    height: "100%",
    width: "1px",
  },
  title: {
    fontSize: "1.1rem",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 300,
  },
  button: {
    paddingTop: "13px",
    paddingBottom: "15px",
  },
  divider: {
    padding: "10px",
    position: "relative",
    top: "10px",
  },
  link: {
    textDecorantion: "none",
    color: "inherit",
  },
  goalsReportRoot: {
    marginTop: "2vh",
  },
});

class Row extends React.Component {
  render() {
    let { classes } = this.props;
    return (
      <>
        <Grid item xs={5}>
          <Typography align="right" className={classes.labels}>
            {this.props.label}
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" xs={2}>
          <Divider className={classes.divider2} orientation="vertical" />
        </Grid>
        <Grid item container alignItems="center" xs={5}>
          <Typography align="left" className={classes.data}>
            {this.props.value}
          </Typography>
        </Grid>
      </>
    );
  }
}

const DEFAULT_BACKDROP_TEXT = "Recuperando aberturas...";

/**
 * Tela de relatório de metas.
 */
class GoalsReport extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {boolean} this.state.newDate Estado que armazerna a Data do relatório a ser exibido
   */
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      newDate: new Date(),
      vendaIdeal: 0,
      vendaAlcancada: 0,
      venda: 0,
      soldBaskets: {},
      recebimentoIdeal: 0,
      recebimentoAlcancado: 0,
      recebimento: 0,
      dailyList: false,
      days: [],
      backdropText: DEFAULT_BACKDROP_TEXT,
    };
    this.setDate = this.setDate.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.handleCardIcon = this.handleCardIcon.bind(this);
    this.toggleBody = this.toggleBody.bind(this);
    this.handleBackDropClose = this.handleBackDropClose.bind(this);
    this.openPrinterView = this.openPrinterView.bind(this);
    this.renderDailyCardState = this.renderDailyCardState.bind(this);
    this.handleClickExchangeReport = this.handleClickExchangeReport.bind(this);
    this.setActiveIndex = this.setActiveIndex.bind(this);
  }

  async componentDidMount() {
    try {
      await this.getBasketSalesInfo(this.state.newDate);
      await this.getRouteGoals();
      await this.getListMonthOpeningList();
      this.setState({ isLoading: false });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getListMonthOpeningList() {
    try {
      const days = await listMonthOpeningList();
      console.log(days);
      this.setState({ days });
    } catch (error) {
      console.error(error);
    }
  }

  async getRouteGoals(date) {
    let routeInfo;

    if (date) {
      routeInfo = await rotaIdeal(date);
    } else {
      routeInfo = await LocalStorage.instance.getItem(MAIN_ROUTE);
    }

    const salePercent = Moeda.create(
      Moeda.create(routeInfo.alcancado.venda).div(routeInfo.ideal.venda)
    ).mul(100);

    const receivementPercent = Moeda.create(
      Moeda.create(routeInfo.alcancado.recebimento).div(
        routeInfo.ideal.recebimento
      )
    ).mul(100);

    this.setState({
      vendaIdeal: routeInfo.ideal.venda,
      vendaAlcancada: routeInfo.alcancado.venda,
      recebimentoIdeal: Moeda.create(routeInfo.ideal.recebimento).format(),
      recebimentoAlcancado: routeInfo.alcancado.recebimento.formatted.replace(
        /-/g,
        ""
      ),
      venda: salePercent.mount(),
      recebimento: receivementPercent.mount().replace(/-/g, ""),
    });
  }

  async getBasketSalesInfo(date) {
    const init = moment(date).startOf("month").toDate();
    const final = moment(date).endOf("month").toDate();
    const sales = await this.context.getRouteSales(init, final);
    let soldBaskets = sales.filter(
      (a) => a.CestaVendas && a.CestaVendas.length
    );

    soldBaskets = R.flatten(soldBaskets.map((a) => a.CestaVendas));
    soldBaskets = soldBaskets.reduce((memo, cestaVenda) => {
      const basketId = cestaVenda.CestumId;
      if (!memo[basketId]) memo[basketId] = 0;
      memo[basketId] += parseInt(cestaVenda.quantidade);
      return memo;
    }, {});
    this.setState({ soldBaskets });
  }

  /**Função onde irá armazenar a nova data escolhida.
   * @function
   * @param {date} date Data escolhida pelo usuário
   */
  async setDate(date) {
    try {
      this.setState({ isLoading: true, newDate: date });
      await this.getRouteGoals(date);
      await this.getBasketSalesInfo(date);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getProducts() {}

  /**Função responsável por indicar qual ícone terá o card.
   * @function
   * @param {boolean} isOpen Boleano que caso true, a rota está aberta.
   * @return {icon} Icone.
   */
  handleCardIcon(isOpen) {
    return isOpen ? <InsertDriveFileIcon /> : <InsertDriveFileOutlinedIcon />;
  }

  /**Função irá alternar a exibição entre o  relatório mensal e a lista de relatórios diários.
   * @function
   */
  toggleBody() {
    this.setState({ dailyList: !this.state.dailyList });
  }

  handleBackDropClose() {
    this.setState({ isLoading: false });
  }

  renderProductsSale(productContext) {
    const { classes } = this.props;
    if (Object.keys(this.state.soldBaskets).length > 0) {
      return Object.keys(this.state.soldBaskets).map((basketId) => {
        const amount = this.state.soldBaskets[basketId];
        const product = productContext.storedBaskets.find(
          (c) => c.id == basketId
        );
        const label = product ? product.nome : "Carregando...";
        return (
          <Row label={label} value={amount} classes={classes} key={basketId} />
        );
      });
    } else {
      return <Row classes={classes} label="Não há vendas nesses periodo"></Row>;
    }
  }

  openPrinterView(printerContext, dailyInvoice, reportTitle) {
    return () => {
      const listaDiariaFim = moment(dailyInvoice.listaDiariaFim).format(
        "DD/MM/YYYY"
      );
      const listaDiariaIni = moment(dailyInvoice.listaDiariaInicio).format(
        "DD/MM/YYYY"
      );

      printerContext.callPrinterComponent({
        title: reportTitle || "Relatório de fechamento",
        headerMainText: `${listaDiariaIni} - ${listaDiariaFim}`,
        disableSellerButton: false,
        disableClientButton: true,
        content: dailyInvoice.invoice || "Não há dados",
        viaEmployee: dailyInvoice.invoice || "Não há dados",
        viaClient: dailyInvoice.invoice || "Não há dados",
      });
    };
  }

  renderDailyCardState(el) {
    return el.dataFechamento ? "Rota Fechada" : "Rota Aberta";
  }

  handleClickExchangeReport(context, dailyInvoice) {
    return async () => {
      this.setState({
        isLoading: true,
        backdropText: "Baixando dados de troca",
      });

      const invoice = await generateRouteOpeningExchangeReport(
        dailyInvoice.hash
      );

      this.openPrinterView(
        context,
        {
          listaDiariaFim: dailyInvoice.listaDiariaFim,
          listaDiariaInicio: dailyInvoice.listaDiariaInicio,
          invoice,
        },
        "Relatório de trocas"
      )();

      this.setState({ isLoading: false, backdropText: DEFAULT_BACKDROP_TEXT });
    };
  }

  setActiveIndex(index) {
    if (index === this.state.activeIndex) {
      return this.setState({ activeIndex: null });
    }
    this.setState({ activeIndex: index });
  }

  render() {
    const { classes } = this.props;
    return (
      <ProductContext.Consumer>
        {(productContext) => (
          <>
            <AppBar title="Relatório de Metas" />
            <Backdrop
              open={this.state.isLoading}
              secondaryText={this.state.backdropText}
            />
            {this.state.dailyList ? (
              <PrinterContext.Consumer>
                {(printerContext) => (
                  <div className={classes.goalsReportRoot}>
                    <List>
                      {this.state.days.map((el, index) => {
                        return (
                          <CardDays
                            index={index}
                            onExpand={this.setActiveIndex}
                            expanded={this.state.activeIndex === index}
                            key={el.hash}
                            onClickFechamento={this.openPrinterView(
                              printerContext,
                              el
                            )}
                            onClickTroca={this.handleClickExchangeReport(
                              printerContext,
                              el
                            )}
                            icon={this.handleCardIcon(!!el.dataFechamento)}
                            mainText={`${moment(el.listaDiariaInicio).format(
                              "DD/MM/YYYY"
                            )} - ${moment(el.listaDiariaFim).format(
                              "DD/MM/YYYY"
                            )}`}
                            secondaryText={
                              el.dataAbertura && el.dataFechamento ? (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                >
                                  {`Abertura: ${moment(el.dataAbertura).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}`}
                                  <br />
                                  {`Fechamento: ${moment(
                                    el.dataFechamento
                                  ).format("DD/MM/YYYY HH:mm")}`}
                                </Typography>
                              ) : (
                                "Rota aberta" +
                                (el.dataAbertura
                                  ? ` desde ${moment(el.dataAbertura).format(
                                      "DD/MM/YYYY HH:mm"
                                    )}`
                                  : "")
                              )
                            }
                          />
                        );
                      })}
                    </List>
                    <Grid
                      container
                      justifyContent="center"
                      className={classes.button}
                    >
                      <Button variant="outlined" onClick={this.toggleBody}>
                        Voltar
                      </Button>
                    </Grid>
                  </div>
                )}
              </PrinterContext.Consumer>
            ) : (
              <>
                <CalendarPicker setData={this.setDate} background="#F5F5F5" />

                <Grid
                  container
                  justifyContent="center"
                  className={classes.button}
                >
                  <Button variant="outlined" onClick={this.toggleBody}>
                    Listar relatórios diários
                  </Button>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ padding: "10px" }}
                >
                  <Typography variant="h5" className={classes.title}>
                    METAS
                  </Typography>
                </Grid>

                <Grid container className={classes.spacingCards}>
                  <Row
                    label={"Venda Ideal"}
                    value={this.state.vendaIdeal}
                    classes={classes}
                  />
                  <Row
                    label={"Venda Alcançada"}
                    value={this.state.vendaAlcancada}
                    classes={classes}
                  />
                  <Row
                    label={"Venda %"}
                    value={this.state.venda + "%"}
                    classes={classes}
                  />
                </Grid>

                <Grid
                  container
                  className={classes.spacingCards}
                  style={{ position: "relative", top: "10px" }}
                >
                  <Row
                    label={"Recebimento Ideal"}
                    value={this.state.recebimentoIdeal}
                    classes={classes}
                  />
                  <Row
                    label={"Recebimento Alcançado"}
                    value={this.state.recebimentoAlcancado}
                    classes={classes}
                  />
                  <Row
                    label={"Recebimento %"}
                    value={this.state.recebimento + "%"}
                    classes={classes}
                  />
                </Grid>

                <Grid item className={classes.divider}>
                  <Divider />
                </Grid>

                <Grid
                  container
                  justify="center"
                  style={{ paddingTop: "8px", paddingBottom: "8px" }}
                >
                  <Typography variant="h5" className={classes.title}>
                    PRODUTOS VENDIDOS
                  </Typography>
                </Grid>

                <Grid container className={classes.spacingCards}>
                  {this.renderProductsSale(productContext)}
                </Grid>
              </>
            )}
          </>
        )}
      </ProductContext.Consumer>
    );
  }
}
GoalsReport.contextType = MovementsContext;
export default withStyles(styles)(GoalsReport);
