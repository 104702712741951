import DataAccess from "../DataAccessStrategy";

export default class ClienteEdit extends DataAccess {
  constructor() {
    super("ClienteEdit", {
      id: Number,
      origem: String,
      edit: Object,
      hashRotaAbertura: String,
      hash: String,
      dataRealizacao: Date,
      ativo: Boolean,
      verificado: Boolean,
      appEditedDate: Date,
      appSyncDate: Date,
      shouldSync: Boolean,
      updatedAt: Date,
      createdAt: Date,
      RotaId: Number,
      addedByApp: Boolean,
      isChecked: Boolean,
      FuncionarioId: Number,
      ClienteId: Number,
    });
  }
}
