import React from "react";
import CurrencyInput from "react-currency-masked-input";

/**
 * Componente responsável por renderizar uma lista de cards com infinite scroll.
 */
class AmountMask extends React.Component {
  constructor() {
    super();
  }

  render() {
    const { inputRef, onChange, ...others } = this.props;

    return (
      <CurrencyInput
        {...others}
        name="myInput"
        onChange={this.props.onChange}
        separator={","}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        value={this.props.value}
        onKeyDown={(evt) => {
          (evt.key === "e" || evt.key === "E") && evt.preventDefault();
        }}
        placeholder={this.props.placeholder}
        ref={inputRef}
        type="tel"
      />
    );
  }
}

export default AmountMask;
