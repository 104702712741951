import Dexie from "dexie";
import * as R from "ramda";
import * as moment from "moment";

export default class ErrorLog {
  constructor() {
    const ERROR_LOG = new Dexie("ERROR_LOG");

    ERROR_LOG.version(1.0).stores({
      ConnectionError: "++DATABASE_ID",
      AppStorageError: "++DATABASE_ID",
      RuntimeError: "++DATABASE_ID",
      UnknowError: "++DATABASE_ID",
    });

    ERROR_LOG.version(1.1).stores({
      EventLog: "++DATABASE_ID, employee, timeStamp",
    });

    ERROR_LOG.version(1.2).stores({
      ConnectionError: "++DATABASE_ID, timeStamp",
      AppStorageError: "++DATABASE_ID, timeStamp",
      RuntimeError: "++DATABASE_ID, timeStamp",
      UnknowError: "++DATABASE_ID, timeStamp",
    });

    ERROR_LOG.getAll = this.getAll.bind(this);
    ERROR_LOG.clearOldRecords = this.clearOldRecords.bind(this);
    ErrorLog.database = ERROR_LOG;
  }

  static get database() {
    if (!window.ERROR_LOG)
      throw new Error(
        "Dexie database definition must be initiatate before access it."
      );
    return window.ERROR_LOG;
  }

  static set database(databaseArg) {
    if (window.ERROR_LOG) return;
    window.ERROR_LOG = databaseArg;
  }

  async getAll() {
    const connectionError = await ErrorLog.database.ConnectionError.toArray();
    const appStorageError = await ErrorLog.database.AppStorageError.toArray();
    const runtimeError = await ErrorLog.database.RuntimeError.toArray();
    const unknowError = await ErrorLog.database.UnknowError.toArray();

    return new Promise((resolve, reject) => {
      try {
        const orderedArray = R.sortWith(
          [R.descend(R.prop("timeStamp")), R.descend(R.prop("DATABASE_ID"))],
          [connectionError, appStorageError, runtimeError, unknowError].flat(1)
        );
        resolve(orderedArray);
      } catch (error) {
        reject(error);
      }
    });
  }

  async clearOldRecords() {
    const proms = [];
    const now = moment();
    const getDatabaseId = (record) => record.DATABASE_ID;
    const twoMonthsAgo = now.subtract(2, "months").toDate();

    const connectionError = await ErrorLog.database.ConnectionError.where(
      "timeStamp"
    )
      .below(twoMonthsAgo)
      .toArray();
    const appStorageError = await ErrorLog.database.AppStorageError.where(
      "timeStamp"
    )
      .below(twoMonthsAgo)
      .toArray();
    const runtimeError = await ErrorLog.database.RuntimeError.where("timeStamp")
      .below(twoMonthsAgo)
      .toArray();
    const unknowError = await ErrorLog.database.UnknowError.where("timeStamp")
      .below(twoMonthsAgo)
      .toArray();
    const eventLog = await ErrorLog.database.EventLog.where("timeStamp")
      .below(twoMonthsAgo)
      .toArray();

    proms.push(
      ErrorLog.database.ConnectionError.bulkDelete(
        connectionError.map(getDatabaseId)
      )
    );
    proms.push(
      ErrorLog.database.AppStorageError.bulkDelete(
        appStorageError.map(getDatabaseId)
      )
    );
    proms.push(
      ErrorLog.database.RuntimeError.bulkDelete(runtimeError.map(getDatabaseId))
    );
    proms.push(
      ErrorLog.database.UnknowError.bulkDelete(unknowError.map(getDatabaseId))
    );
    proms.push(
      ErrorLog.database.EventLog.bulkDelete(eventLog.map(getDatabaseId))
    );

    await Promise.all(proms);
  }
}
