/* eslint-disable eqeqeq */
import React from "react";
import LocalStorage from "../../utils/localStorage";
import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Fab from "@material-ui/core/Fab";
import SyncIcon from "@material-ui/icons/Sync";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import Switch from "@material-ui/core/Switch";
import DoneIcon from "@material-ui/icons/Done";
import { SyncContext } from "../../contexts/SyncContext";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import Drawer from "@material-ui/core/Drawer";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import history from "../../router/History";
import { getLocalCongfigVar } from "../../service/config";
import SyncNotificationCenter from "../../service/notifications/syncNotificationCenter";
import {
  DialogMessageByType,
  SyncNotificationDialog,
} from "./SyncNotificationDialog";
import { ExpensesContext } from "../../contexts/ExpensesContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DEFAULT_OPTIONS = [
  {
    references: "syncMainRoute",
    primaryText: "Atualizar rota principal",
    secondaryText: "Atualiza os dados da rota",
  },
  {
    references: "syncBasket",
    primaryText: "Atualizar cestas e produtos",
    secondaryText: "Atualizará as cestas e produtos caso existam novas",
  },
  {
    references: "sendClients",
    primaryText: "Enviar clientes",
    secondaryText: "Envia os clientes que você atendeu hoje",
  },
  {
    references: "syncVehicle",
    primaryText: "Atualizar veículos",
    secondaryText: "Atualiza os veiculos disponivéis",
  },
  {
    references: "syncFuel",
    primaryText: "Atualizar combustiveis",
    secondaryText: "Atualizará os combustiveis caso existam novos",
  },
  {
    references: "syncExpenses",
    primaryText: "Enviar despesas",
    secondaryText: "Envia as despesas do seu dispositivo",
  },
  {
    references: "syncNeighborhood",
    primaryText: "Enviar bairros",
    secondaryText: "Envia os bairros do seu dispositivo",
  },
  {
    references: "syncRouteOpening",
    primaryText: "Enviar abertura de rota",
    secondaryText: "Envia os dados das aberturas da rota",
  },
  {
    references: "syncTeam",
    primaryText: "Atualizar equipe",
    secondaryText: "Atualiza os dados da sua equipe",
  },
  {
    references: "syncCityNeighborhood",
    primaryText: "Atualizar Bairros",
    secondaryText: "Atualiza os dados de bairros",
  },
  {
    references: "syncPaymentForm",
    primaryText: "Atualizar formas de pagamento",
    secondaryText: "Formas de pagamento serão atualizadas",
  },
  {
    references: "syncPaymentMethod",
    primaryText: "Atualizar meio de pagamentos",
    secondaryText: "Meio de pagamentos serão atualizados",
  },
  {
    references: "syncValidators",
    primaryText: "Atualizar validadores da rota",
    secondaryText: "Atualizaos validadores",
  },
  {
    references: "syncRequiredFields",
    primaryText: "Atualizar campos obrigatórios",
    secondaryText: "Atualizará os campos obrigatórios caso existam mudanças",
  },
  {
    references: "syncHoliday",
    primaryText: "Atualizar feriados",
    secondaryText: "Atualizará os feriados caso existam modificações",
  },
  {
    references: "syncDailyMap",
    primaryText: "Enviar a ordem da diaria",
    secondaryText: "Envia a ordem da diaria",
  },
  {
    references: "syncDocuments",
    primaryText: "Atualizar documentos",
    secondaryText: "Recuperar do servidor os dados de documentos",
  },
  {
    references: "syncClients",
    primaryText: "Baixar clientes da rota",
    secondaryText: "Baixará todos os clientes da sua rota",
  },
];
const DEFAULT_STATE = {
  isLoading: false,
  isSynced: false,
  openMenu: false,
  downloadType: "complete",
  selectAll: true,
  preselectedItems: [],
  syncDialog: {
    isOpen: false,
    type: "",
    info: {},
  },
};

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    lineHeight: 1.2,
  },
  floatButton: {
    margin: theme.spacing(1),
    position: "fixed",
    right: "40px",
    bottom: "50px",
    fontSize: ".7rem",
    color: "#fff",
  },
  itemTaskComplete: {
    backgroundColor: "rgba(24, 104, 184, 0.45)",
    textDecoration: "line-through rgba(24, 104, 184, 0.55)",
  },
  loadingState: {
    backgroundColor: "rgba( 0, 0, 0, 0.75 )",
  },
  percentNumber: {
    "font-weight": "bold",
    fontSize: "3em",
  },
  percent: {
    fontSize: "0.5em",
  },
});

/**
 * Componente responsável por renderizar a tela de sincronização.
 * Possui em seu código, duas contantes utilizadas para renderizar os tipos corretos e disponíveis de sincronização, bem como seu texto ao carregar.
 * Tambem possui uma instanciacao do LocalStorage em seu construtor.
 */
class SyncDialog extends React.Component {
  /**
   * @param {array} props.preselectedItems Prop que possui a referencia de todas as opções de sync que devem estar pre selecionadas ao renderizar a tela
   * @param {boolean} props.selectAll Prop que define se todas as opções de sync ja devem vir selecionadas ao renderizar a tela
   * @constant {array} DEFAULT_OPTIONS Constante que contém todas as opções disponíveis de sincronização, bem como bem como seu texto ao ser sincronizado
   * @constant {object} DEFAULT_STATE Constante que contém o estado default dessa classe
   */
  constructor(props) {
    super(props);

    this.localStorage = LocalStorage.instance;

    let { preselectedItems, selectAll } = props;
    let DEFAULT_OPTIONS_STATE = {};
    let updated_DEFAULT_STATE = { ...DEFAULT_STATE };
    if (!preselectedItems || !preselectedItems.length) {
      preselectedItems = [];
    }

    //Update syncOptions property
    DEFAULT_OPTIONS_STATE.syncOptions = DEFAULT_OPTIONS.reduce((memo, opt) => {
      memo[opt.references] = {
        ...memo[opt.references],
        isComplete: false,
        isSelected: selectAll || preselectedItems.indexOf(opt.references) != -1,
      };

      return memo;
    }, {});

    Object.assign(DEFAULT_OPTIONS_STATE, updated_DEFAULT_STATE);
    this.state = { ...DEFAULT_OPTIONS_STATE };

    this.redirectToOpenRoute = this.redirectToOpenRoute.bind(this);
    this.handleSyncNotifier = this.handleSyncNotifier.bind(this);
    this.handleInitSync = this.handleInitSync.bind(this);
    this.notifyselectAll = this.notifyselectAll.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.handleConfigMenuOptionClick =
      this.handleConfigMenuOptionClick.bind(this);
    this.handleselectAllClick = this.handleselectAllClick.bind(this);
    this.handleDialogOnClose = this.handleDialogOnClose.bind(this);
    this.handleDialogOnEnter = this.handleDialogOnEnter.bind(this);
    this.renderListItem = this.renderListItem.bind(this);
  }

  componentDidMount() {
    this.unsubscribe = SyncNotificationCenter.instance.subscribe(
      this.handleSyncNotifier
    );
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  /**
   * Função chamada ao identificar algum erro lançado.
   * Ela altera o estado do componente para interromper a renderização da tela.
   * @function
   */
  componentDidCatch(error, info) {
    this.setState({ isSynced: false, isLoading: false });
    throw error;
  }

  redirectToOpenRoute() {
    history.push("/route/open");
  }

  /**
   * Função responsável por retornar classes dinâmicas aos itens de opções de sincronização.
   * @function
   */
  getItemClassName(references) {
    let { classes } = this.props;
    let itemListClass = null;
    if (references) {
      itemListClass = clsx({
        [classes.itemTaskComplete]:
          this.state.syncOptions[references].isComplete,
      });
    }

    return itemListClass;
  }

  /**
   * Função que é enviada para o contexto (SyncContext) como uma callback. Ela é responsável por tratar o conteúdo mostrado no popup de andamento da sincronização.
   * @function
   */
  handleSyncNotifier(evtName, data) {
    const expectedEvents = [
      "client-block-progress",
      "daily-map-calc",
      "common-sync",
    ];
    if (!expectedEvents.includes(evtName)) return;

    this.setState({
      syncDialog: {
        isOpen: true,
        type: evtName,
        info: data,
      },
    });
  }

  /**
   * Função responsável por iniciar o esquema de sincronização. No meio, ela completa a informação de tipo de download ("parcial" ou "completo") previamente selecionada, para enviar ao contexto de sincronização (SyncContext) juntamente com as opções de sincronização também previamente selecionadas.
   * Por final, quando a sincronização estiver completa, ela lança um AsyncDialog informando ao usuário.
   * @function
   */
  async handleInitSync() {
    try {
      if (this.state.isSynced) {
        this.handleDialogOnClose();
      } else {
        this.setState({ isLoading: true });
        const options = {
          downloadAllClient:
            this.state.downloadType == "complete" ? true : false,
          syncVarEnvs: true,
        };

        for (let key in this.state.syncOptions) {
          options[key] = this.state.syncOptions[key].isSelected;
        }
        await this.context.initActiveSync(options);

        // Set loading
        this.setState({ isLoading: false, isSynced: true });
        this.handleDialogOnClose();

        if (this.contextExpenses) {
          await this.contextExpenses.setConfigUser();
        } else {
          await this.messageContext.addAsyncDialog({
            message:
              "Ocorreu um erro na recuperação de algum dado, caso veja essa mensagem novamente por favor informe ao suporte",
            title: "Recuperação de dados",
            type: "warning",
          });
        }

        await this.messageContext.addAsyncDialog({
          type: "success",
          title: "Sincronização realizada com sucesso!",
          handleConfirm: () => {
            this.props.handleRedirect && this.props.handleRedirect();
            this.messageContext.popDialog();
          },
          hasCloseButton: false,
        });
        return;
      }
    } catch (error) {
      this.setState({ isLoading: false, isSynced: false });
      this.handleDialogOnClose();
    }
  }

  /**
   * Função responsável por verificar todos se todos os itens de opções de sincronização estão selecionados ou não.
   * Ela é chamada pela função onClickListItem(), ou seja, toda vez que algum item é clicado.
   * @function
   */
  notifyselectAll() {
    let totalSelectedItems = 0;
    let totalItems = 0;
    let allSyncOptions = { ...this.state.syncOptions };

    for (const reference in allSyncOptions) {
      if (allSyncOptions[reference]) {
        // allSyncOptions[reference]
        totalItems++;
        if (allSyncOptions[reference].isSelected) {
          totalSelectedItems++;
        }
      }
    }
    if (this.state.selectAll != (totalSelectedItems == totalItems))
      this.setState({ selectAll: totalSelectedItems == totalItems });
  }

  /**
   * Função chamada ao clicar em algum item das opções de sincronização.
   * Função responsável por alterar o estado referente ao valor passado por referência.
   * @function
   */
  onClickListItem(references) {
    return () => {
      const updatedState = !this.state.syncOptions[references].isSelected;

      this.setState(
        {
          syncOptions: {
            ...this.state.syncOptions,
            [references]: {
              ...this.state.syncOptions[references],
              isSelected: updatedState,
            },
          },
        },
        this.notifyselectAll
      );
    };
  }

  /**
   * Função responsável por renderizar ícones dinâmicos para cada item das opções de sincronização.
   * @function
   */
  renderItemListIcon(references) {
    if (
      this.state.isLoading &&
      this.state.syncOptions[references].isSelected &&
      !this.state.syncOptions[references].isComplete
    ) {
      return (
        <ListItemIcon>
          <CircularProgress />
        </ListItemIcon>
      );
    } else if (
      (this.state.syncOptions[references].isComplete || this.state.isLoading) &&
      this.state.syncOptions[references].isSelected
    ) {
      return (
        <ListItemIcon>
          <DoneIcon />
        </ListItemIcon>
      );
    } else {
      return null;
    }
  }

  /**
   * Função que renderiza o componente popup que contém as informações de andamento da sincronização.
   * @function
   */
  renderLoadingMessage() {
    return null;
  }

  handleToggleMenu() {
    this.setState({ openMenu: !this.state.openMenu });
  }

  /**
   * Função que renderiza o componente drawer que contém as opções de Tipos de Download ("parcial" ou "completo").
   * @function
   */
  renderConfigMenu() {
    return (
      <Drawer
        anchor="bottom"
        open={this.state.openMenu}
        onClose={this.handleToggleMenu}
      >
        <List>
          <ListItem
            button
            // value="complete"
            onClick={(e) => this.handleConfigMenuOptionClick("complete")}
          >
            <Radio checked={this.state.downloadType == "complete"} />
            <ListItemText>
              <Typography>Download completo</Typography>
              <Typography variant="body2">Baixa todos os clientes</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            // value="partial"
            onClick={(e) => this.handleConfigMenuOptionClick("partial")}
          >
            <Radio checked={this.state.downloadType == "partial"} />
            <ListItemText>
              <Typography>Download parcial</Typography>
              <Typography variant="body2">
                Baixa somente os clientes necessários
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
    );
  }

  /**
   * Função chamada ao clicar em um item do drawer de Tipos de Download (renderConfigMenu) responsável por modificar o valor de this.state.downloadType para o tipo selecionado.
   * @function
   */
  handleConfigMenuOptionClick(e) {
    if (this.state.downloadType != e) {
      this.setState({ downloadType: e }, () =>
        setTimeout(() => this.setState({ openMenu: false }), 300)
      );
    }
    return;
  }

  /**
   * Função chamada ao clicar no ícone de "marcar/desmarcar todas as opções".
   * Ela é responsável por alterar todos os itens do estado syncOptions para que eles sejam todos marcados como selecionados ou não. Além de também alterar o estado de selectAll.
   * @function
   */
  handleselectAllClick() {
    let temp_syncOptions = { ...this.state.syncOptions };
    for (let item in temp_syncOptions) {
      temp_syncOptions[item] = {
        ...temp_syncOptions[item],
        isSelected: !this.state.selectAll,
      };
    }
    this.setState({
      syncOptions: temp_syncOptions,
      selectAll: !this.state.selectAll,
    });
  }

  /**
   * Função chamada ao fechar o SyncDialog.
   * Ela é responsável por restaurar todos os valores padrões alterados e todos os valores parrados como props. Isto é necessário pois este componente é 'construído' somente uma vez, mesmo após ser fechado.
   * Similar ao construtor deste componente.
   * @function
   */
  handleDialogOnClose() {
    this.props.onClose();
    return;
  }

  /**
   * Função chamada ao abrir o SyncDialog.
   * Ela chama a função de iniciar a sincronização baseado na prop autoSync.
   * Similar ao construtor deste componente.
   * @function
   */
  async handleDialogOnEnter() {
    let { preselectedItems, selectAll } = this.props;
    let DEFAULT_OPTIONS_STATE = {};
    let updated_DEFAULT_STATE = { ...DEFAULT_STATE };
    if (!preselectedItems || !preselectedItems.length) {
      preselectedItems = [];
    } else if (preselectedItems && selectAll) {
      preselectedItems = [];
      selectAll = true;
    }
    //Update selectAll property
    updated_DEFAULT_STATE.selectAll = selectAll;

    //Update downloadType property
    let DownloadType = await getLocalCongfigVar("DownloadType");
    this.setState({
      downloadType: DownloadType,
    });

    updated_DEFAULT_STATE.downloadType = DownloadType;

    //Update syncOptions property
    DEFAULT_OPTIONS_STATE.syncOptions = DEFAULT_OPTIONS.reduce((memo, opt) => {
      memo[opt.references] = {
        ...memo[opt.references],
        isComplete: false,
        isSelected: selectAll || preselectedItems.indexOf(opt.references) != -1,
      };

      //Get total amount of sync options
      return memo;
    }, {});

    Object.assign(DEFAULT_OPTIONS_STATE, updated_DEFAULT_STATE);
    this.setState({ ...DEFAULT_OPTIONS_STATE });

    if (this.props.autoSync) {
      try {
        await this.handleInitSync();
      } catch (error) {
        this.setState({ isLoading: false, isSynced: false });
        this.handleDialogOnClose();
      }
    }
  }

  renderListItem(item) {
    return (
      <ListItem
        className={this.getItemClassName(item.references)}
        button
        disabled={this.state.isLoading || this.state.isSynced}
        onClick={this.onClickListItem(item.references)}
        key={item.references}
      >
        {this.renderItemListIcon(item.references)}
        <ListItemText
          primary={`${item.primaryText}`}
          secondary={item.secondaryText}
        />
        {!this.state.isSynced ? (
          <ListItemIcon>
            <Switch
              checked={this.state.syncOptions[item.references].isSelected}
              color="primary"
            />
          </ListItemIcon>
        ) : null}
      </ListItem>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <MessageDialogContext.Consumer>
        {(messageContext) => {
          if (this.messageContext != messageContext) {
            this.messageContext = messageContext;
          }
          return (
            <ExpensesContext.Consumer>
              {(context) => {
                if (this.contextExpenses !== context)
                  this.contextExpenses = context;
                return (
                  <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.handleDialogOnClose}
                    onEnter={this.handleDialogOnEnter}
                    TransitionComponent={Transition}
                  >
                    <AppBar className={classes.appBar} position="fixed">
                      <Toolbar>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={this.handleDialogOnClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                          Configurações da sincronização
                        </Typography>
                        {!this.state.isSynced && (
                          <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="close"
                            style={{ padding: 0 }}
                          >
                            <Checkbox
                              icon={
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{ padding: "5px" }}
                                >
                                  <PlaylistAddCheckIcon />
                                </Grid>
                              }
                              checkedIcon={
                                <Grid
                                  container
                                  alignItems="center"
                                  style={{
                                    padding: "5px",
                                    borderRadius: 4,
                                    background: "#65acef",
                                  }}
                                >
                                  <PlaylistAddCheckIcon />
                                </Grid>
                              }
                              style={{ color: "#fff" }}
                              checked={this.state.selectAll}
                              onChange={this.handleselectAllClick}
                              name="selectAll"
                            />
                          </IconButton>
                        )}
                        <IconButton
                          edge="end"
                          color="inherit"
                          onClick={this.handleToggleMenu}
                          aria-label="close"
                        >
                          <MoreVertIcon />
                        </IconButton>
                        {this.renderConfigMenu()}
                      </Toolbar>
                    </AppBar>
                    <List disablePadding>
                      {DEFAULT_OPTIONS.map(this.renderListItem)}
                    </List>
                    <SyncNotificationDialog
                      open={this.state.syncDialog.isOpen}
                      totalItems={this.state.syncDialog.info.totalItems}
                      title={this.state.syncDialog.info.title}
                      storedItems={this.state.syncDialog.info.storedItems}
                      dialogMessage={this.state.syncDialog.info.dialogMessage}
                      totalSyncItems={this.state.syncDialog.info.totalSyncItems}
                      downloadedItems={
                        this.state.syncDialog.info.downloadedItems
                      }
                      totalCompleteSyncItems={
                        this.state.syncDialog.info.totalCompleteSyncItems
                      }
                    >
                      <DialogMessageByType
                        type={this.state.syncDialog.type}
                        data={this.state.syncDialog.info}
                      />
                    </SyncNotificationDialog>
                    <Fab
                      onClick={this.handleInitSync}
                      aria-label="sync"
                      className={classes.floatButton}
                      color={this.state.isLoading ? "default" : "primary"}
                      disabled={this.state.isLoading ? true : false}
                    >
                      {this.state.isSynced ? (
                        <CheckIcon fontSize="large" />
                      ) : (
                        <SyncIcon fontSize="large" />
                      )}
                    </Fab>
                  </Dialog>
                );
              }}
            </ExpensesContext.Consumer>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

SyncDialog.contextType = SyncContext;
export default withStyles(styles)(SyncDialog);
