import React from "react";
import { withStyles, Divider, Grid } from "@material-ui/core";
import Header from "../HeaderForAdd/Header";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Report from "../Report/ReceivementFinalReport";
import moment from "moment";
import Slide from "@material-ui/core/Slide";
import SyncDialog from "../Dialogs/SyncDialog";
import BackNextButton from "../Buttons/BackNextButton";
import { generateCloseRouteReport } from "../../service/movements";
import { OpenRouteContext } from "../../contexts/OpenRouteContext";
import Backdrop from "../Dialogs/BackdropLoader";
const styles = (theme) => ({
  report: {
    paddingTop: "1vh",
  },
  buttons: {
    position: "fixed",
    bottom: "20px",
    backgroundColor: "none",
  },
  textArea: { border: "none", width: "100%", padding: "0 15px" },
});

const closeRoute = [
  "syncExpenses",
  "sendClients",
  "syncNeighborhood",
  "syncDailyMap",
  "syncRouteOpening",
  "syncMainRoute",
  "syncTeam",
  "syncCityNeighborhood",
  "syncBasket",
  "syncVehicle",
  "syncFuel",
  "syncVarEnvs",
];

/**
 * Tela do relatório de recebimento
 */
class Closure extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {function} props.providePreviousScene Função responsável por voltar para a cena anterior.
   * @param {function} props.provideNextScene Função responsável por prover a próxima cena.
   * @param {string} props.slideDirection Direção em que o slide irá se movimentar.
   */
  constructor() {
    super();
    this.state = {
      isOpenSyncModal: false,
      invoice: "",
      isLoading: true,
    };
    this.onClickGoBack = this.onClickGoBack.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentDidMount() {
    this.getClosureInfo();
  }

  async getClosureInfo() {
    let invoice = this.props.defaultValues.closureInvoice;
    const isLoading = false;
    invoice = await generateCloseRouteReport();
    this.props.onDataChange({ closureInvoice: invoice });
    this.setState({ invoice, isLoading });
  }

  /**Função responsável por voltar para a cena anterior.
   * @function
   */
  onClickGoBack() {
    this.props.providePreviousScene({ slideDirection: "right" });
  }

  /**Função responsável pela exibição do SyncDialog.
   * @function
   */
  onClickSave() {
    this.setState({ isOpenSyncModal: true });
  }

  /**Função responsável por prover a próxima cena.
   * @function
   */
  handleRedirect() {
    this.context.onConfirmCloseRoute().then((resp) => {
      this.props.provideNextScene({ slideDirection: "left" });
    });
  }

  handleModalClose() {
    this.setState({ isOpenSyncModal: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <Slide
        direction="left"
        in={true}
        mountOnEnter
        unmountOnExit
        style={{ minHeight: "100%" }}
      >
        <div>
          <Backdrop open={this.state.isLoading} />
          <Grid container>
            <Grid item xs={12}>
              <Header
                icon={<ArrowBackIcon fontSize="large" />}
                name={moment().format("DD/MM/YYYY H:mm")}
                func={this.onClickGoBack}
              >
                Fechar Rota
              </Header>

              <Divider />

              <Grid container justifyContent="center">
                <Grid item xs={10} className={classes.report}>
                  <Report title="Fechamento de rota">
                    <textarea
                      value={this.state.invoice}
                      disabled
                      className={classes.textArea}
                    />
                  </Report>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.buttons}>
              <BackNextButton
                onClickGoBack={this.onClickGoBack}
                onClickGoNext={this.onClickSave}
                text2="SALVAR E IMPRIMIR"
              />
            </Grid>

            <SyncDialog
              preselectedItems={closeRoute}
              handleRedirect={this.handleRedirect}
              open={this.state.isOpenSyncModal}
              autoSync={true}
              onClose={this.handleModalClose}
            />
          </Grid>
        </div>
      </Slide>
    );
  }
}
Closure.contextType = OpenRouteContext;
export default withStyles(styles)(Closure);
