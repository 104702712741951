import React, { createContext } from "react";
import RotaService, {
  sendingRouteOpening,
  generateRouteOpeningExchangeReport,
  team,
  generateRouteOpeningInvoiceServer,
} from "../service/route/index";
import { generateCloseRouteReport } from "../service/movements";
import { MessageDialogContext } from "./MessageDialogContext";
import { Veiculo, Funcionario } from "../repository/models";
import { DailyListContext } from "./DailyListContext";
import AppStorageError from "../errorDefinition/AppStorageError";
import { AbstractContext } from "./AbstractContext";
import * as moment from "moment";
import { Typography } from "@material-ui/core";
import { getConfigKey } from "../service/config";
import LocalStorage from "../utils/localStorage";

const localStorage = LocalStorage.instance;

const rotaService = new RotaService();
const veiculo = new Veiculo();
const funcionario = new Funcionario();

const OpenRouteContext = createContext({
  openRoute: [],
});

const info = (context, message, title) =>
  context.addDialog({
    message: message
      ? message
      : "Um combustível com esse nome já foi cadastrado, tente um outro nome!",
    title: title ? title : "Oops, houve um problema",
    type: "info",
    hasCloseButton: false,
  });

const success = (context, message) =>
  context.addAsyncDialog({
    message: message,
    title: "Sucesso!",
    type: "success",
    hasCloseButton: false,
  });

const OBSERVED_MODELS = [
  "Bairro",
  "RotaAbertura",
  "Veiculo",
  "Funcionario",
  "Cliente",
  "ClienteAgendamento",
  "ClienteBairro",
  "ClienteDiaria",
];
const OBSERVED_KEYS = [
  "route-opening",
  "main-team",
  "main-route",
  "logged",
  "dailyMap",
];
class OpenRouteContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS, OBSERVED_KEYS);
    this.observable = this.observable.bind(this);
    this.verifyRouteIsOpen = this.verifyRouteIsOpen.bind(this);
    this.updateSelf = this.updateSelf.bind(this);
    this.createRoute = this.createRoute.bind(this);
    this.cleanFilter = this.cleanFilter.bind(this);
    this.buildSuccessMessage = this.buildSuccessMessage.bind(this);
    this.delete = this.delete.bind(this);
    this.getCloseRouteData = this.getCloseRouteData.bind(this);
    this.getRouteOpeningExchangeReport =
      this.getRouteOpeningExchangeReport.bind(this);
    this.getRouteOpeningInvoiceServer =
      this.getRouteOpeningInvoiceServer.bind(this);
    this.onCloseRoute = this.onCloseRoute.bind(this);
    this.onConfirmCloseRoute = this.onConfirmCloseRoute.bind(this);
    this.localStorage = LocalStorage.instance;
    this.state = {
      team,
      vehicle: [],
      updated: false,
      routeIsOpen: false,
      delete: this.delete,
      updateSelf: this.updateSelf,
      createRoute: this.createRoute,
      onCloseRoute: this.onCloseRoute,
      getCloseRouteData: this.getCloseRouteData,
      onConfirmCloseRoute: this.onConfirmCloseRoute,
      cleanFilter: this.cleanFilter,
      getRouteOpeningExchangeReport: this.getRouteOpeningExchangeReport,
      getRouteOpeningInvoiceServer: this.getRouteOpeningInvoiceServer,
    };
    this.dialogMessageContext = React.createRef();
  }

  async componentDidMount() {
    await super.componentDidMount();
    await this.updateSelf().catch(console.error);
    await this.verifyRouteIsOpen().catch((err) => {
      console.error(err);
      this.setState({ routeIsOpen: false });
    });
  }

  async observable() {
    await this.updateSelf().catch(console.error);
    await this.verifyRouteIsOpen().catch((err) => {
      console.error(err);
      this.setState({ routeIsOpen: false });
    });
    await this.handleReceiveRouteOpening().catch(console.error);
  }
  /**Função responsável por Verificar se a rota esta aberta.
   * @function
   */
  async verifyRouteIsOpen() {
    let routeIsOpen = await rotaService.routeOpening();
    if (routeIsOpen) {
      this.setState({ routeIsOpen: true });
    } else if (this.state.routeIsOpen) {
      this.dialogMessageContext.current.addDialog({
        message: "A abertura foi encerrada.",
        type: "info",
        title: "Rota fechada!",
        showCancelButton: false,
      });
      this.setState({ routeIsOpen: false });
    } else {
      this.setState({ routeIsOpen: false });
    }
  }

  async handleReceiveRouteOpening() {
    const routeOpenningOnDevice = await rotaService.routeOpening();
    if (routeOpenningOnDevice) return;
    const opcached = await localStorage.getItem("openingCache");
    const openingCached = opcached && JSON.parse(opcached);

    if (!openingCached) return;
    if (openingCached.dataFechamento) return;
    if (openingCached.separar) {
      const employeeOpenedRoute = (
        await funcionario.getAll("id", [openingCached.FuncionarioId])
      )[0];
      if (employeeOpenedRoute) {
        const team = await rotaService.team();
        if (!(employeeOpenedRoute.EquipeId == team.id)) {
          return;
        }
      }
    }

    openingCached.listaDiariaInicio = moment(
      openingCached.listaDiariaInicio,
      "DD/MM/YYYY"
    )
      .startOf("day")
      .toDate();

    openingCached.listaDiariaFinal = moment(
      openingCached.listaDiariaFinal,
      "DD/MM/YYYY"
    )
      .endOf("day")
      .toDate();

    await this.createRoute(openingCached);
  }

  /**Função responsável por atualizar os dados armazenados no estado.
   * @function
   */
  async updateSelf() {
    let team = await rotaService.team();
    const vehicles = await veiculo.filter((a) => a.ativo);
    const isBitboxEmployee = await getConfigKey("isBitboxEmployee");
    this.setState({
      vehicle: vehicles,
    });

    if (team)
      this.setState({
        employee: await funcionario.filter(
          (a) => a.ativo && a.EquipeId == team.id
        ),
        teamVehicle: team.VeiculoId,
        isBitboxEmployee,
      });
  }

  /**Função responsável pela abertura de uma rota.
   * @function
   */
  async createRoute(data) {
    try {
      const shouldLog = false;
      const route = await rotaService.routeOpening(data);
      let employee = await funcionario.getAll("id", route.cobrador);
      if (!employee[0]) return;
      employee[0].hashRotaAbertura = route.hash;

      await sendingRouteOpening(route);

      await funcionario.put(employee[0], shouldLog);
      employee = await funcionario.getAll("id", route.motorista);
      employee[0].hashRotaAbertura = route.hash;
      await funcionario.put(employee[0], shouldLog);

      if (data.palma) {
        await success(
          this.dialogMessageContext.current,
          "Rota aberta para bater palma!"
        );
        this.setState({ routeIsOpen: true });
      } else {
        info(
          this.dialogMessageContext.current,
          "Estamos calculando sua lista diaria",
          "Lista diaria"
        );
        const forceReacalculateDailyList = true;
        await this.context.calculateDailyList(forceReacalculateDailyList);
        await success(
          this.dialogMessageContext.current,
          this.buildSuccessMessage(
            route.listaDiariaInicio,
            route.listaDiariaFinal
          )
        );
        this.setState({ routeIsOpen: true });
        this.cleanFilter();
      }
      localStorage.removeItem("openingCache");
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao abrir a rota!",
        type: "error",
        method: "createRoute",
        error,
      });
      throw error;
    }
  }

  buildSuccessMessage(start, end) {
    return (
      <>
        <Typography>Abertura de rota realizada com sucesso</Typography>
        <Typography>
          Lista diaria de <b>{start}</b> até <b>{end}</b>
        </Typography>
      </>
    );
  }

  /**Função responsável por limpar a rota abertura.
   * @function
   */
  async delete() {
    try {
      await rotaService.cleanRoute();
      await this.updateSelf("employee");
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao limpar a rota!",
        type: "error",
        method: "delete",
      });
      throw error;
    }
  }

  /**Função responsável por pegar os dados que serão exibidos no fechamento da rota.
   * @function
   */
  async getCloseRouteData() {
    try {
      return await rotaService.getCloseRouteData();
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao buscar os dados para o fechamento!",
        type: "error",
        method: "getCloseRouteData",
      });
      throw error;
    }
  }

  /**Função responsável por pegar o relatório de trocas versão servidor.
   * @function
   */
  async getRouteOpeningExchangeReport(hash) {
    try {
      if (!hash) {
        const routeOpening = await rotaService.routeOpening();
        if (!routeOpening) return "Dados da abertura não encontrados";
        hash = routeOpening.hash;
      }
      const exchangeReport = await generateRouteOpeningExchangeReport(hash);
      return exchangeReport;
    } catch (error) {
      return "Dados da abertura não encontrados";
    }
  }

  /**Função responsável por pegar o relatório de fechamento de rota versão servidor.
   * @function
   */
  async getRouteOpeningInvoiceServer(hash) {
    try {
      if (!hash) {
        const routeOpening = await rotaService.routeOpening();
        if (!routeOpening) return "Dados da abertura não encontrados";
        hash = routeOpening.hash;
      }
      const exchangeReport = await generateRouteOpeningInvoiceServer(hash);
      return exchangeReport;
    } catch (error) {
      return "Dados da abertura não encontrados";
    }
  }

  async onCloseRoute(data) {
    try {
      const filledData = await rotaService.closeRoute(data);
      // Update invoice of main-route
      await generateCloseRouteReport(filledData);
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao fechar a rota!",
        type: "error",
        method: "onCloseRoute",
        error,
      });
      throw error;
    }
  }

  /**Função responsável por limpar a rota abertura.
   * @function
   */
  async onConfirmCloseRoute() {
    await rotaService.cleanRouteOpening();
    this.setState({ routeIsOpen: false });
    this.cleanFilter();
  }

  async cleanFilter() {
    const localConfig = await localStorage.getItem("local-config");
    if (localConfig.togglePersist) return;
    window.localStorage.removeItem("daily");
    window.localStorage.removeItem("repass");
    window.localStorage.removeItem("debtor");
    window.localStorage.removeItem("settled");
    window.localStorage.removeItem("defaulter");
    window.localStorage.removeItem("all");
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.dialogMessageContext.current !== context)
            this.dialogMessageContext.current = context;
          return (
            <OpenRouteContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </OpenRouteContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

OpenRouteContextProvider.contextType = DailyListContext;
export { OpenRouteContext, OpenRouteContextProvider };
