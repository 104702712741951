import AbstractError from "./AbstractError";
import { RuntimeError } from "../repository/errorsModels";

export default class BusinessError extends AbstractError {
  constructor(helperData = {}) {
    helperData.devHelper = "Regra de negocio não atendida";
    helperData = Object.assign(
      {
        message: "Uma regra de negocio foi quebrada",
        title: "Oops, houve um problema",
        type: "warning",
      },
      helperData
    );
    super(helperData, new RuntimeError());
  }
}
