import React from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

// import FuncionarioRegister from "../../../components/FuncionarioRegister";
// import ScenesPage from '../../../components/AbstractComponents/ScenesPage';

class RegisterBody extends React.Component {
  render() {
    return (
      <Grid item>
        <Typography variant="h5" gutterBottom>
          Criando Um Novo Usuário
        </Typography>
        <hr style={{ marginBottom: "15px" }}></hr>
      </Grid>
    );
  }
}

export default RegisterBody;
