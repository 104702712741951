import DataAccess from "../DataAccessStrategy";

export default class DespesaRota extends DataAccess {
  constructor() {
    super("DespesaRota", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      descricao: String,
      numeroDocumento: String,
      fornecedor: String,
      valor: String,
      data: String,
      ativo: Boolean,
      RotaId: Number,
      RotaAberturaId: Number,
      hashRotaAbertura: String,
      hash: String,
    });
    this.filterDifferentHashRotaAbertura =
      this.filterDifferentHashRotaAbertura.bind(this);
    this.filterByHashRotaAbertura = this.filterByHashRotaAbertura.bind(this);
  }
  async filterDifferentHashRotaAbertura(routeOpening) {
    if (routeOpening) {
      return await this.model
        .where("hashRotaAbertura")
        .notEqual(routeOpening.hash)
        .filter((a) => a.ativo != false)
        .toArray();
    } else {
      return await this.model.toArray();
    }
  }
  async filterByHashRotaAbertura(routeOpening) {
    if (routeOpening) {
      return await this.model
        .where("hashRotaAbertura")
        .equals(routeOpening.hash)
        .filter((a) => a.ativo != false)
        .toArray();
    } else {
      return await this.model.toArray();
    }
  }
}
