import AbstractError from "./AbstractError";
import { RuntimeError } from "../repository/errorsModels";

export default class SessionDataLostError extends AbstractError {
  constructor(helperData = {}) {
    helperData.devHelper =
      "Houve um problema ao recuperar as informações do usuario";

    helperData = Object.assign(
      {
        message: "Seu token de acesso expirou",
        title: "Sua sessão expirou",
        type: "error",
      },
      helperData
    );
    super(helperData, new RuntimeError());
  }
}
