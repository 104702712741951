import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({});

/**
 * Componente que renderiza um skeleton para os cards da lista diária.
 */
class CardSkeleton extends React.Component {
  render() {
    return (
      <>
        <Grid container justify="center" style={{ paddingBottom: "20px" }}>
          <Skeleton variant="circle" width={"40px"} height={"30px"} />
          <Skeleton variant="text" width={"270px"} />
          <Skeleton variant="rect" width={"310px"} height={40} />
        </Grid>
      </>
    );
  }
}
export default withStyles(styles)(CardSkeleton);
