import React from "react";
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import HomeIcon from "@material-ui/icons/Home";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ReceiptIcon from "@material-ui/icons/Receipt";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import RoomIcon from "@material-ui/icons/Room";
import { Link } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";
import { MessageDialogContext } from "../../../contexts/MessageDialogContext";
import { OpenRouteContext } from "../../../contexts/OpenRouteContext";
import history from "../../../router/History";
import { version } from "../../../../package.json";

const styles = (theme) => ({
  styleItemText: {
    fontSize: 15,
    marginLeft: -16,
  },
  styleListItem: {
    marginTop: -5,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    "& p": {
      color: theme.palette.text.secondary,
      fontSize: "1.8rem",
    },
  },
});

/**
 * Lista de links do menu.
 */
class LinksSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onClickSync = this.onClickSync.bind(this);
    this.handleLogOutClick = this.handleLogOutClick.bind(this);
  }

  onClickSync() {
    this.props.onClickSync(true);
  }

  async handleLogOutClick() {
    let logoutDialog = await this.messageDialogContext.addAsyncDialog({
      type: "warning",
      title: "Logout",
      message: "Tem certeza que deseja se deslogar do aplicativo?",
      okText: "Sair",
      disableBackdropClick: false,
    });
    if (logoutDialog) {
      this.setState({ isLoading: true });
      await this.context.logoutUser();
      this.setState({ isLoading: false });
    }
    return;
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
      <MessageDialogContext.Consumer>
        {(messageDialogContext) => {
          if (this.messageDialogContext != messageDialogContext) {
            this.messageDialogContext = messageDialogContext;
          }
          return (
            <>
              <List>
                <Link to="/" className={classes.link}>
                  <ListItem
                    button
                    key={"Inicio"}
                    className={classes.styleListItem}
                  >
                    <ListItemIcon>
                      <>
                        <HomeIcon fontSize="small" />
                      </>
                    </ListItemIcon>
                    <ListItemText
                      primary={"Inicio"}
                      className={classes.styleItemText}
                    />
                  </ListItem>
                </Link>
                <OpenRouteContext.Consumer>
                  {({ routeIsOpen }) =>
                    routeIsOpen ? (
                      <Link to="/route/close" className={classes.link}>
                        <ListItem
                          button
                          key={"Fechar Rota"}
                          className={classes.styleListItem}
                        >
                          <ListItemIcon>
                            <RoomIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText
                            primary={"Fechar Rota"}
                            className={classes.styleItemText}
                          />
                        </ListItem>
                      </Link>
                    ) : (
                      <ListItem
                        button
                        key={"Abrir Rota"}
                        className={classes.styleListItem}
                        onClick={this.onClickSync}
                      >
                        <ListItemIcon>
                          <RoomIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                          primary={"Abrir Rota"}
                          className={classes.styleItemText}
                        />
                      </ListItem>
                    )
                  }
                </OpenRouteContext.Consumer>
                <Link
                  to={{
                    pathname: "/client/new",
                    state: {
                      ...history.location.state,
                      prevUrl: history.location.pathname,
                    },
                  }}
                  className={classes.link}
                >
                  <ListItem
                    button
                    key={"Novo Cliente"}
                    className={classes.styleListItem}
                  >
                    <ListItemIcon>
                      {" "}
                      <PersonAddIcon fontSize="small" />{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Novo Cliente"}
                      className={classes.styleItemText}
                    />
                  </ListItem>
                </Link>
                <Link to="/expenses" className={classes.link}>
                  <ListItem
                    button
                    key={"Despesas"}
                    className={classes.styleListItem}
                  >
                    <ListItemIcon>
                      {" "}
                      <MoneyOffIcon fontSize="small" />{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Despesas"}
                      className={classes.styleItemText}
                    />
                  </ListItem>
                </Link>
              </List>
              <Divider style={{ marginTop: -7 }} />
              <List>
                <Link to="/sort/clients" className={classes.link}>
                  <ListItem
                    button
                    key={"Ordenar Clientes"}
                    className={classes.styleListItem}
                  >
                    <ListItemIcon>
                      {" "}
                      <SortByAlphaIcon fontSize="small" />{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Ordenar Clientes"}
                      className={classes.styleItemText}
                    />
                  </ListItem>
                </Link>
                <Link to="/sort/neighborhoods" className={classes.link}>
                  <ListItem
                    button
                    key={"Ordenar Bairros"}
                    className={classes.styleListItem}
                  >
                    <ListItemIcon>
                      {" "}
                      <SortByAlphaIcon fontSize="small" />{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Ordenar Bairros"}
                      className={classes.styleItemText}
                    />
                  </ListItem>
                </Link>
                <Link to="/report/goals" className={classes.link}>
                  <ListItem
                    button
                    key={"Relatório de metas"}
                    className={classes.styleListItem}
                  >
                    <ListItemIcon>
                      {" "}
                      <ReceiptIcon fontSize="small" />{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Relatório de metas"}
                      className={classes.styleItemText}
                    />
                  </ListItem>
                </Link>

                <Link to="/report/voucher" className={classes.link}>
                  <ListItem
                    button
                    key={"Relatório de Vale"}
                    className={classes.styleListItem}
                  >
                    <ListItemIcon>
                      {" "}
                      <ReceiptIcon fontSize="small" />{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Relatório de Vale"}
                      className={classes.styleItemText}
                    />
                  </ListItem>
                </Link>

                {!this.context.isBitboxEmployee && (
                  <Link to="/documents" className={classes.link}>
                    <ListItem
                      button
                      key={"Documentos"}
                      className={classes.styleListItem}
                    >
                      <ListItemIcon>
                        {" "}
                        <FileCopyIcon fontSize="small" />{" "}
                      </ListItemIcon>
                      <ListItemText
                        primary={"Documentos"}
                        className={classes.styleItemText}
                      />
                    </ListItem>
                  </Link>
                )}

                <Link to="/schedulings" className={classes.link}>
                  <ListItem
                    button
                    key={"Agendamentos"}
                    className={classes.styleListItem}
                  >
                    <ListItemIcon>
                      {" "}
                      <QueryBuilderIcon fontSize="small" />{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Agendamentos"}
                      className={classes.styleItemText}
                    />
                  </ListItem>
                </Link>

                {!this.context.isBitboxEmployee && (
                  <Link to="/products" className={classes.link}>
                    <ListItem
                      button
                      key={"Produtos"}
                      className={classes.styleListItem}
                    >
                      <ListItemIcon>
                        {" "}
                        <ShoppingBasketIcon fontSize="small" />{" "}
                      </ListItemIcon>
                      <ListItemText
                        primary={"Produtos"}
                        className={classes.styleItemText}
                      />
                    </ListItem>
                  </Link>
                )}
              </List>
              <Divider style={{ marginTop: -7 }} />
              <List>
                <Link to="/profile" className={classes.link}>
                  <ListItem
                    button
                    key={"Perfil"}
                    className={classes.styleListItem}
                  >
                    <ListItemIcon>
                      {" "}
                      <AccountCircleIcon fontSize="small" />{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Perfil"}
                      className={classes.styleItemText}
                    />
                  </ListItem>
                </Link>
                <Link to="/configuration" className={classes.link}>
                  <ListItem
                    button
                    key={"Configurações"}
                    className={classes.styleListItem}
                  >
                    <ListItemIcon>
                      {" "}
                      <SettingsIcon fontSize="small" />{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Configurações"}
                      className={classes.styleItemText}
                    />
                  </ListItem>
                </Link>
                {!this.context.isBitboxEmployee && (
                  <ListItem
                    button
                    key={"Seu plano"}
                    className={classes.styleListItem}
                  >
                    <ListItemIcon>
                      {" "}
                      <EmojiEventsIcon fontSize="small" />{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Seu plano"}
                      className={classes.styleItemText}
                    />
                  </ListItem>
                )}

                <Link to="/support" className={classes.link}>
                  <ListItem
                    button
                    key={"Suporte"}
                    className={classes.styleListItem}
                  >
                    <ListItemIcon>
                      {" "}
                      <HeadsetMicIcon fontSize="small" />{" "}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Suporte"}
                      className={classes.styleItemText}
                    />
                  </ListItem>
                </Link>
                <ListItem
                  onClick={this.handleLogOutClick}
                  button
                  key={"Logout"}
                  className={classes.styleListItem}
                >
                  <ListItemIcon>
                    {" "}
                    <ExitToAppIcon fontSize="small" />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary={"Sair"}
                    className={classes.styleItemText}
                  />
                </ListItem>
                <Divider style={{ marginTop: -7 }} />
                <ListItem key={"Version"} className={classes.styleListItem}>
                  <ListItemIcon>
                    <PhoneIphoneIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Versão ${version}`}
                    className={classes.styleItemText}
                  />
                </ListItem>
              </List>
              <Backdrop
                className={classes.backdrop}
                open={!!this.state.isLoading}
              >
                <CircularProgress />
              </Backdrop>
            </>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

LinksSideBar.contextType = UserContext;
export default withStyles(styles)(LinksSideBar);
