import React from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles, Grid, Typography } from "@material-ui/core/";
import MenuIcon from "@material-ui/icons/Menu";
import SideBar from "./SideBar/SideBar";
import { UserContext } from "../../contexts/UserContext";

const styles = (theme) => ({
  root: {
    minHeight: 62,
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: 300,
    animation: "scroll-left 10s linear infinite",
    lineHeight: 1,
  },
  outerContainer: {
    background:
      "linear-gradient(right, rgba(131,139,187,1) , rgba(107,137,218,1) )",
    padding: "10px 15px",
  },
  innerContainer: {
    backgroundColor: "#1b2f66",
    borderRadius: 5,
    padding: "7px 0",
    minHeight: "42px",
    boxShadow: "0px 2px 15px -2px #2f2f2fa8",
  },
  middleText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  input: {
    border: 0,
    backgroundColor: "transparent",
    height: "100%",
    outline: "none",
    color: "#fff",
    fontSize: "1rem",
  },
  menuButton: {
    color: "#D3D3D3",
    padding: "0",
  },

  bigA: {
    fontSize: "1.8rem",
  },
  mediumB: {
    fontSize: "1.5rem",
  },
});

/**
 * Componente que emgloba o menu e a barra superior do aplicativo.
 */
class MenuToolbar extends React.Component {
  /**
   * @property {boolean} this.state.input Boleano que altera a exibição da logo e do input
   * @property {boolean} this.state.sideBar  Boleano que altera a exibição do menu lateral
   */
  constructor(props) {
    super(props);
    this.state = {
      sideBar: false,
      input: false,
    };
    this.handleToggleSideBar = this.handleToggleSideBar.bind(this);
    this.clickInput = this.clickInput.bind(this);
    this.inputRef = React.createRef();
  }

  /**Função que altera o estado de exibição do menu.
   * @function
   */
  handleToggleSideBar() {
    this.setState({ sideBar: !this.state.sideBar });
  }

  /**Função que altera o estado de exibição do do input na barra superior.
   * @function
   */
  clickInput() {
    if (this.props.search) {
      this.setState({ input: !this.state.input });
      setTimeout(() => {
        if (this.state.input) {
          if (!this.inputRef.current) return;
          this.inputRef.current.focus();
        }
      }, 300);
    }
  }

  getDefaultTitle() {
    return this.context.isBitboxEmployee ? (
      <Typography className={this.props.classes.title}>
        <span className={this.props.classes.bigA}>B</span>it
        <span className={this.props.classes.mediumB}>B</span>ox
      </Typography>
    ) : (
      <Typography className={this.props.classes.title}>
        Porta<span className={this.props.classes.bigA}>A</span>Porta
      </Typography>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position={this.props.position || "static"}>
          <Grid container justify="center" className={classes.outerContainer}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justify="space-between"
                className={classes.innerContainer}
              >
                <Grid item xs={2}>
                  <Grid container justify="center">
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      aria-label="open drawer"
                      onClick={this.handleToggleSideBar}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs={8} className={classes.middleText}>
                  {!this.state.input ? (
                    <span
                      onClick={this.clickInput}
                      align="center"
                      className={classes.title}
                    >
                      {this.props.logo ? (
                        this.getDefaultTitle()
                      ) : (
                        <Typography className={classes.title}>
                          {this.props.title}
                        </Typography>
                      )}
                    </span>
                  ) : (
                    <input
                      onBlur={() => this.setState({ input: !this.state.input })}
                      className={classes.input}
                      ref={this.inputRef}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  {this.props.icon ? (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      onClick={this.props.onIconCLick}
                      style={this.props.iconStyle}
                    >
                      {this.props.icon}
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AppBar>

        <SideBar
          onClose={this.handleToggleSideBar}
          showSideBar={this.state.sideBar}
        />
      </div>
    );
  }
}
MenuToolbar.contextType = UserContext;
export default withStyles(styles)(MenuToolbar);
