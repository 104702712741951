import * as moment from "moment";
import LocalStorage from "../../../utils/localStorage";
import SyncFetch from "./syncFetch";
import SyncSend from "./syncSend";
import SyncStore from "./syncStore";
import {
  DespesaCombustivelVeiculo,
  ManutencaoVeiculo,
  DespesaDeleted,
  CestaItens,
  ProdutoVenda,
  CestaVenda,
  Cliente,
  ClienteTrocaAvulso,
} from "../../../repository/models";

import SyncNotificationCenter from "../../notifications/syncNotificationCenter";

const despesaCombustivelVeiculo = new DespesaCombustivelVeiculo();
const manutencaoVeiculo = new ManutencaoVeiculo();
const despesaDeleted = new DespesaDeleted();
const cestaItens = new CestaItens();
const produtoVenda = new ProdutoVenda();
const cestaVenda = new CestaVenda();
const cliente = new Cliente();
const clienteTrocaAvulso = new ClienteTrocaAvulso();

const syncFetch = new SyncFetch();
const syncSend = new SyncSend();
const syncStore = new SyncStore();

export const LAST_UPDATE_KEY = "last-update";

/**
 * @description Atualiza as models que dependem de um valor especifico de uma model criada pelo celular para terem o valor
 * atualizado dessa model criada pelo celular quando ela é enviada ao servidor e tem o seu Id alterado.
 * @param {Array} modelDepsArray Models que dependem de uma model criada pelo celular.
 * @param {Array} appCreatedModels Models que dependem de uma model criada pelo celular.
 * @param {Array} apiCreatedModels Models que uma vez foram criadas pelo celular mas foram enviadas para o servidor e portanto, possuem o id diferente.
 * @param {String} key String que define qual chave procurar nas models criadas pelo celular.
 * @param {String} mainAttribute String que define qual chave procurar nas models de modelDepsArray.
 */
async function updateModelDep(
  modelDepsArray = [],
  appCreatedModels = [],
  apiCreatedModels = [],
  key,
  mainAttribute
) {
  for (let i = 0; i < modelDepsArray.length; i++) {
    let filteredAppModels = [];
    let index = null;

    // Get all of the dependency models that match the modelDepsArray[i][mainAttribute] == appCreatedModels[j][key]
    filteredAppModels = await modelDepsArray[i].filter((model) => {
      let foundMatch = false;
      return (
        appCreatedModels.length &&
        appCreatedModels.some((appModel, ind) => {
          if (model[mainAttribute] == appModel[key]) {
            index = ind;
            foundMatch = true;
          }

          return foundMatch;
        })
      );
    });

    // Now we updated the right values in those dependency models we filtered on the function above
    await Promise.all(
      filteredAppModels.map(async (model, ind) => {
        if (apiCreatedModels.length) {
          let currentModel =
            apiCreatedModels.find((model) => {
              return model.hash === appCreatedModels[index].hash;
            }) || apiCreatedModels[index];
          model[mainAttribute] = currentModel[key];
          model.shouldSync = true;
          await modelDepsArray[i].put(model, false);
        }
      })
    );
  }
}

/**
 * @description Remove do armazenamento interno dados da rota principal e baixa do servidor dados
 * atalizados. Notifica o progresso a medida que é concluido cada passo
 */
export async function syncMainRoute() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de rota principal",
  });
  console.log(await syncFetch.fetchMainRoute());
  await syncStore.cleanMainRoute();
  let temp = await syncFetch.fetchMainRoute();

  await syncStore.saveMainRoute(temp);
}
/**
 * @description Remove do armazenamento interno dados da equipe e baixa do servidor dados
 * atalizados. Notifica o progresso a medida que é concluido cada passo
 */
export async function syncTeam() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync da equipe",
  });
  await syncStore.clearTeam();
  let temp = await syncFetch.fetchTeam();
  if (temp.rotaAbertura)
    localStorage.setItem("openingCache", JSON.stringify(temp.rotaAbertura));
  await syncStore.saveTeam(temp);
  return ["RotaAbertura"];
}
/**
 * @description Remove do armazenamento interno dados de veiculo e baixa do servidor dados
 * atalizados. Notifica o progresso a medida que é concluido cada passo
 */
export async function syncVehicle() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de veículos",
  });
  let { apiCreatedVehicles, newVehicles } = await syncSend.sendVehicle();
  apiCreatedVehicles.length &&
    (await updateModelDep(
      [despesaCombustivelVeiculo, manutencaoVeiculo, despesaDeleted],
      newVehicles,
      apiCreatedVehicles,
      "id",
      "VeiculoId"
    ));

  await syncStore.clearVehicle();
  let temp = await syncFetch.fetchVehicle();

  await syncStore.saveVehicle(temp);
  return [
    "Veiculo",
    "DespesaCombustivelVeiculo",
    "ManutencaoVeiculo",
    "DespesaDeleted",
  ];
}

/**
 * Remove do armazenamento interno dados de combustivel e baixa do servidor dados
 * atalizados. Notifica o progresso a medida que é concluido cada passo
 * @param {Function} notifer
 */
export async function syncFuel() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de combustíveis",
  });
  let { apiCreatedFuels, newFuels } = await syncSend.sendFuel();
  await syncStore.clearFuel();
  let temp = await syncFetch.fetchFuel();

  apiCreatedFuels.length &&
    (await updateModelDep(
      [despesaCombustivelVeiculo, despesaDeleted],
      newFuels,
      apiCreatedFuels,
      "id",
      "CombustivelId"
    ));
  await syncStore.saveFuel(temp);
  return ["Combustivel", "DespesaCombustivelVeiculo", "DespesaDeleted"];
}
/**
 * @description Remove do armazenamento interno dados de documentos e baixa do servidor dados
 * atalizados. Notifica o progresso a medida que é concluido cada passo
 */
export async function syncDocuments() {
  const { createdDocs } = await syncSend.sendDocuments();
  await syncStore.updateDocumentsPk(createdDocs);
  await syncStore.clearDocuments();
  let temp = await syncFetch.fetchDocument();

  await syncStore.saveDocuments(temp);
  return ["Documento"];
}
/**
 * @description Remove do armazenamento interno dados de cidade e bairro e baixa do servidor dados
 * atalizados. Notifica o progresso a medida que é concluido cada passo
 */
export async function syncCityNeighborhood() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de cidades e bairros",
  });
  await syncStore.clearCityNeighborhood();
  let temp = await syncFetch.fetchCityNeighborhood();
  await syncStore.saveCityNeighborhood(temp);
  return ["Bairro", "ClienteBairro", "Cliente"];
}
/**
 * @description Remove do armazenamento interno dados de meios de pagamento e baixa do servidor dados
 * atalizados. Notifica o progresso a medida que é concluido cada passo
 */
export async function syncPaymentForm() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de formas de pagamento",
  });
  await syncStore.clearPaymentForm();
  let temp = await syncFetch.fetchPaymentForm();
  await syncStore.savePaymentForm(temp);
  return ["FormaPagamento"];
}
/**
 * @description Remove do armazenamento interno dados de meios de pagamento e baixa do servidor dados
 * atalizados. Notifica o progresso a medida que é concluido cada passo
 */
export async function syncPaymentMethod() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de meios de pagamento",
  });
  await syncStore.clearPaymentMethod();
  let temp = await syncFetch.fetchPaymentMethod();
  await syncStore.savePaymentMethod(temp);
  return ["MeioPagamento"];
}
/**
 * @description Remove do armazenamento interno dados de produto, cesta e itensCesta e baixa do servidor dados
 * atalizados. Notifica o progresso a medida que é concluido cada passo
 */
export async function syncBasket() {
  try {
    SyncNotificationCenter.instance.notifyAll("common-sync", {
      message: "",
      title: "Sync de produtos",
    });
    let { newBaskets, apiCreatedBaskets, newProducts, apiCreatedProducts } =
      await syncSend.sendBasketsProducts();

    await updateModelDep(
      [cestaItens, cestaVenda],
      newBaskets,
      apiCreatedBaskets,
      "id",
      "CestumId"
    );

    await updateModelDep(
      [cestaItens, produtoVenda],
      newProducts,
      apiCreatedProducts,
      "id",
      "ProdutoId"
    );
    const allExchanges = await clienteTrocaAvulso.getAll();

    const exchangeUpdateProms = allExchanges.map(async (exchange) => {
      let shouldPutOnBd = false;
      const idUpdater = (p) => {
        const shouldUpdateId = apiCreatedProducts.some(
          (product) => product.oldId == p.id
        );
        if (shouldUpdateId) {
          p.ProdutoId = apiCreatedProducts.find(
            (product) => product.oldId == p.id
          ).id;
          shouldPutOnBd = true;
        }
      };
      if (exchange) {
        if (exchange.adicionados) {
          if (typeof exchange.adicionados == "string") {
            exchange.adicionados = JSON.parse(exchange.adicionados);
          }
          exchange.adicionados.forEach(idUpdater);
        }
        if (exchange.retirados) {
          if (typeof exchange.retirados == "string") {
            exchange.retirados = JSON.parse(exchange.retirados);
          }
          exchange.retirados.forEach(idUpdater);
        }
      }

      if (shouldPutOnBd) await clienteTrocaAvulso.put(exchange);
    });

    await Promise.all(exchangeUpdateProms);

    await syncSend.sendBasketItems();

    await syncStore.clearBasketsProducts();

    let temp = await syncFetch.fetchBasketsProducts();

    await syncStore.saveBasketsProducts(temp);

    return ["Cesta", "CestaItens", "Produto", "ProdutoVenda", "CestaVenda"];
    //   Cesta,
    //   CestaVenda,
    //   ProdutoVenda,
    //   CestaItens,
    //   Produto,
    //   ClienteTrocaAvulso,
  } catch (e) {
    console.log(e);
    throw e;
  }
}
/**
 * @description Remove do armazenamento interno dados de rota validators e baixa do servidor dados
 * atalizados. Notifica o progresso a medida que é concluido cada passo*/

export async function syncValidators() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de validadores",
  });
  await syncStore.clearValidators();
  let temp = await syncFetch.fetchValidators();
  await syncStore.saveValidators(temp);
  return ["RotaValidator", "Validator"];
}
/**
 * @description Remove do armazenamento interno dados de campos requiridos, obrigatórios e recomendaveis e baixa do servidor dados
 * atalizados. Notifica o progresso a medida que é concluido cada passo
 */
export async function syncRequiredFields() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de campos requiridos",
  });
  await syncStore.clearRequiredFields();
  let temp = await syncFetch.fetchRequiredFields();
  await syncStore.saveRequiredFields(temp);
  return [
    "ClienteCampoObrigatorio",
    "ClienteCampoRecomendavel",
    "ClienteCampoEdit",
  ];
}
/**
 * @description Remove do armazenamento interno dados de feriado e baixa do servidor dados
 * atalizados. Notifica o progresso a medida que é concluido cada passo
 */
export async function syncHoliday() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de feriados",
  });
  await syncStore.clearHoliday();
  let temp = await syncFetch.fetchHoliday();
  await syncStore.saveHoliday(temp);
  return ["Feriado"];
}

/**
 * @description Baixa clientes do servidor.
 * @param {Boolean} shouldDownloadAllClient Caso true o download de clientes será total, ou seja. Ira baixar todos os clientes da rota
 * @param {Function}
 */
export async function syncClients(shouldDownloadAllClient) {
  try {
    const lastUpdate = await LocalStorage.instance.getItem(LAST_UPDATE_KEY);
    const notifier = SyncNotificationCenter.instance.notifyAll.bind(
      SyncNotificationCenter.instance
    );

    if (shouldDownloadAllClient) {
      await syncAllClients(notifier);
    } else if (lastUpdate) {
      await partialClientSync(lastUpdate.date, notifier);
    } else {
      await syncAllClients(notifier);
    }

    await LocalStorage.instance.setItem(LAST_UPDATE_KEY, {
      date: moment().format("DD/MM/YYYY HH:mm:ss"),
    });
    return [
      "Cliente",
      "ClienteTrocaAvulso",
      "ClienteAgendamento",
      "ClienteBairro",
      "ClienteTelefone",
      "ClienteDocumento",
      "ClienteMovimentacaoCancelada",
    ];
  } catch (error) {
    throw error;
  }
}

/**
 * @description Envia ao servidor dados de clientes que sofreram atualização (ou as models relacionadas)
 */
export async function sendClients(clienteId) {
  const notifier = SyncNotificationCenter.instance.notifyAll.bind(
    SyncNotificationCenter.instance
  );
  await syncSend.sendClients(notifier, clienteId);
}

/**
 * @description Envia despesas que sofreram alteração. Para mais detalhes veja as classes syncSend e syncStore
 */
export async function sendExpenses() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Envio de despesas",
  });
  await syncSend.sendExpenses();
  return [
    "ManutencaoVeiculo",
    "DespesaVeiculo",
    "DespesaCombustivelVeiculo",
    "Veiculo",
    "DespesaDeleted",
    "AdiantamentoFuncionario",
    "DespesaRota",
  ];
}

/**
 * @description Envia bairros que sofreram alteração. Para mais detalhes veja as classes syncSend e syncStore
 */
export async function sendNeighborhood() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de bairros e cidades",
  });
  await syncSend.sendNeighborhood();
  return ["Bairro", "ClienteBairro", "Cliente"];
}

/**
 * @description
 */
export async function sendDailyMap() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de diaria map",
  });
  let map = await syncSend.sendDailyMap();
  const shouldDownloadServerMap = !Object.keys(map || {}).length;
  if (shouldDownloadServerMap) {
    console.log("Baixando diaria do servidor");
    map = await syncFetch.fetchDailyMap();
    if (map) await syncStore.saveDailyMap(map);
  } else {
    console.log("Utilizando diaria local");
  }
  return ["DailyOrder"];
}

/**
 * @description Envia dados de abertura. Para mais detalhes veja as classes syncSend e syncStore
 */

export async function sendRouteOpening() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de dados da abertura",
  });
  const routeInfo = await syncSend.sendRouteOpening();
  if (routeInfo && routeInfo.fechada) {
    await syncStore.clearExpenses();
    await syncStore.cleanRouteOpening();
  }
  return ["RotaAbertura"];
}

/**
 * @description Função que limpa todos os dados de cliente antes de começar a fazer as
 * requisições de dados ao servidor. Realiza o armazenamento e download
 * simultanêamente
 * @param {Function}
 */
async function syncAllClients(notifier) {
  notifier &&
    notifier("common-sync", {
      message: "Criando blocos de clientes",
      title: "Sync de clientes",
    });
  const { blocks } = await syncFetch.generateBlocksClients();

  const totalClients = parseInt(blocks.flat().length);
  LocalStorage.instance.setItem("TOTAL_CLIENTS", totalClients);

  notifier &&
    notifier("common-sync", {
      message: "Criação de blocos concluido",
      title: "Sync de clientes",
    });

  const promsFinais = [];
  let index = 1;
  let savedBlockIndex = 1;
  let errorCount = 0;
  let promClear = syncStore.clearAllClients();
  for (let clientIds of blocks) {
    try {
      if (errorCount > 0) break;

      notifier &&
        notifier("client-block-progress", {
          title: "Sync de clientes",
          message: `Baixando ${index} de ${blocks.length}`,
          currentItem: index,
          totalItems: blocks.length,
          downloadedItems: index,
          storedItems: savedBlockIndex,
        });
      let promFetch = syncFetch.fetchBlockClients(clientIds);
      // eslint-disable-next-line no-loop-func
      const promsSave = promFetch.then(async (clientes) => {
        await promClear;

        notifier &&
          notifier("client-block-progress", {
            message: `Salvando ${savedBlockIndex} de ${blocks.length}`,
            currentItem: savedBlockIndex,
            totalItems: blocks.length,
            title: "Sync de clientes",
            downloadedItems: index,
            storedItems: savedBlockIndex,
          });
        await syncFetch.saveClients(clientes);
        savedBlockIndex++;
        notifier &&
          notifier("client-block-progress", {
            message: `Salvando ${savedBlockIndex} de ${blocks.length}`,
            currentItem: savedBlockIndex,
            totalItems: blocks.length,
            title: "Sync de clientes",
            downloadedItems: index,
            storedItems: savedBlockIndex,
          });
      });

      promsFinais.push(promsSave);
      await promFetch;
      index++;
    } catch (error) {
      errorCount++;
    }
  }

  try {
    await Promise.all(promsFinais);
  } catch (error) {
    throw error;
  }
}

export async function syncOneClient(notifier, clientId) {
  notifier &&
    notifier("common-sync", {
      message: "Sincronizando cliente",
      title: "Sync de cliente",
      status: "info",
    });
  const client = await cliente.getAll("id", clientId);
  if (client.length) {
    await syncSend.sendClients(null, client);
  } else {
    return;
  }

  const blocks = [[clientId]];

  const promsFinais = [];
  let errorCount = 0;
  let promClear = syncStore.clearClients(clientId);
  for (let clientIds of blocks) {
    try {
      if (errorCount > 0) break;
      let promFetch = syncFetch.fetchBlockClients(clientIds);
      // eslint-disable-next-line no-loop-func
      const promsSave = promFetch.then(async (clientes) => {
        await promClear;

        await syncFetch.saveClients(clientes);

        promsFinais.push(promsSave);
        await promFetch;
        notifier &&
          notifier("client-block-progress", {
            message: `Cliente sincronizado!`,
            title: "Sync de cliente",
            status: "success",
          });
      });
    } catch (error) {
      errorCount++;
    }
  }

  try {
    await Promise.all(promsFinais);
  } catch (error) {
    throw error;
  }
}

/**
 * @description Baixa do servidor somente os clientes que sofreram atualização pós periodo informado
 * no parametro lasUpdate e notifica o progresso de armazenamento e download. Download e
 * armazenamento são feitos em paralelo
 * @param {Date} lastUpdate Delimita quais clientes devem ou não ser baixados do servidor baseado
 * no campo updatedAt em cliente
 * @param {Function}
 */
async function partialClientSync(lastUpdate, notifier) {
  notifier &&
    notifier("common-sync", {
      message: "Criando blocos de clientes",
      title: "Sync de clientes",
    });
  const { blocks } = await syncFetch.generateBlocksClients(null, lastUpdate); // [1,45,32,67,433,5,23 ...]

  notifier &&
    notifier("common-sync", {
      message: "Criação de blocos concluido",
      title: "Sync de clientes",
    });

  const promsFinais = [];
  let index = 1;
  let savedBlockIndex = 1;
  let errorCount = 0;
  for (let clientIds of blocks) {
    try {
      if (errorCount > 0) break;

      notifier &&
        notifier("client-block-progress", {
          title: "Sync de clientes",
          message: `Baixando ${index} de ${blocks.length}`,
          currentItem: index,
          totalItems: blocks.length,
          downloadedItems: index,
          storedItems: savedBlockIndex,
        });

      let promFetch = syncFetch.fetchBlockClients(clientIds);
      let promClear = syncStore.clearClients(clientIds);
      // eslint-disable-next-line no-loop-func
      const promsClearSave = promFetch.then(async (clientes) => {
        await promClear;

        notifier &&
          notifier("client-block-progress", {
            message: `Salvando ${savedBlockIndex} de ${blocks.length}`,
            currentItem: savedBlockIndex,
            totalItems: blocks.length,
            title: "Sync de clientes",
            downloadedItems: index,
            storedItems: savedBlockIndex,
          });

        await syncFetch.saveClients(clientes);
        savedBlockIndex++;

        notifier &&
          notifier("client-block-progress", {
            message: `Salvando ${savedBlockIndex} de ${blocks.length}`,
            currentItem: savedBlockIndex,
            totalItems: blocks.length,
            title: "Sync de clientes",
            downloadedItems: index,
            storedItems: savedBlockIndex,
          });
      });

      promsFinais.push(promsClearSave);
      await promFetch;
      index++;
    } catch (error) {
      errorCount++;
    }
  }

  try {
    await Promise.all(promsFinais);
  } catch (error) {
    throw error;
  }
}

export async function syncVarEnvs() {
  SyncNotificationCenter.instance.notifyAll("common-sync", {
    message: "",
    title: "Sync de variaveis de ambiente",
  });
  let temp = await syncFetch.fetchEnvs();
  await syncStore.clearEnvs();
  await syncStore.saveEnvs(temp);

  return ["VariaveisAmbiente"];
}

export async function syncScheduleTypes() {
  const scheduleTypes = await syncFetch.fetchScheduleTypes();
  await syncStore.saveSchedulesTypes(scheduleTypes);
  return ["schedulesType"];
}
