import React, { createContext } from "react";
import LocalStorage from "../utils/localStorage";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import {
  Cliente,
  ClienteAgendamento,
  MeioPagamento,
  FormaPagamento,
  ProdutoVenda,
} from "../repository/models";
import Moeda from "../utils/Moeda";
import {
  createScheduling,
  removeScheduleFromDaily,
  validateSchedule,
} from "../service/schedulings/index";
import moment from "moment";
import { createSale } from "../service/movements";
import { AbstractContext } from "./AbstractContext";
import ClientnotificationCenter from "../service/notifications/clientNotificationCenter";
import BusinessError from "../errorDefinition/BusinessError";

const SaleContext = createContext({
  receivement: [],
});

const eventEmitter = ClientnotificationCenter.instance;
const cliente = new Cliente();
const clienteAgendamento = new ClienteAgendamento();
const meioPagamento = new MeioPagamento();
const formaPagamento = new FormaPagamento();
const produtoVenda = new ProdutoVenda();

/**
 * Contexto utilizado na pagina de configurações.
 */
const OBSERVED_MODELS = ["MeioPagamento", "FormaPagamento"];
class SaleContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.observable = this.observable.bind(this);
    this.updateSelf = this.updateSelf.bind(this);
    this.createSale = this.createSale.bind(this);
    this.getClientByHash = this.getClientByHash.bind(this);
    this.getSchedulingsByDate = this.getSchedulingsByDate.bind(this);
    this.clientBackgroundSync = this.clientBackgroundSync.bind(this);

    this.state = {
      payments: [],
      paymentForms: [],
      clientBackgroundSync: this.clientBackgroundSync,
      createSale: this.createSale,
      getClientByHash: this.getClientByHash,
      getSchedulingsByDate: this.getSchedulingsByDate,
      getRouteOpeningInfo: () => LocalStorage.instance.getItem("route-opening"),
      getMainRouteInfo: () => LocalStorage.instance.getItem("main-route"),
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.updateSelf();
  }

  observable() {
    this.updateSelf().catch(console.error);
  }

  /**Função responsável atualizar dados do estado.
   * @function
   */
  async updateSelf() {
    this.setState({
      payments: await meioPagamento.getAll(),
      paymentForms: await formaPagamento.getAll(),
    });
  }

  async createSale(data) {
    try {
      const { dataRecebimento, tipo, clientHash, ProdutoVendas, ...sale } =
        data;

      const hasSchedule = dataRecebimento && tipo;

      if (hasSchedule) {
        const { error, message } = await validateSchedule({ dataRecebimento });
        if (error)
          throw new BusinessError({
            message,
            type: "warning",
            title: "Problemas com a data do agendamento",
          });
      }

      const { id, hash } = await createSale(clientHash, sale);

      if (hasSchedule) {
        await createScheduling(clientHash, {
          dataRecebimento,
          tipo,
          ClienteVendaId: id,
        });
      }

      if (Array.isArray(ProdutoVendas)) {
        const promsArr = ProdutoVendas.map((el) => produtoVenda.create(el));
        await Promise.all(promsArr);
      }
      await removeScheduleFromDaily(clientHash, "Venda");
      eventEmitter.notifyAll("addSale", clientHash);

      return {
        id,
        hash,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**Função responsável por pegar os dados de um cliente pela hash do mesmo.
   * @function
   * @param {string} hash Hash do cliente
   */
  async getClientByHash(hash) {
    let client = await cliente.findByHash(hash);
    return {
      id: client.id,
      nome: client.nome,
      hash: client.hash,
      currentBalance: Moeda.create(client.saldoAtual).mount(),
      cpf: client.cpf,
      veaco: client.veaco,
    };
  }

  /**Função responsável por verificar se o cliente tem um recebimento numa determinada data.
   * @function
   * @param {string} id Id do cliente
   * @param {Date} date Respectiva data que será consultada
   */
  async getSchedulingsByDate(date, id) {
    let schedulingExist = await clienteAgendamento.getAll("ClienteId", id);
    schedulingExist = schedulingExist.filter(
      (a) =>
        a.ativo == true &&
        a.tipo.toLowerCase() == "Recebimento".toLowerCase() &&
        moment(a.dataRecebimento).isSame(date, "day")
    );
    if (schedulingExist.length > 0) return true;
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <SaleContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </SaleContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { SaleContext, SaleContextProvider };
