import React from "react";
import CardReportScheduling from "../CardComponents/CardOfClient/CardsSchedulings/CardReportScheduling";
import Skeleton from "../Skeleton/CardSkeleton";

class SchedulingReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      isLoading: true,
      search: null,
    };
    this.getClientData = this.getClientData.bind(this);
    this.handleToggleVerticalEditDisplaySettings =
      this.handleToggleVerticalEditDisplaySettings.bind(this);
  }

  componentDidMount() {
    this.getClientData();
    this.setState({ search: this.props.search });
  }

  async getClientData() {
    const data = await this.props.context.getClientByHash(
      this.props.data.hash,
      {
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      }
    );

    this.setState({ data, isLoading: false });
  }

  handleToggleVerticalEditDisplaySettings() {
    this.props.handleToggleVerticalEdit(this.props.data.cardHash);
  }

  render() {
    return (
      <>
        {!this.state.isLoading ? (
          <CardReportScheduling
            data={this.state.data}
            toggleDisplaySettings={this.handleToggleVerticalEditDisplaySettings}
            displaySettings={this.props.displaySettings}
            moves={this.state.data.type}
          />
        ) : (
          <Skeleton />
        )}
      </>
    );
  }
}

export default SchedulingReport;
