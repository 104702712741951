import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import getStyles from "./style";
import Header from "../../HeaderForAdd/Header";
import ItemCardPoints from "../../Sale/ItemCardPoints";
import FooterBalance from "../../Footer/FooterBalance";
import GreenButton from "../../Buttons/GreenButton";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import * as moment from "moment";

function Exchange(props) {
  const classes = getStyles();
  const handleClick = (inputName) => (evt) => props.onClick(inputName, evt);

  return (
    <>
      <Header
        icon={<CloseIcon fontSize="large" />}
        name={props.clientName}
        date={moment().format("DD/MM/YYYY")}
        func={props.onClose}
      >
        Troca Avulsa
      </Header>

      <Divider />

      <Grid container justify="center" className={classes.containerBlock}>
        <Grid item xs={10} className={classes.block}>
          <Grid container wrap="nowrap" justify="center">
            <Typography align="center">PRODUTOS A RETIRAR </Typography>
            <KeyboardReturnIcon
              style={{ transform: "rotate(180deg)" }}
              fontSize="small"
            />
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: "5px" }}>
            <Divider className={classes.divider_title} />
          </Grid>

          <GreenButton onClickButton={handleClick("itemsOut")}>
            {" "}
            RETIRAR ITEM{" "}
          </GreenButton>
          <Grid item xs={12} className={classes.containerCards}>
            {props.itemsRemoved.map((el, index) => {
              return (
                <ItemCardPoints
                  itemData={el}
                  index={index}
                  key={index}
                  handleItemAdd={() => props.handleItemAdd("itemsOut", index)}
                  handleItemSub={() => props.handleItemSub("itemsOut", index)}
                  handleRemoveItem={() =>
                    props.handleRemoveItem("itemsOut", index)
                  }
                />
              );
            })}
          </Grid>
          <Typography variant="h6" align="right">
            Saldo: {props.removedBalance}pts
          </Typography>
        </Grid>

        <Divider className={classes.divider} />

        <Grid item xs={10} className={classes.block}>
          <Grid container wrap="nowrap" justify="center">
            <Typography>PRODUTOS A ADICIONAR </Typography>
            <KeyboardReturnIcon fontSize="small" />
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: "5px" }}>
            <Divider className={classes.divider_title} />
          </Grid>

          <GreenButton onClickButton={handleClick("itemsEnter")}>
            {" "}
            ADICIONAR ITEM{" "}
          </GreenButton>
          <Grid item xs={12} className={classes.containerCards}>
            {props.itemsAdded.map((el, index) => {
              let points = props.getPoints(el);
              return (
                <ItemCardPoints
                  itemData={el}
                  index={index}
                  key={index}
                  handleItemAdd={() => props.handleItemAdd("itemsEnter", index)}
                  handleItemSub={() => props.handleItemSub("itemsEnter", index)}
                  handleRemoveItem={() =>
                    props.handleRemoveItem("itemsEnter", index)
                  }
                  points={points}
                />
              );
            })}
          </Grid>
          <Typography align="right" variant="h6">
            <span>Saldo:</span>
            {props.addedBalance}pts
          </Typography>
        </Grid>
      </Grid>

      <FooterBalance
        type="sale"
        finalBalance={props.finalBalance}
        onClickButton={props.onSave}
        isLoading={props.isLoading}
      />
    </>
  );
}

export default Exchange;
