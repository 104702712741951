import models from "../../repository/models";

async function mapValidators() {
  let validators = {};
  let validatorsDB = await models.Validator.getAll();

  for (let i = 0; i < validatorsDB.length; i++) {
    let validatorDB = validatorsDB[i];
    let value = validatorDB.validator;
    let namespaceArray = validatorDB.namespace.split(".");
    if (!validators[namespaceArray[1]]) {
      validators[namespaceArray[1]] = {};
    }
    if (!validators[namespaceArray[1]][namespaceArray[2]]) {
      validators[namespaceArray[1]][namespaceArray[2]] = {};
    }

    if (!validators[namespaceArray[1]][namespaceArray[2]][namespaceArray[3]]) {
      if (namespaceArray[3] == "collections") {
        validators[namespaceArray[1]][namespaceArray[2]][namespaceArray[3]] =
          {};
      } else if (namespaceArray[3] == "handler") {
        validators[namespaceArray[1]][namespaceArray[2]][namespaceArray[3]] =
          new Function("return " + value)();
      } else {
        validators[namespaceArray[1]][namespaceArray[2]][namespaceArray[3]] =
          value;
      }
    }

    if (namespaceArray[4]) {
      validators[namespaceArray[1]][namespaceArray[2]][namespaceArray[3]][
        namespaceArray[4]
      ] = new Function("return " + value)();
    }
  }
  return validators;
}
/**
 * @param {Array} exceptionValidators
 */
export async function validateClientDetails(clientData, exceptionValidators) {
  try {
    let validators = await mapValidators();
    let RotaValidator = await models.RotaValidator.getAll();
    if (exceptionValidators && exceptionValidators.length) {
      RotaValidator = RotaValidator.filter(
        filterUnnecessaryValidators(exceptionValidators)
      );
    }

    let erros = [];
    for (let i = 0; i < RotaValidator.length; i++) {
      let namespaceArray = RotaValidator[i].validatorNamespace.split(".");
      if (validators[namespaceArray[0]]) {
        if (validators[namespaceArray[0]][namespaceArray[1]]) {
          let validator = validators[namespaceArray[0]][namespaceArray[1]];
          let collection = await validator.collections.pwa();
          let passed = await validator.handler(clientData, collection);
          if (!passed) {
            erros.push(validator.message);
          }
        }
      }
    }
    return erros;
  } catch (e) {
    console.log(e);
  }
}

function filterUnnecessaryValidators(exceptions) {
  return (validator) => {
    return !exceptions.some((exception) =>
      validator.validatorNamespace.includes(exception)
    );
  };
}
