import React from "react";
import { withStyles, Divider, Grid } from "@material-ui/core";
import Header from "../HeaderForAdd/Header";
import CloseIcon from "@material-ui/icons/Close";
import Report from "../Report/ReceivementFinalReport";
import PrintOutAndNext from "../Buttons/PrintOutAndNext";
import Slide from "@material-ui/core/Slide";
import { PrinterContext } from "../../contexts/PrinterContext";
import { OpenRouteContext } from "../../contexts/OpenRouteContext";
const styles = (theme) => ({
  report: {
    paddingTop: "1vh",
  },
  buttons: {
    position: "fixed",
    bottom: "20px",
  },
  button_next: {
    paddingLeft: "5px",
  },
});

/**
 * Tela do relatório de recebimento
 */
class ExchangeReport extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {function} props.provideNextScene Função responsável por prover para a próxima cena.
   * @param {string} props.slideDirection Direção em que o slide irá se movimentar.
   */
  constructor() {
    super();
    this.state = {
      report: "",
    };
    this.onClickGoNext = this.onClickGoNext.bind(this);
    this.showPrinterComponent = this.showPrinterComponent.bind(this);
  }

  /**Função responsável por prover para a próxima cena.
   * @function
   */
  onClickGoNext() {
    this.props.provideNextScene({ slideDirection: "left" });
  }

  async componentDidMount() {
    const exchangeReportFromServer =
      await this.props.openRouteContext.getRouteOpeningExchangeReport(
        this.props.defaultValues.hashRotaAbertura
      );
    this.setState({ report: exchangeReportFromServer });
  }

  showPrinterComponent() {
    const dialogRenderOptions = {
      disableClientButton: true,
      content: this.state.report,
      viaEmployee: this.state.report,
      title: "Relátório final de troca",
      headerMainText: "Fechamento de rota",
    };
    this.context.callPrinterComponent(dialogRenderOptions);
  }

  render() {
    let { classes } = this.props;
    return (
      <Slide
        direction={this.props.slideDirection}
        in={true}
        mountOnEnter
        unmountOnExit
        style={{ minHeight: "100%" }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Header
              icon={<CloseIcon fontSize="large" />}
              name={"Finalizada"}
              link="/"
            >
              Fechar Rota
            </Header>

            <Divider />
            <Grid container justify="center">
              <Grid item xs={10} className={classes.report}>
                <Report title="Relatório de trocas">
                  <textarea
                    style={{
                      backgroundColor: "#f5f5f5",
                      height: "60vh",
                      overflowX: "hidden",
                      width: "100%",
                      border: "none",
                      padding: "3px 15px",
                    }}
                    value={this.state.report}
                    disabled
                  />
                </Report>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.buttons}>
            <PrintOutAndNext
              onClickGoNext={this.onClickGoNext}
              onClickPrint={this.showPrinterComponent}
              icon={
                <span style={{ fontSize: ".9rem" }}>
                  <i className="fas fa-arrow-right fa-lg" />
                </span>
              }
            />
          </Grid>
        </Grid>
      </Slide>
    );
  }
}
ExchangeReport.contextType = PrinterContext;
const ExchangeReportWithStyles = withStyles(styles)(ExchangeReport);

export default function Wrapper(props) {
  return (
    <OpenRouteContext.Consumer>
      {(openRouteContext) => (
        <ExchangeReportWithStyles
          {...props}
          openRouteContext={openRouteContext}
        />
      )}
    </OpenRouteContext.Consumer>
  );
}
