import React, { createContext } from "react";
import { Combustivel } from "../repository/models";
import AppStorageError from "../errorDefinition/AppStorageError";
import BusinessError from "../errorDefinition/BusinessError";
import { AbstractContext } from "./AbstractContext";
import { MessageDialogContext } from "../contexts/MessageDialogContext";

const combustivel = new Combustivel();

const FuelContext = createContext({
  get fuel() {
    return [];
  },
});

const success = (context, message) =>
  context.addAsyncDialog({
    message: message,
    title: "Sucesso!",
    type: "success",
    hasCloseButton: false,
  });

/**
 * Contexto utilizado na pagina de despesas.
 */
const OBSERVED_MODELS = ["Combustivel"];
class FuelContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.observable = this.observable.bind(this);
    this.updateSelf = this.updateSelf.bind(this);
    this.create = this.create.bind(this);
    this.delete = this.delete.bind(this);
    this.update = this.update.bind(this);
    this.getById = this.getById.bind(this);

    this.state = {
      ...this.state,
      get fuel() {
        return combustivel.filter((a) => a.ativo != false);
      },
      create: this.create,
      delete: this.delete,
      update: this.update,
      getById: this.getById,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.updateSelf();
  }

  observable() {
    this.updateSelf();
  }
  /**Função responsável por atualizar os dados do estado.
   * @function
   */
  async updateSelf() {}

  /**Função responsável por atualizar os dados do estado.
   * @function
   * @param {object} data Dados usanos na criação de um novo combustível.
   */
  async create(data) {
    try {
      let fuelExist = (await this.state.fuel).find((a) => a.nome == data.nome);
      if (!fuelExist) {
        data.ativo = true;
        data.shouldSync = true;
        let newFuel = await combustivel.create(data);
        await this.updateSelf();
        await success(this.context, "Combustivel salvo com sucesso!");
        return newFuel;
      } else {
        throw new BusinessError({
          message: "Um combustível com este nome já existe.",
          title: "Falhar ao criar novo combustível.",
          type: "warning",
          method: "create",
        });
      }
    } catch (error) {
      if (!error instanceof BusinessError) {
        new AppStorageError({
          message: error.message,
          title: "Falha ao criar novo combustível!",
          type: "error",
          method: "create",
        });
      }
      throw error;
    }
  }

  /**Função responsável por deletar um combustivel.
   * @function
   * @param {object} data Dados usanos na exclusão de um combustível.
   */
  async delete(data) {
    try {
      data.ativo = false;
      data.shouldSync = true;
      await combustivel.uptate(data);
      await this.updateSelf();
      await success(this.context, "Combustivel atualizado com sucesso!");
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao deletar combustível!",
        type: "error",
        method: "delete",
      });
      throw error;
    }
  }

  /**Função responsável por deletar um combustivel.
   * @function
   * @param {object} data Dados usanos na atualização do dados de um combustível.
   */
  async update(data) {
    try {
      let fuelExist = (await this.state.fuel).find((a) => a.nome == data.nome);
      if (!fuelExist) {
        await combustivel.put(data);
        await this.updateSelf();
      } else {
        throw new BusinessError({
          message: "Um combustível com este nome já existe.",
          title: "Falhar ao criar novo combustível.",
          type: "warning",
          method: "update",
        });
      }
    } catch (error) {
      if (!error instanceof BusinessError) {
        new AppStorageError({
          message: error.message,
          title: "Falha ao atualizar os dados do combustível!",
          type: "error",
          method: "update",
        });
      }
      throw error;
    }
  }

  async getById(id) {
    (await this.state.fuel).find((a) => a.id === id);
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <FuelContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </FuelContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { FuelContext, FuelContextProvider };
