import React from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Fab, withStyles } from "@material-ui/core";
import CelularMask from "../../MaskedInputs/CelularMask";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = (theme) => ({
  button: {
    top: "21px",
    backgroundColor: "#ff0000",
    color: "#fff",
  },
});

/**
 * Card com input de texto com mascara para o telefone celular brasileiro, botão para fechar o componente (opcional)
 * comunicação da alteração de estado é feita através da callback onChange
 * @extends {React.Component}
 */
class TelefoneInputCard extends React.Component {
  /**
   * Caso props.value não seja informado um novo Obejeto é criado com o seguinte formato: @example state = { value: '', error: false }
   * @param {Object} props Objeto de possiveis atributos informados a tela.
   * @param {Object} props.value Endereço de memoria que será referenciado pelo state deste componente
   * @param {callback} props.onChange Callback a ser chamada quando o input deste componente for alterado
   * @param {callback} props.onRemovePhoneNumber Callback a ser chamada quando o evento de click em "FECHAR" deste componente for disparado
   * @param {boolean} props.error Caso true o input será circundado com uma exibição visual indicando algum tipo de erro
   * @param {callback} props.onFocus Callback a ser chamada quando o input deste componente for focado
   * @param {boolean} props.showclosebutton Caso true exibe um botão de fechamento do componente que retira o componente da DOM
   */
  constructor(props) {
    super(props);
    this.state = {};
    this.onNumeroChange = this.onNumeroChange.bind(this);
    this.closeBox = this.closeBox.bind(this);
  }
  /**
   * Função que chama a callback informada em props.onChange
   */
  onNumeroChange(event) {
    let value = event.target.value;
    if (value.trim) value = value.trim();
    this.props.onSetPhoneNumber(event.target.value, this.props.index);
  }
  /**
   * Função responsavel por retirar o componente da DOM e notificar o
   * componente pai através de props.onRemovePhoneNumber informando o atual
   * estado do componente
   */

  closeBox() {
    this.props.onRemovePhoneNumber(this.props.index);
  }

  getValue() {
    if (typeof this.props.value == "object")
      if ("numero" in this.props.value)
        return this.props.value.numero.replace(/\s/g, "");
    // else return this.props.value[0];
    return this.props.value.replace(/\s/g, "");
  }

  render() {
    let { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs>
          <TextField
            fullWidth
            {...this.props}
            value={this.getValue()}
            error={this.props.error}
            helperText={
              this.props.error
                ? "Preencha o campo de telefone corretamente"
                : null
            }
            label="Telefone"
            placeholder="Seu numero aqui"
            variant="outlined"
            margin="normal"
            onChange={this.onNumeroChange}
            type="tel"
            onFocus={this.props.onFocus}
            InputProps={{
              inputComponent: CelularMask,
            }}
            required={this.props.required}
            className={this.props.className}
            InputLabelProps={{
              className: this.props.recomended ? this.props.classe : null,
            }}
          />
        </Grid>

        {this.props.showclosebutton && (
          <Grid item xs={2}>
            <Grid container justify="flex-end">
              <Fab
                size="medium"
                onClick={this.closeBox}
                className={classes.button}
              >
                <DeleteIcon fontSize="small" />
              </Fab>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(TelefoneInputCard);
