import DataAccess from "../DataAccessStrategy";

export default class ClienteCampoRecomendavel extends DataAccess {
  constructor() {
    super("ClienteCampoRecomendavel", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      hash: String,
      attributeName: String,
    });
  }
}
