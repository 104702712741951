import React from "react";
import ClientList from "./ClientList";
import { DailyListContext } from "../../contexts/DailyListContext";

class DailyListConsumer extends React.Component {
  constructor(props) {
    super(props);
    this.getHeaderCardProps = this.getHeaderCardProps.bind(this);
    this.shouldRenderCardHeader = this.shouldRenderCardHeader.bind(this);
    this.addClientToRepass = this.addClientToRepass.bind(this);
    this.handleSyncSingleClient = this.handleSyncSingleClient.bind(this);
  }
  getHeaderCardProps(client) {
    const clients = this.context.orderedClients.filter(this.props.filter);
    const totalOfClients = clients.filter(
      (c) => c.BairroId === client.BairroId
    ).length;
    return {
      mainText: client.bairro,
      secondaryText: `${totalOfClients} ${
        totalOfClients > 1 ? "Clientes" : "Cliente"
      }`,
    };
  }

  shouldRenderCardHeader(client, lastClient) {
    if (!lastClient) return true;
    return client.BairroId !== lastClient.BairroId;
  }

  addClientToRepass(clientHash) {
    this.context.addClientToRepass(clientHash);
  }

  async handleSyncSingleClient(cliente) {
    await this.props.onSync(cliente);
    // await syncService.syncOneClient(console.log,cliente);
  }

  render() {
    const clients = this.context.orderedClients.filter(this.props.filter);

    return (
      <ClientList
        clients={clients}
        getHeaderCardProps={this.getHeaderCardProps}
        shouldRenderCardHeader={this.shouldRenderCardHeader}
        addClientToRepass={this.addClientToRepass}
        onSync={this.handleSyncSingleClient}
        onClickPular={this.context.jumpClient}
        isRepassList={this.props.isRepassList}
      />
    );
  }
}
DailyListConsumer.contextType = DailyListContext;
export default DailyListConsumer;
