async function clearCache(cacheName) {
  const cache = await caches.open(cacheName);

  const deleteCacheFn = (request) => cache.delete(request);
  const cachedRequests = await cache.keys();
  const removeCacheProms = cachedRequests.map(deleteCacheFn);
  return await Promise.all(removeCacheProms);
}

export async function clearSiteCache() {
  const cacheKeys = await caches.keys();
  const clearCacheProms = cacheKeys.map(clearCache);
  const resultSet = await Promise.all(clearCacheProms);

  const successArray = resultSet.flat(1);
  const isAllRemoved = successArray.every((result) => result === true);
  return isAllRemoved;
}

export async function unregisterAllSw() {
  const swRegistrations = await navigator.serviceWorker.getRegistrations();
  const unregisterProms = swRegistrations.map((reg) => reg.unregister());
  const unregisterResults = await Promise.all(unregisterProms);
  const isAllSwUnregster = unregisterResults.every((result) => result === true);
  return isAllSwUnregster;
}
