import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const styles = (theme) => ({
  root: {
    paddingTop: "15px",
    "& > div": {
      paddingRight: "10px",
    },
  },
  saveButton: {
    backgroundColor: "#1473cc",
    color: "#fff",
  },
});

/**
 * Conjunto de 2 componentes sendo eles para cancelar ou salvar.
 * O componente utiliza este meio de CSS acima.
 */
class CancelOrSave extends React.Component {
  /**
     * @param {function} props.clickSave Função acionada no 'click' para salvar!
     * @param {function} props.clickCancel Função acionada no 'click' para cancelar!
     * @param {boolean} props.enableSave Stado que irá apontar se o salvar estará abilitado ou não, sendo false o valor default
     * @param {object} props.classes Classes do css vindas do withStyles

    */
  constructor(props) {
    super(props);
  }

  render() {
    let { classes } = this.props;
    return (
      <Grid container justify="flex-end" className={classes.root}>
        <Grid item>
          {!!this.props.showCancelButton && (
            <Button variant="contained" onClick={this.props.onClickCancel}>
              Cancelar
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button
            disabled={this.props.enableSave ? false : true}
            className={classes.saveButton}
            variant="contained"
            onClick={this.props.onClickSave}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  }
}
CancelOrSave.propTypes = {
  disabledSave: PropTypes.bool,
};

CancelOrSave.defaultProps = {
  disabledSave: false,
};
export default withStyles(styles)(CancelOrSave);
