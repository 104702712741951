import DataAccess from "../DataAccessStrategy";

export default class ClienteTrocaAvulso extends DataAccess {
  constructor() {
    super("ClienteTrocaAvulso", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      adicionados: Array,
      retirados: Array,
      saldoRetirar: Number,
      saldoAdicionar: Number,
      dataRealizacao: Date,
      dataTrocaRealizada: Date,
      assinatura: String,
      historico: String,
      hash: String,
      ativo: Boolean,
      cancelado: Boolean,
      ClienteId: Number,
      RotumId: Number,
      ClienteVendaId: Number,
      hashRotaAbertura: String,
      latitude: String,
      longitude: String,
    });
  }
}
