/* eslint-disable no-useless-constructor */
/* eslint-disable eqeqeq */
import React from "react";
import {
  Typography,
  withStyles,
  Grid,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Moment from "moment";
import { MessageDialogContext } from "../../../../contexts/MessageDialogContext";

const styles = (theme) => ({
  card: {
    backgroundColor: "#fff",
    padding: "5px",
    position: "relative",
  },
  listHeader: {
    fontSize: "1.1rem",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.complex,
    }),
  },
  expandOpen: {
    transform: "rotate(-180deg)",
  },

  showMore: {
    flexWrap: "nowrap",
    backgroundColor: "rgba(21, 137, 158, 0.15)",
  },
  showNotRealized: {
    backgroundColor: "rgba(189, 30, 19, 0.15)",
  },

  movementTypeText: {
    fontSize: "1.2rem",
    fontWeight: 400,
  },
  paymentMethod: {
    color: "#7c7c7c",
  },
  movementHour: {
    color: "#C0C0C0",
    fontSize: ".9rem",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
  moreSettingsIcon: {
    height: "0.8em",
    float: "right",
    color: "#a3a3a3",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.complex,
    }),
  },
  moreSettingsIcon_active: {
    transform: "rotate(90deg)",
  },

  realized: {
    color: theme.palette.primary.main,
  },
  pendent: {
    color: "#c51162",
  },
});

class CardMovements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
      showDropdownMenu: false,
    };
    this.addAsyncDialog = null;

    this.toggleCardExpand = this.toggleCardExpand.bind(this);
    this.toggleDropdownMenu = this.toggleDropdownMenu.bind(this);
    this.onClickCard = this.onClickCard.bind(this);
    this.onClickMenuViewMore = this.onClickMenuViewMore.bind(this);
    this.onClickRealize = this.onClickRealize.bind(this);
  }

  mapExchangeItemToListItem(product) {
    return (
      <>
        <ListItem key={product.id}>
          <ListItemText
            id={product.id}
            primary={product.name}
            secondary={"Quantidade: " + product.amount}
          />
        </ListItem>
        <Divider component="li" />
      </>
    );
  }

  renderExchangeList() {
    const { classes } = this.props;
    return (
      <Grid container spacing={2} justify="space-between">
        <Grid item>
          <Typography className={classes.listHeader}>Retirados</Typography>
          <List>
            {this.props.data.output.map(this.mapExchangeItemToListItem)}
          </List>
        </Grid>
        <Grid item>
          <Typography className={classes.listHeader}>Adicionados</Typography>
          <List>
            {this.props.data.input.map(this.mapExchangeItemToListItem)}
          </List>
        </Grid>
      </Grid>
    );
  }

  toggleCardExpand() {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  }
  toggleDropdownMenu(event) {
    this.setState({
      showDropdownMenu: !this.state.showDropdownMenu,
      anchorEl: event.currentTarget,
    });
  }

  onClickCard() {
    this.props.onClick(this.props.data);
  }

  getTextToRender(type) {
    if (type === "seeMore") {
      return this.state.isCollapsed ? "Ver Mais" : "Ver Menos";
    } else if (type === "troca") {
      const { classes } = this.props;
      return this.props.status ? (
        <Typography
          className={clsx(classes.movementTypeText, {
            [classes.realized]: this.props.status,
          })}
        >
          Troca realizada
        </Typography>
      ) : (
        <Typography
          className={clsx(classes.movementTypeText, {
            [classes.pendent]: !this.props.status,
          })}
        >
          Troca pendente
        </Typography>
      );
    } else if (type === "realize") {
      return this.props.status ? "Já realizada" : "Realizar troca";
    }
  }

  onClickMenuViewMore() {
    this.toggleCardExpand();
    this.setState({ showDropdownMenu: false });
  }

  async onClickRealize(isOk = false) {
    if (isOk) {
      if (
        await this.props.onClickRealize(
          this.props.data.hash,
          this.props.clientHash
        )
      ) {
        await this.addAsyncDialog({
          type: "success",
          message: "A troca realizada com sucesso!",
          title: "Troca realizada",
        });

        this.onClickCard(this.props.data);
      }
    }
    this.setState({ showDropdownMenu: false });
  }

  render() {
    const { classes } = this.props;
    const moreSettingsIconClass = clsx(classes.moreSettingsIcon, {
      [classes.moreSettingsIcon_active]: this.state.showDropdownMenu,
    });

    return (
      <>
        <Grid
          container
          className={classes.card}
          direction="row"
          alignItems="stretch"
        >
          <Grid
            item
            xs={2}
            container
            alignItems="center"
            justify="center"
            style={{ position: "relative" }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                background: "rgba(255, 255, 255, 0.15)",
              }}
            ></div>
            <IconButton onClick={this.onClickCard}>
              {this.props.icon}
            </IconButton>
          </Grid>
          <Grid
            item
            xs={7}
            container
            direction="column"
            alignItems="stretch"
            justify="space-between"
          >
            {this.getTextToRender("troca")}

            <Typography variant="body2" className={classes.paymentMethod}>
              Data de criação da troca
            </Typography>
            <Typography variant="body2" className={classes.amount}>
              {this.props.data.dataRealizacao.format("DD - MMM")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-end"
            >
              <Grid item>
                <Typography
                  variant="subtitle1"
                  className={classes.movementHour}
                >
                  {Moment(this.props.data.dataRealizacao).format("HH:mm")}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  className={moreSettingsIconClass}
                  onClick={this.toggleDropdownMenu}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={!this.state.isCollapsed}>
              <Grid container wrap="nowrap">
                <Grid item xs={12}>
                  {this.renderExchangeList()}
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={clsx(classes.showMore, {
              [classes.showNotRealized]: !this.props.status,
            })}
            onClick={this.toggleCardExpand}
          >
            <Grid item>
              <Typography variant="caption">
                {this.getTextToRender("seeMore")}
              </Typography>
            </Grid>
            <Grid item>
              <Box
                display="flex"
                className={clsx(classes.expand, {
                  [classes.expandOpen]: !this.state.isCollapsed,
                })}
              >
                <ExpandMoreIcon />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.showDropdownMenu}
          onClose={this.toggleDropdownMenu}
        >
          <MessageDialogContext.Consumer>
            {({ addAsyncDialog }) => {
              if (this.addAsyncDialog != addAsyncDialog)
                this.addAsyncDialog = addAsyncDialog;
              return (
                <>
                  <MenuItem onClick={this.onClickMenuViewMore}>
                    {this.getTextToRender("seeMore")}
                  </MenuItem>
                  <MenuItem
                    disabled={this.props.status}
                    onClick={async () => {
                      const isOk = await addAsyncDialog({
                        type: "info",
                        message:
                          "Você está prestes a realizar esta troca, deseja continuar?",
                        title: "Ação irreversivel",
                      });
                      await this.onClickRealize(isOk);
                    }}
                  >
                    {this.getTextToRender("realize")}
                  </MenuItem>
                  <MenuItem onClick={this.onClickCard}>
                    Imprimir comprovante
                  </MenuItem>
                </>
              );
            }}
          </MessageDialogContext.Consumer>
        </Menu>
      </>
    );
  }
}
export default withStyles(styles)(CardMovements);
