import DataAccess from "../DataAccessStrategy";
import ClienteBairro from "./ClienteBairro";
export default class Bairro extends DataAccess {
  constructor() {
    super("Bairro", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      nome: String,
      estado: String,
      cidade: String,
    });

    this.clienteBairroModel = new ClienteBairro();
  }

  async getClientNeighborhood(clientId) {
    const bairroNotFound = {
      nome: "Bairro não Encontrado",
      nomeCheck: "Bairro não Encontrado",
      id: -1,
      estado: "",
      cidade: "",
      index: -1,
    };
    let neighborhoodId;

    const clientNeighborhood = await this.clienteBairroModel.getAll(
      "ClienteId",
      clientId
    );

    if (clientNeighborhood && clientNeighborhood[0]) {
      neighborhoodId = clientNeighborhood[0].BairroId;
    } else {
      return bairroNotFound;
    }

    return await this.model.where("id").equals(neighborhoodId).first();
  }

  async findById(bairroId) {
    return await this.model.where("id").equals(bairroId).first();
  }

  async getOrderedNeighborhoodByIndex() {
    const orderedNeighborhood = await this.model.orderBy("index").toArray();
    return orderedNeighborhood.map((e) => ({
      id: e.id,
      name: e.nome,
      hash: e.hash,
      index: e.index,
      state: e.estado,
    }));
  }
}
