import React from "react";
import DocumentForm from "../../components/DocumentsList/DocumentForm";
import history from "../../router/History";

import { DocumentContext } from "../../contexts/DocumentContext";

/**
 * Tela de criação de documento
 */
class WindorForAdd extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   */
  constructor(props) {
    super(props);
    this.state = {};
    this.saveData = this.saveData.bind(this);
  }

  /**Função salva os dados no banco .
   * @function
   * @param {string} data Dado vindo do filho
   */
  async saveData(data) {
    try {
      await this.context.create({ nome: data });
      history.push("/documents");
    } catch (error) {}
  }

  render() {
    return (
      <DocumentForm
        titleHeader="Adicionar Documento"
        saveData={this.saveData}
      />
    );
  }
}
WindorForAdd.contextType = DocumentContext;
export default WindorForAdd;
