import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import UploadAndTakeImage from "../Buttons/UploadAndTakeImage";
import { Pictures } from "../../service/Pictures/Pictures";
import Camera from "../Pictures/";

const styles = (theme) => ({
  text: {
    fontSize: "0.7rem",
  },
  input: {
    //visibility:'hidden'
    display: "none",
  },
});

/**
 * Componente responsável por passar para os cards os estados que em que ele deve estar junto com os dados.
 */
class SelectImage extends React.Component {
  /**
   * @param {function} props.closePopUp Função fecharáa este componente
   * @param {boolean} props.status Boelano que definirá a exibição do dialog
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {ref} this.inputUploadImage  Referência para o input fazer upload de uma imagem
   */
  constructor(props) {
    super(props);
    this.state = {
      isCameraOpen: false,
    };
    this.inputUploadImage = React.createRef();
    this.onFileSelected = this.onFileSelected.bind(this);
    this.clickUploadImage = this.clickUploadImage.bind(this);
    this.clickTakeImage = this.clickTakeImage.bind(this);
    this.toggleCamera = this.toggleCamera.bind(this);
    this.onPhotoTake = this.onPhotoTake.bind(this);
  }

  componentDidMount() {}

  /**
   * Importante: 'Pictures.onFileSelected()' é uma service onde ocorre o acerto na orientação da foto e a geração da imagem
   * @example
   * onFileSelected =  async(event)=> {
   *  let image = await Pictures.onFileSelected(event)
   *  this.setState({document:{...this.state.document,value:{...this.state.document.value,image:image}}})
   * }
   */

  async onFileSelected(event) {
    let image = await Pictures.onFileSelected(event);
    this.props.uploadImage(image);
  }

  /**Função chamada ao clicar num botão, onde é ativado o input por referencia para carregar uma imagem
   * @function
   */
  clickUploadImage() {
    this.inputUploadImage.current.click();
    this.props.closePopUp();
  }

  /**Função chamada ao clicar num botão, onde é ativado o input por referencia para carregar uma imagem
   * @function
   */
  clickTakeImage() {
    this.setState({ isCameraOpen: true });
    this.props.closePopUp();
  }

  toggleCamera() {
    this.setState({ isCameraOpen: !this.state.isCameraOpen });
  }

  onPhotoTake(image) {
    this.props.uploadImage(image);
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Dialog onClose={this.props.closePopUp} open={this.props.status}>
          <Grid container justify="center" style={{ padding: "10px" }}>
            <Grid container justify="space-between">
              <Typography align="center">Escolha a Opção:</Typography>
              <CloseIcon onClick={this.props.closePopUp} />
            </Grid>
            <hr style={{ width: "100%" }} />
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ padding: "7px" }}
            >
              <UploadAndTakeImage
                clickTakeImage={this.clickTakeImage}
                clickUploadImage={this.clickUploadImage}
              />
            </Grid>
          </Grid>
        </Dialog>

        <Camera
          isOpen={this.state.isCameraOpen}
          onClose={this.toggleCamera}
          onPhotoTakeComplete={this.onPhotoTake}
        />

        <input
          type="file"
          accept="image/png,image/jpeg"
          ref={this.inputUploadImage}
          className={classes.input}
          onChange={this.onFileSelected}
        />
      </>
    );
  }
}
export default withStyles(styles)(SelectImage);
