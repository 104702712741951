import DataAccess from "../DataAccessStrategy";

export default class DespesaCombustivelVeiculo extends DataAccess {
  constructor() {
    super("DespesaCombustivelVeiculo", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      numeroDocumento: String,
      fornecedor: String,
      valor: String,
      quilometragem: String,
      data: String,
      ativo: Boolean,
      VeiculoId: Number,
      CombustivelId: Number,
      hashRotaAbertura: String,
      hash: String,
    });
    this.filterDifferentHashRotaAbertura =
      this.filterDifferentHashRotaAbertura.bind(this);
    this.filterByHashRotaAbertura = this.filterByHashRotaAbertura.bind(this);
  }

  async filterDifferentHashRotaAbertura(routeOpening) {
    if (routeOpening) {
      return await this.model
        .where("hashRotaAbertura")
        .notEqual(routeOpening.hash)
        .filter((a) => a.ativo != false)
        .toArray();
    } else {
      return await this.model.toArray();
    }
  }
  async filterByHashRotaAbertura(routeOpening) {
    if (routeOpening) {
      return await this.model
        .where("hashRotaAbertura")
        .equals(routeOpening.hash)
        .filter((a) => a.ativo != false)
        .toArray();
    } else {
      return await this.model.toArray();
    }
  }
}
