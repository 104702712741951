import ServiceNotification from "../serviceNotification";
/**
 * @description O funcionamento desda classe é identico À ErrorsNotificationsCenter, vide a descrição
 */
export default class SyncNotificationCenter extends ServiceNotification {
  static #instance;
  static #isSyncing = false;

  static activeSync = function () {
    SyncNotificationCenter.#isSyncing = true;
  };
  static disableSync = function () {
    SyncNotificationCenter.#isSyncing = false;
  };
  static getStatus = function () {
    return SyncNotificationCenter.#isSyncing;
  };

  static get instance() {
    if (!SyncNotificationCenter.#instance) {
      SyncNotificationCenter.#instance = new SyncNotificationCenter();
    }
    return SyncNotificationCenter.#instance;
  }
}
