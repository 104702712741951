import DataAccess from "../DataAccessStrategy";

export default class ProdutoVenda extends DataAccess {
  constructor() {
    super("ProdutoVenda", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      quantidade: String,
      valor: String,
      ProdutoId: Number,
      ClienteId: Number,
      ClienteVendaId: Number,
    });
  }
}
