import React from "react";
import * as moment from "moment";
import PropTypes from "prop-types";

class Cache extends React.Component {
  async componentDidMount() {
    const { fetchOptions } = this.props;
    const { url, ...options } = fetchOptions;

    const isCacheValid = this.cacheIsValid();
    const cache = await this.getDataFromCache(url);
    if (isCacheValid && cache) this.props.handleSuccess(cache, "Veio do cache");

    try {
      const [parsedInfo, response] = await this.fetch(url, options);
      if (!(isCacheValid && cache)) {
        this.props.handleSuccess(parsedInfo, "Veio do servidor");
      }
      await this.cacheData(url, response);
    } catch (error) {
      if (!(isCacheValid && cache)) {
        this.props.handleError(error);
      }
    }
  }

  async fetch(url, options) {
    let response;
    let clonedResponse;
    try {
      response = await fetch(url, options);
      clonedResponse = response.clone();
    } catch (error) {
      throw new Error("Verifique sua conexão com a internet");
    }

    if (response.status > 400) {
      throw new Error("Erro code: " + response.status);
    }

    const data = await response.json();
    if (data.error) {
      throw new Error("Houve um problema no servidor");
    }
    return [data, clonedResponse];
  }

  async getDataFromCache(url) {
    const cache = await caches.open("v1");

    const cachedData = await cache.match(url, { ignoreVary: true });
    if (!cachedData) return;
    return await cachedData.json();
  }

  async cacheData(url, data) {
    const { name } = this.props;

    const cache = await caches.open("v1");
    await cache.put(url, data);

    const now = new Date();
    localStorage.setItem(name, JSON.stringify(now));
  }

  cacheIsValid() {
    const { name, timeToLive } = this.props;
    const limitDate = moment().subtract(timeToLive, "days");
    const cacheInfoString = localStorage.getItem(name);
    const cacheDate = moment(JSON.parse(cacheInfoString));

    return cacheDate.isSameOrAfter(limitDate);
  }

  render() {
    return null;
  }
}

Cache.propTypes = {
  fetchOptions: PropTypes.object,
  handleSuccess: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  timeToLive: PropTypes.number.isRequired,
};

export default Cache;
