import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core/";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import SelectImage from "../../components/Dialogs/SelectImage";
import { Delete } from "@material-ui/icons/";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Img from "react-exif-orientation-img";
import Backdrop from "../Dialogs/BackdropLoader";
const styles = (theme) => ({
  card: {
    padding: "20px 20px 0",
    backgroundColor: "#F5F5F5",
    position: "relative",
  },
  floaterCardInfo: {
    top: 0,
    left: 0,
    position: "absolute",
    padding: "1vh 2vw",
    "& > p": {
      opacity: ".5",
      "font-size": " .85rem;",
      "font-weight": " 400;",
      "line-height": " 1.5;",
      color: theme.palette.text.secondary,
    },
  },

  avatar: {
    width: "110px",
    height: "110px",
    borderRadius: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "15px",
    background:
      "linear-gradient(to right, rgba(226,226,226,1) 0%, rgba(219,219,219,1) 50%, rgba(218,217,218,1) 55%, rgba(209,202,209,1) 100%)",
  },
  image: {
    color: "transparent",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    textAlign: "center",
    // textIndent: "10000px",
  },
  imageDiv: {
    width: "110px",
    height: "110px",
    marginBottom: "15px",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    fontSize: "1.25rem",
    alignItems: "center",
    flexShrink: 0,
    lineHeight: 1,
    userSelect: "none",
    borderRadius: "50%",
    justifyContent: "center",
  },
  deleteImage: {
    // position:'absolute',
    // bottom:'30px',
    // right:'15px'
  },
  changeImage: {
    // position:'absolute',
    // bottom:'80px',
    // right:'15px'
  },
});

/**
 * Componente onde é importado uma foto, onde a foto importada será exibida no meio do componente, no avatar.
 */
class AddPictures extends React.Component {
  /**
   * @param {string} props.image Imagem em base64
   * @param {function} props.onUploadImage Função que seta a imagem em base64 no componente pai
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {funcion} props.clickDeleteImage Função que exclui a imagem
   * @property {boolean} this.state.popUpImage Boelano usado como referência para alterar a exibição do dialog
   */
  constructor(props) {
    super(props);
    this.state = {
      popUpImage: false,
    };
    this.clickOpenPopUp = this.clickOpenPopUp.bind(this);
  }

  /**Função que altera a exibição do Dialog de upload de imagens <SelectImage/>
   * @function
   */
  clickOpenPopUp() {
    this.setState({ popUpImage: !this.state.popUpImage });
  }

  render() {
    let { classes } = this.props;

    return (
      <>
        <Backdrop
          secondaryText="Renderizando imagem"
          open={this.props.isLoading}
        />
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.card}
          direction={this.props.image ? "column" : "row"}
        >
          <div className={classes.floaterCardInfo}>
            <Typography>HASH: {this.props.hash}</Typography>
            <Typography>ID: {this.props.id}</Typography>
          </div>
          {!this.props.image ? (
            <>
              <Grid
                item
                className={classes.avatar}
                onClick={this.clickOpenPopUp}
              >
                <AddAPhotoIcon fontSize="large" />
              </Grid>
              <Grid item xs={12} container justify="center" alignItems="center">
                {/* <Grid item xs={6} container justify="center" alignItems="center"> */}
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="small"
                  startIcon={<PhotoCamera />}
                  onClick={this.clickOpenPopUp}
                >
                  Enviar foto
                </Button>
                {/* </Grid> */}
              </Grid>
            </>
          ) : (
            <>
              <Grid item className={classes.imageDiv}>
                <Img
                  alt="Foto do cliente"
                  src={this.props.image}
                  className={classes.image}
                />
              </Grid>
              <Grid item container direction="row" justify="space-between">
                <Grid
                  item
                  xs={6}
                  container
                  justify="center"
                  alignItems="center"
                >
                  {/* <span className={classes.deleteImage}> */}
                  <Button
                    variant="outlined"
                    disableElevation
                    size="small"
                    startIcon={<Delete />}
                    onClick={this.props.clickDeleteImage}
                  >
                    Deletar foto
                  </Button>
                  {/* </span> */}
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  justify="center"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="small"
                    startIcon={<PhotoCamera />}
                    onClick={this.clickOpenPopUp}
                  >
                    Enviar foto
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <SelectImage
          closePopUp={this.clickOpenPopUp}
          uploadImage={this.props.onUploadImage}
          status={this.state.popUpImage}
        />
      </>
    );
  }
}
export default withStyles(styles)(AddPictures);
