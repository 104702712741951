import React from "react";
import { withStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Grid from "@material-ui/core/Grid";
import CachedIcon from "@material-ui/icons/Cached";
import LinksSideBar from "./LinksSideBar";
import SyncDialog from "../../Dialogs/SyncDialog";
import { getUser } from "../../../service/authentication/index";
import history from "../../../router/History";
import { Avatar } from "@material-ui/core";
import { OpenRouteContext } from "../../../contexts/OpenRouteContext";

const styles = (theme) => ({
  box: {
    background:
      "linear-gradient(150deg, rgb(73, 53, 218) 10%, rgb(22, 99, 203) 60%, rgb(27, 91, 177) 90%)",
    padding: 10,
    width: "auto",
    position: "relative",
  },
  avatar: {
    boxShadow: "0 2px 12px -4px",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: " top center",
    margin: "auto",
    marginTop: "10px",
  },
  sincronizar: {
    backgroundColor: "#ffff",
    border: 0,
    fontSize: "0.8rem",
    boxShadow: "-2px 4px 5px -2px rgba(0,0,0,0.75)",
    padding: "6px",
    float: "right",
    outline: "none",
    fontFamily: "Roboto, sans-serif",
  },
  nome: {
    left: "30%",
    color: "#fff",
    fontSize: "1.1rem",
    fontFamily: "Roboto, sans-serif",
  },
  teamName: {
    left: "40%",
    color: "#fff",
    fontSize: ".9rem",
    fontFamily: "Roboto, sans-serif",
    display: "inline-block",
    paddingLeft: "10%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "80%",
  },
  icon: {
    height: 13,
    position: "relative",
    marginBottom: -2,
    marginLeft: -4,
  },
  gridBox: {
    height: "100%",
  },
  gridNameButton: {
    justifySelf: "flex-end",
  },
  gridHeight: {
    height: "100%",
  },
  gridOfButton: {
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
});

const DEFAULT_OPENROUTE_SYNCITEMS = [
  "syncMainRoute",
  "syncTeam",
  "syncVehicle",
  "syncFuel",
  "syncDocuments",
  "syncCityNeighborhood",
  "syncPaymentForm",
  "syncPaymentMethod",
  "syncBasket",
  "syncValidators",
  "syncHoliday",
  "syncRequiredFields",
  "syncClients",
  "syncVarEnvs",
];

const DEFAULT_CLOSEROUTE_SYNCITEMS = [
  "syncFuel",
  "syncVehicle",
  "syncExpenses",
  "sendClients",
  "syncNeighborhood",
  "syncDailyMap",
  "syncRouteOpening",
  "syncMainRoute",
  "syncTeam",
  "syncCityNeighborhood",
  "syncBasket",
  "syncVarEnvs",
];

class SideBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenSyncModal: false,
      autoSync: false,
      routeIsOpen: false,
      selectAll: false,
      preselectedItems: [],
      employeePic: null,
      teamName: "",
    };

    this.handleSyncInit = this.handleSyncInit.bind(this);
    this.handleToggleSyncModal = this.handleToggleSyncModal.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.closeList = this.closeList.bind(this);
  }
  componentDidMount() {
    this.context
      .team()
      .then((team) => this.setState({ teamName: team ? team.nome : "" }));
    getUser()
      .then((user) => {
        this.setState({ employeePic: user.foto });
      })
      .catch(console.log);
  }

  handleSyncInit(redirect) {
    if (!this.context.routeIsOpen && redirect && typeof redirect == "boolean")
      this.setState({
        redirect: true,
        preselectedItems: DEFAULT_OPENROUTE_SYNCITEMS,
        autoSync: true,
        selectAll: false,
      });
    else if (
      this.context.routeIsOpen &&
      redirect &&
      typeof redirect == "boolean"
    )
      this.setState({
        redirect: true,
        preselectedItems: DEFAULT_CLOSEROUTE_SYNCITEMS,
        autoSync: true,
        selectAll: false,
      });
    else
      this.setState({
        redirect: false,
        autoSync: false,
        preselectedItems: [],
        selectAll: true,
      });

    this.handleToggleSyncModal();
  }

  handleToggleSyncModal() {
    this.setState({ isOpenSyncModal: !this.state.isOpenSyncModal });
  }

  handleRedirect() {
    if (this.state.redirect && !this.context.routeIsOpen)
      history.push("/route/open");
  }

  closeList() {
    this.props.onClose();
  }

  render() {
    return (
      <React.Fragment>
        <SwipeableDrawer
          open={this.props.showSideBar}
          onClose={this.closeList}
          onOpen={this.closeList}
          onKeyDown={this.closeList}
          swipeAreaWidth={0}
        >
          <div style={{ width: "80vw", position: "relative", height: "100%" }}>
            <div className={this.props.classes.box}>
              <Grid
                container
                spacing={1}
                className={this.props.classes.gridBox}
              >
                <Grid item md={2} sm={2} xs={4}>
                  <Avatar
                    src={this.state.employeePic}
                    className={this.props.classes.avatar}
                  />
                </Grid>
                <Grid
                  item
                  md={10}
                  sm={10}
                  xs={8}
                  className={this.props.classes.gridNameButton}
                >
                  <Grid
                    container
                    spacing={2}
                    justify="space-between"
                    className={this.props.classes.gridHeight}
                  >
                    <Grid item md={8} sm={8} xs={12}>
                      <span className={this.props.classes.nome}>
                        Maximus Solucoes
                      </span>
                      <span className={this.props.classes.teamName}>
                        {this.state.teamName}
                      </span>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sm={4}
                      xs={12}
                      className={this.props.classes.gridOfButton}
                    >
                      <button
                        onClick={this.handleSyncInit}
                        className={this.props.classes.sincronizar}
                      >
                        <CachedIcon className={this.props.classes.icon} />
                        <span>Sincronizar o app</span>
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <LinksSideBar
              routeIsOpen={this.context.routeIsOpen}
              onClickSync={this.handleSyncInit}
            />
          </div>
        </SwipeableDrawer>

        <SyncDialog
          preselectedItems={this.state.preselectedItems}
          handleRedirect={this.handleRedirect}
          open={this.state.isOpenSyncModal}
          autoSync={this.state.autoSync}
          selectAll={this.state.selectAll}
          onClose={this.handleToggleSyncModal}
        />
      </React.Fragment>
    );
  }
}

SideBar.contextType = OpenRouteContext;
export default withStyles(styles)(SideBar);
