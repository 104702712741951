import React from "react";
import CardExpenses from "../CardComponents/CardsExpenses/Card";
import ExpansionPanel from "../ExpansionPanel/ExpansionPanel";
import { withStyles, TextField, FormControl, Grid } from "@material-ui/core";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import AmountMask from "../MaskedInputs/AmountMask";
import SelectInput from "../Inputs/SelectInput";
import AddIcon from "@material-ui/icons/Add";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";

const styles = (theme) => ({
  expansionPanel: {},
  "fade-enter": {
    maxHeight: 0,
    opacity: 0,
  },
  "fade-enter-active": {
    opacity: 1,
    maxHeight: "100vh",
    transition: "all 500ms",
  },
  "fade-exit": {
    opacity: 1,
    maxHeight: "100vh",
  },
  "fade-exit-active": {
    opacity: 0,
    maxHeight: 0,
    transition: "all 500ms",
  },
  addIcon: {
    height: "100%",
    color: "#1473cc",
  },
});

class GasComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expensesArray: [],
      // invalid: true,
      requiredFieldsArray: ["CombustivelId", "valor", "VeiculoId"],
      routeIsOpen: false,
      expenseIndex: null,
      fuelFormIsOpen: false,
      vehicleFormIsOpen: false,
    };
    this.setNewFuel = this.setNewFuel.bind(this);
    this.setNewVehicle = this.setNewVehicle.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onAddExpense = this.onAddExpense.bind(this);
    this.onDeleteExpense = this.onDeleteExpense.bind(this);
    this.onSaveExpense = this.onSaveExpense.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.handleToggleVehicleForm = this.handleToggleVehicleForm.bind(this);
    this.handleToggleFuelForm = this.handleToggleFuelForm.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.expenseContext.isLoading != this.props.expenseContext.isLoading
    ) {
      let savedExpenses = this.props.expenseContext[this.props.modelName].value;
      let newSavedExpenses = savedExpenses.map((expense) => {
        let exp = {};
        for (let key in expense) {
          exp[key] = { value: expense[key], error: false };
        }
        exp.isSaved = true;
        return exp;
      });
      if (!newSavedExpenses.length) {
        let invalid = false;
        this.setState({ invalid });
      }
      this.setState({ expensesArray: newSavedExpenses });
    }
  }

  handleInputChange(event, input, index) {
    let tempExpenseArray = [...this.state.expensesArray];
    if (input === "valor" || input === "VeiculoId" || input === "CombustivelId")
      tempExpenseArray[index][input].value = event;
    else tempExpenseArray[index][input].value = event.target.value;

    tempExpenseArray[index].isSaved = false;
    this.setState({ expensesArray: tempExpenseArray });
  }

  onHandleChange(isExpanded) {
    this.props.handleChange(isExpanded);
  }

  async onAddExpense() {
    if (await this.props.onAddExpense()) {
      let temp_expensesArray = {
        gasTypeArray: { value: "", error: false },
        numeroDocumento: { value: "", error: false },
        valor: { value: "", error: false },
        quilometragem: { value: "", error: false },
        VeiculoId: {
          value: this.props.vehicleContext.teamVehicleId,
          error: false,
        },
        CombustivelId: { value: "", error: false },
        isSaved: false,
      };
      this.setState({
        expensesArray: [...this.state.expensesArray, temp_expensesArray],
      });
      this.setState({ invalid: true });
    }
  }

  async onDeleteExpense(index) {
    const initialLength = this.state.expensesArray.length;
    let expense = this.state.expensesArray.map((expense) => {
      let exp = {};
      for (let key in expense) {
        exp[key] = expense[key].value;
      }
      return exp;
    });
    if (expense[index].DATABASE_ID) {
      await this.props.onDeleteExpense(this.props.modelName, expense[index]);

      if (initialLength === 1) {
        let temp_expensesArray = [...this.state.expensesArray];
        temp_expensesArray.splice(index, 1);
        this.setState({ expensesArray: temp_expensesArray });
      }
      this.setState({ invalid: false });
    }
  }

  async onSaveExpense(index) {
    if (this.validateFields(index)) {
      let exp = {};
      for (let key in this.state.expensesArray[index]) {
        exp[key] = this.state.expensesArray[index][key].value;
      }
      if (this.state.expensesArray[index].DATABASE_ID) {
        exp.DATABASE_ID = this.state.expensesArray[index].DATABASE_ID.value;
      }
      let success = await this.props.onSaveExpense(this.props.modelName, exp);
      if (success) {
        // Change isSaved property
        let temp_expensesArray = [...this.state.expensesArray];
        temp_expensesArray[index].isSaved = true;
        this.setState({ invalid: false, expensesArray: temp_expensesArray });
      }
    } else return;
  }

  validateFields(index) {
    let requiredFieldsArray = this.state.requiredFieldsArray;
    let formInvalid = false;

    for (let i = 0; i < requiredFieldsArray.length; i++) {
      if (
        this.state.expensesArray[index][requiredFieldsArray[i]].value === ""
      ) {
        let expense = [...this.state.expensesArray];
        expense[index][requiredFieldsArray[i]].error = true;
        formInvalid = true;
        this.setState({ expensesArray: expense });
      } else {
        let expense = [...this.state.expensesArray];
        expense[index][requiredFieldsArray[i]].error = false;
        this.setState({ expensesArray: expense });
      }
    }

    if (!formInvalid) {
      return true;
    } else return false;
  }

  handleToggleVehicleForm(expenseIndex) {
    this.props.attatchSetNewVehicleEvent(() => {
      this.setNewVehicle(expenseIndex, "GasComponent");
    });
  }
  handleToggleFuelForm(expenseIndex) {
    this.props.attatchSetNewFuelEvent(() => {
      this.setNewFuel(expenseIndex, "GasComponent");
    });
  }

  async setNewVehicle(index, component) {
    const vehicles = await this.props.vehicleContext.vehicle;
    let lastVehicleId = vehicles[vehicles.length - 1].id;
    if (component == "GasComponent");
    this.handleInputChange(lastVehicleId, "VeiculoId", index);
  }

  async setNewFuel(index, component) {
    const fuels = await this.props.fuelContext.fuel;
    let lastFuelId = fuels[fuels.length - 1]?.id;
    if (component == "GasComponent");
    this.handleInputChange(lastFuelId, "CombustivelId", index);
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <ExpansionPanel
          handleChange={this.onHandleChange}
          expanded={this.props.expanded}
          title={this.props.title || "Combustível"}
          iconTitle={<LocalGasStationIcon />}
          className={classes.expansionPanel}
          expensesCount={this.state.expensesArray.length}
          isValid={!this.state.invalid}
          disableAddBtn={this.state.invalid}
          onAddExpense={this.onAddExpense}
          custom
        >
          <TransitionGroup className={classes.prodcutsList} component={null}>
            {this.state.expensesArray.map((card, index) => (
              <CSSTransition
                timeout={500}
                classNames={{
                  enter: classes["fade-enter"],
                  enterActive: classes["fade-enter-active"],
                  exit: classes["fade-exit"],
                  exitActive: classes["fade-exit-active"],
                }}
                key={index}
              >
                <CardExpenses
                  key={index}
                  onDeleteExpense={() => this.onDeleteExpense(index)}
                  onSaveExpense={() => this.onSaveExpense(index)}
                  isSaved={card.isSaved}
                >
                  <Grid container>
                    <Grid item xs={!this.props.isBitboxEmployee ? 10 : 12}>
                      <FormControl fullWidth style={{ paddingBottom: 15 }}>
                        <SelectInput
                          label="Tipo de combustível"
                          required
                          error={card.CombustivelId.error}
                          value={card.CombustivelId.value}
                          onChange={(event) =>
                            this.handleInputChange(
                              event,
                              "CombustivelId",
                              index
                            )
                          }
                          menuItem={this.props.fuelContext.fuel}
                        />
                      </FormControl>
                    </Grid>
                    {!this.props.isBitboxEmployee && (
                      <Grid item xs={2}>
                        <Grid
                          container
                          justify="flex-end"
                          alignItems="center"
                          className={classes.addIcon}
                        >
                          <AddIcon
                            fontSize="large"
                            onClick={() => this.handleToggleFuelForm(index)}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid container>
                    <Grid item xs={!this.props.isBitboxEmployee ? 10 : 12}>
                      <FormControl fullWidth style={{ paddingBottom: 15 }}>
                        <SelectInput
                          label="Veículo"
                          required
                          error={card.VeiculoId.error}
                          value={card.VeiculoId.value}
                          onChange={(event) =>
                            this.handleInputChange(event, "VeiculoId", index)
                          }
                          menuItem={this.props.vehicleContext.vehicle}
                        />
                      </FormControl>
                    </Grid>
                    {!this.props.isBitboxEmployee && (
                      <Grid item xs={2}>
                        <Grid
                          container
                          justify="flex-end"
                          alignItems="center"
                          className={classes.addIcon}
                        >
                          <AddIcon
                            fontSize="large"
                            onClick={() => this.handleToggleVehicleForm(index)}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <FormControl fullWidth style={{ paddingBottom: 15 }}>
                    <TextField
                      label="Número do documento"
                      type="number"
                      fullWidth
                      value={card.numeroDocumento.value}
                      onChange={(event) =>
                        this.handleInputChange(event, "numeroDocumento", index)
                      }
                      error={card.numeroDocumento.error}
                      helperText={
                        card.numeroDocumento.error
                          ? "Informe o número de documento!"
                          : null
                      }
                    ></TextField>
                  </FormControl>

                  <FormControl fullWidth style={{ paddingBottom: 15 }}>
                    <TextField
                      label="Quilometragem"
                      type="number"
                      fullWidth
                      value={card.quilometragem.value}
                      onChange={(event) =>
                        this.handleInputChange(event, "quilometragem", index)
                      }
                      error={card.quilometragem.error}
                      helperText={
                        card.quilometragem.error
                          ? "Informe a quilometragem!"
                          : null
                      }
                    ></TextField>
                  </FormControl>

                  <FormControl fullWidth style={{ paddingBottom: 15 }}>
                    <TextField
                      label="Valor"
                      required
                      fullWidth
                      ref={(e) => (this.ref = e)}
                      value={card.valor.value}
                      onChange={(e1, e2) => {
                        this.handleInputChange(e2, "valor", index);
                      }}
                      InputProps={{
                        inputComponent: AmountMask,
                      }}
                      error={card.valor.error}
                      helperText={card.valor.error ? "Informe um valor!" : null}
                    ></TextField>
                  </FormControl>
                </CardExpenses>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </ExpansionPanel>
      </>
    );
  }
}

GasComponent.contextType = MessageDialogContext;

export default withStyles(styles)(GasComponent);
