import Dexie from "dexie";

export default class DexieCollections {
  constructor(db_name) {
    if (!db_name) throw new Error("É necessario informar o nome do banco");
    const DATABASE = new Dexie(db_name);
    DATABASE.version(1.0).stores({
      AdiantamentoFuncionario: "++DATABASE_ID, id, hash",
      Bairro: "++DATABASE_ID, id, index, nome, hash",
      Cesta: "++DATABASE_ID, id, hash",
      Cidade: "++DATABASE_ID, id, hash",
      ClienteEdit: "++DATABASE_ID, id, hash",
      Cliente: "++DATABASE_ID, id, hash, nome, diariaOrder, saldo",
      ClienteRecebimento: "++DATABASE_ID, id, hash, dataRealizacao, ClienteId",
      ClienteVenda: "++DATABASE_ID, id, hash, dataRealizacao,ClienteId",
      ClienteTrocaAvulso: "++DATABASE_ID, id, hash, dataRealizacao",
      Combustivel: "++DATABASE_ID, id, hash",
      DespesaCombustivelVeiculo: "++DATABASE_ID, id, hash",
      DespesaRota: "++DATABASE_ID, id, hash",
      DespesaVeiculo: "++DATABASE_ID, id, hash",
      Feriado: "++DATABASE_ID, id, hash",
      FormaPagamento: "++DATABASE_ID, id, hash",
      Funcionario: "++DATABASE_ID, id, hash",
      ManutencaoVeiculo: "++DATABASE_ID, id, hash",
      Produto: "++DATABASE_ID, id, hash",
      ProdutoVenda: "++DATABASE_ID, id, hash",
      ProdutoVendaAdicionado: "++DATABASE_ID, id, hash",
      ProdutoVendaRetirado: "++DATABASE_ID, id, hash",
      RotaValidator: "++DATABASE_ID, id",
      Validator: "++DATABASE_ID, id",
      Veiculo: "++DATABASE_ID, id, hash",
      CachedError: "++DATABASE_ID, id, date",
      ClienteDocumento: "++DATABASE_ID, id, hash, hashRotaAbertura",
      Documento: "++DATABASE_ID, id, hash",
      ClienteAgendamento: "++DATABASE_ID, id, hash, dataRecebimento, ClienteId",
      ClienteTelefone: "++DATABASE_ID, id, hash, ClienteId",
      ClienteBairro: "++DATABASE_ID, id, hash",
      CestaVenda: "++DATABASE_ID, id, hash, ClienteVendaId",
      CestaItens: "++DATABASE_ID, id, hash",
      MeioPagamento: "++DATABASE_ID, id",
      ClienteCampoRecomendavel: "++DATABASE_ID, id, hash",
      ClienteCampoObrigatorio: "++DATABASE_ID, id, hash",
      ClienteMovimentacaoCancelada: "++DATABASE_ID, id, hash, dataRealizacao",
      DespesaDeleted: "++DATABASE_ID, hash",
      ClienteCampoEdit: "++DATABASE_ID, id, hash",
      ClienteDiaria: "++DATABASE_ID, bairroOrder, saldo, nome, hash, BairroId",
    });

    DATABASE.version(1.1).stores({
      ClienteBairro: "++DATABASE_ID, id, hash, ClienteId",
      CestaItens: "++DATABASE_ID, id, hash, CestumId",
      ClienteDocumento: "++DATABASE_ID, id, hash, hashRotaAbertura, ClienteId",
    });

    DATABASE.version(1.2).stores({
      ManutencaoVeiculo: "++DATABASE_ID, id, hash, ativo",
      AdiantamentoFuncionario: "++DATABASE_ID, id, hash, ativo",
      DespesaCombustivelVeiculo: "++DATABASE_ID, id, hash, ativo",
      DespesaRota: "++DATABASE_ID, id, hash, ativo",
      DespesaVeiculo: "++DATABASE_ID, id, hash, ativo",
      Documento: "++DATABASE_ID, id, hash, ativo",
      Produto: "++DATABASE_ID, id, hash, ativo",
      Combustivel: "++DATABASE_ID, id, hash, ativo",
      Bairro: "++DATABASE_ID, id, index, nome, hash, ativo",
      Veiculo: "++DATABASE_ID, id, hash, ativo",
      Funcionario: "++DATABASE_ID, id, hash, ativo",
      Cesta: "++DATABASE_ID, id, hash, ativo",
      CestaItens: "++DATABASE_ID, id, hash, CestumId, ativo",
      ClienteAgendamento:
        "++DATABASE_ID, id, hash, dataRecebimento, ClienteId, ativo",
      Cliente: "++DATABASE_ID, id, hash, nome, diariaOrder, saldo, ativo",
      ClienteVenda:
        "++DATABASE_ID, id, hash, dataRealizacao,ClienteId, ativo, hashRotaAbertura",
      ClienteRecebimento:
        "++DATABASE_ID, id, hash, dataRealizacao, ClienteId, ativo, hashRotaAbertura",
    });

    DATABASE.version(1.3).stores({
      ClienteRecebimento:
        "++DATABASE_ID, id, hash, dataRealizacao, ClienteId, hashRotaAbertura, [ClienteId+dataRealizacao]",
      ClienteVenda:
        "++DATABASE_ID, id, hash, dataRealizacao,ClienteId, hashRotaAbertura, [ClienteId+dataRealizacao]",
      ClienteAgendamento:
        "++DATABASE_ID, id, hash, dataRecebimento, ClienteId, [dataRecebimento+ClienteId], [dataRecebimento+ClienteId+tipo]",
      ManutencaoVeiculo: "++DATABASE_ID, id, hash",
      AdiantamentoFuncionario: "++DATABASE_ID, id, hash",
      DespesaCombustivelVeiculo: "++DATABASE_ID, id, hash",
      DespesaRota: "++DATABASE_ID, id, hash",
      DespesaVeiculo: "++DATABASE_ID, id, hash",
      Documento: "++DATABASE_ID, id, hash",
      Produto: "++DATABASE_ID, id, hash",
      Combustivel: "++DATABASE_ID, id, hash",
      Bairro: "++DATABASE_ID, id, index, nome, hash",
      Veiculo: "++DATABASE_ID, id, hash",
      Funcionario: "++DATABASE_ID, id, hash, EquipeId",
      Cesta: "++DATABASE_ID, id, hash",
      CestaItens: "++DATABASE_ID, id, hash, CestumId",
      Cliente: "++DATABASE_ID, id, hash, nome, diariaOrder, saldo",
    });

    DATABASE.version(1.4).stores({
      ClienteRecebimento:
        "++DATABASE_ID, id, hash, dataRealizacao, ClienteId, hashRotaAbertura, [ClienteId+dataRealizacao]",
      ClienteVenda:
        "++DATABASE_ID, id, hash, dataRealizacao,ClienteId, hashRotaAbertura, [ClienteId+dataRealizacao]",
    });

    DATABASE.version(1.5).stores({
      ClienteBairro: "++DATABASE_ID, id, hash, ClienteId, BairroId",
      ManutencaoVeiculo: "++DATABASE_ID, id, hash, hashRotaAbertura",
      DespesaVeiculo: "++DATABASE_ID, id, hash, hashRotaAbertura",
      DespesaRota: "++DATABASE_ID, id, hash, hashRotaAbertura",
      DespesaCombustivelVeiculo: "++DATABASE_ID, id, hash, hashRotaAbertura",
      AdiantamentoFuncionario: "++DATABASE_ID, id, hash, hashRotaAbertura",
    });

    DATABASE.version(1.6).stores({
      ClienteEdit: "++DATABASE_ID, id, hash, ClienteId",
      ClienteTrocaAvulso: "++DATABASE_ID, id, hash, dataRealizacao, ClienteId",
      ProdutoVenda: "++DATABASE_ID, id, hash, ClienteVendaId",
      ClienteMovimentacaoCancelada:
        "++DATABASE_ID, id, hash, dataRealizacao, ClienteId",
    });

    DATABASE.version(1.7).stores({
      ClienteAgendamento:
        "++DATABASE_ID, id, hash, dataRecebimento, ClienteId, createdAt, [dataRecebimento+ClienteId], [dataRecebimento+ClienteId+tipo]",
    });
    DATABASE.version(1.8).stores({
      Cliente:
        "++DATABASE_ID, id, hash, nome, diariaOrder, saldoAtual, lowerCaseNome",
    });
    DATABASE.version(1.9).stores({
      ClienteDiaria: "++DATABASE_ID, position, saldo, nome, hash, BairroId",
    });

    DATABASE.version(2.0).stores({
      ClienteTrocaAvulso:
        "++DATABASE_ID, id, hash, dataRealizacao, ClienteId, hashRotaAbertura",
    });
    DATABASE.version(2.1).stores({
      ClienteTrocaAvulso:
        "++DATABASE_ID, id, hash, dataRealizacao, ClienteId, hashRotaAbertura, ClienteVendaId",
    });
    DATABASE.version(2.2).stores({
      ClienteDocumento:
        "++DATABASE_ID, id, hash, hashRotaAbertura, ClienteId, DocumentoId",
    });

    DATABASE.version(2.3).stores({
      ClienteFoto: "++DATABASE_ID, ClienteId, ClienteHash",
    });

    DATABASE.version(2.4).stores({
      CestaItens: "++DATABASE_ID, id, hash, CestumId, ProdutoId",
      CestaVenda: "++DATABASE_ID, id, hash, ClienteVendaId, CestumId",
    });

    DATABASE.version(2.5).stores({
      ProdutoVenda: "++DATABASE_ID, id, hash, ClienteVendaId, ProdutoId",
    });

    DATABASE.clearDatabase = this.clearDatabase.bind(this);
    DATABASE.generateUniqId = this.generateUniqId;
    DexieCollections.database = DATABASE;
  }

  static get database() {
    if (!window.DATABASE)
      throw new Error(
        "Dexie database definition must be initiatate before access it."
      );
    return window.DATABASE;
  }

  static set database(databaseArg) {
    if (window.DATABASE) return;
    window.DATABASE = databaseArg;
  }

  clearDatabase() {
    const db = DexieCollections.database;
    const proms = db.tables.map(async (table) => {
      return db[table.name].clear();
    });
    return Promise.all(proms);
  }

  generateUniqId() {
    const length = 10;
    const array = crypto.getRandomValues(new Uint32Array(length));
    const randomNumberPre = Math.floor(Math.random() * length);
    const randomNumberSub = Math.floor(Math.random() * length);
    return (
      -1 *
      (new Date().getTime() +
        (array[randomNumberPre] || randomNumberPre) +
        (array[randomNumberSub] || randomNumberSub))
    );
  }
}
