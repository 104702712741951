import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import RoundButton from "../../Buttons/RoundButton";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";

const styles = (theme) => ({});

class CardExpenses extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Paper elevation={3} style={{ padding: "10px", marginBottom: "10px" }}>
          {this.props.children}

          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <RoundButton
                func={this.props.onDeleteExpense}
                disabled={this.props.disableDeleteBtn}
                color={!this.props.disableDeleteBtn && "#ff0000"}
                icon={<DeleteIcon />}
                colorIcon="#fff"
              />
            </Grid>
            <Grid item>
              <Button
                onClick={this.props.onSaveExpense}
                variant={this.props.isSaved ? `outlined` : `contained`}
                color="primary"
                // style={{ backgroundColor: "#24f", color: "#fff" }}
              >
                {this.props.isSaved ? (
                  <>
                    <DoneIcon />
                    &nbsp;Salvo
                  </>
                ) : (
                  <>Salvar</>
                )}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}
export default withStyles(styles)(CardExpenses);
