import greenlet from "greenlet";

const worker = greenlet(`async (options, messageChannel) => {
  class Helper {
    static READ_ONLY = "readonly";
    static READ_WRITE = "readwrite";
    static DATABASE_NAME = "employee_db";
    static relations = [
      {
        origin: "Cesta",
        to: "CestaVenda",
        keyPath: "CestumId",
      },
      {
        origin: "Produto",
        to: "CestaItens",
        keyPath: "ProdutoId",
      },
      {
        origin: "Produto",
        to: "ProdutoVenda",
        keyPath: "ProdutoId",
      },
      {
        origin: "Cesta",
        to: "CestaItens",
        keyPath: "CestumId",
      },
      {
        origin: "Documento",
        to: "ClienteDocumento",
        keyPath: "DocumentoId",
      },
      {
        origin: "Bairro",
        to: "ClienteBairro",
        keyPath: "BairroId",
      },
    ];

    openDatabase(dbVersion) {
      return new Promise((resolve, reject) => {
        const openDbRequest = indexedDB.open(Helper.DATABASE_NAME, dbVersion);
        openDbRequest.onsuccess = (event) => {
          this.db = event.target.result;
          resolve();
        };
        openDbRequest.onerror = reject;
      });
    }

    getClientBlock(options, validate) {
      return new Promise((resolve) => {
        if (!this.db) throw new Error("You must open the database!");
        const opt = Object.assign({ blockSize: 1, startFrom: null }, options);
        const clientStore = this.db
          .transaction("Cliente", Helper.READ_ONLY)
          .objectStore("Cliente");

        const keyRange =
          opt.startFrom === null
            ? null
            : IDBKeyRange.lowerBound(opt.startFrom, true);

        const cursorRequest = clientStore.openCursor(keyRange);

        const clientBlock = [];
        cursorRequest.onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor && clientBlock.length < opt.blockSize) {
            const client = cursor.value;
            const shouldIncludeClient = validate(client);

            if (shouldIncludeClient) {
              clientBlock.push(client);
            }

            cursor.continue();
          } else {
            const lastClient = clientBlock[clientBlock.length - 1];
            clientBlock.forEach(this.appendFinishFn);
            resolve({
              result: clientBlock,
              nextCursor: lastClient ? lastClient.DATABASE_ID : null,
            });
          }
        };
      });
    }

    appendFinishFn = (client) => {
      client.finish = (newId) => {
        const db = this.db;
        return new Promise((resolve, reject) => {
          client.shouldSync = false;
          client.id = newId ? newId : client.id;

          delete client.finish;
          const updateRequest = db
            .transaction("Cliente", Helper.READ_WRITE)
            .objectStore("Cliente")
            .put(client);

          updateRequest.onsuccess = resolve;
          updateRequest.onerror = reject;
        });
      };
    };

    async updateExchanges(productComparators) {
      const transaction = this.db.transaction(
        "ClienteTrocaAvulso",
        Helper.READ_WRITE
      );
      const store = transaction.objectStore("ClienteTrocaAvulso");
      const exchanges = await this._getObjectsToUpdate(store);

      const idUpdater = (p) => {
        const comparator = productComparators.find(
          (el) => el.oldId === p.ProdutoId
        );
        if (comparator) {
          p.ProdutoId = comparator.newId;
        }
      };

      const proms = exchanges.map((ex) => {
        return new Promise((resolve, reject) => {
          if (Array.isArray(ex.adicionados)) {
            ex.adicionados.forEach(idUpdater);
            ex.retirados.forEach(idUpdater);
            const updateRequest = store.put(ex);
            updateRequest.onsuccess = resolve;
            updateRequest.onerror = reject;
          }
        });
      });

      return await Promise.all(proms);
    }

    async preSync(options) {
      const models = ["Documento", "Bairro", "Cesta", "Produto", "CestaItens"];

      const docs = await this.sendDocuments(options);
      const { baskets, products, items } = await this.sendBaskets(options);
      const neighborhood = await this.sendNeighborhood(options);
      const writerTransaction = this.db.transaction(models, Helper.READ_WRITE);

      const compareableInfo = {
        Cesta: baskets,
        CestaItens: items,
        Documento: docs,
        Produto: products,
        Bairro: neighborhood,
      };

      const updateProms = models.map(async (modelName) => {
        const store = writerTransaction.objectStore(modelName);
        const idIndex = store.index("id");
        const comparable = compareableInfo[modelName];
        await Promise.all(
          comparable.map((comp) => this.updateId(comp, idIndex, store))
        );
      });

      await Promise.all(updateProms);

      const updateRelationedModels = Helper.relations.map((el) => {
        const updateProms = compareableInfo[el.origin].map((comparator) =>
          this.updateRelationedModels(el.to, comparator, el.keyPath)
        );
        return Promise.all(updateProms);
      });

      await Promise.all(updateRelationedModels);
      await this.updateExchanges(compareableInfo.Produto);

      return compareableInfo;
    }

    updateRelationedModels(modelName, comparator, keyPath) {
      return new Promise((resolve, reject) => {
        const transaction = this.db.transaction(modelName, Helper.READ_WRITE);
        const store = transaction.objectStore(modelName);
        const pathIndex = store.index(keyPath);

        const idEqualToComparator = IDBKeyRange.only(comparator.oldId);
        const cursorRequest = pathIndex.openCursor(idEqualToComparator);

        cursorRequest.onsuccess = (event) => {
          const cursor = event.target.result;

          if (cursor) {
            const info = cursor.value;
            info[keyPath] = comparator.newId;
            const updateRequest = store.put(info);
            updateRequest.onsuccess = (event) => {
              info.t = "";
              cursor.continue();
            };
            updateRequest.onerror = reject;
          } else {
            resolve();
          }
        };
        cursorRequest.onerror = reject;
      });
    }

    updateId({ oldId, newId }, index, store, keyPath = "id") {
      return new Promise((resolve, reject) => {
        const getRequest = index.get(oldId);
        getRequest.onsuccess = (event) => {
          const data = event.target.result;
          data[keyPath] = newId;
          data.shouldSync = false;
          const updateRequest = store.put(data);
          updateRequest.onsuccess = resolve;
          updateRequest.onerror = reject;
        };
        getRequest.onerror = reject;
      });
    }

    negativeId = (el) => el.id < 0;

    async sendBaskets(options) {
      const transaction = this.db.transaction(
        ["Produto", "Cesta", "CestaItens"],
        Helper.READ_ONLY
      );
      const productStore = transaction.objectStore("Produto");
      const basketStore = transaction.objectStore("Cesta");
      const itemStore = transaction.objectStore("CestaItens");

      const basketSend = await this._getObjectsToUpdate(basketStore);
      const productSend = await this._getObjectsToUpdate(productStore);
      const itemsSend = await this._getObjectsToUpdate(itemStore);

      const URL = \`\${options.baseUrl}/api/cestasprodutos?token=\${options.token}\`;

      const body = {
        newBaskets: basketSend.filter(this.negativeId),
        newProducts: productSend.filter(this.negativeId),
        newBasketItems: itemsSend.filter(this.negativeId),
      };

      const shouldSend = Object.keys(body).some((key) => !!body[key].length);
      if (!shouldSend) return { baskets: [], products: [], items: [] };

      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const serverData = await response.json();
      if (serverData.error) throw new Error(serverData.msg);

      const getDiffId = (el) => ({ newId: el.id, oldId: el.oldId });

      return {
        baskets: serverData.baskets.map(getDiffId),
        products: serverData.products.map(getDiffId),
        items: serverData.basketItems.map(getDiffId),
      };
    }

    async sendDocuments(options) {
      const transaction = this.db.transaction("Documento", Helper.READ_ONLY);
      const docStore = transaction.objectStore("Documento");
      const docSend = await this._getObjectsToUpdate(docStore);
      const URL = \`\${options.baseUrl}/api/documento?token=\${options.token}\`;

      const body = {
        documentsToCreate: docSend.filter(this.negativeId),
      };
      if (!body.documentsToCreate.length) return [];

      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      if (data.error) throw new Error(data.msg);

      return data.createdDocs.map((el) => {
        return {
          oldId: el.oldId || el.id,
          newId: el.id,
        };
      });
    }

    async sendNeighborhood(options) {
      const transaction = this.db.transaction("Bairro", Helper.READ_ONLY);
      const store = transaction.objectStore("Bairro");
      const info = await this._getObjectsToUpdate(store);
      const URL = \`\${options.baseUrl}/api/bairro?token=\${options.token}\`;

      const data = await Promise.all(
        info.filter(this.negativeId).map(async (neighborhood) => {
          const res = await fetch(URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              bairro: neighborhood,
              rotaId: routeId,
            }),
          });

          const data = await res.json();
          if (data.error) throw new Error(data.msg);

          return {
            oldId: neighborhood.id,
            newId: data.data.bairro.id,
          };
        })
      );

      return data;
    }

    async buildClientDataChanged(client) {
      const involvedModels = [
        "Bairro",
        "CestaVenda",
        "ClienteFoto",
        "ClienteEdit",
        "ClienteVenda",
        "ProdutoVenda",
        "ClienteBairro",
        "ClienteTelefone",
        "ClienteDocumento",
        "ClienteRecebimento",
        "ClienteAgendamento",
        "ClienteTrocaAvulso",
      ];

      const transaction = this.db.transaction(involvedModels, Helper.READ_ONLY);

      const modelNameToStore = (modelName) => ({
        store: transaction.objectStore(modelName),
        name: modelName,
      });

      const storesToData = async (model) => {
        let force = false;
        if(model.name === "ClienteTelefone") {
          force = true;
        }
        const data = await this._getObjectsToUpdate(model.store, client.id, force);
        return {
          data: data,
          name: model.name,
        };
      };
      const stores = involvedModels.map(modelNameToStore);
      const prom = stores.map(storesToData);
      const clientChangedInfo = await Promise.all(prom);
      return clientChangedInfo;
    }

    _getObjectsToUpdate(dataStore, clientId, shouldForce = false) {
      return new Promise((resolve, reject) => {
        const cursorRequest = dataStore.openCursor();
        const dataToSend = [];

        cursorRequest.onsuccess = (event) => {
          const currentCursor = event.target.result;
          if (currentCursor) {
            const data = currentCursor.value;

            if ((clientId && clientId === data.ClienteId) || !clientId) {
              if (shouldForce || data.shouldSync) {
                dataToSend.push(data);
              }
            }

            currentCursor.continue();
          } else {
            resolve(dataToSend);
          }
        };

        cursorRequest.onerror = reject;
      });
    }

    onIdchange() {}

    buildDataToSend(client, dataToSend) {
      const getInfoByModelName = (modelName) => {
        const info = dataToSend.find((el) => el.name === modelName);
        return info.data;
      };
      const cestaVendidas = getInfoByModelName("CestaVenda");
      const produtoVenda = getInfoByModelName("ProdutoVenda");
      const infoToSend = Object.assign({}, client);

      const sales = getInfoByModelName("ClienteVenda").map((sale) => {
        const saleClone = Object.assign({}, sale);
        const getRelations = (el) => el.ClienteVendaId === sale.id;
        saleClone.cestaVendidas = cestaVendidas.filter(getRelations);
        saleClone.produtoVenda = produtoVenda.filter(getRelations);
        return saleClone;
      });

      const bairroId =
        getInfoByModelName("ClienteBairro")[0] &&
        getInfoByModelName("ClienteBairro")[0].BairroId;

      infoToSend.vendas = sales;
      infoToSend.clienteEdit = getInfoByModelName("ClienteEdit");
      infoToSend.recebimentos = getInfoByModelName("ClienteRecebimento");
      infoToSend.trocasAvulso = getInfoByModelName("ClienteTrocaAvulso");
      infoToSend.telefones = getInfoByModelName("ClienteTelefone");
      infoToSend.bairro = bairroId;
      const documents = getInfoByModelName("ClienteDocumento");

      infoToSend.documentos = documents.map((el) => {
        const clone = Object.assign({}, el);
        delete clone.foto;
        return clone;
      });

      const foto = getInfoByModelName("ClienteFoto");

      const schedulesToSend = getInfoByModelName("ClienteAgendamento");

      infoToSend.agendamentosEditados = schedulesToSend.filter(
        (el) => el.editado && el.id > 0
      );
      infoToSend.agendamentos = schedulesToSend.filter((el) => el.id < 0);

      delete infoToSend.foto;
      delete infoToSend.telefone;
      return {
        foto,
        documents,
        structuredDataToSend: infoToSend,
      };
    }

    async postToServer(infoToSend, URL) {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(infoToSend),
      });

      const serverData = await response.json();
      if (serverData.error) throw new Error(serverData.msg);

      return serverData;
    }

    updateModelReference(modelName, options) {
      const { newClientId, dataToUpdate } = options;
      const transaction = this.db.transaction(modelName, Helper.READ_WRITE);
      const store = transaction.objectStore(modelName);

      const updator = (instance) =>
        new Promise((resolve, reject) => {
          instance.shouldSync = false;
          if (!!instance.ClienteId) instance.ClienteId = newClientId;

          const changeRequest = store.put(instance);
          changeRequest.onsuccess = resolve;
          changeRequest.onerror = reject;
        });

      const updateProms = dataToUpdate.map(updator);
      return Promise.all(updateProms);
    }
  }

  const { baseUrl, token, routeId, dbVersion } = options;
  const helper = new Helper();

  try {
    await helper.openDatabase(dbVersion);
    const shouldUploadClient = (client) => client.shouldSync;

    const clientEndpoint = \`\${baseUrl}/api/rota/\${routeId}/cliente?token=\${token}\`;
    let hasNext = true;
    let nextCursor = null;

    //messageChannel.postMessage({
      //status: "info",
      //message: "Realizando préSync",
    //});

    await helper.preSync(options);

    //messageChannel.postMessage({
      //status: "info",
      //message: "Identificando clientes para upload.",
    //});

    while (hasNext) {
      const { result: clientBlock, nextCursor: cursor } =
        await helper.getClientBlock(
          { startFrom: nextCursor },
          shouldUploadClient
        );

      for (const client of clientBlock) {
        const dataShouldSync = await helper.buildClientDataChanged(client);

        //messageChannel.postMessage({
          //status: "info",
          //message: \`Upload de \${client.nome}\`,
        //});

        const { foto, documents, structuredDataToSend } =
          helper.buildDataToSend(client, dataShouldSync);

        const apiResponse = await helper.postToServer(
          structuredDataToSend,
          clientEndpoint
        );

        const newClientId = apiResponse.cliente.id;

        if (client.shouldUploadFoto && foto.length) {
          const fotoEndpoint = \`\${baseUrl}/api/cliente/\${newClientId}/upload-foto?token=\${token}\`;
          const picture = foto[0].foto;
          await helper.postToServer({ foto: picture }, fotoEndpoint);
        }

        if (documents.length) {
          const docsToSend = documents.filter(
            (doc) => doc.shouldUploadFoto && doc.foto
          );

          const uploadProms = docsToSend.map((doc) => {
            const documentEndpoint = \`\${baseUrl}/api/cliente/\${newClientId}/\${doc.DocumentoId}/\${doc.hash}/upload-foto?token=\${token}\`;
            return helper.postToServer({ foto: doc.foto }, documentEndpoint);
          });

          await Promise.all(uploadProms);
        }

        const updateModelReferenceProms = dataShouldSync.map(({ name, data }) =>
          helper.updateModelReference(name, {
            newClientId,
            dataToUpdate: data,
          })
        );

        await Promise.all(updateModelReferenceProms);

        await client.finish(newClientId);
      }

      nextCursor = cursor;
      hasNext = !!cursor;
    }

    //messageChannel.postMessage({
      //status: "success",
      //message: "Upload concluido",
    //});
  } catch (error) {
    messageChannel.postMessage({
      status: "error",
      message: "Houve um problema com upload de dados",
    });
    throw error;
  }
}`);

export { worker as WorkerDownloadClients };
