import React from "react";
import AppBar from "../../components/MenuSideBar/AppBar.js";
import { withStyles, Grid, Typography } from "@material-ui/core";
import Footer from "../../components/Footer/FooterBalance";
import TodayIcon from "@material-ui/icons/Today";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import moment from "moment";
import "moment/locale/pt-br";
import TwoCalendarInputs from "../../components/Inputs/TwoCalendarInputs";
import InfiniteScroll from "react-infinite-scroll-component";
import SchedulingReport from "../../components/Scheduling/SchedulingReport";
import SearchIcon from "@material-ui/icons/Search";

import { SchedulingsContext } from "../../contexts/SchedulingsContext";

const styles = (theme) => ({
  root: {
    backgroundColor: "#f5f5f5",
    paddingBottom: "75px",
    height: "100vh",
    position: "relative",
  },
  containerCards: {
    backgroundColor: "#f5f5f5",
    paddingBottom: "86px",
    "& > div": {
      width: "100%",
    },
  },
  list: {
    backgroundColor: "#fff",
    "& > div": {
      paddingBottom: "10px",
    },
  },
  date: {
    backgroundColor: "#fff",
    padding: "10px 10px 0px 10px",
    "& .MuiTypography-body1": {
      fontFamily: "Roboto,sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#191919",
    },
  },
  reportDate: {
    lineHeight: "1.6",
    display: "inline-block",
    padding: "0px 3px",
    height: "30px",
    width: "auto",
    background: "#ddd",
    borderRadius: "10px",
  },
  dateDialogClass_tableContainer: {
    marginTop: "40px",
  },
  dateDialogClass_dialogContentClass: {
    padding: "15px 0",
  },
  dateDialogClass_endDateClass: {
    "& .MuiInputBase-root": {
      maxHeight: "40px",
    },
  },
  dateDialogClass_startDateClass: {
    "& .MuiInputBase-root": {
      maxHeight: "40px",
    },
  },
  dateDialogClass_dateInputClass: {
    color: "#999",
  },
  notFound: {
    position: "absolute",
    top: "50vh",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "& .MuiTypography-body1": {
      fontFamily: "Roboto,sans-serif",
      fontSize: "2rem",
    },
    "& svg": {
      width: "10vh",
      height: "10vh",
    },
  },
});

/**
 * Tela do relatório de agendamentos.
 */
class Schedulings extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {object} props.dialogContext Contexto dos popUps
   * @param {object} props.schedulingsContext Contexto dos agendamentos
   * @property {array} this.state.data Lista com os dados de todos os agendamentos
   * @property {array} this.state.dataFiltered  Lista com os dados filtrados após a pesquisa no searchBar
   * @property {number} this.state.isOpen  Estado que armazena a hash do card selecionado
   * @property {number} this.state.clientCount  Estado que armazena o numero total de clientes no relatório
   * @property {object} this.state.moves  Estado que armazena o numero total de movimentações de troca,venda e recimento
   * @property {boolean} this.state.viewClientIsOpen  Boleano que definirá a exibição do componente de ver cliente.
   */
  constructor() {
    super();
    this.state = {
      dataFiltered: [],
      search: "",
      clientCount: 0,
      moves: { exchanges: 0, receive: 0, sell: 0 },
      dialogOpen: false,
      temp_startDate: null,
      temp_endDate: null,
      startDate: new Date(),
      endDate: new Date(),
    };

    this.fetchSchedulings = this.fetchSchedulings.bind(this);
    this.handleToggleVerticalEditDisplaySettings =
      this.handleToggleVerticalEditDisplaySettings.bind(this);
    this.handleCloseVerticalEdit = this.handleCloseVerticalEdit.bind(this);
    this.handleToggleVerticalEdit = this.handleToggleVerticalEdit.bind(this);
    this.handleNotFound = this.handleNotFound.bind(this);
    this.handleClickFilterDate = this.handleClickFilterDate.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.filterDialog = this.filterDialog.bind(this);
    this.getscheduleCount = this.getscheduleCount.bind(this);
  }

  /**Função executada na montagem do componente.
   * @function
   */
  componentDidMount() {
    this.fetchSchedulings();
  }

  /**Função irá fazer o fetch de agendamentos dos dias selecionados.
   * @function
   * @param {boolean} reset Boelano que caso for true irá limpar a array de agendamentos.
   */
  async fetchSchedulings(reset) {
    await this.props.schedulingsContext.fetchSchedulingsHash(
      this.state.startDate,
      this.state.endDate,
      reset
    );
  }

  /**Função que irá fazer a alternância da exibição das opção de editar nos cards.
   * @function
   * @param {number} hash Hash do agendamento
   */
  handleToggleVerticalEditDisplaySettings(hash) {
    if (hash && this.state.isOpen != hash) {
      this.setState({ isOpen: hash });
    } else {
      this.setState({ isOpen: null });
    }
  }

  /**Função que cancela a exibição do componente EditRemove.
   * @function
   */
  handleCloseVerticalEdit() {
    this.setState({ isOpen: null });
  }

  /**Função que seta o hash do novo card selecionado.
   * @function
   * @param {number} hash hash do card selecionado
   */
  handleToggleVerticalEdit(hash) {
    this.setState({ isOpen: hash });
  }

  /**Função que renderiza uma menssagem de 'Not found' caso não estiver nenhum cliente para aquele dia.
   * @function
   */
  handleNotFound() {
    if (this.props.schedulingsContext.schedulingsHash.length == 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={this.props.classes.notFound}
        >
          <Grid item xs={12}>
            <Typography align="center">Nenhum cliente encontrado!</Typography>
          </Grid>
          <SearchIcon />
        </Grid>
      );
    }
  }

  /**Função que lida com a alternância de exibição do popUp de filtragem por data.
   * @function
   */
  handleClickFilterDate() {
    this.filterDialog();
    this.setState({ dialogOpen: true });
  }

  /**Função que seta as datas de inicio e fim temporiariamente.
   * @function
   * @param {Date} startDate Data de inicio
   * @param {Date} endDate Data final
   */
  onFilterChange(startDate, endDate) {
    this.setState({ temp_startDate: startDate, temp_endDate: endDate });
  }

  /**Função que renderiza o popUps de filtragem.
   * @function
   */
  filterDialog() {
    let { classes } = this.props;
    this.props.dialogContext.addDialog({
      message: (
        <Grid container direction="column">
          <TwoCalendarInputs
            classes={{
              startDateClass: classes.dateDialogClass_startDateClass,
              endDateClass: classes.dateDialogClass_endDateClass,
              dateInputClass: classes.dateDialogClass_dateInputClass,
              tableContainer: classes.dateDialogClass_tableContainer,
            }}
            onChange={this.onFilterChange}
            errors={{ errorEnd: null, errorStart: null }}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            activePreset="schedulings"
          />
        </Grid>
      ),
      handleConfirm: async () => {
        let moves = {
          receive: 0,
          sell: 0,
          exchanges: 0,
        };
        this.setState(
          {
            startDate: this.state.temp_startDate || this.state.startDate,
            endDate: this.state.temp_endDate || this.state.endDate,
            moves: moves,
          },
          () => {
            window.scrollTo(0, 0);
            setTimeout(() => this.fetchSchedulings(true), 400);
          }
        );
        this.props.dialogContext.popDialog();
      },
      classes: {
        dialogContentClass: classes.dateDialogClass_dialogContentClass,
      },
      type: "info",
      noIcon: true,
      title: "Escolha o período do relatório",
      disableBackdropClick: false,
    });
  }

  getscheduleCount() {
    const {
      recebimento: receive,
      troca: exchanges,
      venda: sell,
    } = this.props.schedulingsContext.schedulingCount;

    return {
      receive,
      exchanges,
      sell,
    };
  }

  render() {
    let { classes } = this.props;
    moment.locale("pt-br");
    return (
      <>
        <div className={classes.root}>
          <AppBar
            title="Agendamentos"
            icon={<TodayIcon />}
            iconStyle={{ color: "#d9d9d9" }}
            onIconCLick={this.handleClickFilterDate}
          />
          {this.handleNotFound()}
          <Grid container className={classes.containerCards}>
            <InfiniteScroll
              dataLength={this.props.schedulingsContext.schedulingsHash.length}
              next={this.fetchSchedulings}
              hasMore={true}
            >
              <Grid container justifyContent="center" className={classes.list}>
                {this.props.schedulingsContext.schedulingsHash.map(
                  (el, index) => {
                    let isOpen = false;
                    if (el.cardHash === this.state.isOpen) isOpen = true;
                    return (
                      <Grid container key={index} justifyContent="center">
                        {!el.hash ? (
                          <Grid
                            container
                            alignItems="center"
                            className={classes.date}
                          >
                            <Typography align="left">{el}</Typography>
                          </Grid>
                        ) : (
                          <Grid item xs={11}>
                            <SchedulingReport
                              data={el}
                              handleToggleVerticalEdit={
                                this.handleToggleVerticalEdit
                              }
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              context={this.props.schedulingsContext}
                              displaySettings={isOpen}
                              handleSearch={this.handleSearch}
                              search={this.state.search}
                            />
                          </Grid>
                        )}
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </InfiniteScroll>
          </Grid>
        </div>
        <Footer
          type="schedulingReport"
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          moves={this.getscheduleCount()}
        />
      </>
    );
  }
}

/**
 * Componente que renderiza o componente da tela de agendamento e provê seus respectivos contextos.
 */
class SchedulingsProvider extends React.Component {
  constructor() {
    super();
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <SchedulingsContext.Consumer>
          {(schedulings) => (
            <MessageDialogContext.Consumer>
              {(dialog) => (
                <Schedulings
                  classes={classes}
                  schedulingsContext={schedulings}
                  dialogContext={dialog}
                />
              )}
            </MessageDialogContext.Consumer>
          )}
        </SchedulingsContext.Consumer>
      </>
    );
  }
}

export default withStyles(styles)(SchedulingsProvider);
