import React from "react";
import SignaturePad from "./SignatureCanvas";
import { withStyles, Grid, Typography } from "@material-ui/core";
import RoundButton from "../Buttons/RoundButton";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = (theme) => ({
  container: {
    width: "100%",
    height: "100%",
    // paddingTop:'10px',
    padding: "10px 10px",
  },
  sigContainer: {
    width: "100%",
    height: "70vh",
    // margin: '20px 0',
    backgroundColor: "#fff",
    border: "1px solid black",
    position: "relative",
    // top:'-5px  '
  },

  sigPad: {
    width: "100%",
    height: "100%",
  },

  buttons: {
    width: "100%",
    height: "30px",
  },
  hidden: {
    display: "none",
  },
  title: {
    paddingBottom: "20px",
    margin: "auto",
    textDecoration: "underline #000",
    fontFamily: "Roboto, sans-serif",
  },
});

/**
 * Componente responsável por 'exibir' um modal de aonde será feita a assinatura do cliente e transformada em uma imagemBASE64
 */
class Signature extends React.Component {
  /**
   * @param {function} props.getTrimmedDataURL Função que serará a imagem em base64 no componente pai
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {function} props.handleToggleSignature Função que alternará a exibição deste componente
   * @property {ref} this.sigPad Referência para o componente filho de assinatura
   */
  constructor(props) {
    super(props);

    this.state = {
      trimmedDataURL: null,
      isLandscape: window.screen.orientation.angle != 0,
    };

    this.unlockScreenRotation = this.unlockScreenRotation.bind(this);
    this.onScreenOrientationChange = this.onScreenOrientationChange.bind(this);
    this.subscribeOrientationEventHandler =
      this.subscribeOrientationEventHandler.bind(this);
    this.lockScreenRotation = this.lockScreenRotation.bind(this);
    this.fullScreenCheck = this.fullScreenCheck.bind(this);
    this.rotateScreen = this.rotateScreen.bind(this);
    this.clear = this.clear.bind(this);
    this.trim = this.trim.bind(this);
    this.toggleSignature = this.toggleSignature.bind(this);
  }

  sigPad = {};

  async componentDidMount() {
    await this.rotateScreen();
    this.subscribeOrientationEventHandler();
  }

  componentWillUnmount() {
    this.unlockScreenRotation();
  }

  async unlockScreenRotation() {
    document.documentElement[this.state.exitFullScreen] &&
      (await document.documentElement[this.state.exitFullScreen]());

    if (window.screen.orientation.unlock) {
      window.screen.orientation.unlock();
    } else {
      window.screen.orientationUnlock();
    }

    return true;
  }

  onScreenOrientationChange(event) {
    this.setState({ isLandscape: event.target.screen.orientation.angle != 0 });
  }

  subscribeOrientationEventHandler() {
    window.onorientationchange = this.onScreenOrientationChange;
  }

  async lockScreenRotation() {
    let promise = null;
    if (window.screen.orientation.lock) {
      promise = window.screen.orientation.lock("landscape");
    } else {
      promise = window.screen.orientationLock("landscape");
    }

    let result;
    try {
      result = await promise;
      this.setState({ isLandscape: true });
    } catch (error) {
      alert(error);
      this.setState({ isLandscape: false });
    }
    return result;
  }

  async fullScreenCheck() {
    // browsers require full screen mode in order to obtain the orientation lock
    let goFullScreen = null;
    if ("requestFullscreen" in document.documentElement) {
      goFullScreen = "requestFullscreen";
    } else if ("mozRequestFullScreen" in document.documentElement) {
      goFullScreen = "mozRequestFullScreen";
    } else if ("webkitRequestFullscreen" in document.documentElement) {
      goFullScreen = "webkitRequestFullscreen";
    } else if ("msRequestFullscreen") {
      goFullScreen = "msRequestFullscreen";
    }

    if (document.fullscreenElement) return;

    try {
      const reponse = await document.documentElement[goFullScreen]();
      return document.documentElement[goFullScreen] && reponse;
    } catch (error) {
      return false;
    }

    // return document.documentElement.requestFullscreen();
  }

  async rotateScreen() {
    try {
      await this.fullScreenCheck();
      await this.lockScreenRotation();
    } catch (e) {
      this.unlockScreenRotation();
    }
  }

  /**Função que limpa a assinatura
   * @function
   */
  clear() {
    this.sigPad.clear();
  }

  /**Função que salva a assinatura
   * @function
   */
  trim() {
    this.props.getTrimmedDataURL(
      this.sigPad.getTrimmedCanvas().toDataURL("image/png")
    );
    this.toggleSignature();
  }

  async toggleSignature() {
    // document.documentElement[this.state.exitFullScreen] && await document.documentElement[this.state.exitFullScreen]();
    this.props.handleToggleSignature();
  }

  render() {
    let { classes } = this.props;

    return (
      <>
        <Grid container alignItems="flex-start" className={classes.container}>
          <Grid item xs={12}>
            <Typography align="center" className={classes.title} variant="h5">
              Assine o quadro abaixo
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            container
            direction={this.state.isLandscape ? "row" : "column"}
            alignItems="center"
          >
            <Grid item xs={this.state.isLandscape ? 2 : 12}>
              <Grid
                container
                justify="space-between"
                direction={this.state.isLandscape ? "column" : "row"}
                wrap={this.state.isLandscape ? "nowrap" : "wrap"}
                style={{ height: "100%" }}
              >
                <Grid item style={{ padding: "5px" }}>
                  <Grid item xs={12} container justify="center">
                    <RoundButton
                      color="#32CD32"
                      colorIcon="#fff"
                      func={this.trim}
                      icon={<CheckIcon />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="center">Aceitar</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ padding: "5px" }}>
                  <Grid item xs={12} container justify="center">
                    <RoundButton
                      color="#4169E1"
                      colorIcon="#fff"
                      func={this.clear}
                      icon={<CloseIcon />}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Typography align="center">Limpar</Typography>
                  </Grid>
                </Grid>
                <Grid item style={{ padding: "5px" }}>
                  <Grid item xs={12} container justify="center">
                    <RoundButton
                      color="#FF0000"
                      colorIcon="#fff"
                      func={this.toggleSignature}
                      icon={<DeleteIcon />}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Typography align="center">Rejeitar</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={10} className={classes.sigContainer}>
              <SignaturePad
                fromDataURL={this.props.fromDataURL}
                canvasProps={{ className: classes.sigPad }}
                ref={(ref) => {
                  this.sigPad = ref;
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withStyles(styles)(Signature);
