import React from "react";
import Header from "../../components/HeaderForAdd/Header";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Typography,
  withStyles,
  Grid,
  InputLabel,
  FormControl,
  Input,
  Switch,
  TextField,
} from "@material-ui/core";
import AddProductsDialog from "./AllItemsDialog";
import ItemForm from "./ItemForm";
import CloseIcon from "@material-ui/icons/Close";
import AmountMask from "../../components/MaskedInputs/AmountMask";
import Moeda from "../../utils/Moeda";
import { ProductContext } from "../../contexts/ProductsContext";
import GreenButton from "../Buttons/GreenButton";
import ListIcon from "@material-ui/icons/List";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from "@material-ui/icons/Edit";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";

const styles = (theme) => ({
  inputs: {
    paddingBottom: "20px",
  },
  body: {
    backgroundColor: "#F5F5F5",
    width: "100%",
    minHeight: "100vh",
  },
  inputContainer: {
    backgroundColor: "#fff",
    padding: "10px 15px 20px",
    position: "relative",
    marginTop: "10px",
  },
  addProductsContainer: {
    backgroundColor: "#fff",
    position: "relative",
    padding: "10px 0px 20px 0px",
  },

  basketItemTable: {
    "& > .MuiGrid-item": {
      border: ".3px solid #afafaf",
      overflow: "hidden",
      flexWrap: "nowrap",
      whiteSpace: "nowrap",
    },
  },

  button: {
    paddingTop: "10px",
  },
  greenButton: {
    paddingBottom: "20px",
    paddingTop: "10px",
  },

  addNewItemButtonIcon: {
    margin: 0,
  },
});
/**
 * Componente único e princípal da tela de cadastro de products.
 */
class ProductsForm extends React.Component {
  /**
   * @param {boolean} props.Add Boleano que define o títilo do Header deste componente, no caso se será para edição ou criação de products
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {boolean} this.state.showItemForm Boleano que terá o valor usado para definir a alternancia com o <ItemForm/>
   * @property {object} this.state.cesta  Estado que armazerará os dados do produto
   * @property {number} this.state.costValue  Estado que armazerará o valor de custo do produto
   */
  constructor(props) {
    super(props);
    this.state = {
      showItemForm: false,
      name: { value: "", error: false },
      id: -1,
      salePrice: { value: Moeda.create(0).mount(), error: false },
      maxDiscount: { value: Moeda.create(0).mount(), error: false },
      ideal: false,
      items: [],
      allItemsWQ: [],
      removedItems: [],
      filteredItems: [],
      basketFilteredProducts: [],
      costValue: Moeda.create(0).mount(),
      word: "",
      showAddItemsDialog: false,
      showItemDetails: false,
      itemDetailsData: null,
      dataHasLoaded: false,
      loadingAllItems: true,

      storedBasketsItems: [],
    };
    this.ref = React.createRef();
    this.saveProduct = this.saveProduct.bind(this);
    this.checkFields = this.checkFields.bind(this);
    this.setInitialValues = this.setInitialValues.bind(this);
    this.calculateCostValue = this.calculateCostValue.bind(this);
    this.handleSearchItems = this.handleSearchItems.bind(this);
    this.onClickAddItemToBasket = this.onClickAddItemToBasket.bind(this);
    this.handleIncreaseItemQnt = this.handleIncreaseItemQnt.bind(this);
    this.handleDecreaseItemQnt = this.handleDecreaseItemQnt.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleSaveItem = this.handleSaveItem.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.handleToggleItemForm = this.handleToggleItemForm.bind(this);
    this.handleToggleShowItemDetails =
      this.handleToggleShowItemDetails.bind(this);
    this.toggleAddItemsDialog = this.toggleAddItemsDialog.bind(this);
    this.renderItemsDetailsDrawer = this.renderItemsDetailsDrawer.bind(this);
  }

  componentDidMount() {
    this.setInitialValues();
  }

  componentDidUpdate(oldProps, oldState) {
    // Update filteredItems property when this.context.storedItems is updated
    if (this.state == oldState) {
      if (this.state.filteredItems !== this.context.storedItems) {
        this.setState({ filteredItems: this.context.storedItems });
      }
    }
    if (this.state.items !== oldState.items) {
      this.calculateCostValue(this.state.items);
    }
  }

  /**Função enviará os dados para o componente pai.
   * @function
   */
  async saveProduct() {
    const formHasErros = this.checkFields();
    if (formHasErros) {
      return;
    }
    const {
      id,
      name,
      salePrice,
      maxDiscount,
      ideal,
      items,
      costValue,
      removedItems,
    } = this.state;
    await this.props.saveProduct({
      id,
      name: name.value,
      salePrice: salePrice.value,
      maxDiscount: maxDiscount.value,
      ideal,
      items,
      costValue,
      removedItems,
    });
  }

  /**Função que irá verificar se os campos estão preenchidos corretamente.
   * @function
   */
  checkFields() {
    let foundErrors = false;
    if (
      this.state.name.value == null ||
      this.state.name.value == undefined ||
      this.state.name.value == ""
    ) {
      this.setState({ name: { ...this.state.name, error: true } });
      foundErrors = true;
    } else {
      this.setState({ name: { ...this.state.name, error: false } });
    }

    if (Moeda.create(this.state.salePrice.value).isZero()) {
      this.setState({ salePrice: { ...this.state.salePrice, error: true } });
      foundErrors = true;
    } else {
      this.setState({ salePrice: { ...this.state.salePrice, error: false } });
    }

    return foundErrors;
  }

  async setInitialValues() {
    try {
      // Get current basket's data
      const product = await this.context.getBasketById(this.props.productId);

      // Get current basket's items
      const productItems = (product.itens || []).filter((item) => {
        return item.ativo;
      });

      // Get all available items
      let storedBasketsItems = await this.context.getAllItems();

      // Make allItemsWQ array while checking each item's quantity stored in the storedBasketsItems array
      let allItemsWQ = storedBasketsItems.map((item) => {
        let itemQnt = (!!productItems ? productItems : []).find(
          (i) => i.product.hash === item.hash
        );
        let itemWQ = {
          quantidade: itemQnt ? itemQnt.quantidade : 0,
          item: { ...item },
        };
        return itemWQ;
      });

      if (!this.props.add) {
        // Set all necessary values in the state
        this.setState({
          id: product.id,
          name: { value: String(product.nome), error: false },
          salePrice: {
            value: Moeda.create(product.valor).mount(),
            error: false,
          },
          maxDiscount: {
            value: Moeda.create(product.maxDesconto).mount(),
            error: false,
          },
          ideal: product.contarIdeal,
          items: productItems,
          word: "",
          dataHasLoaded: true,
          filteredItems: storedBasketsItems,
          allItemsWQ,
          storedBasketsItems,
          loadingAllItems: false,
        });
        this.calculateCostValue(product.itens);
      } else {
        this.setState({
          dataHasLoaded: true,
          filteredItems: storedBasketsItems,
          allItemsWQ,
          loadingAllItems: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**Função que calcula o custo total do produto a ser criado..
   * @function
   * @param {Array} products Lista de itens adicionados aquele produto
   */
  calculateCostValue(products) {
    let costValue = Moeda.create(0).mount();
    products.forEach((item) => {
      let multiplication = Moeda.create(item.product.valor)
        .mul(item.quantidade)
        .mount();
      costValue = Moeda.create(costValue).add(multiplication).mount();
    });
    this.setState({ costValue: costValue });
  }

  handleSearchItems(searchWord) {
    let searchAllProducts = this.context.storedItems;
    this.setState({ word: searchWord });
    //  this.filterAllProducts(this.state.items);

    if (
      this.state.word == null ||
      this.state.word == undefined ||
      this.state.word == ""
    ) {
    }
    // this.setState({ filteredItems: this.context.storedItems });
    else {
      searchAllProducts = searchAllProducts.filter((a) =>
        a.identificador.toLowerCase().includes(this.state.word.toLowerCase())
      );
    }
    this.setState({ filteredItems: searchAllProducts });
  }

  onClickAddItemToBasket(el) {
    if (el.product) {
      this.handleIncreaseItemQnt(el);
      return;
    }

    // Check if there is already an instance of this item in the state.items array
    let temp_index = null;
    let temp = this.state.items.find((item, index) => {
      if (item.product.hash == el.item.hash) {
        temp_index = index;
        return true;
      }
      return false;
    });

    // Update state.allItemsWQ array
    let new_allItemsWQ = this.state.allItemsWQ.map((item) => {
      if (item.item.hash == el.item.hash) {
        item.quantidade++;
      }
      return item;
    });

    // Update state.items array
    let items = [...this.state.items];

    if (temp) {
      items[temp_index].quantidade++;
      // this.setState({items: items, allItemsWQ: new_allItemsWQ})
    } else {
      let newItem = {
        created: true,
        quantidade: 1,
        // ProductId: el.product.id,
        CestumId: this.props.productId,
        product: el.item,
      };
      items.unshift(newItem);
    }

    this.setState(
      { items: items, allItemsWQ: new_allItemsWQ },
      this.calculateCostValue(this.state.items)
    );
  }

  handleIncreaseItemQnt(itemData) {
    // Find index of itemData in state.items array
    let temp_index = null;
    let temp = this.state.items.find((item, index) => {
      if (item.product.hash == itemData.product.hash) {
        temp_index = index;
        return true;
      }
      return false;
    });

    // Update state.allItemsWQ array
    let new_allItemsWQ = this.state.allItemsWQ.map((item) => {
      if (item.item.hash == itemData.product.hash) {
        item.quantidade++;
      }
      return item;
    });

    // Update state.items array
    let items = [...this.state.items];
    if (temp) {
      items[temp_index].quantidade++;
      items[temp_index].edited = true;
      // this.setState({items: items, })
    }

    this.setState(
      {
        items: items,
        itemDetailsData: { ...this.state.items[temp_index] },
        allItemsWQ: new_allItemsWQ,
      },
      this.calculateCostValue(this.state.items)
    );
  }

  handleDecreaseItemQnt(itemData) {
    // Find index of itemData in state.items array
    let temp_index = null;
    let temp = this.state.items.find((item, index) => {
      if (
        item.product.hash ==
        ((itemData.product && itemData.product.hash) || itemData.item.hash)
      ) {
        temp_index = index;
        return true;
      }
      return false;
    });

    // Update state.items array
    let items = [...this.state.items];
    if (temp) {
      if (items[temp_index].quantidade > 1) {
        items[temp_index].quantidade--;
        items[temp_index].edited = true;
      } else {
        items.splice(temp_index, 1);
      }
    }

    // Update state.allItemsWQ array
    let new_allItemsWQ = this.state.allItemsWQ.map((item) => {
      if (
        item.item.hash ==
        ((itemData.product && itemData.product.hash) || itemData.item.hash)
      ) {
        item.quantidade--;
      }
      return item;
    });

    this.setState(
      {
        items: items,
        itemDetailsData:
          this.state.itemDetailsData != null
            ? { ...this.state.items[temp_index] }
            : null,
        allItemsWQ: new_allItemsWQ,
      },
      this.calculateCostValue(this.state.items)
    );
  }

  handleRemoveItem() {
    let items = [...this.state.items];

    // Find item on state.items array
    let itemIndex = items.findIndex(
      (item) => item.product.hash == this.state.itemDetailsData.product.hash
    );

    // Push item into state.removedItems array
    let removedItems = [...this.state.removedItems];
    removedItems.push(this.state.items[itemIndex]);

    // Update state.allItemsWQ array
    let new_allItemsWQ = this.state.allItemsWQ.map((item) => {
      if (item.item.hash == this.state.itemDetailsData.product.hash) {
        item.quantidade = 0;
      }
      return item;
    });

    // Delete item from state.items array
    if (itemIndex != -1) {
      items.splice(itemIndex, 1);
      this.setState(
        {
          items: items,
          itemDetailsData: null,
          removedItems: removedItems,
          allItemsWQ: new_allItemsWQ,
        },
        this.calculateCostValue(this.state.items)
      );
    }

    return;
  }

  handleEditItem() {
    let items = [...this.state.items];
    // Find item on this basket's items
    let itemIndex = items.findIndex(
      (item) => item.product.hash == this.state.itemDetailsData.product.hash
    );
    if (itemIndex != -1) {
      this.handleToggleItemForm();
    }
  }

  async handleSaveItem(itemDetails) {
    let savedItem = await this.context.saveItem(itemDetails);
    const cloneItems = [...this.state.items];

    cloneItems.unshift({
      quantidade: 1,
      created: true,
      product: savedItem,
      CestumId: undefined,
      ProdutoId: savedItem.id,
    });

    const addedNewProducts = [...this.state.allItemsWQ];

    addedNewProducts.unshift({
      item: savedItem,
      quantidade: 1,
    });

    this.setState({
      items: cloneItems,
      allItemsWQ: addedNewProducts,
    });

    this.handleToggleItemForm();
  }

  async handleDeleteItem(databaseId) {
    await this.context.deleteItem(databaseId);
    // await this.setInitialValues()
    this.handleToggleItemForm();
  }

  handleToggleItemForm() {
    // User is closing item form
    if (this.state.showItemForm) {
      this.setState({ showItemForm: false, itemDetailsData: null });
    }
    // User is opening item form
    else {
      this.setState({ showItemForm: true, showAddItemsDialog: false });
    }
  }

  handleToggleShowItemDetails(index, event) {
    // Opening itemDetailsDrawer
    if (typeof index == "number") {
      this.setState({ itemDetailsData: { ...this.state.items[index] } });
    }
    // Closing itemDetailsDrawer
    else {
      this.setState({ itemDetailsData: null });
    }
  }

  toggleAddItemsDialog() {
    let hiding = this.state.showAddItemsDialog;

    this.setState({ showAddItemsDialog: !hiding });

    // If addItemsDialog is being opened, reset filteredItems state
    if (!hiding) this.setState({ filteredItems: this.context.storedItems });
  }

  renderItemsDetailsDrawer() {
    return (
      <Drawer
        anchor="bottom"
        // open={this.state.showItemDetails}
        open={this.state.itemDetailsData != null}
        onClose={this.handleToggleShowItemDetails}
      >
        <Grid
          container
          alignItems="center"
          direction="column"
          style={{ padding: "20px 15px" }}
        >
          <div
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={this.handleToggleShowItemDetails}
          >
            <CloseIcon />
          </div>
          <Grid item xs={12}>
            <AddShoppingCartIcon
              style={{ fontSize: "5rem", color: "#c6c6c6" }}
            ></AddShoppingCartIcon>
          </Grid>

          <Grid item xs={12}>
            <Typography
              style={{ color: "#555", fontSize: "1.2rem", textAlign: "center" }}
            >
              {this.state.itemDetailsData.product &&
                this.state.itemDetailsData.product.identificador}
            </Typography>
            <Typography
              style={{ color: "#888", fontSize: ".9rem", textAlign: "center" }}
            >
              {this.state.itemDetailsData.product &&
                Moeda.create(this.state.itemDetailsData.product.valor).format()}
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ padding: "15px 0 0" }}>
            <Typography>
              Quantidade:
              <Button
                onClick={() =>
                  this.handleDecreaseItemQnt(this.state.itemDetailsData)
                }
                disabled={
                  this.state.itemDetailsData.quantidade > 1 ? false : true
                }
              >
                <RemoveIcon />
              </Button>
              {this.state.itemDetailsData.quantidade}
              <Button
                onClick={() =>
                  this.handleIncreaseItemQnt(this.state.itemDetailsData)
                }
              >
                <AddIcon />
              </Button>
            </Typography>
          </Grid>

          <Grid
            item
            container
            direction="row"
            xs={12}
            justify="space-evenly"
            alignItems="center"
            style={{ padding: "20px 0" }}
          >
            <Grid item>
              <Button
                startIcon={<RemoveShoppingCartIcon />}
                size="small"
                color="default"
                style={{ color: "#f44336" }}
                onClick={this.handleRemoveItem}
              >
                Remover item
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<EditIcon />}
                size="small"
                color="default"
                style={{ color: "#00acc1" }}
                onClick={this.handleEditItem}
              >
                Editar item
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    );
  }

  render() {
    let { classes } = this.props;

    return (
      <div className={classes.body}>
        {!this.state.showItemForm ? (
          <>
            <Header
              func={this.props.onClose}
              link={"/products"}
              icon={<CloseIcon />}
            >
              {this.props.add ? "Criando novo produto" : "Editando produto"}
            </Header>
            {!this.state.dataHasLoaded ? (
              <CircularProgress
                style={{ position: "absolute", left: "50%", top: "50%" }}
              />
            ) : (
              <>
                {/* FIRST SECTION */}
                <Grid
                  container
                  justify="center"
                  className={classes.inputContainer}
                >
                  <Grid item xs={10}>
                    <Grid item xs className={classes.inputs}>
                      <TextField
                        fullWidth
                        label="Nome do produto"
                        value={this.state.name.value}
                        error={this.state.name.error}
                        helperText={
                          this.state.name.error
                            ? "Preencha o campo de nome!"
                            : null
                        }
                        required
                        onChange={(e) =>
                          this.setState({
                            name: { ...this.state.name, value: e.target.value },
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs className={classes.inputs}>
                      <TextField
                        fullWidth
                        label="Preço de venda"
                        error={this.state.salePrice.error}
                        placeholder="0,00"
                        value={this.state.salePrice.value}
                        helperText={
                          this.state.salePrice.error
                            ? "Defina o preço de venda"
                            : null
                        }
                        onChange={(e, e2) =>
                          this.setState({
                            salePrice: { ...this.state.salePrice, value: e2 },
                          })
                        }
                        required
                        InputProps={{
                          startAdornment: <AttachMoneyIcon color="action" />,
                          inputComponent: AmountMask,
                        }}
                      />
                    </Grid>
                    <Grid item xs className={classes.inputs}>
                      <TextField
                        fullWidth
                        label="Valor máximo de desconto"
                        error={this.state.maxDiscount.error}
                        value={this.state.maxDiscount.value}
                        onChange={(e, e2) =>
                          this.setState({
                            maxDiscount: {
                              ...this.state.maxDiscount,
                              value: e2,
                            },
                          })
                        }
                        required
                        InputProps={{
                          startAdornment: <MoneyOffIcon color="action" />,
                          inputComponent: AmountMask,
                        }}
                      />
                    </Grid>

                    <Grid container alignItems="center" justify="space-between">
                      <Typography>Produto conta no ideal</Typography>
                      <Switch
                        checked={this.state.ideal}
                        onChange={(e) =>
                          this.setState({ ideal: !this.state.ideal })
                        }
                        value="checkedA"
                        color="primary"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* END OF FIRST SECTION */}

                {/* SECOND SECTION */}
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  className={classes.inputContainer}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item>
                      <Typography
                        component="span"
                        variant="h6"
                        style={{ textTransform: "uppercase" }}
                        color="textSecondary"
                      >
                        <ListIcon style={{ verticalAlign: "sub" }} />{" "}
                        &nbsp;Itens do produto
                      </Typography>
                      <Typography
                        color="textSecondary"
                        style={{ paddingLeft: "8px" }}
                      >
                        &#9679; {this.state.items.length} itens
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        classes={{ startIcon: classes.addNewItemButtonIcon }}
                        startIcon={<AddIcon></AddIcon>}
                        style={{ fontSize: ".75rem" }}
                        onClick={this.handleToggleItemForm}
                      >
                        Criar item
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} style={{ paddingTop: "20px" }}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disableElevation
                        startIcon={<AddShoppingCartIcon />}
                        style={{ textAlign: "left" }}
                        onClick={this.toggleAddItemsDialog}
                      >
                        Incluir itens ao seu produto
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: "20px",
                        maxHeight: "450px",
                        overflowY: "auto",
                      }}
                    >
                      {this.state.items.length ? (
                        <Grid
                          container
                          alignItems="stretch"
                          className={classes.basketItemTable}
                        >
                          {this.state.items.map((item, i) => (
                            <Grid
                              onClick={() =>
                                this.handleToggleShowItemDetails(i)
                              }
                              key={i}
                              item
                              xs={3}
                              container
                              justify="flex-start"
                              alignItems="center"
                              direction="column"
                              style={{
                                padding: "10px 5px",
                                position: "relative",
                              }}
                            >
                              <AddShoppingCartIcon
                                fontSize="default"
                                style={{ color: "#c6c6c6" }}
                              />
                              <Typography
                                style={{
                                  color: "#555",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                }}
                              >
                                {item.product.identificador}
                              </Typography>

                              {item.quantidade > 1 && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 8,
                                    right: 5,
                                    display: "flex",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    component="span"
                                    style={{
                                      lineHeight: 0.5,
                                      fontSize: "0.9rem",
                                      color: "#8bb5f5",
                                      fontWeight: 700,
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {item.quantidade}x
                                  </Typography>
                                </div>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Grid
                          item
                          container
                          direction="column"
                          alignItems="center"
                        >
                          <ShoppingCartOutlinedIcon
                            fontSize="large"
                            style={{ color: "#c6c6c6", fontSize: "5.5rem" }}
                          />
                          <Typography
                            variant="body1"
                            style={{ fontSize: "1.2rem", textAlign: "center" }}
                          >
                            Este produto não tem nenhum item
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{ fontSize: "0.9rem", textAlign: "center" }}
                          >
                            Para incluir itens nesse produto, clique no botão
                            acima
                          </Typography>
                        </Grid>
                      )}

                      {this.state.itemDetailsData != null &&
                        this.renderItemsDetailsDrawer()}
                    </Grid>
                  </Grid>
                </Grid>
                {/* END OF SECOND SECTION */}

                <Grid
                  container
                  justify="center"
                  className={classes.greenButton}
                >
                  <Grid item xs={11}>
                    <GreenButton onClickButton={this.saveProduct}>
                      <Typography variant="h6" component="span">
                        {this.props.Add ? "Criar Produto" : "Salvar Produto"}
                      </Typography>
                    </GreenButton>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        ) : (
          <ItemForm
            clickClose={this.handleToggleItemForm}
            onSaveItem={this.handleSaveItem}
            onDeleteItem={this.handleDeleteItem}
            itemData={
              this.state.itemDetailsData
                ? this.state.itemDetailsData.product
                : null
            }
          />
        )}

        <AddProductsDialog
          allItems={this.state.allItemsWQ}
          dialogTitle="Todos os itens"
          loading={this.state.loadingAllItems}
          createNewItemBtnText="novo item"
          onAddItemclick={this.onClickAddItemToBasket}
          onRemoveItemClick={this.handleDecreaseItemQnt}
          onCreateNewItemClick={this.handleToggleItemForm}
          // onSearch={(e)=>console.log(e)}
          onClose={(e) => {
            this.setState({ showAddItemsDialog: false });
          }}
          open={this.state.showAddItemsDialog}
        />
        {/* {this.renderAddItemsDialog()} */}
      </div>
    );
  }
}

ProductsForm.contextType = ProductContext;
export default withStyles(styles)(ProductsForm);
