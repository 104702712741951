/* eslint-disable no-useless-constructor */
/* eslint-disable eqeqeq */
import React from "react";
import { withStyles, Grid, Backdrop } from "@material-ui/core";
import HelperList from "../../components/support/HelperList";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import { fade } from "@material-ui/core/styles";
import Cache from "../../components/support/Cache";

import { MessageDialogContext } from "../../contexts/MessageDialogContext";

import Header from "../../components/HeaderForAdd/Header";

const styles = (theme) => ({
  body: {
    backgroundColor: "#F5F5F5",
    minHeight: "100vh",
    position: "absolute",
  },

  headerRoot: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.primary.dark, 0.25),
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.dark, 0.45),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    "& p": {
      color: theme.palette.text.secondary,
      fontSize: "1.8rem",
    },
  },
});

/**
 * Tela de Suporte.
 */
class Support extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      pages: [],
      searchBar: "",
      tree: [],
      error: false,
    };
    this.fetchOptions = {
      // url: 'https://cesta-central-maximus.herokuapp.com/faq/all',
      url: "https://cesta-central-maximus.herokuapp.com/empresa/faqs?token=DF74748A72FEE776A37BD566BB3CB",
      method: "GET",
    };
    this.onSearchBarChange = this.onSearchBarChange.bind(this);
    this.getItemProps = this.getItemProps.bind(this);
    this.testFunc = this.testFunc.bind(this);
    this.handleErrorCache = this.handleErrorCache.bind(this);
    this.cachePagesInState = this.cachePagesInState.bind(this);
  }

  cachePagesInState(pages) {
    const treeTorender = this.buildTreeStructure(pages);
    this.setState({
      isLoading: false,
      tree: treeTorender || [],
      pages: pages || [],
      searchBar: "",
    });
  }
  /**
   *
   * @param {Array} pagesInfo
   */
  buildTreeStructure(pagesInfo) {
    if (!Array.isArray(pagesInfo) || !pagesInfo.length) {
      return [];
    }

    const groups = [
      ...pagesInfo.reduce((memo, info) => {
        memo.add(info.group);
        return memo;
      }, new Set()),
    ];

    const tree = groups.map((group) => {
      const groupTree = {
        key: group,
        label: group,
      };

      const subGroups = [
        ...pagesInfo
          .filter((info) => info.group === group)
          .reduce((memo, info) => {
            memo.add(info.subGroup);
            return memo;
          }, new Set()),
      ];

      groupTree.subMenus = subGroups.map((subGroup) => {
        const subGroupTree = {
          key: subGroup,
          label: subGroup,
        };

        subGroupTree.subMenus = pagesInfo
          .filter((info) => info.group === group && info.subGroup === subGroup)
          .map((info) => ({
            key: info.id,
            label: info.label,
          }));
        return subGroupTree;
      });
      return groupTree;
    });
    return tree;
  }

  onSearchBarChange(event) {
    this.setState({ searchBar: event.target.value });
  }

  filterBySearchField() {
    /** @todo Realizar a filtragem dos dados utilizando a estrutura de dados da avore patricia*/
    return this.state.tree;
  }

  getItemProps(itemInfo, defaultOptions) {
    const item = this.state.pages.find((page) => page.id == itemInfo.key);
    if (!item) return defaultOptions;

    const defaultOnClick = defaultOptions.onClick;
    defaultOptions.onClick = () => {
      defaultOnClick();
      this.props.history.push(`/support${item.pageUrl}`);
    };
    return defaultOptions;
  }

  testFunc(data, origin) {
    this.setState({ isLoading: false });
    this.cachePagesInState(data.data.faqs);
  }

  handleErrorCache(error) {
    this.setState({
      error: true,
      errorMessage: error.message,
      isLoading: false,
    });
    this.context.addDialog({
      title: "Houve um problema",
      message: error.message,
      type: "warning",
    });
  }

  render() {
    const itemsToRender = this.filterBySearchField();
    let { classes } = this.props;
    return (
      <>
        <Grid
          container
          justify="flex-start"
          alignItems="strech"
          direction="column"
          style={{ height: "100%" }}
        >
          <Grid item>
            <Header
              icon={
                <CloseSharpIcon style={{ fontSize: 35, display: "inline" }} />
              }
            >
              Suporte
            </Header>
          </Grid>

          <Grid item>
            <HelperList
              getItemProps={this.getItemProps}
              isLoading={this.state.isLoading}
              items={itemsToRender}
            />
          </Grid>

          <Cache
            fetchOptions={this.fetchOptions}
            handleSuccess={this.testFunc}
            handleError={this.handleErrorCache}
            name="support-pages"
            timeToLive={4}
          />
        </Grid>
        <Backdrop className={classes.backdrop} open={this.state.isLoading}>
          <CircularProgress />
        </Backdrop>
      </>
    );
  }
}

Support.contextType = MessageDialogContext;
export default withStyles(styles)(Support);
