import React from "react";
import {
  withStyles,
  Divider,
  Typography,
  Grid,
  TextField,
} from "@material-ui/core";
import Moeda from "../../utils/Moeda";
import AmountMask from "../../components/MaskedInputs/AmountMask";

const styles = (theme) => ({
  inputValueClass: {
    padding: "10px 0px 5px 0px",
    lineHeight: 1,
    color: "#696969",
    fontSize: "3rem",
    fontFamily: "Roboto,sans-serif",
    "& .currency": {
      fontSize: "1.3rem",
      fontWeight: 300,
    },
  },
  totalValueInput: {
    fontSize: "2.6rem",
    color: "#696969",
    fontWeight: "300",
    textAlign: "center",
    padding: 0,
  },
  saleTotalValue: {
    fontSize: "1.4rem",
    fontWeight: "300",
    textTransform: "uppercase",
    textAlign: "center",
  },
  clientBalance: {
    color: "#868686",
    fontSize: "1.2rem",
    fontFamily: "Roboto,sans-serif",
    fontWeight: 300,
  },
});

/**
 * Componente que renderiza um card que mostra o saldo atual do cliente um outro valor passado'
 */
class ClientMoneyBalance extends React.Component {
  /**
   * @param {object} props.inputValue Valor principal exibido no componente, normalmente sendo o valor de entrada de alguma movimentação.
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {object} props.clientBalance Saldo atual do cliente
   */
  constructor() {
    super();
    this.state = {};
    this.setCursorPosition = this.setCursorPosition.bind(this);
  }

  setCursorPosition() {
    const { classes } = this.props;

    let input = document.querySelector(`.${classes.totalValueInput}`);
    if (!!input && input instanceof HTMLInputElement) {
      let pos = input.value.length;
      input.setSelectionRange(pos, pos);
    }
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Grid container justify="center" className={classes.inputValueClass}>
          <Grid
            item
            xs={7}
            container
            direction="row"
            wrap="nowrap"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <span className="currency">R$&nbsp;</span>
            </Grid>
            <Grid
              item
              style={{ borderBottom: "1px solid #d3d3d3", marginBottom: "8px" }}
            >
              <TextField
                fullWidth={true}
                onChange={(event, value) =>
                  this.props.setData("movementTotal", value)
                }
                error={this.props.totalValueError}
                value={this.props.totalValue}
                inputRef={this.props.totalValueRef}
                disabled={!this.props.canInsertValue}
                onSelect={() => {
                  this.setCursorPosition();
                }}
                required={true}
                InputProps={{
                  classes: {
                    input: classes.totalValueInput,
                  },
                  inputComponent: AmountMask,
                }}
                variant="standard"
                placeholder={"0,00"}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.saleTotalValue} variant="h1">
              Valor total
            </Typography>
          </Grid>
          <Divider
            style={{
              width: "90%",
              backgroundColor: "#D3D3D3",
              margin: "15px 0 10px",
            }}
          />
          <Typography align="center" className={classes.clientBalance}>
            <span>Saldo do cliente:</span>
            {Moeda.create(this.props.clientCurrentBalance)
              .format()
              .replace("R$", "")}
          </Typography>
        </Grid>
      </>
    );
  }
}
export default withStyles(styles)(ClientMoneyBalance);
