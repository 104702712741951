import React from "react";
import HeaderMenu from "../../components/HeaderForAdd/HeaderMenu";
import { withStyles } from "@material-ui/core";
import FuelForm from "../../components/Forms/FuelForm";
import VehicleForm from "../../components/Forms/VehicleForm";
import {
  GasComponent,
  MaintenanceComponent,
  OtherExpensesComponent,
  VoucherComponent,
} from "../../components/Expenses";
import clsx from "clsx";
import { ExpensesContext } from "../../contexts/ExpensesContext";
import { FuelContext } from "../../contexts/FuelContext";
import { VehicleContext } from "../../contexts/VehicleContext";
import { UserContext } from "../../contexts/UserContext";
import BackdropLoader from "../../components/Dialogs/BackdropLoader";

const fuelExpense = "fuelExpense";
const maintenance = "maintenanceExpense";
const otherExpenses = "otherExpenses";
const voucherExpenses = "voucherExpenses";

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#F5F5F5",
    height: "100%",
  },
  expandPanels: {
    position: "relative",
    top: "15px",
  },
  expansionPanel: {
    "& .MuiFormControl-root": {
      paddingBottom: 15,
    },
  },
  hidden: {
    display: "none",
  },
});

/**
 * Tela de despesas.
 */
class Expenses extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {boolean} props.headerIsHidden Boleano que, caso true, o componente não irá renderizar o header
   * @property {object} this.state.expandedPanel Estado que armazena uma referência de qual Panel está aberto.
   * @property {boolean} this.state.fuelFormIsOpen Boleano que indica a exibição do componente de criação de combustivel.
   * @property {boolean} this.state.vehicleFormIsOpen  Boleano que indica a exibição do componente de criação de veiculo.
   */
  constructor() {
    super();
    this.state = {
      expandedPanel: null,
      fuelFormIsOpen: false,
      vehicleFormIsOpen: false,

      newVehicleData: {},
      newFuelData: {},
      setFuelFunction: null,
      setVehicleFunction: null,

      isLoading: false,
    };
    this.vehicleContext = React.createRef();
    this.saveExpense = this.saveExpense.bind(this);
    this.handleToggleVehicleForm = this.handleToggleVehicleForm.bind(this);
    this.handleToggleFuelForm = this.handleToggleFuelForm.bind(this);
    this.deleteExpense = this.deleteExpense.bind(this);
    this.filterExpenses = this.filterExpenses.bind(this);
    this.addExpense = this.addExpense.bind(this);
    this.setLoaderState = this.setLoaderState.bind(this);
  }

  componentDidMount() {
    this.context.callObservable();
  }
  componentWillUnmount() {
    try {
      // this.vehicleContext.current
      //   .clearCache()
      //   .then(console.log)
      //   .cactch(console.error);
    } catch (error) {
      console.error();
    }
  }

  /**Função irá alternar a abertura de cada ExpansionPanel.
   * @function
   * @param {string} reference Referencia que identifica unicamente cada painel
   * @param {boolean} expanded Boleano que indica se tal componente está préviamente aberto ou fechado
   */
  toggleOpen(reference) {
    return (isExpanded) => {
      if (isExpanded) {
        this.setState({ expandedPanel: null });
      } else {
        this.setState({ expandedPanel: reference });
      }
    };
  }

  /**Função irá salvar os dados no banco.
   * @function
   * @param {string} modelName Nome que referencia a model no contexto.
   * @param {object} data Dado a ser adicionado no banco.
   */
  async saveExpense(modelName, data) {
    try {
      delete data.isSaved;
      this.setLoaderState(true);
      if (data.DATABASE_ID) {
        await this.context.update(modelName, data);
      } else {
        await this.context.create(modelName, data);
      }
      this.setLoaderState(false);
      return "sucess";
    } catch (error) {
      console.error(error);
      this.setLoaderState(false);
    }
  }

  /**Função altera a exibição do componente de criação de veículo.
   * @function
   */
  handleToggleVehicleForm() {
    this.setState({ vehicleFormIsOpen: !this.state.vehicleFormIsOpen });
  }

  /**Função altera a exibição do componente de criação de combustivel.
   * @function
   */
  handleToggleFuelForm() {
    this.setState({ fuelFormIsOpen: !this.state.fuelFormIsOpen });
  }

  /**Função seta o ativo como false no banco.
   * @function
   * @param {string} modelName Nome que referencia a model no contexto.
   * @param {object} data Keypaths.
   */
  async deleteExpense(modelName, data) {
    await this.context.delete(modelName, data);
  }

  /**Função seta o ativo como false no banco.
   * @function
   * @param {string} modelName Nome que referencia a model no contexto.
   */
  filterExpenses(modelName) {
    let newList = this.context;
    newList[modelName].value = this.context[modelName].value.filter(
      (a) => a.ativo !== false
    );
    return newList;
  }

  async addExpense() {
    return await this.context.isRouteOpen();
  }

  setLoaderState(loaderState) {
    this.setState({ isLoading: loaderState });
  }

  render() {
    let { classes } = this.props;
    let classRoot = clsx({
      [classes.root]: !this.props.headerIsHidden,
      [classes.hidden]:
        this.state.fuelFormIsOpen || this.state.vehicleFormIsOpen,
    });

    return (
      <UserContext.Consumer>
        {(UserContext) => (
          <VehicleContext.Consumer>
            {(vehicle) => {
              this.vehicleContext.current = vehicle;
              return (
                <FuelContext.Consumer>
                  {(fuel) => (
                    <>
                      <div className={classRoot}>
                        {!this.props.headerIsHidden && (
                          <HeaderMenu>Despesas</HeaderMenu>
                        )}
                        <BackdropLoader
                          open={this.state.isLoading}
                          secondaryText="Um momento, estamos salvando seus dados..."
                        />
                        <GasComponent
                          fuelContext={fuel}
                          vehicleContext={vehicle}
                          isBitboxEmployee={
                            this.context.configKeys.isBitboxEmployee
                          }
                          expenseContext={this.filterExpenses(fuelExpense)}
                          handleChange={this.toggleOpen("GasComponent")}
                          expanded={this.state.expandedPanel == "GasComponent"}
                          onAddExpense={this.addExpense}
                          onSaveExpense={this.saveExpense}
                          onDeleteExpense={this.deleteExpense}
                          modelName={fuelExpense}
                          attatchSetNewFuelEvent={(func) => {
                            this.handleToggleFuelForm();
                            this.setState({ setFuelFunction: func });
                          }}
                          attatchSetNewVehicleEvent={(func) => {
                            this.handleToggleVehicleForm();
                            this.setState({ setVehicleFunction: func });
                          }}
                        />
                        <MaintenanceComponent
                          handleChange={this.toggleOpen("MaintenanceComponent")}
                          vehicleContext={vehicle}
                          expenseContext={this.filterExpenses(maintenance)}
                          isBitboxEmployee={
                            this.context.configKeys.isBitboxEmployee
                          }
                          expanded={
                            this.state.expandedPanel == "MaintenanceComponent"
                          }
                          onAddExpense={this.addExpense}
                          onSaveExpense={this.saveExpense}
                          onDeleteExpense={this.deleteExpense}
                          modelName={maintenance}
                          attatchSetNewVehicleEvent={(func) => {
                            this.handleToggleVehicleForm();
                            this.setState({ setVehicleFunction: func });
                          }}
                        />
                        <OtherExpensesComponent
                          handleChange={this.toggleOpen(
                            "OtherExpensesComponent"
                          )}
                          expanded={
                            this.state.expandedPanel == "OtherExpensesComponent"
                          }
                          onSaveExpense={this.saveExpense}
                          onAddExpense={this.addExpense}
                          expenseContext={this.filterExpenses(otherExpenses)}
                          onDeleteExpense={this.deleteExpense}
                          modelName={otherExpenses}
                        />
                        <VoucherComponent
                          handleChange={this.toggleOpen("VoucherComponent")}
                          expanded={
                            this.state.expandedPanel == "VoucherComponent"
                          }
                          acceptsAdvanceOutStaff={
                            this.context.configKeys.acceptsAdvanceOutStaff
                          }
                          onSaveExpense={this.saveExpense}
                          onAddExpense={this.addExpense}
                          onDeleteExpense={this.deleteExpense}
                          expenseContext={this.filterExpenses(voucherExpenses)}
                          userContext={UserContext}
                          modelName={voucherExpenses}
                        />
                      </div>
                      <FuelForm
                        open={this.state.fuelFormIsOpen}
                        handleClose={() =>
                          this.setState({
                            fuelFormIsOpen: !this.state.fuelFormIsOpen,
                          })
                        }
                        handleToggleFuelForm={this.handleToggleFuelForm}
                        fuelContext={fuel}
                        setNewFuel={this.state.setFuelFunction}
                      />
                      <VehicleForm
                        open={this.state.vehicleFormIsOpen}
                        handleClose={() =>
                          this.setState({
                            vehicleFormIsOpen: !this.state.vehicleFormIsOpen,
                          })
                        }
                        handleToggleVehicleForm={this.handleToggleVehicleForm}
                        vehicleContext={vehicle}
                        setNewVehicle={this.state.setVehicleFunction}
                      />
                    </>
                  )}
                </FuelContext.Consumer>
              );
            }}
          </VehicleContext.Consumer>
        )}
      </UserContext.Consumer>
    );
  }
}

Expenses.contextType = ExpensesContext;
export default withStyles(styles)(Expenses);
