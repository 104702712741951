import SyncNotificationCenter from "../../service/notifications/syncNotificationCenter";
import { WorkerDownloadClients } from "../workerDownloadClients";

function getUser() {
  return window.LocalStorage.getItem("logged");
}

export async function clientBackgroundSync() {
  try {
    const channel = new MessageChannel();
    const dbVersion = parseFloat(window.DATABASE.verno) * 10;
    const user = await getUser();
    const route = await window.LocalStorage.getItem("main-route");

    const options = {
      dbVersion,
      routeId: route.id,
      baseUrl: user.service,
      token: user.sessao.token,
    };

    channel.port1.onmessage = (message) => {
      if (["success", "error"].includes(message.data.status)) {
        SyncNotificationCenter.instance.notifyAll("activeSync", ["Cliente"]);
      }
      SyncNotificationCenter.instance.notifyAll(
        "client-background-sync",
        message.data
      );
    };

    WorkerDownloadClients(options, channel.port2);
  } catch (error) {
    console.error(error);
    //Não lança erro pra quem o chama, somente exibe na tela que hove problemas
  }
}
