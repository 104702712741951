import models from "../../../repository/models";
import AbstractService from "../../AbstractService";
import { clearEnv, setEnvVar } from "../../config";
import RouteService from "../../route";

/**
 * @description Classe agrupadora do comportamento de armazenamento local para o processo de sincronização
 */
export default class SyncStore extends AbstractService {
  constructor() {
    super();
    this.routeService = new RouteService();
  }
  /**
   *
   * @description Limpa despesas do armazenamento interno
   */
  async clearExpenses() {
    await Promise.all([
      models.ManutencaoVeiculo.removeAll(),
      models.DespesaVeiculo.removeAll(),
      models.DespesaRota.removeAll(),
      models.DespesaCombustivelVeiculo.removeAll(),
      models.AdiantamentoFuncionario.removeAll(),
      models.DespesaDeleted.removeAll(),
    ]);
  } //final clearExpenses

  async saveMainRoute(route) {
    await this.routeService.mainRoute(route);
  }

  async clearTeam() {
    await this.routeService.cleanTeam();
    await models.Funcionario.removeAll();
  }

  async saveTeam(data) {
    await models.Funcionario.bulkCreate(data.funcionarios);
    await this.routeService.team(data.equipe);
  }

  async clearVehicle() {
    await Promise.all([models.Veiculo.removeAll()]);
  }
  async clearFuel() {
    await Promise.all([models.Combustivel.removeAll()]);
  }

  async clearDocuments() {
    await Promise.all([models.Documento.removeAll()]);
  }

  async saveFuel(fuels) {
    for (const fuel of fuels) {
      await models.Combustivel.create(fuel, false);
    }
  }

  async saveVehicle(vehicles) {
    await Promise.all(
      vehicles.map((vehicle) => models.Veiculo.create(vehicle, false))
    );
  }

  async saveDocuments(documents) {
    for (const document of documents) {
      await models.Documento.create(document, false);
    }
  }

  async clearCityNeighborhood() {
    await Promise.all([models.Bairro.removeAll(), models.Cidade.removeAll()]);
  }

  async saveCityNeighborhood(data) {
    await models.Cidade.bulkCreate(data.cidades);
    await models.Bairro.bulkCreate(data.bairros);
  }

  /**
   * @description Limpa todos os dados relacionados diretamente à clientes
   * Models envolvidas:
   * Cliente
   * CestaVenda
   * ClienteEdit
   * ClienteVenda
   * ProdutoVenda
   * ClienteBairro
   * ClienteTelefone
   * ClienteDocumento
   * ClienteTrocaAvulso
   * ClienteAgendamento
   * ClienteRecebimento
   * ClienteMovimentacaoCancelada
   */
  async clearAllClients() {
    await Promise.all([
      models.Cliente.removeAll(),
      models.CestaVenda.removeAll(),
      models.ClienteEdit.removeAll(),
      models.ClienteVenda.removeAll(),
      models.ProdutoVenda.removeAll(),
      models.ClienteBairro.removeAll(),
      models.ClienteTelefone.removeAll(),
      models.ClienteDocumento.removeAll(),
      models.ClienteTrocaAvulso.removeAll(),
      models.ClienteAgendamento.removeAll(),
      models.ClienteRecebimento.removeAll(),
      models.ClienteMovimentacaoCancelada.removeAll(),
    ]);

    await this.localStorage.removeItem("TOTAL_CLIENTS");
  }

  /**
   * @description Remove dados de um grupo definido no parametro ids de dados relacionados ao(s) cliente(s)
   * Models envolvidas:
   * Cliente
   * CestaVenda
   * ClienteEdit
   * ClienteVenda
   * ProdutoVenda
   * ClienteBairro
   * ClienteTelefone
   * ClienteDocumento
   * ClienteTrocaAvulso
   * ClienteAgendamento
   * ClienteRecebimento
   * ClienteMovimentacaoCancelada
   * @param {[Number]} ids Conjunto de Id de clientes que devem ser removidos
   */
  async clearClients(ids) {
    const vendasToRemove = await models.ClienteVenda.getAll("ClienteId", ids);
    let vendaIds = [];
    if (vendasToRemove && vendasToRemove.length)
      vendaIds = vendasToRemove.map((v) => v.id);

    await Promise.all([
      models.Cliente.bulkDeleteByKeyPath("id", ids),
      models.ClienteEdit.bulkDeleteByKeyPath("ClienteId", ids),
      models.ClienteVenda.bulkDeleteByKeyPath("ClienteId", ids),
      models.ClienteBairro.bulkDeleteByKeyPath("ClienteId", ids),
      models.ClienteTelefone.bulkDeleteByKeyPath("ClienteId", ids),
      models.ClienteDocumento.bulkDeleteByKeyPath("ClienteId", ids),
      models.ClienteTrocaAvulso.bulkDeleteByKeyPath("ClienteId", ids),
      models.ClienteAgendamento.bulkDeleteByKeyPath("ClienteId", ids),
      models.ClienteRecebimento.bulkDeleteByKeyPath("ClienteId", ids),
      models.CestaVenda.bulkDeleteByKeyPath("ClienteVendaId", vendaIds),
      models.ProdutoVenda.bulkDeleteByKeyPath("ClienteVendaId", vendaIds),
      models.ClienteMovimentacaoCancelada.bulkDeleteByKeyPath("ClienteId", ids),
    ]);
  }
  /**
   * @description Limpa informações sobre: campos requeridos, obrigatórios e editaveis
   *
   */
  async clearRequiredFields() {
    await models.ClienteCampoObrigatorio.removeAll();
    await models.ClienteCampoRecomendavel.removeAll();
    await models.ClienteCampoEdit.removeAll();
  }

  /**
   * @description Armazena no banco informações sobre: campos requeridos, obrigatórios e editaveis
   * @param {Object} param0
   */
  async saveRequiredFields({ required, recomended, editable }) {
    for (let i = 0; i < required.length; i++) {
      if (required[i] && required[i].attributeName == "telefones[]")
        required[i].attributeName = "telefones-list";
      await models.ClienteCampoObrigatorio.create(required[i], false);
    }
    for (let i = 0; i < recomended.length; i++) {
      if (recomended[i] && recomended[i].attributeName == "telefones[]")
        recomended[i].attributeName = "telefones-list";
      await models.ClienteCampoRecomendavel.create(recomended[i], false);
    }

    for (let i = 0; i < editable.length; i++) {
      if (editable[i] && editable[i].attributeName == "telefones[]")
        editable[i].attributeName = "telefones-list";
      await models.ClienteCampoEdit.create(editable[i], false);
    }
  }

  async clearValidators() {
    await models.RotaValidator.removeAll();
    await models.Validator.removeAll();
  }

  async saveValidators({ validators, usedValidators }) {
    for (let i in validators) {
      let val = {
        id: i,
        namespace: i,
        validator: validators[i],
      };
      await models.Validator.create(val, false);
    }
    await models.RotaValidator.bulkCreate(usedValidators);
  }

  async clearHoliday() {
    await models.Feriado.removeAll();
  }

  async saveHoliday(holidays) {
    await models.Feriado.bulkCreate(holidays);
  }

  async clearEnvs() {
    await clearEnv();
  }

  async saveEnvs(envs) {
    for (let env in envs) {
      await setEnvVar(env, envs[env]);
    }
  }

  async cleanMainRoute() {
    await this.routeService.clearMainRoute();
  }

  async clearPaymentForm() {
    await models.FormaPagamento.removeAll();
  }

  async savePaymentForm(paymentForms) {
    for (const form of paymentForms) {
      await models.FormaPagamento.create(
        {
          nome: form.nome,
          id: form.nome,
        },
        false
      );
    }
  }

  async clearPaymentMethod() {
    await models.MeioPagamento.removeAll();
  }

  async savePaymentMethod(paymentsMethod) {
    for (const method of paymentsMethod) {
      await models.MeioPagamento.create(
        {
          nome: method.nome,
          id: method.nome,
        },
        false
      );
    }
  }

  async clearBasketsProducts() {
    await models.Cesta.removeAll();
    await models.Produto.removeAll();
    await models.CestaItens.removeAll();
  }

  async saveBasketsProducts(data) {
    if (data && data.cestas)
      for (const basket of data.cestas) {
        // const { itens: items, ...basketData } = basket;
        await models.Cesta.create(basket, false);
        // await models.CestaItens.bulkCreate(items);
      }

    if (data && data.produtos)
      for (const product of data.produtos) {
        await models.Produto.create(product, false);
      }

    if (data && data.cestaItens)
      for (const basketItem of data.cestaItens) {
        await models.CestaItens.create(basketItem, false);
      }
  }

  async saveBasketItems(basketItems) {
    for (const item of basketItems) {
      await models.CestaItens.create(item);
    }
  }

  async clearProduct() {
    await models.Produto.removeAll();
  }
  async clearBasketItems() {
    await models.CestaItens.removeAll();
  }

  async saveProduct(products) {
    for (const product of products) {
      await models.Produto.create(product, false);
    }
  }

  async clearDailyMap() {
    await this.localStorage.removeItem("dailyMap");
  }

  async saveSchedulesTypes(scheduleTypes) {
    await this.localStorage.setItem("scheduleTypes", scheduleTypes);
  }

  async saveDailyMap(data) {
    await this.localStorage.setItem("dailyMap", data);
  }

  async updateDocumentsPk(createdDocs) {
    const oldIdsCreatedDocs = createdDocs.map((el) => el.oldId);
    const docsToUpdatePk = await models.Documento.getAll(
      "id",
      oldIdsCreatedDocs
    );
    const clientDocsToUpdateFk = await models.ClienteDocumento.getAll(
      "DocumentoId",
      oldIdsCreatedDocs
    );
    const shouldUseSecurityLog = false;
    const keyUpdater = (keyName) => (el) => {
      const dbDoc = createdDocs.find((doc) => doc.oldId == el[keyName]);
      if (dbDoc) el[keyName] = dbDoc.id;
      return el;
    };

    await Promise.all(
      docsToUpdatePk
        .map(keyUpdater("id"))
        .map((el) => models.Documento.put(el, shouldUseSecurityLog))
    );

    await Promise.all(
      clientDocsToUpdateFk
        .map(keyUpdater("DocumentoId"))
        .map((el) => models.ClienteDocumento.put(el, shouldUseSecurityLog))
    );
  }

  cleanRouteOpening() {
    return this.routeService.cleanRouteOpening();
  }
} // FIM CLASSE
