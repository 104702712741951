import React, { createContext } from "react";
import {
  Cliente,
  ClienteCampoObrigatorio,
  ClienteCampoRecomendavel,
  ClienteCampoEdit,
  Documento,
  Bairro,
  ClienteTelefone,
  ClienteAgendamento,
  Validator,
} from "../repository/models";
import { Typography } from "@material-ui/core";
import SyncNotificationCenter from "../service/notifications/syncNotificationCenter";
import ClientNotificationCenter from "../service/notifications/clientNotificationCenter";
import { createClient, updateClient, getClientByHash } from "../service/client";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import AppStorageError from "../errorDefinition/AppStorageError";
import BusinessError from "../errorDefinition/BusinessError";
import { AbstractContext } from "./AbstractContext";
import RotaService from "../service/route/index";
import { validateClientDetails } from "../service/validator";
import LocalStorage from "../utils/localStorage";
import * as syncService from "../service/sync/activeSync/index";

const rotaService = new RotaService();
const clientModel = new Cliente();
const bairro = new Bairro();
const clienteCampoRecomendavel = new ClienteCampoRecomendavel();
const clienteCampoObrigatorio = new ClienteCampoObrigatorio();
const clienteCampoEdit = new ClienteCampoEdit();
const documento = new Documento();
const clienteTelefone = new ClienteTelefone();
const clienteAgendamento = new ClienteAgendamento();
const validator = new Validator();

const localStorage = LocalStorage.instance;

const success = (context, message) =>
  context.addAsyncDialog({
    message: message,
    title: "Sucesso!",
    type: "success",
    hasCloseButton: false,
  });

const ClientContext = createContext();
const OBSERVED_MODELS = ["Cliente", "Documento"];
/**
 * Contexto utilizado na pagina de criar cliente e ver cliente.
 */
class ClientContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.getRouteOpening = this.getRouteOpening.bind(this);
    this.observable = this.observable.bind(this);
    this.syncData = this.syncData.bind(this);
    this.updateSelf = this.updateSelf.bind(this);
    this.createClient = this.createClient.bind(this);
    this.updateClient = this.updateClient.bind(this);
    this.getClientById = this.getClientById.bind(this);
    this.getClientNeighborhood = this.getClientNeighborhood.bind(this);
    this.getAllClientsCount = this.getAllClientsCount.bind(this);
    this.handleSyncSingleClient = this.handleSyncSingleClient.bind(this);
    this.state = {
      requiredFields: [],
      recommendedFields: [],
      notEditableFields: [],
      documents: [],
      validators: [],
      isLoading: false,
      getRouteOpening: this.getRouteOpening,
      updateSelf: this.updateSelf,
      createClient: this.createClient,
      updateClient: this.updateClient,
      getClientById: this.getClientById,
      fetchClients: this.fetchClients,
      getAllClientsCount: this.getAllClientsCount,
      handleSyncSingleClient: this.handleSyncSingleClient,
      getClientNeighborhood: this.getClientNeighborhood,
      getAllClientIdAndName: this.getClientInfo,
      getClientInfoToRenderCard: this.getClientInfoToRenderCard,
      getClientPicture: this.getClientPicture.bind(this),
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.updateSelf();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.unsubscribeClientEvents && this.unsubscribeClientEvents();
  }

  async getRouteOpening() {
    try {
      let routeOpening = await rotaService.routeOpening();
      if (!routeOpening) {
        await this.context.addAsyncDialog({
          message: "Abra a rota para cadastrar novos clientes.",
          title: "Rota está fechada!",
          type: "warning",
          method: "create",
        });
        return false;
      }
      return true;
    } catch (error) {
      if (!error instanceof BusinessError) {
        new AppStorageError({
          message: error.message,
          title: "Falha ao criar novo cliente!",
          type: "error",
          method: "create",
          error,
        });
      }
      throw error;
    }
  }

  async handleSyncSingleClient(cliente) {
    await syncService.syncOneClient((eventName, data) => {
      SyncNotificationCenter.instance.notifyAll("single-client-sync", data);
    }, cliente);
  }

  async observable() {
    await this.updateSelf();
  }

  async syncData() {
    // Check if user is working online, and if so, send expenses.
    let localConfig = await localStorage.getItem("local-config");
    if (
      localConfig &&
      navigator &&
      (!localConfig.OnlineOffline || !navigator.onLine)
    ) {
      return;
    }
    this.clientBackgroundSync();
  }

  /**Função responsável por atualiza os dados dos estados.
   * @function
   */
  async updateSelf() {
    this.setState({ isLoading: true });
    this.setState(
      {
        requiredFields: await clienteCampoObrigatorio.getAll(),
        recommendedFields: await clienteCampoRecomendavel.getAll(),
        notEditableFields: await clienteCampoEdit.getAll(),
        documents: await documento.filter((a) => a.ativo),
        validators: await validator.getAll(),
      },
      () => this.setState({ isLoading: false })
    );
  }

  /**Função responsável por criar um cliente.
   * @function
   */
  async createClient(data) {
    try {
      // Validate client data
      const validator = await validateClientDetails(data);

      if (validator && validator.length) {
        this.context.addAsyncDialog({
          message: (
            <Typography>
              {validator.map((val) => (
                <>
                  {val}
                  <br />
                </>
              ))}
            </Typography>
          ),
          title: "Erro ao salvar cliente!",
          type: "error",
          hasCloseButton: false,
        });
        return false;
      }
      data.shouldSync = true;

      const newUserHash = await createClient(data);
      await success(this.context, "Cliente criado com sucesso!");
      await this.updateSelf();

      return newUserHash;
    } catch (error) {
      if (!error instanceof BusinessError) {
        new AppStorageError({
          message: error.message,
          title: "Falha ao criar cliente!",
          type: "error",
          method: "createClient",
          error,
        });
      }
      throw error;
    }
  }

  /**Função responsável por atualizar os dados de um cliente.
   * @function
   */
  async updateClient(dataClient) {
    try {
      // Validate client data
      const validator = await validateClientDetails(dataClient, [
        "cpf-valido",
        "cpf-unico",
      ]);
      if (validator && validator.length) {
        await this.context.addAsyncDialog({
          message: validator,
          title: "Erro ao salvar cliente!",
          type: "error",
          hasCloseButton: false,
        });
        return;
      }

      await updateClient(dataClient);
      await success(this.context, "Cliente atualizado com sucesso!");
      await this.syncData();
      await this.updateSelf();
      ClientNotificationCenter.instance.notifyAll(
        "clientEdit",
        dataClient.hash
      );
    } catch (error) {
      if (!error instanceof BusinessError) {
        new AppStorageError({
          message: error.message,
          title: "Falha ao atualizar dados do cliente!",
          type: "error",
          method: "UpdateClient",
          error,
        });
      }
      throw error;
    }
  }

  /**Função responsável por buscar os dados de um cliente pelo id do mesmo.
   * @function
   */
  async getClientById(hash) {
    try {
      return await getClientByHash(hash);
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao buscar cliente",
        type: "error",
        method: "getClientById",
        error,
      });
      throw error;
    }
  }

  /**Função responsável por buscar o bairro de um determinado cliente.
   * @function
   */
  async getClientNeighborhood(clientId) {
    let clientNeighborhood = await bairro.getAll();
    clientNeighborhood = clientNeighborhood.find((a) => a.ClientId == clientId);
    if (clientNeighborhood) return clientNeighborhood.BairroId;
    else return;
  }

  async getClientInfo(options) {
    const allClients = await clientModel.getClientsOrderedBy(
      null,
      null,
      options
    );

    return allClients.map((client) => {
      return {
        id: client.id,
        cpf: client.cpf,
        veaco: client.veaco,
        saldoAtual: client.saldoAtual,
        BairroId: client.bairroIdCache,
        lowerCaseNome: client.lowerCaseNome,
        nome: client.lowerCaseNome,
        cidade: client.cidade,
        referencia: client.pontoReferencia,
      };
    });
  }

  async getAllClientsCount() {
    return await clientModel.count();
  }

  async fetchClients(filters, configs) {
    return await clientModel.getClients(filters, configs);
  }

  async getClientPicture(client) {
    return await clientModel.getPicture(client);
  }

  async getClientInfoToRenderCard(clientId, keypath) {
    let client;
    if (keypath === "hash") {
      [client] = await clientModel.getAll("hash", clientId);
    } else {
      [client] = await clientModel.getAll("id", clientId);
    }

    const telephones = await clienteTelefone.getAll("ClienteId", client.id);
    const schedules = await clienteAgendamento.getAll("ClienteId", client.id);
    const neighborhood = await bairro.getClientNeighborhood(client.id);

    client.foto = await this.getClientPicture(client);
    client.telephones = telephones.filter((telephone) => telephone.ativo);
    client.schedules = schedules.filter((schedule) => schedule.ativo);
    client.neighborhood = neighborhood;

    return client;
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <ClientContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </ClientContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { ClientContext, ClientContextProvider };
