import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  buttonsLarge: {
    borderRadius: 40,
    border: 0,
    fontSize: "0.7rem",
    color: "#ffffff",
    display: "inline-flex",
    lineHeight: 1.3,
    padding: "3px 10px",
    "& svg": {
      paddingRight: "3px",
    },
  },
});

class StickerMoves extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <span
          className={classes.buttonsLarge}
          style={{ backgroundColor: this.props.backgroundColor }}
        >
          {this.props.icon}
          {this.props.text}
        </span>
      </>
    );
  }
}
export default withStyles(styles)(StickerMoves);
