import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid, Select, MenuItem } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
  text: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "1.1rem",
  },
  root: {
    height: "100%",
    backgroundColor: "#F5F5F5",
  },
  card: {
    backgroundColor: "#fff",
    position: "relative",
    top: "15px",
  },
  selectInput: {
    width: "100%",
  },
});
/**
 * Card onde tem o switch de seleção, utilizado para selecionar algo
 */
class CardSelect extends React.Component {
  /**
   * @param {boolean} props.disabled Boleano que definirá se o card estará desabilitado ou não
   * @param {boolean} props.checked Boleano que definirá se o switch foi selecionado
   */
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { classes } = this.props;
    return (
      <Grid container direction="column" className={classes.card}>
        <Grid
          item
          container
          alignItems="center"
          justify="space-between"
          style={{ padding: "15px" }}
        >
          <Grid item xs={9}>
            <Typography className={classes.text}>{this.props.title}</Typography>
            {this.props.helperText && (
              <Typography variant="body2" color="textSecondary">
                {this.props.helperText}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <Select
              labelId="select-input"
              id="my-select-input"
              value={this.props.value}
              disabled={this.props.disabled}
              onChange={this.props.func}
              className={classes.selectInput}
            >
              {this.props.values.map((el) => (
                <MenuItem value={el}>{el}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
    );
  }
}
export default withStyles(styles)(CardSelect);
