import styles from "./index.module.css";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import MainPage from "./Main";
import LoginPage from "./login/index";
import RegisterPage from "./register/index";
import logo from "../../img/logo_maximus_solucoes.png";

class AuthPages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogo: true,
      title: "",
    };
    this.checkLogo = this.checkLogo.bind(this);
    this.getTitle = this.getTitle.bind(this);
  }

  checkLogo() {
    if (
      this.props.location.pathname.includes("pap") ||
      this.props.location.pathname.includes("enterprise")
    ) {
      if (this.state.showLogo) this.setState({ showLogo: false });
    } else {
      if (!this.state.showLogo) this.setState({ showLogo: true });
    }
  }

  getTitle() {
    if (this.props.location.pathname.includes("pap")) {
      return (
        <>
          <Typography variant="h1" className={styles.Main_Title} gutterBottom>
            <span className={styles.Small_Title}>Porta</span>A
            <span className={styles.Small_Title}>Porta</span>
          </Typography>
        </>
      );
    } else if (this.props.location.pathname.includes("enterprise")) {
      return (
        <>
          <Typography variant="h1" className={styles.Main_Title} gutterBottom>
            B<span className={styles.Small_Title}>itBox</span>
          </Typography>
        </>
      );
    } else {
      return (
        <Grid item xs={9}>
          <img src={logo} style={{ width: "100%" }} alt="Company logo"></img>
        </Grid>
      );
    }
  }

  render() {
    const { location } = this.props;

    let TopContainer_Class;

    switch (location.pathname) {
      case "/":
        TopContainer_Class = styles.Top_Container;
        break;

      case "/login/pap" || "/login/enterprise":
        TopContainer_Class = styles.Top_Container_OnLogin;
        break;
      case "/register":
        TopContainer_Class = styles.Top_Container_OnRegister;
        break;
      default:
        TopContainer_Class = styles.Top_Container;
        break;
    }

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="flex-start"
        className={styles.Main_Container}
      >
        <Grid item container className={TopContainer_Class} justify="center">
          {this.getTitle()}
        </Grid>
        <Switch>
          <Route exact path="/" component={MainPage}></Route>
          <Route exact path="/login/:loginType" component={LoginPage}></Route>
          <Route exact path="/register" component={RegisterPage}></Route>
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        </Switch>
      </Grid>
    );
  }
}
export default AuthPages;
