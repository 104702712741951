import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import RefreshIcon from "@material-ui/icons/Refresh";
import Grid from "@material-ui/core/Grid";
import PhoneIcon from "@material-ui/icons/Phone";
import Divider from "@material-ui/core/Divider";
import StickerMoves from "../../../Stickers/StickerMoves";
import * as moment from "moment";
import { Link } from "react-router-dom";
import history from "../../../../router/History";
const styles = (theme) => ({
  syncButton: {
    backgroundColor: "#fff",
    borderRadius: 3,
    border: "1px solid #DCDCDC",
    display: "flex",
    // display: "none",
    alignItems: "center",
    fontSize: ".7rem",
    marginTop: 10,
    float: "right",
  },
  name: {
    fontSize: "0.85rem",
    fontWeight: 600,
    wordWrap: "break-word",
  },
  address: {
    fontSize: "0.8rem",
    color: "#a1a1a1",
    wordWrap: "break-word",
  },
  telephone: {
    "& a": {
      color: "#222",
      fontSize: "0.85rem",
      textDecoration: "none",
      verticalAlign: "middle",
      padding: "2px 5px",
      border: "none",
      borderRadius: "30px",
      background: "#a2a2a245",
    },
  },
  telephoneIcon: {
    verticalAlign: "middle",
  },
  dataScheduling: {
    fontSize: "0.8rem",
    color: "#a5a5a5",
    paddingTop: "10px",
  },
  money: {
    color: "#d44444",
    fontSize: ".8rem",
    fontWeight: 600,
  },
  currentBalanceText: {
    fontSize: "0.7rem",
    color: "#777777",
    marginTop: "-5px",
  },
  divider: {
    margin: "0px 0 5px 0",
  },

  link: {
    textDecoration: "none",
    color: "inherit",
  },
});

/**
 * Card expansivel de clientes
 */
class ExpandCard extends React.Component {
  /**
   * @param {boolean} props.active Boleano que será o collapse do componente
   * @param {object} props.Client Prop onde vem os dados dos clientes
   * @param {function} props.showActionsCard Função que irá ser acinado ao clicar no ícone para abrir o ActionsCard
   * @param {function} props.closeExpandCard Função que irá ser acinado ao clicar no ícone de fechar o ActionsCard
   */
  constructor(props) {
    super(props);
    this.makeMovement = this.makeMovement.bind(this);
    this.renderDailyActions = this.renderDailyActions.bind(this);
    this.onSyncClick = this.onSyncClick.bind(this);
  }

  makeMovement(type) { }

  onSyncClick() {
    this.props.onSync && this.props.onSync(this.props.Client?.id);
  }

  renderDailyActions() {
    let { classes } = this.props;

    const shouldSell = this.props.Client.dailySchedules.some(
      (a) => a.type.toLowerCase() == "venda"
    );
    const shouldExchange = this.props.Client.dailySchedules.some(
      (a) => a.type.toLowerCase() == "troca"
    );
    const shouldReceiviment = this.props.Client.dailySchedules.some(
      (a) => a.type.toLowerCase() == "recebimento"
    );
    const displayNone = { display: "none" };
    return (
      <>
        <Grid style={shouldReceiviment ? undefined : displayNone} item>
          <Link
            to={{
              pathname: `/receivement/create/${this.props.Client.hash}`,
              state: { prevUrl: history.location.pathname },
            }}
            className={classes.link}
          >
            <StickerMoves
              onClick={this.makeMovement("recebimento")}
              backgroundColor="#21A94F"
              icon={<LocalAtmIcon style={{ fontSize: 13 }} />}
              text="RECEBER"
            />
          </Link>
        </Grid>
        <Grid item style={shouldSell ? undefined : displayNone}>
          <Link
            to={{
              pathname: `/sale/create/${this.props.Client.hash}`,
              state: { prevUrl: history.location.pathname },
            }}
            className={classes.link}
          >
            <StickerMoves
              backgroundColor="#F3C631"
              icon={<ShoppingCartIcon style={{ fontSize: 13 }} />}
              text="VENDER"
            />
          </Link>
        </Grid>
        <Grid item style={shouldExchange ? undefined : displayNone}>
          <Link
            to={{
              pathname: `/client/view/exchanges/${this.props.Client.hash}`,
              state: { prevUrl: history.location.pathname },
            }}
            className={classes.link}
          >
            <StickerMoves
              backgroundColor="#2383F0"
              icon={<SyncAltIcon style={{ fontSize: 13 }} />}
              text="TROCAR"
            />
          </Link>
        </Grid>
        <Typography
          style={
            !shouldReceiviment && !shouldSell && !shouldExchange
              ? undefined
              : displayNone
          }
          className={classes.dataScheduling}
        >
          Não há agendamentos.
        </Typography>
      </>
    );
  }

  render() {
    let { classes } = this.props;
    return (
      <Grid container style={{ paddingLeft: 10 }}>
        <Grid container>
          <Grid
            item
            xs={8}
            style={{ overflow: "auto", maxHeight: "90px", minHeight: "70px" }}
          >
            <Typography className={classes.address}>
              {this.props.Client.logradouro}, {this.props.Client.numero},{" "}
              {this.props.Client.bairro} - {this.props.Client.referencePoint}
              <br />
              {this.props.Client.estado}{" "}
              {this.props.Client.cep ? `(${this.props.Client.cep})` : ""}
            </Typography>
            <Typography className={classes.address}>
              {this.props.Client.pontoReferencia}
            </Typography>
          </Grid>

          <Grid
            item
            xs={4}
            container
            direction="column"
            justify="space-between"
          >
            <Grid item>
              <button className={classes.syncButton} onClick={this.onSyncClick}>
                <RefreshIcon fontSize="small" />
                Sincronizar
              </button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              justify="flex-start"
              direction="row"
              wrap="nowrap"
              style={{ overflow: "auto", padding: "3px 0" }}
            >
              <PhoneIcon
                className={classes.telephoneIcon}
                htmlColor="#777777"
                fontSize="small"
                alignmentBaseline="middle"
              />
              &nbsp;
              {this.props.Client.telefones.length ? (
                this.props.Client.telefones.map((tel, i) => (
                  <Grid key={tel.id} item style={{ paddingRight: 5 }}>
                    <Typography
                      className={classes.telephone}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <a href={`tel:0${tel.numero.replace(/\D/g, "")}`}>
                        {tel.numero}
                      </a>
                    </Typography>
                  </Grid>
                ))
              ) : (
                <Typography style={{ color: "#999" }}>
                  Nenhum telefone
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          style={{ paddingTop: "5px", marginBottom: "12px" }}
        >
          <Grid item xs={12}>
            <Divider className={classes.divider} />
            <Typography>
              {!!this.props.aboutScheduylingText
                ? this.props.aboutScheduylingText
                : "Ações para hoje:"}
            </Typography>
            <Grid container direction="row" spacing={1}>
              {this.renderDailyActions()}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.dataScheduling}>
              {this.getLastSchedule()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  getLastSchedule() {
    if (!this.props.Client.dailySchedules.length) return "";

    const lastSchedule = this.props.Client.dailySchedules.reduce(
      (memo, schedule) => {
        if (moment(memo.createdAt).isAfter(schedule.createdAt)) {
          return memo;
        } else {
          return schedule;
        }
      }
    );

    return `Ultimo agendamento criado em ${moment(
      lastSchedule.createdAt
    ).format("DD/MM/YYYY")}`;
  }
}
export default withStyles(styles)(ExpandCard);
