import DataAccess from "../DataAccessStrategy";

export default class Funcionario extends DataAccess {
  constructor() {
    super("Funcionario", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      descricao: String,
      valor: String,
      data: String,
      RotaAberturaId: Number,
      FuncionarioId: Number,
      hashRotaAbertura: String,
      hash: String,
      EquipeId: Number,
      regime: Object,
    });
  }
}
