import React from "react";
import SchedulingForm from "../../components/Scheduling/ViewClient/SchedulingForm";

import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import { SchedulingsContext } from "../../contexts/SchedulingsContext";

/**
 * Tela de criação de agendamento
 */
class WindowForAdd extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {array} this.state.clientName Nome do respectivo cliente que realizará a troca
   */
  constructor(props) {
    super(props);
    this.state = {
      clientName: null,
      isLoading: true,
    };
    this.saveData = this.saveData.bind(this);
  }

  /**Função executada na montagem do componente, onde irá buscar o nome do cliente.
   * @function
   */
  async componentDidMount() {
    const client = await this.context.getClientByHash(
      this.props.match.params.hash
    );

    this.setState({
      clientName: client.name,
      clientBalance: client.balance,
      schedules: client.type,
      isLoading: false,
      open: false,
    });
  }

  /**Função que irá criar os agendamentos no banco e em seguida redirecionar para o 'Ver Cliente'.
   * @function
   * @param {object} data Dados que serão usados na criação do novo agendamento.
   */
  async saveData(data) {
    try {
      await this.context.createScheduling(this.props.match.params.hash, data);
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(messageContext) => (
          <SchedulingForm
            titleHeader="Novo agendamento"
            isLoading={this.state.isLoading}
            schedulesTypes={this.context.scheduleTypes}
            history={this.props.history}
            func={this.saveData}
            open={this.state.open}
            // link={`/client/view/schedules/${this.props.match.params.hash}`}
            context={this.context}
            messageDialogContext={messageContext}
            data={{
              clientName: this.state.clientName,
              clientBalance: this.state.clientBalance,
              schedules: this.state.schedules,
            }}
          />
        )}
      </MessageDialogContext.Consumer>
    );
  }
}
WindowForAdd.contextType = SchedulingsContext;
export default WindowForAdd;
