import React, { useEffect, useState } from "react";
import Hammer from "react-hammerjs";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useConfigs } from "../../../../contexts/ConfigContext";

const MAX_OFFSET = 60;

const getStyles = makeStyles((theme) => ({
  childWrapper: ({ cardOffset }) => {
    const absOffset = Math.abs(cardOffset);
    const opacity = Math.abs(absOffset / MAX_OFFSET - 1) + 0.4;
    const styles = {};

    if (cardOffset !== 0) {
      styles.boxShadow = theme.shadows[5];
    }

    return {
      opacity: opacity,
      transform: `translateX(${cardOffset}px)`,
      position: "relative",
      ...styles,
    };
  },

  wrapperCardStyles: ({ cardOffset }) => {
    return {
      background:
        cardOffset > 0 ? "#008080" : cardOffset < 0 ? "#ff4500" : "none",
      color: "#fff",
      position: "relative",
      boxShadow: `${
        cardOffset > 0
          ? "inset -240px 0px 90px -20px rgba(0, 0, 0, 0.4)"
          : "inset 240px 0px 90px -20px rgba(0, 0, 0, 0.4)"
      } `,
    };
  },
  backgroundTextClass: ({ cardOffset }) => {
    return {
      fontSize: "1.1rem",
      textTransform: "uppercase",
      opacity: Math.abs(cardOffset / MAX_OFFSET),
      position: "absolute",
      right: cardOffset > 0 ? "auto" : "10px",
      left: cardOffset > 0 ? "10px" : "auto",
    };
  },
}));

function useDragLimit(maxRange, options) {
  const [step, setStep] = React.useState(0);
  const offsetMemo = React.useRef(0);
  const timeOutId = React.useRef();

  const clearHookTimeout = React.useCallback(function () {
    if (timeOutId.current) {
      clearTimeout(timeOutId.current);
      timeOutId.current = null;
    }
  }, []);

  React.useEffect(
    function () {
      clearHookTimeout();
      return () => clearHookTimeout(); // Limpar ao desmontar
    },
    [clearHookTimeout]
  );

  const shouldBlockDrag = React.useCallback(
    function (offset) {
      const { blockSwipeRight, blockSwipeLeft } = options;
      const isDirectionRight = offset > 0;

      if (isDirectionRight && blockSwipeRight) return true;
      if (!isDirectionRight && blockSwipeLeft) return true;
      return false;
    },
    [options]
  );

  const limitSetter = React.useCallback(
    function (offset, force = false) {
      if (force) return setStep(offset);
      if (shouldBlockDrag(offset)) {
        return;
      }

      clearHookTimeout();
      const differenceFromLast = Math.abs(
        Math.abs(offsetMemo.current) - Math.abs(offset)
      );
      if (differenceFromLast > 100) {
        setStep(0);
        offsetMemo.current = 0;
        return;
      }

      if (offset > maxRange) {
        setStep(maxRange);
      } else if (offset < -maxRange) {
        setStep(-maxRange);
      } else {
        setStep(offset);
      }
      offsetMemo.current = offset;
    },
    [maxRange, clearHookTimeout, shouldBlockDrag]
  );

  const resetLimit = React.useCallback(
    function (to, stepDecrease, delay, force = false) {
      let nextStep = step;
      if (Math.abs(step) >= maxRange && !force) {
        return;
      }

      function onTick() {
        const absLimit = Math.abs(nextStep);
        const signal = absLimit !== 0 ? nextStep / absLimit : 0;
        const goingOut = to < absLimit;
        nextStep = goingOut ? absLimit - stepDecrease : absLimit + stepDecrease;
        if (goingOut) {
          if (nextStep < to) {
            nextStep = to;
          }
        }
        nextStep *= signal;
        setStep(nextStep);
        if (Math.abs(nextStep) === Math.abs(to)) {
          setTimeout(() => {
            clearHookTimeout();
            offsetMemo.current = to;
            setStep(0);
          }, 100);
        }
      }

      if (delay !== null) {
        timeOutId.current = setInterval(onTick, delay);
      }
    },
    [step, clearHookTimeout, maxRange]
  );

  return [step, limitSetter, resetLimit, clearHookTimeout];
}

export default function DragActions({
  children,
  onSwipeRight,
  onSwipeLeft,
  disabled,
  blockSwipeRight,
  blockSwipeLeft,
}) {
  const [renders, setRenders] = useState(0);
  const [cardOffset, setCardOffset, resetLimit] = useDragLimit(MAX_OFFSET, {
    blockSwipeRight,
    blockSwipeLeft,
  });
  const classes = getStyles({ cardOffset });
  const { isDragEnableOnClientCard } = useConfigs();

  const handlePan = React.useCallback(
    function (panEvent) {
      const { deltaX } = panEvent;
      setCardOffset(deltaX);
    },
    [setCardOffset]
  );

  const handlePanEnd = React.useCallback(
    async function (panEvent) {
      const { deltaX } = panEvent;
      let actionResp = null;

      if (deltaX >= MAX_OFFSET) {
        actionResp = !!onSwipeRight && (await onSwipeRight(500));
        if (!!actionResp) {
          resetLimit(400, 10, 25, true);
        }
      } else if (deltaX <= -MAX_OFFSET) {
        actionResp = !!onSwipeLeft && (await onSwipeLeft(500));
        if (!!actionResp) {
          resetLimit(400, 10, 25, true);
        }
      }

      if (!actionResp) {
        resetLimit(400, 10, 25, true);
      }

      setRenders((r) => r + 1);
    },
    [onSwipeLeft, onSwipeRight, resetLimit]
  );

  useEffect(() => {
    console.log(renders);
  }, [renders]);

  if (disabled || !isDragEnableOnClientCard) return children;

  return (
    <Hammer
      onPan={handlePan}
      direction="DIRECTION_HORIZONTAL"
      onPanEnd={handlePanEnd}
    >
      <Grid container alignItems="center" className={classes.wrapperCardStyles}>
        <Typography className={classes.backgroundTextClass}>
          {cardOffset > 0 ? "Repassar" : "Pular"}
        </Typography>
        <Grid item xs={12} className={classes.childWrapper}>
          {children}
        </Grid>
      </Grid>
    </Hammer>
  );
}
