import ScenesPage from "../../components/AbstractComponents/ScenesPage";
import Details from "../../components/RouteClosureScreen/Details";
import AddExpenses from "../../components/RouteClosureScreen/AddExpenses";
import Closure from "../../components/RouteClosureScreen/Closure";
import ExchangeReport from "../../components/RouteClosureScreen/ExchangeReport";
import FinalReport from "../../components/RouteClosureScreen/FinalReport";
import history from "../../router/History";
import MovementDetails from "../../components/RouteClosureScreen/MovementDetails";

/**
 * Componente responsável de prover o sistema de cenas e renderizar as telas para fechamento de tela
 * @extends ScenePage
 */
class CloseRouteScenes extends ScenesPage {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {array} this.state.scenes Lista dos componentes que serão exibidos nas cenas e suas respectivas configurações
   * @property {object} this.state.previousScene  Indica a próxima cena a ser exibida pelo nome e id
   * @property {object} this.state.activeScene  Indica a cena ativa no momento.
   * @property {object} this.state.nextScene Indica a próxima cena a se exibida
   */
  constructor(props) {
    super(props);
    this.state.scenes.push(
      {
        name: "Detalhes das movimentações da abertura",
        id: "movementDetails",
        component: MovementDetails,
        hideButtons: true,
      },
      {
        name: "Detalhes para o fechamento da rota",
        id: "details",
        component: Details,
        hideButtons: true,
      },
      {
        name: "Adicionar despesas",
        id: "expenses",
        component: AddExpenses,
        hideButtons: true,
      },
      {
        name: "Fechamento de rota",
        id: "closure",
        component: Closure,
        hideButtons: true,
      },
      {
        name: "Relatório de trocas",
        id: "exchanges",
        component: ExchangeReport,
        hideButtons: true,
      },
      {
        name: "Relatório Final",
        id: "finalReport",
        component: FinalReport,
        hideButtons: true,
        onClickPrevious: () => {
          history.push("/");
        },
      }
    );

    this.state = {
      ...this.state,
      previousScene: { name: "", id: "" },
      activeScene: this.state.scenes[0],
      nextScene: this.state.scenes[1],
    };
  }
}
export default CloseRouteScenes;
