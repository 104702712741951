import AbstractService from "../AbstractService";
import {
  Veiculo,
  ClienteRecebimento,
  ClienteVenda,
  Cliente,
  ClienteDiaria,
} from "../../repository/models";
import SessionDataLostError from "../../errorDefinition/SessionDataLostError";
import moment from "moment";
import Moeda from "../../utils/Moeda";
import { getUser, checkEmployeeToken } from "../authentication/index.js";
import { fetchPollyfill } from "../AbstractService";
import LocalStorage from "../../utils/localStorage";
import ApiErrorResponse from "../../errorDefinition/ApiErrorResponse";
import EventLog from "../../repository/errorsModels/EventLog";

const veiculo = new Veiculo();
const clienteRecebimento = new ClienteRecebimento();
const clienteVenda = new ClienteVenda();
const cliente = new Cliente();
const clienteDiaria = new ClienteDiaria();

/**
 * @typedef {Object} MainRoute
 * @property {Number} id
 * @property {String} nome
 * @property {String} estado
 * @property {String} hash
 * @property {String} diasAgendamento
 * @property {Date} ultimaExecAgendamento
 * @property {Number} mesesMovimentacoes
 * @property {Boolean} aceitaProdutoNovo
 * @property {Boolean} isSorted
 * @property {Boolean} acceptPrimeiroCadastro
 * @property {[String]} verificaValidators
 * @property {Boolean} ativo
 * @property {Date} createdAt
 * @property {Date} updatedAt
 * @property {String} ideal
 * @property {String} alcancado
 * @property {[String]} telefones
 */

/**
 * @typedef {Object} Team
 * @property {number} RotumId
 * @property {number} VeiculoId
 * @property {Boolean} ativo
 * @property {Date} createdAt
 * @property {number} id
 * @property {string} nome
 * @property {date} updatedAt
 *
 */

/**
 * @typedef {Object} RouteOpening
 * @property {Number} cobrador: 60
 * @property {Date} dataAbertura: "2020-05-08T15:41:39.602Z"
 * @property {String} hash: "-1594983334250"
 * @property {String} id: "-1594983334250"
 * @property {String} listaDiariaFinal: "08/05/2020"
 * @property {String} listaDiariaInicio: "08/05/2020"
 * @property {Number} motorista: 60
 * @property {Boolean} palma: false
 * @property {String} quilometragemVeiculoInicio: "55"
 * @property {Boolean} separar: false
 * @property {Number} veiculo: 11
 */

/**
 * @description Label que deve ser utilizado por toda a aplicação quando a inteção for referenciar os dados de rota principal em local storage
 * @type {String}
 */
export const MAIN_ROUTE = "main-route";

/**
 * @description Label que deve ser utilizado por toda a aplicação quando a inteção forreferenciar os dados de rota
 * @type {String}
 */
export const ROUTE = "route";

/**
 * @description Label que deve ser utilizado por toda a aplicação quando a inteção for referenciar as informações da equipe do funcionario logado
 * @type {String}
 */
export const MAIN_TEAM = "main-team";

/**
 * @description Label que deve ser utilizado por toda a aplicação quando a inteção for referenciar os dados de abertura de rota em LocalStorage
 * @type {String}
 */
export const ROUTE_OPENING = "route-opening";

/**
 * @description Label que deve ser utilizado por toda a aplicação quando a inteção for referenciar os dados de Motorista
 * @type {String}
 */
export const DRIVER_OPENING = "driver-opening";

/**
 * @description Label que deve ser utilizado por toda a aplicação quando a inteção for referenciar os dados de vendedor
 * @type {String}
 */
export const COLLECTOR_OPENING = "collector-opening";

export default class RotaService extends AbstractService {
  /**
   * @description Função utilizada tanto para setar quanto para recuperar os dados da rota principal.
   * Caso seja passado um argumento os dados de rota principal será substituido. Caso não seja
   * informado nenhum parametro será retornado o valor da rota principal armazenado em memória.
   * @param {MainRoute} [rota]
   * @returns {Promise<MainRoute>} Em ambos os casos os dados da abertura serão retornados.
   */
  async mainRoute(rota) {
    if (arguments.length) {
      await this.localStorage.setItem(MAIN_ROUTE, rota);
      return rota;
    } else {
      const route = await this.localStorage.getItem(MAIN_ROUTE);
      if (!route) {
        throw new SessionDataLostError();
      }
      return route;
    }
  }

  /**
   * @description Funciona como Getter e Setter dos dados de {RouteOpening} Caso não seja informado
   * parametros, será um Getter asyncrono caso seja informado o parametro sobreescreverá os dados
   * que já existem
   * @param {RouteOpening} data
   * @returns {Promise<RouteOpening>} Dados da abertura de rota.
   */
  async routeOpening(data) {
    if (arguments.length) {
      try {
        let hash = !data.hash ? window.DATABASE.generateUniqId() : data.hash;
        let id = window.DATABASE.generateUniqId();
        const loggedUser = await getUser();
        data.hash = String(hash);
        data.id = id;
        data.dataAbertura = moment().toDate();

        data.FuncionarioId = data.FuncionarioId
          ? data.FuncionarioId
          : loggedUser.id;
        data.listaDiariaInicio = moment(data.listaDiariaInicio).format(
          "DD/MM/YYYY"
        );
        data.listaDiariaFinal = moment(data.listaDiariaFinal).format(
          "DD/MM/YYYY"
        );

        let newVeiculo = await veiculo.getAll("id", data.veiculo);
        newVeiculo = newVeiculo[0];

        if (!newVeiculo) throw new Error("Véiculo não encontrado");
        newVeiculo.quilometragem = data.quilometragemVeiculoInicio;
        await veiculo.put(newVeiculo);

        let motoristaModel = {
          FuncionarioId: data.motorista,
          VeiculoId: data.veiculo,
          EquipeId: data.EquipeId,
          dataInicio: data.listaDiariaInicio,
        };

        let cobradorModel = {
          FuncionarioId: data.cobrador,
          EquipeId: data.EquipeId,
          dataInicio: data.listaDiariaInicio,
        };

        await this.localStorage.setItem(DRIVER_OPENING, motoristaModel);
        await this.localStorage.setItem(COLLECTOR_OPENING, cobradorModel);

        if (data && data.hash === hash)
          new EventLog().registerEvent("ROTA", "ROTA", "ABERTURA", data);

        return data;
      } catch (error) {
        throw error;
      }
    } else {
      return await this.localStorage.getItem(ROUTE_OPENING);
    }
  }

  /**
   *
   * @description Responsavel por fechar a rota aberta no dispositivo modificando os dados de Rota abertura
   * @param {Object} data Dados necessarios para fechamento da rota. como total de recebimento e venda
   */
  async closeRoute(data) {
    let rotaAbertura = await this.routeOpening();
    if (!rotaAbertura)
      throw new Error("Abra a rota para seguir com esta ação!");
    rotaAbertura.quilometragemVeiculoFinal = data.km;
    rotaAbertura.quantidadeRecebimento = data.receivementCount;
    rotaAbertura.quantidadeVendas = data.saleCount;
    rotaAbertura.valorVendas = data.sellValue;
    rotaAbertura.dataFechamento = new Date();
    await this.localStorage.setItem(ROUTE_OPENING, rotaAbertura);

    new EventLog().registerEvent("ROTA", "ROTA", "FECHAMENTO", data);
    return rotaAbertura;
  }

  /**
   * @description Funciona como Getter e Setter dos dados de equipe, caso não informado um parametro
   * o retorno será os dados de equipe. caso informado os dados do parametro sobreescreverá os dados
   * antigos
   * @param {Team} [teamInfo] Dados que devem ser armazenados
   * @returns {Promise<Team>} Dados da equipe
   */
  async team(teamInfo) {
    if (arguments.length) {
      await this.localStorage.setItem(MAIN_TEAM, teamInfo);
      return teamInfo;
    } else {
      return await this.localStorage.getItem(MAIN_TEAM);
    }
  }

  /**
   * @description Getter para os dados de fechamento de rota
   */
  async getCloseRouteData() {
    let routeOpening = await this.routeOpening();

    if (!routeOpening) {
      throw new Error("Abra a rota para prosseguir com esta ação!");
    }
    const receivement = await clienteRecebimento.getAll(
      "hashRotaAbertura",
      routeOpening.hash
    );
    const sales = await clienteVenda.getAll(
      "hashRotaAbertura",
      routeOpening.hash
    );

    const valueSomatory = (memo, el) => memo.add(el.valor);

    const appendClient = async (mov) => {
      const [client] = await cliente.getAll("id", mov.ClienteId);
      mov.client = client || {};
      return mov;
    };

    const receivementValue = receivement.reduce(valueSomatory, Moeda.create());
    const saleValue = sales.reduce(valueSomatory, Moeda.create());

    const receivementClients = await Promise.all(receivement.map(appendClient));
    const saleClients = await Promise.all(sales.map(appendClient));

    return {
      saleClients,
      receivementClients,
      saleCount: sales.length,
      vehicle: routeOpening.veiculo,
      hashRotaAbertura: routeOpening.hash,
      receivementCount: receivement.length,
      openDate: routeOpening.listaDiariaInicio,
      closeDate: routeOpening.listaDiariaFinal,
      saleValue: Moeda.create(saleValue).format(),
      receivementValue: Moeda.create(receivementValue).format(),
    };
  }

  /**
   * @description Função que atribui o hash correto aos dados de rota abertura
   * @param {RouteOpening} [rotaAbertura]  Dados de rota abertura que deve receber hash
   * @returns {Promise<RouteOpening>} Dados com o novo (ou antigo dependendo da ocasião) hash
   */
  async routeOpeningGenerateHash(rotaAbertura) {
    if (arguments.length) {
      //SE NÃO TEM HASH, DEVE PROCURAR O HASH NO STORAGE CASO N TENHA
      if (!rotaAbertura.hash) {
        let rotaAberturaString = await this.localStorage.getItem(ROUTE_OPENING);

        if (rotaAberturaString) {
          try {
            let rotaAberturaOnStorage = rotaAberturaString;
            if (rotaAberturaOnStorage && rotaAberturaOnStorage.hash) {
              rotaAbertura.hash = rotaAberturaOnStorage.hash;
            }
          } catch (err) {
            rotaAbertura.hash = window.DATABASE.generateUniqId();
          }
        } else {
          rotaAbertura.hash = window.DATABASE.generateUniqId();
        }
      }
      return rotaAbertura;
    } else {
      let rotaAbertura = await this.localStorage.getItem(ROUTE_OPENING);
      if (!rotaAbertura.hash) {
        // let oldHash = rotaAbertura.hash;
        rotaAbertura.hash = String(window.DATABASE.generateUniqId());

        //@todo CAPTURAR O PQ NÃO TEVE HASH

        rotaAbertura = await this.routeOpening(rotaAbertura);
      }
      return rotaAbertura;
    }
  }

  /**
   * @description Utilitario para limpeza dos dados listados a seguir: dados da abertura, motorista, cobrador, filtros de todas as listas e todas as instancias de clienteDiaria
   */
  async cleanRouteOpening() {
    await this.localStorage.removeItem(ROUTE_OPENING);
    await this.localStorage.removeItem(DRIVER_OPENING);
    await this.localStorage.removeItem(COLLECTOR_OPENING);
    await this.localStorage.removeItem("dailyMap");
    this.clearAllFilters();
    // Clear all clienteDiaria instances so there will no data that could be reused after opening the route again
    await clienteDiaria.removeAll();
  }

  clearAllFilters() {
    //Clear all filters
    window.localStorage.setItem("daily", "{}");
    window.localStorage.setItem("debtor", "{}");
    window.localStorage.setItem("defaulter", "{}");
    window.localStorage.setItem("settled", "{}");
    window.localStorage.setItem("repass", "{}");
  }

  /**
   * @description Limpeza dos dados da Rota principal
   */
  async clearMainRoute() {
    await this.localStorage.removeItem(MAIN_ROUTE);
  }
  /**
   * @description Limpa os dados da equipe armazenados em memória
   */
  async cleanTeam() {
    await this.localStorage.removeItem(MAIN_TEAM);
  }
} //FIM CLASSE

/**
 * @description Utilitario para limpeza dos dados listados a seguir: dados da abertura, motorista e cobrador
 */
export async function cleanRouteOpening() {
  await LocalStorage.instance.removeItem(ROUTE_OPENING);
  await LocalStorage.instance.removeItem(DRIVER_OPENING);
  await LocalStorage.instance.removeItem(COLLECTOR_OPENING);
}

/**
 * @description Envia os dados da abertura ao servidor
 * @param {Boolean} removeRotaAbertura Caso true os dados de abertura serão removidos da memória interna do dispositivo
 */
export async function sendingRouteOpening(
  rotaAbertura,
  removeRotaAbertura = true
) {
  let user = await getUser();
  let rota = await LocalStorage.instance.getItem("main-team");
  if (rota && rota.id) {
    if (!rotaAbertura) {
      return;
    }

    const URL = `${user.service}/api/rota-abertura?token=${user.sessao.token}`;
    const response = await fetchPollyfill(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        rotaAbertura: rotaAbertura,
      }),
    });

    const data = await response.json();
    checkEmployeeToken(data, response.abort);

    if (data.error) {
      throw new ApiErrorResponse({
        method: "services.route.sendingRouteOpening",
        title: "Houve um problema no servidor",
        type: "error",
        message: data.msg,
        rotaAbertura,
      });
    }
    const rotaAberturaId = data.data.id;
    const rotaAberturaHash = data.data.hash;
    await LocalStorage.instance.setItem("route-opening", {
      ...rotaAbertura,
      id: rotaAberturaId,
      hash: rotaAberturaHash ? rotaAberturaHash : rotaAbertura.hash,
    });

    if (data.data.fechada) {
      if (removeRotaAbertura) {
        await cleanRouteOpening();
      }
    } else {
      if (data.data.clienteAndAgendamentoCount) {
        let numClientes = data.data.clienteAndAgendamentoCount.numClientes;
        let numAgendamentos =
          data.data.clienteAndAgendamentoCount.numAgendamento;
        return {
          numClientes,
          numAgendamentos,
        };
      }
    }
  }
}

export async function rotaIdeal(date) {
  const rota = await new RotaService().mainRoute();
  const mes = moment(date).format("MM");
  const ano = moment(date).format("YYYY");

  const employee = await getUser();
  const URL = `${employee.service}/api/rota-ideal/${rota.id}/${mes}/${ano}?token=${employee.sessao.token}`;
  const response = await fetchPollyfill(URL);

  let body = await response.json();
  await checkEmployeeToken(body, response.abort);
  return body.data.rotaIdeal;
}
export async function listMonthOpeningList() {
  const rota = await new RotaService().mainRoute();
  const funcionario = await getUser();

  const URL = `${funcionario.service}/api/rota-abertura/${rota.id}/list?token=${funcionario.sessao.token}`;
  const response = await fetchPollyfill(URL);

  const body = await response.json();
  await checkEmployeeToken(body, response.abort);
  return body.data.aberturas;
}

/**
 * @description Funciona como Getter e Setter dos dados de equipe, caso não informado um parametro
 * o retorno será os dados de equipe. caso informado os dados do parametro sobreescreverá os dados
 * antigos
 * @param {Object} [teamInfo] Dados que devem ser armazenados
 * @returns {Promise<Object>} Dados da equipe
 */
export async function team(teamInfo) {
  if (arguments.length) {
    await LocalStorage.instance.setItem(MAIN_TEAM, teamInfo);
    return teamInfo;
  } else {
    return await LocalStorage.instance.getItem(MAIN_TEAM);
  }
}

/**
 * @description Função utilizada tanto para setar quanto para recuperar os dados da rota principal.
 * Caso seja passado um argumento os dados de rota principal será substituido. Caso não seja
 * informado nenhum parametro será retornado o valor da rota principal armazenado em memória.
 * @param {MainRoute} [rota]
 * @returns {Promise<MainRoute>} Em ambos os casos os dados da abertura serão retornados.
 */
export async function mainRoute(rota) {
  if (arguments.length) {
    await LocalStorage.instance.setItem(MAIN_ROUTE, rota);
    return rota;
  } else {
    return await LocalStorage.instance.getItem(MAIN_ROUTE);
  }
}

export async function generateRouteOpeningExchangeReport(hashRotaAbertura) {
  const user = await getUser();
  if (!navigator.onLine) {
    throw new Error("Necessário ter internet!");
  }

  const response = await fetchPollyfill(
    `${user.service}/api/abertura/${hashRotaAbertura}/generate/relatorio/troca?token=${user.sessao.token}`
  );
  const { abort } = response;
  const data = await response.json();
  if (data.error) {
    await checkEmployeeToken(data, abort);
    throw new ApiErrorResponse({
      message: data.msg,
      title: "Oops, houve um problema",
      type: "error",
      method: "service.route.generateRouteOpeningExchangeReport",
    });
  }
  return data.trocaInvoice;
}

export async function generateRouteOpeningInvoiceServer(hashRotaAbertura) {
  const user = await getUser();
  if (!navigator.onLine) {
    throw new Error("Necessário ter internet!");
  }

  const response = await fetchPollyfill(
    `${user.service}/api/abertura/${hashRotaAbertura}/generate/relatorio?token=${user.sessao.token}`
  );
  const { abort } = response;
  const data = await response.json();
  if (data.error) {
    await checkEmployeeToken(data, abort);
    throw new ApiErrorResponse({
      message: data.msg,
      title: "Oops, houve um problema",
      type: "error",
      method: "service.route.generateRouteOpeningInvoiceServer",
    });
  }
  return data.invoiceServer;
}
