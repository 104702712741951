/* eslint-disable eqeqeq */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Typography, IconButton, Grid, Button } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import style from "./css.module.css";
import clsx from "clsx";

const styles = (theme) => ({
  root: {
    backgroud: "rgba(0, 0, 0, 0.2)",
    padding: "0 15px",
  },
  filter: {
    color: "rgba(0, 0, 0, 0.2)",
  },
  filterOn: {
    color: "#0352fc",
  },

  pulseButton: {
    fontWeight: "400",
    textTransform: "uppercase",
    letterSpacing: "2px",
    color: "white",
    background: "#f5005780",
    border: "none",
    cursor: "pointer",
    boxShadow: "0 0 0 0 rgba(245, 0 , 87, .2)",
    // boxShadow: '0 0 0 0 rgba(90, 153, 212, .2)',
    animation: "$pulse 1.5s infinite",
  },
  /* .pulse-button:hover {
    animation: none;
  } */

  "@keyframes pulse": {
    "0%": {
      transform: "scale(.9)",
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 20px rgba(245, 0 , 87, 0)",
    },
    "100%": {
      transform: "scale(.9)",
      boxShadow: "0 0 0 0 rgba(245, 0 , 87, 0)",
    },
  },
  // @-webkit-keyframes .pulse {
  //   0% {
  //     @include transform(scale(.9));
  //   }
  //   70% {
  //     @include transform(scale(1));
  //     box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  //   }
  //     100% {
  //     @include transform(scale(.9));
  //     box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  //   }
  // }
});

class FilterCard extends React.Component {
  static propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    onClickClear: PropTypes.func,
  };
  render() {
    const { classes, isActive, onClick, onClickClear } = this.props;
    const color = clsx({
      [classes.filter]: !isActive,
      [classes.filterOn]: isActive,
    });
    return (
      <>
        <Grid className={classes.root} alignItems="center" container>
          <Grid item xs={6} className={isActive ? style["container"] : null}>
            <Button
              color={isActive ? "secondary" : "default"}
              size="small"
              className={isActive ? classes["pulseButton"] : null}
              variant={"outlined"}
              onClick={isActive ? onClickClear : onClick}
            >
              {isActive ? "Limpar" : "Filtrar"}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              direction="row"
              wrap="nowrap"
            >
              <Grid onClick={onClick} item>
                <Typography className={color}>
                  {isActive ? "Editar filtros" : "Sem filtros"}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton className={color} onClick={onClick}>
                  <FilterListIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withStyles(styles)(FilterCard);
