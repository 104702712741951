import React from "react";
import SyncNotificationCenter from "../../service/notifications/syncNotificationCenter";
import { NotificationSnackbar } from "./notification";

export function FotoDownloadListener() {
  const timeoutId = React.useRef();
  const closeMemo = React.useRef(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [progress, setProgress] = React.useState(0);
  const [status, setStatus] = React.useState("info");
  const [showLoader, setShowLoader] = React.useState(false);

  const handleListennerOpen = React.useCallback(() => {
    //Se o usuario AINDA NÃO pediu para fechar
    if (!closeMemo.current) {
      setOpen(true);
    }
  }, []);

  const handleClose = React.useCallback((event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    if (reason === "userClick") {
      closeMemo.current = true;
    }

    setOpen(false);
  }, []);

  const handleFotoProgress = React.useCallback(
    (eventName, data) => {
      if (eventName !== "foto-download") return;
      const { status, total, download, message } = data;

      handleListennerOpen();
      setStatus(status);
      setMessage(message);

      if (total && download) {
        const percent = (download * 100) / total;
        setProgress(percent);
      }
    },
    [handleListennerOpen]
  );

  const handleClientBackgroundSync = React.useCallback(
    (eventName, data) => {
      if (eventName !== "client-background-sync") return;
      closeMemo.current = false; //Força  a exibição di snack;
      const { status, message } = data;

      handleListennerOpen();
      setShowLoader(false);
      setStatus(status);
      setMessage(message);
    },
    [handleListennerOpen]
  );

  const handleSyncSingleCliente = React.useCallback(
    (eventName, data) => {
      if (eventName !== "single-client-sync") return;
      closeMemo.current = false; //Força  a exibição di snack;
      const { message, status } = data;

      handleListennerOpen();
      setShowLoader(false);
      setStatus(status);
      setMessage(message);
    },
    [handleListennerOpen]
  );

  React.useEffect(() => {
    const sub1 = SyncNotificationCenter.instance.subscribe(handleFotoProgress);
    const sub2 = SyncNotificationCenter.instance.subscribe(
      handleClientBackgroundSync
    );
    const sub3 = SyncNotificationCenter.instance.subscribe(
      handleSyncSingleCliente
    );
    return () => {
      sub1();
      sub2();
      sub3();
    };
  }, [handleFotoProgress, handleClientBackgroundSync, handleSyncSingleCliente]);

  React.useEffect(() => {
    if (["success", "error"].includes(status)) {
      setOpen(true);
      timeoutId.current = setTimeout(() => {
        setOpen(false);
      }, 5000);
      return () => clearTimeout(timeoutId.current);
    }
  }, [status]);

  React.useEffect(() => clearTimeout(timeoutId.current), []);

  return (
    <NotificationSnackbar
      open={open}
      message={message}
      severity={status}
      progression={progress}
      handleClose={handleClose}
      showProgressLoader={showLoader}
    />
  );
}
