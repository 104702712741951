export function builderFilterFunction(filters) {
  return (element, index) => {
    const fields = Object.keys(filters);
    if (!fields.length) return true;
    let isValid = true;
    for (const key of fields) {
      const comparator = filters[key];
      if (typeof comparator === "function") {
        isValid = isValid && comparator(element);
      } else {
        isValid = isValid && filters[key] == element[key];
      }
    }
    return isValid;
  };
}
