import DataAccess from "../DataAccessStrategy";

export default class CestaItens extends DataAccess {
  constructor() {
    super("CestaItens", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      quantidade: Number,
      CestaId: Number,
      ProdutoId: Number,
      CestumId: Number,
    });
    this.getByCestumId = this.getByCestumId.bind(this);
  }

  async getByCestumId(id) {
    return await this.model.where("CestumId").equals(id).toArray();
  }
}
