import AdiantamentoFuncionario from "./AdiantamentoFuncionario.js";
import ClienteCampoRecomendavel from "./ClienteCampoRecomendavel.js";
import Feriado from "./Feriado.js";
import Bairro from "./Bairro.js";
import Cliente from "./Cliente.js";
import FormaPagamento from "./FormaPagamento.js";
import CachedError from "./CachedError.js";
import ClienteRecebimento from "./ClienteRecebimento.js";
import Funcionario from "./Funcionario.js";
import CestaItens from "./CestaItens.js";
import ClienteTelefone from "./ClienteTelefone.js";
import ManutencaoVeiculo from "./ManutencaoVeiculo.js";
import Cesta from "./Cesta.js";
import ClienteTrocaAvulso from "./ClienteTrocaAvulso.js";
import MeioPagamento from "./MeioPagamento.js";
import CestaVenda from "./CestaVenda.js";
import ClienteVenda from "./ClienteVenda.js";
import Produto from "./Produto.js";
import Cidade from "./Cidade.js";
import Combustivel from "./Combustivel.js";
import ProdutoVenda from "./ProdutoVenda.js";
import ClienteAgendamento from "./ClienteAgendamento.js";
import DespesaCombustivelVeiculo from "./DespesaCombustivelVeiculo.js";
import RotaValidator from "./RotaValidator.js";
import ClienteBairro from "./ClienteBairro.js";
import DespesaRota from "./DespesaRota.js";
import Validator from "./Validator.js";
import ClienteCampoObrigatorio from "./ClienteCampoObrigatorio.js";
import DespesaVeiculo from "./DespesaVeiculo.js";
import Veiculo from "./Veiculo.js";
import ClienteMovimentacaoCancelada from "./ClienteMovimentacaoCancelada";
import DespesaDeleted from "./DespesaDeleted";
import Documento from "./Documento";
import ClienteCampoEdit from "./ClienteCampoEdit";
import ClienteEdit from "./ClienteEdit";
import ClienteDocumento from "./ClienteDocumento";
import ClienteDiaria from "./ClienteDiaria";
import ClienteFoto from "./ClienteFoto";

export {
  Cesta,
  Bairro,
  Cidade,
  Cliente,
  Produto,
  Feriado,
  Veiculo,
  Documento,
  Validator,
  CestaVenda,
  CestaItens,
  ClienteEdit,
  ClienteFoto,
  CachedError,
  DespesaRota,
  Combustivel,
  Funcionario,
  ProdutoVenda,
  ClienteVenda,
  ClienteBairro,
  ClienteDiaria,
  RotaValidator,
  MeioPagamento,
  DespesaVeiculo,
  DespesaDeleted,
  FormaPagamento,
  ClienteTelefone,
  ClienteDocumento,
  ClienteCampoEdit,
  ManutencaoVeiculo,
  ClienteTrocaAvulso,
  ClienteRecebimento,
  ClienteAgendamento,
  ClienteCampoObrigatorio,
  AdiantamentoFuncionario,
  ClienteCampoRecomendavel,
  DespesaCombustivelVeiculo,
  ClienteMovimentacaoCancelada,
};

const models = {
  Cesta: new Cesta(),
  Bairro: new Bairro(),
  Cidade: new Cidade(),
  Cliente: new Cliente(),
  Produto: new Produto(),
  Feriado: new Feriado(),
  Veiculo: new Veiculo(),
  Documento: new Documento(),
  Validator: new Validator(),
  CestaVenda: new CestaVenda(),
  CestaItens: new CestaItens(),
  ClienteEdit: new ClienteEdit(),
  CachedError: new CachedError(),
  DespesaRota: new DespesaRota(),
  Combustivel: new Combustivel(),
  Funcionario: new Funcionario(),
  ClienteFoto: new ClienteFoto(),
  ProdutoVenda: new ProdutoVenda(),
  ClienteVenda: new ClienteVenda(),
  ClienteBairro: new ClienteBairro(),
  ClienteDiaria: new ClienteDiaria(),
  RotaValidator: new RotaValidator(),
  MeioPagamento: new MeioPagamento(),
  DespesaVeiculo: new DespesaVeiculo(),
  DespesaDeleted: new DespesaDeleted(),
  FormaPagamento: new FormaPagamento(),
  ClienteTelefone: new ClienteTelefone(),
  ClienteDocumento: new ClienteDocumento(),
  ClienteCampoEdit: new ClienteCampoEdit(),
  ManutencaoVeiculo: new ManutencaoVeiculo(),
  ClienteTrocaAvulso: new ClienteTrocaAvulso(),
  ClienteRecebimento: new ClienteRecebimento(),
  ClienteAgendamento: new ClienteAgendamento(),
  ClienteCampoObrigatorio: new ClienteCampoObrigatorio(),
  AdiantamentoFuncionario: new AdiantamentoFuncionario(),
  ClienteCampoRecomendavel: new ClienteCampoRecomendavel(),
  DespesaCombustivelVeiculo: new DespesaCombustivelVeiculo(),
  ClienteMovimentacaoCancelada: new ClienteMovimentacaoCancelada(),
};

export default models;
