import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  containerCards: {
    zIndex: 3,
  },
  containerBlock: {
    backgroundColor: "#fff",
    paddingBottom: "10vh",
  },
  block: {
    paddingTop: "2vh",
    overflow: "hidden",
    position: "relative",
    "& .MuiTypography-body1": {
      fontFamily: "Roboto, sans-serif",
    },
    "& h6": {
      fontFamily: "Roboto, sans-serif",
    },
  },
  divider: {
    width: "100%",
    backgroundColor: "#f5f5f5",
    height: "3vh",
  },
  divider_title: {
    width: "100%",
    height: "1px",
    backgroundColor: "grey",
  },
  title: {
    fontSize: "1rem",
    fontFamily: "Roboto, sans-serif",
    fontWeigth: "bold",
  },
}));
