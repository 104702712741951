import React, { useState } from "react";
import Skeleton from "../Skeleton/CardSkeleton";
import Card from "../CardComponents/CardOfClient/CardsDailyList/ClientCard";

export default function ClientCardWraper(props) {
  const [cardState, setCardState] = useState("minicard");

  const setOnChangeView = function (state) {
    setCardState(state);
  };

  return (
    <>
      {props.isLoading ? (
        <Skeleton />
      ) : (
        <Card
          key={props.Client.hash}
          Client={props.Client}
          cardState={cardState}
          onChangeView={setOnChangeView}
          onClickRepass={props.onClickRepass}
          onClickPular={props.onClickPular}
          onSync={props.onSync}
          repasseBtn={props.repasseBtn}
          pularBtn={props.pularBtn}
          veacoBtn={props.veacoBtn}
        />
      )}
    </>
  );
}
