import React from "react";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "../../components/HeaderForAdd/Header";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CKEditor from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import Cache from "../../components/support/Cache";
import { SuportBottomGroupButton } from "../../components/support/SuportBottomGroupButton";

const applyStyles = withStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowY: "scroll",
  },
  iframeClass: {
    width: "100%",
    border: 0,
  },
  footer: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  buttonGroup: {
    color: "red",
    "& > a span": {
      textAlign: "center",
    },
  },
}));

class HelperContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlContent: "",
      isLoading: true,
      notFound: false,
    };
    this.fetchOptions = {
      url: "https://cesta-central-maximus.herokuapp.com/empresa/faqs?token=DF74748A72FEE776A37BD566BB3CB&type=PAP",
      method: "GET",
    };

    this.getInfoFromContent = this.getInfoFromContent.bind(this);
    this.populateContent = this.populateContent.bind(this);
  }

  renderLoaderPage() {
    return <CircularProgress />;
  }

  getInfoFromContent(pages) {
    if (Array.isArray(pages) && pages.length) {
      const pageToRender = pages.find((page) => {
        return (
          page.pageUrl.replace("/", "") == this.props.match.params.pageName
        );
      });
      if (pageToRender) {
        const { content, ...pageProps } = pageToRender;
        this.setState({
          isLoading: false,
          htmlContent: content,
          ...pageProps,
        });
      } else {
        this.setState({ isLoading: false, notFound: true });
      }
    }
  }

  populateContent(data) {
    this.getInfoFromContent(data.data.faqs);
  }

  handleErrorCache() {
    console.log(arguments);
  }

  renderNotFoundPage() {
    return <div>Não foi encontrado o conteudo de suporte desejado</div>;
  }

  render() {
    const { classes } = this.props;

    if (this.state.notFound) {
      return this.renderNotFoundPage();
    }

    return (
      <>
        <Header
          icon={<ChevronLeftIcon style={{ fontSize: 35, display: "inline" }} />}
        >
          Suporte
        </Header>
        <div className={classes.root} id="ckeditorDiv">
          <CKEditor
            editor={InlineEditor}
            data={this.state.htmlContent}
            disabled
          />
        </div>
        <SuportBottomGroupButton />
        <Cache
          fetchOptions={this.fetchOptions}
          handleSuccess={this.populateContent}
          handleError={this.handleErrorCache}
          name="support-pages"
          timeToLive={4}
        />
      </>
    );
  }
}

export default applyStyles(HelperContent);
