import React from "react";
import {
  withStyles,
  Divider,
  Grid,
  Typography,
  Dialog,
  Button,
} from "@material-ui/core";
import Header from "../../components/HeaderForAdd/Header";
import CloseIcon from "@material-ui/icons/Close";
import ClientMoneyBalance from "../../components/Receivement/ClientMoneyBalance";
import PaymentForm from "../../components/Sale/PaymentForm";
import PaymentMethods from "../../components/Sale/PaymentMethods";
import Moeda from "../../utils/Moeda";
import History from "../../router/History";
import moment from "moment";
import ProductsSection from "../../components/Sale/SaleProductsSection";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { SaleContext } from "../../contexts/SaleContext";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import { ReceivementContext } from "../../contexts/ReceivementContext";
import { ExchangeContext } from "../../contexts/ExchangeContext";
import { ProductContext } from "../../contexts/ProductsContext";
import { UserContext } from "../../contexts/UserContext";
import { SchedulingsContext } from "../../contexts/SchedulingsContext";
import ExchangeLogic from "../../components/Exchanges/ExchangeLogic";
import { getEnvVar } from "../../service/config";
import CardSwitch from "../../components/CardComponents/CardSwitch/Card";
import ProductsForm from "../../components/Products/ProductsForm2";
import Backdrop from "../../components/Dialogs/BackdropLoader";
import { AppStorageError } from "../../repository/errorsModels";
import Signature from "../../components/Signature/Signature";

const styles = (theme) => ({
  root: {
    backgroundColor: "#f5f5f5",
  },
  divider: {
    width: "100%",
    height: "2px",
  },
  section: {
    marginBottom: "25px",
    background: "rgb(255, 255, 255)",
    padding: "10px",
  },
  paymentMethodIcons: {
    color: "#999999",
  },

  inputValueClass: {
    padding: "10px 0px 5px 0px",
    lineHeight: 1,
    color: "#696969",
    fontSize: "3rem",
    fontFamily: "Roboto,sans-serif",
    "& .currency": {
      fontSize: "1.3rem",
      fontWeight: 300,
    },
  },
  totalValueInput: {
    fontSize: "2.6rem",
    color: "#696969",
    fontWeight: "300",
    textAlign: "center",
    padding: 0,
  },
  saleTotalValue: {
    fontSize: "1.4rem",
    fontWeight: "300",
    textTransform: "uppercase",
    textAlign: "center",
  },
  clientBalance: {
    color: "#868686",
    fontSize: "1.2rem",
    fontFamily: "Roboto,sans-serif",
    fontWeight: 300,
  },
  exchangeBtn: {
    padding: 5,
    color: "#fff",
    minWidth: "0px",
    backgroundColor: "#35A6F1",
  },
});

/**
 * Tela de Venda
 */
class SaleComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      saleData: {
        movementTotal: { value: null, error: false },
        inputValue: { value: null, error: false },
        finalBalance: { value: 0, error: false },
        nextDate: { value: null, error: false },
        receiptNumber: { value: null, error: false },
        note: { value: null, error: false },
        payment: { value: null, error: false },
        paymentType: { value: null, error: false },
        discount: { value: 0, error: false },
        saleProducts: [],
        exchange: {
          itemsEnter: [],
          itemsOut: [],
        },
      },
      sigImage: null,
      isLoading: false,
      avaliableProducts: [],
      canInsertTotalValue: true,
      canCreateProduct: false,
      client: {},
      allProductsWQ: [],
      itemDetailsData: null,
      showAddNewItemDialog: false,
      loadingAllProducts: true,
      maxDiscountValue: 0,
      exchangeModal: false,
      showProductsForm: false,
      routeOpening: {},
      envVar: {
        ACEITA_TROCA_PRODUTO: false,
        ACEITA_VENDA_VEACO: false,
        IS_BITBOX_EMPLOYEE: true,
        SALDO_DESATIVAR_VENDA: false,
      },
    };
    this.refDate = React.createRef();
    this.totalValueRef = React.createRef();
    this.inputValueRef = React.createRef();
    this.maxDiscountRef = React.createRef();

    this.toggleExchangeModal = this.toggleExchangeModal.bind(this);
    this.getInitialData = this.getInitialData.bind(this);
    this.getClientData = this.getClientData.bind(this);
    this.finalBalanceIsPositive = this.finalBalanceIsPositive.bind(this);
    this.showDialogReceivementDate = this.showDialogReceivementDate.bind(this);
    this.showDialogBalance = this.showDialogBalance.bind(this);
    this.calculateClientBalance = this.calculateClientBalance.bind(this);
    this.setSaleData = this.setSaleData.bind(this);
    this.applyEnvVarRules = this.applyEnvVarRules.bind(this);
    this.checkFields = this.checkFields.bind(this);
    this.saveData = this.saveData.bind(this);
    this.buildExchangeInfo = this.buildExchangeInfo.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.calculateSaleTotalValue = this.calculateSaleTotalValue.bind(this);
    this.handleAddProduct = this.handleAddProduct.bind(this);
    this.handleDecreaseProductQnt = this.handleDecreaseProductQnt.bind(this);
    this.handleToggleItemDetails = this.handleToggleItemDetails.bind(this);
    this.handleToggleNewProductDialog =
      this.handleToggleNewProductDialog.bind(this);
    this.handleToggleEditProduct = this.handleToggleEditProduct.bind(this);
    this.handleSaveProduct = this.handleSaveProduct.bind(this);
    this.handleExchangeChange = this.handleExchangeChange.bind(this);
    this.onSaleProductsChange = this.onSaleProductsChange.bind(this);
    this.getAvaliableProductsOnSale =
      this.getAvaliableProductsOnSale.bind(this);
    this.handleToggleSignature = this.handleToggleSignature.bind(this);
    this.handleSignatureImage = this.handleSignatureImage.bind(this);
  }

  /**Função executada na montagem do componente.
   * @function
   */
  async componentDidMount() {
    await this.getClientData();
    await this.setEnvVar();
    await this.getInitialData();
  }
  //isBitboxEmployee
  async setEnvVar() {
    const [ACEITA_TROCA_PRODUTO, ACEITA_VENDA_VEACO, SALDO_DESATIVAR_VENDA] =
      await Promise.all([
        getEnvVar("ACEITA_TROCA_PRODUTO"),
        getEnvVar("ACEITA_VENDA_VEACO"),
        getEnvVar("SALDO_DESATIVAR_VENDA"),
      ]);

    if (!ACEITA_VENDA_VEACO && this.state.client.veaco) {
      this.props.dialogContext.addDialog({
        title: "Venda não autorizada",
        message: "Venda para inadimplentes não é permitida",
        type: "warning",
      });
      return History.goBack();
    }

    const isValidBalance = this.applyEnvVarRules(SALDO_DESATIVAR_VENDA);
    if (!isValidBalance) return History.goBack();

    this.setState({
      canInsertTotalValue: !this.props.isBitboxEmployee,
      envVar: {
        ACEITA_TROCA_PRODUTO,
        ACEITA_VENDA_VEACO,
        SALDO_DESATIVAR_VENDA,
        IS_BITBOX_EMPLOYEE: this.props.isBitboxEmployee,
      },
    });
  }

  handlePaymentType(e) {
    console.log("Forma de pagamento selecionada:", e);
    this.setState({ paymentType: e });
    this.props.setData("paymentType", e);
  }

  handlePaymentTypeChange = (paymentType) => {
    this.props.onPaymentTypeChange(paymentType);
  };

  toggleExchangeModal() {
    if (!this.state.saleData.saleProducts.length) {
      this.props.dialogContext.addDialog({
        message:
          "Adicone ao menos um produto a sua venda antes de realizar uma troca.",
        title: "Não é possivel abrir a tela de trocas!",
        type: "warning",
      });
    } else {
      this.setState({ exchangeModal: !this.state.exchangeModal });
    }
  }

  async getInitialData() {
    try {
      let allProducts = await this.context.getAllBaskets();
      let allProductsWQ = allProducts.map((item) => {
        let itemsQnt =
          this.state.allProductsWQ &&
          this.state.allProductsWQ.find(
            (itemwq) => itemwq.item.hash === item.hash
          );
        let itemWQ = {
          quantidade: itemsQnt ? itemsQnt.quantidade : 0,
          item: { ...item },
        };
        return itemWQ;
      });
      let saleData = { ...this.state.saleData };
      saleData.saleProducts = allProductsWQ.filter(
        (prod) => prod.quantidade > 0
      );

      const routeOpening = await this.props.saleContext.getRouteOpeningInfo();
      const routeInfo = await this.props.saleContext.getMainRouteInfo();
      this.setState(
        {
          allProductsWQ,
          loadingAllProducts: false,
          canCreateProduct: !!routeInfo.aceitaProdutoNovo,
          saleData,
          routeOpening,
          // itemDetailsData
        },
        () => {
          this.calculateSaleTotalValue();
          this.handleToggleItemDetails();
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  /**Função responsável por pegar os dados do cliente no banco.
   * @function
   */
  async getClientData() {
    try {
      let client = await this.props.saleContext.getClientByHash(
        this.props.hash
      );
      let saleData = { ...this.state.saleData };
      saleData.finalBalance.value = client.currentBalance;
      this.setState({ client: client, saleData: saleData });
    } catch (e) {
      console.log(e);
    }
  }

  /**Função onde é feita a comparação se o saldo final do cliente será quitado ou não.
   * @function
   * @returns {boolean} Retorna true o saldo ficar quitado e false caso não fique.
   */
  finalBalanceIsPositive() {
    return Moeda.create(
      this.state.saleData.finalBalance.value
    ).isGreaterOrEqualThan(0);
  }

  /**Função onde retorna um dialog informando que já existe um agendamento para tal data.
   * @function
   * @returns {dialog} Retorna um dialogo
   */
  async showDialogReceivementDate() {
    if (
      !this.finalBalanceIsPositive() &&
      (await this.props.saleContext.getSchedulingsByDate(
        this.state.saleData.nextDate.value,
        this.state.client.id
      ))
    )
      return this.props.dialogContext.addDialog({
        message: `Já existe um agendamento para
                ${moment(this.state.saleData.nextDate.value).format(
                  "DD/MM/YYYY"
                )}
                ,gostaria de continuar com esta data?`,
        title: "Próximo recebimento",
        handleConfirm: () => {
          this.props.dialogContext.popDialog();
        },
        handleClose: () => {
          this.props.dialogContext.popDialog();
          this.refDate.current.click();
        },
        type: "warning",
        okText: "Usar esta data",
        cancelText: "Marcar outra data",
      });
  }

  /**Função um dialogo informando que o saldo do cliente está positivo.
   * @function
   * @returns {dialog} Retorna um dialogo
   */
  showDialogBalance() {
    if (this.finalBalanceIsPositive())
      // return true
      this.props.dialogContext.addAsyncDialog({
        message: "A data fará refêrencia para uma nova próxima venda.",
        title: "Saldo do cliente está positivo!",
        handleConfirm: () => {
          this.props.dialogContext.popDialog();
        },
        hasCloseButton: false,
        type: "info",
      });
  }

  /**Função onde é calculado o saldo final do cliente.
   * @function
   */
  calculateClientBalance() {
    let saleData = { ...this.state.saleData };
    let finalBalance = Moeda.create(this.state.client.currentBalance)
      .subtract(this.state.saleData.movementTotal.value)
      .mount();
    if (this.state.saleData.paymentType.value == "Entrada + Parcelado") {
      finalBalance = Moeda.create(this.state.client.currentBalance)
        .subtract(this.state.saleData.movementTotal.value)
        .add(this.state.saleData.inputValue.value)
        .mount();
    } else if (this.state.saleData.paymentType.value == "A vista") {
      finalBalance = this.state.client.currentBalance;
    }

    saleData.finalBalance.value = finalBalance;
    this.setState({ saleData: saleData });
  }

  /**Função que armazena os valores digitados no formulário.
   * @function
   */
  setSaleData(name, data) {
    let saleData = { ...this.state.saleData };

    saleData[name].value = data;

    if (name == "inputValue") {
      if (
        this.state.canInsertTotalValue &&
        this.state.saleData.paymentType.value != "Entrada + Parcelado"
      ) {
        saleData["inputValue"].value = Moeda.create(data).mount();
        if (this.state.saleData.paymentType.value == "A vista")
          saleData.maxDiscountValue = Moeda.create(data).mount();
      }
    } else if (name == "movementTotal") {
      if (
        this.state.canInsertTotalValue &&
        this.state.saleData.paymentType.value != "Entrada + Parcelado"
      ) {
        // Set both movementTotal and inputValue with the same value
        // Also set maxDiscount allowed to current movementTotal
        saleData["movementTotal"].value = Moeda.create(data).mount();
        saleData["inputValue"].value = Moeda.create(data).mount();
        if (this.state.saleData.paymentType.value == "A vista")
          saleData.maxDiscountValue = Moeda.create(data).mount();
      } else if (
        !this.state.canInsertTotalValue &&
        this.state.saleData.paymentType.value == "Entrada + Parcelado"
      ) {
        saleData["inputValue"].value = Moeda.create(
          this.state.saleData.inputValue.value
        ).mount();
      }
    } else if (name == "paymentType") {
      if (data != "Entrada + Parcelado") {
        saleData["inputValue"].value = Moeda.create(
          this.state.saleData.movementTotal.value
        ).mount();
      } else {
        saleData["inputValue"].value = Moeda.create(0).mount();
      }
    } else if (name == "saleProducts") {
      // Force inputValue to remain what it was
      saleData["inputValue"].value = Moeda.create(
        this.state.saleData.inputValue.value
      ).mount();
    }

    this.setState({ saleData: saleData }, () => {
      this.calculateClientBalance();
      this.checkFields();
    });
  }

  applyEnvVarRules(balanceToUse) {
    let valueToCompare = balanceToUse
      ? balanceToUse
      : this.state.envVar.SALDO_DESATIVAR_VENDA;

    const finalBalance = Moeda.create(
      Math.abs(this.state.saleData.finalBalance.value)
    );

    const maxDebt = Moeda.create(Math.abs(valueToCompare));
    const shouldIgnore = maxDebt.isEqual(0);
    if (shouldIgnore) return true;

    if (finalBalance.isGreaterThan(maxDebt)) {
      this.props.dialogContext.addDialog({
        message: `Não é permitido a venda para clientes com debito maior que ${maxDebt.format()}`,
        title: `Atenção`,
        type: "warning",
      });
      return false;
    }
    return true;
  }

  calculateProductsSale(saleId) {
    const retirados = [...this.state.saleData.exchange.itemsOut];
    const adicionados = [...this.state.saleData.exchange.itemsEnter];
    const avaliableProducts = [...this.state.avaliableProducts];

    const productsOfSale = avaliableProducts.map((p) => {
      const findProduct = (el) => el.id == p.id;
      const productToRemove = retirados.find(findProduct);
      const amountToRemove =
        (productToRemove && productToRemove.quantidade) || 0;
      return {
        valor: p.valor,
        ProdutoId: p.id,
        ClienteVendaId: saleId,
        identificador: p.identificador,
        quantidade: p.quantidade - amountToRemove,
        ClienteId: this.state.client.id,
        shouldSync: true,
      };
    });

    adicionados.forEach((el) => {
      const product = productsOfSale.find((p) => p.ProdutoId == el.id);
      if (product) {
        product.quantidade += el.quantidade;
      } else {
        productsOfSale.push({
          valor: el.valor,
          ProdutoId: el.id,
          ClienteVendaId: saleId,
          identificador: el.identificador,
          quantidade: el.quantidade,
          ClienteId: this.state.client.id,
          shouldSync: true,
        });
      }
    });

    const resultSet = productsOfSale.filter(
      (product) => product.quantidade > 0
    );
    return resultSet;
  }

  /**Função que valida os campos com base nas regras de negócio.
   * @function
   */
  checkFields() {
    let check = true;
    let saleData = { ...this.state.saleData };
    // If client's balance is negative
    if (!this.finalBalanceIsPositive()) {
      if (
        this.state.saleData.nextDate.value === null ||
        this.state.saleData.nextDate.value === undefined
      ) {
        saleData.nextDate.error = true;
        check = false;
      } else {
        saleData.nextDate.error = false;
      }
    } else {
      saleData.nextDate.error = false;
    }

    if (
      this.state.saleData.movementTotal.value === null ||
      Moeda.create(this.state.saleData.movementTotal.value).isZero()
    ) {
      saleData.movementTotal.error = true;
      check = false;
    } else {
      saleData.movementTotal.error = false;
    }
    if (
      this.state.saleData.inputValue.value === null ||
      Moeda.create(this.state.saleData.inputValue.value).isZero()
    ) {
      saleData.inputValue.error = true;
      check = false;
    } else {
      saleData.inputValue.error = false;
    }
    if (
      this.state.saleData.paymentType.value != "Parcelado" &&
      (this.state.saleData.payment.value === null ||
        this.state.saleData.payment.value === undefined)
    ) {
      saleData.payment.error = true;
      check = false;
    } else {
      saleData.payment.error = false;
    }
    if (
      Moeda.create(this.state.saleData.discount.value).isGreaterThan(
        this.state.maxDiscountValue
      )
    ) {
      saleData.discount.error = true;
      check = false;
    } else {
      saleData.discount.error = false;
    }

    // if(!check)
    this.setState({ saleData: saleData });
    return check;
  }

  /**Função que salva os dados no banco e redirecionado para a página que irá gerar o relatório de recebimento.
   * @function
   */
  async saveData() {
    const saleData = {
      id: window.DATABASE.generateUniqId(),
      hash: `${window.DATABASE.generateUniqId()}`,
      clientHash: this.state.client.hash,
      valor: this.state.saleData.movementTotal.value,
      formaPagamento: this.state.saleData.paymentType.value,
      ClienteId: this.state.client.id,
      documentoFisico: this.state.saleData.receiptNumber.value,
      dataRecebimento: this.state.saleData.nextDate.value,
      houveTroca: !!this.state.saleData.exchange.scheduleDate,
      desconto: this.state.saleData.discount.value,
      observacao: this.state.saleData.note.value,
      CestaVendas: this.state.saleData.saleProducts,
      assinatura: this.state.sigImage,
    };
    saleData.ProdutoVendas = this.calculateProductsSale(saleData.id);

    const recebimento = {
      documentoFisico: this.state.saleData.receiptNumber.value,
      valor: this.state.saleData.inputValue.value,
      meioPagamento: this.state.saleData.payment.value,
      recibo: this.state.saleData.receiptNumber.value,
      historico: this.state.saleData.note.value,
      ClienteId: this.state.client.id,
      ClienteVendaId: saleData.id,
      vendaHash: saleData.hash,
    };

    saleData.receivement = recebimento;

    if (this.finalBalanceIsPositive()) {
      saleData.tipo = "Venda";
    } else {
      saleData.tipo = "Recebimento";
    }

    try {
      const { data: exchangeData, receivingDate } = this.buildExchangeInfo(
        saleData.id
      );

      await this.props.schedulingsContext.validateScheduleDate(receivingDate);
      await this.props.schedulingsContext.validateScheduleDate(
        saleData.dataRecebimento
      );
      await this.props.saleContext.createSale(saleData);

      if (saleData.formaPagamento.toLowerCase() !== "parcelado") {
        await this.props.receivementContext.createReceivement(
          recebimento,
          this.props.hash
        );
      }

      if (saleData.houveTroca) {
        await this.props.exchangeContext.createExchange(
          this.state.client.hash,
          exchangeData,
          receivingDate
        );
      }

      this.props.saleContext.clientBackgroundSync();

      this.props.dialogContext.addDialog({
        message: "A venda foi realizada com sucesso!",
        title: "Concluído",
        hasCloseButton: false,
        type: "success",
      });

      History.push(`/sale/report/${this.props.hash}/${saleData.hash}`, {
        ...History.location.state,
        prevUrl: History.location.pathname,
      });
    } catch (error) {
      console.error(error);
      throw new AppStorageError({
        message: error.message,
        title: "Falha ao lançar recebimento!",
        type: "error",
        method: "save receivement",
        error,
      });
    }
  }

  buildExchangeInfo(saleId) {
    const calculateValue = (memo, product) =>
      memo.add(product.valor).mul(product.quantidade);
    const saldoAdicionar = this.state.saleData.exchange.itemsEnter.reduce(
      calculateValue,
      Moeda.create(0)
    );
    const saldoRetirar = this.state.saleData.exchange.itemsOut.reduce(
      calculateValue,
      Moeda.create(0)
    );

    const mapToExchangeItem = (productExchange) => ({
      ProdutoId: productExchange.id,
      quantidade: productExchange.quantidade,
    });

    return {
      data: {
        saldoRetirar: saldoRetirar.mount(),
        saldoAdicionar: saldoAdicionar.mount(),
        ClienteVendaId: saleId,
        retirados: this.state.saleData.exchange.itemsOut.map(mapToExchangeItem),
        adicionados:
          this.state.saleData.exchange.itemsEnter.map(mapToExchangeItem),
      },
      receivingDate: this.state.saleData.exchange.scheduleDate,
    };
  }

  async handleSave() {
    this.setState({ isLoading: true });
    try {
      const noErrors = this.checkFields();
      const isEnvVarAllow = this.applyEnvVarRules();
      if (noErrors && isEnvVarAllow) {
        await this.saveData();
      } else if (this.state.saleData.movementTotal.error) {
        this.props.dialogContext.addDialog({
          title: "Campos inválidos!",
          message: (
            <Typography>
              Insira um {this.state.canInsertTotalValue ? "valor" : "produto"}{" "}
              para esta venda.
            </Typography>
          ),
          type: "warning",
        });
        return;
      } else {
        this.props.dialogContext.addDialog({
          title: "Campos inválidos!",
          message: (
            <Typography>
              Algum campo permanece inválido.
              <br />
              Por favor, verifique o formulário e tente novamente.
            </Typography>
          ),
          type: "warning",
        });
        return;
      }
    } catch (err) {
      console.log(err);

      this.props.dialogContext.addDialog({
        title: "Falha ao lançar venda",
        message: <Typography>{err.message}</Typography>,
        type: "error",
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  calculateSaleTotalValue() {
    const { temp_total, maxDiscount } = this.state.saleData.saleProducts.reduce(
      (memo, item) => {
        // Calculate saleTotalValue based on the products' price being sold
        memo.temp_total = Moeda.create(item.item.valor)
          .mul(item.quantidade)
          .add(memo.temp_total)
          .mount();
        // Calculate maximum discount as well
        memo.maxDiscount = Moeda.create(item.item.maxDesconto)
          .add(memo.maxDiscount)
          .mount();

        return memo;
      },
      {
        temp_total: 0,
        maxDiscount: 0,
      }
    );

    let temp_saleData = { ...this.state.saleData };

    temp_saleData.movementTotal.value = temp_total;
    // Set input value to temp_total only if user is inserting products and paymentType is not Entrada + Parcelado
    temp_saleData.inputValue.value =
      !this.state.canInsertTotalValue &&
      this.state.saleData.paymentType.value != "Entrada + Parcelado"
        ? temp_total
        : this.state.saleData.inputValue.value;
    this.setState(
      {
        saleData: temp_saleData,
        maxDiscountValue: maxDiscount,
      },
      this.calculateClientBalance
    );
    return;
  }

  async handleAddProduct(product) {
    // if(this.state.saleData.exchange.itemsOut)
    // const resp = await this.props.dialogContext.addAsyncDialog({
    //   message: "Caso retire um produto sua troca pode ser invalidada",
    //   title: "Tem certeza?"
    // })
    let temp_saleProducts = [...this.state.saleData.saleProducts];
    let temp_allProductsWQ = [...this.state.allProductsWQ];
    let temp_itemDetailsData = this.state.itemDetailsData
      ? { ...this.state.itemDetailsData }
      : null;

    // Update state.allProductsWQ and state.itemDetailsData arrays
    let prodI = temp_allProductsWQ.findIndex(
      (i) => product.item && product.item.hash == i.item.hash
    );
    if (prodI != -1) {
      temp_allProductsWQ[prodI].quantidade++;
      temp_itemDetailsData && temp_itemDetailsData.quantidade++;
    }

    // Push all products that have their quantity >= 1 to the saleProducts array
    temp_saleProducts = temp_allProductsWQ.filter(
      (item) => item.quantidade >= 1
    );

    this.setState(
      {
        allProductsWQ: temp_allProductsWQ,
        saleData: { ...this.state.saleData, saleProducts: temp_saleProducts },
        itemDetailsData: temp_itemDetailsData,
      },
      () => {
        this.calculateSaleTotalValue();
        this.getInitialData();
      }
    );
    return;
  }

  handleDecreaseProductQnt(product, canDelete = false) {
    let temp_saleProducts = [...this.state.saleData.saleProducts];
    let temp_allProductsWQ = [...this.state.allProductsWQ];
    let temp_itemDetailsData = this.state.itemDetailsData
      ? { ...this.state.itemDetailsData }
      : null;

    // Find product in allProductsWQ array to change its quantity
    let prodI = temp_allProductsWQ.findIndex(
      (i) => product.item && product.item.hash == i.item.hash
    );

    // Update item's quantity in state.saleData.saleProducts array
    if (canDelete) {
      temp_allProductsWQ[prodI].quantidade = 0;
      temp_itemDetailsData = null;
    } else {
      temp_allProductsWQ[prodI].quantidade--;
      // Update state.itemDetailsData array
      temp_itemDetailsData && temp_itemDetailsData.quantidade--;
    }

    // Update item's quantity in state.allProductsWQ array
    temp_saleProducts = temp_allProductsWQ.filter(
      (item) => item.quantidade >= 1
    );

    this.setState(
      {
        allProductsWQ: temp_allProductsWQ,
        saleData: { ...this.state.saleData, saleProducts: temp_saleProducts },
        itemDetailsData: temp_itemDetailsData,
      },
      () => {
        this.calculateSaleTotalValue();
        this.getInitialData();
      }
    );
    return;
  }

  handleToggleItemDetails(product) {
    if (this.state.itemDetailsData == null)
      this.setState({ itemDetailsData: product });
    else this.setState({ itemDetailsData: null });
  }

  handleToggleNewProductDialog() {
    this.setState({ showAddNewItemDialog: !this.state.showAddNewItemDialog });
  }

  handleToggleEditProduct(product) {
    this.setState({ showProductsForm: !this.state.showProductsForm });
  }

  async handleSaveProduct(product) {
    if (!!product.items) {
      // Product is being edited
      await this.props.productsContext.updateBasket(product);
      await this.getInitialData();
      this.handleToggleEditProduct();
    } else {
      // Transform the data that will be sent to context
      product = {
        identificador: product.name.value,
        valor: product.salePrice.value,
        maxDiscount: Moeda.create(0).format(),
        ideal: product.contaNoIdeal,
      };
      const newItem = await this.props.productsContext.createBasket(
        product,
        true
      );
      await this.getInitialData();
      this.handleAddProduct({ quantidade: 0, item: newItem });
      this.handleToggleNewProductDialog();
    }
  }

  handleExchangeChange(exchangeData) {
    this.setState({
      saleData: { ...this.state.saleData, exchange: exchangeData },
      exchangeModal: false,
    });
  }

  onSaleProductsChange() {
    const { itemsEnter, itemsOut } = this.state.saleData.exchange;
    const { avaliableProducts } = this.state;
    if (!itemsEnter.length && !itemsOut.length && !avaliableProducts.length)
      return;

    const isValid = this.validateExchange();

    if (!isValid) {
      this.props.dialogContext.addDialog({
        message:
          "Você retirou produtos que constavam na troca, ela foi invalidada",
        title: "Sua troca foi invalidada",
        hasCloseButton: false,
        type: "error",
      });
      this.setState({
        saleData: {
          ...this.state.saleData,
          exchange: {
            itemsEnter: [],
            itemsOut: [],
          },
        },
      });
    }
  }

  validateExchange() {
    const { itemsOut } = this.state.saleData.exchange;
    const { avaliableProducts } = this.state;
    const isInvalid = itemsOut.some((product) => {
      const avaliableProduct = avaliableProducts.find(
        (p) => p.id == product.id
      );
      if (!avaliableProduct) return true;
      const isAmountValid = product.quantidade <= avaliableProduct.quantidade;
      return !isAmountValid;
    });

    return !isInvalid;
  }

  getAvaliableProductsOnSale(products) {
    this.setState({ avaliableProducts: products }, this.onSaleProductsChange);
  }

  handleToggleSignature() {
    this.setState({ signatureIsOpen: !this.state.signatureIsOpen });
  }

  handleSignatureImage(signatureB64) {
    this.setState({
      sigImage: signatureB64,
    });
  }

  render() {
    let { classes } = this.props;
    const exchangeComponent = (
      <ExchangeLogic
        basketsSold={this.state.saleData.saleProducts}
        onValidStateChange={console.warn}
        isDetachedExchange={false}
        onClose={this.toggleExchangeModal}
        prevExchanges={this.state.saleData.exchange}
        onComplete={this.handleExchangeChange}
        onBasketChange={this.getAvaliableProductsOnSale}
        defaultScheduleDate={moment(
          this.state.routeOpening.listaDiariaFinal,
          "DD/MM/YYYY"
        )
          .add(1, "day")
          .toDate()}
      />
    );

    const formasPagamento = this.props.saleContext.paymentForms;

    return (
      <>
        <Backdrop open={this.state.isLoading} />
        {!this.state.showProductsForm ? (
          this.state.signatureIsOpen ? (
            <Signature
              getTrimmedDataURL={this.handleSignatureImage}
              handleToggleSignature={this.handleToggleSignature}
            />
          ) : (
            <>
              <Grid container className={classes.root}>
                <Header
                  position="fixed"
                  icon={<CloseIcon fontSize="large" />}
                  name={this.state.client.nome}
                  date={moment().format("DD/MM/YYYY")}
                >
                  Venda
                </Header>
                <CardSwitch
                  title="Inserir valor"
                  disabled={this.state.envVar.IS_BITBOX_EMPLOYEE}
                  checked={this.state.canInsertTotalValue}
                  func={() => {
                    this.setState({
                      canInsertTotalValue: !this.state.canInsertTotalValue,
                    });
                  }}
                />
                <Divider className={classes.divider} />

                <ClientMoneyBalance
                  clientCurrentBalance={this.state.client.currentBalance}
                  totalValue={Moeda.create(
                    this.state.saleData.movementTotal.value
                  ).mount()}
                  totalValueRef={this.totalValueRef}
                  totalValueError={this.state.saleData.movementTotal.error}
                  canInsertValue={this.state.canInsertTotalValue}
                  setData={this.setSaleData}
                  classes={{
                    inputValueClass: classes.inputValueClass,
                    saleTotalValue: classes.saleTotalValue,
                    totalValueInput: classes.totalValueInput,
                    clientBalance: classes.clientBalance,
                  }}
                />

                {this.state.canInsertTotalValue ? null : (
                  <Grid item xs={12} className={classes.section}>
                    <ProductsSection
                      canCreateNew={this.state.canCreateProduct}
                      saleProducts={this.state.saleData.saleProducts}
                      allProductsWQ={this.state.allProductsWQ}
                      loadingAllProducts={this.state.loadingAllProducts}
                      onClickAddProduct={this.handleAddProduct}
                      handleDecreaseProductQnt={this.handleDecreaseProductQnt}
                      itemDetailsData={this.state.itemDetailsData}
                      onItemDetailsClick={this.handleToggleItemDetails}
                      handleEditItem={this.handleToggleEditProduct}
                      newProductDialogOpenState={
                        this.state.showAddNewItemDialog
                      }
                      onToggleNewProductDialog={
                        this.handleToggleNewProductDialog
                      }
                      onSaveNewProduct={this.handleSaveProduct}
                    />
                  </Grid>
                )}

                <Grid item xs={12} className={classes.section}>
                  <PaymentForm
                    handleToggleSignature={this.handleToggleSignature}
                    signatureImage={this.state.sigImage}
                    paymentType={this.state.saleData.paymentType.value}
                    setData={this.setSaleData}
                    data={this.state.saleData}
                    menuItems={formasPagamento}
                    finalBalanceIsPositive={this.finalBalanceIsPositive}
                    showDialogBalance={this.showDialogBalance}
                    showDialogReceivementDate={this.showDialogReceivementDate}
                    refDate={this.refDate}
                    inputValueHelperText={
                      this.state.saleData.inputValue.value >
                      this.state.saleData.movementTotal.value
                        ? "O valor de entrada  pode ser maior que o valor total"
                        : "O valor de entrada deve ser acima de 0,00"
                    }
                    refInputValue={this.inputValueRef}
                    refDiscountValue={this.maxDiscountRef}
                    maxDiscount={this.state.maxDiscountValue}
                    onPaymentTypeChange={this.handlePaymentTypeChange}
                  />
                </Grid>
                {(this.state.saleData.paymentType.value == "A vista" ||
                  this.state.saleData.paymentType.value ==
                    "Entrada + Parcelado") && (
                  <Grid item xs={12} className={classes.section}>
                    <PaymentMethods
                      paymentSelected={this.state.saleData.payment.value}
                      setData={this.setSaleData}
                      error={this.state.saleData.payment.error}
                      allPaymentMethods={this.props.saleContext.payments}
                      classes={{
                        card: classes.paymentMethodIcons,
                      }}
                    />
                  </Grid>
                )}
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ padding: "15px 10px" }}
                >
                  <Grid item>
                    <Button
                      disabled={!this.state.envVar.ACEITA_TROCA_PRODUTO}
                      onClick={this.toggleExchangeModal}
                      variant="contained"
                      classes={{
                        contained: classes.exchangeBtn,
                      }}
                      size="large"
                    >
                      <SyncAltIcon />
                      &nbsp;Trocar itens
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      style={{ backgroundColor: "#5DE23D", color: "#fff" }}
                      size="large"
                      onClick={this.handleSave}
                      disabled={this.state.isLoading}
                    >
                      Concluir venda
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Dialog
                fullScreen
                open={this.state.exchangeModal}
                onClose={this.toggleExchangeModal}
                keepMounted
              >
                {exchangeComponent}
              </Dialog>
            </>
          )
        ) : (
          <ProductsForm
            onClose={this.handleToggleEditProduct}
            productId={
              this.state.itemDetailsData && this.state.itemDetailsData.item.id
            }
            add={!this.state.envVar.IS_BITBOX_EMPLOYEE}
            saveProduct={this.handleSaveProduct}
          />
        )}
      </>
    );
  }
}
SaleComponent.contextType = ProductContext;

/**
 * Componente que renderiza a tela de recebimentos e provê seus respectivos contextos
 */
class SaleConsumer extends React.Component {
  render() {
    let { classes } = this.props;
    return (
      <UserContext.Consumer>
        {({ isBitboxEmployee }) => (
          <SchedulingsContext.Consumer>
            {(schedulingsContext) => (
              <ReceivementContext.Consumer>
                {(receivementContext) => (
                  <ExchangeContext.Consumer>
                    {(exchangeContext) => (
                      <MessageDialogContext.Consumer>
                        {(dialog) => (
                          <SaleContext.Consumer>
                            {(sale) => (
                              <ProductContext.Consumer>
                                {(products) => (
                                  <SaleComponent
                                    classes={classes}
                                    saleContext={sale}
                                    dialogContext={dialog}
                                    exchangeContext={exchangeContext}
                                    receivementContext={receivementContext}
                                    schedulingsContext={schedulingsContext}
                                    productsContext={products}
                                    hash={this.props.match.params.hash}
                                    isBitboxEmployee={isBitboxEmployee}
                                  />
                                )}
                              </ProductContext.Consumer>
                            )}
                          </SaleContext.Consumer>
                        )}
                      </MessageDialogContext.Consumer>
                    )}
                  </ExchangeContext.Consumer>
                )}
              </ReceivementContext.Consumer>
            )}
          </SchedulingsContext.Consumer>
        )}
      </UserContext.Consumer>
    );
  }
}
export default withStyles(styles)(SaleConsumer);
