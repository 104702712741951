import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import {
  ArrowBack,
  CalendarToday,
  CreditCard,
  Edit,
  LocalAtm,
  SyncAlt,
} from "@material-ui/icons";
import React from "react";
import { Link, useParams } from "react-router-dom";
import GreenButton from "../Buttons/GreenButton";

const styles = (theme) => ({
  report: {
    paddingTop: "1vh",
  },
  buttons: {
    paddingTop: "5vh",
    paddingBottom: "2vh",
    "& div": {
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& > div": {
      paddingBottom: "16px",
    },
  },
  textArea: {
    width: "100%",
    border: "none",
    padding: "0 15px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
    },
    "& .MuiButton-root": {
      width: "100%",
    },
  },
});

function RouteDialog(props) {
  const [open, setOpen] = React.useState(false);
  const params = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <GreenButton onClickButton={handleClickOpen}>{props.text}</GreenButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open || props.open || props.routeDialogOpen}
        className={styles.root}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{ textAlign: "center" }}
        >
          Deseja realizar mais alguma outra ação neste cliente?
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Link
                to={`/client/view/data/${params.hash}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Grid
                  container
                  flex
                  justifyContent="center"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ width: "100%" }}
                  >
                    <span style={{ marginRight: "10px", marginTop: "5px" }}>
                      <Edit />
                    </span>
                    Editar Cliente
                  </Button>
                </Grid>
              </Link>
              <Link
                to="/daily"
                style={{
                  textDecoration: "none",
                }}
              >
                <Grid
                  container
                  flex
                  justifyContent="center"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ width: "100%" }}
                  >
                    <span style={{ marginRight: "10px", marginTop: "5px" }}>
                      <ArrowBack />
                    </span>
                    Voltar à lista
                  </Button>
                </Grid>
              </Link>
              <Link
                to={`/sale/create/${params.hash}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Grid
                  container
                  flex
                  justifyContent="center"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ width: "100%" }}
                  >
                    <span style={{ marginRight: "10px", marginTop: "5px" }}>
                      <CreditCard />
                    </span>
                    Vender
                  </Button>
                </Grid>
              </Link>
              <Link
                to={`/receivement/create/${params.hash}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Grid
                  container
                  flex
                  justifyContent="center"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ width: "100%" }}
                  >
                    <span style={{ marginRight: "10px", marginTop: "5px" }}>
                      <LocalAtm />
                    </span>
                    Receber
                  </Button>
                </Grid>
              </Link>
              <Link
                to={`/exchange/create/${params.hash}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Grid
                  container
                  flex
                  justifyContent="center"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ width: "100%" }}
                  >
                    <span style={{ marginRight: "10px", marginTop: "5px" }}>
                      <SyncAlt />
                    </span>
                    Trocar
                  </Button>
                </Grid>
              </Link>
              <Link
                to={`/schedulings/create/${params.hash}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Grid
                  container
                  flex
                  justifyContent="center"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ width: "100%" }}
                  >
                    <span style={{ marginRight: "10px", marginTop: "5px" }}>
                      <CalendarToday />
                    </span>
                    Agendar
                  </Button>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" href="/daily">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RouteDialog;
