import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

const maskOptions = [
  [
    "(",
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ], //11
  [
    "(",
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ], //10
];

/**
 * Componente utilizado como parametro de props.InputProps de componentes <TextField/> do Material-uipara defnição de mascara do tipo telefone celular
 * que é dada pelo seguinte padrão (##) #####-#### onde '#' representa um numero inteiro
 */
class CelularMask extends React.Component {
  constructor(props) {
    super(props);
    this.render.propTypes = {
      inputRef: PropTypes.func.isRequired,
    };
  }

  mapMaskOption() {
    const valueFormated = this.props.value;
    if (!valueFormated) return maskOptions[0];
    const unformatedString = valueFormated
      .replace(/\s/g, "")
      .replace("(", "")
      .replace(")", "")
      .replace("-", "");
    const stringSize = unformatedString.length;
    const masks = {
      10: maskOptions[1],
      11: maskOptions[0],
    };
    return masks[stringSize] || maskOptions[0];
  }

  forwardRef = (ref) => {
    const inputElement = ref ? ref.inputElement : null;
    this.props.inputRef(inputElement);
  };

  render() {
    const { inputRef, ...other } = this.props;

    return (
      <MaskedInput
        {...other}
        ref={this.forwardRef}
        placeholderChar={"\u2000"}
        mask={this.mapMaskOption()}
      />
    );
  }
}

export default CelularMask;
