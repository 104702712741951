import DataAccess from "../DataAccessStrategy";

export default class Cidade extends DataAccess {
  constructor() {
    super("Cidade", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      cidade: String,
    });
  }
}
