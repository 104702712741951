import React from "react";
import { Typography, withStyles, Grid } from "@material-ui/core";
import clsx from "clsx";
import history from "../../router/History";
const styles = (theme) => ({
  cabecalho: {
    padding: "8px 15px",
    borderBottom: "1px solid #c1c1c1",
    backgroundColor: "#fff",
    minHeight: "60px",
    "& svg": {
      width: "30px",
      height: "30px",
    },
  },
  titleCard: {
    paddingLeft: "7px",
  },
  icon: {
    color: "black",
  },
  title: {
    fontSize: "1.2rem",
    fontFamily: "Roboto, sans-serif",
    whiteSpace: "nowrap",
  },
  name: {
    fontSize: "1.1rem",
    color: "#A9A9A9",
    fontFamily: "Roboto, sans-serif",
    textOverflow: "ellipsis",
  },
  date: {
    fontSize: ".8rem",
    color: "#A9A9A9",
    fontFamily: "Roboto, sans-serif",
  },

  outterContainerDefault: {
    backgroundColor: "#fff",
    boxShadow: "0 -30px 17px 20px rgb(0 0 0 / 50%)",
    margin: "0",
    minHeight: "60px",
  },

  outterContainer_fixed: {
    zIndex: 4,
    position: "fixed",
    padding: "8px 15px",
    borderBottom: "1px solid #c1c1c1",
    "& svg": {
      width: "30px",
      height: "30px",
    },
  },
  outterContainer: {
    zIndex: 4,
    padding: "10px 15px",
    position: "relative",
    borderRadius: "0px",
  },
});

/**
 * Componente de um herder usado normalmente em páginas utilizadas para adicionar alguma produto ou objeto
 */
class Header extends React.Component {
  /**
   * @param {string} props.link Link para redirecionamento
   * @param {function} props.func Função executada ao clicar no ícone
   * @param {string} props.children Texto principal do componente
   * @param {string} props.name Nome de alguma pessoa que ficará no Header
   */
  constructor(props) {
    super(props);
    this.handleIconClick = this.handleIconClick.bind(this);
  }

  handleIconClick() {
    if (this.props.func) {
      this.props.func();
      return;
    }
    if (this.props.link) {
      history.push(this.props.link, { ...history.location.state });
    }
    // If no function has been received, user browser history and go back
    else {
      history.goBack();
    }
  }

  render() {
    let { classes, position } = this.props;
    const isFixed = position == "fixed";

    const outterContainerClass = clsx({
      [classes.outterContainer]: !isFixed,
      [classes.outterContainer_fixed]: isFixed,
      [classes.outterContainerDefault]: true,
    });

    return (
      <>
        <Grid
          container
          // className={classes.cabecalho}
          className={outterContainerClass}
          alignItems="center"
        >
          <Grid item xs={1}>
            <Grid
              container
              justify="center"
              alignItems="center"
              onClick={this.handleIconClick}
              className={classes.icon}
            >
              {this.props.icon}
            </Grid>
          </Grid>
          <Grid item xs={11} className={classes.titleCard}>
            <Grid container alignItems="center">
              <Grid item xs={11} style={{ padding: "0px 5px 0 0" }}>
                <Grid container wrap="nowrap" alignItems="center">
                  <Grid item>
                    <Typography
                      display="inline"
                      align="left"
                      className={classes.title}
                      noWrap
                    >
                      {this.props.children}
                    </Typography>
                  </Grid>
                  {this.props.name && (
                    <Typography
                      display="inline"
                      noWrap
                      className={classes.name}
                    >
                      &nbsp; - &nbsp;{this.props.name}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {this.props.iconEnd && (
                <Grid item xs={1}>
                  {this.props.iconEnd}
                </Grid>
              )}
              {this.props.date && (
                <Grid item xs={4} style={{ height: "19px" }}>
                  <Typography className={classes.date}>
                    {this.props.date}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {isFixed && (
          <div
            style={{ minHeight: "60px", position: "relative", width: "100%" }}
          ></div>
        )}
      </>
    );
  }
}
export default withStyles(styles)(Header);
