import React from "react";
import { withStyles, Grid, Avatar, Typography } from "@material-ui/core";
import StickerMoves from "../../../Stickers/StickerMoves";
import { LocalAtm, ShoppingCart, SyncAlt } from "@material-ui/icons";
import VerticalEdit from "../../../Buttons/VerticalEdit";
import Moeda from "../../../../utils/Moeda";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  outterContainer: {
    padding: "8px 6px",
    "& a": {
      textDecoration: "none",
    },
    overflow: "hidden",
  },
  cardContainer: {
    boxShadow: "0px 3px 8px -2px rgba(120,112,120,1)",
    padding: "5px 10px 5px 10px",
    marginBottom: "10px",
  },
  money: {
    wordBreak: "break-all",
    color: "#d44444",
    fontSize: ".9rem",
    fontWeight: 600,
  },
  currentBalance: {
    fontSize: ".75rem",
    color: "#777",
    lineHeight: 1,
  },
  name: {
    fontSize: ".9rem",
    fontWeight: "bold",
    color: "#222",
  },
  address: {
    lineHeight: 1,
    fontSize: ".8rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#696969",
  },

  onShowIcons: {
    maxHeight: "100px",
  },
  onHideIcons: {
    maxHeight: "30px",
  },
});

/**
 * Card utilizado no relatório de agendamentos.
 * O componente utiliza este meio de CSS acima.
 */
class CardReportsScheduling extends React.Component {
  /**
   * @param {string} props.index Índice do card na Array
   * @param {object} props.moves Prop onde se tem as movimentações do cliente
   * @param {object} props.data Prop onde se tem os dados do cliente
   * @param {function} props.handleToggleViewClient Função que exibe a tela de dados do cliente
   * @param {function} props.handleCloseVerticalEdit Função que tira a tela de dados do cliente
   * @property {State} this.state
   */
  constructor(props) {
    super(props);
    this.state = {};
    this.displayCardSettings = this.displayCardSettings.bind(this);
    this.hideCardSettings = this.hideCardSettings.bind(this);
    this.toggleDisplaySettings = this.toggleDisplaySettings.bind(this);
    this.setMoves = this.setMoves.bind(this);
  }

  /**Função acionada ao clicar no icone
   * @function
   */
  displayCardSettings() {
    this.props.handleToggleVerticalEdit(this.props.data.hash);
  }

  /**Função acionada ao clicar no icone para recolher
   * @function
   */
  hideCardSettings() {
    this.props.handleCloseVerticalEdit(this.props.data.hash);
  }

  toggleDisplaySettings() {
    this.props.toggleDisplaySettings();
  }

  setMoves() {
    return (
      <Grid
        container
        wrap="nowrap"
        style={{ overflowX: "auto", paddingTop: 5 }}
      >
        {this.props.moves.exchanges ? (
          <Grid item style={{ paddingRight: "4px" }}>
            <StickerMoves
              backgroundColor="#2383F0"
              icon={<SyncAlt style={{ fontSize: 13, color: "#fff" }} />}
              text="TROCAR"
            />
          </Grid>
        ) : null}

        {this.props.moves.receive ? (
          <Grid item style={{ paddingRight: "4px" }}>
            <StickerMoves
              backgroundColor="#21A94F"
              icon={<LocalAtm style={{ fontSize: 13, color: "#fff" }} />}
              text="RECEBER"
            />
          </Grid>
        ) : null}

        {this.props.moves.sell ? (
          <Grid item style={{ paddingRight: "4px" }}>
            <StickerMoves
              backgroundColor="#F3C631"
              icon={<ShoppingCart style={{ fontSize: 13, color: "#fff" }} />}
              text="VENDER"
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Paper elevation={2} key={this.props.key}>
          <Grid container className={classes.outterContainer}>
            <Grid item xs={2} container alignItems="center" justify="center">
              <Avatar className={classes.avatar}></Avatar>
            </Grid>
            <Grid item xs={9}>
              <Link to={`/client/view/schedules/${this.props.data.hash}`}>
                <Grid container>
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography className={classes.name}>
                          {this.props.data.name}
                        </Typography>
                        <Typography noWrap className={classes.address}>
                          {this.props.data.address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {true || !this.props.displaySettings ? (
                    <Grid item xs={4} container alignItems="center">
                      <Grid item xs={12}>
                        <Typography align="right" className={classes.money}>
                          {Moeda.create(this.props.data.balance).format()}
                        </Typography>
                        <Typography
                          align="right"
                          className={classes.currentBalance}
                        >
                          Saldo atual
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : null}
                  {this.setMoves()}
                </Grid>
              </Link>
            </Grid>

            <Grid item xs={1} container alignItems="center">
              <Grid item style={{ color: "#A9A9A9", width: "100%" }}>
                <VerticalEdit
                  isOpen={this.props.displaySettings}
                  toggleCardSettings={this.toggleDisplaySettings}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default withStyles(styles)(CardReportsScheduling);
