import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import { Box, List, Slide, Typography } from "@material-ui/core";

const styles = (theme) => ({});

class ListRequirementsDialog extends Component {
  constructor() {
    super();
    this.Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  }

  componentDidMount() {}

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.isOpen}
        sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "none" } }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{ fontSize: "14px", color: "red" }}
        >
          {this.props.title}
        </DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            style={{ fontSize: "14px", marginLeft: "20px" }}
          >
            {this.props.subTitle}
          </Typography>
        </Box>
        <DialogContent>
          <DialogContentText>
            <List dense={true}>
              {this.props.message.map((item, index) => (
                <li style={{ marginBottom: "10px", fontWeight: "bold" }}>
                  {index + 1} - {item}
                </li>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={this.props.actions[0].onClick}
            color="primary"
          >
            {this.props.actions[0].label}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ListRequirementsDialog);
