import DataAccess from "../DataAccessStrategy";

export default class Documento extends DataAccess {
  constructor() {
    super("Documento", {
      id: Number,
      nome: String,
      shouldSync: Boolean,
    });
  }
}
