import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Container,
  Button,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroller";
import { DailyListContext } from "../../contexts/DailyListContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "./ClientCardWraper";
import NeighborhoodCard from "../CardComponents/CardNeighborhood/DailyListCard";
import { getEnvVar } from "../../service/config";
import { reverse } from "ramda";

const styles = (theme) => ({
  totalClients: {
    borderBottom: "1px solid #A9A9A9",
    paddingTop: "10px",
  },
  textTotalClients: {
    fontSize: ".9rem",
    fontFamily: "Roboto, sans-serif",
  },
  containerTotalClients: {
    paddingBottom: "20px",
    paddingLeft: "10px",
    paddingRight: "35px",
    overflowX: "hidden",
  },
  neighborhoodDiv: {
    fontSize: "1.4rem",
    padding: "7px 0 10px 0",
    fontWeight: 500,
  },
});
const BLOCK_SIZE = 10;

/**
 * Componente responsável por renderizar uma lista de cards com infinite scroll.
 */
class ListClients extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   */
  constructor(props) {
    super(props);
    this.state = {
      hasMore: true,
      total: 0,
      totalShowCard: 0,
      canSetVeaco: false,
    };
    this.setInitialValues = this.setInitialValues.bind(this);
    this.fetchMoreData = this.fetchMoreData.bind(this);
  }

  componentDidMount() {
    this.setInitialValues();
  }

  async setInitialValues() {
    let canSetVeaco = await getEnvVar("VEACO_BY_FUNCIONARIO");
    this.setState({
      canSetVeaco,
    });
  }

  componentDidUpdate(oldProps, oldState) {
    if (
      this.props.clients !== oldProps.clients &&
      this.props.clients.length > 0
    ) {
      this.setState({ hasMore: true });
    }
  }

  fetchMoreData(page) {
    if (this.state.totalShowCard >= this.props.clients.length) {
      this.setState({ hasMore: false });
      return;
    }
    this.setState((state, props) => ({
      totalShowCard: state.totalShowCard + BLOCK_SIZE,
      hasMore: true,
    }));
  }

  addSkeletons(arr) {
    const skeltonArray = new Array(3).fill(true).map((e, index) => {
      const element = <Card key={`skeleton-${index}`} isLoading={true} />;
      return element;
    });

    arr.push(...skeltonArray);
  }

  getCardHeaderProps(client) {
    if (this.props.getHeaderCardProps) {
      return this.props.getHeaderCardProps(client);
    }
    return {
      mainText: client.nome[0],
      secondaryText: `Clientes com ${client.nome[0]}`,
    };
  }

  shouldRenderCardHeader(client, lastClient) {
    if (this.props.shouldRenderCardHeader) {
      return this.props.shouldRenderCardHeader(client, lastClient);
    }

    if (!lastClient) return true;

    return client.nome[0] !== lastClient.nome[0];
  }

  buildListItem(client, lastClient) {
    const shouldPrintCardHeader = this.shouldRenderCardHeader(
      client,
      lastClient
    );

    return (
      <Container
        key={`${client.id}-daily-card`}
        maxWidth="sm"
        style={{ padding: "10px 4vw 0 4vw" }}
      >
        {shouldPrintCardHeader ? (
          <NeighborhoodCard {...this.getCardHeaderProps(client)} />
        ) : null}
        <Card
          Client={client}
          onClickRepass={this.props.addClientToRepass}
          onClickPular={this.props.onClickPular}
          onSync={this.props.onSync}
          repasseBtn={!this.props.isRepassList}
          pularBtn={true}
          veacoBtn={this.state.canSetVeaco}
        />
      </Container>
    );
  }

  render() {
    let { classes } = this.props;

    const list =
      this.props.isRepassList == true
        ? reverse(this.props.clients)
        : this.props.clients;

    const items = list
      .slice(0, this.state.totalShowCard)
      .map((Client, index, array) => {
        return this.buildListItem(Client, array[index - 1]);
      });

    if (this.state.hasMore) {
      this.addSkeletons(items);
    }

    return (
      <>
        <DailyListContext.Consumer>
          {(ctx) => {
            return ctx.isLoading ? (
              <p style={{ textAlign: "center" }}>Calculando Lista Diária</p>
            ) : (
              <>
                <Grid
                  container
                  justify="space-between"
                  className={classes.containerTotalClients}
                >
                  <Grid item xs={7} className={classes.totalClients}>
                    <Typography className={classes.textTotalClients}>
                      {`Total:${this.props.clients.length} clientes`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => ctx.calculateDailyList(true)}
                    >
                      Recalcular
                    </Button>
                  </Grid>
                </Grid>

                <Grid container>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    style={{ width: "100%", overflowX: "hidden" }}
                    loader={<LinearProgress key="loader-daily-list" />}
                  >
                    {items}
                  </InfiniteScroll>
                </Grid>
              </>
            );
          }}
        </DailyListContext.Consumer>
      </>
    );
  }
}

export default withStyles(styles)(ListClients);
