import React from "react";
import Dialog from "@material-ui/core/Dialog";

export default function EmptyDialog(props) {
  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={props.Transition}
    >
      {props.children}
    </Dialog>
  );
}
