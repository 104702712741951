import React from "react";
import {
  withStyles,
  Grid,
  Container,
  Snackbar,
  Typography,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroller";
import { ClientContext } from "../../contexts/ClientContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "./ClientCardWraperSearch";
import DivisorCard from "../CardComponents/CardNeighborhood/DailyListCard";
import { Alert } from "@material-ui/lab";
import { If } from "../If";

const styles = (theme) => ({
  totalClients: {
    borderBottom: "1px solid #A9A9A9",
    paddingTop: "10px",
  },
  textTotalClients: {
    fontSize: ".9rem",
    fontFamily: "Roboto, sans-serif",
  },
  containerTotalClients: {
    paddingBottom: "20px",
  },
  neighborhoodDiv: {
    fontSize: "1.4rem",
    padding: "7px 0 10px 0",
    fontWeight: 500,
  },
});
const BLOCK_SIZE = 5;

/**
 * Componente responsável por renderizar uma lista de cards com infinite scroll.
 */
class AllClientsList extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {ref} this.inputTakeImage Referência para o input de tirar uma imagems
   * @property {ref} this.inputUploadImage  Referência para o input fazer upload de uma imagem
   */
  constructor(props) {
    super(props);
    this.state = {
      orderBy: "lowerCaseNome",
      orderDirection: "ASC",
      isSnackOpen: true,
    };
    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.compareByName = this.compareByName.bind(this);
  }

  createDivisorCard(client) {
    const render = {
      lowerCaseNome: this.renderCardByInitial,
      default: () => {
        throw new Error(
          "Tipo de card inesperado em AllClientList.createDivisorCard"
        );
      },
    };
    const card = (render[this.state.orderBy] || render["default"])(client);
    return card;
  }

  renderCardByInitial(client) {
    const initialLetter =
      (client.lowerCaseNome && client.lowerCaseNome[0]) || "Sem nome";
    return (
      <DivisorCard
        key={`${initialLetter}-${client.id}`}
        mainText={initialLetter.toUpperCase()}
        secondaryText={`Clientes com ${initialLetter.toUpperCase()}`}
      />
    );
  }

  compareByOrderType(lastClient, current) {
    if (!lastClient) return true;
    const comparator = {
      lowerCaseNome: this.compareByName,
      default: () => {
        throw new Error(
          "Tipo de card inesperado em AllClientList.createDivisorCard"
        );
      },
    };
    return (comparator[this.state.orderBy] || comparator["default"])(
      lastClient,
      current
    );
  }

  getClientNameStrategy(client) {
    let name = client.lowerCaseNome ? client.lowerCaseNome[0] : false;
    if (!name) {
      name = client.nomeLatinizado
        ? client.nomeLatinizado.toLowerCase()[0]
        : false;
    }
    if (!name) {
      name = "Sem nome";
    }

    return name;
  }

  compareByName(lastClient, current) {
    if (!lastClient) {
      return true;
    }
    const lasClientName = this.getClientNameStrategy(lastClient);
    const currentClientName = this.getClientNameStrategy(current);

    const initialLetterLastClient = lasClientName[0];
    const initialLetterCurrentClient = currentClientName[0];
    return initialLetterLastClient !== initialLetterCurrentClient;
  }

  async fetchMoreData(page) {
    const status = {
      page,
      blockSize: BLOCK_SIZE,
      listType: this.props.listType,
    };

    try {
      await this.props.onFetchMore(status);
    } catch (error) {
      return;
    }
  }

  _buildItemList() {
    let lastClient;
    const items = this.props.clients.map((clientInfo) => {
      const shouldRenderDivisorCard = this.compareByOrderType(
        lastClient,
        clientInfo
      );

      lastClient = clientInfo;
      return (
        <Container
          key={`${clientInfo.id}-daily-card`}
          maxWidth="sm"
          style={{ padding: "10px 4vw 0 4vw" }}
        >
          {shouldRenderDivisorCard ? this.createDivisorCard(clientInfo) : null}
          <Card clientId={clientInfo.id} onSync={this.props.onSync} />
        </Container>
      );
    });
    return items;
  }

  render() {
    const items = this._buildItemList();
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            {this.props.clientsCount && (
              <Typography
                variant="body1"
                align="right"
                style={{
                  padding: "0 10px",
                  fontSize: ".8rem",
                  color: "#8c8c8c",
                }}
              >
                Total clientes: {this.props.clientsCount}
              </Typography>
            )}
            {this.props.downloadedClients && (
              <Typography
                variant="body1"
                align="right"
                style={{
                  padding: "0 10px",
                  fontSize: ".8rem",
                  color: "#8c8c8c",
                }}
              >
                Clientes baixados: {this.props.downloadedClients}
              </Typography>
            )}
          </Grid>
          <If condition={this.props.shouldRenderInfiniteScroll}>
            <InfiniteScroll
              hasMore={this.props.hasMore}
              loadMore={this.fetchMoreData}
              style={{ width: "100%", overflowX: "hidden" }}
              loader={<LinearProgress key="progress-bar-1" />}
              threshold={100}
            >
              {items}
            </InfiniteScroll>
          </If>
          <Snackbar
            open={!this.props.hasMore && this.state.isSnackOpen}
            onClose={() => this.setState({ isSnackOpen: false })}
            autoHideDuration={5000}
          >
            <Alert
              elevation={2}
              style={{ zIndex: "1300", opacity: 0.6 }}
              variant="filled"
              severity="info"
            >
              Fim da lista
            </Alert>
          </Snackbar>
        </Grid>
      </>
    );
  }
}

AllClientsList.contextType = ClientContext;
export default withStyles(styles)(AllClientsList);
