import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { UserContext } from "../../../contexts/UserContext";
import { ExpensesContext } from "../../../contexts/ExpensesContext";
import UnknownError from "../../../errorDefinition/UnknownError";
import { MessageDialogContext } from "../../../contexts/MessageDialogContext";

const styles = (theme) => ({
  BodyContainer: {
    maxWidth: "300px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  Login_Btn: {
    "& button": {
      backgroundColor: "#199BDA",
      color: "#fff",
    },
  },
  UsernameInput: {
    backgroundColor: "#fff",
    width: "100%",
    marginBottom: "15px !important",
    boxShadow: "0 3px 14px -1px rgba(0, 0, 0, .45)",
  },
  PasswordInput: {
    backgroundColor: "#fff",
    width: "100%",
    marginBottom: "15px !important",
    boxShadow: "0 3px 14px -1px rgba(0, 0, 0, .45)",
  },
  btn: {
    minWidth: "35vw",
  },
});

const initialState = {
  username: "",
  password: "",
  companyName: "",
  showCompanyField: true,
};

class LoginBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState, loginBtnText: "Entrar" };
    this.authenticate = this.authenticate.bind(this);
  }

  componentDidMount() {
    const loginType = this.props.match.params.loginType;
    const shouldShowCompanyField = loginType === "enterprise";
    this.setState({ showCompanyField: shouldShowCompanyField });
  }

  async authenticate(event) {
    try {
      event.preventDefault && event.preventDefault();
      this.setState({ loginBtnText: "Carregado..." });
      await this.context.authenticateUser(
        this.state.username,
        this.state.password,
        this.state.showCompanyField ? this.state.companyName : "",
        this.props.match.params.loginType
      );
      if (this.contextExpenses) {
        await this.contextExpenses.setConfigUser();
      } else {
        await this.messageContext.addAsyncDialog({
          message:
            "Ocorreu um erro na recuperação de algum dado, caso veja essa mensagem novamente por favor informe ao suporte",
          title: "Recuperação de dados",
          type: "warning",
        });
      }
      // this.setState({loginBtnText: 'Entrando!'})
    } catch (error) {
      this.setState({ loginBtnText: "Entrar" });
      new UnknownError({
        showToUser: false,
        devHelper: "Provavelmente erro ao autentcar o usuário, pagina de login",
        title: "Erro inexperado",
        message:
          "Ocorreu um erro ao autenticar o usuário no sistema, por favor informe o ocorrido ao suporte",
        type: "error",
        error: error,
      });
    }
  }

  updateFields(event) {
    const user = { ...this.state };
    user[event.target.name] = event.target.value;
    this.setState(user);
  }

  componentDidCatch(error) {
    console.error(error);
  }

  render() {
    let { classes } = this.props;
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.messageContext !== context) this.messageContext = context;
          return (
            <ExpensesContext.Consumer>
              {(context) => {
                if (this.contextExpenses !== context)
                  this.contextExpenses = context;
                return (
                  <Grid container className={classes.BodyContainer}>
                    <Grid item xs={12}>
                      <form onSubmit={this.authenticate} id="loginForm">
                        {this.state.showCompanyField ? (
                          <TextField
                            id="companyNameInput"
                            name="companyName"
                            onChange={(e) => this.updateFields(e)}
                            className={classes.UsernameInput}
                            label="Empresa"
                            variant="filled"
                            type="text"
                            value={this.state.companyName}
                          />
                        ) : null}
                        <TextField
                          id="usernameInput"
                          name="username"
                          onChange={(e) => this.updateFields(e)}
                          className={classes.UsernameInput}
                          label="Nome de usuário"
                          variant="filled"
                          type="text"
                          value={this.state.username}
                        />

                        <TextField
                          id="passwordInput"
                          name="password"
                          onChange={(e) => this.updateFields(e)}
                          className={classes.PasswordInput}
                          label="Senha"
                          variant="filled"
                          type="password"
                          value={this.state.password}
                        />
                      </form>
                    </Grid>
                    <Grid
                      container
                      className={classes.Btns_Container}
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item className={classes.Goback_Btn}>
                        <Button
                          className={classes.btn}
                          onClick={this.props.history.goBack}
                          variant="contained"
                        >
                          Voltar
                        </Button>
                      </Grid>
                      <Grid item className={classes.Login_Btn}>
                        <Button
                          className={classes.btn}
                          variant="contained"
                          type="submit"
                          form="loginForm"
                        >
                          {this.state.loginBtnText}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }}
            </ExpensesContext.Consumer>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

LoginBody.contextType = UserContext;

export default withStyles(styles)(LoginBody);
