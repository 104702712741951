import DataAccess from "../DataAccessStrategy";

export default class Veiculo extends DataAccess {
  constructor() {
    super("Veiculo", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      marca: String,
      modelo: String,
      anoFabricacao: String,
      anoModelo: String,
      placa: String,
      dataAquisicao: String,
      chassi: String,
      renavan: String,
      quilometragem: String,
    });
  }
}
