import React from "react";
import {
  Typography,
  Grid,
  withStyles,
  Dialog,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SelectInput from "../Inputs/SelectInput";
import UploadAndTakeImage from "../Buttons/UploadAndTakeImage";
import { Pictures, resetOrientation } from "../../service/Pictures/Pictures";
import ExifOrientationImg from "react-exif-orientation-img";

import Camera from "../Pictures/";

const styles = (theme) => ({
  text: {
    fontSize: "0.7rem",
  },
  pictureButtons: {
    position: "relative",
    top: "8px",
  },
  documentName: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  cardAddPicture: {
    position: "relative",
    top: "15px",
    border: "1px solid grey",
    borderRadius: 3,
    padding: "10px",
  },
  cardButton: {
    position: "relative",
    top: "25px",
  },
  saveButton: {
    position: "relative",
    top: "40px",
    padding: "10px",
    backgroundColor: "#1473cc",
    color: "#fff",
  },
  backgroundPage: {
    backgroundColor: "#F5F5F5",
    height: "100vh%",
    position: "absolute",
  },
  inputFile: {
    //visibility:'hidden'
    display: "none",
  },
  image: {
    maxWidth: "200px",
    maxHeight: "200px",
  },
});
/**
 * Dialog onde será adicionado Documentos e fotos do documento.
 */
class AddDocuments extends React.Component {
  /**
   * @param {function} props.getData Função onde passará o tipo de documento selecionado no input
   * @param {function} props.close Função que fechará o Dialog
   * @param {boolean} props.status Boleano usado como referencia para abertura e fechamento do dialog
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {object} this.state.documento Estado onde armazera o documento selecionado
   * @property {boolean} this.state.popUpPictures Estado que altera a exibição do dialog de adicionar foto
   * @property {ref} this.inputTakeImage Referência para o input de tirar uma imagems
   * @property {ref} this.inputUploadImage  Referência para o input fazer upload de uma imagem
   */
  constructor(props) {
    super(props);
    this.state = {
      document: { value: { id: "", nome: "", image: null }, error: false },
      popUpPictures: false,
      isCameraOpen: false,
    };
    this.inputUploadImage = React.createRef();
    this.image = React.createRef();

    this.clickGetSelect = this.clickGetSelect.bind(this);
    this.clickPopUpPictures = this.clickPopUpPictures.bind(this);
    this.onFileSelected = this.onFileSelected.bind(this);
    this.onPhotoTake = this.onPhotoTake.bind(this);
    this.clickTakeImage = this.clickTakeImage.bind(this);
    this.clickUploadImage = this.clickUploadImage.bind(this);
    this.toggleCamera = this.toggleCamera.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  /**Função ativada ao salvar um documento, passará o valor do input selecionado para o pai e fechará o dialog
   * @function
   */
  clickGetSelect() {
    if (
      this.state.document.value.id != "" &&
      this.state.document.value.image != null
    ) {
      let documentName = this.props.documents.find(
        (a) => a.id == this.state.document.value.id
      );
      this.props.getData(
        this.state.document.value.id,
        documentName.nome,
        this.state.document.value.image
      );
      let cleanDocument = { ...this.state.document };
      cleanDocument.value.id = "";
      cleanDocument.value.image = null;
      cleanDocument.error = false;
      this.setState({ document: cleanDocument });
      this.props.close();
    } else this.setState({ document: { ...this.state.document, error: true } });
  }

  /**Função para fechar e exibir o dialog de adicionar fotos
   * @function
   */
  clickPopUpPictures() {
    this.setState({ popUpPictures: !this.state.popUpPictures });
  }

  /**
   * Importante: 'Pictures.onFileSelected()' é uma service onde ocorre o acerto na orientação da foto e a geração da imagem
   * @example
   * FileSelected =  async function(event){
   *  let image = await Pictures.onFileSelected(event)
   *  this.setState({document:{...this.state.document,value:{...this.state.document.value,image:image}}})
   * }
   */
  async onFileSelected(event) {
    const imageBASE64 = await Pictures.onFileSelected(event);
    resetOrientation(imageBASE64, 1).then((image) => {
      this.setState({
        document: {
          ...this.state.document,
          value: { ...this.state.document.value, image: image },
        },
      });
    });
  }

  onPhotoTake(imageBASE64) {
    resetOrientation(imageBASE64, 1).then((image) => {
      this.setState({
        document: {
          ...this.state.document,
          value: { ...this.state.document.value, image: image },
        },
      });
    });
  }

  /**Função chamada ao clicar num botão, onde é ativado o input por referencia para carregar uma imagem
   * @function
   */
  clickTakeImage() {
    this.setState({ isCameraOpen: true });
  }

  /**Função chamada ao clicar num botão, onde é ativado o input por referencia para carregar uma imagem
   * @function
   */
  clickUploadImage() {
    this.inputUploadImage.current.click();
  }

  toggleCamera() {
    this.setState({ isCameraOpen: !this.state.isCameraOpen });
  }

  handleChangeInput(value) {
    let document = { ...this.state.document };
    document.value.id = value;
    this.setState({ document: document });
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Dialog
          onClose={this.props.close}
          aria-labelledby="simple-dialog-title"
          open={this.props.status}
        >
          <Grid
            container
            direction="column"
            alignItems="stretch"
            style={{ padding: "20px 20px 50px 20px" }}
          >
            <Grid container justify="space-between">
              <Typography align="center">Novo Documento:</Typography>
              <CloseIcon onClick={this.props.close} />
            </Grid>

            <SelectInput
              label="Selecione"
              onChange={this.handleChangeInput}
              required={false}
              value={this.state.document.value.id}
              error={this.state.document.error}
              menuItem={this.props.documents}
            />

            {this.state.document.value.image != null ? (
              <Grid container justify="center" style={{ paddingTop: "20px" }}>
                <ExifOrientationImg
                  alt="Foto do documento"
                  ref={this.image}
                  className={classes.image}
                  src={this.state.document.value.image}
                />
              </Grid>
            ) : null}

            <Grid container justify="center" className={classes.cardButton}>
              <UploadAndTakeImage
                clickUploadImage={this.clickUploadImage}
                clickTakeImage={this.clickTakeImage}
              />
            </Grid>

            <Grid container justify="center" alignItems="center">
              <Button
                className={classes.saveButton}
                onClick={this.clickGetSelect}
              >
                <Typography className={classes.text}>Salvar</Typography>
              </Button>
            </Grid>
          </Grid>
        </Dialog>

        <Camera
          isOpen={this.state.isCameraOpen}
          onClose={this.toggleCamera}
          onPhotoTakeComplete={this.onPhotoTake}
        />

        <input
          type="file"
          accept="image/png,image/jpeg"
          ref={this.inputUploadImage}
          className={classes.inputFile}
          onChange={this.onFileSelected}
        />
      </>
    );
  }
}
export default withStyles(styles)(AddDocuments);
