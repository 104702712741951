import {
  Button,
  Divider,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import React from "react";
import Backdrop from "../../components/Dialogs/BackdropLoader";
import Header from "../../components/HeaderForAdd/Header";
import ClientMoneyBalance from "../../components/Receivement/ClientMoneyBalance";
import PaymentForm from "../../components/Sale/PaymentForm";
import PaymentMethods from "../../components/Sale/PaymentMethods";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import { ReceivementContext } from "../../contexts/ReceivementContext";
import UnknownError from "../../errorDefinition/UnknownError";
import { AppStorageError } from "../../repository/errorsModels";
import History from "../../router/History";
import Moeda from "../../utils/Moeda";

const styles = (theme) => ({
  root: {
    backgroundColor: "#f5f5f5",
  },
  section: {
    marginBottom: "20px",
    background: "rgb(255, 255, 255)",
    padding: "10px",
  },
  divider: {
    width: "100%",
    height: "2px",
  },
});
const DEFAULT_RECEIVEMENT_STATE = {
  movementTotal: { value: 0, error: false },
  inputValue: { name: "inputValue", value: 0, error: false },
  finalBalance: { name: "finalBalance", value: 0, error: false },
  nextDate: { name: "nextDate", value: null, error: false },
  receiptNumber: { name: "receiptNumber", value: null, error: false },
  note: { name: "note", value: null, error: false },
  payment: { name: "payment", value: null, error: false },
  paymentType: { value: null, error: false },
  discount: { value: 0, error: false },

  // inputValue:{ value:null,error:false },
  // finalBalance:{ value:0,error:false },
  // nextDate:{ value:null,error:false },
  // receiptNumber:{ value:null,error:false },
  // note:{ value:null,error:false },
  // payment:{ value:null,error:false },
};

function getDefaultState() {
  return JSON.parse(JSON.stringify(DEFAULT_RECEIVEMENT_STATE));
}
/**
 * Tela de recebimento
 */
class ReceivementConsumer extends React.Component {
  constructor() {
    super();
    this.state = {
      recData: {
        ...getDefaultState(),
      },
      client: {},
      receivementCache: [],
      isLoading: false,
    };
    this.refDate = React.createRef();
    this.inputValueRef = React.createRef();

    this.getClientData = this.getClientData.bind(this);
    this.finalBalanceIsPositive = this.finalBalanceIsPositive.bind(this);
    this.showDialogReceivementDate = this.showDialogReceivementDate.bind(this);
    this.showDialogBalance = this.showDialogBalance.bind(this);
    this.calculateClientBalance = this.calculateClientBalance.bind(this);
    this.setReceivementData = this.setReceivementData.bind(this);
    this.checkFields = this.checkFields.bind(this);
    this.saveData = this.saveData.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.addMoreReceivement = this.addMoreReceivement.bind(this);
  }

  /**Função responsável por pegar os dados do cliente no banco.
   * @function
   */
  async getClientData() {
    try {
      let client = await this.props.receivementContext.getClientByHash(
        this.props.hash
      );
      let recData = { ...this.state.recData };
      recData.finalBalance.value = client.currentBalance;
      this.setState({ client: client, recData: recData });
    } catch (error) {
      console.log(error);
      throw new UnknownError({
        devHelper: error.message,
        title: "Erro inexperado",
        message:
          "Ocorreu um erro inexperado ao buscar os dados do cliente, por favor informe os detalhes ao suporte",
        type: "error",
      });
    }
  }

  /**Função executada na montagem do componente.
   * @function
   */
  componentDidMount() {
    this.getClientData();
  }

  /**Função onde é feita a comparação se o saldo final do cliente será quitado ou não.
   * @function
   * @returns {boolean} Retorna true o saldo ficar quitado e false caso não fique.
   */
  finalBalanceIsPositive() {
    if (Moeda.create(this.state.recData.finalBalance.value).isNegative())
      return false;
    else return true;
  }

  /**Função onde retorna um dialog informando que já existe um agendamento para tal data.
   * Qnd o ja existe um agendamento daquele tipo
   * @function
   * @returns {dialog} Retorna um dialogo
   */
  async showDialogReceivementDate() {
    try {
      if (
        // !this.finalBalanceIsPositive() &&
        await this.props.receivementContext.getSchedulingsByDate(
          this.state.recData.nextDate.value,
          this.state.client.id
        )
      )
        return this.props.dialogContext.addDialog({
          message: `Já existe um agendamento para
                  ${moment(this.state.recData.nextDate.value).format(
                    "DD/MM/YYYY"
                  )}
                  ,gostaria de continuar com esta data?`,
          title: "Próximo recebimento",
          handleConfirm: () => {
            this.props.dialogContext.popDialog();
          },
          handleClose: () => {
            this.props.dialogContext.popDialog();
            this.refDate.current.click();
          },
          type: "warning",
          okText: "Usar esta data",
          cancelText: "Marcar outra data",
        });
    } catch (error) {
      console.log(error);
      throw new UnknownError({
        devHelper: error.message,
        title: "Erro inexperado",
        message:
          "Ocorreu um erro inexperado ao buscar os dados do cliente, por favor informe os detalhes ao suporte",
        type: "error",
      });
    }
  }

  /**Função um dialogo informando que o saldo do cliente está positivo.
   * @function
   * @returns {dialog} Retorna um dialogo
   */
  showDialogBalance() {
    if (this.finalBalanceIsPositive())
      return this.props.dialogContext.addDialog({
        message: "A data fará refêrencia para uma nova próxima venda.",
        title: "Saldo do cliente está positivo!",
        handleConfirm: () => {
          this.props.dialogContext.popDialog();
        },
        hasCloseButton: false,
        type: "info",
      });
  }

  /**Função onde é calculado o saldo final do cliente.
   * @function
   */
  calculateClientBalance() {
    let recData = { ...this.state.recData };
    let finalBalance = Moeda.create(this.getClientCurrentBalance())
      .add(this.state.recData.inputValue.value)
      .mount();
    recData.finalBalance.value = finalBalance;
    // If client's finalBalance becomes 0 or positive
    if (!Moeda.create(finalBalance).isNegative()) recData.nextDate.value = null;

    this.setState({ recData: recData });
  }

  /**Função que armazena os valores digitados no formulário.
   * @function
   */
  setReceivementData(name, data) {
    let recData = { ...this.state.recData };
    recData[name].value = data;
    if (name == "movementTotal" || name == "inputValue") {
      recData["movementTotal"].value = Moeda.create(data).mount();
      recData["inputValue"].value = Moeda.create(data).mount();
    }

    this.setState({ recData: recData }, () => {
      if (name == "inputValue" || name == "movementTotal")
        this.calculateClientBalance();
      this.checkFields();
    });
  }

  /**Função que valida os campos com base nas regras de negócio.
   * @function
   */
  checkFields() {
    let check = true;
    let recData = { ...this.state.recData };

    if (!this.finalBalanceIsPositive()) {
      if (
        this.state.recData.nextDate.value === null ||
        this.state.recData.nextDate.value === undefined
      ) {
        recData.nextDate.error = true;
        check = false;
      } else {
        recData.nextDate.error = false;
      }
    } else {
      recData.nextDate.error = false;
    }
    if (
      this.state.recData.inputValue.value === null ||
      Moeda.create(this.state.recData.inputValue.value).isZero()
    ) {
      recData.inputValue.error = true;
      check = false;
    } else {
      recData.inputValue.error = false;
    }
    if (
      this.state.recData.payment.value === null ||
      this.state.recData.payment.value === undefined
    ) {
      recData.payment.error = true;
      check = false;
    } else {
      recData.payment.error = false;
    }

    this.setState({ recData: recData });

    return check;
  }

  /**Função que salva os dados no banco e redirecionado para a página que irá gerar o relatório de recebimento.
   * @function
   */
  async saveData() {
    const clientHash = this.props.hash;
    let data = {
      dataRealizacao: new Date(),
      valor: this.state.recData.inputValue.value,
      saldo: this.state.recData.finalBalance.value,
      meioPagamento: this.state.recData.payment.value,
      ClienteId: this.state.client.id,
      documentoFisico: this.state.recData.receiptNumber.value,
      dataRecebimento: this.state.recData.nextDate.value,
    };

    if (this.finalBalanceIsPositive()) {
      data.tipo = "Venda";
    } else {
      data.tipo = "Recebimento";
    }
    let receivementHashs;
    try {
      if (this.state.receivementCache.length > 0) {
        receivementHashs = await this.createReceivementFromCache(data);
      } else {
        receivementHashs =
          await this.props.receivementContext.createReceivement(
            data,
            clientHash
          );
      }

      if (!receivementHashs) {
        return;
      }

      this.props.receivementContext.clientBackgroundSync();

      await this.props.dialogContext.addAsyncDialog({
        message: "O recebimento foi realizado com sucesso!",
        title: "Concluído",
        hasCloseButton: false,
        type: "success",
      });

      History.push(
        `/receivement/report/${this.props.hash}/${JSON.stringify(
          receivementHashs
        )}`,
        {
          ...History.location.state,
          prevUrl: History.location.pathname,
        }
      );
    } catch (error) {
      console.error(error);
      throw new AppStorageError({
        message: error.message,
        title: "Falha ao lançar recebimento!",
        type: "error",
        method: "save receivement",
        error,
      });
    }
  }

  async handleSave() {
    this.setState({ isLoading: true });
    try {
      if (this.checkFields()) {
        await this.saveData();
      }
    } catch (err) {
      console.log(err);
      this.props.dialogContext.addDialog({
        title: "Falha ao lançar recebimento!",
        message: <Typography>{err.message}</Typography>,
        type: "error",
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  // /**Função redireciona para a pagina anterior.
  //  * @function
  // */
  // goBackPage = ()=>{
  //     History.goBack()
  // }

  async createReceivementFromCache(actualReceivement) {
    try {
      const clientHash = this.props.hash;
      const receivements = [...this.state.receivementCache];
      const grupoRecebimentoHash = `${window.DATABASE.generateUniqId()}`;
      receivements.push(actualReceivement);
      const shouldProceed = await this.alertUserMultipleReceivement(
        receivements
      );
      if (!shouldProceed) return;
      let proms = [];
      for (let receivement of receivements) {
        receivement.grupoRecebimentoHash = grupoRecebimentoHash;
        proms.push(
          await this.props.receivementContext.createReceivement(
            receivement,
            clientHash
          )
        );
      }
      return proms;
    } catch (error) {
      console.log(error);
      throw new UnknownError({
        devHelper: error.message,
        title: "Erro inexperado",
        message:
          "Ocorreu um erro inexperado, por favor informe os detalhes do ocorrido ao suporte",
        type: "error",
      });
    }
  }

  async alertUserMultipleReceivement(receivements) {
    return await this.props.dialogContext.addAsyncDialog({
      message: (
        <>
          {receivements.map((receivement, index) => {
            return (
              <>
                <Typography style={{ fontSize: "bold" }}>{`${
                  index + 1
                }° Recebimento`}</Typography>
                <Typography>{`Valor: ${receivement.valor}`}</Typography>
                <Typography>{`Meio de pagamento: ${receivement.meioPagamento}`}</Typography>
              </>
            );
          })}
        </>
      ),
      title: "Aviso de multiplos recebimentos",
      type: "warning",
      okText: "Ok",
      cancelText: "Cancelar",
    });
  }

  addMoreReceivement() {
    const isValid = this.checkFields();
    if (!isValid) {
      this.props.dialogContext.addDialog({
        title: "Campos inválidos!",
        message: (
          <Typography>
            Algum campo permanece inválido.
            <br />
            Por favor, verifique o formulário e tente novamente.
          </Typography>
        ),
        type: "warning",
      });
      return;
    } else if (this.finalBalanceIsPositive()) {
      this.props.dialogContext.addDialog({
        title: "Saldo do cliente positivo",
        message: (
          <Typography>
            Não é possível adicionar multiplos recebimentos caso o saldo final
            do cliente esteja positivo.
          </Typography>
        ),
        type: "warning",
      });
      return;
    }

    const recData = {
      dataRealizacao: new Date(),
      valor: this.state.recData.inputValue.value,
      saldo: this.state.recData.finalBalance.value,
      meioPagamento: this.state.recData.payment.value,
      ClienteId: this.state.client.id,
      documentoFisico: this.state.recData.receiptNumber.value,
    };

    this.setState({
      recData: {
        ...getDefaultState(),
        nextDate: this.state.recData.nextDate,
        finalBalance: this.state.recData.finalBalance,
      },
      receivementCache: this.state.receivementCache.concat([recData]),
    });
  }

  getClientCurrentBalance() {
    const acumulatedBalance = this.state.receivementCache.reduce(
      (memo, receivement) => {
        return memo.add(receivement.valor);
      },
      Moeda.create(0)
    );

    const finalBalance = Moeda.create(this.state.client.currentBalance).add(
      acumulatedBalance
    );
    return finalBalance.mount();
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Grid container className={classes.root}>
          <Header
            position="fixed"
            icon={<CloseIcon fontSize="large" />}
            name={this.state.client.nome}
            date={moment(new Date()).format("DD/MM/YYYY")}
          >
            Recebimento
          </Header>

          <Divider className={classes.divider} />

          <ClientMoneyBalance
            totalValue={Moeda.create(
              this.state.recData.movementTotal.value
            ).mount()}
            totalValueError={this.state.recData.movementTotal.error}
            clientCurrentBalance={this.state.client.currentBalance}
            canInsertValue={true}
            setData={this.setReceivementData}
          />

          <Grid item xs={12} className={classes.section}>
            <PaymentForm
              setData={this.setReceivementData}
              menuItems={this.state.paymentForms}
              // canInsertValue={this.state.canInsertValue}
              data={this.state.recData}
              disablePaymentType={true}
              finalBalanceIsPositive={this.finalBalanceIsPositive}
              showDialogBalance={this.showDialogBalance}
              showDialogReceivementDate={this.showDialogReceivementDate}
              refDate={this.refDate}
              // refInputValue={this.inputValueRef}
              refDiscountValue={this.maxDiscountRef}
              maxDiscount={this.state.maxDiscountValue}
            />
          </Grid>

          <Grid item xs={12} className={classes.section}>
            <PaymentMethods
              paymentSelected={this.state.recData.payment.value}
              setData={this.setReceivementData}
              error={this.state.recData.payment.error}
              allPaymentMethods={this.props.receivementContext.payments}
            />
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: "15px 10px" }}
          >
            <Grid item>
              <Button
                style={{
                  backgroundColor: "#35A6F1",
                  color: "#fff",
                  padding: 5,
                  minWidth: "0px",
                }}
                size="large"
                onClick={this.addMoreReceivement}
              >
                <AddOutlinedIcon />
                {this.state.receivementCache.length + 1}
                &nbsp; Recebimento
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ backgroundColor: "#5DE23D", color: "#fff" }}
                size="large"
                onClick={this.handleSave}
                disabled={this.state.isLoading}
              >
                Finalizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop open={this.state.isLoading} />
      </>
    );
  }
}

/**
 * Componente que renderiza a tela de recebimentos e provê seus respectivos contextos
 */
class Receivement extends React.Component {
  render() {
    let { classes } = this.props;
    return (
      <MessageDialogContext.Consumer>
        {(dialog) => (
          <ReceivementContext.Consumer>
            {(receivement) => (
              <ReceivementConsumer
                dialogContext={dialog}
                receivementContext={receivement}
                classes={classes}
                hash={this.props.match.params.hash}
              />
            )}
          </ReceivementContext.Consumer>
        )}
      </MessageDialogContext.Consumer>
    );
  }
}
export default withStyles(styles)(Receivement);
