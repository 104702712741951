/* eslint-disable no-useless-constructor */
/* eslint-disable eqeqeq */
import React from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CSSTransition } from "react-transition-group";
import history from "../../router/History";
const styles = (theme) => ({
  floatBtn: {
    color: "#fff",
  },

  buttonsGroup: {
    marginBottom: "40px",
    position: "absolute",
    bottom: 0,
    "& svg": {
      color: "#fff",
    },
    "& > .MuiGrid-item": {
      width: "auto",
      paddingBottom: "calc(10px + 2vh)",
    },
    "& > div > p": {
      fontSize: "1.2rem",
      fontFamily: "Roboto,sans-serif",
      color: "#222",
      textTransform: "uppercase",
      position: "relative",
      paddingRight: 15,
      fontWeight: "bold",
    },
  },

  mainBtn: {
    borderRadius: 400,
    height: "10vh",
    width: "10vh",
    outline: "none",
    backgroundColor: "#fff",
    color: "#2383f0",
    border: 0,
    boxShadow: "-1px 3px 5px -2px rgba(0,0,0,0.52)",
    transitionDuration: ".4s",
  },
  closeBtn: {
    color: "#000",
    height: "45px",
    width: "45px",
  },

  recebimentoBtn: {
    backgroundColor: "#21a94f",
    color: "#fff",
  },

  vendaBtn: {
    backgroundColor: "#f3c631",
  },
  trocaBtn: {
    backgroundColor: "#2383f0",
  },

  // Fade Transition
  "fade-enter": {
    opacity: 0,
    // display: 'none',
    transform: "translate(0, -90px)",
  },
  "fade-enter-active": {
    display: "inline-block",
    opacity: 1,
    transition: "all 300ms",
    transform: "translate(0, 0)",
  },
  "fade-enter-done": {
    display: "inline-block",
  },
  "fade-exit": {
    // transform: 'translate(0, 0)',
    display: "inline-block",
    opacity: 1,
  },
  "fade-exit-active": {
    transform: "translate(0, -90px)",
    opacity: 0,
    transition: "all 300ms",
  },
  "fade-exit-done": {
    display: "none",
  },
});

/**
 * Botão estilizado que redireciona ou abre um componente por cima
 * O componente utiliza este meio de CSS acima.
 */
class AddButton extends React.Component {
  /**
   * @param {Obejct} props.buttonsGroup Informações que serão usadas no grupos de botões
   * @param {function} props.onClick Caso true Button se torna verde caso false vermelho
   * @param {icon} props.icon Icone do component
   * @param {object} props.classes Classes do css vindas do withStyles
   */
  constructor() {
    super();
  }

  render() {
    let { classes, classNameMainBtn, classNameFloatBtn } = this.props;
    let classRecebimentoBtn = clsx({
      [classNameFloatBtn]: true,
      [classes.mainBtn]: true,
      [classes.recebimentoBtn]: true,
    });
    let classVendaBtn = clsx({
      [classNameFloatBtn]: true,
      [classes.mainBtn]: true,
      [classes.vendaBtn]: true,
    });
    let classTrocaBtn = clsx({
      [classNameFloatBtn]: true,
      [classes.mainBtn]: true,
      [classes.trocaBtn]: true,
    });
    let ClassMainBtn = clsx({
      [classNameMainBtn]: true,
      [classes.mainBtn]: true,
      [classes.closeBtn]: this.props.isSmall,
    });
    return (
      <>
        <Grid container justifyContent="center" style={{ width: "10vh" }}>
          {this.props.buttonsGroup ? (
            <CSSTransition
              in={this.props.isSmall}
              timeout={300}
              classNames={{
                enter: classes["fade-enter"],
                enterActive: classes["fade-enter-active"],
                enterDone: classes["fade-enter-done"],
                exit: classes["fade-exit"],
                exitActive: classes["fade-exit-active"],
                exitDone: classes["fade-exit-done"],
              }}
              unmountOnExit
              mountOnEnter
            >
              <Grid
                item
                container
                className={classes.buttonsGroup}
                justifyContent="center"
              >
                <Grid
                  item
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography>Recebimento</Typography>
                  <Link
                    to={{
                      pathname: this.props.buttonsGroup.button1.link,
                      state: { ...history.location.state },
                    }}
                  >
                    <button className={classRecebimentoBtn}>
                      {this.props.buttonsGroup.button1.icon}
                    </button>
                  </Link>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography>Venda</Typography>
                  <Link
                    to={{
                      pathname: this.props.buttonsGroup.button3.link,
                      state: { ...history.location.state },
                    }}
                  >
                    <button className={classVendaBtn}>
                      {this.props.buttonsGroup.button3.icon}
                    </button>
                  </Link>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  justify="flex-end"
                >
                  <Typography>Troca</Typography>
                  <Link
                    to={{
                      pathname: this.props.buttonsGroup.button2.link,
                      state: { ...history.location.state },
                    }}
                  >
                    <button className={classTrocaBtn}>
                      {this.props.buttonsGroup.button2.icon}
                    </button>
                  </Link>
                </Grid>
              </Grid>
            </CSSTransition>
          ) : null}
          <Grid container justifyContent="center">
            <button
              className={ClassMainBtn}
              onClick={this.props.onClick}
              data-testid="Add-button"
            >
              {this.props.icon}{" "}
            </button>
          </Grid>
        </Grid>
      </>
    );
  }
}
AddButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.object,
};
export default withStyles(styles)(AddButton);
