import DataAccess from "../DataAccessStrategy";
import ClienteFoto from "./ClienteFoto";

export default class Cliente extends DataAccess {
  constructor() {
    super("Cliente", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      hash: String,
      nome: String,
      nomeLatinizado: String,
      nomeConjuge: String,
      nomeConjugeLatinizado: String,
      conjugeCpf: String,
      foto: String,
      dataNascimento: Date,
      sexo: String,
      estadoCivil: String,
      cep: String,
      estado: String,
      cidade: String,
      logradouro: String,
      numero: String,
      pontoReferencia: String,
      observacao: String,
      cpf: String,
      email: String,
      latitude: String,
      longitude: String,
      saldoAtual: String,
      assinatura: String,
      veaco: Boolean,

      updatedAt: Date,
      createdAt: Date,
      hasAppCliente: Boolean,
    });
    this.clientFotoModel = new ClienteFoto();
  }

  getClientsOrderedBy(offset, limit, options) {
    if (!offset && !limit) {
      if (options.order.direction == "DESC") {
        return this.model.orderBy(options.order.by).reverse().toArray();
      }
      return this.model.orderBy(options.order.by).toArray();
    }
    if (options.order.direction == "DESC") {
      return this.model
        .orderBy(options.order.by)
        .reverse()
        .offset(offset)
        .limit(limit)
        .toArray();
    }
    const clients = this.model
      .orderBy(options.order.by)
      .offset(offset)
      .limit(limit)
      .toArray();

    return clients;
  }

  getPicture({ id, hash }) {
    if (id) {
      return this.clientFotoModel.getById(id);
    } else if (hash) {
      return this.clientFotoModel.getByHash(hash);
    }
    throw new Error("You must provide an id or hash for the client");
  }

  async getClients(filters, configs) {
    const { offset, limit, orderBy } = configs;

    let filteredClients = await this.model.orderBy(orderBy);
    for (const filter of filters) {
      const { functionToApply, value, prop } = filter;
      if (prop) {
        filteredClients = await filteredClients
          .where(prop)
          [functionToApply](value);
      } else {
        filteredClients = await filteredClients[functionToApply](value);
      }
    }

    const resultSet = await filteredClients
      .offset(offset)
      .limit(limit)
      .toArray();

    console.log(`Page: ${offset / limit}: `, resultSet);
    return resultSet;
  }
}
