import DataAccess from "../DataAccessStrategy";
import { Cliente } from "../models";

export default class ClienteVenda extends DataAccess {
  constructor() {
    super("ClienteVenda", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      valor: String,
      desconto: String,
      valorFinalDescontado: String,
      saldo: String,
      formaPagamento: String,
      dataRealizacao: String,
      documentoFisico: String,
      hash: String,
      assinatura: String,
      historico: String,
      houveTroca: Boolean,
      trocaRealizada: Boolean,
      dataRealizacaoTroca: Date,
      cancelado: Boolean,
      ClienteId: Number,
      hashRotaAbertura: String,
    });
    this.fetchSell = this.fetchSell.bind(this);
    this.getSalesByInterval = this.getSalesByInterval.bind(this);
  }

  async fetchSell(hash, startDate, endDate) {
    const cliente = new Cliente();
    let client = await cliente.findByHash(hash);
    let sell = await this.model
      .where("[ClienteId+dataRealizacao]")
      .between([client.id, startDate], [client.id, endDate], true, true)
      .and((a) => !a.cancelado)
      .sortBy("dataRealizacao");
    return sell;
  }

  getSalesByInterval(startDate, endDate) {
    return this.model
      .where("dataRealizacao")
      .between(startDate, endDate, true, true)
      .toArray();
  }
}
