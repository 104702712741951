import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "../../components/MenuSideBar/AppBar";
import SearchBar from "../../components/SearchBar/SearchBar";
import DocumentsCards from "../../components/DocumentsList/Documents";
import AddButton from "../../components/Buttons/AddButton";
import DescriptionIcon from "@material-ui/icons/Description";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { DocumentContext } from "../../contexts/DocumentContext";

const styles = (theme) => ({
  AddButton: {
    position: "fixed",
    bottom: "20px",
    right: "10px",
  },
  "fade-enter": {
    opacity: 0,
  },
  "fade-enter-active": {
    opacity: 1,
    transition: "opacity 500ms",
  },
  "fade-exit": {
    opacity: 1,
  },
  "fade-exit-active": {
    opacity: 0,
    transition: "opacity 300ms",
  },
});

/**
 * Tela de listagem de documentos.
 */
class Documents extends React.Component {
  /**
   * @property {object} this.state.allDocuments Lista de todos os documentos
   * @property {object} this.state.documentsFilteredLista Lista filtrada após a pesquisa no SearchBar
   * @property {object} this.state.classes Objeto com todas as classes do CSS
   * @property {boolean} this.state.isOpen Boleano que definirá se o título da barra superior será a logo da aplicação
   * @property {string} this.state.search Texto digitado na barra de pesquisa
   */
  constructor(props) {
    super(props);

    this.state = {
      documentsFiltered: [],
      isOpen: null,
      search: null,
      isLoading: false,
    };

    this.handleRemoveProduct = this.handleRemoveProduct.bind(this);
    this.handleToggleRemoveConfirmDelete =
      this.handleToggleRemoveConfirmDelete.bind(this);
    this.handleCloseConfirmDelete = this.handleCloseConfirmDelete.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    this.setState({ documentsFiltered: this.context.document });
  }

  /**Função que carrega a lista total para a lista filtrada.
   * @function
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isLoading != this.context.isLoading) {
      this.setState({
        documentsFiltered: this.context.document,
        isLoading: this.context.isLoading,
      });
    }
  }

  handleRemoveProduct(hash) {}

  /**Função que altera o estado de exibição do componente de confirmação da exclusão.
   * @function
   * @param {number} hash Hash do card em escolhido para exclusão
   */
  handleToggleRemoveConfirmDelete(hash) {
    this.setState({ isOpen: hash });
  }

  /**Função onde limpa o estado do card que deve estar aberto, fazendo assim nenhum card ficar aberto.
   * @function
   */
  handleCloseConfirmDelete() {
    this.setState({ isOpen: null });
  }

  /**Função que altera o estado de exibição do menu.
   * @function
   * @param {string} word Valor digitado no SearchBar
   */
  handleSearch(word) {
    if (word == null || word == "" || word == " ") {
      this.setState({ documentsFiltered: this.context.document });
    } else {
      let documentsFiltered = this.context.document.filter((a) =>
        a.nome.toLowerCase().includes(word.toLowerCase())
      );
      this.setState({ documentsFiltered: documentsFiltered });
    }
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <AppBar title="Tipos de documentos" />

        <SearchBar getInput={this.handleSearch} />

        <List disablePadding={true}>
          <TransitionGroup className={classes.prodcutsList} component={null}>
            {this.state.documentsFiltered.map((card, index) => {
              let isOpen = false;
              if (this.state.isOpen === card.hash) {
                isOpen = true;
              }
              return (
                <CSSTransition
                  timeout={{
                    enter: 300,
                    exit: 500,
                  }}
                  classNames={{
                    enter: classes["fade-enter"],
                    enterActive: classes["fade-enter-active"],
                    exit: classes["fade-exit"],
                    exitActive: classes["fade-exit-active"],
                  }}
                  key={index}
                >
                  <ListItem disableGutters={true} style={{ padding: "0" }}>
                    <DocumentsCards
                      data={card}
                      showDeleteConfirm={isOpen}
                      handleToggleRemoveConfirmDelete={
                        this.handleToggleRemoveConfirmDelete
                      }
                      handleCloseConfirmDelete={this.handleCloseConfirmDelete}
                      handleRemoveProduct={this.handleRemoveProduct}
                      confirmDelete={this.deleteDocument}
                      deleteConfirmText={`Deseja mesmo deletar esse tipo de documento?`}
                    />
                  </ListItem>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </List>
        <span className={classes.AddButton}>
          <Link to="/documents/create">
            <AddButton icon={<DescriptionIcon fontSize="large" />} />
          </Link>
        </span>
      </>
    );
  }
}

Documents.contextType = DocumentContext;

export default withStyles(styles)(Documents);
