import React from "react";
import ExchangeView from "../Exchange";
import useAvaliableProducts from "./hooks/useAvaliableProducts";
import usePoints from "./hooks/usePoints";
import Dialog from "../../Dialogs/SearchItems";
import * as R from "ramda";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { MessageDialogContext } from "../../../contexts/MessageDialogContext";
import dialog from "./ConfirmMessage";

function isSameProduct(el1, el2) {
  return el1.id === el2.id;
}
function getProductsDiff(arr1, arr2) {
  return R.differenceWith(isSameProduct, arr1, arr2);
}

const throwError = function () {
  throw new Error("Invalid list name on ExchangeLogic.handleAdd");
};

function ExchangeLogic({
  basketsSold,
  onValidStateChange,
  isDetachedExchange,
  onComplete,
  onBasketChange,
  prevExchanges = {},
  defaultScheduleDate,
  isLoading,
  ...props
}) {
  const [avaliableProducts, isValidAmount, allProducts] = useAvaliableProducts(
    basketsSold,
    isDetachedExchange
  );
  const { addAsyncDialog } = React.useContext(MessageDialogContext);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [isSnackOpen, setIsSnackOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState("");
  const [exchangeInfo, setExchangeInfo] = React.useState({ ...prevExchanges });
  const [randomFactor] = React.useState(
    Math.ceil(Math.random() * (15 - 1) + 1)
  );

  const [totalAdded, totalRemoved, calculateUnitaryValue, calculateTotal] =
    usePoints(randomFactor, exchangeInfo);

  const showSnack = function (message) {
    setSnackMessage(message);
    setIsSnackOpen(true);
  };

  /**
   * Realiza validação caso as cestas da venda foram modificadas
   */
  React.useEffect(
    function () {
      if (onBasketChange) onBasketChange(avaliableProducts);
    },
    [avaliableProducts, onBasketChange]
  );

  React.useEffect(
    function () {
      setExchangeInfo({ ...prevExchanges });
    },
    [prevExchanges]
  );

  const handleChangeOnExchangeInfo = React.useCallback(
    function (fieldName, value) {
      const clonedState = { ...exchangeInfo };

      clonedState[fieldName] = value;
      setExchangeInfo(clonedState);
    },
    [exchangeInfo]
  );

  const onAddFromRemovedList = function (index) {
    const clone = [...exchangeInfo.itemsOut];

    const nextAmount = clone[index].quantidade + 1;
    const isValid = isValidAmount(clone[index].id, nextAmount);
    if (!isValid) {
      showSnack(
        `Atingiu o limite maximo de ( ${clone[index].identificador} ) disponiveis na venda`
      );
      return;
    }

    clone[index].quantidade = nextAmount;
    handleChangeOnExchangeInfo("itemsOut", clone);
  };

  const onAddFromAddedList = function (index) {
    const clone = [...exchangeInfo.itemsEnter];
    const nextAmount = clone[index].quantidade + 1;

    const productPoints = calculateUnitaryValue(clone[index]);
    const isGreaterThanOutput = productPoints + totalAdded > totalRemoved;
    if (isGreaterThanOutput) {
      showSnack("Você não tem pontos o suficiente");
      return;
    }
    if (nextAmount <= 0) {
      showSnack("Valor negativo!");
      return;
    }

    clone[index].quantidade = nextAmount;
    handleChangeOnExchangeInfo("itemsEnter", clone);
  };

  const handleAdd = function (listName, index) {
    const handlers = {
      itemsOut: onAddFromRemovedList,
      itemsEnter: onAddFromAddedList,
      default: throwError,
    };

    return (handlers[listName] || handlers["default"])(index);
  };

  const onSubtractFromRemovedList = function (index) {
    const clone = [...exchangeInfo.itemsOut];

    const productToRemove = clone[index];
    const pointsOfProduct = calculateUnitaryValue(productToRemove);
    const pointsAfterRemove = totalRemoved - pointsOfProduct;
    const nextAmount = productToRemove.quantidade - 1;

    if (pointsAfterRemove < totalAdded) {
      showSnack("Não é possivel remover, saldo ficará negativo!");
      return;
    }
    if (nextAmount <= 0) {
      showSnack("Valor negativo!");
      return;
    }
    clone[index].quantidade = nextAmount;
    handleChangeOnExchangeInfo("itemsOut", clone);
  };
  const onSubtractFromAddedList = function (index) {
    const clone = [...exchangeInfo.itemsEnter];

    const productToRemove = clone[index];
    const nextAmount = productToRemove.quantidade - 1;

    if (nextAmount <= 0) {
      showSnack("Valor negativo!");
      return;
    }

    clone[index].quantidade = nextAmount;
    handleChangeOnExchangeInfo("itemsEnter", clone);
  };

  const handleSubtract = function (listName, index) {
    const handlers = {
      itemsOut: onSubtractFromRemovedList,
      itemsEnter: onSubtractFromAddedList,
      default: throwError,
    };

    return (handlers[listName] || handlers["default"])(index);
  };

  const handleModal = function (listType) {
    setDialogOpen(listType);
  };

  const applyFilter = React.useCallback(
    function (products, listType, order = null) {
      const orderFunc = function (a, b) {
        if (order.toUpperCase() == "DESC")
          return b.identificador
            .toLocaleLowerCase()
            .localeCompare(a.identificador.toLocaleLowerCase());
        return a.identificador
          .toLocaleLowerCase()
          .localeCompare(b.identificador.toLocaleLowerCase());
      };
      if (listType === "itemsEnter") {
        const productsToRemove = [...products];
        const uniqProducts = getProductsDiff(allProducts, productsToRemove);
        const productsValueAllowed = uniqProducts.filter(
          (el) => calculateUnitaryValue(el) <= totalRemoved
        );
        if (!!order) {
          let orderedArray = productsValueAllowed.sort(orderFunc);
          return orderedArray;
        } else {
          return productsValueAllowed;
        }
      }
      if (listType === "itemsOut") {
        if (!!order) {
          let orderedArray = getProductsDiff(avaliableProducts, products).sort(
            orderFunc
          );
          return orderedArray;
        } else {
          return getProductsDiff(avaliableProducts, products);
        }
      }
    },
    [avaliableProducts, allProducts, totalRemoved, calculateUnitaryValue]
  );

  const handleRemoveItem = function (listType, index) {
    const clone = [...exchangeInfo[listType]];
    clone.splice(index, 1);
    if (listType == "itemsOut") {
      const totalPointsOfProduct = calculateTotal([clone[index]]);
      const isLessThanAddedPoints =
        totalRemoved - totalPointsOfProduct < totalAdded;
      if (isLessThanAddedPoints) {
        showSnack(
          "Não é possivel remover, verifique a quantidade de pontos disponivel"
        );
        return;
      }
    }

    handleChangeOnExchangeInfo(listType, clone);
  };

  const onClickSaveItemsOnModal = React.useCallback(
    function (items) {
      const itemsEnterClone = [...exchangeInfo.itemsEnter];
      const itemsOutClone = [...exchangeInfo.itemsOut];

      function ShouldInsertItem(item) {
        const enterPoints = calculateTotal(itemsEnterClone);
        const outPoints = calculateTotal(itemsOutClone);

        if (dialogOpen === "itemsEnter") {
          const itemPoints = calculateUnitaryValue(item);
          const isGreatherThanOutput = itemPoints + enterPoints > outPoints;
          item.quantidade = 1;
          if (isGreatherThanOutput) return false;
          itemsEnterClone.push(item);
          return true;
        } else if (dialogOpen === "itemsOut") {
          item.quantidade = 1;
          itemsOutClone.push(item);
          return true;
        }
      }
      const resultSet = items.map(ShouldInsertItem);
      const shouldShowErrorMsg = resultSet.some((el) => !el);
      if (shouldShowErrorMsg)
        showSnack("Alguns produtos não foram adicionados por falta de pontos!");
      setExchangeInfo({
        itemsEnter: itemsEnterClone,
        itemsOut: itemsOutClone,
      });
    },
    [
      exchangeInfo,
      setExchangeInfo,
      dialogOpen,
      calculateTotal,
      calculateUnitaryValue,
    ]
  );

  const getPoints = React.useCallback(
    function (product) {
      return ` - ${calculateUnitaryValue(product)} pts`;
    },
    [calculateUnitaryValue]
  );

  const onSnackClose = function (evt, reason) {
    if (reason == "clickaway") {
      return;
    }
    setSnackMessage("");
    setIsSnackOpen(false);
  };

  const handleComplete = React.useCallback(
    async function () {
      let date = defaultScheduleDate || new Date();
      const resp = await addAsyncDialog({
        message: (
          <dialog.DatePickerMessage
            defaultValue={date}
            message="Selecione uma data para realização da troca"
            onChange={(scheduleDate) => (date = scheduleDate)}
          />
        ),
        title: "Agendamento da troca",
        okText: "Marcar",
        cancelText: "Voltar",
        type: "success",
      });
      if (!resp) return;
      const clonedInfo = { ...exchangeInfo };
      clonedInfo.scheduleDate = date;
      onComplete(clonedInfo);
    },
    [onComplete, exchangeInfo, addAsyncDialog, defaultScheduleDate]
  );

  const itemsToShowOnAdicionarList = applyFilter(
    exchangeInfo.itemsEnter,
    "itemsEnter",
    "ASC"
  );
  const itemsToShowOnRemoverList = applyFilter(
    exchangeInfo.itemsOut,
    "itemsOut",
    "ASC"
  );

  return (
    <>
      <ExchangeView
        handleItemAdd={handleAdd}
        handleItemSub={handleSubtract}
        onClick={handleModal}
        clientName={props.clientName}
        itemsRemoved={exchangeInfo.itemsOut}
        itemsAdded={exchangeInfo.itemsEnter}
        handleRemoveItem={handleRemoveItem}
        removedBalance={totalRemoved}
        addedBalance={totalAdded}
        finalBalance={totalRemoved - totalAdded}
        getPoints={getPoints}
        onClose={props.onClose}
        isLoading={isLoading}
        onSave={handleComplete}
      />

      <Dialog
        open={dialogOpen === "itemsEnter"}
        onClose={() => handleModal("")}
        searchedItems={itemsToShowOnAdicionarList}
        randomNumber={randomFactor}
        onClickSaveItems={onClickSaveItemsOnModal}
        shouldShowPoints={true}
        getPoints={calculateUnitaryValue}
        pointsAvaliable={totalRemoved}
        shouldBlockItems={true}
      />
      <Dialog
        open={dialogOpen === "itemsOut"}
        onClose={() => handleModal("")}
        searchedItems={itemsToShowOnRemoverList}
        randomNumber={randomFactor}
        onClickSaveItems={onClickSaveItemsOnModal}
        shouldShowPoints={false}
        getPoints={calculateUnitaryValue}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={4000}
        onClose={onSnackClose}
        open={isSnackOpen}
        style={{ marginBottom: "8vh" }}
      >
        <Alert
          onClose={onSnackClose}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
export default ExchangeLogic;
