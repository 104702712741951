import DataAccess from "../DataAccessStrategy";

export default class RotaValidator extends DataAccess {
  constructor() {
    super("RotaValidator", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      validatorNamespace: String,
    });
  }
}
