import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
const styles = (theme) => ({
  button: {
    width: "80%",
    positiom: "relative",
    margin: "auto",
    borderColor: "rgba(54,137,252,1)",
    color: "#87CEFA",
    backgroundColor: "#FFF",
    height: "6.3vh",
    borderRadius: 2,
    fontWeight: "bold",
    "& :active": {
      color: "rgba(54,137,252,1)",
    },
  },
  text: {
    fontWeight: "bold",
  },
});
/**
 *
 * Botão normalmente utilizado em telas para adicionar ou editar.
 * O componente utiliza este meio de CSS acima.
 */
class ButtonForAdd extends React.Component {
  /**
     * @param {function} props.func Função acionada no 'click'
     * @param {string} props.children Texto que o componente receberá
    * @param {object} props.classes Classes do css vindas do withStyles

    */
  constructor(props) {
    super(props);
  }

  render() {
    let { classes } = this.props;
    return (
      <Button
        variant="outlined"
        className={classes.button}
        style={this.props.styleButton}
        onClick={this.props.func}
        color="primary"
        data-testid="button-add"
      >
        <Typography className={classes.text}>{this.props.children}</Typography>
      </Button>
    );
  }
}
export default withStyles(styles)(ButtonForAdd);
