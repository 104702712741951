import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import EditRemove from "../Buttons/EditRemove";
import Divider from "@material-ui/core/Divider";
import ConfirmDelete from "../../components/Buttons/ConfirmDelete";
import { CSSTransition } from "react-transition-group";

const styles = (theme) => ({
  documentName: {
    padding: "15px",
    fontSize: "1rem",
    // fontFamily:'Roboto, sans-serif',
  },

  actionsColumn: {
    padding: "0 10px 0 0",
  },

  // Edit and Remove Product CSS
  confirmDelete_on: {
    animationName: "$fadeIn",
    animationDuration: ".5s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  confirmDelete_off: {
    animationName: "$fadeOut",
    animationDuration: ".5s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  innerContainer_on: {
    animationName: "$slideLeft",
    animationDuration: ".28s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  innerContainer_off: {
    animationName: "$slideRight",
    animationDuration: ".18s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  "@keyframes slideLeft": {
    from: { transform: "translate(100%, 0)" },
    to: { transform: "translate(0, 0)" },
  },
  "@keyframes slideRight": {
    from: { transform: "translate(0, 0)" },
    to: { transform: "translate(100%, 0)" },
  },
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  "@keyframes fadeOut": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
});

/**
 * Card de cada documento, onde pode excluir e edita-lo
 */
class Documents extends React.Component {
  /**
   * @param {function} props.handleToggleRemoveConfirmDelete Função chamada para exibir e esconder o componente do ComfirmDelete
   * @param {function} props.handleCloseConfirmDelete Função chamada para fechar o componente ConfirmDelete
   * @param {function} props.handleRemoveProduct Função que remove o produto
   * @param {boolean} props.showDeleteConfirm Boleano que define a exibição do componente
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {boolean} this.state.skeleton Estado que que armazena o valor que define se o skeleton é exibido ou não
   */
  constructor(props) {
    super(props);
    this.state = {
      skeleton: false,
    };
    this.handleToggleRemoveConfirmDelete =
      this.handleToggleRemoveConfirmDelete.bind(this);
    this.handleCloseConfirmDelete = this.handleCloseConfirmDelete.bind(this);
    this.handleRemoveProduct = this.handleRemoveProduct.bind(this);
  }

  /**Função que altera a exibição do componente ConfirmDelete
   * @function
   */
  handleToggleRemoveConfirmDelete() {
    this.props.handleToggleRemoveConfirmDelete(this.props.data.hash);
  }

  /**Função que retira o  componente ConfirmDelete da tela
   * @function
   */
  handleCloseConfirmDelete() {
    this.props.handleCloseConfirmDelete(this.props.data.hash);
  }

  /**Função que remove um produto da lista
   * @function
   */
  handleRemoveProduct() {
    this.props.handleRemoveProduct(this.props.data.hash);
  }

  render() {
    let { classes } = this.props;

    return (
      <>
        <Grid container style={{ position: "relative" }} wrap="nowrap">
          <Grid
            item
            container
            justify="space-between"
            wrap="nowrap"
            alignItems="center"
          >
            <Grid item xs={8}>
              <Typography className={classes.documentName}>
                {this.props.data.nome}
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.actionsColumn}>
              <EditRemove
                link={`/documents/edit/${this.props.data.hash}`}
                onDeleteItem={this.handleToggleRemoveConfirmDelete}
                id="123"
              />
            </Grid>
          </Grid>

          <CSSTransition
            in={this.props.showDeleteConfirm}
            timeout={300}
            classNames="noTransitionHere"
            unmountOnExit
          >
            {/* The only reason there is a CSSTransition here is because of of its unmountOnExit funcionality */}
            <ConfirmDelete
              cancelDelete={this.handleCloseConfirmDelete}
              confirmDelete={this.handleRemoveProduct}
              classes={classes}
              confirmText="Deseja mesmo deletar esse tipo de documento?"
              transitionStatus={this.props.showDeleteConfirm}
              deleteConfirmText={this.props.deleteConfirmText}
            />
          </CSSTransition>
        </Grid>
        <Divider />
      </>
    );
  }
}
export default withStyles(styles)(Documents);
