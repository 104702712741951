import React from "react";
import ButtonForAdd from "../Buttons/ButtonForAdd";
import Header from "../HeaderForAdd/Header";
import { Grid, withStyles, TextField, Dialog, Slide } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import LicenseMask from "../MaskedInputs/LicenseMask";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";
const styles = (theme) => ({
  inputsList: {
    paddingBottom: "40px",
    "& > div": {
      paddingBottom: "10px",
    },
  },
  inputContainer: {
    backgroundColor: "#fff",
    position: "relative",
    top: "10px",
    paddingTop: "20px",
  },
  body: {
    backgroundColor: "#F5F5F5",
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 4,
  },
  button: {
    position: "relative",
    top: "30px",
  },
  title: {
    fontSize: "1rem",
    fontFamily: "Roboto, sans-serif",
    wordBreak: "break-all",
  },
  title_dialog: {
    fontSize: ".8rem",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Componente do formulário de cadastrar veículo.
 */
class VehicleForm extends React.Component {
  /**
   * @param {object} props.vehicleContext Contexto de Veículos provido pelo componente pai.
   * @param {function} props.handleToggleVehicleForm Função responsável pela alternância de exibição deste componente.
   * @property {string} this.state.brand Propriedade onde será armazenado a marca.
   * @property {string} this.state.model Propriedade onde será armazenado o modelo.
   * @property {string} this.state.license Propriedade onde será armazenado a placa.
   */
  constructor(props) {
    super(props);
    this.state = {
      brand: { value: "", error: false },
      model: { value: "", error: false },
      license: { value: "", error: false },
    };
    this.checkFiels = this.checkFiels.bind(this);
    this.saveData = this.saveData.bind(this);
  }

  checkFiels() {
    let check = true;
    if (this.state.brand.value === "" || this.state.brand.value.length < 1) {
      this.setState({ brand: { ...this.state.brand, error: true } });
      check = false;
    } else {
      this.setState({ brand: { ...this.state.brand, error: false } });
    }

    if (this.state.model.value === "" || this.state.model.value.length < 1) {
      this.setState({ model: { ...this.state.model, error: true } });
      check = false;
    } else {
      this.setState({ model: { ...this.state.model, error: false } });
    }

    if (
      this.state.license.value === "" ||
      this.state.license.value.length < 8
    ) {
      this.setState({ license: { ...this.state.license, error: true } });
      check = false;
    } else {
      this.setState({ license: { ...this.state.license, error: false } });
    }

    if (check) return true;
    else return false;
  }

  async saveData() {
    try {
      if (!this.checkFiels()) {
        return;
      }
      let newVehicle = {
        marca: this.state.brand.value,
        modelo: this.state.model.value,
        placa: this.state.license.value,
      };

      newVehicle = await this.props.vehicleContext.create(newVehicle);
      this.props.setNewVehicle(newVehicle, "VeiculoId");
      this.props.handleToggleVehicleForm();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    let { classes } = this.props;
    let state = this.state;
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.handleClose}
        TransitionComponent={Transition}
      >
        <div className={classes.body}>
          <Header
            func={this.props.handleToggleVehicleForm}
            icon={<CloseIcon />}
          >
            Criar novo veículo
          </Header>
          <Grid container justify="center" className={classes.inputContainer}>
            <Grid item xs={9} className={classes.inputsList}>
              <Grid item>
                <TextField
                  label="Marca do carro"
                  fullWidth
                  error={this.state.brand.error}
                  helperText={
                    this.state.brand.error && "Informe a marca do carro!"
                  }
                  required
                  onChange={(e) =>
                    this.setState({
                      brand: { ...state.brand, value: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Modelo do carro"
                  fullWidth
                  error={this.state.model.error}
                  helperText={
                    this.state.model.error && "Informe o modelo do carro!"
                  }
                  required
                  onChange={(e) =>
                    this.setState({
                      model: { ...state.model, value: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Placa do carro"
                  fullWidth
                  error={this.state.license.error}
                  helperText={
                    this.state.license.error && "Preencha o campo corretamente!"
                  }
                  required
                  value={this.state.license.value}
                  onChange={(e) =>
                    this.setState({
                      license: {
                        ...state.license,
                        value: e.target.value.toUpperCase(),
                      },
                    })
                  }
                  InputProps={{
                    inputComponent: LicenseMask,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="center" className={classes.button}>
            <ButtonForAdd func={this.saveData}>Salvar veículo</ButtonForAdd>
          </Grid>
        </div>
      </Dialog>
    );
  }
}
VehicleForm.contextType = MessageDialogContext;
export default withStyles(styles)(VehicleForm);
