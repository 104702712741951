class RequirementsService {
  constructor() {
    this.isChrome = this.isChrome();
    this.isChromeVersionValid = this.isChromeVersionValid();
    this.isGeolocationEnabled = this.isGeolocationEnabled();
    this.isIndexedDBEnabled = this.isIndexedDBEnabled();
    this.isLocalStorageEnabled = this.isLocalStorageEnabled();
    this.isServiceWorkerEnabled = this.isServiceWorkerEnabled();
    this.isWebWorkerEnabled = this.isWebWorkerEnabled();
    this.isFetchEnabled = this.isFetchEnabled();
    this.isShareEnabled = this.isShareEnabled();
    this.isBluetoothEnabled = this.isBluetoothEnabled();
    this.isFileSystemHandle = this.isFileSystemHandle();
    this.isAudioVideoEnabled = this.isAudioVideoEnabled();
    this.isWakeLockEnabled = this.isWakeLockEnabled();
    this.isScreenOrientationEnabled = this.isScreenOrientationEnabled();
  }

  isChrome() {
    return navigator.userAgent.indexOf("Chrome") !== -1;
  }

  isChromeVersionValid() {
    const version = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    return version && version[2] >= 107;
  }
  isGeolocationEnabled() {
    return "geolocation" in navigator;
  }

  isIndexedDBEnabled() {
    return "indexedDB" in window;
  }

  isLocalStorageEnabled() {
    return "localStorage" in window;
  }

  isServiceWorkerEnabled() {
    return "serviceWorker" in navigator;
  }

  isWebWorkerEnabled() {
    return "Worker" in window;
  }

  isFetchEnabled() {
    return "fetch" in window;
  }

  isShareEnabled() {
    return "share" in navigator;
  }

  isBluetoothEnabled() {
    return "bluetooth" in navigator;
  }

  isFileSystemHandle = async () => {
    return "FileReader" in window;
  };

  isAudioVideoEnabled() {
    const audio = document.createElement("audio");
    const video = document.createElement("video");

    navigator.mediaDevices.getUserMedia({ audio: true, video: true });

    return (
      (audio.canPlayType("audio/mpeg") !== "" ||
        audio.canPlayType("audio/ogg") !== "") &&
      (video.canPlayType("video/mp4") !== "" ||
        video.canPlayType("video/ogg") !== "")
    );
  }

  isWakeLockEnabled() {
    return "WakeLock" in window;
  }

  isScreenOrientationEnabled() {
    return "ScreenOrientation" in window;
  }

  isRequirementsValid() {
    return (
      this.isChrome &&
      this.isChromeVersionValid &&
      this.isGeolocationEnabled &&
      this.isIndexedDBEnabled &&
      this.isLocalStorageEnabled &&
      this.isServiceWorkerEnabled &&
      this.isWebWorkerEnabled &&
      this.isFetchEnabled &&
      this.isShareEnabled &&
      this.isBluetoothEnabled &&
      this.isFileSystemHandle &&
      this.isAudioVideoEnabled &&
      this.isWakeLockEnabled &&
      this.isScreenOrientationEnabled
    );
  }

  getRequirements() {
    return {
      isChrome: this.isChrome,
      isChromeVersionValid: this.isChromeVersionValid,
      isGeolocationEnabled: this.isGeolocationEnabled,
      isIndexedDBEnabled: this.isIndexedDBEnabled,
      isLocalStorageEnabled: this.isLocalStorageEnabled,
      isServiceWorkerEnabled: this.isServiceWorkerEnabled,
      isWebWorkerEnabled: this.isWebWorkerEnabled,
      isFetchEnabled: this.isFetchEnabled,
      isShareEnabled: this.isShareEnabled,
      isBluetoothEnabled: this.isBluetoothEnabled,
      isFileSystemHandle: this.isFileSystemHandle,
      isAudioVideoEnabled: this.isAudioVideoEnabled,
      isWakeLockEnabled: this.isWakeLockEnabled,
      isScreenOrientationEnabled: this.isScreenOrientationEnabled,
    };
  }

  getRequirementsMessages() {
    const messages = [];
    if (!this.isChrome) {
      messages.push("O navegador não é o Chrome");
    }
    if (!this.isChromeVersionValid) {
      messages.push("Versão do Chrome não é válida");
    }
    if (!this.isGeolocationEnabled) {
      messages.push("Geolocalização não está habilitada");
    }
    if (!this.isIndexedDBEnabled) {
      messages.push("IndexedDB não está habilitada");
    }
    if (!this.isLocalStorageEnabled) {
      messages.push("LocalStorage não está habilitada");
    }
    if (!this.isServiceWorkerEnabled) {
      messages.push("ServiceWorker não está habilitada");
    }
    if (!this.isWebWorkerEnabled) {
      messages.push("WebWorker não está habilitada");
    }
    if (!this.isFetchEnabled) {
      messages.push("Fetch não está habilitada");
    }
    if (!this.isShareEnabled) {
      messages.push("Share não está habilitada");
    }
    if (!this.isBluetoothEnabled) {
      messages.push("Bluetooth não está habilitada");
    }
    if (!this.isFileSystemHandle) {
      messages.push("FileSystemHandle não está habilitada");
    }
    if (!this.isAudioVideoEnabled) {
      messages.push("Audio/Video não está habilitada");
    }
    if (!this.isScreenOrientationEnabled) {
      messages.push("ScreenOrientation não está habilitada");
    }
    return messages;
  }
}

export default RequirementsService;
