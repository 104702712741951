import {
  Divider,
  Grid,
  Radio,
  Switch,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PersonIcon from "@material-ui/icons/Person";
import clsx from "clsx";
import React from "react";
import GreenButton from "../../components/Buttons/GreenButton";
import VehicleForm from "../../components/Forms/VehicleForm";
import Header from "../../components/HeaderForAdd/Header";
import InputSelect from "../../components/Inputs/SelectInput";
import TwoCalendarInputs from "../../components/Inputs/TwoCalendarInputs";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import { OpenRouteContext } from "../../contexts/OpenRouteContext";
import { SyncContext } from "../../contexts/SyncContext";
import { VehicleContext } from "../../contexts/VehicleContext";
import history from "../../router/History";
import { getUser } from "../../service/authentication";

const styles = (theme) => ({
  input: {
    paddingTop: "20px",
  },
  labelRadio: {
    fontSize: "1rem",
    fontFamily: "Roboto,sans-serif",
  },
  labelSelected: {
    color: "#3f51b5",
  },
  separate: {
    paddingTop: "15px",
    paddingBottom: "15px",
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
      fontFamily: "Roboto,sans-serif",
    },
  },
  container1: {
    paddingTop: "3vh",
    "& > .MuiTypography-body1": {
      fontSize: "1.1rem",
      fontFamily: "Roboto,sans-serif",
    },
  },
  dataOpening: {
    paddingTop: "3vh",
    "& > .MuiTypography-body1": {
      fontSize: "1.1rem",
      fontFamily: "Roboto,sans-serif",
    },
    "& spam": {
      paddingLeft: "2vh",
      paddingRigth: "2vh",
    },
    "& svg:first-child": {
      width: "8vh",
      height: "8vh",
      color: "#A9A9A9",
    },
  },
  driverCard: {
    color: "#A9A9A9",
    fontSize: "19px",
  },
  greenButton: {
    paddingTop: "6vh",
    paddingBottom: "3vh",
  },

  twoCalendarInputs_tableContainer: {
    marginTop: "20px",
  },
  twoCalendarInputs_mainContainer: {
    marginTop: "15px",
  },
  twoCalendarInputs_startDateClass: {},
  twoCalendarInputs_endDateClass: {},
  twoCalendarInputs_dateInputClass: {},
});

class OpenRoute extends React.Component {
  constructor() {
    super();
    this.state = {
      separate: false,
      radioSelect: "radio1",
      startDate: { value: "", error: false },
      endDate: { value: "", error: false },
      collector: { value: "", error: false },
      driver: { value: "", error: false },
      vehicle: { value: "", error: false },
      km: { value: "", error: false },
      quilometragemFinal: "",
      isBtnOpenEnabled: true,
    };
    this.errorMessageWasDisplayed = false;
    this.buttonOpenRouteRef = React.createRef();

    this.saveData = this.saveData.bind(this);
    this.clearDate = this.clearDate.bind(this);
    this.checkFields = this.checkFields.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleToggleVehicleForm = this.handleToggleVehicleForm.bind(this);
    this.renderVehicleForm = this.renderVehicleForm.bind(this);
  }

  async fetchQuilometragemFinal() {
    try {
      const user = await getUser();
      const response = await fetch(
        `${user.service}/api/quilometragem-ultimo-fechamento?token=${user.sessao.token}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + user.sessao.token,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("dataaa", parseFloat(data.quilometragemFinal));
        this.setState({
          quilometragemFinal: parseFloat(data.quilometragemFinal),
        });
      } else {
        console.error("Falha ao buscar a quilometragem final");
      }
    } catch (error) {
      console.error("Erro ao buscar a quilometragem final:", error);
    }
  }

  async saveData() {
    this.setState({ isBtnOpenEnabled: false });
    if (!this.checkFields()) {
      this.setState({ isBtnOpenEnabled: true });
      return;
    }

    let data = {
      cobrador: this.state.collector.value,
      motorista: this.state.driver.value,
      veiculo: this.state.vehicle.value,
      quilometragemVeiculoInicio:
        this.state.km.value || this.state.quilometragemFinal,
      listaDiariaInicio: this.state.startDate.value,
      listaDiariaFinal: this.state.endDate.value,
      separar: this.state.separate,
    };
    if (this.state.radioSelect === "radio1") {
      data.palma = false;
    } else {
      data.palma = true;
    }

    try {
      this.errorMessageWasDisplayed = true;
      await this.context.createRoute(data);
      history.push("/");
    } catch (error) {
      console.error(error);
    }
    this.setState({ isBtnOpenEnabled: true });
  }

  clearDate() {
    this.setState({ startDate: { ...this.state.startDate, value: null } });
    this.setState({ endDate: { ...this.state.endDate, value: null } });
  }

  checkFields() {
    let noErrors = true;

    if (this.state.radioSelect === "radio1") {
      if (this.state.startDate.value === null) {
        this.setState({ startDate: { ...this.state.startDate, error: true } });
        noErrors = false;
      } else {
        this.setState({ startDate: { ...this.state.startDate, error: false } });
      }

      if (this.state.endDate.value === null) {
        this.setState({ endDate: { ...this.state.endDate, error: true } });
        noErrors = false;
      } else {
        this.setState({ endDate: { ...this.state.endDate, error: false } });
      }
    }

    if (
      this.state.collector.value === null ||
      this.state.collector.value === "" ||
      !this.state.collector.value
    ) {
      this.setState({ collector: { ...this.state.collector, error: true } });
      noErrors = false;
    } else {
      this.setState({ collector: { ...this.state.collector, error: false } });
    }

    if (
      this.state.driver.value === null ||
      this.state.driver.value === "" ||
      !this.state.collector.value
    ) {
      this.setState({ driver: { ...this.state.driver, error: true } });
      noErrors = false;
    } else {
      this.setState({ driver: { ...this.state.driver, error: false } });
    }

    if (
      this.state.vehicle.value === null ||
      this.state.vehicle.value === "" ||
      !this.state.collector.value
    ) {
      this.setState({ vehicle: { ...this.state.vehicle, error: true } });
      noErrors = false;
    } else {
      this.setState({ vehicle: { ...this.state.vehicle, error: false } });
    }

    if (
      (this.state.km.value === null ||
        this.state.km.value === "" ||
        !this.state.collector.value) &&
      (this.state.quilometragemFinal === null ||
        this.state.quilometragemFinal === "")
    ) {
      // Aqui verificamos tanto a quilometragem informada quanto a quilometragem final.
      this.setState({ km: { ...this.state.km, error: true } });
      noErrors = false;
    } else {
      this.setState({ km: { ...this.state.km, error: false } });
    }

    return noErrors;
  }

  async handleChangeDate(startDate, endDate) {
    let state = this.state;
    state.startDate.value = startDate;
    state.endDate.value = endDate;
    this.setState({ state });
  }

  handleChange(dataType, data) {
    let state = this.state;

    if (dataType === "km" && typeof data === "number") {
      state[dataType] = { value: data, error: false };
    } else {
      state[dataType].value = data;
    }

    this.setState({ state });
  }

  handleToggleVehicleForm() {
    this.setState({ vehicleFormIsOpen: !this.state.vehicleFormIsOpen });
  }

  renderVehicleForm() {
    return (
      <VehicleForm
        open={this.state.vehicleFormIsOpen}
        handleClose={() =>
          this.setState({ vehicleFormIsOpen: !this.state.vehicleFormIsOpen })
        }
        handleToggleVehicleForm={this.handleToggleVehicleForm}
        vehicleContext={this.vehicleContext}
        setNewVehicle={async (newVehicle) => {
          if (!newVehicle) return;
          // let apiNewVehicle = await this.vehicleContext.sendVehicle(newVehicle);
          await this.syncContext.syncVehicle();
          await this.vehicleContext.updateSelf();

          //

          this.handleChange(
            "vehicle",
            this.vehicleContext.vehicle[this.vehicleContext.vehicle.length - 1]
              .id
          );
        }}
      />
    );
  }

  async componentDidMount() {
    await this.setDefaultValues();

    await this.fetchQuilometragemFinal();
  }

  async setDefaultValues() {
    await this.context.updateSelf();
    if (this.context.isBitboxEmployee) {
      let vehicle = this.context.teamVehicle;
      if (!vehicle)
        vehicle = this.context.vehicle[0] ? this.context.vehicle[0].id : null;

      this.setState({ vehicle: { value: vehicle, error: false } });
    } else {
      let vehicle = this.context.teamVehicle;
      if (!vehicle)
        vehicle = this.context.vehicle[0] ? this.context.vehicle[0].id : null;

      const employee = this.context.employee;

      this.setState({
        vehicle: { value: vehicle, error: false },
        collector: { value: employee && employee[0]?.id, error: false },
        driver: { value: employee && employee[0].id, error: false },
      });
    }
  }

  async componentDidUpdate() {
    if (this.context.routeIsOpen && !this.errorMessageWasDisplayed) {
      this.errorMessageWasDisplayed = true;
      await this.messageContext.addAsyncDialog({
        message: "Já existe uma rota aberta para sua equipe",
        title: "Não é possivel abrir rota",
        type: "warning",
      });
      this.props.history.goBack();
    }
  }

  render() {
    let { classes } = this.props;
    let labelSelected = clsx({
      [classes.labelRadio]: true,
      [classes.labelSelected]: true,
    });

    const ContextUpdater = (props) => (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.messageContext !== context) this.messageContext = context;

          return (
            <SyncContext.Consumer>
              {(syncContext) => {
                if (this.syncContext !== syncContext)
                  this.syncContext = syncContext;

                return props.children;
              }}
            </SyncContext.Consumer>
          );
        }}
      </MessageDialogContext.Consumer>
    );

    return (
      <Slide
        direction="up"
        in={true}
        mountOnEnter
        unmountOnExit
        style={{ minHeight: "100%" }}
      >
        <div>
          <ContextUpdater></ContextUpdater>
          <Grid container>
            <Grid item xs={12}>
              <Header link="/" icon={<CloseIcon fontSize="large" />}>
                Abrir Rota
              </Header>
              <Divider />
            </Grid>
            <Grid container justify="center">
              <Grid item xs={11} className={classes.container1}>
                <Typography>DADOS PARA A LISTA DIÁRIA</Typography>
                <Divider />

                <Grid container alignItems="center">
                  <Radio
                    checked={this.state.radioSelect === "radio1"}
                    onChange={() => this.setState({ radioSelect: "radio1" })}
                    color="primary"
                    size="small"
                  />
                  <Typography
                    className={
                      this.state.radioSelect === "radio1"
                        ? labelSelected
                        : classes.labelRadio
                    }
                    display="inline"
                    onClick={() => this.setState({ radioSelect: "radio1" })}
                  >
                    Carregar agendamentos
                  </Typography>
                </Grid>

                <Grid container alignItems="center">
                  <Radio
                    checked={this.state.radioSelect === "radio2"}
                    onChange={() => this.setState({ radioSelect: "radio2" })}
                    color="primary"
                    size="small"
                  />
                  <Typography
                    className={
                      this.state.radioSelect === "radio2"
                        ? labelSelected
                        : classes.labelRadio
                    }
                    display="inline"
                    onClick={() => {
                      this.setState({ radioSelect: "radio2" });
                      this.clearDate();
                    }}
                  >
                    Abrir para bater palma
                  </Typography>

                  {this.state.radioSelect === "radio1" ? (
                    <>
                      <TwoCalendarInputs
                        classes={{
                          startDateClass:
                            classes.twoCalendarInputs_startDateClass,
                          endDateClass: classes.twoCalendarInputs_endDateClass,
                          dateInputClass:
                            classes.twoCalendarInputs_dateInputClass,
                          tableContainer:
                            classes.twoCalendarInputs_tableContainer,
                          mainContainer:
                            classes.twoCalendarInputs_mainContainer,
                        }}
                        onChange={this.handleChangeDate}
                        errors={{
                          errorStart: this.state.startDate.error,
                          errorEnd: this.state.endDate.error,
                        }}
                        startDate={this.state.startDate.value}
                        endDate={this.state.endDate.value}
                        activePreset="openRoute"
                        required
                        disablePast={true}
                      />
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.separate}
                      >
                        <Typography>Dividir rota?</Typography>
                        <Switch
                          onChange={() =>
                            this.setState({ separate: !this.state.separate })
                          }
                          color="primary"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={12} className={classes.dataOpening}>
                    <Typography>DADOS SOBRE A ABERTURA</Typography>
                    <Divider />
                    <Grid
                      container
                      wrap="nowrap"
                      alignItems="center"
                      className={classes.input}
                      spacing={1}
                    >
                      <Grid item xs={2}>
                        <PersonIcon fontSize="large" />
                      </Grid>
                      <Grid item xs={10}>
                        <InputSelect
                          outlined
                          onChange={(data) =>
                            this.handleChange("collector", data)
                          }
                          required
                          value={this.state.collector.value}
                          label={
                            <span style={{ paddingLeft: "2vh" }}>Cobrador</span>
                          }
                          menuItem={this.context.employee}
                          error={this.state.collector.error}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      wrap="nowrap"
                      alignItems="center"
                      className={classes.input}
                      spacing={1}
                    >
                      <Grid item xs={2}>
                        <Grid container justify="center">
                          <span className={classes.driverCard}>
                            <i className="fas fa-id-card fa-2x" />
                          </span>
                        </Grid>
                      </Grid>
                      <Grid item xs={10}>
                        <InputSelect
                          outlined
                          onChange={(data) => this.handleChange("driver", data)}
                          label={
                            <span style={{ paddingLeft: "2vh" }}>
                              Motorista
                            </span>
                          }
                          menuItem={this.context.employee}
                          value={this.state.driver.value}
                          error={this.state.driver.error}
                          required
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      alignItems="flex-start"
                      className={classes.input}
                      spacing={1}
                    >
                      <Grid item xs={2}>
                        <Grid
                          container
                          justifyContent="flex-end"
                          alignItems="center"
                          className={classes.addIcon}
                        >
                          {!this.context.isBitboxEmployee ? (
                            <AddIcon
                              fontSize="small"
                              onClick={this.handleToggleVehicleForm}
                            />
                          ) : (
                            <LocalShippingIcon fontSize="small" />
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <VehicleContext.Consumer>
                          {(vehicleContext) => (
                            <InputSelect
                              outlined
                              onChange={(data) =>
                                this.handleChange("vehicle", data)
                              }
                              label={
                                <span style={{ paddingLeft: "2vh" }}>
                                  Veículo
                                </span>
                              }
                              value={this.state.vehicle.value}
                              menuItem={
                                vehicleContext && vehicleContext.vehicle
                              }
                              error={this.state.vehicle.error}
                              required
                              variant="outlined"
                            />
                          )}
                        </VehicleContext.Consumer>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          variant="outlined"
                          label={
                            this.state.quilometragemFinal !== ""
                              ? "Última Km"
                              : "Km"
                          }
                          value={
                            this.state.quilometragemFinal !== ""
                              ? this.state.quilometragemFinal.toString()
                              : this.state.km.value === null
                              ? ""
                              : this.state.km.value.toString()
                          }
                          onKeyDown={(evt) =>
                            evt.key === "e" && evt.preventDefault()
                          }
                          onChange={(e) =>
                            this.setState({
                              quilometragemFinal: e.target.value.replace(
                                /e/g,
                                ""
                              ),
                            })
                          }
                          type="number"
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.greenButton}>
                      <GreenButton
                        onClickButton={this.saveData}
                        disabled={!this.state.isBtnOpenEnabled}
                      >
                        <Typography>ABRIR ROTA</Typography>
                      </GreenButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {this.renderVehicleForm()}
        </div>
      </Slide>
    );
  }
}

OpenRoute.contextType = OpenRouteContext;
export default withStyles(styles)(OpenRoute);
