import React from "react";
import ExchangeLogic from "../../components/Exchanges/ExchangeLogic";
import { ExchangeContext } from "../../contexts/ExchangeContext";
import LocalStorage from "../../utils/localStorage";
import Backdrop from "../../components/Dialogs/BackdropLoader";
import * as moment from "moment";
import history from "../../router/History";
import Moeda from "../../utils/Moeda";
import { Typography } from "@material-ui/core";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";

class Exchange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      scheduleDate: new Date(),
      prevExchanges: {
        itemsEnter: [],
        itemsOut: [],
      },
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
  }

  componentDidMount() {
    this.setInitialState();
  }

  async setInitialState() {
    const routeOpening = await LocalStorage.instance.getItem("route-opening");
    const scheduleDate = moment(routeOpening.listaDiariaFinal, "DD/MM/YYYY")
      .add(1, "day")
      .toDate();

    this.setState({ scheduleDate });
  }

  mapToExchangeItem({ itemsEnter, itemsOut }) {
    const mapToExchangeItem = (productExchange) => ({
      ProdutoId: productExchange.id,
      quantidade: productExchange.quantidade,
    });

    const adicionados = itemsEnter.map(mapToExchangeItem);
    const retirados = itemsOut.map(mapToExchangeItem);
    return {
      adicionados,
      retirados,
    };
  }

  handleClose() {
    history.goBack();
  }

  async handleComplete(exchangeData) {
    this.setState({ isLoading: true });
    const { saldoAdicionar, saldoRetirar } =
      this.calculateProductsBalance(exchangeData);
    const { retirados, adicionados } = this.mapToExchangeItem(exchangeData);

    const clientHash = this.props.match.params.hash;
    const receivingDate = exchangeData.scheduleDate;

    const exchange = {
      retirados,
      adicionados,
      saldoRetirar,
      saldoAdicionar,
    };

    try {
      const exchangeHash = await this.props.exchangeContext.createExchange(
        clientHash,
        exchange,
        receivingDate
      );

      history.push(`/exchange/report/${clientHash}/${exchangeHash}`);
    } catch (error) {
      console.error(error);
      this.context.addDialog({
        title: "Falha ao lançar troca!",
        message: <Typography>{error.message}</Typography>,
        type: "error",
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  calculateProductsBalance({ itemsEnter, itemsOut }) {
    const calculateValue = (memo, product) =>
      memo.add(product.valor).mul(product.quantidade);
    const saldoAdicionar = itemsEnter.reduce(calculateValue, Moeda.create(0));
    const saldoRetirar = itemsOut.reduce(calculateValue, Moeda.create(0));

    return {
      saldoAdicionar: saldoAdicionar.mount(),
      saldoRetirar: saldoRetirar.mount(),
    };
  }

  render() {
    return (
      <>
        <Backdrop open={this.state.isLoading} />
        <ExchangeLogic
          prevExchanges={this.state.prevExchanges}
          onValidStateChange={console.warn}
          isDetachedExchange={true}
          onClose={this.handleClose}
          isLoading={this.state.isLoading}
          onComplete={this.handleComplete}
          defaultScheduleDate={this.state.scheduleDate}
        />
      </>
    );
  }
}
Exchange.contextType = MessageDialogContext;

export default function ExchangeWrapper(props) {
  const exchangeContext = React.useContext(ExchangeContext);
  return <Exchange {...props} exchangeContext={exchangeContext} />;
}
