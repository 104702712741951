import React, { createContext } from "react";
import { Documento } from "../repository/models";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import AppStorageError from "../errorDefinition/AppStorageError";
import BusinessError from "../errorDefinition/BusinessError";

import { AbstractContext } from "./AbstractContext";
const documento = new Documento();
const DocumentContext = createContext({
  get document() {
    return [];
  },
});

const success = (context, message) =>
  context.addAsyncDialog({
    message: message,
    title: "Sucesso!",
    type: "success",
    hasCloseButton: false,
  });

/**
 * Contexto utilizado na pagina de Documentos.
 */
const OBSERVED_MODELS = ["Documento"];
class DocumentContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.updateSelf = this.updateSelf.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.getDocumentByHash = this.getDocumentByHash.bind(this);
    this.getById = this.getById.bind(this);

    this.state = {
      isLoading: false,
      get document() {
        return documento.filter((a) => a.ativo);
      },
      create: this.create,
      update: this.update,
      getById: this.getById,
      getDocumentByHash: this.getDocumentByHash,
    };
  }

  componentDidMount() {
    this.updateSelf();
  }

  observable() {
    this.updateSelf();
  }
  /**Função responsável por atualiar os dados armazenados no estado.
   * @function
   */
  async updateSelf() {
    this.setState({ isLoading: true });

    this.setState({ isLoading: false });
  }

  /**Função responsável pela criação de um novo documento.
   * @function
   */
  async create(data) {
    let documentExist = (await this.state.document).find(
      (a) => a.nome == data.nome
    );
    if (!documentExist) {
      try {
        data.ativo = true;
        data.shouldSync = true;
        await documento.create(data);
        this.updateSelf();
        await success(this.context, "Documento criado com sucesso!");
      } catch (error) {
        new AppStorageError({
          message: error.message,
          title: "Falha ao criar novo documento!",
          type: "error",
          method: "create",
        });
        throw error;
      }
    } else {
      throw new BusinessError({
        message: "Um documento com este nome já existe.",
        title: "Falhar ao criar novo documento.",
        type: "warning",
        method: "create",
      });
    }
  }

  /**Função responsável por atualizar os dados de um documento já existente.
   * @function
   */
  async update(data) {
    try {
      data.shouldSync = true;
      await documento.put(data);
      await this.updateSelf();
      await success(this.context, "Documento atualizado com sucesso!");
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao atualizar os dados do documento!",
        type: "error",
        method: "update",
      });
      throw error;
    }
  }

  /**Função responsável por buscar um documento pela hash.
   * @function
   */
  async getDocumentByHash(hash) {
    return await documento.findByHash(hash);
  }

  async getById(id) {
    (await this.state.document).find((a) => a.id === id);
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <DocumentContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </DocumentContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { DocumentContext, DocumentContextProvider };
