import DataAccess from "../DataAccessStrategy";

export default class ClienteBairro extends DataAccess {
  constructor() {
    super("ClienteBairro", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      BairroId: Number,
      ClienteId: Number,
    });
  }

  async updateClienteBairroModels(oldClientId, newClientId) {
    if (newClientId && oldClientId && parseInt(oldClientId) < 0) {
      let clientNeighborhood = await this.model
        .where("ClienteId")
        .equals(oldClientId)
        .toArray();

      for (let clienteBairro of clientNeighborhood) {
        clienteBairro.ClienteId = newClientId;
        await this.model.put(clienteBairro);
      }
    }
  }
}
