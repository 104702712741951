import React from "react";
import {
  withStyles,
  Grid,
  Typography,
  Fab,
  TextField,
  Button,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import CardPhone from "../CardComponents/TelefoneInputCard/index";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import AddDocuments from "../Dialogs/AddDocument";
import CpfMask from "../MaskedInputs/CpfMask";
import CepMask from "../MaskedInputs/CepMask";
import CloseIcon from "@material-ui/icons/Close";
import CancelOrSave from "../Buttons/CancelOrSave";
import SelectInput from "../Inputs/SelectInput";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import { If } from "../If";
import { generateCpf } from "../../utils/generateValidCpf";
import { getUser } from "../../service/authentication";

const styles = (theme) => ({
  helperText: {
    fontSize: "0.75rem",
    margin: 2,
  },
  signature: {
    height: "200px",
    border: "1px solid #000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  text: {
    fontSize: "0.7rem",
  },
  input: {
    paddingTop: "10px",
  },
  backgroundPage: {
    backgroundColor: "#F5F5F5",
  },
  cardInputs: {
    backgroundColor: "#fff",
    padding: "20px",
    marginTop: "15px",
  },
  cancelOrSave: {
    position: "relative",
  },
  sigImage: {
    width: "100%",
    height: "100%",
    fontSize: "1.5rem",
  },
  recomended: {
    color: "#4682B4",
    fontFamily: "Roboto, sans-serif",
  },
  addNeighborhood: {
    height: "100%",
    color: "#1473cc",
    "& :active": {
      color: "#6495ED",
    },
  },
  documentCard: {
    paddingBottom: "5px",
    "& > div": {
      border: "1px solid #696969",
      borderRadius: "8px",
      padding: "5px",
    },
  },

  sectionTitle: {
    fontWeight: 500,
    fontSize: "1.1rem",
    color: "#141414",
    textTransform: "uppercase",
  },

  errorSignature: {
    color: "#f44336",
    margin: 0,
    fontSize: "0.75rem",
    marginTop: "3px",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
});

const SEXO = [
  {
    nome: "Nenhum",
    id: "null",
  },
  {
    nome: "Masculino",
    id: "Masculino",
  },
  {
    nome: "Feminino",
    id: "Feminino",
  },
];
/**
 * Conjunto de inputs usados no formulário de cliente
 */
class AddClient extends React.Component {
  /**
   * @param {string} props.image Imagem de perfil do cliente em BASE64
   * @param {string} props.signatureImage Imagem da assinatura do cliente em BASE64
   * @param {function} props.saveClient Função que salvará no componente pai os dados digitados no input
   * @param {function} props.handleToggleSignature Função que altera a exibição do componente de assinatura
   * @param {boolean} props.redirect Boleano que definirá o redirecionamento da página
   * @param {object} props.classes Classes do CSS
   * @property {string} this.state.nome Estado que armazena o nome digitado
   * @property {string} this.state.cpf Estado que armazena o cpf digitado
   * @property {string} this.state.cpfConjuge Estado que armazena o conjugeCPF digitado
   * @property {string} this.state.estadoCivil Estado que armazena o estadoCivil selecionado
   * @property {string} this.state.sexo Estado que armazena o sexo digitado
   * @property {Date} this.state.dataNascimento Estado que armazena a data de nascimento informada
   * @property {array} this.state.telefone Estado que armazena a lista de telefones
   * @property {string} this.state.cep Estado que armazena o cep digitado
   * @property {string} this.state.estado Estado que armazena o estado selecionado
   * @property {string} this.state.cidade Estado que armazena o cep selecionado
   * @property {string} this.state.bairro Estado que armazena o cep selecionado
   * @property {string} this.state.logradouro Estado que armazena o logradouro digitado
   * @property {string} this.state.numero Estado que armazena o numero digitado
   * @property {array} this.state.documentos Estado que armazena os documentos e suas respectivas imagens
   * @property {string} this.state.pontoReferencia Estado que armazena a pontoReferencia digitada.
   * @property {string} this.state.observacao Estado que armazena a observação digitada.
   */
  constructor(props) {
    super(props);
    this.state = {
      nome: { value: "", error: false },
      cpf: { value: "", error: false },
      cpfConjuge: { value: "", error: false },
      nomeConjuge: { value: "", error: false },
      email: { value: "", error: false },
      estadoCivil: { value: "", error: false },
      sexo: { value: "", error: false },
      dataNascimento: { value: null, error: false },
      telefone: { value: [""], error: [] },
      cep: { value: "", error: false },
      estado: { value: "", error: false },
      cidade: { value: "", error: false },
      bairro: { value: "", error: false },
      logradouro: { value: "", error: false },
      numero: { value: "", error: false },
      documentos: { value: [], error: false },
      pontoReferencia: { value: "", error: false },
      observacao: { value: "", error: false },
      foto: "",
      assinatura: { value: "", error: false },
      // countPhoneNumber: (props.clientData && props.clientData.telefone.length && props.clientData.telefone) || [1],
      popUpDocuments: false,
      removedDocs: [],
      removedTelephones: [],
      alteredTelephones: [],
      user: undefined,
    };
    this.nascimentoInputRef = React.createRef();

    this.getFilteredStates = this.getFilteredStates.bind(this);
    this.getFilteredCities = this.getFilteredCities.bind(this);
    this.getFilteredNeighborhoods = this.getFilteredNeighborhoods.bind(this);
    this.sortedListNeightborhoods = this.sortedListNeightborhoods.bind(this);
    this.setDefaultCity = this.setDefaultCity.bind(this);
    this.setDefaultEstado = this.setDefaultEstado.bind(this);
    this.setClientData = this.setClientData.bind(this);
    this.saveInDataBase = this.saveInDataBase.bind(this);
    this.clickPopUpDocuments = this.clickPopUpDocuments.bind(this);
    this.setPhoneNumber = this.setPhoneNumber.bind(this);
    this.onClickAddPhone = this.onClickAddPhone.bind(this);
    this.clickRemoveDocuments = this.clickRemoveDocuments.bind(this);
    this.clickRemovePhoneNumber = this.clickRemovePhoneNumber.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleSaveDocuments = this.handleSaveDocuments.bind(this);
    this.checkItemBelongsToArray = this.checkItemBelongsToArray.bind(this);
    this.controllerRecomended = this.controllerRecomended.bind(this);
    this.controllerDisabledFields = this.controllerDisabledFields.bind(this);
    this.countNumberInString = this.countNumberInString.bind(this);
    this.cidadeMapper = this.cidadeMapper.bind(this);
    this.estadoMapper = this.estadoMapper.bind(this);
    this.bairroMapper = this.bairroMapper.bind(this);
    this.checkFields = this.checkFields.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
    this.getDocumentById = this.getDocumentById.bind(this);
  }

  componentDidMount() {
    if (this.props.clientData) {
      this.setClientData();
    }
    getUser().then((user) => {
      this.setState({ user });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.estado.value) {
      this.setDefaultEstado();
    }
    if (!this.state.cidade.value) {
      this.setDefaultCity();
    }
  }

  async getFilteredStates() {
    let filteredStates = await this.props.neighborhoodContext.states;
    return filteredStates;
  }
  async getFilteredCities() {
    let filteredCities = await this.props.neighborhoodContext.cities;
    return filteredCities;
  }

  async getFilteredNeighborhoods() {
    if (this.state.cidade.value) {
      let refCity = this.state.cidade.value;
      const neighborhood = await this.props.neighborhoodContext.neighborhood;
      let filteredNeighborhoods = neighborhood.filter((bairro) => {
        return bairro.cidade == refCity;
      });
      return this.sortedListNeightborhoods(filteredNeighborhoods);
    }
    return [];
  }

  sortedListNeightborhoods(neightborhoods) {
    return neightborhoods.sort((a, b) => a.nome.localeCompare(b.nome));
  }

  async setDefaultCity() {
    const cities = await this.props.neighborhoodContext.cities;
    if (cities && cities.length) {
      this.setState({
        cidade: {
          value: cities[0].cidade,
          error: false,
        },
      });
    }
  }

  async setDefaultEstado() {
    const states = await this.props.neighborhoodContext.states;
    if (states && states.length) {
      this.setState({
        estado: {
          value: states[0].nome,
          error: false,
        },
      });
    }
  }

  setClientData() {
    let state = { ...this.state };
    let clientData = this.props.clientData;

    state.nome.value = clientData.nome;
    state.cpf.value = clientData.cpf || "";
    state.email.value = clientData.email || "";
    // state.estadoCivil.value = !!clientData.estadoCivil && clientData.estadoCivil != 'undefined' ? clientData.estadoCivil : 'null'
    state.sexo.value =
      !!clientData.sexo && clientData.sexo != "undefined"
        ? clientData.sexo
        : "null";
    state.nomeConjuge.value = clientData.nomeConjuge
      ? clientData.nomeConjuge || ""
      : "";
    state.cpfConjuge.value = clientData.conjugeCpf ? clientData.conjugeCpf : "";
    state.dataNascimento.value = clientData.dataNascimento || "";
    state.cep.value = clientData.cep;
    state.estado.value = clientData.estado;
    state.documentos.value = clientData.documentos || [];
    state.cidade.value = clientData.cidade;
    state.bairro.value = clientData.bairroIdCache || clientData.bairro;
    state.logradouro.value = clientData.logradouro;
    state.numero.value = clientData.numero;
    state.pontoReferencia.value = clientData.pontoReferencia;
    state.observacao.value = clientData.observacao;
    state.foto = this.props.image || clientData.foto || "";
    state.assinatura = this.props.signatureImage || clientData.assinatura || "";
    if (clientData.telefone.length && clientData.telefone.map) {
      state.telefone.value = [...clientData.telefone];
    } else {
      if (clientData.telefone.length === 0) {
        state.telefone.value = [""];
      } else {
        state.telefone.value = [clientData.telefone];
      }
    }
    this.setState({ state });
  }

  /**Função que envia um objeto com os dados digitados no formulário para o elemento pai, aonde será salvo no bancoe redirecionado em seguida
   * @function
   * @param {string} number número a ser adicionado.
   */
  async saveInDataBase() {
    this.setState({ isLoading: true });
    if (!(await this.checkRequiredFields())) return;
    let clientData = {
      ...this.props.clientData,
      nome: this.state.nome.value,
      cpf: this.state.cpf.value,
      nomeConjuge: this.state.nomeConjuge.value,
      conjugeCpf: this.state.cpfConjuge.value,
      email: this.state.email.value,
      sexo: this.state.sexo.value == "null" ? null : this.state.sexo.value,
      dataNascimento: this.state.dataNascimento.value,
      cep: this.state.cep.value,
      estado: this.state.estado.value,
      cidade: this.state.cidade.value,
      logradouro: this.state.logradouro.value,
      numero: this.state.numero.value,
      pontoReferencia: this.state.pontoReferencia.value,
      observacao: this.state.observacao.value,
      bairroIdCache: this.state.bairro.value,
      bairro: this.state.bairro.value,
      documentos: [...this.state.documentos.value],
      removedDocs: this.state.removedDocs,
      foto: this.props.image,
      shouldUploadFoto: this.props.shouldUploadFoto,
      assinatura: this.props.signatureImage,
      telefone:
        this.state.telefone.value[0] == "" ? [] : this.state.telefone.value,
      alteredTelephones: this.state.alteredTelephones,
      removedTelephones: this.state.removedTelephones,
    };

    await this.props.saveClient(clientData);
  }

  /**Função que altera a exibição do Dialog responsável por adicionar documentos
   * @function
   */
  clickPopUpDocuments() {
    this.setState({ popUpDocuments: !this.state.popUpDocuments });
  }

  /**Função que salva os numeros de telefones adicionados
   * @function
   * @param {string} number número a ser adicionado.
   * @param {number} index índice da lista em que será adicionado o número.
   */
  setPhoneNumber(number, index) {
    let telefone = { ...this.state.telefone };
    let alteredTelephones = [...this.state.alteredTelephones];
    let i = null;

    if (telefone.value[index] && telefone.value[index].id) {
      let hasBeenAltered = alteredTelephones.some((tel, ind) => {
        if (tel.id == telefone.value[index].id) {
          i = ind;
          return true;
        }
        return false;
      });
      if (hasBeenAltered) {
        alteredTelephones[i].numero = number;
      } else {
        alteredTelephones.push(telefone.value[index]);
      }
      telefone.value[index].numero = number;
    } else {
      telefone.value[index] = number;
    }
    this.setState({ telefone: telefone, alteredTelephones });
  }

  /**Função que renderiza mais um campo para adicionar novo número de telefone
   * @function
   */
  onClickAddPhone(e) {
    let indice = this.state.telefone.value.length - 1;
    if (
      this.state.telefone.value[indice] != null &&
      this.state.telefone.value[indice] != ""
    ) {
      this.setState({
        telefone: {
          ...this.state.telefone,
          value: [...this.state.telefone.value, ""],
          error: [...this.state.telefone.error, false],
        },
      });
    } else if (indice == -1) {
      this.setState({ telefone: [""] });
    }
  }

  /**Função que remove um documento selecionado da lista de documentos
   * @function
   * @param {number} index índice da lista em que será removido o documento.
   */
  clickRemoveDocuments(index) {
    let array = [...this.state.documentos.value];
    let removedDocs = [...this.state.removedDocs];

    removedDocs = removedDocs.concat(array.splice(index, 1));
    this.setState({
      documentos: { ...this.state.documentos, value: array },
      removedDocs,
    });
  }

  /**Função que remove um numero de telefone selecionado da lista de telefones
   * @function
   * @param {number} index índice da lista em que será removido o telefone.
   */
  async clickRemovePhoneNumber(index) {
    const shouldRemovePhone = await this.context.addAsyncDialog({
      message: "Realmente deseja remover este número de telefone?",
      title: "Por favor confirme!",
      type: "warning",
      okText: "Sim",
      cancelText: "Não",
    });
    if (!shouldRemovePhone) return;
    let removedTelephones = [...this.state.removedTelephones];
    const telefone = { ...this.state.telefone };

    let removedTel = telefone.value.splice(index, 1);
    if (typeof removedTel[0] != "object") {
      removedTel = [];
    }

    removedTelephones = removedTelephones.concat(removedTel);

    this.setState({
      telefone,
      removedTelephones,
    });
  }

  handleChangeInput(stateName, value, valid) {
    let state = { ...this.state };
    state[stateName].value = value;
    if (stateName === "dataNascimento") {
      const isValidDate = moment(valid).isValid();
      state[stateName].error = !isValidDate && value != null;
      state[stateName].value = isValidDate ? valid : "";
    }
    this.setState({ state });
  }

  handleSaveDocuments(id, name, image) {
    let documents = { ...this.state.documentos };
    documents.value.push({
      DocumentoId: id,
      nome: name,
      foto: image,
    });

    this.setState({ documentos: documents });
  }

  /**
   * Checa se um campo é recomentado ou obrigatório, fazendo  uma busca sequencial pela array passada
   *
   * @example
   * checkItemBelongsToArray = (array,name)=>{
   *   let result = false
   *   array.forEach(el=>{
   *       if(el.attributeName == name)
   *           result = true
   *   })
   *   return result
   *}
   */
  checkItemBelongsToArray(array, name) {
    let result = false;
    array.forEach((el) => {
      if (el.attributeName == name) result = true;
    });
    return result;
  }

  /**Função onde é feito o controle da customização dos campos recomendados
   * Importante: Se ocorrer um erro em algum campo, a customização será cancelada e o erro permanecerá
   * @function
   * @param {array} array lista .
   * @param {string} name nome do campo a ser verificado.
   * @param {boolean} error boleano que indica se ocorreu algum erro na validação.
   */
  controllerRecomended(name, error) {
    if (
      this.checkItemBelongsToArray(
        this.props.context.recommendedFields,
        name
      ) &&
      error == false
    )
      return true;
    else return false;
  }

  controllerDisabledFields(name) {
    const shouldExecute = this?.state?.user?.cargo?.executarNaoEditaveis;
    if (shouldExecute === false) return;

    if (this.props.clientData) {
      return this.checkItemBelongsToArray(this.props.context.notEditableFields, name)
    } else {
      return false;
    }
  }

  /**Função onde é feito a contagem de numeros em um campo, por exemplo o campo de CPF
   * @function
   * @param {string} data String a passar pela contagem .
   * @param {number} length Número mínimo de numeros na string.
   * @returns {boolean} Retorna true ou false, true se o numero de numéros for igual ou maior doque o parametro length passado
   * e false se for menor
   */
  countNumberInString(data, length) {
    if (!data) {
      return false;
    }
    if (typeof data === "object") {
      data = data.numero;
    }
    if (data != "" || data != undefined) {
      let string = data.split("");
      let i = 0,
        j = 0;
      string.forEach((el) => {
        for (let cont = 0; cont < 10; cont++) {
          if (parseInt(el) == cont) i += 1;
          else j += 1;
        }
      });
      if (i < length) {
        if (i == 0 && j == 0) return true;
        else return false;
      } else return true;
    }
  }

  cidadeMapper(data, index) {
    return (
      <MenuItem
        key={index}
        value={data.cidade}
        selected={data.cidade == this.state.cidade.value}
      >
        {data.cidade}
      </MenuItem>
    );
  }

  estadoMapper(data, index) {
    return (
      <MenuItem
        key={index}
        value={data.nome}
        selected={data.nome == this.state.estado.value}
      >
        {data.nome}
      </MenuItem>
    );
  }

  bairroMapper(data, index) {
    return (
      <MenuItem
        key={index}
        value={data.id}
        selected={data.id === this.state.bairro.value}
      >
        {data.nome}
      </MenuItem>
    );
  }

  /**Função que verifica se os campos de CPF,CEP E Telefone que não forem vázios estão preenchidos corretamente,
   * @function
   * @returns {boolean} Retorna true ou false, true se não ocorrer erro na validação e false se occorrer
   */
  checkFields() {
    let check = true;
    if (this.state.cpf.value != undefined && this.state.cpf.value != "") {
      if (!this.countNumberInString(this.state.cpf.value, 11)) {
        this.setState({ cpf: { ...this.state.cpf, error: true } });
        check = false;
      } else this.setState({ cpf: { ...this.state.cpf, error: false } });
    }

    if (
      this.state.telefone.value != "" &&
      this.state.telefone.value != undefined
    ) {
      this.state.telefone.value.forEach((el, index) => {
        if (
          !(
            this.countNumberInString(el, 11) || this.countNumberInString(el, 10)
          )
        ) {
          let telefone = { ...this.state.telefone };
          telefone.error[index] = true;
          this.setState({ telefone: telefone });
          check = false;
        } else {
          let telefone = { ...this.state.telefone };
          telefone.error[index] = false;
          this.setState({ telefone: telefone });
        }
      });
    } else {
      let telefone = { ...this.state.telefone };
      telefone.error[0] = false;
      this.setState({ telefone: telefone });
    }

    if (this.state.cep.value != "" && this.state.cep.value != undefined) {
      if (!this.countNumberInString(this.state.cep.value, 8)) {
        this.setState({ cep: { ...this.state.cep, error: true } });
        check = false;
      } else this.setState({ cep: { ...this.state.cep, error: false } });
    }

    if (this.state.email.value != "" && this.state.email.value != undefined) {
      if (
        !this.state.email.value.toLowerCase().includes("@") ||
        !this.state.email.value.toLowerCase().includes(".")
      ) {
        this.setState({ email: { ...this.state.email, error: true } });
        check = false;
      }
    } else this.setState({ email: { ...this.state.email, error: false } });

    const dataNascimento = moment(this.state.dataNascimento.value);
    const isValid = dataNascimento.isValid();
    const isBeforeToday = dataNascimento.isBefore(moment());
    const isError = this.state.dataNascimento.error;

    if (isValid && isBeforeToday) {
      this.setState({
        dataNascimento: { ...this.state.dataNascimento, error: false },
      });
    } else if (isError) {
      this.setState({
        dataNascimento: { ...this.state.dataNascimento, error: true },
      });
      check = false;
    } else
      this.setState({
        dataNascimento: { ...this.state.dataNascimento, error: false },
      });

    return check;
  }

  /**Função que verifica todos os campos obrigatórios estão preenchidos corretamente.
   * @function
   */
  async checkRequiredFields() {
    let check = this.checkFields();

    if (!this.state.nome.value.trim()) {
      this.setState({ nome: { ...this.state.nome, error: true } });
      check = false;
    } else {
      this.setState({ nome: { ...this.state.nome, error: false } });
    }

    if (
      this.checkItemBelongsToArray(this.props.context.requiredFields, "cpf")
    ) {
      if (
        this.state.cpf.value == "" ||
        this.state.cpf.value == undefined ||
        !this.countNumberInString(this.state.cpf.value, 11)
      ) {
        this.setState({ cpf: { ...this.state.cpf, error: true } });
        check = false;
      } else this.setState({ cpf: { ...this.state.cpf, error: false } });
    }

    if (
      this.checkItemBelongsToArray(this.props.context.requiredFields, "email")
    ) {
      if (
        this.state.email.value == "" ||
        this.state.email.value == undefined ||
        !this.state.email.value.toLowerCase().includes("@") ||
        !this.state.email.value.toLowerCase().includes(".")
      ) {
        this.setState({ email: { ...this.state.email, error: true } });
        check = false;
      } else this.setState({ email: { ...this.state.email, error: false } });
    }

    if (
      this.checkItemBelongsToArray(
        this.props.context.requiredFields,
        "nomeConjuge"
      )
    ) {
      if (
        this.state.nomeConjuge.value == "" ||
        this.state.nomeConjuge.value == undefined
      ) {
        this.setState({
          nomeConjuge: { ...this.state.nomeConjuge, error: true },
        });
        check = false;
      } else
        this.setState({
          nomeConjuge: { ...this.state.nomeConjuge, error: false },
        });
    }

    if (
      this.checkItemBelongsToArray(
        this.props.context.requiredFields,
        "conjugeCpf"
      )
    ) {
      if (
        this.state.cpfConjuge.value == "" ||
        this.state.cpfConjuge.value == undefined ||
        !this.countNumberInString(this.state.cpfConjuge.value, 11)
      ) {
        this.setState({
          cpfConjuge: { ...this.state.cpfConjuge, error: true },
        });
        check = false;
      } else
        this.setState({
          cpfConjuge: { ...this.state.cpfConjuge, error: false },
        });
    }

    if (
      this.checkItemBelongsToArray(this.props.context.requiredFields, "sexo")
    ) {
      if (this.state.sexo.value == "" || this.state.sexo.value == undefined) {
        this.setState({ sexo: { ...this.state.sexo, error: true } });
        check = false;
      } else this.setState({ sexo: { ...this.state.sexo, error: false } });
    }
    if (
      this.checkItemBelongsToArray(
        this.props.context.requiredFields,
        "dataNascimento"
      )
    ) {
      if (
        this.state.dataNascimento.value == null ||
        this.state.dataNascimento.value == "" ||
        moment(this.state.dataNascimento.value).isAfter(moment.now())
      ) {
        this.setState({
          dataNascimento: { ...this.state.dataNascimento, error: true },
        });
        check = false;
      } else
        this.setState({
          dataNascimento: { ...this.state.dataNascimento, error: false },
        });
    }
    if (
      moment(this.state.dataNascimento.value, "DD/MM/YYYY").isAfter(
        moment.now()
      )
    ) {
      this.setState({
        dataNascimento: {
          ...this.state.dataNascimento,
          error: true,
          helperText: "Data de nascimento inválida.",
        },
      });
      check = false;
    }

    if (
      this.checkItemBelongsToArray(
        this.props.context.requiredFields,
        "telefones-list"
      )
    ) {
      if (this.state.telefone.value.length > 0) {
        this.state.telefone.value.forEach((el, index) => {
          if (
            el == "" ||
            el == undefined ||
            !this.countNumberInString(el, 11)
          ) {
            // if(index == this.state.telefone.value.length - 1) break;
            let telefone = { ...this.state.telefone };
            telefone.error[index] = true;
            this.setState({ telefone: telefone });
            check = false;
          } else {
            let telefone = { ...this.state.telefone };
            telefone.error[index] = false;
            this.setState({ telefone: telefone });
          }
        });
      } else {
        let telefone = { ...this.state.telefone };
        telefone.error[0] = true;
        this.setState({ telefone: telefone });
        check = false;
      }
    }

    if (
      this.checkItemBelongsToArray(this.props.context.requiredFields, "cep")
    ) {
      if (
        this.state.cep.value == "" ||
        this.state.cep.value == undefined ||
        !this.countNumberInString(this.state.cep.value, 8)
      ) {
        this.setState({ cep: { ...this.state.cep, error: true } });
        check = false;
      } else {
        this.setState({ cep: { ...this.state.cep, error: false } });
      }
    }

    if (
      this.checkItemBelongsToArray(this.props.context.requiredFields, "estado")
    ) {
      if (
        this.state.estado.value == "" ||
        this.state.estado.value == undefined
      ) {
        this.setState({ estado: { ...this.state.estado, error: true } });
        check = false;
      } else this.setState({ estado: { ...this.state.estado, error: false } });
    }

    if (
      this.checkItemBelongsToArray(this.props.context.requiredFields, "cidade")
    ) {
      if (
        this.state.cidade.value == "" ||
        this.state.cidade.value == undefined
      ) {
        this.setState({ cidade: { ...this.state.cidade, error: true } });
        check = false;
      } else this.setState({ cidade: { ...this.state.cidade, error: false } });
    }

    if (
      this.checkItemBelongsToArray(
        this.props.context.requiredFields,
        "bairro"
      ) ||
      true
    ) {
      if (
        this.state.bairro.value == "" ||
        this.state.bairro.value == undefined
      ) {
        this.setState({ bairro: { ...this.state.bairro, error: true } });
        check = false;
      } else this.setState({ bairro: { ...this.state.bairro, error: false } });
    }

    if (
      this.checkItemBelongsToArray(
        this.props.context.requiredFields,
        "logradouro"
      )
    ) {
      if (
        this.state.logradouro.value == "" ||
        this.state.logradouro.value == undefined
      ) {
        this.setState({
          logradouro: { ...this.state.logradouro, error: true },
        });
        check = false;
      } else
        this.setState({
          logradouro: { ...this.state.logradouro, error: false },
        });
    }

    if (
      this.checkItemBelongsToArray(this.props.context.requiredFields, "numero")
    ) {
      if (
        this.state.numero.value == "" ||
        this.state.numero.value == undefined
      ) {
        this.setState({ numero: { ...this.state.numero, error: true } });
        check = false;
      } else this.setState({ numero: { ...this.state.numero, error: false } });
    }

    if (
      this.checkItemBelongsToArray(
        this.props.context.requiredFields,
        "documentos"
      )
    ) {
      if (this.state.documentos.value.length < 1) {
        this.setState({
          documentos: { ...this.state.documentos, error: true },
        });
        check = false;
      } else
        this.setState({
          documentos: { ...this.state.documentos, error: false },
        });
    }

    if (
      this.checkItemBelongsToArray(
        this.props.context.requiredFields,
        "pontoReferencia"
      )
    ) {
      if (
        this.state.pontoReferencia.value == "" ||
        this.state.pontoReferencia.value == undefined
      ) {
        this.setState({
          pontoReferencia: { ...this.state.pontoReferencia, error: true },
        });
        check = false;
      } else
        this.setState({
          pontoReferencia: { ...this.state.pontoReferencia, error: false },
        });
    }

    if (
      this.checkItemBelongsToArray(
        this.props.context.requiredFields,
        "observacao"
      )
    ) {
      if (
        this.state.observacao.value == "" ||
        this.state.observacao.value == undefined
      ) {
        this.setState({
          observacao: { ...this.state.observacao, error: true },
        });
        check = false;
      } else
        this.setState({
          observacao: { ...this.state.observacao, error: false },
        });
    }

    if (
      this.checkItemBelongsToArray(
        this.props.context.requiredFields,
        "assinatura"
      )
    ) {
      if (!this.props.signatureImage) {
        this.setState({
          assinatura: { ...this.state.assinatura, error: true },
        });
        check = false;
      } else
        this.setState({
          assinatura: { ...this.state.assinatura, error: false },
        });
    }

    if (!check) {
      await this.context.addAsyncDialog({
        title: "Erro ao salvar cliente",
        message:
          "Alguns campos permanecem inválidos, por favor, verifique-os e tente novamente",
        hasCloseButton: false,
        type: "error",
      });
    }
    return check;
  }

  render() {
    let { classes } = this.props;
    return (
      <Grid container className={classes.backgroundPage}>
        <Grid
          container
          direction="column"
          className={classes.cardInputs}
          alignItems="stretch"
          justify="center"
        >
          <Grid item>
            <Grid container justify="space-between" direction="row">
              <Grid item>
                <Typography className={classes.sectionTitle}>Dados</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.input}>
            <TextField
              fullWidth={true}
              label="Nome Completo"
              onChange={(e) => this.handleChangeInput("nome", e.target.value)}
              value={this.state.nome.value}
              error={this.state.nome.error}
              InputLabelProps={{
                className: this.controllerRecomended(
                  "nome",
                  this.state.nome.error
                )
                  ? classes.recomended
                  : null,
              }}
              disabled={this.controllerDisabledFields("nome")}
              helperText={
                this.state.nome.error
                  ? "Preencha o campo de nome corretamente"
                  : null
              }
              required={true}
            />
          </Grid>

          <Grid item className={classes.input}>
            <CpfMask
              fullWidth
              label="CPF"
              type="tel"
              InputProps={{ disabled: this.controllerDisabledFields("cpf") }}
              InputLabelProps={{
                className: this.controllerRecomended(
                  "cpf",
                  this.state.cpf.error
                )
                  ? classes.recomended
                  : null,
              }}
              onChange={(e) => this.handleChangeInput("cpf", e.target.value)}
              error={this.state.cpf.error}
              helperText={
                this.state.cpf.error
                  ? "Preencha o campo de cpf corretamente"
                  : null
              }
              value={this.state.cpf.value}
              required={this.checkItemBelongsToArray(
                this.props.context.requiredFields,
                "cpf"
              )}
            />
            <If condition={process.env.NODE_ENV === "development"}>
              <IconButton
                onClick={() => this.handleChangeInput("cpf", generateCpf())}
                size="small"
              >
                <FingerprintIcon />
              </IconButton>
            </If>
          </Grid>

          <Grid item className={classes.input}>
            <TextField
              fullWidth
              label="Email"
              onChange={(e) => this.handleChangeInput("email", e.target.value)}
              value={this.state.email.value}
              required={this.checkItemBelongsToArray(
                this.props.context.requiredFields,
                "email"
              )}
              disabled={this.controllerDisabledFields("email")}
              error={this.state.email.error}
              helperText={
                this.state.email.error
                  ? "Preencha o campo de email corretamente"
                  : null
              }
              type="email"
              InputLabelProps={{
                className: this.controllerRecomended(
                  "email",
                  this.state.email.error
                )
                  ? classes.recomended
                  : null,
              }}
            />
          </Grid>

          <Grid item className={classes.input}>
            <TextField
              fullWidth
              label="Nome do cônjugue"
              value={this.state.nomeConjuge.value}
              onChange={(e) =>
                this.handleChangeInput("nomeConjuge", e.target.value)
              }
              InputLabelProps={{
                className: this.controllerRecomended(
                  "nomeConjuge",
                  this.state.nomeConjuge.error
                )
                  ? classes.recomended
                  : null,
              }}
              required={this.checkItemBelongsToArray(
                this.props.context.requiredFields,
                "nomeConjuge"
              )}
              disabled={this.controllerDisabledFields("nomeConjuge")}
              error={this.state.nomeConjuge.error}
              recomended={this.controllerRecomended(
                "nomeConjuge",
                this.state.nomeConjuge.error
              ).toString()}
              classe={classes.recomended}
              helperText={
                this.state.nomeConjuge.error
                  ? "Preencha o campo de nome do conjuge corretamente"
                  : null
              }
            />
          </Grid>
          <Grid item className={classes.input}>
            <CpfMask
              fullWidth
              type="tel"
              label="CPF do cônjugue"
              value={this.state.cpfConjuge.value}
              InputProps={{ disabled: this.controllerDisabledFields("conjugeCpf") }}
              onChange={(e) =>
                this.handleChangeInput("cpfConjuge", e.target.value)
              }
              InputLabelProps={{
                className: this.controllerRecomended(
                  "conjugeCpf",
                  this.state.cpfConjuge.error
                )
                  ? classes.recomended
                  : null,
              }}
              required={this.checkItemBelongsToArray(
                this.props.context.requiredFields,
                "conjugeCpf"
              )}
              error={this.state.cpfConjuge.error}
              recomended={this.controllerRecomended(
                "conjugeCpf",
                this.state.cpfConjuge.error
              ).toString()}
              classe={classes.recomended}
              helperText={
                this.state.cpfConjuge.error
                  ? "Preencha o campo de CPF do conjuge corretamente"
                  : null
              }
            />
          </Grid>

          <Grid item className={classes.input}>
            <SelectInput
              label="Sexo"
              // className={this.controllerRecomended('sexo',this.state.sexo.error) ? classes.recomended:null}
              InputLabelProps={{
                className: this.controllerRecomended(
                  "sexo",
                  this.state.sexo.error
                )
                  ? classes.recomended
                  : null,
              }}
              value={this.state.sexo.value}
              onChange={(e) => this.handleChangeInput("sexo", e)}
              required={this.checkItemBelongsToArray(
                this.props.context.requiredFields,
                "sexo"
              )}
              menuItem={SEXO}
              disabled={this.controllerDisabledFields("sexo")}
              error={this.state.sexo.error}
              recomended={this.controllerRecomended(
                "sexo",
                this.state.sexo.error
              ).toString()}
              classe={classes.recomended}
            />
          </Grid>

          <Grid item className={classes.input}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                fullWidth
                type="tel"
                disableFuture
                margin="normal"
                autoComplete="off"
                format="dd/MM/yyyy"
                cancelLabel="Cancelar"
                name={"dataNascimento"}
                id="date-picker-dialog"
                label="Data de nascimento"
                placeholder="Ex: 30/12/1980"
                animateYearScrolling={false}
                disabled={this.controllerDisabledFields("dataNascimento")}
                error={this.state.dataNascimento.error}
                value={this.state.dataNascimento.value}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                helperText={"Preencha o campo de nascimento corretamente"}
                onChange={(isInvalid, value) => {
                  this.handleChangeInput("dataNascimento", value, isInvalid);
                }}
                InputLabelProps={{
                  className: this.controllerRecomended(
                    "dataNascimento",
                    this.state.dataNascimento.error
                  )
                    ? classes.recomended
                    : null,
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item className={classes.input} disa>
            {this.state.telefone.value.map((a, index) => {
              return (
                <CardPhone
                  showclosebutton={index != 0}
                  key={index}
                  index={index}
                  onRemovePhoneNumber={this.clickRemovePhoneNumber}
                  onSetPhoneNumber={this.setPhoneNumber}
                  required={this.checkItemBelongsToArray(
                    this.props.context.requiredFields,
                    "telefones-list"
                  )}
                  disabled={this.controllerDisabledFields("telefones-list")}
                  error={this.state.telefone.error[index]}
                  value={this.state.telefone.value[index]}
                  recomended={this.controllerRecomended(
                    "telefones-list",
                    this.state.telefone.error
                  ).toString()}
                  className={classes.recomended}
                />
              );
            })}
          </Grid>
          <Grid container justify="flex-end">
            <Fab
              size="medium"
              aria-label="add"
              color="primary"
              onClick={this.onClickAddPhone}
              disabled={
                this.controllerDisabledFields("telefones-list") ||
                this.state.telefone.value[
                this.state.telefone.value.length - 1
                ] == "" ||
                !(
                  this.countNumberInString(
                    this.state.telefone.value[
                    this.state.telefone.value.length - 1
                    ],
                    11
                  ) ||
                  this.countNumberInString(
                    this.state.telefone.value[
                    this.state.telefone.value.length - 1
                    ],
                    10
                  )
                )
              }
            >
              <AddCircleIcon />
            </Fab>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          className={classes.cardInputs}
          alignItems="stretch"
          justify="center"
        >
          <Typography className={classes.sectionTitle}>Endereço</Typography>
          <Grid item className={classes.input}>
            <TextField
              fullWidth
              label="CEP"
              type="tel"
              onChange={(e) => this.handleChangeInput("cep", e.target.value)}
              value={this.state.cep.value}
              disabled={this.controllerDisabledFields("cep")}
              InputProps={{
                inputComponent: CepMask,
              }}
              InputLabelProps={{
                className: this.controllerRecomended(
                  "cep",
                  this.state.cep.error
                )
                  ? classes.recomended
                  : null,
              }}
              required={this.checkItemBelongsToArray(
                this.props.context.requiredFields,
                "cep"
              )}
              error={this.state.cep.error}
              helperText={
                this.state.cep.error
                  ? "Preencha o campo de cep corretamente"
                  : null
              }
            />
          </Grid>

          <Grid item className={classes.input}>
            <SelectInput
              label="Estado"
              fullWidth
              value={this.state.estado.value}
              onChange={(e) => this.handleChangeInput("estado", e)}
              InputLabelProps={{
                className: this.controllerRecomended(
                  "estado",
                  this.state.estado.error
                )
                  ? classes.recomended
                  : null,
              }}
              required={this.checkItemBelongsToArray(
                this.props.context.requiredFields,
                "estado"
              )}
              menuItem={this.getFilteredStates()}
              mapper={this.estadoMapper}
              disabled={this.controllerDisabledFields("estado")}
              error={this.state.estado.error}
              recomended={this.controllerRecomended(
                "estado",
                this.state.estado.error
              ).toString()}
              classe={classes.recomended}
            />
          </Grid>

          <Grid item className={classes.input}>
            <SelectInput
              label="Cidade"
              fullWidth
              value={this.state.cidade.value}
              onChange={(e) => this.handleChangeInput("cidade", e)}
              InputLabelProps={{
                className: this.controllerRecomended(
                  "cidade",
                  this.state.cidade.error
                )
                  ? classes.recomended
                  : null,
              }}
              required={true}
              menuItem={this.getFilteredCities()}
              // defaultValue={this.props.neighborhoodContext.cities.value.length == 1 && this.props.neighborhoodContext.cities.value[0].cidade}
              disabled={this.controllerDisabledFields("cidade")}
              error={this.state.cidade.error}
              mapper={this.cidadeMapper}
              recomended={
                true ||
                this.controllerRecomended(
                  "cidade",
                  this.state.cidade.error
                ).toString()
              }
              classe={classes.recomended}
            />
          </Grid>

          <Grid item className={classes.input}>
            <Grid container wrap="nowrap" justify="space-between">
              <Grid item xs={10}>
                <SelectInput
                  label="Bairro"
                  fullWidth
                  value={this.state.bairro.value}
                  onChange={(e) => this.handleChangeInput("bairro", e)}
                  InputLabelProps={{
                    className: this.controllerRecomended(
                      "bairro",
                      this.state.bairro.error
                    )
                      ? classes.recomended
                      : null,
                  }}
                  required={true}
                  menuItem={this.getFilteredNeighborhoods()}
                  mapper={this.bairroMapper}
                  disabled={
                    this.state.cidade.value == "" ||
                    this.controllerDisabledFields("bairro")
                  }
                  error={this.state.bairro.error}
                  recomended={
                    true ||
                    this.controllerRecomended(
                      "bairro",
                      this.state.bairro.error
                    ).toString()
                  }
                  classe={classes.recomended}
                />
              </Grid>
              <Grid item xs={2}>
                <Grid
                  container
                  justify="flex-end"
                  alignItems="flex-end"
                  className={classes.addNeighborhood}
                >
                  <AddIcon
                    fontSize="large"
                    onClick={() =>
                      this.props.handleToggleNeighborhoodForm({
                        city: this.state.cidade.value,
                        state: this.state.estado.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.input}>
            <TextField
              fullWidth={true}
              label="Logradouro"
              onChange={(e) =>
                this.handleChangeInput("logradouro", e.target.value)
              }
              value={this.state.logradouro.value}
              required={this.checkItemBelongsToArray(
                this.props.context.requiredFields,
                "logradouro"
              )}
              disabled={this.controllerDisabledFields("logradouro")}
              error={this.state.logradouro.error}
              helperText={
                this.state.logradouro.error
                  ? "Preencha o campo de logradouro corretamente"
                  : null
              }
              InputLabelProps={{
                className: this.controllerRecomended(
                  "logradouro",
                  this.state.logradouro.error
                )
                  ? classes.recomended
                  : null,
              }}
            />
          </Grid>

          <Grid item className={classes.input}>
            <TextField
              fullWidth={true}
              label="Número"
              type="number"
              // InputProps={{pattern: '/([0-9]+)/'}}
              onKeyDown={(e) => {
                (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault();
              }}
              onChange={(e) => {
                this.handleChangeInput("numero", e.target.value);
              }}
              value={this.state.numero.value}
              required={this.checkItemBelongsToArray(
                this.props.context.requiredFields,
                "numero"
              )}
              disabled={this.controllerDisabledFields("numero")}
              error={this.state.numero.error}
              helperText={
                this.state.numero.error
                  ? "Preencha o campo de numero corretamente"
                  : null
              }
              InputLabelProps={{
                className: this.controllerRecomended(
                  "logradouro",
                  this.state.nome.error
                )
                  ? classes.recomended
                  : null,
              }}
            />
          </Grid>

          <Grid item className={classes.input}>
            <TextField
              fullWidth={true}
              label="Referência"
              onChange={(e) =>
                this.handleChangeInput("pontoReferencia", e.target.value)
              }
              value={this.state.pontoReferencia.value}
              required={this.checkItemBelongsToArray(
                this.props.context.requiredFields,
                "pontoReferencia"
              )}
              disabled={this.controllerDisabledFields("pontoReferencia")}
              error={this.state.pontoReferencia.error}
              helperText={
                this.state.pontoReferencia.error
                  ? "Preencha o campo de ponto de referência corretamente"
                  : null
              }
              InputLabelProps={{
                className: this.controllerRecomended(
                  "pontoReferencia",
                  this.state.pontoReferencia.error
                )
                  ? classes.recomended
                  : null,
              }}
            />
          </Grid>

          {/* Observacao */}
          <Grid item className={classes.input}>
            <TextField
              fullWidth={true}
              label="Observaçẫo"
              onChange={(e) =>
                this.handleChangeInput("observacao", e.target.value)
              }
              value={this.state.observacao.value}
              required={this.checkItemBelongsToArray(
                this.props.context.requiredFields,
                "observacao"
              )}
              disabled={this.controllerDisabledFields("observacao")}
              error={this.state.observacao.error}
              helperText={
                this.state.observacao.error
                  ? "Preencha o campo de ponto de observação corretamente"
                  : null
              }
              InputLabelProps={{
                className: this.controllerRecomended(
                  "observacao",
                  this.state.observacao.error
                )
                  ? classes.recomended
                  : null,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="stretch"
          className={classes.cardInputs}
        >
          <Grid item className={classes.input}>
            <Typography className={classes.sectionTitle}>Documentos</Typography>
          </Grid>

          {this.state.documentos.value.map((documento, index) => {
            return (
              <Grid item key={index} className={classes.documentCard}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={10}>
                    {this.getDocumentById(documento.DocumentoId)}
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container justify="flex-end">
                      <CloseIcon
                        fontSize="default"
                        onClick={() => this.clickRemoveDocuments(index)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}

          <Grid container justify="center" style={{ paddingTop: "10px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#1473cc",
                color: "#fff",
              }}
              onClick={this.clickPopUpDocuments}
              endIcon={<PostAddIcon />}
              className={classes.input}
            >
              Adicionar
            </Button>
          </Grid>

          <AddDocuments
            documents={this.props.context.documents}
            close={this.clickPopUpDocuments}
            status={this.state.popUpDocuments}
            getData={this.handleSaveDocuments}
          />

          <Grid
            container
            justify="space-between"
            style={{ paddingTop: "30px" }}
            className={classes.input}
          >
            {/* <Grid item className={classes.input}> */}
            <Typography className={classes.sectionTitle}>Assinatura</Typography>
            {/* </Grid>                      */}
            <AspectRatioIcon
              fontSize="small"
              onClick={this.props.handleToggleSignature}
            />
          </Grid>

          <Grid item className={classes.input}>
            <Grid
              container
              className={classes.signature}
              onClick={this.props.handleToggleSignature}
            >
              {this.props.signatureImage && (
                <img
                  className={classes.sigImage}
                  alt="Imagem da assinatura"
                  src={this.props.signatureImage}
                />
              )}
            </Grid>
          </Grid>
          <p className={classes.errorSignature}>
            {this.state.assinatura.error ? "Assinatura necessária" : ""}{" "}
          </p>
          <Grid container className={classes.cancelOrSave}>
            <CancelOrSave enableSave onClickSave={this.saveInDataBase} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
  getDocumentById(id) {
    const doc = this.props.context.documents.find((doc) => doc.id === id);
    if (!doc) return "Não encontrado";
    return doc.nome;
  }
}
AddClient.contextType = MessageDialogContext;
export default withStyles(styles)(AddClient);
