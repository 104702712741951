import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core/";

const styles = (theme) => ({});

/**
 * Componente de select.
 */
class SelectInput extends React.Component {
  /**
   * @param {boolean} props.required boleano que define se é obrigatório
   * @param {boolean} props.error Boelano que definire se ocorreu um erro
   * @param {object} props.classe Objeto com todas uma classe do CSS
   * @param {string} props.defaultValue Valor padrão
   * @param {object} props.onChange Função que setá o dado no elemento pai
   * @param {array} props.menuItem Lista de selects
   * @param {object} props.classe Objeto com todas uma classe do CSS
   * @param {string} props.variant String com o dado de opcao de style para o select
   */
  constructor(props) {
    super(props);
    this.defaultMaper = this.defaultMaper.bind(this);
    this.resolveItems = this.resolveItems.bind(this);
    this.mapMenuItemToComponent = this.mapMenuItemToComponent.bind(this);
    this.state = {
      items: [],
    };
  }

  async componentDidMount() {
    await this.resolveItems();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.menuItem != this.props.menuItem) {
      await this.resolveItems();
    }
  }

  async resolveItems() {
    const { menuItem } = this.props;
    const items = await Promise.all([].concat(menuItem).flat(Infinity));
    this.setState({
      items: items.flat(Infinity),
    });
  }

  defaultMaper(data, index) {
    if (!data.nome) {
      if (data.cidade) data.nome = data.cidade;
      if (data.placa) data.nome = data.placa;
    }

    return (
      <MenuItem
        key={index}
        value={data.id}
        selected={data.id == this.props.value}
      >
        {data.nome}
      </MenuItem>
    );
  }

  mapMenuItemToComponent(item, index) {
    if (this.props.mapper) {
      return this.props.mapper(item, index);
    } else {
      return this.defaultMaper(item, index);
    }
  }

  render() {
    const {
      variant,
      disabled,
      required,
      error,
      value,
      onChange,
      label,
      disableUnderline,
      helperText,
      InputLabelProps,
      defaultValue,
      ...others
    } = this.props;

    return (
      <FormControl
        fullWidth
        variant={variant ? variant.toString() : "filled"}
        disabled={disabled}
        required={required}
        error={error}
        {...others}
      >
        <InputLabel id={`label-${label}`} {...InputLabelProps}>
          {label}
        </InputLabel>
        <Select
          labelId={`label-${label}`}
          id={`input-${label}`}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          error={error}
          label={label}
          disableUnderline={disableUnderline ? true : false}
        >
          {this.state.items.map(this.mapMenuItemToComponent)}
        </Select>
        {error ? (
          <FormHelperText error={error}>
            {helperText ? helperText : "Selecione alguma opção!"}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}
SelectInput.propTypes = {
  name: PropTypes.array,
};

SelectInput.defaultProps = {
  menuItem: [],
  variant: "standard",
};
export default withStyles(styles)(SelectInput);
