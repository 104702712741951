import React from "react";
import SchedulingForm from "../../components/Scheduling/ViewClient/SchedulingForm";
import history from "../../router/History";

import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import { SchedulingsContext } from "../../contexts/SchedulingsContext";

/**
 * Tela de Edição de agendamento
 */
class WindowForEdit extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {array} this.state.clientName Nome do respectivo cliente que realizará a troca
   * @property {array} this.state.type Tipo do agendamento
   */
  constructor(props) {
    super(props);
    this.state = {
      clientName: null,
      type: null,
    };
    this.saveData = this.saveData.bind(this);
  }

  /**Função executada na montagem do componente, onde irá buscar o nome do cliente.
   * @function
   */
  async componentDidMount() {
    const client = await this.context.getClientByHash(
      this.props.match.params.clientHash
    );
    const schedule = await this.context.getSchedulingsType(
      this.props.match.params.hash
    );
    this.setState({
      type: schedule.tipo,
      schedule: schedule,
      clientName: client.name,
      clientBalance: client.balance,
      schedules: client.type,
      isLoading: false,
      open: false,
    });
  }

  /**Função que irá fazer o update do agendamento selecionado no banco e em seguida redirecionar para o 'Ver Cliente'.
   * @function
   * @param {object} data Dados que serão usados na criação do novo agendamento.
   */
  async saveData(data) {
    await this.context.editScheduling(
      this.props.match.params.clientHash,
      this.props.match.params.hash,
      data
    );
    this.setState({ open: true });
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(messageContext) => (
          <SchedulingForm
            titleHeader="Editar agendamento"
            id={this.state.id}
            func={this.saveData}
            history={this.props.history}
            type={this.state.type}
            isLoading={this.state.isLoading}
            open={this.state.open}
            schedulesTypes={this.context.scheduleTypes}
            context={this.context}
            messageDialogContext={messageContext}
            data={{
              clientName: this.state.clientName,
              clientBalance: this.state.clientBalance,
              schedules: this.state.schedules,
              dataRecebimento: this.state.schedule
                ? this.state.schedule.dataRecebimento
                : null,
            }}
            edit
          />
        )}
      </MessageDialogContext.Consumer>
    );
  }
}
WindowForEdit.contextType = SchedulingsContext;
export default WindowForEdit;
