export default class Text2Picture {
  // Function to HTML encode the text
  // This creates a new hidden element,
  // inserts the given text into it
  // and outputs it out as HTML
  static htmlEncode(value) {
    return document.createElement("div").text(value).html();
  }

  static generate(text, lineHeight = 16, returnType = "base64", width = null) {
    return new Promise((resolve) => {
      
      const padding = 20;
      const fontSize = Text2Picture.findFontSizeByLineHeight(lineHeight);

      const lines = text.split("\n").length;
      let imageHeight = 0;
      let extraHeightForFixedText = 120;

      if (text.includes(";base64,")) {
          imageHeight = 500;
          
      }


      let height = lines * lineHeight + 2 * padding + imageHeight +  extraHeightForFixedText;


      width = width || Text2Picture.findWidthByLineHeigth(lineHeight);


      const canvasElement = document.createElement("canvas");
      canvasElement.width = width;
      canvasElement.height = height;

      const ctx = canvasElement.getContext("2d");

      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);

      ctx.font = `${fontSize}px Roboto, Helvetica, Arial, sans-serif`;
      ctx.textBaseline = "top";
      ctx.fillStyle = "black";
      let contents = text.split("__x__");

      contents.forEach(element => {
        if (element.includes && element.includes(";base64,")) {
          const image = document.createElement("img");
          image.setAttribute("src", element);
          image.setAttribute("style", "background: blue");
        
            ctx.drawImage(
                image,
                0,
                height - 500,
                360,
                400
            );
        
        } else {
          ctx.wrapText(element, padding, padding, width - 2 * padding, lineHeight);
        }
        
      });
        
      if (returnType == "base64") {
        return resolve(canvasElement.toDataURL());
      }

      canvasElement.toBlob(resolve, "image/png", 1);
    });
  }

  static findFontSizeByLineHeight(lineHeight) {
    return 0.75 * lineHeight;
  }

  static findWidthByLineHeigth(lineHeight) {
    return (220 * lineHeight) / 16;
  }
}
