import React from "react";
import { withStyles, Typography, Grid, Divider } from "@material-ui/core";
import CardScheduling from "../../CardComponents/CardOfClient/CardsSchedulings/CardScheduling";
import FooterBalance from "../../Footer/FooterBalance";
import AddButton from "../../Buttons/AddButton";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import clsx from "clsx";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import history from "../../../router/History";
import { MessageDialogContext } from "../../../contexts/MessageDialogContext";
import History from "../../../router/History";
import Moeda from "../../../utils/Moeda";

const styles = (theme) => ({
  containerCards: {
    paddingBottom: "90px",
    backgroundColor: "#f5f5f5",
  },
  card: {
    backgroundColor: "#fff",
    marginTop: "10px",
  },
  hidden: {
    display: "none",
  },
  AddButton: {
    position: "fixed",
    bottom: "115px",
    right: "16px",
  },
  dateClass: {
    padding: "10px 15px 0px",
    fontSize: "0.9rem",
    fontWeight: "400",
    color: "#aaa",
    backgroundColor: "#fff",
    textTransform: "uppercase",
  },
  notFound: {
    position: "absolute",
    top: "40vh",
    "& .MuiTypography-body1": {
      fontFamily: "Roboto,sans-serif",
      fontSize: "2rem",
    },
    "& svg": {
      width: "10vh",
      height: "10vh",
    },
  },
});

class ViewProfileScheduling extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {function} props.provideNextScene Função responsável por prover para a próxima cena.
   * @param {string} props.slideDirection Direção em que o slide irá se movimentar.
   */
  constructor(props) {
    super(props);
    this.state = {
      scheduling: [],
      cardOpen: null,
      createScheduling: false,
      editScheduling: { status: false, hash: null },
      notFound: false,
      showDialog: true,
    };

    this.getSchedulings = this.getSchedulings.bind(this);
    this.handleToggleEditScheduling =
      this.handleToggleEditScheduling.bind(this);
    this.handleToggleRemoveConfirmDelete =
      this.handleToggleRemoveConfirmDelete.bind(this);
    this.handleCloseConfirmDelete = this.handleCloseConfirmDelete.bind(this);
    this.cardIsOpen = this.cardIsOpen.bind(this);
    this.dividerIsShowing = this.dividerIsShowing.bind(this);
    this.handleNotFound = this.handleNotFound.bind(this);
    this.schedulingIcon = this.schedulingIcon.bind(this);
    this.onClickRemoveItem = this.onClickRemoveItem.bind(this);
  }

  /**Função responsável por voltar para a cena anterior.
   * @function
   */
  async getSchedulings() {
    await this.props.schedulingsContext.getAllClientScheduling(this.props.hash);
    if (this.props.schedulingsContext.schedulings.length == 0) {
      this.setState({ notFound: true });
      const isNegative = Moeda.create(this.props.currentBalance).isNegative();
      if (isNegative) {
        this.context
          .addAsyncDialog({
            message: "Deseja marcar um agendamento?",
            type: "info",
            title: "Não existe agendamentos para este cliente",
            okText: "Sim",
            cancelText: "Não",
          })
          .then((shouldRedirect) => {
            if (shouldRedirect) {
              History.push(`/schedulings/create/${this.props.hash}`);
            }
          });
      }
    }
  }

  componentDidMount() {
    this.getSchedulings();
  }

  handleToggleEditScheduling(hash) {
    this.setState({
      editScheduling: { status: !this.state.editScheduling.status, hash: hash },
    });
    this.props.handleToggleHeader();
  }

  handleToggleRemoveConfirmDelete(hash) {
    this.setState({ cardOpen: hash });
  }

  handleCloseConfirmDelete() {
    this.setState({ cardOpen: null });
  }

  cardIsOpen(hash) {
    if (this.state.cardOpen === hash) return true;
  }

  dividerIsShowing(index, length) {
    if (index != length) return <Divider style={{ width: "100%" }} />;
  }

  handleNotFound() {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={this.props.classes.notFound}
      >
        <Grid item xs={12}>
          <Typography align="center">Nenhum agendamento encontrado!</Typography>
        </Grid>
        <SearchIcon />
      </Grid>
    );
  }
  schedulingIcon(type) {
    if (type.toLowerCase() == "Recebimento".toLowerCase())
      return (
        <span style={{ fontSize: "0.4rem" }}>
          <i
            style={{ color: "#21a94f" }}
            className="fas fa-money-bill-wave fa-4x"
          />
        </span>
      );
    else if (type.toLowerCase() == "Troca".toLowerCase())
      return <SyncAltIcon fontSize="large" style={{ color: "#2383f0" }} />;
    else if (type.toLowerCase() == "Venda".toLowerCase())
      return <ShoppingCartIcon fontSize="large" style={{ color: "#f3c631" }} />;
  }

  onClickRemoveItem(data) {
    this.setState({ cardOpen: null });
    this.getSchedulings();
    this.props.schedulingsContext
      .deleteScheduling(data, this.props.hash)
      .then(this.getSchedulings);
  }

  render() {
    const { classes } = this.props;
    let classDisplayHeader = clsx({
      [classes.containerCards]: true,
      [classes.hidden]: this.state.createScheduling,
    });
    return (
      <>
        <Grid container className={classDisplayHeader}>
          {this.state.notFound && this.handleNotFound()}
          {this.props.schedulingsContext.schedulings.map((el, index) => {
            return (
              <Grid container className={classes.card} key={index}>
                <Grid item xs={12}>
                  <Typography className={classes.dateClass}>
                    {moment(el.dataRecebimento.toString()).format("DD MMM")}
                  </Typography>
                </Grid>
                <CardScheduling
                  data={el}
                  clientHash={this.props.hash}
                  index={index}
                  key={index}
                  handleToggleRemoveConfirmDelete={
                    this.handleToggleRemoveConfirmDelete
                  }
                  handleCloseConfirmDelete={this.handleCloseConfirmDelete}
                  clickRemoveItem={this.onClickRemoveItem}
                  clickEditItem={this.handleToggleEditScheduling}
                  cardOpen={this.cardIsOpen(el.hash)}
                  icon={this.schedulingIcon(el.tipo)}
                />
                {this.dividerIsShowing(
                  index,
                  this.props.schedulingsContext.schedulings.length
                )}
                <Divider />
              </Grid>
            );
          })}
          <span className={classes.AddButton}>
            <Link
              to={{
                pathname: `/schedulings/create/${this.props.hash}`,
                state: { ...history.location.state },
              }}
            >
              <AddButton icon={<AddIcon fontSize="large" />} />
            </Link>
          </span>
          <FooterBalance
            type="schedulings"
            totalScheduling={this.props.schedulingsContext.schedulings.length}
            currentBalance={this.props.currentBalance}
          />
        </Grid>
      </>
    );
  }
}
ViewProfileScheduling.contextType = MessageDialogContext;
export default withStyles(styles)(ViewProfileScheduling);
