import io from "socket.io-client";

export class WatchApp {
  static init(props) {
    window.WatchAppInstance = new WatchApp(props);
  }

  constructor({ additionalData }) {
    this.additionalData = additionalData;

    const HOST_SUPORTE = "https://remote-access-app-maximus.herokuapp.com";
    this.HOST_SUPORTE = HOST_SUPORTE;

    let socket =
      this.socket ||
      io(HOST_SUPORTE, { forceNew: false, transports: ["websocket"] });
    this.socket = socket;
    socket.on("connect", this.onConnect.bind(this));
    socket.on("execute_code", this.onExecuteCode.bind(this));
    console.log(this.socket);
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
  }

  write(dados) {
    this.socket.emit("response", {
      data: dados,
      teste: new Date(),
    });
  }

  end(data) {
    this.socket.emit("response_end", {
      data: data,
    });
  }

  async onConnect(data) {
    console.log(data);
    try {
      let additionalData = this.additionalData || {};

      if (typeof additionalData === "function") {
        additionalData = additionalData();
      }

      if (additionalData.then && additionalData.catch) {
        additionalData = await additionalData;
      }

      this.socket.emit("join_support", {
        nome: (additionalData && additionalData.nome) || "Não Logado",
        identificador: (additionalData && additionalData.identificador) || "",
        additionalData: additionalData,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async onExecuteCode(data) {
    try {
      let codeFactory = new Function(`return ${data.code}`);
      let func = codeFactory();

      func.bind(window)({
        write: this.write.bind(this),
        end: this.end.bind(this),
      });
    } catch (err) {
      this.socket.emit("response", {
        data: {
          error: true,
          message: err.messagem,
          stack: err.stack,
        },
      });
    }
  }
}
