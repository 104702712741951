import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, Typography } from "@material-ui/core/";

const getStyles = makeStyles((theme) => {
  return {
    loadingContainer: {
      minHeight: "80px",
      maxWidth: "250px",
      padding: "5px",
      backgroundColor: "rgba(37, 37, 37, 0.85)",
      color: "#fff",
      transform: "translate(50%, 50%)",
      position: "fixed",
      top: "30%",
      right: "50%",
      textAlign: "center",
      "& h6": {
        fontSize: "1.05rem",
        lineHeight: 2,
      },
    },
  };
});

export function SyncNotificationDialog({
  open,
  totalItems,
  title,
  storedItems,
  children,
  totalSyncItems,
  downloadedItems,
  totalCompleteSyncItems,
}) {
  const classes = getStyles();

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown disablePortal open={open}>
      <Grid
        container
        direction="column"
        id="helper"
        className={classes.loadingContainer}
      >
        <Grid item xs={12}>
          {totalItems != null ? (
            <LinearProgress
              variant="buffer"
              color="primary"
              value={(storedItems / totalItems) * 100}
              valueBuffer={(downloadedItems / totalItems) * 100}
            />
          ) : (
            <LinearProgress
              variant="determinate"
              color="primary"
              value={(totalCompleteSyncItems / totalSyncItems) * 100}
            />
          )}

          <Typography variant="h6" style={{ paddingTop: 7, lineHeight: 1.3 }}>
            {title}
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ padding: "10px 0 0" }}>
          {children}
        </Grid>
      </Grid>
    </Dialog>
  );
}

export function DialogMessageByType({ type, data }) {
  if (type === "client-block-progress") {
    return (
      <>
        <Typography>
          Baixando bloco {data.currentItem} de {data.totalItems}
        </Typography>
        <Typography
          component="span"
          style={{ fontSize: ".7rem", color: "#999", float: "left" }}
        >
          Baixados: {data.downloadedItems} de {data.totalItems}
        </Typography>
        <Typography
          component="span"
          style={{ fontSize: ".7rem", color: "#999", float: "right" }}
        >
          Salvos: {data.storedItems} de {data.totalItems}
        </Typography>
      </>
    );
  }

  if (type === "client-send") {
    return (
      <>
        <Typography variant="subtitle2" style={{ fontSize: ".9rem" }}>
          {`Cliente: ${data.nome}`} &nbsp;
        </Typography>
        <Typography
          variant="subtitle2"
          style={{
            color: "#bfbfbf",
            fontSize: ".8rem",
            lineHeight: 1,
            paddingBottom: 10,
          }}
        >
          Enviando para servidor...
        </Typography>
        <Typography
          component="span"
          style={{ fontSize: ".7rem", color: "#999999", float: "left" }}
        >
          {`Hash: ${data.hash}`}
        </Typography>
        <Typography
          component="span"
          style={{ fontSize: ".7rem", color: "#999", float: "right" }}
        >
          {data.current} de {data.total}
        </Typography>
      </>
    );
  }

  if (type === "daily-map-calc") {
    return (
      <Typography>Estamos calculando a ordem das suas fichas do dia</Typography>
    );
  }

  if (type === "common-sync") {
    return <Typography>{data.message}</Typography>;
  }
  return null;
}

/**
 *   syncDialog: {
    isOpen: false,
    type: "",
    info: {},
  },
 */
