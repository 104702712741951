import React, { Component, createContext } from "react";
import SyncStore from "../service/sync/activeSync/syncStore";
import SyncNotificationCenter from "../service/notifications/syncNotificationCenter";
import { Timer } from "../utils/timer";
import { getLocalCongfigVar } from "../service/config";
import * as syncService from "../service/sync/activeSync";
import { downloadFotosWorker } from "../utils/DownloadClientFotoWorker";
const SyncContext = createContext({});
class SyncContextProvider extends Component {
  constructor(props) {
    super(props);
    this.syncStore = new SyncStore();
    this.state = {
      isAppSync: false,
      syncFuel: syncService.syncFuel,
      syncTeam: syncService.syncTeam,
      syncBasket: syncService.syncBasket,
      syncVehicle: syncService.syncVehicle,
      syncHoliday: syncService.syncHoliday,
      sendClients: syncService.sendClients,
      sendDailyMap: syncService.sendDailyMap,
      sendExpenses: syncService.sendExpenses,
      sendDocuments: syncService.syncDocuments,
      syncDocuments: syncService.syncDocuments,
      syncMainRoute: syncService.syncMainRoute,
      syncValidators: syncService.syncValidators,
      syncPaymentForm: syncService.syncPaymentForm,
      initActiveSync: this.initActiveSync.bind(this),
      sendNeighborhood: syncService.sendNeighborhood,
      sendRouteOpening: syncService.sendRouteOpening,
      syncPaymentMethod: syncService.syncPaymentMethod,
      syncRequiredFields: syncService.syncRequiredFields,
      syncCityNeighborhood: syncService.syncCityNeighborhood,
    };
  }

  #modifiedModels = null;

  set modifiedModels(models) {
    if (models === undefined) {
      return;
    }
    if (models === null) {
      this.#modifiedModels = null;
      return;
    }
    this.modifiedModels.add(...models);
  }

  get modifiedModels() {
    if (!this.#modifiedModels) {
      this.#modifiedModels = new Set();
      return this.#modifiedModels;
    }
    return this.#modifiedModels;
  }

  render() {
    return (
      <SyncContext.Provider value={this.state}>
        {typeof this.props.children === "function"
          ? this.props.children()
          : this.props.children}
      </SyncContext.Provider>
    );
  }

  async initActiveSync(options) {
    const totalTimer = new Timer();
    let isClientsSended = false;

    try {
      this.setState({ isAppSync: true });
      totalTimer.start();
      this.modifiedModels = await syncService.syncScheduleTypes();
      if (options.syncMainRoute) {
        this.modifiedModels = await syncService.syncMainRoute();
      }
      if (options.syncBasket) {
        this.modifiedModels = await syncService.syncBasket();
      }
      if (options.syncNeighborhood) {
        this.modifiedModels = await syncService.sendNeighborhood();
      }
      if (options.sendClients) {
        this.modifiedModels = await syncService.syncDocuments();
        this.modifiedModels = await syncService.sendClients();
        isClientsSended = true;
      }
      if (options.syncVehicle) {
        this.modifiedModels = await syncService.syncVehicle();
      }
      if (options.syncFuel) {
        this.modifiedModels = await syncService.syncFuel();
      }
      if (options.syncExpenses) {
        this.modifiedModels = await syncService.sendExpenses();
      }
      if (options.syncRouteOpening) {
        this.modifiedModels = await syncService.sendRouteOpening();
      }
      if (options.syncTeam) {
        this.modifiedModels = await syncService.syncTeam();
      }
      if (options.syncCityNeighborhood) {
        this.modifiedModels = await syncService.syncCityNeighborhood();
      }
      if (options.syncPaymentForm) {
        this.modifiedModels = await syncService.syncPaymentForm();
      }
      if (options.syncPaymentMethod) {
        this.modifiedModels = await syncService.syncPaymentMethod();
      }
      if (options.syncValidators) {
        this.modifiedModels = await syncService.syncValidators();
      }
      if (options.syncRequiredFields) {
        this.modifiedModels = await syncService.syncRequiredFields();
      }
      if (options.syncHoliday) {
        this.modifiedModels = await syncService.syncHoliday();
      }

      if (options.syncVarEnvs) {
        this.modifiedModels = await syncService.syncVarEnvs();
      }
      if (options.syncDailyMap) {
        this.modifiedModels = await syncService.sendDailyMap();
      }
      if (options.syncClients) {
        if (!isClientsSended) {
          this.modifiedModels = await syncService.syncDocuments();
          this.modifiedModels = await syncService.sendClients();
        }
        this.modifiedModels = await syncService.syncClients(
          options.downloadAllClient
        );

        const shouldDownloadFoto = await getLocalCongfigVar("downloadFotos");
        if (shouldDownloadFoto) {
          this.startBackgroundFotoSync();
        }
      }

      const modifiedModels = [...this.modifiedModels];

      await SyncNotificationCenter.instance.notifyAll(
        "activeSync",
        modifiedModels
      );
    } catch (error) {
      throw error;
    } finally {
      this.setState({ isAppSync: false });
      this.modifiedModels = null;
      const time = totalTimer.stop();
      console.log(time);
    }
  }

  startBackgroundFotoSync() {
    const channel = new MessageChannel();
    const dbVersion = parseFloat(window.DATABASE.verno) * 10;

    downloadFotosWorker(dbVersion, channel.port1);
    channel.port2.onmessage = (message) => {
      SyncNotificationCenter.instance.notifyAll("foto-download", message.data);
    };
  }
}

export { SyncContextProvider, SyncContext };
