import ConnectionErrorTimeOut from "../errorDefinition/ConnectionErrorTimeOut";

const nativeFetch = window.fetch;
const MAX_WAIT_TIME = process.env.NODE_ENV === "development" ? 1200000 : 60000;
console.log("Tempo maximo de espera da request: ", MAX_WAIT_TIME);

function retryTimeout(attempt) {
  const tempo = fib(attempt + 2) * 1000;
  console.log("ESPERAR:" + tempo);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, tempo);
  });
}

function fib(num) {
  let a = 0;
  let b = 1;
  let temp;

  while (num > 0) {
    temp = b;
    b = b + a;
    a = temp;
    num--;
  }
  return a;
}

/**
 * @description Sobreescrita da função default do js para prover o comportamento necessario.
 * Esta sobreescrita fornece a capacidade de cancelar todas as requisições fetch que foram
 * realizadas depois que uma qualquer tenha sofrido timeout ou ocorrido um erro, evitando
 * desta forma requisições desnecessarias e liberação
 */
window.fetch = async function fetchPolyfill(url, options = {}) {
  if (typeof url === "object") {
    return nativeFetch(url);
  }
  let timeoutId;
  const timeoutProm = () =>
    new Promise((resolve, reject) => {
      timeoutId = setTimeout(
        () => reject(new ConnectionErrorTimeOut({ showToUser: false })),
        Math.min(options.timeout || MAX_WAIT_TIME, MAX_WAIT_TIME)
      );
    });

  const MAX_ATTEMPTS = 5;
  let attempts = 1;

  while (MAX_ATTEMPTS != attempts) {
    try {
      let response = await Promise.race([
        nativeFetch(url, { ...options }),
        timeoutProm(),
      ]);
      response.abort = () => console.log("ABORTED");
      if (response.ok) {
        const data = await response.json();
        response.json = () => data;

        if (typeof data.error === "undefined") return response;

        if (data.error == 0) {
          return response;
        } else {
          if (attempts + 1 === MAX_ATTEMPTS) {
            return response;
          } else {
            throw new Error(data.msg);
          }
        }
      }
      throw new Error(response.statusText);
    } catch (error) {
      attempts++;
      if (attempts === MAX_ATTEMPTS) {
        throw error;
      }
      clearTimeout(timeoutId);
      await retryTimeout(attempts);
    }
  }
};
