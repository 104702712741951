import { FormControl, TextField, withStyles } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import moment from "moment";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Moeda from "../../utils/Moeda";
import CardExpenses from "../CardComponents/CardsExpenses/Card";
import ExpansionPanel from "../ExpansionPanel/ExpansionPanel";
import SelectInput from "../Inputs/SelectInput";
import AmountMask from "../MaskedInputs/AmountMask";

const styles = (theme) => ({
  expansionPanel: {},

  "fade-enter": {
    maxHeight: 0,
    opacity: 0,
  },
  "fade-enter-active": {
    opacity: 1,
    maxHeight: "100vh",
    transition: "all 500ms",
  },
  "fade-exit": {
    opacity: 1,
    maxHeight: "100vh",
  },
  "fade-exit-active": {
    opacity: 0,
    maxHeight: 0,
    transition: "all 500ms",
  },
});

class VoucherComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expensesArray: [],
      invalid: false,
      requiredFieldsArray: ["valor", "funcionarioId"],
      selectedEmployeesArray: [],
      invalidValueHelperText: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onAddExpense = this.onAddExpense.bind(this);
    this.onDeleteExpense = this.onDeleteExpense.bind(this);
    this.onSaveExpense = this.onSaveExpense.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.verificaEmergencia = this.verificaEmergencia.bind(this);
    this.verificaLimiteMensal = this.verificaLimiteMensal.bind(this);
    this.verificaLimiteDiario = this.verificaLimiteDiario.bind(this);
    this.verificaLimiteDiaSemana = this.verificaLimiteDiaSemana.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.expenseContext.isLoading !== this.props.expenseContext.isLoading
    ) {
      let empArray = [];
      let savedExpenses = this.props.expenseContext[this.props.modelName].value;
      let newSavedExpenses = savedExpenses.map((expense) => {
        let exp = {};
        for (let key in expense) {
          exp[key] = { value: expense[key], error: false };
        }
        // Update selectedEmployeesArray
        let employee = this.props.expenseContext.employees.value.find(
          (e) => e.id == expense.funcionarioId
        );
        if (employee) empArray.push(employee);
        exp.isSaved = true;
        return exp;
      });
      if (!newSavedExpenses.length) {
        let invalid = false;
        this.setState({ invalid });
      }
      this.setState({
        expensesArray: newSavedExpenses,
        selectedEmployeesArray: empArray,
      });
    }
  }

  getMaxMoneyAdvance(funcionario, QUANTIDADE_RECEBIMENTO_MES) {
    if (funcionario.regime.regime.toLocaleUpperCase() !== "LIVRE") {
      if (funcionario.regime.regime.toLocaleUpperCase() === "FIXO") {
        return Moeda.create(funcionario.regime.teto).mount();
      } else if (funcionario.regime.regime.toLocaleUpperCase() === "IDEAL") {
        // const percentTeto = Percent.create(funcionario.regime.teto + "%").toNumber();
        return Moeda.create(QUANTIDADE_RECEBIMENTO_MES)
          .mul(funcionario.regime.teto)
          .mount();
      }
    }
  }

  verificaLimiteMensal(valorMax, valorRequisitado, emergencia, limiteMensal, funcionario) {
    const saldoDisponivel = Moeda.create(valorMax).subtract(valorRequisitado);
    const possoPegarPeloLimite =
      !Moeda.create(saldoDisponivel).isLessThan("0,00");
    if (possoPegarPeloLimite) {
      return { error: 0, msg: "" };
    }
    
    const valorMaiorQueEmergencia =
      Moeda.create(valorRequisitado).isGreaterThan(emergencia);

    if (valorMaiorQueEmergencia) {
      return {
        error: 1,
        msg: `${Moeda.create(
          valorRequisitado
        ).format()} não pode ser pego. Limite de ${Moeda.create(
          valorMax
        ).format()}. Emergência: ${Moeda.create(emergencia).format()}`,
      };
    }

    if (Moeda.create(valorMax).isLessThan("0,00") && !this.verificaEmergencia(funcionario,emergencia)) {
      return {
        error: 1,
        msg: `${Moeda.create(
          valorRequisitado
        ).format()} não pode ser pego. Limite de ${Moeda.create(
          valorMax
        ).format()}. Emergência já foi pego`,
      };
    }

    return { error: 0, msg: "" };
  }

  verificaLimiteDiario(valorMax, valorRequisitado, emergencia, limiteDiario) {
    const saldoDisponivel = Moeda.create(valorMax).subtract(valorRequisitado);
    const possoPegarPeloLimite =
      !Moeda.create(saldoDisponivel).isLessThan("0,00");

    const possoPegarPeloLimiteDiario =
      !Moeda.create(valorRequisitado).isLessOrEqualThan(limiteDiario);
    if (possoPegarPeloLimite && possoPegarPeloLimiteDiario) {
      return { error: 0, msg: "" };
    }

    const valorMaiorQueEmergencia =
      Moeda.create(valorRequisitado).isGreaterThan(emergencia);

    if (valorMaiorQueEmergencia) {
      return {
        error: 1,
        msg: `${Moeda.create(
          valorRequisitado
        ).format()} não pode ser pego. Limite de ${Moeda.create(
          valorMax
        ).format()}. Emergência: ${Moeda.create(emergencia).format()}`,
      };
    }

    if (Moeda.create(valorMax).isLessThan("0,00")) {
      return {
        error: 1,
        msg: `${Moeda.create(
          valorRequisitado
        ).format()} não pode ser pego. Limite de ${Moeda.create(
          valorMax
        ).format()}. Emergência já foi pego`,
      };
    }

    return { error: 0, msg: "" };
  }

  verificaLimiteDiaSemana(
    valorMax,
    valorRequisitado,
    emergencia,
    limiteDiaSemana
  ) {
    const saldoDisponivel = Moeda.create(valorMax).subtract(valorRequisitado);
    const possoPegarPeloLimite =
      !Moeda.create(saldoDisponivel).isLessThan("0,00");

    const possoPegarPeloLimiteDiario =
      !Moeda.create(valorRequisitado).isLessOrEqualThan(limiteDiaSemana);
    if (possoPegarPeloLimite && possoPegarPeloLimiteDiario) {
      return { error: 0, msg: "" };
    }

    const valorMaiorQueEmergencia =
      Moeda.create(valorRequisitado).isGreaterThan(emergencia);

    if (valorMaiorQueEmergencia) {
      return {
        error: 1,
        msg: `${Moeda.create(
          valorRequisitado
        ).format()} não pode ser pego. Limite de ${Moeda.create(
          valorMax
        ).format()}. Emergência: ${Moeda.create(emergencia).format()}`,
      };
    }

    if (Moeda.create(valorMax).isLessThan("0,00")) {
      return {
        error: 1,
        msg: `${Moeda.create(
          valorRequisitado
        ).format()} não pode ser pego. Limite de ${Moeda.create(
          valorMax
        ).format()}. Emergência já foi pego`,
      };
    }

    return { error: 0, msg: "" };
  }

  // Função para verificar se o limite de emergência já foi atingido hoje
  verificaEmergencia(funcionario, emergencia) {
    const hoje = moment().format("YYYY-MM-DD");
    const adiantamentos = this.props.expenseContext.voucherExpenses.value;
    const totalAdiantamentosHoje = adiantamentos.reduce(
      (acc, adiantamento) =>
        adiantamento.funcionarioId === funcionario.id &&
        moment(adiantamento.data).format("YYYY-MM-DD") === hoje
          ? acc.add(Moeda.create(adiantamento.valor))
          : acc,
      Moeda.create(0)
    );

    
    const adiantamentosHoje = Moeda.create(totalAdiantamentosHoje).isLessThan(emergencia)
    return adiantamentosHoje;
  }

  validateRequisicaoAdiantamento(funcionario, valorMax, valorRequisitado) {
    const regime = funcionario.regime;

    if (regime.regime.toLocaleUpperCase() === "LIVRE") {
      return { error: 0, msg: "" };
    }

    const emergencia = Moeda.create(regime.emergencia);
    valorRequisitado = Moeda.create(valorRequisitado);
    valorMax = Moeda.create(valorMax);

    // Encontra o limitante pela forma de distribuição
    if (regime.divisao.toLocaleUpperCase() === "MENSAL") {
      return this.verificaLimiteMensal(
        valorMax,
        valorRequisitado,
        emergencia,
        regime.teto,
        funcionario
      );
    } else if (regime.divisao.toLocaleUpperCase() === "DIARIO") {
    return this.verificaLimiteDiario(
        valorMax,
        valorRequisitado,
        emergencia,
        regime.limiteDiario
      );
    } else if (regime.divisao.toLocaleUpperCase() === "DSEMANA") {
      const weekDay = moment().format("ddd").toLowerCase();
      const limiteDiaSemana = regime.distribuicao[weekDay];
      return this.verificaLimiteDiaSemana(
        valorMax,
        valorRequisitado,
        emergencia,
        limiteDiaSemana
      );
    }
    return {
      error: 1,
      msg: `O valor ${Moeda.create(
        valorRequisitado
      ).format()} não pode ser pego.`,
    };
  }

  async handleInputChange(event, input, index) {
    let tempExpenseArray = [...this.state.expensesArray];
    if (input === "valor") {
      tempExpenseArray[index][input].value = event;
    } else if (input == "funcionarioId") {
      const findEmployee = (e) => e.id == event;

      tempExpenseArray[index][input].value = event;
      const isHaveEmployee =
        this.state.selectedEmployeesArray.find(findEmployee);
      if (!isHaveEmployee) {
        const empArray = [...this.state.selectedEmployeesArray];
        const employee =
          this.props.expenseContext.employees.value.find(findEmployee);
        empArray.push(employee);
        this.setState({ selectedEmployeesArray: empArray });
      }
    } else tempExpenseArray[index][input].value = event.target.value;

    tempExpenseArray[index].isSaved = false;
    this.setState({ expensesArray: tempExpenseArray });
  }

  onHandleChange(isExpanded) {
    this.props.handleChange(isExpanded);
  }

  async onAddExpense() {
    if (await this.props.onAddExpense()) {
      let newExpenseObj = {
        funcionarioId: { value: "", error: false },
        descricao: { value: "", error: false },
        valor: { value: "", error: false },
        isSaved: false,
      };
      this.setState({
        expensesArray: [...this.state.expensesArray, newExpenseObj],
      });
      this.setState({ invalid: true });
    }
  }

  async onDeleteExpense(index) {
    const initialLength = this.state.expensesArray.length;
    let expense = this.state.expensesArray.map((expense) => {
      let exp = {};
      for (let key in expense) {
        exp[key] = expense[key].value;
      }
      return exp;
    });
    if (expense[index].DATABASE_ID) {
      await this.props.onDeleteExpense(this.props.modelName, expense[index]);

      if (initialLength === 1) {
        let temp_expensesArray = [...this.state.expensesArray];
        temp_expensesArray.splice(index, 1);
        this.setState({ expensesArray: temp_expensesArray });
      }
      this.setState({ invalid: false });
    }
  }

  async onSaveExpense(index) {
    if (this.validateFields(index)) {
      let exp = {};
      for (let key in this.state.expensesArray[index]) {
        exp[key] = this.state.expensesArray[index][key].value;
      }
      if (this.state.expensesArray[index].DATABASE_ID) {
        exp.DATABASE_ID = this.state.expensesArray[index].DATABASE_ID.value;
      }
      let success = await this.props.onSaveExpense(this.props.modelName, exp);
      if (success) {
        // Change isSaved property
        let temp_expensesArray = [...this.state.expensesArray];
        temp_expensesArray[index].isSaved = true;
        this.setState({ invalid: false, expensesArray: temp_expensesArray });
      }
    } else return;
  }

  validateFields(index) {
    let requiredFieldsArray = this.state.requiredFieldsArray;
    let formInvalid = false;

    for (let i = 0; i < requiredFieldsArray.length; i++) {
      if (
        this.state.expensesArray[index][requiredFieldsArray[i]].value === ""
      ) {
        let expense = [...this.state.expensesArray];
        expense[index][requiredFieldsArray[i]].error = true;
        formInvalid = true;
        this.setState({ expensesArray: expense });
      } else {
        let expense = [...this.state.expensesArray];
        expense[index][requiredFieldsArray[i]].error = false;
        this.setState({ expensesArray: expense });
      }
      const employee = this.state.selectedEmployeesArray.find(
        (el) => el.id == this.state.expensesArray[index].funcionarioId.value
      );

      if (employee) {
        const adiantamentos =
          this.props.expenseContext.voucherExpenses.value.filter(
            (voucher) => voucher.FuncionarioId == employee.id
          );
        const valorVale = Moeda.create(
          this.state.expensesArray[index].valor.value || "0,00"
        ).mount();
        const valesTotalAdiantamentosCriados = adiantamentos.reduce(
          (memo, a) => memo.add(a.valor || "0,00"),
          Moeda.create(employee.valeMes || "0,00")
        );
        const recebimentoCelular =
          this.props.expenseContext.clientReceivement.value;
        const totalRecebimentos = recebimentoCelular.reduce(
          (memo, r) => memo.add(r.valor || "0,00"),
          Moeda.create(employee.totalRecebimentos)
        );
        //VERIFICAR A POSSIBILIDADE DE ACEITAR O VALE
        const valorMax = this.getMaxMoneyAdvance(
          employee,
          totalRecebimentos.ammount
        );
        const valorMaxCorrection = Moeda.create(valorMax || "0,00")
          .subtract(valesTotalAdiantamentosCriados || "0,00")
          .mount();

        const isValid = this.validateRequisicaoAdiantamento(
          employee,
          valorMaxCorrection,
          valorVale
        );

        if (isValid.error) {
          let expense = [...this.state.expensesArray];
          expense[index].valor.error = true;
          formInvalid = true;
          this.setState({
            expensesArray: expense,
            invalidValueHelperText: isValid.msg,
          });
        }
      }
    }

    if (!formInvalid) {
      return true;
    } else return false;
  }

  render() {
    let { classes } = this.props;

    return (
      <ExpansionPanel
        handleChange={this.onHandleChange}
        expanded={this.props.expanded}
        title={this.props.title || "Vale"}
        iconTitle={<WarningIcon />}
        className={classes.expansionPanel}
        expensesCount={this.state.expensesArray.length}
        isValid={!this.state.invalid}
        disableAddBtn={this.state.invalid}
        onAddExpense={this.onAddExpense}
        custom
      >
        <TransitionGroup className={classes.prodcutsList} component={null}>
          {this.state.expensesArray &&
            this.state.expensesArray.map((card, index) => (
              <CSSTransition
                timeout={500}
                classNames={{
                  enter: classes["fade-enter"],
                  enterActive: classes["fade-enter-active"],
                  exit: classes["fade-exit"],
                  exitActive: classes["fade-exit-active"],
                }}
                key={index}
              >
                <CardExpenses
                  key={index}
                  onDeleteExpense={() => this.onDeleteExpense(index)}
                  onSaveExpense={() => this.onSaveExpense(index)}
                  isSaved={card.isSaved}
                >
                  <FormControl fullWidth style={{ paddingBottom: 15 }}>
                    <SelectInput
                      label="Funcionário"
                      required
                      error={card.funcionarioId.error}
                      value={card.funcionarioId.value}
                      onChange={(event) =>
                        this.handleInputChange(event, "funcionarioId", index)
                      }
                      menuItem={(this.props.acceptsAdvanceOutStaff
                        ? this.props.expenseContext.employees.value
                        : this.props.expenseContext.employees.value.filter(
                            (emp) =>
                              emp.EquipeId ==
                              (this.props.expenseContext &&
                                this.props.expenseContext.currentUser &&
                                this.props.expenseContext.currentUser.EquipeId)
                          )
                      ).sort((a, b) =>
                        a.nome
                          .toLocaleLowerCase()
                          .localeCompare(b.nome.toLocaleLowerCase())
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth style={{ paddingBottom: 15 }}>
                    <TextField
                      label="Descrição"
                      fullWidth
                      value={card.descricao.value}
                      onChange={(event) =>
                        this.handleInputChange(event, "descricao", index)
                      }
                      error={card.descricao.error}
                      helperText={
                        card.descricao.error ? "Informe a descrição!" : null
                      }
                    ></TextField>
                  </FormControl>

                  <FormControl fullWidth style={{ paddingBottom: 15 }}>
                    <TextField
                      // label={`Valor ${card.funcionarioId.value == '' ? '' : `(máx: ${this.props.expenseContext.employees.value.find(emp => emp.id == card.funcionarioId.value) && this.props.expenseContext.employees.value.find(emp => emp.id == card.funcionarioId.value).regime.teto})`}`}
                      label={`Valor ${
                        card.valor.error
                          ? `${this.state.invalidValueHelperText}`
                          : ""
                      }`}
                      required
                      fullWidth
                      value={card.valor.value}
                      onChange={(e1, e2) =>
                        this.handleInputChange(e2, "valor", index)
                      }
                      InputProps={{
                        inputComponent: AmountMask,
                      }}
                      error={card.valor.error}
                      helperText={card.valor.error ? "Informe o valor!" : null}
                    ></TextField>
                  </FormControl>
                </CardExpenses>
              </CSSTransition>
            ))}
        </TransitionGroup>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(VoucherComponent);
