import DataAccess from "../DataAccessStrategy";

export default class Combustivel extends DataAccess {
  constructor() {
    super("Combustivel", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      nome: String,
      edited: Boolean,
    });
  }
}
