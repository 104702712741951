import React, { createContext } from "react";
import {
  DespesaCombustivelVeiculo,
  ManutencaoVeiculo,
  DespesaRota,
  AdiantamentoFuncionario,
  Funcionario,
  ClienteRecebimento,
  DespesaDeleted,
} from "../repository/models";
import BusinessError from "../errorDefinition/BusinessError";
import AppStorageError from "../errorDefinition/AppStorageError";
import RotaService from "../service/route/index";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import { AbstractContext } from "./AbstractContext";
import { getConfigKey, getEnvVar } from "../service/config";
import { getUser } from "../service/authentication/index";
import LocalStorage from "../utils/localStorage";
import * as syncService from "../service/sync/activeSync";
import SyncSend from "../service/sync/activeSync/syncSend";
import UnknownError from "../errorDefinition/UnknownError";

const rotaService = new RotaService();
const syncSend = new SyncSend();
// const funcionario = new Funcionario();
// const clienteRecebimento = new ClienteRecebimento();
// const adiantamentoFuncionario = new AdiantamentoFuncionario();
// const despesaDeleted = new DespesaDeleted();

const ExpensesContext = createContext({
  expenses: [],
});

/**
 * Contexto utilizado na pagina de despesas.
 */
const OBSERVED_MODELS = [
  "DespesaCombustivelVeiculo",
  "ManutencaoVeiculo",
  "DespesaRota",
  "AdiantamentoFuncionario",
  "Veiculo",
  "Combustivel",
  "Funcionario",
  "DespesaDeleted",
];

const localStorage = LocalStorage.instance;

class ExpensesContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.callObservable = this.callObservable.bind(this);
    this.observable = this.observable.bind(this);
    this.updateSelf = this.updateSelf.bind(this);
    this.syncData = this.syncData.bind(this);
    this.create = this.create.bind(this);
    this.delete = this.delete.bind(this);
    this.update = this.update.bind(this);
    this.setConfigUser = this.setConfigUser.bind(this);
    this.getRouteOpening = this.getRouteOpening.bind(this);
    this.fetchVoucherEmploy = this.fetchVoucherEmploy.bind(this);
    this.state = {
      fetchVoucherEmploy: this.fetchVoucherEmploy,
      isLoading: false,
      fuelExpense: { class: new DespesaCombustivelVeiculo(), value: [] },
      maintenanceExpense: { class: new ManutencaoVeiculo(), value: [] },
      otherExpenses: { class: new DespesaRota(), value: [] },
      voucherExpenses: { class: new AdiantamentoFuncionario(), value: [] },
      deleteExpenses: { class: new DespesaDeleted(), value: [] },
      employees: { class: new Funcionario(), value: [] },
      clientReceivement: { class: new ClienteRecebimento(), value: [] },
      isRouteOpen: this.getRouteOpening,
      currentUser: {},
      configKeys: {},
      callObservable: this.callObservable,
      create: this.create,
      update: this.update,
      delete: this.delete,
      setConfigUser: this.setConfigUser,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.observable();
    await this.setConfigUser();
  }

  callObservable() {
    this.observable();
  }

  observable() {
    return Promise.all([
      this.updateSelf("fuelExpense"),
      this.updateSelf("maintenanceExpense"),
      this.updateSelf("otherExpenses"),
      this.updateSelf("voucherExpenses"),
      this.updateSelf("deleteExpenses"),
      this.updateSelf("employees"),
    ]).catch(console.error);
  }

  async setConfigUser() {
    try {
      let configKeys = {};
      configKeys.isBitboxEmployee = await getConfigKey("isBitboxEmployee");

      configKeys.acceptsAdvanceOutStaff = await getEnvVar(
        "ACEITA_ADIANTAMENTO_FORA_EQUIPE"
      );

      let currentUser = await getUser();
      this.setState({ configKeys, currentUser });
    } catch (error) {
      new UnknownError({
        showToUser: false,
        devHelper:
          "Provavelmente o metodo setConfigUser não conseguiu recuperar os dados do usuário",
        title: "Erro inexperado",
        message: "Ocorreu um erro ao recuperar dados do usuário",
        type: "error",
        error: error,
      });
    }
  }

  /**Função responsável atualizar os dados dos estados.
   * @function
   * @param {string} stateName Nome  do estado a ser atualizado
   */
  async updateSelf(stateName) {
    this.setState({ isLoading: true });
    let state = { ...this.state };
    state[stateName].value = await state[stateName].class.filter(
      (a) => a.ativo != false
    );
    if (state[stateName].value.length)
      this.setState({ ...state, isLoading: false });
  }

  async syncData() {
    // Check if user is working online, and if so, send expenses.
    let localConfig = await localStorage.getItem("local-config");
    if (
      localConfig &&
      navigator &&
      (!localConfig.OnlineOffline || !navigator.onLine)
    ) {
      return;
    }

    await syncService.syncVehicle();
    await syncService.syncFuel();
    await syncSend.sendExpenses(null, false);
  }

  async fetchVoucherEmploy(func, mes, ano, login, senha) {
    if (!func || !mes || !ano || !login || !senha) {
      throw new BusinessError({
        message: "Paramentos faltando.",
        title: "Falha ao criar despesa!",
        type: "warning",
        method: "create",
      });
    }
    return await this.state.voucherExpenses.class.fetchVoucher(
      func,
      mes,
      ano,
      login,
      senha
    );
  }

  /**Função responsável por criar uma nova despesa.
   * @function
   * @param {string} stateName Nome  do estado em que será criado
   * @param {object} data Dado da nova despesa a ser criada
   */
  async create(stateName, data) {
    try {
      let routeOpening = await rotaService.routeOpening();
      const route = await rotaService.mainRoute();
      if (!routeOpening) {
        throw new BusinessError({
          message: "Abra a rota para prosseguir com esta ação.",
          title: "Falha ao criar despesa!",
          type: "warning",
          method: "create",
        });
      }
      data.ativo = true;
      data.shouldSync = true;
      data.RotumId = route.id;
      data.hashRota = route.hash;
      data.FuncionarioId = data.funcionarioId;
      data.hashRotaAbertura = routeOpening.hash;
      data.data = new Date();
      data.RotaAberturaId = routeOpening.id;
      data.RotumId = route.id;

      await this.state[stateName].class.create(data);
      await this.syncData();
      await this.updateSelf(stateName);
    } catch (error) {
      if (!(error instanceof BusinessError)) {
        new AppStorageError({
          message: error.message,
          title: "Falha ao criar nova despesa!",
          type: "error",
          method: "create",
          error,
        });
      }
      throw error;
    }
  }

  /**Função responsável por deletar uma despesa existente.
   * @function
   * @param {string} stateName Nome  do estado em que será deletado
   * @param {object} data Dado da nova despesa a ser deletada
   */
  async delete(stateName, data) {
    try {
      data.ativo = false;
      data.shouldSync = true;

      await this.state[stateName].class.put(data);

      delete data.DATABASE_ID;
      await this.state.deleteExpenses.class.create(data);

      await this.updateSelf(stateName);
      await this.updateSelf("deleteExpenses");
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao deletar despesa!",
        type: "error",
        method: "delete",
      });
      throw error;
    }
  }

  /**Função responsável por atualizar os dados de uma despesa existente.
   * @function
   * @param {string} stateName Nome  do estado a ser atualizado
   * @param {object} data Dado da despesa a ser atualizada
   */
  async update(stateName, data) {
    try {
      let routeOpening = await rotaService.routeOpening();
      if (!routeOpening) {
        throw new BusinessError({
          message: "Abra a rota para prosseguir com esta ação.",
          title: "Falha ao salvar",
          type: "warning",
          method: "CreateExpenses",
        });
      }
      data.shouldSync = true;
      await this.state[stateName].class.put(data);
      await this.syncData();
      await this.updateSelf(stateName);
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao atualizar dados da despesa!",
        type: "error",
        method: "ExpensesContext.update",
      });
      throw error;
    }
  }

  async getRouteOpening() {
    try {
      let routeOpening = await rotaService.routeOpening();
      if (!routeOpening) {
        this.context.addDialog({
          message: "Abra a rota para prosseguir com esta ação.",
          title: "Sem permissão",
          type: "warning",
          method: "AddExpenses",
          disableBackdropClick: false,
          hasCloseButton: false,
        });
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <ExpensesContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </ExpensesContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { ExpensesContext, ExpensesContextProvider };
