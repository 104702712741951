import AbstractService from "../../AbstractService";
import * as R from "ramda";
import RouteService from "../../route";
import { getUser, checkEmployeeToken } from "../../authentication";
import { saveClientsBlock } from "../../client";
import ApiErrorResponse from "../../../errorDefinition/ApiErrorResponse";
import moment from "moment";
import { getLocalCongfigVar } from "../../config";

/**
 * @extends AbstractService
 * @description Classe que reune as chamadas ao servidor para popular o banco de dados do dispositivo
 */
export default class SyncFetch extends AbstractService {
  constructor(serviceNotificationInstance) {
    super(serviceNotificationInstance);
    this.routeService = new RouteService();
  }

  /**
   * @description Recupera do servidor os dados de rota principal
   */
  async fetchMainRoute() {
    try {
      const user = await getUser();
      const URL = `${user.service}/api/rota/funcionario/${user.id}?token=${user.sessao.token}`;
      const response = await this.httpGet(URL);
      const data = await this.resolveFetchResponse(response);

      return data.rota;
    } catch (error) {
      await this.handlerConnectionError(error);
    }
  }
  /**
   * @description Recupera do servidor os dados de equipe
   */
  async fetchTeam() {
    try {
      const user = await getUser();
      const URL = `${user.service}/api/equipe?token=${user.sessao.token}`;
      const response = await this.httpGet(URL);
      const data = await this.resolveFetchResponse(response);
      return {
        equipe: data.equipe,
        rotaAbertura: data.rotaAbertura,
        funcionarios: data.funcionarios,
      };
    } catch (error) {
      await this.handlerConnectionError(error);
    }
  }

  async fetchBasketItems() {
    try {
      const user = await getUser();
      const URL = `${user.service}/api/cestaItens?token=${user.sessao.token}`;
      const response = await this.httpGet(URL);
      const data = await this.resolveFetchResponse(response);
      return data.basketItems;
    } catch (error) {
      await this.handlerConnectionError(error);
    }
  }
  /**
   * @description Recupera do servidor os dados de veicúlos
   */
  async fetchVehicle() {
    try {
      const user = await getUser();
      const URL = `${user.service}/api/veiculos?token=${user.sessao.token}`;
      const response = await this.httpGet(URL);
      const data = await this.resolveFetchResponse(response);

      return data.veiculos;
    } catch (error) {
      await this.handlerConnectionError(error);
    }
  }
  /**
   * @description Recupera do servidor os dados de combustivel
   */
  async fetchFuel() {
    try {
      const user = await getUser();
      const URL = `${user.service}/api/combustivel?token=${user.sessao.token}`;
      const response = await this.httpGet(URL);
      const data = await this.resolveFetchResponse(response);

      return data.combustiveis;
    } catch (error) {
      await this.handlerConnectionError(error);
    }
  }
  /**
   * @description Recupera do servidor os dados de documentos
   */
  async fetchDocument() {
    try {
      const user = await getUser();
      const URL = `${user.service}/api/documento?token=${user.sessao.token}`;
      const response = await this.httpGet(URL);
      const data = await this.resolveFetchResponse(response);

      return data.documentos;
    } catch (error) {
      await this.handlerConnectionError(error);
    }
  }
  /**
   * @description Recupera do servidor os dados de cidades e bairros
   */
  async fetchCityNeighborhood() {
    try {
      const user = await getUser();
      const URL = `${user.service}/api/cidades-bairros?token=${user.sessao.token}`;
      const response = await this.httpGet(URL);
      const data = await this.resolveFetchResponse(response);

      return {
        cidades: data.cidades,
        bairros: data.bairros,
      };
    } catch (error) {
      await this.handlerConnectionError(error);
    }
  }

  async saveClients(clients, blockIndex) {
    await saveClientsBlock(clients, blockIndex);
  }

  /**
   * @description Recupera do servidor os dados de combustivel
   */
  async generateBlocksClients(idClient, lastUpdate) {
    const user = await getUser();
    const URL = `${user.service}/api/rota/clientes/block?token=${
      user.sessao.token
    }${idClient ? "&clienteId=" + idClient : ""}${
      lastUpdate ? "&lastUpdate=" + lastUpdate : ""
    }`;

    const response = await this.httpGet(URL);
    const data = await this.resolveFetchResponse(response);

    const blockSize = await getLocalCongfigVar("ClientBlockSize");

    const blocks = R.splitEvery(blockSize || 50, data.data.ids);
    return {
      blocks,
      total: data.data.ids.length,
    };
  }
  /**
   * @description Baixa do servidor as informações relacionadas a um gurpo de clientes definido no parametro block
   * @param {[Number]} block  Array contendo os ids dos clientes que devem ser baixados do servidor
   * @example
   * await fetchBlockClients([1,2,3,4,44,5,666,77,754,32])
   */
  async fetchBlockClients(block) {
    const user = await getUser();
    const downloadMovementMonths = await getLocalCongfigVar(
      "DownloadMovementMonths"
    );
    let dataMaior = moment()
      .subtract(downloadMovementMonths || 2, "month")
      .startOf("day")
      .format("DD/MM/YYYY");

    const URL = `${user.service}/api/rota/clientes?token=${
      user.sessao.token
    }&block=${JSON.stringify(block)}&dataMaior=${dataMaior}`;
    const response = await this.httpGet(URL);
    const data = await this.resolveFetchResponse(response);

    return data.clientes;
  }

  /**
   * @description Recupera do servidor dados sobre: Campos requiridos, editaveis e recomendados
   */
  async fetchRequiredFields() {
    const user = await getUser();
    const URL = `${user.service}/api/campos-obrigatorios?token=${user.sessao.token}`;
    const response = await this.httpGet(URL);
    const data = await this.resolveFetchResponse(response);

    return {
      required: data.camposObrigatorios,
      recomended: data.camposRecomendaveis,
      editable: data.camposEdit,
    };
  }

  /**
   * @description Recupera do servidor dados de validadores de rota
   */
  async fetchValidators() {
    const user = await getUser();
    const URL = `${user.service}/api/validators?token=${user.sessao.token}`;
    const response = await this.httpGet(URL);
    const data = await this.resolveFetchResponse(response);

    return {
      validators: data.validators,
      usedValidators: data.validatorsUsados,
    };
  }

  /**
   * @description Recupera do servidor dados de feriados
   */
  async fetchHoliday() {
    const user = await getUser();
    const URL = `${user.service}/api/feriados?token=${user.sessao.token}`;
    const response = await this.httpGet(URL);
    const data = await this.resolveFetchResponse(response);

    return data.feriados;
  }

  /**
   * @description Recupera do servidor dados de variaveis de ambiente
   */
  async fetchEnvs() {
    const user = await getUser();
    const URL = `${user.service}/api/variavel-ambiente?token=${user.sessao.token}`;
    const response = await this.httpGet(URL);
    const data = await this.resolveFetchResponse(response);

    return data.data.ENV;
  }

  /**
   * @description Recupera do servidor dados de forma de pagamento
   */
  async fetchPaymentForm() {
    const user = await getUser();
    const URL = `${user.service}/api/forma-pagamento?token=${user.sessao.token}`;
    const response = await this.httpGet(URL);
    const data = await this.resolveFetchResponse(response);

    return data.formas;
  }

  /**
   * @description Recupera do servidor dados de meios de pagamento
   */
  async fetchPaymentMethod() {
    const user = await getUser();
    const URL = `${user.service}/api/meio-pagamento?token=${user.sessao.token}`;
    const response = await this.httpGet(URL);
    const data = await this.resolveFetchResponse(response);

    return data.meios;
  }

  /**
   * @description Recupera do servidor dados de cestas
   */
  async fetchBasketsProducts() {
    const user = await getUser();
    const URL = `${user.service}/api/cestasprodutos?token=${user.sessao.token}`;
    const response = await this.httpGet(URL);
    const data = await this.resolveFetchResponse(response);

    return data;
  }
  /**
   * @description Recupera do servidor dados de produtos
   */
  async fetchProduct() {
    const user = await getUser();
    const URL = `${user.service}/api/produtos?token=${user.sessao.token}`;
    const response = await this.httpGet(URL);
    const data = await this.resolveFetchResponse(response);

    return data.produtos;
  }

  /**
   * @description Recupera do servidor a ordem da lista diaria
   */
  async fetchDailyMap() {
    const routeOpening = await this.routeService.routeOpening();
    const routeOpeningFromServer = JSON.parse(
      localStorage.getItem("openingCache")
    );
    if (
      !routeOpening &&
      (!routeOpeningFromServer || !!routeOpeningFromServer.dataFechamento)
    )
      return;
    const user = await getUser();
    const URL = `${user.service}/api/diaria/map?token=${
      user.sessao.token
    }&hashRotaAbertura=${
      routeOpening ? routeOpening.hash : routeOpeningFromServer.hash
    }`;
    const response = await this.httpGet(URL);
    const data = await this.resolveFetchResponse(response);
    console.warn("recebido do servidor: ", data.data.map);
    return data.data.map;
  }

  async fetchScheduleTypes() {
    const user = await getUser();
    const origin = "PORTA-A-PORTA";
    const URL = `${user.service}/api/agendamento/tipos?token=${user.sessao.token}&origin=${origin}`;
    const response = await this.httpGet(URL);
    const data = await this.resolveFetchResponse(response);

    return data.tiposAgendamento;
  }

  /**
   * @description Utilitário para tratamento das respostas do servidor que recupera os dados
   * do corpo da menssagem e em seguida verifica se houve algum problema no server side
   * @param {fetchResponse} response Retorno da função nativa do js fetch()
   * @returns {Promise<any>} Campo body da resposta
   * @throws {ApiErrorResponse \\ InvalidAccessTokenError} Possiveis erros que podem ocorrer
   */
  async resolveFetchResponse(response) {
    const data = await response.json();
    if (data.error) {
      await checkEmployeeToken(data, response.abort);
      throw new ApiErrorResponse({
        message: data.msg,
        title: "Oops, houve um problema",
        type: "error",
      });
    }
    return data;
  }
}
