import React from "react";
import { Grid, withStyles, Typography } from "@material-ui/core";
import DesktopAccessDisabledIcon from "@material-ui/icons/DesktopAccessDisabled";
import MaximusImg from "../img/logo_maximus_solucoes.png";

const styles = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
    background: "#37aecd61",
  },
  innerContainer: {
    maxWidth: "800px",
    margin: "auto",
    padding: "30px 25px 40px 25px",
    background: "#00000038",
    borderRadius: "6px",
    color: "#fff",
  },
  warningText: {
    fontSize: "1.2rem",
    lineHeight: "1.7",
  },
});

class DeviceWarning extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("Entrou em um computador");
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid container className={classes.innerContainer}>
          <Grid item sm={12} justify={"center"} container alignItems={"center"}>
            <img width={200} src={MaximusImg} alt="Error"></img>
          </Grid>
          <Grid item sm={4} justify={"center"} container alignItems={"center"}>
            <DesktopAccessDisabledIcon
              style={{ fontSize: "12rem", color: "#00061ecc" }}
            />
          </Grid>
          <Grid item sm={8} container alignItems={"center"}>
            <Typography variant="overline" className={classes.warningText}>
              A tela deste aparelho não é compatível com a resolução do
              aplicativo. Para obter acesso ao aplicativo, utilize um{" "}
              <strong>smartphone</strong>.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(DeviceWarning);
