import DataAccess from "../DataAccessStrategy";

export default class ClienteFoto extends DataAccess {
  constructor() {
    super("ClienteFoto", {
      foto: String,
      ClienteId: String,
      shouldSync: Boolean,
      ClienteHash: String,
    });
  }

  async savePicture(data) {
    const shouldLog = false;
    const clientIdPath = "ClienteId";
    const oldFoto = await this.getAll(clientIdPath, data.ClienteId);
    if (oldFoto) await this.bulkDeleteByKeyPath(clientIdPath, data.ClienteId);

    return await this.model.put(data, shouldLog);
  }

  async getById(id) {
    const [pictureData] = await this.model
      .where("ClienteId")
      .equals(id)
      .toArray();
    return pictureData && pictureData.foto;
  }
  async getByHash(hash) {
    const [pictureData] = await this.model
      .where("ClienteHash")
      .equals(hash)
      .toArray();
    return pictureData && pictureData.foto;
  }
}
