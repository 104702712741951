import React from "react";
import TextField from "@material-ui/core/TextField";
import ButtonForAdd from "../Buttons/ButtonForAdd";
import Header from "../HeaderForAdd/Header";
import { Grid, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AmountMask from "../../components/MaskedInputs/AmountMask";
import Moeda from "../../utils/Moeda";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { ProductContext } from "../../contexts/ProductsContext";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";

const styles = (theme) => ({
  input: {
    paddingTop: "10px",
    paddingBottom: "60px",
  },
  cardInputs: {
    backgroundColor: "#FFf",
    position: "relative",
    marginTop: "10px",
  },
  button: {
    position: "relative",
    marginTop: "20px",
  },
  deleteButton: {
    paddingTop: "10px",
    "& button": {
      border: "1px solid red",
      color: "red",
    },
  },
});

/**
 * Formulário de registro de um item na cesta.
 */
class ItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identificador: { value: "", error: false },
      valor: { value: Moeda.create(0).mount(), error: false },
      hash: -1,
      databaseId: -1,
      id: -1,
    };
    this.setInitialValues = this.setInitialValues.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.checkFields = this.checkFields.bind(this);
  }

  componentDidMount() {
    if (this.props.itemData) {
      this.setInitialValues();
    }
  }

  async setInitialValues() {
    this.setState({
      identificador: {
        ...this.state.identificador,
        value: this.props.itemData.identificador,
      },
      valor: {
        ...this.state.valor,
        value: Moeda.create(this.props.itemData.valor).mount(),
      },
      hash: this.props.itemData.hash,
      databaseId: this.props.itemData.DATABASE_ID,
      id: this.props.itemData.id,
    });
  }

  handleChange(evt1, evt2) {
    this.setState({ valor: { ...this.state.valor, value: evt2 } });
  }

  async saveItem() {
    const noErrors = this.checkFields();
    if (noErrors)
      await this.props.onSaveItem({
        hash: this.state.hash,
        identificador: this.state.identificador.value,
        valor: this.state.valor.value,
        databaseId: this.state.databaseId,
        id: this.state.id,
      });
    return;
    // this.props.clickClose()
  }

  async deleteItem() {
    const resp = await this.messageDialogContext.addAsyncDialog({
      message: "Tem certeza que deseja excluir esse item do sistema?",
      title: "Excluir item",
      type: "warning",
      hasCloseButton: true,
      okText: "Sim",
      canceltext: "Cancelar",
    });

    if (!resp) return;
    await this.props.onDeleteItem(this.state.databaseId);
  }

  /**Função que salvará os dados no componente pai e fechará este componente em seguida.
   * @function
   */
  checkFields() {
    let check = true;
    if (this.state.identificador.value == "") {
      this.setState({
        identificador: { ...this.state.identificador, error: true },
      });
      check = false;
    } else {
      this.setState({
        identificador: { ...this.state.identificador, error: false },
      });
    }

    if (Moeda.create(this.state.valor.value).isZero()) {
      this.setState({ valor: { ...this.state.valor, error: true } });
      check = false;
    } else {
      this.setState({ valor: { ...this.state.valor, error: false } });
    }

    if (check) return true;
    return false;
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <MessageDialogContext.Consumer>
          {(messageDialogContext) => {
            this.messageDialogContext = messageDialogContext;
            return (
              <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                  <Header func={this.props.clickClose} icon={<CloseIcon />}>
                    {this.props.itemData ? "Editar item" : "Novo item"}
                  </Header>
                </Grid>
                <Grid container justify="center" className={classes.cardInputs}>
                  <Grid item xs={10} className={classes.input}>
                    <TextField
                      fullWidth
                      label="Nome do item"
                      value={this.state.identificador.value}
                      onChange={(e) =>
                        this.setState({
                          identificador: {
                            ...this.state.identificador,
                            value: e.target.value,
                          },
                        })
                      }
                      error={this.state.identificador.error}
                      helperText={
                        this.state.identificador.error
                          ? "Informe o nome do item!"
                          : null
                      }
                    />

                    <TextField
                      InputProps={{
                        startAdornment: <AttachMoneyIcon color="action" />,
                        inputComponent: AmountMask,
                      }}
                      fullWidth
                      label="Preço do item"
                      value={this.state.valor.value}
                      placeholder="0,00"
                      className={classes.cardInputs}
                      onChange={(e, value) =>
                        this.setState({
                          valor: { ...this.state.valor, value: value },
                        })
                      }
                      error={this.state.valor.error}
                      helperText={
                        this.state.valor.error
                          ? "Informe o valor do item!"
                          : null
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container justify="center" className={classes.button}>
                  <ButtonForAdd func={this.saveItem}>
                    {this.props.itemData ? "Salvar item" : "Adicionar item"}
                  </ButtonForAdd>
                </Grid>
                <Grid
                  container
                  justify="center"
                  className={classes.deleteButton}
                >
                  <ButtonForAdd func={this.deleteItem}>
                    Excluir item
                  </ButtonForAdd>
                </Grid>
              </Grid>
            );
          }}
        </MessageDialogContext.Consumer>
      </>
    );
  }
}

ItemForm.contextType = ProductContext;

export default withStyles(styles)(ItemForm);
