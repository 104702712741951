import React from "react";
import Text2Picture from "../service/Pictures/Text2Picture";
import ShareService from "../service/share";
// import QRCode from 'qrcode';
// import {concatImages, base64ToBlob} from '../service/Pictures/Pictures';

const PrinterContext = React.createContext({});
const DEFAULT_STATE = {
  title: "",
  open: false,
  content: "",
  viaClient: "",
  viaEmployee: "",
  headerMainText: "",
  headerSecondaryText: "",
  disableSellerButton: false,
  disableClientButton: false,
  imagemLogo: ""
};

class PrinterContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.onClickSellerButton = this.onClickSellerButton.bind(this);
    this.onClickClientButton = this.onClickClientButton.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onClickCloseIcon = this.onClickCloseIcon.bind(this);
    this.onClickBackToInit = this.onClickBackToInit.bind(this);
    this.printAndShare = this.printAndShare.bind(this);
    this.print = this.print.bind(this);
    this.callPrinterComponent = this.callPrinterComponent.bind(this);

    this.state = {
      title: "",
      open: false,
      content: "",
      viaClient: "",
      viaEmployee: "",
      headerMainText: "",
      onCloseDialog: null,
      headerSecondaryText: "",
      disableClientButton: false,
      disableSellerButton: false,
      imagemLogo: "",
      handleClose: this.handleClose,
      onClickCloseIcon: this.onClickCloseIcon,
      onClickBackToInit: this.onClickBackToInit,
      onClickSellerButton: this.onClickSellerButton,
      onClickClientButton: this.onClickClientButton,
      callPrinterComponent: this.callPrinterComponent,
      printAndShare: this.printAndShare,
      print: this.print,
    };
  }

  async onClickSellerButton() {
    await this.printAndShare(this.state.viaEmployee);
  }
  async onClickClientButton() {
    await this.printAndShare(`${this.state.viaClient}__x__${this.state.imagemLogo}`);
  }

  handleClose() {
    this.state.onCloseDialog && this.state.onCloseDialog();

    this.setState({
      open: false,
      content: "",
      viaClient: "",
      viaEmployee: "",
      imagemLogo: "",
      disableSellerButton: false,
      disableClientButton: false,
      onCloseDialog: console.log,
    });
  }

  onClickCloseIcon() {
    this.setState({ open: false });
    this.state.onCloseDialog && this.state.onCloseDialog();
  }
  onClickBackToInit() {
    this.setState({ open: false });
    this.state.onCloseDialog && this.state.onCloseDialog();
  }

  async printAndShare(invoice) {
    const blobImage = await Text2Picture.generate(invoice, 26, "blob");
    await ShareService.shareImage(
      blobImage,
      invoice.includes(";base64,") ? "qrcode.png" : "invoice.png"
    );
  }
  async print(invoice) {
    const blobImage = await Text2Picture.generate(invoice, 26, "blob");
    alert(blobImage);
    await ShareService.shareImage(
      blobImage,
      invoice.includes(";base64,") ? "qrcode.png" : "invoice.png"
    );
  }

  callPrinterComponent(info) {
    const viewState = Object.assign({ ...DEFAULT_STATE }, info);
    this.setState({ ...this.state, ...viewState, open: true });
  }

  render() {
    return (
      <PrinterContext.Provider value={this.state}>
        {this.props.children}
      </PrinterContext.Provider>
    );
  }
}

export { PrinterContextProvider, PrinterContext };
