import React from "react";
import { withStyles, Typography, Grid } from "@material-ui/core";
import AmountMask from "../MaskedInputs/AmountMask";
import Moeda from "../../utils/Moeda";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/pt";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MessageIcon from "@material-ui/icons/Message";
import ReceiptIcon from "@material-ui/icons/Receipt";
import TextField from "@material-ui/core/TextField";
import EventIcon from "@material-ui/icons/Event";
import SelectInput from "../Inputs/SelectInput";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import { Alert } from "@material-ui/lab";

const styles = (theme) => ({
  sectionTitle: {
    fontWeight: 400,
    paddingBottom: "20px",
  },
  input: {
    paddingBottom: "10px",
  },
  totalValueInput: {},
  signature: {
    height: "200px",
    border: "1px solid #000",
  },
});

/**
 * Componente que renderiza o formulário utilizado na tela de recimento
 */
class PaymentForm extends React.Component {
  /**
   * @param {function} props.setData Função que seta os dados dos inputs no elemento pai
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {string} props.data Dados do cliente
   * @property {boolean} this.state.datePickerIsOpen  Booleano que indica se o DatePicker foi clicado para ser aberto
   * @param {function} props.handleToggleSignature Função que altera a exibição do componente de assinatura
   * @param {string} props.signatureImage Imagem da assinatura do cliente em BASE64
   */
  constructor(props) {
    super(props);
    this.state = {
      datePickerIsOpen: false,
      paymentType: this.props.data.paymentType.value || "",
      showAlert: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePaymentType = this.handlePaymentType.bind(this);
    this.setCursorPosition = this.setCursorPosition.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
    this.alertRef = React.createRef();
  }

  /**Função que seta no componente os dados digitados no input.
   * @function
   * @param {string} name Tipo do dado
   *  @param {string} data Dado capturado pelo input
   */

  handleChange(name, data) {
    this.props.setData(name, data);
  }

  handlePaymentType(e) {
    const paymentType = e.target ? e.target.value : e;
    this.setState({ paymentType });
    this.props.setData("paymentType", paymentType);
  }

  setCursorPosition() {
    const { classes } = this.props;

    let input = document.querySelector(`.${classes.totalValueInput}`);
    if (!!input && input instanceof HTMLInputElement) {
      let pos = input.value.length;
      input.setSelectionRange(pos, pos);
    }
  }

  handleCloseAlert() {
    this.setState({ showAlert: false }, () => {
      if (this.alertRef.current) {
        this.alertRef.current.focus();
      }
    });
  }

  render() {
    let { classes } = this.props;
    let { data } = this.props;

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.sectionTitle}>
            FORMA DE PAGAMENTO
          </Typography>
        </Grid>

        <Grid item xs={11} className={classes.containerInputs}>
          {!this.props.disablePaymentType && (
            <Grid item xs className={classes.input}>
              <SelectInput
                menuItem={this.props.menuItems}
                value={this.state.paymentType}
                error={data.payment.error}
                mapper={this.props.mapper}
                onChange={this.handlePaymentType}
                label="Escolha uma forma de pagamento"
              ></SelectInput>
            </Grid>
          )}

          {this.state.paymentType || this.props.disablePaymentType ? (
            <>
              {this.state.paymentType !== "Parcelado" && (
                <Grid item xs className={classes.input}>
                  <TextField
                    fullWidth={true}
                    disabled={this.state.paymentType === "A vista"}
                    label="Valor de entrada"
                    onChange={(e1, e2) => this.handleChange("inputValue", e2)}
                    error={data.inputValue.error}
                    onSelect={this.setCursorPosition}
                    value={Moeda.create(data.inputValue.value).mount()}
                    helperText={
                      data.inputValue.error
                        ? this.props.inputValueHelperText
                          ? this.props.inputValueHelperText
                          : "O valor de entrada deve ser acima de 0,00"
                        : null
                    }
                    required={false}
                    ref={this.props.refInputValue}
                    InputProps={{
                      startAdornment: <AttachMoneyIcon color="action" />,
                      inputComponent: AmountMask,
                      classes: {
                        input: classes.totalValueInput,
                      },
                    }}
                    onBlur={this.props.showDialogBalance}
                    placeholder={"0,00"}
                  />
                </Grid>
              )}
              {this.state.paymentType === "A vista" && (
                <Grid item xs className={classes.input}>
                  <TextField
                    fullWidth={true}
                    label={`Desconto (Max: R$${this.props.maxDiscount})`}
                    onChange={(e1, e2) => this.handleChange("discount", e2)}
                    error={data.discount.error}
                    value={Moeda.create(data.discount.value).mount()}
                    helperText={
                      data.discount.error
                        ? `Digite um valor de deconto válido!`
                        : null
                    }
                    required={false}
                    ref={this.props.refDiscountValue}
                    InputProps={{
                      startAdornment: <AttachMoneyIcon color="action" />,
                      inputComponent: AmountMask,
                    }}
                    placeholder={"0,00"}
                  />
                </Grid>
              )}
              <Grid item xs className={classes.input}>
                <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                  <DatePicker
                    fullWidth
                    margin="normal"
                    placeholder="Selecione"
                    style={{ marginBottom: "0px", marginTop: "0px" }}
                    label={
                      !this.props.finalBalanceIsPositive()
                        ? "Data do próximo recebimento"
                        : "Data da próxima venda"
                    }
                    format="dd/MM/yyyy"
                    value={data.nextDate.value}
                    onChange={(e) => this.handleChange("nextDate", e)}
                    disablePast={true}
                    error={data.nextDate.error}
                    className={classes.picker}
                    inputRef={this.props.refDate}
                    onClose={this.props.showDialogReivementDate}
                    helperText={
                      data.nextDate.error ? "Selecione uma data!" : null
                    }
                    InputProps={{
                      startAdornment: <EventIcon style={{ color: "grey" }} />,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs className={classes.input}>
                <TextField
                  fullWidth={true}
                  label="Número do recibo"
                  type="number"
                  onKeyDown={(e) => {
                    (e.keyCode === 69 || e.keyCode === 190) &&
                      e.preventDefault();
                  }}
                  onChange={(e) =>
                    this.handleChange("receiptNumber", Number(e.target.value))
                  }
                  defaultValue={""}
                  helperText={""}
                  required={false}
                  InputProps={{
                    startAdornment: <ReceiptIcon color="action" />,
                  }}
                />
              </Grid>
              <Grid item xs className={classes.input}>
                <TextField
                  fullWidth={true}
                  label="Observação"
                  onChange={(e) => this.handleChange("note", e.target.value)}
                  defaultValue={""}
                  helperText={""}
                  required={false}
                  InputProps={{
                    startAdornment: <MessageIcon color="action" />,
                  }}
                />
              </Grid>
              <Grid item xs className={classes.input}>
                <TextField
                  fullWidth={true}
                  label="Saldo final do cliente"
                  disabled={true}
                  value={Moeda.create(data.finalBalance.value).mount()}
                  InputLabelProps={{
                    style: { color: "rgba(0, 0, 0, 0.54)" },
                  }}
                  helperText={""}
                  required={false}
                  InputProps={{
                    startAdornment: <AttachMoneyIcon color="action" />,
                    style: {
                      color: this.props.finalBalanceIsPositive()
                        ? "#32CD32"
                        : "#FF0000",
                    },
                  }}
                />
              </Grid>
              {this.state.showAlert && data.payment.error && (
                <Alert
                  ref={this.alertRef}
                  onClose={this.handleCloseAlert}
                  severity="error"
                  className={classes.input}
                  tabIndex={-1}
                >
                  Verifique todos os campos!
                </Alert>
              )}
            </>
          ) : null}

          {!this.props.disablePaymentType && (
            <>
              <Grid
                container
                justify="space-between"
                style={{ paddingTop: "30px" }}
                className={classes.input}
              >
                <Typography className={classes.sectionTitle}>
                  Assinatura
                </Typography>
                <AspectRatioIcon
                  fontSize="small"
                  onClick={this.props.handleToggleSignature}
                />
              </Grid>

              <Grid item className={classes.input}>
                <Grid
                  container
                  className={classes.signature}
                  onClick={this.props.handleToggleSignature}
                >
                  {this.props.signatureImage && (
                    <img
                      className={classes.sigImage}
                      alt="Imagem da assinatura"
                      src={this.props.signatureImage}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PaymentForm);
