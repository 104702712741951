async function dailyMapHumanize(map) {
  const DATABASE = window.DATABASE;
  async function getNeighborhood(id) {
    id = parseInt(id);
    const [neighbor] = await DATABASE.Bairro.where("id").equals(id).toArray();
    return neighbor;
  }
  async function getClient(id) {
    id = parseInt(id);
    const [client] = await DATABASE.Cliente.where("id").equals(id).toArray();
    return client;
  }

  const allBairros = await Promise.all(
    Object.keys(map).map(async (id) => {
      const { nome } = await getNeighborhood(id);
      return {
        id,
        nome,
      };
    })
  );

  function arrayToObj(arr, path) {
    const finalObj = {};
    const keys = arr.map((el, index) => ({ path: el[path], index }));
    for (const key of keys) {
      finalObj[key.path] = arr[key.index].position;
    }

    return finalObj;
  }

  async function makeClientObjReadable(clientObj) {
    const arrayClient = await Promise.all(
      Object.keys(clientObj).map(async (clientId) => {
        const { nome } = await getClient(clientId);
        return {
          nome: `${nome}: ${clientId}`,
          id: clientId,
          position: clientObj[clientId],
        };
      })
    );

    return arrayToObj(arrayClient, "nome");
  }

  const humanReadableMap = {};
  for (const bairro of allBairros) {
    const bairroKey = `${bairro.id}: ${bairro.nome}`;
    humanReadableMap[bairroKey] = await makeClientObjReadable(map[bairro.id]);
  }

  return humanReadableMap;
}

export async function getHumanReadableDailyMap() {
  const map = await window.LocalStorage.getItem("dailyMap");
  const readableMap = await dailyMapHumanize(map);
  return readableMap;
}
