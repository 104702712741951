import React from "react";
import {
  Tab,
  Tabs,
  Box,
  Divider,
  Dialog,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
  makeStyles,
  Grid,
} from "@material-ui/core";
import Bar from "@material-ui/core/AppBar";
import AppBar from "../../components/MenuSideBar/AppBar";
import DailyList from "../../components/Lists/DailyListConsumer";
import AllClientsList from "../../components/Lists/AllClientsList";
import PropTypes from "prop-types";
import { ClientContext } from "../../contexts/ClientContext";
import Moeda from "../../utils/Moeda";
import { Link } from "react-router-dom";
import FilterListForm from "../../components/Forms/FilterListForm";
import { builderFilterFunction } from "../../utils/buildFilterFunction";
import FilterCard from "../../components/CardComponents/FilterCard/index.js";
import LocalStorage from "../../utils/localStorage";
import { equals } from "ramda";

const localStorage = LocalStorage.instance;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100vw",
    height: "100vh",
  },
  dialogTitleContainer: {
    background: theme.palette.primary.main,
    justifyContent: "space-between",
    display: "flex",
  },
  modalTitle: {
    fontWeight: "500",
    fontSize: "1.2rem",
    color: theme.palette.primary.contrastText,
  },
  tabs: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #D3D3D3",
  },
  hidden: {
    display: "none",
  },
  appBar: {},
  divider: {
    height: "27px",
    width: "1px",
    top: "10px",
    position: "relative",
    backgroundColor: "#000",
  },
  headerWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    maxHeight: "170px",
    zIndex: theme.zIndex.appBar,
  },
  divDivisor: {
    marginTop: "180px",
  },
  tabItem: { textDecoration: "none", color: "inherit" },
});

function mapListTypeToIndex(type) {
  const options = {
    all: 5,
    daily: 0,
    repass: 1,
    debtor: 2,
    settled: 3,
    defaulter: 4,
    default: 0,
  };

  const tabIndex = options[type] || options.default;
  return tabIndex;
}

class TabPanel extends React.Component {
  render() {
    const { children, value, index, ...other } = this.props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {mapListTypeToIndex(value) === index && <Box>{children}</Box>}
      </Typography>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class ClientLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      clients: [],
      hasMore: false,
      isFiltered: false,
      filter: {
        nome: "",
        cpf: "",
        cidade: "",
        bairroIdCache: "",
      },
      shouldRenderInfiniteScroll: false,
    };
    this.lastPageType = React.createRef();
    this.lastPageType.current = this.props.match.params.listType;
    this.oldFilter = React.createRef(this.state.filter);
    this.oldFilter.current = this.state.filter;

    this.getDefaultFilters = this.getDefaultFilters.bind(this);
    this.handleToggleHeader = this.handleToggleHeader.bind(this);
    this.a11yProps = this.a11yProps.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.cpfComparator = this.cpfComparator.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.setClear = this.setClear.bind(this);
  }

  async _setFilters() {
    const [filter, isFiltered] = await this.getDefaultFilters();
    this.setState({
      filter,
      isFiltered,
    });
  }

  getDefaultFilterByListType(listType) {
    const filterByOptions = this.getFilters();

    const defaulter = {
      ...filterByOptions,
      veaco: true,
    };
    const debtor = {
      ...filterByOptions,
      saldoAtual: (client) =>
        Moeda.create(client.saldoAtual).isLessThan("0,00"),
    };

    const settled = {
      ...filterByOptions,
      saldoAtual: (client) =>
        Moeda.create(client.saldoAtual).isGreaterOrEqualThan(0),
    };

    const filters = {
      debtor,
      settled,
      defaulter,
      all: filterByOptions,
    };

    const selectedFilter = filters[listType];
    if (!selectedFilter) throw new Error("List Type not defined");

    return selectedFilter;
  }

  async fetchClients(listStatus) {
    const { blockSize, listType, page } = listStatus;
    if (
      this.props.match.params.listType !== this.lastPageType.current ||
      !equals(this.state.filter, this.oldFilter.current)
    ) {
      this.resetInifiniteScroll();
      return;
    }
    try {
      const filters = this.getDefaultFilterByListType(listType);
      const filterFn = builderFilterFunction(filters);
      const offset = Math.max(page - 1, 0) * blockSize;
      const fetchConfigs = {
        offset: offset,
        orderBy: "lowerCaseNome",
        limit: blockSize,
      };
      const fetchFilter = [{ functionToApply: "and", value: filterFn }];
      const newClients = await this.context.fetchClients(
        fetchFilter,
        fetchConfigs
      );
      const hasMore = newClients.length === blockSize;
      const clients = this.state.clients.concat(newClients);
      this.setState({ clients, hasMore });
    } catch (error) {
      return;
    }
  }

  resetInifiniteScroll() {
    this.setState({
      clients: [],
      shouldRenderInfiniteScroll: false,
      hasMore: false,
    });

    setTimeout(
      () => this.setState({ shouldRenderInfiniteScroll: true, hasMore: true }),
      250
    );
  }

  async componentDidUpdate() {
    if (this.props.match.params.listType !== this.lastPageType.current) {
      this.lastPageType.current = this.props.match.params.listType;
      await this._setFilters();
      this.resetInifiniteScroll();
    }
    if (!equals(this.state.filter, this.oldFilter.current)) {
      this.oldFilter.current = this.state.filter;
      this.resetInifiniteScroll();
    }
  }

  async componentDidMount() {
    let clientsCount = await localStorage.getItem("TOTAL_CLIENTS");
    const downloadedClients = await this.context.getAllClientsCount();
    await this._setFilters();
    this.setState({
      clientsCount,
      downloadedClients: downloadedClients,
      hasMore: true,
      shouldRenderInfiniteScroll: true,
    });
  }

  async getDefaultFilters() {
    const pageName = this.props.match.params.listType;
    let filter = JSON.parse(window.localStorage.getItem(pageName));
    let isFiltered = true;
    let isRouteOpen = await localStorage.getItem("route-opening");
    if (!filter || !isRouteOpen) {
      filter = {
        nome: "",
        cpf: "",
        cidade: "",
        bairroIdCache: "",
      };
      isFiltered = false;
    }

    return [filter, isFiltered];
  }

  handleToggleHeader() {
    this.setState({ header: !this.state.header });
  }

  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  setFilters(filter) {
    this.toggleModal();
    this.setState({ filter });
    const pageName = this.props.match.params.listType;
    window.localStorage.setItem(pageName, JSON.stringify(filter));
  }

  getFilters() {
    const fields = Object.keys(this.state.filter);
    const filters = {};
    let hasValidProperty = false;
    for (const key of fields) {
      const value = this.state.filter[key];
      if (!value) {
        hasValidProperty = false || hasValidProperty;
        continue;
      }

      if (typeof value === "string") {
        hasValidProperty = false || hasValidProperty;
        if (!value.trim()) continue;
      }

      if (key == "nome") {
        filters[key] = (client) => {
          if (!client[key]) return false;
          return client[key].toLowerCase().includes(value.toLowerCase());
        };

        hasValidProperty = true;
      } else if (key == "cpf") {
        filters[key] = (client) => this.cpfComparator(value, client);
        hasValidProperty = true;
      } else {
        filters[key] = value;
        hasValidProperty = true;
      }
    }

    if (hasValidProperty !== this.state.isFiltered) {
      this.setState({ isFiltered: hasValidProperty });
    }
    return filters;
  }

  cpfComparator(value, client) {
    if (!client.cpf) return false;
    const clientCpf = client.cpf
      .replace(".", "")
      .replace(".", "")
      .replace("-", "")
      .trim();
    const searchCpf = value
      .replace(".", "")
      .replace(".", "")
      .replace("-", "")
      .trim();

    const isValid = clientCpf.includes(searchCpf);
    return isValid;
  }

  clearFilter() {
    const filter = {
      nome: "",
      cpf: "",
      cidade: "",
      bairroIdCache: "",
    };
    const isFiltered = false;
    const pageName = this.props.match.params.listType;
    window.localStorage.setItem(pageName, JSON.stringify(filter));
    this.clearDialog();
    this.setState({
      filter,
      isFiltered,
    });
  }

  setClear(clearFunc) {
    this.clearDialog = clearFunc;
  }

  render() {
    const { classes } = this.props;
    const filterByOptions = this.getFilters();

    const daily = {
      ...filterByOptions,
      isRepass: false,
    };

    const repass = {
      ...filterByOptions,
      isRepass: true,
    };
    const fetchClients = this.fetchClients.bind(this);

    const listType = this.props.match.params.listType;
    return (
      <>
        <div className={classes.root} ref={this.rootRef}>
          <div className={classes.headerWrapper}>
            <AppBar position="relative" search={false} logo={true} />
            <Divider />
            <Bar position="relative" color="default" className={classes.appBar}>
              <Tabs
                className={classes.tabs}
                value={mapListTypeToIndex(this.props.match.params.listType)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Link className={classes.tabItem} to="/daily">
                  <Tab label="DIARIA" {...this.a11yProps(0)} />
                </Link>
                <Link className={classes.tabItem} to="/repass">
                  <Tab label="REPASSE" {...this.a11yProps(1)} />
                </Link>
                <Link className={classes.tabItem} to="/debtor">
                  <Tab label="DEVEDOR" {...this.a11yProps(2)} />
                </Link>
                <Link className={classes.tabItem} to="/settled">
                  <Tab label="QUITADO" {...this.a11yProps(3)} />
                </Link>
                <Link className={classes.tabItem} to="/defaulter">
                  <Tab label="INADIMPLENTE" {...this.a11yProps(4)} />
                </Link>
                <Link className={classes.tabItem} to="/all">
                  <Tab label="Todos" {...this.a11yProps(5)} />
                </Link>
              </Tabs>

              <FilterCard
                isActive={this.state.isFiltered}
                onClickClear={this.clearFilter}
                onClick={this.toggleModal}
              />
            </Bar>
          </div>
          <div className={classes.divDivisor} />
          <TabPanel value={listType} index={0}>
            <DailyList
              onSync={this.context.handleSyncSingleClient}
              filter={builderFilterFunction(daily)}
            />
          </TabPanel>

          <TabPanel value={listType} index={1}>
            <DailyList
              onSync={this.context.handleSyncSingleClient}
              filter={builderFilterFunction(repass)}
              isRepassList={true}
            />
          </TabPanel>

          <TabPanel value={listType} index={2}>
            <AllClientsList
              onSync={this.context.handleSyncSingleClient}
              clients={this.state.clients}
              hasMore={this.state.hasMore}
              onFetchMore={fetchClients}
              listType={listType}
              shouldRenderInfiniteScroll={this.state.shouldRenderInfiniteScroll}
            />
          </TabPanel>

          <TabPanel value={listType} index={3}>
            <AllClientsList
              onSync={this.context.handleSyncSingleClient}
              clients={this.state.clients}
              hasMore={this.state.hasMore}
              onFetchMore={fetchClients}
              listType={listType}
              shouldRenderInfiniteScroll={this.state.shouldRenderInfiniteScroll}
            />
          </TabPanel>

          <TabPanel value={listType} index={4}>
            <AllClientsList
              onSync={this.context.handleSyncSingleClient}
              clients={this.state.clients}
              hasMore={this.state.hasMore}
              onFetchMore={fetchClients}
              listType={listType}
              shouldRenderInfiniteScroll={this.state.shouldRenderInfiniteScroll}
            />
          </TabPanel>
          <TabPanel value={listType} index={5}>
            <AllClientsList
              onSync={this.context.handleSyncSingleClient}
              clients={this.state.clients}
              hasMore={this.state.hasMore}
              onFetchMore={fetchClients}
              clientsCount={this.state.clientsCount}
              downloadedClients={this.state.downloadedClients}
              listType={listType}
              shouldRenderInfiniteScroll={this.state.shouldRenderInfiniteScroll}
            />
          </TabPanel>

          <FilterDialog
            handleCancel={this.toggleModal}
            handleOk={this.setFilters}
            open={this.state.isModalOpen}
            clear={this.setClear}
          />
        </div>
      </>
    );
  }
}
ClientLists.contextType = ClientContext;
export default withStyles(styles)(ClientLists);

const getStyles = makeStyles(styles);

function FilterDialog({ handleCancel, handleOk, open, clear }) {
  const classes = getStyles();
  const [value, setValue] = React.useState({
    nome: "",
    cpf: "",
    cidade: "",
    bairroIdCache: "",
  });

  function onInputchange(inputName, inputValue) {
    setValue({
      ...value,
      [inputName]: inputValue,
    });
  }

  function clearInputs() {
    setValue({
      nome: "",
      cpf: "",
      cidade: "",
      bairroIdCache: "",
    });
  }

  React.useEffect(() => {
    clear(clearInputs);
  }, [clear]);

  const onClickCleanFields = function () {
    setValue({
      nome: "",
      cpf: "",
      cidade: "",
      bairroIdCache: "",
    });
  };

  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      open={!!open}
      fullWidth
      onBackdropClick={handleCancel}
    >
      <DialogTitle
        className={classes.dialogTitleContainer}
        id="confirmation-dialog-title"
      >
        <Grid item>
          <Typography className={classes.modalTitle} component="h4">
            FILTRAR LISTA
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <FilterListForm onInputChange={onInputchange} {...value} />
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          <Grid item>
            <Grid container>
              <Grid item>
                <Button color="secondary" onClick={onClickCleanFields}>
                  Limpar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <Button autoFocus onClick={handleCancel} color="primary">
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => handleOk(value)} color="primary">
                  Aplicar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
