import React from "react";
import { withStyles, Dialog, Slide, Backdrop, Button } from "@material-ui/core";
import { Camera, FACING_MODES } from "./PhotoCapture";
import TakePhotoPictureActionBar from "./TakePhotoPictureActionBar";
import CropPicture from "./CropPicture";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
const applyStyles = withStyles((theme) => ({
  root: {
    height: "100%",
  },
  actionBar: {
    position: "fixed",
    bottom: "0px",
    left: "0px",
    width: "100vw",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
  },
  floatButton: {
    position: "fixed",
    right: "15px",
    top: "15px",
    color: "#000",
    padding: theme.spacing(1),
    background: "#929292bf",
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

class PhotoContainer extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onPhotoTakeComplete: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      imageSrc: "",
      frontCam: false,
      isLoading: true,
      isModalOpen: false,
      isImageMirror: false,
      handleTakePhoto: console.log,
    };

    this.capturePhoto = this.capturePhoto.bind(this);
    this.toggleCam = this.toggleCam.bind(this);
    this.toggleMirror = this.toggleMirror.bind(this);
    this.handleCameraError = this.handleCameraError.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.onProcessComplete = this.onProcessComplete.bind(this);
    this.notifyChildTakePhoto = this.notifyChildTakePhoto.bind(this);
  }

  async capturePhoto(image) {
    this.setState({ imageSrc: image, isModalOpen: true }, () =>
      this.setState({ isLoading: false })
    );
  }

  toggleCam() {
    this.setState({ frontCam: !this.state.frontCam });
    this.toggleMirror();
  }

  toggleMirror() {
    this.setState({ isImageMirror: !this.state.isImageMirror });
  }

  handleCameraError(error) {
    this.setState({ isLoading: false });
    this.context.addDialog({
      type: "warning",
      message: this.renderErrorMessage(error),
      title: "Erro ao exibir camera",
    });
  }

  renderErrorMessage(error) {
    return error.message;
  }

  onClickBack() {
    this.setState({ imageSrc: "", isModalOpen: false });
  }

  onProcessComplete(image) {
    this.props.onPhotoTakeComplete(image);
    this.setState({ imageSrc: image, isModalOpen: false });
    this.props.onClose();
  }

  notifyChildTakePhoto() {
    this.setState({ isLoading: true });
    this.state.handleTakePhoto();
  }

  render() {
    const { classes } = this.props;
    const facingMode = this.state.frontCam
      ? FACING_MODES.USER
      : FACING_MODES.ENVIRONMENT;

    return (
      <Dialog
        fullScreen
        open={this.props.isOpen}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <div id="captureContainer" className={classes.root}>
          <Camera
            isFullscreen
            idealFacingMode={facingMode}
            onTakePhoto={this.capturePhoto}
            onCameraError={this.handleCameraError}
            isImageMirror={this.state.isImageMirror}
            attachTakePhotoEvent={(takePhoto) =>
              this.setState({ handleTakePhoto: takePhoto })
            }
            onCameraStart={() => this.setState({ isLoading: false })}
          />
          <div className={classes.actionBar}>
            <Button
              color="inherit"
              onClick={this.props.onClose}
              aria-label="close"
              className={classes.floatButton}
            >
              <CloseIcon color="inherit" />
            </Button>
            <TakePhotoPictureActionBar
              onClickFlip={this.toggleCam}
              disabled={this.state.isLoading}
              onClickChangeMirror={this.toggleMirror}
              onClickTakePhoto={this.notifyChildTakePhoto}
            />
          </div>
        </div>
        <Dialog
          fullScreen
          open={this.state.isModalOpen}
          TransitionComponent={Transition}
          onClose={() => this.setState({ isModalOpen: false })}
        >
          {!!this.state.imageSrc ? (
            <CropPicture
              imageSrc={this.state.imageSrc}
              onClickBack={this.onClickBack}
              onClickDone={this.onProcessComplete}
              errorHandler={this.handleCameraError}
            />
          ) : (
            <div />
          )}
        </Dialog>
        <Backdrop className={classes.backdrop} open={this.state.isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
      </Dialog>
    );
  }
}

PhotoContainer.contextType = MessageDialogContext;
export default applyStyles(PhotoContainer);
