import React from "react";
import { withStyles } from "@material-ui/core";
import { Grid, Divider, Typography } from "@material-ui/core";

const applyStyles = withStyles((theme) => ({
  root: {
    marginBottom: "10px",
  },
  mainText: {
    fontSize: "1.5rem",
    fontWeight: 450,
    color: theme.palette.text.primary,
  },
  secondaryText: {
    fontSize: ".78rem",
    color: theme.palette.text.secundary,
  },
  divider: {
    backgroundColor: "#999",
  },
}));

class DailyListCard extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid
          className={classes.root}
          alignContent="flex-start"
          alignItems="flex-end"
          container
        >
          <Grid item xs={9}>
            <Typography className={classes.mainText}>
              {this.props.mainText}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.secondaryText} align="right">
              {this.props.secondaryText}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default applyStyles(DailyListCard);
