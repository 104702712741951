import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import SideBar from "../MenuSideBar/SideBar/SideBar";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  cabecalho: {
    padding: "8px 15px",
    backgroundColor: "#fff",
    height: "62px",
  },
  title: {
    paddingLeft: "5px",
  },
  icon: {
    paddingTop: "3px",
    color: "black",
  },
  text: {
    fontSize: "1.2rem",
    fontFamily: "Roboto, sans-serif",
  },
});

/**
 * Header onde se tem um menu sandwich que abre o menu da aplicação.
 */
class HeaderMenu extends React.Component {
  /**
   * @param {string} props.children Texto principal do Header
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {boolean} this.state.sideBar Boleano que define a exibição do menu
   */
  constructor(props) {
    super(props);
    this.state = {
      sideBar: false,
    };
    this.showSideBar = this.showSideBar.bind(this);
  }

  /**Função chamada ao clicar no ícone, assim abrindo e fechando o menu
   * @function
   */
  showSideBar() {
    this.setState({ sideBar: !this.state.sideBar });
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid container className={classes.cabecalho} alignItems="stretch">
          <Grid item xs={1} container alignItems="center">
            <MenuIcon onClick={this.showSideBar} />
          </Grid>
          <Grid
            item
            xs={11}
            className={classes.title}
            container
            alignItems="center"
          >
            {typeof this.props.children == "string" ? (
              <Typography className={classes.text}>
                {this.props.children}
              </Typography>
            ) : (
              this.props.children
            )}
          </Grid>
        </Grid>

        <SideBar
          onClose={() => this.setState({ sideBar: false })}
          showSideBar={this.state.sideBar}
        />
      </>
    );
  }
}

export default withStyles(styles)(HeaderMenu);
