import DexieCollections from "../DexieCollections/employeedb";
import SecurityLogger from "../errorsModels/EventLog";
import AppStorageError from "../../errorDefinition/AppStorageError";
export default class DexieDataAccessObjectInterface {
  static get database() {
    if (!window.DATABASE)
      throw new Error(
        "Dexie database definition must be initiatate before access it."
      );
    return window.DATABASE;
  }

  static define(DATABASE, className, fields = {}) {
    if (!DexieDataAccessObjectInterface[className]) {
      DATABASE[className].defineClass(fields);
      DexieDataAccessObjectInterface[className] = true;
    }
    return DATABASE[className];
  }

  #model = undefined;

  set model(model) {
    this.#model = model;
  }

  get model() {
    return this.#model;
  }

  #securityLogger = new SecurityLogger();

  constructor(className, fields = {}) {
    if (!className) throw new Error("Dexie model requires a name!");
    if (!window.DATABASE) {
      new DexieCollections("employee_db");
    }

    this.model = DexieDataAccessObjectInterface.define(
      window.DATABASE,
      className,
      fields
    );

    this.fields = fields;
    this.className = className;
    this.#registerHooks(className);
  }

  put = (data, shouldLog = true) => {
    this.#addIndetifier(data);
    if (shouldLog) {
      const clonedInfo = this.#removeUnnecessaryFieldsToLog(data);

      this.#securityLogger
        .registerEvent("DATABASE", this.className, "update", clonedInfo)
        .catch(this.#handleIndexeddbError);
    }

    this.model.put(data);
  };

  /**
   * Utilitario mais performatico na inserção. Mas é valido ressaltar que sua utilização deve ser previamente
   * avaliada. Caso exista a necessidade de salvar as criações de dados feitas no log de segurança, prefira
   * a utilização do metodo convecional create desta mesma classe.
   * @param {Array} modelArr Conjunto de dados a ser salvo
   */
  bulkCreate = (modelArr) => this.model.bulkAdd(modelArr);
  delete = (DATABASE_ID) => this.model.delete(DATABASE_ID);

  removeAll = () => this.model.clear();

  async findByHash(hash) {
    const model = await this.model.where({ hash: String(hash) }).toArray();
    return model[0];
  }

  filter = async (filterFunc) => {
    let result = await this.model.filter(filterFunc);

    result = await result.toArray();
    return result;
  };

  async getAll(keyPath, ids) {
    try {
      if (!ids || !keyPath) return await this.model.toArray();
      return await this.model.where(keyPath).anyOf(ids).toArray();
    } catch (e) {
      const comparator = Array.isArray(ids)
        ? (row) => ids.includes(row[keyPath])
        : (row) => ids === row[keyPath];

      const collection = await this.model.filter(comparator).toArray();

      return collection;
    }
  }

  async count() {
    return await this.model.count();
  }

  create(modelData, shouldLog = true) {
    this.#addIndetifier(modelData);
    if (shouldLog) {
      const clonedInfo = this.#removeUnnecessaryFieldsToLog(modelData);

      this.#securityLogger
        .registerEvent("DATABASE", this.className, "add", clonedInfo)
        .catch(this.#handleIndexeddbError);
    }
    return this.model.add(modelData).then((el) => {
      return el;
    });
  }

  async getAllActives(keyPath, ids) {
    return await this.model.filter((a) => a.ativo != false);
  }

  bulkDeleteByKeyPath(key = "id", ids) {
    return this.model.where(key).anyOf(ids).delete();
  }

  #registerHooks = (className) => {};

  #addIndetifier = (data) => {
    if (!data.hash) {
      data.hash = String(window.DATABASE.generateUniqId());
    }
    if (!data.id) {
      data.id = window.DATABASE.generateUniqId();
    }
    return data;
  };

  #handleIndexeddbError = (className, data) => {
    const error = {
      className,
      data,
    };
    new AppStorageError({ method: "#handleIndexeddbError", error });
  };

  #removeUnnecessaryFieldsToLog = (data) => {
    const clonedInfo = { ...data };
    if (this.className == "Cliente") {
      clonedInfo.foto = undefined;
      clonedInfo.assinatura = undefined;
      clonedInfo.venda = undefined;
      clonedInfo.recebimentos = undefined;
      clonedInfo.documentos = undefined;
      clonedInfo.trocasAvulso = undefined;
      clonedInfo.clienteEdit = undefined;
      clonedInfo.agendamentos = undefined;
      clonedInfo.agendamentosEditados = undefined;
    } else if (this.className == "ClienteVenda") {
      clonedInfo.assinatura = undefined;
    } else if (this.className == "ClienteDocumento") {
      clonedInfo.foto = undefined;
    }

    delete clonedInfo.shouldSync;
    return clonedInfo;
  };
}
