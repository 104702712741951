import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import deLocale from "date-fns/locale/pt";

const styles = (theme) => ({
  date: {
    height: "45px",
  },
  monthYear: {
    fontSize: "13px",
  },
  calendarIcon: {
    color: "#555555",
  },
  text: {
    color: "#555555",
  },
});

/**
 * Componente onde exibe o mês, ano e o calendário .
 * O componente utiliza este meio de CSS acima.
 */
class HeaderDate extends React.Component {
  /**
   * @param {string} props.background Cor de background do componente
   * @param {function} props.setData Função que irá setar a data no componente pai
   * @property {State} this.date Estado onde será armazenado a data
   * @property {State} this.openCalendar Estado de controle de abertura e fechamento do calendário
   */
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      openCalendar: false,
    };
    this.clickOpenCalendar = this.clickOpenCalendar.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onCloseCalendar = this.onCloseCalendar.bind(this);
    this.popUp = React.createRef();
  }
  /**Função para abertura e fechamento do calendário ao clicar
   * @function
   */
  clickOpenCalendar() {
    this.setState({ openCalendar: !this.state.openCalendar });
  }

  onChangeDate(e) {
    this.setState({ date: e }, () => this.props.setData(this.state.date));
  }

  onCloseCalendar() {
    this.setState({ openCalendar: !this.state.openCalendar });
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Grid
          container
          onClick={this.clickOpenCalendar}
          className={classes.date}
          style={{ backgroundColor: this.props.background }}
          alignItems="center"
        >
          <Grid item xs={2}>
            <Grid container justify="center">
              <DateRangeIcon
                className={classes.calendarIcon}
                onClick={this.clickOpenCalendar}
              />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container justify="center">
              <Typography className={classes.text}>
                {this.state.date
                  .toLocaleDateString("pt-BR", { month: "long" })
                  .toUpperCase()}{" "}
                - {this.state.date.getUTCFullYear()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {this.state.openCalendar ? (
          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
            <DatePicker
              open={true}
              onClose={this.onCloseCalendar}
              disabled
              variant="dialog"
              openTo="year"
              format="MM/yyyy"
              views={["year", "month"]}
              onChange={this.onChangeDate}
              TextFieldComponent={"a"}
            />
          </MuiPickersUtilsProvider>
        ) : null}
      </>
    );
  }
}
export default withStyles(styles)(HeaderDate);
