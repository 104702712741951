import DataAccess from "../DataAccessStrategy";
import { fetchPollyfill } from "../../service/AbstractService";
import { getUser } from "../../service/authentication";

export default class AdiantamentoFuncionario extends DataAccess {
  constructor() {
    super("AdiantamentoFuncionario", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      hash: String,
      descricao: String,
      valor: String,
      data: String,
      ativo: Boolean,
      RotaAberturaId: Number,
      FuncionarioId: Number,
      hashRotaAbertura: String,
    });
    this.filterDifferentHashRotaAbertura =
      this.filterDifferentHashRotaAbertura.bind(this);
    this.filterByHashRotaAbertura = this.filterByHashRotaAbertura.bind(this);
  }
  async filterDifferentHashRotaAbertura(routeOpening) {
    if (routeOpening) {
      return await this.model
        .where("hashRotaAbertura")
        .notEqual(routeOpening.hash)
        .filter((a) => a.ativo != false)
        .toArray();
    } else {
      return await this.model.toArray();
    }
  }
  async filterByHashRotaAbertura(routeOpening) {
    if (routeOpening) {
      return await this.model
        .where("hashRotaAbertura")
        .equals(routeOpening.hash)
        .filter((a) => a.ativo != false)
        .toArray();
    } else {
      return await this.model.toArray();
    }
  }

  async fetchVoucher(func, mes, ano, login, senha) {
    const user = await getUser();
    const URL = `${user.service}/api/funcionario/${func}/relatorio/${mes}?token=${user.sessao.token}`;
    const response = await fetchPollyfill(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        login: login,
        senha: senha,
      }),
    });
    const data = await response.json();
    return data;
  }
}
