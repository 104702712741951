/* eslint-disable no-mixed-operators*/

import { Checkbox, FormControlLabel, Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import clsx from "clsx";
import React from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import history from "../../../../router/History";
import Moeda from "../../../../utils/Moeda";
import ActionsCard from "./ActionsCard";
import DragActions from "./DragActions";
import ExpandCard from "./ExpandCard";
import MiniCard from "./MiniCard";

import { DailyListContext } from "../../../../contexts/DailyListContext";
import { MessageDialogContext } from "../../../../contexts/MessageDialogContext";
import PictureGetter from "../../../Client/PictureGetter";

const styles = (theme) => {
  return {
    cardClassOutterDiv: {
      overflow: "hidden",
    },
    clientCard: {
      position: "relative",
      transition: "all 200ms ease-in-out",
      borderLeft: "2px solid #4e76e3",
      padding: "5px",
    },

    miniCard: {
      height: "80px",
    },

    expandCard: {
      height: "calc(270px)",
    },

    actionsCard: {
      height: "calc(210px)",
      "&::before": {
        letterSpacing: "0.1em !important",
        paddingTop: "6px !important",
      },
    },
    inadimplenteCard: {
      borderColor: "red",
    },
    inadimplenteCardExpanded: {
      borderLeft: "15px solid red",
      "&::before": {
        transition: "all 200ms ease-in-out",
        content: "'INADIMPLENTE'",
        writingMode: "vertical-rl",
        textOrientation: "upright",
        letterSpacing: "0.35rem",
        position: "absolute",
        display: "block",
        textAlign: "center",
        top: "10px",
        bottom: "10px",
        marginLeft: "-21px",
        color: "#fff",
        fontSize: "0.7rem",
        fontWeight: "600",
      },
    },

    syncButton: {
      backgroundColor: "#fff",
      borderRadius: 3,
      border: "1px solid #DCDCDC",
      display: "flex",
      alignItems: "center",
      fontSize: ".7rem",
      marginTop: 10,
    },
    name: {
      fontSize: "0.85rem",
      fontWeight: 600,
      overflow: "hidden",
      lineHeight: "1.30",
      textOverflow: "ellipsis",
      maxHeight: "20px",
    },
    address: {
      fontSize: "0.8rem",
      color: "#a1a1a1",
      maxHeight: "35px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    avatar: {
      margin: "auto",
      width: "45px",
      height: "45px",
    },
    money: {
      color: "#d44444",
      fontSize: ".8rem",
      fontWeight: 600,
    },
    moneySettled: {
      color: theme.palette.primary.main,
      fontSize: ".8rem",
      fontWeight: 600,
    },
    currentBalanceText: {
      fontSize: "0.7rem",
      color: "#777777",
      marginTop: "-5px",
    },

    showMore: {
      color: "#A9A9A9",
      flexWrap: "nowrap",
      position: "absolute",
      left: 0,
      bottom: 0,
    },

    // Fade transition
    "fade-enter": {
      opacity: 0,
    },
    "fade-enter-active": {
      opacity: 1,
      transition: "all 150ms",
    },
    "fade-enter-done": {
      opacity: 1,
    },
    "fade-exit": {
      opacity: 1,
    },
    "fade-exit-active": {
      opacity: 0,
      transition: "all 150ms",
    },
    "fade-exit-done": {
      opacity: 0,
    },
  };
};

/**
 * Componente responsável por passar para os cards os estados que em que ele deve estar junto com os dados.
 */
class ClientCard extends React.Component {
  /**
   * @param {function} props.onChangeView Função que altera qual card ficará aberto
   * @param {string} props.cardState Prop que tem como valor o card que deverá ser aberto
   * @param {object} props.Client Prop onde vem os dados dos clientes
   * @param {object} props.classes Objeto com todas as classes do CSS
   */
  constructor(props) {
    super(props);
    this.state = {
      transitionOn: true,
      isRender: false,
    };
    this.CSSTransitions = {
      enter: this.props.classes["fade-enter"],
      enterActive: this.props.classes["fade-enter-active"],
      enterDone: this.props.classes["fade-enter-done"],
      exit: this.props.classes["fade-exit"],
      exitActive: this.props.classes["fade-exit-active"],
      exitDone: this.props.classes["fade-exit-done"],
    };
    this.handleClickVeaco = this.handleClickVeaco.bind(this);
    this.showMiniCard = this.showMiniCard.bind(this);
    this.showActionsCard = this.showActionsCard.bind(this);
    this.closeActionsCard = this.closeActionsCard.bind(this);
    this.showExpandCard = this.showExpandCard.bind(this);
    this.closeExpandCard = this.closeExpandCard.bind(this);
    this.handleGoToClient = this.handleGoToClient.bind(this);
  }

  buildMessageBody(text) {
    return (
      <Grid container>
        <Grid item xs={12}>
          {text}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => {
                  localStorage.setItem("blockAlert", !!event.target.checked);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label={
              <Typography variant="caption">Não perguntar novamente</Typography>
            }
          />
        </Grid>
      </Grid>
    );
  }

  handleClickVeaco() {
    this.context.handleToggleVeaco(this.props.Client.hash);
  }

  /**Função altera o estado do card para o minicard
   * @function
   */
  showMiniCard() {
    this.props.onChangeView("minicard");
  }

  /**Função altera o estado do card para o actionsCard
   * @function
   */
  showActionsCard() {
    this.props.onChangeView("actionsCard");
  }

  /**Função altera o estado do card para o actionsCard
   * @function
   */
  closeActionsCard() {
    this.props.onChangeView("minicard");
  }

  /**Função altera o estado do card para o expandcard
   * @function
   */
  showExpandCard() {
    requestAnimationFrame(() => this.props.onChangeView("expandcard"));
  }

  /**Função que fecha o ExpandCard
   * @function
   */
  closeExpandCard() {
    requestAnimationFrame(() => this.props.onChangeView("minicard"));
  }

  /**Função que retorna os cards com suas respectiras props, de acordo com a prop 'cardState'
   * @function
   */
  handleCardView() {
    if (this.props.cardState == "minicard") {
      return (
        <MiniCard
          howExpandCard={this.showExpandCard}
          showActionsCard={this.showActionsCard}
          Client={this.props.Client}
        />
      );
    } else if (this.props.cardState == "expandcard") {
      return (
        <ExpandCard
          closeExpandCard={this.closeExpandCard}
          showActionsCard={this.showActionsCard}
          Client={this.props.Client}
          onSync={this.props.onSync}
          aboutScheduylingText={this.props.aboutScheduylingText}
        />
      );
    } else if (this.props.cardState == "actionsCard") {
      return (
        <ActionsCard
          closeActionsCard={this.closeActionsCard}
          Client={this.props.Client}
          handleClickVeaco={this.handleClickVeaco}
          onClickRepass={this.props.onClickRepass}
          onClickPular={this.props.onClickPular}
          buttonsConfig={this.props.buttonConfig}
          repasseBtn={this.props.repasseBtn}
          pularBtn={this.props.pularBtn}
          veacoBtn={this.props.veacoBtn}
        />
      );
    } else {
      return "ELSE";
    }
  }

  handleGoToClient() {
    history.push(`client/view/data/${this.props.Client.hash}`, {
      ...history.location.state,
      prevUrl: history.location.pathname,
    });
  }

  showAlertDialog(dialog, text) {
    const blockAlert = JSON.parse(localStorage.getItem("blockAlert"));
    if (blockAlert) return true;
    const messageBody = this.buildMessageBody(text);
    const resp = dialog.addAsyncDialog({
      message: messageBody,
      title: "Deseja continuar?",
      type: "warning",
      okText: "Sim",
      cancelText: "Não",
    });

    return resp;
  }

  getDragActionsProps(dialogContext) {
    const canRepass = this.props.repasseBtn;
    const canJump = this.props.pularBtn;

    const dragOptions = {
      blockSwipeRight: true,
      blockSwipeLeft: true,
      disabled: !(this.props.cardState === "minicard" && !this.state.clickedNo),
    };

    if (!canJump && !canRepass) return { disabled: true };

    if (canRepass) {
      dragOptions.onSwipeRight = async (timeout_interval = 0) => {
        const resp = await this.showAlertDialog(
          dialogContext,
          "Deseja mesmo repassar o cliente?"
        );

        if (resp) {
          await this.props.onClickRepass(
            this.props.Client.hash,
            timeout_interval
          );
        }

        //SEMPRE DEVE RETORNAR TRUE
        return true;
      };
      dragOptions.blockSwipeRight = false;
    }

    if (canJump) {
      dragOptions.onSwipeLeft = async (timeout_interval = 0) => {
        const resp = await this.showAlertDialog(
          dialogContext,
          "Deseja mesmo pular o cliente?"
        );

        if (resp) {
          await this.props.onClickPular(
            this.props.Client.hash,
            timeout_interval
          );
        }

        //SEMPRE DEVE RETORNAR TRUE
        return true;
      };
      dragOptions.blockSwipeLeft = false;
    }

    return dragOptions;
  }

  render() {
    let { classes } = this.props;
    let cardClass = clsx({
      [classes.clientCard]: true,
      [classes.inadimplenteCard]: this.props.Client.veaco,
      [classes.inadimplenteCardExpanded]:
        (this.props.cardState == "actionsCard" ||
          this.props.cardState == "expandcard") &&
        this.props.Client.veaco,
      [classes.expandCard]: this.props.cardState == "expandcard",
      [classes.actionsCard]: this.props.cardState == "actionsCard",
      [classes.miniCard]: this.props.cardState == "minicard",
    });

    return (
      <MessageDialogContext.Consumer>
        {(dialogContext) => (
          <DragActions {...this.getDragActionsProps(dialogContext)}>
            <Paper className={classes.cardClassOutterDiv} elevation={2}>
              <Grid className={cardClass}>
                <Grid container>
                  <Grid item xs={2}>
                    <PictureGetter id={this.props.Client.id}>
                      {(picture) => {
                        return (
                          <Avatar
                            onClick={this.handleGoToClient}
                            className={classes.avatar}
                            src={picture}
                          ></Avatar>
                        );
                      }}
                    </PictureGetter>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      className={classes.name}
                      style={{
                        maxHeight:
                          this.props.cardState != "expandcard"
                            ? "1.5em"
                            : "none",
                        whiteSpace:
                          this.props.cardState != "expandcard"
                            ? "nowrap"
                            : "break-spaces",
                      }}
                      onClick={this.handleGoToClient}
                    >
                      {this.props.Client.nome}
                    </Typography>
                    {this.props.cardState != "expandcard" && (
                      <>
                        <Typography className={classes.address}>
                          {this.props.Client.referencePoint}
                        </Typography>
                        <Typography className={classes.address}>
                          {this.props.Client.logradouro}
                          {this.props.Client.numero
                            ? `, ${this.props.Client.numero}`
                            : ""}
                        </Typography>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Grid container direction="column" alignItems="flex-end">
                      <Grid item container justifyContent="flex-end" xs={12}>
                        {(this.props.cardState == "actionsCard" && (
                          <CloseIcon
                            fontSize="small"
                            onClick={this.showMiniCard}
                          />
                        )) || (
                          <MoreHorizIcon
                            onClick={this.showActionsCard}
                            style={{ height: "0.8em" }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          align="right"
                          className={clsx({
                            [classes.money]: Moeda.create(
                              this.props.Client.saldoAtual
                            ).isLessThan(0),
                            [classes.moneySettled]: Moeda.create(
                              this.props.Client.saldoAtual
                            ).isGreaterOrEqualThan(0),
                          })}
                        >
                          {Moeda.create(this.props.Client.saldoAtual).format()}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          align="right"
                          className={classes.currentBalanceText}
                        >
                          Saldo Atual
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <SwitchTransition>
                  <CSSTransition
                    key={this.props.cardState}
                    timeout={{
                      enter: 150,
                      exit: 150,
                    }}
                    classNames={this.CSSTransitions}
                  >
                    {this.handleCardView()}
                  </CSSTransition>
                </SwitchTransition>

                {this.props.cardState != "actionsCard" && (
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    className={classes.showMore}
                    onClick={
                      this.props.cardState == "minicard"
                        ? this.showExpandCard
                        : this.showMiniCard
                    }
                  >
                    <Typography variant="caption">
                      {this.props.cardState == "minicard"
                        ? "Ver Mais"
                        : "Ver Menos"}
                    </Typography>

                    {this.props.cardState == "minicard" ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ExpandLessIcon />
                    )}
                  </Grid>
                )}
              </Grid>
            </Paper>
          </DragActions>
        )}
      </MessageDialogContext.Consumer>
    );
  }
}
ClientCard.contextType = DailyListContext;
export default withStyles(styles)(ClientCard);
