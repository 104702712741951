import { Divider, Grid, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import moment from "moment";
import React from "react";
import GreenButton from "../../components/Buttons/GreenButton";
import RouteDialog from "../../components/Dialogs/RouteDialog";
import Header from "../../components/HeaderForAdd/Header";
import Report from "../../components/Report/ReceivementFinalReport";
import { ClientContext } from "../../contexts/ClientContext";
import { PrinterContext } from "../../contexts/PrinterContext";
import History from "../../router/History";
import { generateReceivementInvoice } from "../../service/movements";

const styles = (theme) => ({
  report: {
    paddingTop: "1vh",
  },
  buttons: {
    paddingTop: "5vh",
    paddingBottom: "2vh",
    "& div": {
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& > div": {
      paddingBottom: "16px",
    },
  },
  textArea: {
    width: "100%",
    border: "none",
    padding: "0 15px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
    },
    "& .MuiButton-root": {
      width: "100%",
    },
  },
});

/**
 * Tela do relatório de recebimento
 */
class ReceivementReport extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   */
  constructor() {
    super();
    this.state = {
      clientName: "Carregando...",
      invoice: {
        viaClient: "",
        viaEmployee: "",
      },
    };
  }

  /**Função redireciona para página anterior.
   * @function
   */
  goBackPage = () => {
    History.goBack();
  };

  /**Função redireciona abrindo o modal de rota.
   * @function
   */
  goBackStart = () => {
    History.push("/daily");
  };

  componentDidMount() {
    this.context
      .getClientInfoToRenderCard(this.props.match.params.hash, "hash")
      .then((client) => {
        this.setState({ clientName: client.nome });
      })
      .catch(console.error);
    generateReceivementInvoice(this.props.match.params.receivements)
      .then((vias) => {
        this.setState({ invoice: vias });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onClickToPrint = () => {
    this.props.printerContext.callPrinterComponent({
      title: "Comprovante de Recebimento",
      content: this.state.invoice.viaClient,
      headerMainText: moment().format("DD/MM/YYYY : HH:mm"),
      headerSecondaryText: "Comprovante de recebimento",
      viaEmployee: this.state.invoice.viaEmployee,
      viaClient: this.state.invoice.viaClient,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Header
          icon={<CloseIcon fontSize="large" />}
          name={this.state.clientName}
          date={moment(new Date()).format("DD/MM/YYYY")}
          func={this.goBackPage}
        >
          Recebimento
        </Header>

        <Divider />
        <Grid container justifyContent="center">
          <Grid item xs={10} className={classes.report}>
            <Report title="Comprovante de movimentação" height="71vh">
              <textarea
                className={classes.textArea}
                disabled
                value={this.state.invoice.viaClient}
              />
            </Report>
          </Grid>

          <Grid item xs={11} className={classes.buttons}>
            <Grid item>
              <GreenButton
                onClickButton={this.onClickToPrint}
                startIcon={<PrintIcon />}
                backgroundColor="#CCC"
              >
                IMPRIMIR COMPROVANTE
              </GreenButton>
            </Grid>
            <RouteDialog text="VOLTAR AO INÍCIO" />
          </Grid>
        </Grid>
      </>
    );
  }
}

ReceivementReport.contextType = ClientContext;
const ReceivementReportWithStyles = withStyles(styles)(ReceivementReport);

export default class ContextAgregator extends React.Component {
  render() {
    return (
      <PrinterContext.Consumer>
        {(printerContext) => (
          <ReceivementReportWithStyles
            {...this.props}
            printerContext={printerContext}
          />
        )}
      </PrinterContext.Consumer>
    );
  }
}
