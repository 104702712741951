export async function verifyClientShouldUpload(dbVersion) {
  class InxedDBHelper {
    static DATABASE_NAME = "employee_db";

    openDatabase(dbVersion) {
      return new Promise((resolve, reject) => {
        const openDbRequest = indexedDB.open(
          InxedDBHelper.DATABASE_NAME,
          dbVersion
        );
        openDbRequest.onsuccess = (event) => {
          this.db = event.target.result;
          resolve();
        };
        openDbRequest.onerror = reject;
      });
    }

    getAll(model, key, keyValue) {
      return new Promise((resolve, reject) => {
        const store = this.db.transaction(model, "readonly").objectStore(model);
        const resultSet = [];
        const indexedStore = key ? store.index(key) : store;
        const getterByValue = keyValue ? IDBKeyRange.only(keyValue) : undefined;
        const cursorRequest = indexedStore.openCursor(getterByValue);

        cursorRequest.onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            const data = cursor.value;
            resultSet.push(data);
            cursor.continue();
          } else {
            resolve(resultSet);
          }
        };
        cursorRequest.onerror = reject;
      });
    }

    put(model, data) {
      return new Promise((resolve, reject) => {
        const store = this.db
          .transaction(model, "readwrite")
          .objectStore(model);
        const updateRequest = store.put(data);
        updateRequest.onsuccess = resolve;
        updateRequest.onerror = reject;
      });
    }
  }
  const db = new InxedDBHelper();
  await db.openDatabase(dbVersion);

  const allSchedules = await db.getAll("ClienteAgendamento");
  const schedulesShouldSync = allSchedules.filter((el) => el.shouldSync);

  const clientsShouldSync = [
    ...new Set(schedulesShouldSync.map((el) => el.ClienteId)),
  ];

  const clientModelName = "Cliente";
  const clientMainKey = "id";

  const updateProms = clientsShouldSync.map(async (clientId) => {
    const [client] = await db.getAll(clientModelName, clientMainKey, clientId);
    if (!client || client.shouldSync) return;

    client.shouldSync = true;
    await db.put(clientModelName, client);
  });

  await Promise.all(updateProms);
  return clientsShouldSync;
}
