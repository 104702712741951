import React from "react";
import Header from "../HeaderForAdd/Header";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputSelect from "../Inputs/SelectInput";
import moment from "moment";
import {
  Divider,
  TextField,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import GreenButton from "../Buttons/GreenButton";
import history from "../../router/History";
import Slide from "@material-ui/core/Slide";
import { OpenRouteContext } from "../../contexts/OpenRouteContext";
import BusinessError from "../../errorDefinition/BusinessError";

const styles = (theme) => ({
  main_details: {
    paddingTop: "20px",
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
    },
    "& > div": {
      paddingTop: "5px",
    },
    "& > div > svg": {
      width: "45px",
      height: "45px",
    },
  },
  receivement_details: {
    paddingTop: "20px",
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
      paddingLeft: "5px",
      wordWrap: "break-word",
    },
    "& div": {
      paddingTop: "5px",
    },
  },
  vehicle: {
    paddingRight: "5px",
  },
  sell_details: {
    paddingTop: "20px",
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
      paddingLeft: "5px",
    },
    "& div": {
      paddingTop: "5px",
    },
  },
  button: {
    paddingTop: "50px",
    paddingBottom: "30px",
    "& .MuiTypography-body1": {
      fontFamily: "Roboto, sans-serif",
    },
  },
});

/**
 * Tela de detalhes do fechamento de rota
 */
class Details extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {function} props.provideNextScene Função responsável por prover para a próxima cena.
   * @param {string} props.slideDirection Direção em que o slide irá se movimentar.
   */
  constructor() {
    super();
    this.state = {
      routeData: "",
      km: { value: "", error: false },
    };
    this.getRouteData = this.getRouteData.bind(this);
    this.checkFields = this.checkFields.bind(this);
    this.saveData = this.saveData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.previousScene = this.previousScene.bind(this);
  }
  async componentDidMount() {
    await this.getRouteData();
  }

  /**Função responsável por pegar os dados da rota.
   * @function
   */
  async getRouteData() {
    try {
      let data = await this.context.getCloseRouteData();
      this.props.onDataChange({ hashRotaAbertura: data.hashRotaAbertura });
      this.setState({ routeData: data });
    } catch (error) {
      history.push("/");
    }
  }

  /**Função responsável por fazer a validação e setar os erros.
   * @function
   */
  checkFields() {
    let check = true;
    let state = { ...this.state };
    if (this.state.km.value == "") {
      state.km.error = true;
      check = false;
    } else {
      state.km.error = false;
    }
    this.setState({ state });
    return check;
  }

  /**Função responsável por salvar os dados no banco e pular para a próxima cena.
   * @function
   */
  async saveData() {
    if (!this.checkFields()) return;
    let data = { ...this.state.routeData };
    data.km = this.state.km.value;
    try {
      await this.context.onCloseRoute(data);
      this.props.provideNextScene({ slideDirection: "left" });
    } catch (error) {
      throw new BusinessError({
        message: error.message + "\n" + error.stack,
        type: "warning",
        title: "Problemas com a data do agendamento",
      });
    }
  }

  /**Função responsável por salvar os dados no banco e pular para a próxima cena.
   * @function
   */
  handleChange(modelName, value) {
    let state = { ...this.state };
    state[modelName].value = value;
    this.setState({ state });
  }

  previousScene() {
    try {
      this.props.providePreviousScene({ slideDirection: "right" });
    } catch (e) {
      console.log(e);
      history.push("/");
    }
  }

  getRouteTimeString() {
    if (this.state.routeData.palma) return "Abriu para bater palma";
    return (
      this.state.routeData &&
      `${this.state.routeData.openDate} - ${this.state.routeData.closeDate}`
    );
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Slide
          direction={this.props.slideDirection}
          in={true}
          mountOnEnter
          unmountOnExit
        >
          <Grid container>
            <Header
              // link='/'
              func={this.previousScene}
              icon={<ArrowBackIcon fontSize="large" />}
              name={moment(new Date()).format("DD/MM/YYYY H:mm")}
            >
              Fechar Rota
            </Header>

            <Divider style={{ width: "100%" }} />

            <Grid item xs={12} style={{ padding: "0 10px" }}>
              <Grid container className={classes.main_details}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Typography display="inline">
                    DADOS SOBRE A ABERTURA
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.vehicle}>
                  <InputSelect
                    variant="filled"
                    disabled
                    label="Veículo"
                    value={this.state.routeData && this.state.routeData.vehicle}
                    menuItem={this.context.vehicle}
                    shrink
                    disableUnderline
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    variant="outlined"
                    label="Km"
                    required
                    type="number"
                    onChange={(e) =>
                      this.handleChange("km", e.target.value.replace(/e/g, ""))
                    }
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    value={this.state.km.value}
                    helperText={
                      this.state.km.error ? "Informe a quilometragem!" : null
                    }
                    error={this.state.km.error}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="filled"
                    label="Data da lista diária"
                    fullWidth
                    value={this.getRouteTimeString()}
                    InputLabelProps={{ shrink: true }}
                    disabled
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container className={classes.main_details}>
                <Grid container alignItems="center" wrap="nowrap">
                  <i
                    style={{ color: "#21a94f" }}
                    className="fas fa-money-bill-wave fa-2x"
                  />
                  &nbsp;&nbsp;
                  <Typography display="inline">
                    DETALHES DE RECEBIMENTOS
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="filled"
                    fullWidth
                    label="Qnt de recebimentos"
                    value={
                      this.state.routeData &&
                      this.state.routeData.receivementCount
                    }
                    InputLabelProps={{ shrink: true }}
                    disabled
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="filled"
                    fullWidth
                    label="Valor de recebimentos"
                    InputLabelProps={{ shrink: true }}
                    value={
                      this.state.routeData &&
                      this.state.routeData.receivementValue
                    }
                    disabled
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container className={classes.main_details}>
                <Grid container alignItems="center">
                  <i
                    style={{ color: "#f3c631" }}
                    className="fas fa-shopping-cart fa-2x"
                  />
                  &nbsp;&nbsp;
                  <Typography display="inline">DETALHES DE VENDAS</Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="filled"
                    fullWidth
                    label="Qnt de vendas"
                    InputLabelProps={{ shrink: true }}
                    value={
                      (this.state.routeData &&
                        this.state.routeData.saleCount) ||
                      "0"
                    }
                    disabled
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="filled"
                    fullWidth
                    label="Valor de vendas"
                    value={
                      (this.state.routeData &&
                        this.state.routeData.saleValue) ||
                      "0"
                    }
                    InputLabelProps={{ shrink: true }}
                    disabled
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs className={classes.button}>
                <GreenButton onClickButton={this.saveData}>
                  <Typography>PRÓXIMO</Typography>
                </GreenButton>
              </Grid>
            </Grid>
          </Grid>
        </Slide>
      </>
    );
  }
}
Details.contextType = OpenRouteContext;
export default withStyles(styles)(Details);
