import React from "react";
import CardExpenses from "../CardComponents/CardsExpenses/Card";
import ExpansionPanel from "../ExpansionPanel/ExpansionPanel";
import { withStyles, TextField, FormControl } from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AmountMask from "../MaskedInputs/AmountMask";

import { CSSTransition, TransitionGroup } from "react-transition-group";

const styles = (theme) => ({
  expansionPanel: {},

  "fade-enter": {
    maxHeight: 0,
    opacity: 0,
  },
  "fade-enter-active": {
    opacity: 1,
    maxHeight: "100vh",
    transition: "all 500ms",
  },
  "fade-exit": {
    opacity: 1,
    maxHeight: "100vh",
  },
  "fade-exit-active": {
    opacity: 0,
    maxHeight: 0,
    transition: "all 500ms",
  },
});

class OtherExpensesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFormat: {
        descricao: { value: "", error: false },
        numeroDocumento: { value: "", error: false },
        valor: { value: "", error: false },
        fornecedor: { value: "", error: false },
      },
      expensesArray: [],
      invalid: false,
      requiredFieldsArray: ["valor"],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onAddExpense = this.onAddExpense.bind(this);
    this.onDeleteExpense = this.onDeleteExpense.bind(this);
    this.onSaveExpense = this.onSaveExpense.bind(this);
    this.validateFields = this.validateFields.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.expenseContext.isLoading !== this.props.expenseContext.isLoading
    ) {
      let savedExpenses = this.props.expenseContext[this.props.modelName].value;
      let newSavedExpenses = savedExpenses.map((expense) => {
        let exp = {};
        for (let key in expense) {
          exp[key] = { value: expense[key], error: false };
        }
        exp.isSaved = true;
        return exp;
      });
      if (!newSavedExpenses.length) {
        let invalid = false;
        this.setState({ invalid });
      }
      this.setState({ expensesArray: newSavedExpenses });
    }
  }

  handleInputChange(event, input, index) {
    let tempExpenseArray = [...this.state.expensesArray];
    if (input === "valor") tempExpenseArray[index][input].value = event;
    else tempExpenseArray[index][input].value = event.target.value;

    tempExpenseArray[index].isSaved = false;
    this.setState({ expensesArray: tempExpenseArray });
  }

  onHandleChange(isExpanded) {
    this.props.handleChange(isExpanded);
  }

  async onAddExpense() {
    if (await this.props.onAddExpense()) {
      let temp_expensesArray = {
        descricao: { value: "", error: false },
        numeroDocumento: { value: "", error: false },
        valor: { value: "", error: false },
        fornecedor: { value: "", error: false },
        isSaved: false,
      };
      this.setState({
        expensesArray: [...this.state.expensesArray, temp_expensesArray],
      });
      this.setState({ invalid: true });
    }
  }

  async onDeleteExpense(index) {
    const initialLength = this.state.expensesArray.length;
    let expense = this.state.expensesArray.map((expense) => {
      let exp = {};
      for (let key in expense) {
        exp[key] = expense[key].value;
      }
      return exp;
    });
    if (expense[index].DATABASE_ID) {
      await this.props.onDeleteExpense(this.props.modelName, expense[index]);

      if (initialLength === 1) {
        let temp_expensesArray = [...this.state.expensesArray];
        temp_expensesArray.splice(index, 1);
        this.setState({ expensesArray: temp_expensesArray });
      }
      this.setState({ invalid: false });
    }
  }

  async onSaveExpense(index) {
    if (this.validateFields(index)) {
      let exp = {};
      for (let key in this.state.expensesArray[index]) {
        exp[key] = this.state.expensesArray[index][key].value;
      }
      if (this.state.expensesArray[index].DATABASE_ID) {
        exp.DATABASE_ID = this.state.expensesArray[index].DATABASE_ID.value;
      }
      let success = await this.props.onSaveExpense(this.props.modelName, exp);
      if (success) {
        // Change isSaved property
        let temp_expensesArray = [...this.state.expensesArray];
        temp_expensesArray[index].isSaved = true;
        this.setState({ invalid: false, expensesArray: temp_expensesArray });
      }
    } else return;
  }

  validateFields(index) {
    let requiredFieldsArray = this.state.requiredFieldsArray;
    let formInvalid = false;

    for (let i = 0; i < requiredFieldsArray.length; i++) {
      if (
        this.state.expensesArray[index][requiredFieldsArray[i]].value === ""
      ) {
        let expense = [...this.state.expensesArray];
        expense[index][requiredFieldsArray[i]].error = true;
        formInvalid = true;
        this.setState({ expensesArray: expense });
      } else {
        let expense = [...this.state.expensesArray];
        expense[index][requiredFieldsArray[i]].error = false;
        this.setState({ expensesArray: expense });
      }
    }

    if (!formInvalid) {
      return true;
    } else return false;
  }

  render() {
    let { classes } = this.props;

    return (
      <ExpansionPanel
        handleChange={this.onHandleChange}
        expanded={this.props.expanded}
        title={this.props.title || "Demais despesas"}
        iconTitle={<AttachMoneyIcon />}
        className={classes.expansionPanel}
        expensesCount={this.state.expensesArray.length}
        isValid={!this.state.invalid}
        disableAddBtn={this.state.invalid}
        onAddExpense={this.onAddExpense}
        custom
      >
        <TransitionGroup className={classes.prodcutsList} component={null}>
          {this.state.expensesArray &&
            this.state.expensesArray.map((card, index) => (
              <CSSTransition
                timeout={500}
                classNames={{
                  enter: classes["fade-enter"],
                  enterActive: classes["fade-enter-active"],
                  exit: classes["fade-exit"],
                  exitActive: classes["fade-exit-active"],
                }}
                key={index}
              >
                <CardExpenses
                  key={index}
                  onDeleteExpense={() => this.onDeleteExpense(index)}
                  onSaveExpense={() => this.onSaveExpense(index)}
                  isSaved={card.isSaved}
                >
                  <FormControl fullWidth style={{ paddingBottom: 15 }}>
                    <TextField
                      label="Descrição"
                      fullWidth
                      value={card.descricao.value}
                      error={card.descricao.error}
                      helperText={
                        card.descricao.error ? "Informe a descrição!" : null
                      }
                      onChange={(event) =>
                        this.handleInputChange(event, "descricao", index)
                      }
                    ></TextField>
                  </FormControl>

                  <FormControl fullWidth style={{ paddingBottom: 15 }}>
                    <TextField
                      label="Número do documento"
                      type="number"
                      fullWidth
                      value={card.numeroDocumento.value}
                      error={card.numeroDocumento.error}
                      helperText={
                        card.numeroDocumento.error
                          ? "Informe o numero do documento!"
                          : null
                      }
                      onChange={(event) =>
                        this.handleInputChange(event, "numeroDocumento", index)
                      }
                    ></TextField>
                  </FormControl>

                  <FormControl fullWidth style={{ paddingBottom: 15 }}>
                    <TextField
                      label="Fornecedor"
                      fullWidth
                      value={card.fornecedor.value}
                      onChange={(event) =>
                        this.handleInputChange(event, "fornecedor", index)
                      }
                      error={card.fornecedor.error}
                      helperText={
                        card.fornecedor.error
                          ? "Informe o nome do fornecedor!"
                          : null
                      }
                    ></TextField>
                  </FormControl>

                  <FormControl fullWidth style={{ paddingBottom: 15 }}>
                    <TextField
                      label="Valor"
                      required
                      fullWidth
                      value={card.valor.value}
                      error={card.valor.error}
                      helperText={card.valor.error ? "Informe o valor!" : null}
                      onChange={(e1, e2) =>
                        this.handleInputChange(e2, "valor", index)
                      }
                      InputProps={{
                        inputComponent: AmountMask,
                      }}
                    ></TextField>
                  </FormControl>
                </CardExpenses>
              </CSSTransition>
            ))}
        </TransitionGroup>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(OtherExpensesComponent);
