import React from "react";
import clsx from "clsx";
import { withStyles, Typography, Grid } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    backgroundColor: "#32CD32",
    color: "#fff",
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
      paddingTop: "5px",
      paddingBottom: "5px",
      fontFamily: "Roboto,sans-serif",
      transition: ".7s",
    },
    "& :active": {
      borderColor: "#228B22",
      "& .MuiTypography-body1": {
        fontSize: "1rem",
        paddingTop: "4px",
        paddingBottom: "4px",
      },
    },
  },

  disabledButton: {
    backgroundColor: "rgba(71, 71, 71, 0.4)",
    color: "rgba(100, 100, 100, 0.4)",
  },
});

class GreenButton extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    if (!this.props.disabled) {
      this.props.onClickButton();
    } else {
      return;
    }
  }

  render() {
    const { classes } = this.props;
    const conteinaerClass = clsx({
      [classes.root]: true,
      [classes.disabledButton]: this.props.disabled,
    });
    return (
      <>
        <Grid
          container
          className={conteinaerClass}
          alignItems="center"
          onClick={this.onClick}
          style={{ backgroundColor: this.props.backgroundColor }}
        >
          <Grid item xs={2}>
            <Grid container justify="center" alignItems="center">
              {this.props.startIcon}
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Typography className={this.props.textClassName} align="center">
              {this.props.children}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Grid container justify="center">
              {this.props.endIcon}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withStyles(styles)(GreenButton);
