import React from "react";
import { Grid, withStyles, IconButton } from "@material-ui/core";
import FlipCameraIosIcon from "@material-ui/icons/FlipCameraIos";
import CameraIcon from "@material-ui/icons/Camera";
import PropTypes from "prop-types";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
const applyStyles = withStyles((theme) => ({
  root: {
    background: "#929292bf",
    width: "100%",
  },
  pictureButtonContainer: {
    flexGrow: 1,
  },
}));

class PictureActionBar extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        className={classes.root}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={3}>
          <Grid container justify="center" alignItems="center">
            <IconButton
              disabled={this.props.disabled}
              onClick={this.props.onClickChangeMirror}
            >
              <SwapHorizIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.pictureButtonContainer}>
          <Grid container justify="center" alignItems="center">
            <IconButton
              disabled={this.props.disabled}
              onClick={this.props.onClickTakePhoto}
              size="small"
            >
              <CameraIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container justify="center" alignItems="center">
            <IconButton
              disabled={this.props.disabled}
              onClick={this.props.onClickFlip}
            >
              <FlipCameraIosIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

PictureActionBar.propTypes = {
  onClickTakePhoto: PropTypes.func.isRequired,
  onClickFlip: PropTypes.func.isRequired,
};

export default applyStyles(PictureActionBar);
