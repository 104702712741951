import React from "react";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const getStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function BackdropLoader({ open, secondaryText, ...othersProps }) {
  const classes = getStyles();

  return (
    <Backdrop className={classes.backdrop} open={open} {...othersProps}>
      <CircularProgress color="inherit" />
      <Typography>
        {secondaryText || "Estamos Salvando suas alterações"}
      </Typography>
    </Backdrop>
  );
}

export default BackdropLoader;
