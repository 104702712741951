import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Moeda from "../../utils/Moeda";
import moment from "moment";
import "moment/locale/pt-br";
import GreenButton from "../Buttons/GreenButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
const styles = (theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#041641",
    position: "fixed",
    bottom: 0,
    zIndex: 3,
  },
  root2: {
    color: "#fff",
    backgroundColor: "#08183c",
    position: "relative",
    zIndex: 3,
  },
  row: {
    padding: "10px",
    "& :child": {
      fontFamily: "Roboto, sans-serif",
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  row1Format: {
    padding: "10px 2px 10px 2px",
  },
  row2Format: {
    padding: "10px 15px",
  },
  balance: {
    fontWeight: "bold",
  },
  filterDate: {
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#fff",
  },
  moves: {
    fontSize: "3.6vw",
    whiteSpace: "nowrap",
  },
  divider: {
    height: "100%",
    width: "1px",
    backgroundColor: "#fff",
  },
});

/**
 * Componente onde é exibido dados de listagens de clientes, como por exemplo a página de agendamentos.
 */
class FooterBalance extends React.Component {
  /**
   * @param {object} props.moves Dados de movimentações da lista de cliente
   * @param {string} props.inputValue Valor de entrada num recebimento
   * @param {funtion} props.onClickButton Função acionado ao clicar no botão
   * @param {string} props.type Define o tipo de estrutura a ser renderizado pelo componente
   * @param {boolean} props.totalMovements  Soma total de todas as movimentações.
   * @param {boolean} props.report  Boleano que define a estrutura do card exibido
   * @param {number} props.clientCount Número de clientes da lista
   * @param {object} props.classes Objeto com todas as classes do CSS
   */
  constructor(props) {
    super(props);
    this.state = {
      schedules: 3,
      balance: 400,
    };
    this.shownDate = this.shownDate.bind(this);
  }

  shownDate() {
    let { classes } = this.props;
    if (this.props.startDate && this.props.endDate) {
      return (
        <Typography className={classes.filterDate}>
          {moment(this.props.startDate).format("MMM DD")} -{" "}
          {moment(this.props.endDate).format("MMM DD")}
          <span style={{ float: "right", color: "#bbb" }}>
            {moment(this.props.startDate).format("YYYY") ==
            moment(this.props.endDate).format("YYYY")
              ? moment(this.props.startDate).format("YYYY")
              : `${moment(this.props.startDate).format("YYYY")} - ${moment(
                  this.props.endDate
                ).format("YYYY")}`}
          </span>
        </Typography>
      );
    } else {
      return (
        <Typography className={classes.filterDate}>
          Todos os agendamentos
        </Typography>
      );
    }
  }

  render() {
    moment.locale("pt-br");
    const { classes } = this.props;

    return (
      <>
        <Grid container className={classes.root}>
          {this.props.type == "schedulings" ? (
            <>
              <Grid container justify="space-between" className={classes.row}>
                <Typography>TOTAL DE AGENDAMENTOS</Typography>
                <Typography>{this.props.totalScheduling}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ backgroundColor: "#D3D3D3" }} />
              </Grid>
              <Grid container justify="space-between" className={classes.row}>
                <Typography variant="h6" className={classes.balance}>
                  Saldo Atual
                </Typography>
                <Typography variant="h6" className={classes.balance}>
                  {Moeda.create(this.props.currentBalance).format()}
                </Typography>
              </Grid>
            </>
          ) : null}

          {this.props.type == "schedulingReport" ? (
            <>
              <Grid
                container
                justify="flex-start"
                className={classes.row2Format}
              >
                {/* <Typography className={classes.balance}>Período</Typography> */}

                <Grid item xs={12}>
                  {this.shownDate()}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ backgroundColor: "#D3D3D3" }} />
              </Grid>
              <Grid container className={classes.row1Format}>
                <Grid item xs={4}>
                  <Typography align="center" className={classes.moves}>
                    RECEBIMENTO: {this.props.moves.receive}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Grid container justify="center" style={{ height: "100%" }}>
                    <Divider className={classes.divider} />
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Typography align="center" className={classes.moves}>
                    VENDAS: {this.props.moves.sell}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Grid container style={{ height: "100%" }} justify="center">
                    <Divider className={classes.divider} />
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Typography align="center" className={classes.moves}>
                    TROCAS: {this.props.moves.exchanges}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : null}

          {this.props.type == "movements" ? (
            <>
              <Grid container justify="space-between" className={classes.row}>
                <Typography>SALDO ANTERIOR</Typography>
                <Typography>
                  {Moeda.create(this.props.lastBalance).format()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ backgroundColor: "#D3D3D3" }} />
              </Grid>
              <Grid container justify="space-between" className={classes.row}>
                <Typography className={classes.balance} variant="h6">
                  Saldo Atual
                </Typography>
                <Typography className={classes.balance} variant="h6">
                  {Moeda.create(this.props.currentBalance).format()}
                </Typography>
              </Grid>
            </>
          ) : null}

          {this.props.type == "receivement" ? (
            <Grid container className={classes.root2}>
              <Grid container justify="space-between" className={classes.row}>
                <Typography>{this.props.footerText}</Typography>
                <Typography>
                  {Moeda.create(this.props.inputValue).format()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ backgroundColor: "#D3D3D3" }} />
              </Grid>
              <Grid
                container
                justify="space-between"
                className={classes.row}
                alignItems="center"
                spacing={1}
              >
                <Grid item xs>
                  <GreenButton
                    onClickButton={this.props.onClickAddMore}
                    endIcon={<ArrowForwardIcon />}
                  >
                    +Recebimento
                  </GreenButton>
                </Grid>
                <Grid item xs>
                  <GreenButton
                    onClickButton={this.props.onClickButton}
                    disabled={this.props.isLoading}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Finalizar
                  </GreenButton>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {this.props.type === "sale" ? (
            <Grid container className={classes.root2}>
              <Grid
                container
                justify="space-between"
                className={classes.row}
                wrap="nowrap"
                alignItems="center"
              >
                <Grid item xs>
                  <Typography variant="h6">
                    Saldo final: {this.props.finalBalance}pts
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <GreenButton
                    onClickButton={this.props.onClickButton}
                    disabled={this.props.isLoading}
                    endIcon={<ArrowForwardIcon fontSize="small" />}
                  >
                    Continuar
                  </GreenButton>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(FooterBalance);
