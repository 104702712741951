import React, { createContext } from "react";
import { Veiculo } from "../repository/models";
import BusinessError from "../errorDefinition/BusinessError";
import AppStorageError from "../errorDefinition/AppStorageError";
import { AbstractContext } from "./AbstractContext";
import RotaService from "../service/route/index";
import SyncSend from "../service/sync/activeSync/syncSend";

import { MessageDialogContext } from "../contexts/MessageDialogContext";

const veiculo = new Veiculo();
const rotaService = new RotaService();
const syncSend = new SyncSend();

const VehicleContext = createContext({
  get vehicle() {
    return [];
  },
});

const success = (context, message) =>
  context.addAsyncDialog({
    message: message,
    title: "Sucesso!",
    type: "success",
    hasCloseButton: false,
  });

/**
 * Contexto utilizado na pagina de despesas.
 */
const OBSERVED_MODELS = [];
class VehicleContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.observable = this.observable.bind(this);
    this.updateSelf = this.updateSelf.bind(this);
    this.create = this.create.bind(this);
    this.delete = this.delete.bind(this);
    this.update = this.update.bind(this);
    this.getById = this.getById.bind(this);
    this.sendVehicle = this.sendVehicle.bind(this);
    this.state = {
      get vehicle() {
        return veiculo.filter((a) => a.ativo != false);
      },
      teamVehicleId: "",
      create: this.create,
      delete: this.delete,
      update: this.update,
      getById: this.getById,
      sendVehicle: this.sendVehicle,
      updateSelf: this.updateSelf,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.updateSelf();
  }

  observable() {
    this.updateSelf().catch(console.error);
  }

  /**Função responsável atualizar os dados armazenados no estado.
   * @function
   */
  async updateSelf() {
    const routeOpening = await rotaService.routeOpening();
    if (!routeOpening) {
      const team = await rotaService.team();
      if (team) {
        this.setState({
          teamVehicleId: team.VeiculoId,
        });
      }
      return;
    }
    this.setState({
      teamVehicleId: routeOpening && routeOpening.veiculo,
    });
  }

  /**Função responsável por criar cadastrar um novo veículo.
   * @function
   * @param {object} data Dado utilizado no castro do novo veículo.
   */
  async create(data) {
    try {
      let vehicleExist = (await this.state.vehicle).find(
        (a) => a.placa.toLowerCase() == data.placa.toLowerCase()
      );
      if (!vehicleExist) {
        data.ativo = true;
        data.shouldSync = true;
        let newVehicleId = await veiculo.create(data);
        await this.updateSelf();
        await success(this.context, "Veículo salvo com sucesso!");
        return newVehicleId;
      } else {
        throw new BusinessError({
          message: "Placa já cadastrada.",
          title: "Falhar ao criar novo veículo.",
          type: "warning",
          method: "create",
        });
      }
    } catch (error) {
      if (!error instanceof BusinessError) {
        new AppStorageError({
          message: error.message,
          title: "Falha ao criar novo veículo!",
          type: "error",
          method: "create",
        });
      }
    }
  }

  /**Função responsável por deletar um veículo existente.
   * @function
   * @param {object} data Dado do veículo já existente.
   */
  async delete(data) {
    try {
      let vehicleExist = (await this.state.vehicle).find(
        (a) => a.placa.toLowerCase() == data.placa.toLowerCase()
      );
      if (!vehicleExist) {
        data.ativo = false;
        data.shouldSync = true;

        await veiculo.uptate(data);
        await this.updateSelf();
      } else {
        throw new BusinessError({
          message: "Placa já cadastrada.",
          title: "Falhar ao criar novo veículo.",
          type: "warning",
          method: "create",
        });
      }
    } catch (error) {
      if (!error instanceof BusinessError) {
        new AppStorageError({
          message: error.message,
          title: "Falha ao deletar veículo!",
          type: "error",
          method: "delete",
        });
      }
    }
  }

  /**Função responsável por atualizar um veículo existente.
   * @function
   * @param {object} data Dado do veículo já existente.
   */
  async update(data) {
    try {
      await veiculo.put(data);
      await this.updateSelf();
      await success(this.context, "Veículo atualizado com sucesso!");
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao atualizar dados do veículo veículo!",
        type: "error",
        method: "delete",
      });
    }
  }

  async getById(id) {
    return (await this.state.vehicle).find((a) => a.id === id);
  }

  async sendVehicle(vehicle) {
    let newVehicle = await syncSend.sendVehicle();
    return newVehicle.apiCreatedVehicles[
      newVehicle.apiCreatedVehicles.length - 1
    ];
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <VehicleContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </VehicleContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { VehicleContext, VehicleContextProvider };
