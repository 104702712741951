import DataAccess from "../DataAccessStrategy";

export default class ClienteMovimentacaoCancelada extends DataAccess {
  constructor() {
    super("ClienteMovimentacaoCancelada", {
      id: Number,
      valor: String,
      saldo: String,
      formaPagamento: String,
      dataRealizacao: String,
      documentoFisico: String,
      hash: String,
      assinatura: String,
      historico: String,
      recibo: String,
      meioPagamento: String,
      houveTroca: Boolean,
      updatedDate: Date,

      ClienteId: Number,
      ClienteVendaId: Number,
      hashRotaAbertura: String,
      shouldSync: Boolean,
    });
  }
}
