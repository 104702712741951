import DataAccess from "../DataAccessStrategy";
import { Cliente } from "../models";
const cliente = new Cliente();
export default class ClienteRecebimento extends DataAccess {
  constructor() {
    super("ClienteRecebimento", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      documentoFisico: String,
      dataRealizacao: Date,
      valor: String,
      saldo: String,
      historico: String,
      recibo: String,
      hash: String,
      meioPagamento: String,
      cancelado: Boolean,
      ClienteId: Number,
      ClienteVendaId: Number,
      hashRotaAbertura: String,
      corretorSaldo: Boolean,
    });
    this.fetchReceivement = this.fetchReceivement.bind(this);
  }

  async fetchReceivement(hash, startDate, endDate) {
    let client = await cliente.findByHash(hash);
    let receive = await this.model
      .where("[ClienteId+dataRealizacao]")
      .between([client.id, startDate], [client.id, endDate], true, true)
      .and((a) => !a.cancelado)
      .sortBy("dataRealizacao");
    return receive;
  }
}
