import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@mui-treasury/components/menu/nested";
import { SuportBottomGroupButton } from "./SuportBottomGroupButton";

const applyStyles = withStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflowY: "auto",
    margin: "15px 0",
  },
  footer: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
  },
  buttonGroup: {
    color: "red",
  },
  container: {
    position: "absolute",
    bottom: 0,
    padding: "10px",
  },
}));

class HelperList extends React.Component {
  constructor(props) {
    super(props);
    this.callbackBuilderOnClickCard =
      this.callbackBuilderOnClickCard.bind(this);
  }
  callbackBuilderOnClickCard(cardId) {
    return () => this.props.onClickCard(cardId);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.root}>
          <Menu
            menus={this.props.items}
            getItemProps={this.props.getItemProps}
          />
        </div>
        <Grid
          container
          className={classes.container}
          justify="stretch"
          direction="column"
        >
          <Grid item xs={12}>
            <SuportBottomGroupButton />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default applyStyles(HelperList);
