import React from "react";
import { withStyles, TextField, Grid } from "@material-ui/core";
import ButtonForAdd from "../../components/Buttons/ButtonForAdd";
import Header from "../../components/HeaderForAdd/Header";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  input: {
    paddingTop: "15px",
    paddingBottom: "60px",
  },
  inputContainer: {
    backgroundColor: "#fff",
    position: "relative",
    top: "10px",
  },
  root: {
    backgroundColor: "#F5F5F5",
    height: "100%",
    width: "100%",
  },
  button: {
    paddingTop: "30px",
  },
});
/**
 * Componente do formulário de criação e edição de documentos.
 */
class DocumentForm extends React.Component {
  /**
   * @param {string} props.titleHeader Prop que feirá o header do componente
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {string} this.state.input Input onde será armazenado o nome do documento e onde será pega o nome
   */
  constructor(props) {
    super(props);
    this.state = {
      input: { value: "", error: false },
    };
    this.setDocumentData = this.setDocumentData.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  /**Função onde é seta o input
   * @function
   */
  setDocumentData() {
    let input = { ...this.state.input };
    input.value = this.props.documentData.nome;
    this.setState({ input: input });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.documentData != this.props.documentData)
      this.setDocumentData();
  }

  /**Função onde é seta o input
   * @function
   */
  onClickSave() {
    if (this.state.input.value === "" || this.state.input.value.length < 1)
      this.setState({ input: { ...this.state.input, error: true } });
    else this.props.saveData(this.state.input.value);
  }

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <Header link={"/documents"} icon={<CloseIcon />}>
          {this.props.titleHeader}
        </Header>
        <Grid container justify="center" className={classes.inputContainer}>
          <Grid item xs={9} className={classes.input}>
            <TextField
              fullWidth
              value={String(this.state.input.value)}
              label="Tipo de documento"
              onChange={(e) =>
                this.setState({
                  input: { ...this.state.input, value: e.target.value },
                })
              }
              error={this.state.input.error}
              helperText={
                this.state.input.error ? "Informe o nome do documento!" : null
              }
            />
          </Grid>
        </Grid>
        <Grid container justify="center" className={classes.button}>
          <ButtonForAdd func={this.onClickSave}>Adicionar</ButtonForAdd>
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(DocumentForm);
