import React from "react";
import { withStyles, Typography, Grid } from "@material-ui/core";
import { CSSTransition } from "react-transition-group";
import ConfirmDelete from "../../../Buttons/ConfirmDelete";
import EditRemove from "../../../Buttons/EditRemove";
import moment from "moment";
const styles = () => ({
  card: {
    backgroundColor: "#fff",
    paddingLeft: "10px",
    paddingRight: "10px",
    position: "relative",
  },
  date: {
    fontSize: ".8rem",
    color: "#cccccc",
  },

  shedulingTypeText: {
    textTransform: "uppercase",
    fontSize: ".9rem",
  },

  // Edit and Remove Product CSS
  confirmDelete_on: {
    animationName: "$fadeIn",
    animationDuration: ".5s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  confirmDelete_off: {
    animationName: "$fadeOut",
    animationDuration: ".3s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  innerContainer_on: {
    animationName: "$slideLeft",
    animationDuration: ".3s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  innerContainer_off: {
    animationName: "$slideRight",
    animationDuration: ".2s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  "@keyframes slideLeft": {
    from: { transform: "translate(100%, 0)" },
    to: { transform: "translate(0, 0)" },
  },
  "@keyframes slideRight": {
    from: { transform: "translate(0, 0)" },
    to: { transform: "translate(100%, 0)" },
  },
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  "@keyframes fadeOut": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },

  "fade-enter": {
    opacity: 0,
  },
  "fade-enter-active": {
    opacity: 1,
    transition: "opacity 300ms",
  },
  "fade-exit": {
    opacity: 1,
  },
  "fade-exit-active": {
    opacity: 0,
    transition: "opacity 300ms",
  },
});

class CardScheduling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unMount: true,
    };
    this.handleToggleRemoveConfirmDelete =
      this.handleToggleRemoveConfirmDelete.bind(this);
    this.clickDeleteCard = this.clickDeleteCard.bind(this);
    this.clickEditCard = this.clickEditCard.bind(this);
  }

  componentWillUnmount() {
    this.setState({ unMount: false });
  }

  handleToggleRemoveConfirmDelete() {
    this.props.handleToggleRemoveConfirmDelete(this.props.data.hash);
  }

  clickDeleteCard() {
    this.props.clickRemoveItem(this.props.data);
  }

  clickEditCard() {
    this.props.clickEditItem(this.props.data.hash);
  }
  /* Botão redondo */
  render() {
    let { classes } = this.props;
    return (
      <Grid container className={classes.card}>
        <Grid item xs={2}>
          <Grid
            container
            style={{ height: "100%", position: "relative" }}
            alignItems="center"
            justify="center"
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                background: "rgba(255, 255, 255, 0.15)",
              }}
            ></div>
            {this.props.icon}
          </Grid>
        </Grid>
        <Grid item xs={7} style={{ padding: "10px" }}>
          <Grid item xs={12}>
            <Typography className={classes.shedulingTypeText}>
              {this.props.data.tipo}
            </Typography>
          </Grid>
          {(this.props.data.dataCriacao || this.props.data.createdAt) && (
            <Grid item xs={12}>
              <Typography className={classes.date}>
                {
                  <>
                    Criado em{" "}
                    {moment(
                      this.props.data.dataCriacao != null
                        ? this.props.data.dataCriacao
                        : this.props.data.createdAt
                    ).format("DD/MM/YYYY HH:mm")}
                  </>
                }
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={3}>
          <Grid container alignItems="center" style={{ height: "100%" }}>
            <EditRemove
              link={`/schedulings/edit/${this.props.clientHash}/${this.props.data.hash}`}
              onDeleteItem={this.handleToggleRemoveConfirmDelete}
              onEditItem={this.clickEditCard}
            />
          </Grid>
        </Grid>
        <CSSTransition
          in={this.props.cardOpen}
          timeout={700}
          classNames="fade"
          unmountOnExit
        >
          <ConfirmDelete
            cancelDelete={this.props.handleCloseConfirmDelete}
            confirmDelete={this.clickDeleteCard}
            confirmText="Deseja mesmo deletar esse agendamento?"
            classes={classes}
            transitionStatus={this.props.cardOpen}
          />
        </CSSTransition>
      </Grid>
    );
  }
}
export default withStyles(styles)(CardScheduling);
