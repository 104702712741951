import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

/**
 * Componente utilizado como parametro de props.InputProps de componentes <TextField/> do Material-uipara defnição de mascara do tipo CEP
 * que é dada pelo seguinte padrão #####-### onde '#' indica um numero inteiro
 */
class CepMask extends React.Component {
  constructor(props) {
    super(props);
    this.render.propTypes = {
      inputRef: PropTypes.func.isRequired,
    };
  }

  render() {
    const { inputRef, ...other } = this.props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
        placeholderChar={"\u2000"}
      />
    );
  }
}
export default CepMask;
