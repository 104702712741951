import React from "react";

/**
 * @typedef {SceneOptions} SceneOptions Possibilidades de modificação em cada cena
 *
 * @property {String} title Titulo à ser fornecido a cena
 * @property {String} nextBtnLabel Texto a ser exibido dentro do botão de Próximo
 * @property {String} previousBtnLabel Texto a ser exibido dentro do botão de Anterior
 * @property {callback} onClickNext Função responsavel por sobreescrever o comportamento padrão do evento de click no botão de Próximo
 * @property {callback} onClickPrevious Função responsavel por sobreescrever o comportamento padrão do evento de click no botão de Anterior
 * @property {boolean} hideButtons Oculta os botões caso true
 * @property {React.component} component React component a ser exibido
 * @property {String} id Usado para buscar os componentes
 * @property {String} name Nome a ser utilizado logo acima dos botões de navegação de cada cena para fornecer feedback visual
 *
 */

/**
 * Abstração de um conteiner padrão de cenas, esta classe reune uma série de comportamentos padrão
 * que serão utilizados pelos containers de cenas. utilização desta se da atravez da extensão da classe
 * e definição das cenas.
 *
 * Sobre as responsabilidades das classes que estenderam ScenesPage:
 *
 * @example
 * 1- Fornecer um ou mais objetos de configuração de cena.
 *
 * 1.1- Configurações minimas:
 *
 * class Container extends ScenesPage {
 *  constructor(props){
 *      this.state.scenes.push({
 *          name: 'SceneName',
 *          id: 'SCENE_ID',
 *          component: YourSceneComponent,
 *      })
 *  }
 * }
 *
 * 2- Definir o estado inicial de exibição de cenas.
 * @example
 * class Container extends ScenesPage {
 * constructor(props){
 *     //Your scenes
 *  this.state = {
 *          ...this.state,
 *          previousScene: { name: '', id: '' },
 *          activeScene: this.state.scenes[0],
 *          nextScene: this.state.scenes[1],
 *      }
 *  }
 * }
 */
class ScenesPage extends React.Component {
  /**
   * @property {Object} state
   * @property {SceneOptions} state.previousScene Propriedades para fins de navegação.
   * @property {SceneOptions} state.activeScene Propriedades para fins de navegação.
   * @property {SceneOptions} state.nextScene Propriedades para fins de navegação.
   * @property {SceneOptions[]} state.scenes Conjunto de cenas.
   *
   */
  constructor(props) {
    super(props);
    this.state = {
      previousScene: null,
      activeScene: null,
      nextScene: null,
      scenes: [],
    };
    this.setParentState = this.setParentState.bind(this);
    this.provideNextScene = this.provideNextScene.bind(this);
    this.providePreviousScene = this.providePreviousScene.bind(this);
    this.setActiveScene = this.setActiveScene.bind(this);
  }

  /**Assimila o estado de this.state.activeScene no momento em que a chamada desta é realizada
   * @function
   * @param {Object} childrenState Conjunto de dados que representa o estado do componente exibido na tela no momento da chamada da função
   */
  setParentState(childrenState) {
    this.setState({ ...this.state, ...childrenState });
  }

  /**Callback que realiza a navegação entre as cenas (no sentido cena Próximo). Caso necessite de um comportamento diferente vide SceneOptions.onClickNext
   * @function
   * @param {SceneOptions} [options] Opções para renderização personalizada de cada cena
   */
  provideNextScene(options) {
    const previousScene = this.state.scenes.find(
      (s) => s.id == this.state.activeScene.id
    );
    let newScene = this.state.scenes.find(
      (s) => s.id == this.state.nextScene.id
    );
    const currentIndex = this.state.scenes.findIndex(
      (scene) => scene.id == this.state.activeScene.id
    );
    const nextScene = this.state.scenes[currentIndex + 2];
    if (options) {
      newScene = { ...newScene };
      Object.assign(newScene, options);
    }

    this.setState({
      previousScene: previousScene,
      activeScene: newScene,
      nextScene: nextScene,
    });
  }

  /**
   * Callback que realiza a navegação entre as cenas (no sentido cena Anterior). Caso necessite de um comportamento diferente vide SceneOptions.onClickPrevious
   * @function
   * @param {SceneOptions} [options] Opções para renderização personalizada de cada cena
   */
  providePreviousScene(options) {
    const currentIndex = this.state.scenes.findIndex(
      (scene) => scene.id == this.state.activeScene.id
    );
    const previousScene = this.state.scenes[currentIndex - 2];
    let newScene = this.state.previousScene;
    const nextScene = this.state.activeScene;

    if (options) {
      newScene = { ...newScene };
      Object.assign(newScene, options);
    }

    this.setState({
      previousScene: previousScene,
      activeScene: newScene,
      nextScene: nextScene,
    });
  }

  /**
   * Callback utilizada caso a navegação oferecida por provideNextScene ou providePreviousScene não atendam as necessidades
   * @function
   * @param {String} id id da cena que deve ser renderizada na proxima atualização de estado
   * @param {SceneOptions} [options] Opções para renderização personalizada de cada cena
   */
  setActiveScene(id, options) {
    const currentIndex = this.state.scenes.findIndex((scene) => scene.id == id);
    const previousScene = this.state.scenes[currentIndex - 1];
    let newScene = this.state.scenes.find((scene) => scene.id == id);
    const nextScene = this.state.activeScene;

    if (options) {
      newScene = { ...newScene };
      Object.assign(newScene, options);
    }

    this.setState({
      previousScene: previousScene,
      activeScene: newScene,
      nextScene: nextScene,
    });
  }

  /**
   * Função responsavel por passar todas as propriedades descritas em {SceneOptions} para a cena que deve ser renderizada
   * @function
   */
  render() {
    let sceneData = this.state.activeScene;
    const Scene = sceneData.component;
    const disableBtnNext = !!this.props.disableBtnNext;
    const disableBtnPrevious = !!this.props.disableBtnPrevious;
    const thatState = Object.assign({}, this.state);

    return (
      <Scene
        title={sceneData.title}
        onDataChange={this.setParentState}
        provideNextScene={this.provideNextScene}
        providePreviousScene={this.providePreviousScene}
        disableBtnNext={disableBtnNext}
        disableBtnPrevious={disableBtnPrevious}
        defaultValues={thatState}
        setActiveScene={this.setActiveScene}
        nextBtnLabel={sceneData.nextBtnLabel}
        previousBtnLabel={sceneData.previousBtnLabel}
        onClickNext={sceneData.onClickNext}
        onClickPrevious={sceneData.onClickPrevious}
        hideButtons={sceneData.hideButtons}
        nextBtnSpan={sceneData.nextBtnSpan}
        previousBtnSpan={sceneData.previousBtnSpan}
        subtitle={sceneData.subtitle}
        classes={sceneData.classes ? sceneData.classes : {}}
        previousBtnClass={sceneData.previousBtnClass}
        nextBtnClass={sceneData.nextBtnClass}
        nextPrevBtnClass={sceneData.nextPrevBtnClass}
        btnsDirection={sceneData.btnsDirection}
        slideDirection={sceneData.slideDirection}
      />
    );
  }
}
export default ScenesPage;
