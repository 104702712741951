import DataAccess from "../DataAccessStrategy";
import CestaItens from "./CestaItens";
export default class Produto extends DataAccess {
  constructor() {
    super("Produto", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      identificador: String,
      valor: String,
      marca: String,
      codigo: String,
      conversao: String,
      tipoEstoque: String,
      quantidadeSeguranca: Number,
      descricao: String,
    });
  }

  async getProductsByBasket(basketId) {
    const cestaItem = new CestaItens();

    const items = await cestaItem.getByCestumId(basketId);
    const allProducts = await this.model.toArray();
    const proms = items.map(async (item) => {
      const products = await allProducts.find((p) => p.id == item.ProdutoId);
      item.product = products;
      return item;
    });
    const result = await Promise.all(proms);
    return result;
  }
}
