import React, { useContext } from "react";
import { ClientContext } from "../../contexts/ClientContext";

function PictureGetter({ id, children }) {
  const clientContext = useContext(ClientContext);
  const [picture, setPicture] = React.useState("");

  React.useEffect(
    function () {
      if (!id) return;

      clientContext
        .getClientPicture({ id })
        .then(setPicture)
        .catch(console.error);
    },
    [clientContext, id]
  );

  return children(picture);
}

export default PictureGetter;
