import React from "react";
import { withStyles, Divider, Grid, Typography } from "@material-ui/core";
import CardMovements from "../CardComponents/CardOfClient/CardsMovements/CardMovements";
import FooterBalance from "../../components/Footer/FooterBalance";
import AddButton from "../../components/Buttons/AddButton";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import clsx from "clsx";
import Moeda from "../../utils/Moeda";
import Report from "../../components/Report/InfoDayliReport";
import InvoiceDialog from "../Dialogs/EmptyDialog";
import { PrinterContext } from "../../contexts/PrinterContext";

import Slide from "@material-ui/core/Slide";
import { getEnvVar } from "../../service/config";

const styles = (theme) => ({
  containerCards: {
    paddingBottom: "90px",
    backgroundColor: "#f5f5f5",
    "& > div": {
      width: "100%",
    },
  },
  card: {
    "& > div": {
      backgroundColor: "#fff",
    },
  },
  date: {
    padding: "10px 15px 0px",
    fontSize: "0.9rem",
    fontWeight: "bold",
    color: "#999999",
    backgroundColor: "#fff",
  },
  hidden: {
    display: "none",
  },
  addButton: {
    position: "fixed",
    bottom: "115px",
    right: "10px",
  },
  rotate_on: {
    transform: "rotate(45deg)",
    transition: " 0.3s ",
    color: "#000",
    backgroundColor: "#fff",
  },
  rotate_off: {
    transform: "rotate(0deg)",
    transition: " 0.3s ",
    color: "#1E90FF",
    backgroundColor: "#fff",
  },

  loadMoreMovements: {
    margin: "20px auto 30px",
    padding: "5px 10px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

/**
 * Componente que renderiza a aba de movimentações do 'Ver Cliente'
 */
class Movements extends React.Component {
  /**
   * @param {object} props.id Id do usuário
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {object} this.state.movements  Dados de todas as movimentações
   * @property {string} this.state.currentBalance  Saldo atual do cliente
   * @property {boolean} this.state.cardOpen  Estado que armazerará a hash do card selecionado que está aberto no momento
   * @property {boolean} this.state.buttonGroupIsOpen  Boleano que indica se o botão de adicionar foi clicado
   * @property {object} this.state.buttonsGroup Dados utilizados nos 3 botões gerados pelo component AddButton
   */
  constructor(props) {
    super(props);
    this.state = {
      movements: [],
      showMoreMovementsDisabled: false,
      shownMovements: [],
      currentBalance: "0",
      cardOpen: null,
      buttonGroupIsOpen: false,
      isModalOpen: false,
      buttonsGroup: {
        button1: {
          link: `/receivement/create/${this.props.hash}`,
          icon: <i className="fas fa-money-bill-wave fa-2x" />,
        },
        button2: {
          link: `/exchange/create/${this.props.hash}`,
          icon: <SyncAltIcon fontSize="large" />,
        },
        button3: {
          link: `/sale/create/${this.props.hash}`,
          icon: <ShoppingCartIcon fontSize="large" />,
        },
      },
      offset: 0,
      startDate: null,
      endDate: null,
      movementDays: null,
    };

    this.fetchMovements = this.fetchMovements.bind(this);
    this.showMoreMovements = this.showMoreMovements.bind(this);
    this.handleToggleCardOpen = this.handleToggleCardOpen.bind(this);
    this.onClickCancelMovement = this.onClickCancelMovement.bind(this);
    this.handleToggleCreateMovements =
      this.handleToggleCreateMovements.bind(this);
    this.handleIcon = this.handleIcon.bind(this);
    this.calculateCurrentBalance = this.calculateCurrentBalance.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.onClickCard = this.onClickCard.bind(this);
  }

  async componentDidMount() {
    await this.setEnvVars();
    await this.fetchMovements();
  }

  async setEnvVars() {
    const [REPRINT_MOVIMENTACAO, QUANTIDADE_DIAS_MOVIMENTACOES] =
      await Promise.all([
        getEnvVar("REPRINT_MOVIMENTACAO"),
        getEnvVar("QUANTIDADE_DIAS_MOVIMENTACOES"),
      ]);

    this.setState({
      REPRINT_MOVIMENTACAO,
      movementDays: QUANTIDADE_DIAS_MOVIMENTACOES,
    });
  }

  /**Função responsável pelo fetch das movimentações do cliente.
   * @function
   */
  async fetchMovements() {
    await this.props.movementsContext.fetchMovements(
      this.props.hash,
      this.state.startDate,
      this.state.endDate
    );
  }

  async showMoreMovements() {
    await this.props.movementsContext.fetchMovementsFromServer(this.props.hash);
    this.setState({ showMoreMovementsDisabled: true });
  }

  /**Função armazera o card que ficará aberto e fecha o card caso ele esteja previamente aberto.
   * @function
   * @param {string} string Data
   */
  handleToggleCardOpen(hash) {
    if (hash === this.state.cardOpen) this.setState({ cardOpen: null });
    else this.setState({ cardOpen: hash });
  }

  /**Função executada ao cancelar uma movimentação.
   * @function
   * @param {number} hash hash da movimentação selecionada
   */
  onClickCancelMovement(data) {
    this.props.movementsContext.handleCancelMovements(
      data,
      this.props.hash,
      this.state.startDate,
      this.state.endDate,
      true
    );
  }

  /**Função responsável pela alternância de exibição do botão que habilita um grupo de botões responsável por redirecionar
   * para outras páginas.
   * @function
   */
  handleToggleCreateMovements() {
    this.setState({ buttonGroupIsOpen: !this.state.buttonGroupIsOpen });
  }

  /**Função responsável por retornar o ícone destinado ao tipo do agendamento.
   * @function
   * @param {string} type Tipo do agendamento
   * @return {icon} retorna um ícone
   */
  handleIcon(type) {
    if (type) {
      if (type.toLowerCase() == "clienterecebimento")
        return (
          <span style={{ fontSize: "0.4rem" }}>
            <i
              style={{ color: "#21a94f" }}
              className="fas fa-money-bill-wave fa-4x"
            />
          </span>
        );
      else if (type.toLowerCase() == "ClienteVenda".toLowerCase())
        return (
          <ShoppingCartIcon fontSize="large" style={{ color: "#f3c631" }} />
        );
    }
  }

  /**Função onde é calculado o valor de todas as movimentações.
   * @function
   * @param {array} movements Array com o valor de todas as movimentações.
   * @return {string} Valor resultante do calculo das movimentações.
   */
  calculateCurrentBalance(movements) {
    let result = Moeda.create(0).mount();
    movements.forEach((el) => {
      result = Moeda.create(result).add(el.valor).mount();
    });

    return result;
  }

  handleModalClose() {
    this.setState({ isModalOpen: false, selectedItem: null });
  }

  async onClickCard(movment) {
    try {
      const allowReprint = this.state.REPRINT_MOVIMENTACAO;
      const { viaClient, viaEmployee, imagemLogo } =
        await this.props.movementsContext.generateInvoice(movment);

      this.callPrinterComponent({
        viaClient,
        viaEmployee,
        content: viaClient,
        imagemLogo: imagemLogo,
        headerMainText: "Movimentações",
        disableSellerButton: !allowReprint,
        disableClientButton: !allowReprint,
        headerSecondaryText: this.props.client.nome,
        title: movment.tipoAtividade == "ClienteVenda" ? "Venda" : "Recibo",
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { classes } = this.props;
    let classIconButton = clsx({
      [classes.rotate_on]: this.state.buttonGroupIsOpen,
      [classes.rotate_off]: !this.state.buttonGroupIsOpen,
    });

    return (
      <PrinterContext.Consumer>
        {({ callPrinterComponent }) => {
          if (this.callPrinterComponent !== callPrinterComponent)
            this.callPrinterComponent = callPrinterComponent;
          return (
            <>
              <Grid container className={classes.containerCards}>
                {!this.props.movementsContext.movements.length ? (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={this.props.classes.notFound}
                  >
                    <Grid item xs={12}>
                      <Typography
                        align="center"
                        style={{
                          color: "#333",
                          margin: "30px auto 20px",
                          lineHeight: 1.4,
                          fontSize: "1.2em",
                        }}
                      >
                        Nenhuma movimentação encontrada!
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {this.props.movementsContext.movements.map((el, index) => {
                      let isOpen;
                      if (this.state.cardOpen === el.hash) isOpen = true;
                      return typeof el === "string" || el instanceof String ? (
                        <div key={index}>
                          <Divider
                            style={{ width: "100%", backgroundColor: "#aaa" }}
                          />
                          <Grid item xs={12} className={classes.date}>
                            {el}
                          </Grid>
                        </div>
                      ) : (
                        <>
                          <Grid container className={classes.card} key={index}>
                            <CardMovements
                              data={el}
                              index={index}
                              clickCancelMovement={this.onClickCancelMovement}
                              handleToggleCardOpen={this.handleToggleCardOpen}
                              cardOpen={isOpen}
                              icon={this.handleIcon(el.tipoAtividade)}
                              onClickCard={this.onClickCard}
                            />
                          </Grid>
                        </>
                      );
                    })}
                  </>
                )}
                <Button
                  onClick={this.showMoreMovements}
                  size="medium"
                  className={classes.loadMoreMovements}
                  disabled={this.state.showMoreMovementsDisabled}
                  variant="outlined"
                  color="primary"
                >
                  Carregar mais
                </Button>
                <span className={classes.addButton}>
                  <AddButton
                    onClick={this.handleToggleCreateMovements}
                    icon={
                      <AddIcon
                        fontSize={
                          this.state.buttonGroupIsOpen ? "default" : "large"
                        }
                      />
                    }
                    classNameMainBtn={classIconButton}
                    isCustom={true}
                    buttonsGroup={this.state.buttonsGroup}
                    isSmall={this.state.buttonGroupIsOpen}
                  />
                </span>
                {this.state.movementDays != null && (
                  <FooterBalance
                    type="movements"
                    currentBalance={
                      this.props.client && this.props.client.saldoAtual
                    }
                    lastBalance={
                      this.props.movementsContext.movements.length &&
                      (this.props.movementsContext.movements[
                        this.props.movementsContext.movements.length - 1
                      ].tipoAtividade == "ClienteVenda"
                        ? Moeda.create(
                            this.props.movementsContext.movements[
                              this.props.movementsContext.movements.length - 1
                            ].saldo
                          )
                            .add(
                              Moeda.create(
                                this.props.movementsContext.movements[
                                  this.props.movementsContext.movements.length -
                                    1
                                ].valor
                              )
                            )
                            .mount()
                        : Moeda.create(
                            this.props.movementsContext.movements[
                              this.props.movementsContext.movements.length - 1
                            ].saldo
                          )
                            .subtract(
                              Moeda.create(
                                this.props.movementsContext.movements[
                                  this.props.movementsContext.movements.length -
                                    1
                                ].valor
                              ).mount()
                            )
                            .mount())
                    }
                    // finalBalance={
                    //   this.props.movementsContext.movements.length &&
                    //   this.props.movementsContext.movements[0].saldo
                    // }
                    totalMovements={this.calculateCurrentBalance(
                      this.props.movementsContext.movements
                    )}
                  />
                )}
              </Grid>
              <InvoiceDialog
                open={this.state.isModalOpen}
                handleClose={this.handleModalClose}
                Transition={Transition}
              >
                <Report>
                  {JSON.stringify(this.state.selectedItem, null, "\t")}
                </Report>
              </InvoiceDialog>
            </>
          );
        }}
      </PrinterContext.Consumer>
    );
  }
}

export default withStyles(styles)(Movements);
