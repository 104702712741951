import greenlet from "greenlet";

const greenLetWorker = greenlet(`async (dbVersion, messageChannel) => {
  class ClientPictureDownloadHelper {
    static READ_ONLY = "readonly";
    static READ_WRITE = "readwrite";
    static DATABASE_NAME = "employee_db";

    openDatabase(dbVersion) {
      return new Promise((resolve, reject) => {
        const openDbRequest = indexedDB.open(
          ClientPictureDownloadHelper.DATABASE_NAME,
          dbVersion
        );
        openDbRequest.onsuccess = (event) => {
          this.db = event.target.result;
          resolve();
        };
        openDbRequest.onerror = reject;
      });
    }

    getClientBlock(options, validate) {
      return new Promise((resolve) => {
        if (!this.db) throw new Error("You must open the database!");
        const opt = Object.assign({ blockSize: 10, startFrom: null }, options);
        const clientStore = this.db
          .transaction("Cliente", ClientPictureDownloadHelper.READ_ONLY)
          .objectStore("Cliente");

        const keyRange =
          opt.startFrom === null
            ? null
            : IDBKeyRange.lowerBound(opt.startFrom, true);

        const cursorRequest = clientStore.openCursor(keyRange);

        const clientBlock = [];
        cursorRequest.onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor && clientBlock.length < opt.blockSize) {
            const client = cursor.value;
            const shouldIncludeClient = validate(client);

            if (shouldIncludeClient) {
              clientBlock.push(client);
            }

            cursor.continue();
          } else {
            const lastClient = clientBlock[clientBlock.length - 1];
            resolve({
              result: clientBlock,
              nextCursor: lastClient ? lastClient.DATABASE_ID : null,
            });
          }
        };
      });
    }

    saveClientPicture(client, picture) {
      const prom = new Promise((resolve, reject) => {
        const transaction = this.db.transaction(
          ["Cliente", "ClienteFoto"],
          ClientPictureDownloadHelper.READ_WRITE
        );
        const clientStore = transaction.objectStore("Cliente");
        const fotoStore = transaction.objectStore("ClienteFoto");
        const fotoRequest = fotoStore.index("ClienteHash").get(client.hash);

        fotoRequest.onsuccess = (event) => {
          const foto = event.target.result;
          let addOrUpdateFotoRequest;

          if (foto) {
            foto.foto = picture;
            addOrUpdateFotoRequest = fotoStore.put(foto);
          } else {
            addOrUpdateFotoRequest = fotoStore.add({
              foto: picture,
              ClienteId: client.id,
              ClienteHash: client.hash,
            });
          }

          addOrUpdateFotoRequest.onsuccess = (event) => {
            client.isFotoDownloaded = true;
            const updateClientRequest = clientStore.put(client);
            updateClientRequest.onsuccess = resolve;
            updateClientRequest.onerror = reject;
          };

          addOrUpdateFotoRequest.onerror = reject;
        };

        fotoRequest.onerror = reject;
      });
      return prom;
    }

    getTotalClients() {
      return new Promise((resolve, reject) => {
        const clientStore = this.db
          .transaction("Cliente", ClientPictureDownloadHelper.READ_ONLY)
          .objectStore("Cliente");
        const requestTotal = clientStore.count();
        requestTotal.onsuccess = () => {
          resolve(requestTotal.result);
        };
        requestTotal.onerror = reject;
      });
    }

    async downloadBase64(url = "") {
      if (url.indexOf("cloudinary.com") !== -1) {
        url = url.replace("/image/upload/", "/image/upload/c_scale,w_150/");
      }
      const request = await fetch(url);
      const imgBlob = await request.blob();
      const image = await this.blobToBase64(imgBlob);

      return image;
    }

    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
          const base64 = fr.result;
          resolve(base64);
        };
        fr.onerror = reject;
        fr.readAsDataURL(blob);
      });
    }
  }

  let picturesDownloaded = 0;
  let clientTotal = 0;
  try {
    const helper = new ClientPictureDownloadHelper();
    await helper.openDatabase(dbVersion);
    const shouldDownloadFoto = (client) =>
      !client.isFotoDownloaded && !!client.foto;

    let hasNext = true;
    let nextCursor = null;
    clientTotal = await helper.getTotalClients();

    while (hasNext) {
      const { result: clientBlock, nextCursor: cursor } =
        await helper.getClientBlock(
          { startFrom: nextCursor },
          shouldDownloadFoto
        );

      const fotoDownloadProms = clientBlock.map((client) =>
        helper.downloadBase64(client.foto)
      );

      const fotos = await Promise.all(fotoDownloadProms);

      for (let i = 0; i < clientBlock.length; i++) {
        const client = clientBlock[i];
        const foto = fotos[i];

        await helper.saveClientPicture(client, foto);
      }

      nextCursor = cursor;
      hasNext = !!cursor;
      picturesDownloaded += clientBlock.length;
      messageChannel.postMessage({
        status: "info",
        total: clientTotal,
        download: picturesDownloaded,
        message: "Baixando fotos de clientes...",
      });
    }
    messageChannel.postMessage({
      status: "success",
      total: clientTotal,
      download: clientTotal,
      message: "Download concluido.",
    });
  } catch (error) {
    messageChannel.postMessage({
      status: "error",
      total: clientTotal,
      download: picturesDownloaded,
      message: "Houve um problema ao baixar fotos",
    });

    console.error(error);
    throw error;
  }
}`);

export { greenLetWorker as downloadFotosWorker };
