import { checkEmployeeToken } from "../authentication/index";
import { fetchPollyfill } from "../AbstractService";
import ApiErrorResponse from "../../errorDefinition/ApiErrorResponse";
/**
 * @description Responsavel por baixar as imagens armazenadas no serviço de armazenamento cloudinary
 * @param {String} url String que referencia o endereço de onde a imagem deve ser baixada
 */
export async function downloadBase64(url = "") {
  try {
    if (url.indexOf("cloudinary.com") !== -1) {
      url = url.replace("/image/upload/", "/image/upload/c_scale,w_150/");
    }
    const request = await fetch(url);
    const imgBlob = await request.blob();
    const image = await blobToBase64(imgBlob);

    return image;
  } catch (error) {
    return "";
  }
}

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      const base64 = fr.result;
      resolve(base64);
    };
    fr.onerror = reject;
    fr.readAsDataURL(blob);
  });
}

export async function downloadClientPicture(client, urlFoto) {
  if (!urlFoto) return;

  let base64Image = await downloadBase64(urlFoto);
  return base64Image;
}

export async function uploadClientDocumentPicture(
  clientDoc,
  { service, token }
) {
  const URL = `${service}/api/cliente/${clientDoc.ClienteId}/${clientDoc.DocumentoId}/${clientDoc.hash}/upload-foto?token=${token}`;

  const response = await fetchPollyfill(URL, {
    method: "POST",
    body: JSON.stringify({ foto: clientDoc.fotoUrl }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  checkEmployeeToken(data, response.abort);

  if (data.error) {
    throw new ApiErrorResponse({
      message: data.msg,
      title: "Houve um problema ao enviar foto ao servidor",
      method: "service.file.uploadClientDocumentPicture",
      type: "error",
    });
  }

  const photo = await downloadBase64(data.data.foto);
  return photo;
}

export async function uploadEmployeePicture(employee, picture) {
  const { service, id: employeeId, sessao } = employee;
  const URL = `${service}/api/funcionario/${employeeId}/upload-foto?token=${sessao.token}`;
  const response = await fetchPollyfill(URL, {
    method: "POST",
    body: JSON.stringify({ foto: picture }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  checkEmployeeToken(data, response.abort);

  if (data.error) {
    throw new ApiErrorResponse({
      message: data.msg,
      title: "Houve um problema ao enviar foto ao servidor",
      method: "service.file.uploadEmployeePicture",
    });
  }
  return data.data;
}
