import DataAccess from "../DataAccessStrategy";

export default class CestaVenda extends DataAccess {
  constructor() {
    super("CestaVenda", {
      CestumId: Number,
      ClienteId: Number,
      ClienteVendaId: Number,
      DATABASE_ID: Number,
      hash: String,
      id: Number,
      quantidade: Number,
      shouldSync: Boolean,
      valor: String,
    });
  }
}
