import React from "react";
import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/TextField";

/**
 * Componente utilizado como parametro de props.InputProps de componentes <TextField/> do Material-uide componentes <TextField/> do Material-ui para defnição de mascara do tipo cpf
 * que é dada pelo seguinte padrão ###.###.###-## onde '#' indica um numero inteiro
 */

export default function CpfMask(props) {
  const { value, onChange, disabled, ...others } = props;
  return (
    <InputMask
      maskChar={null}
      disabled={disabled}
      mask="999.999.999-99"
      alwaysShowMask={false}
      value={value}
      onChange={onChange}
    >
      {(inputProps) => {
        return <MaterialInput {...others} {...inputProps} />;
      }}
    </InputMask>
  );
}
