import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { IconButton } from "@material-ui/core";

const styles = (theme) => ({
  buttonClass: {
    border: 0,
    padding: "0 7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "none",
    color: "#A9A9A9",
    outline: "none",
  },
  teste: {
    backgroundColor: "#324",
  },
  icons: {
    fontSize: 18,
  },
  divider: {
    height: "30px",
    width: "1px",
  },
});

/**
 * Componente com 2 botões para editar e remover algum item.
 * O componente utiliza este meio de CSS acima.
 */
class EditRemove extends React.Component {
  /**
   * @param {function} props.onEditItem Função acionada no 'click' para editar um item
   * @param {function} props.onDeleteItem Função acionada no 'click' ao deletar um item
   * @param {object} props.classes Classes do css vindas do withStyles
   */
  constructor(props) {
    super(props);
    this.onDeleteItem = this.onDeleteItem.bind(this);
  }

  onDeleteItem() {
    this.props.onDeleteItem();
  }

  render() {
    let { classes } = this.props;
    return (
      <Grid item container wrap="nowrap" justify="flex-end" alignItems="center">
        <Link to={this.props.link}>
          <button
            className={classes.buttonClass}
            onClick={this.props.onEditItem}
          >
            <BorderColorIcon className={classes.icons} />
          </button>
        </Link>
        <Divider
          style={{ height: "30px", width: "1px" }}
          orientation="vertical"
        />
        <IconButton className={classes.buttonClass} onClick={this.onDeleteItem}>
          <DeleteIcon className={classes.icons} />
        </IconButton>
      </Grid>
    );
  }
}
export default withStyles(styles)(EditRemove);
