import React, { useCallback, useContext, useEffect } from "react";
import ErrosNotificationCenter from "../../service/notifications/errosNotificationCenter";
import { MessageDialogContext } from "../../contexts/MessageDialogContext";

import MessageDialog from "./MessageDialog";

const ERROR_NAME_BLACKLIST = ["InvalidAccessToken", "SessionDataLostError"];

function MessageDialogManager() {
  const dialogsContext = useContext(MessageDialogContext);

  const onNotification = useCallback(
    (type, info) => {
      if (
        ERROR_NAME_BLACKLIST.some(
          (className) => className == info.errorClassName
        )
      ) {
        return;
      }
      // if (userContext.isAuthenticated)
      dialogsContext.addDialog({
        type,
        ...info,
      });
    },
    [dialogsContext]
  );

  useEffect(
    () => ErrosNotificationCenter.instance.subscribe(onNotification),
    [onNotification]
  );

  return (
    <>
      {dialogsContext.dialogsArray.map((dialog, index) => {
        return <MessageDialog key={index} dialogData={dialog} />;
      })}
    </>
  );
}

export default MessageDialogManager;
