import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import CardExchange from "../../CardComponents/CardOfClient/CardsExchanges/CardExchanges";
import { ExchangeContext } from "../../../contexts/ExchangeContext";
import * as moment from "moment";
import clsx from "clsx";
import { PrinterContext } from "../../../contexts/PrinterContext";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import history from "../../../router/History";
const styles = (theme) => ({
  card: {
    backgroundColor: "#fff",
    marginBotton: "3vh",
    marginTop: "1.5vh",
  },
  icon: {
    color: theme.palette.primary.main,
  },
  notRealized: {
    color: "#941c1c",
  },
});

/**
 * Componente que renderiza a listas de trocas,usado no na tela de ver cliente.
 */
class Exchanges extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {array} this.state.data Lista contendo os dados de todos os cards que serão renderizados
   */
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isModalOpen: false,
      selectedItem: null,
    };

    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentDidMount() {
    this.context
      .getAll()
      .then((products) => {
        this.setState({ products });
      })
      .catch(console.error);
    this.context.getClientExchange(this.props.hash);
  }

  mapExchanges(exchange) {
    const mapExchangeItem = (exchangeItem) => {
      const product = this.state.products.find(
        (p) => p.id == exchangeItem.ProdutoId
      );
      return {
        name: product ? product.identificador : "Carregando...",
        amount: exchangeItem.quantidade,
      };
    };

    const output = exchange.retirados.map(mapExchangeItem);
    const input = exchange.adicionados.map(mapExchangeItem);
    let dataTrocaRealizada;
    let dataRealizacao;

    if (exchange.dataTrocaRealizada) {
      dataTrocaRealizada = moment(exchange.dataTrocaRealizada).format(
        "DD - MMM"
      );
    }
    dataRealizacao = moment(exchange.dataRealizacao);

    return {
      hash: exchange.hash,
      dataTrocaRealizada,
      dataRealizacao,
      output,
      input,
    };
  }

  handleCardClick(exchange) {
    return () =>
      history.push(
        `/exchange/report/${this.context.client.hash}/${exchange.hash}`
      );
  }
  handleModalClose() {
    this.setState({ isModalOpen: false, selectedItem: null });
  }

  getClientInfo() {
    if (this.context.client) {
      return this.context.client.nome;
    }
    return "Carregando...";
  }

  render() {
    const { classes } = this.props;
    return (
      <PrinterContext.Consumer>
        {({ callPrinterComponent }) => {
          if (this.callPrinterComponent !== callPrinterComponent)
            this.callPrinterComponent = callPrinterComponent;
          return (
            <>
              <Grid container>
                {!this.context.exchanges.length && (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={this.props.classes.notFound}
                  >
                    <Grid item xs={12}>
                      <Typography align="center">
                        Nenhuma troca encontrada!
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {this.context.exchanges.map((exchange, index) => {
                  const resumedInfo = this.mapExchanges(exchange);
                  const isRealized = !!resumedInfo.dataTrocaRealizada;
                  return (
                    <Grid container className={classes.card} key={index}>
                      <CardExchange
                        onClick={this.handleCardClick(exchange)}
                        data={resumedInfo}
                        icon={
                          <SyncAltIcon
                            fontSize="large"
                            className={clsx(classes.icon, {
                              [classes.notRealized]: !isRealized,
                            })}
                          />
                        }
                        status={isRealized}
                        clientHash={this.props.hash}
                        onClickRealize={this.context.realizeExchange}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          );
        }}
      </PrinterContext.Consumer>
    );
  }
}
Exchanges.contextType = ExchangeContext;
export default withStyles(styles)(Exchanges);
