import AbstractError from "./AbstractError";
import { RuntimeError } from "../repository/errorsModels";

export default class InvalidAccessToken extends AbstractError {
  constructor(helperData = {}) {
    helperData = Object.assign(
      {
        message: "Seu token de acesso expirou",
        title: "Sua sessão expirou",
        type: "error",
      },
      helperData
    );

    super(helperData, new RuntimeError());
  }
}
