import { Bairro, Cidade } from "../../repository/models";
import BusinessError from "../../errorDefinition/BusinessError";

const bairro = new Bairro();
const cidade = new Cidade();

/**
 *@description Responsavel por estruturar e armazenar os dados de bairro no banco de dados
 *@param {Object} neighborhood Dados do bairro que será criado
 *
 */
export async function create(neighborhood) {
  if (!neighborhood || !neighborhood.nome || !neighborhood.cidade) return;
  neighborhood.nomeCheck = neighborhood.nome;
  neighborhood.index = parseInt(neighborhood.index);
  neighborhood.shouldSync = true;

  let cityId = await cidade.getAll();
  cityId = cityId.filter((city) => city.cidade == neighborhood.cidade);
  if (!cityId.length) {
    throw new BusinessError({
      message: "Não foi encontrar a cidade",
      title: "Falhar ao criar novo bairro!",
      type: "warning",
      method: "createNeighborhood",
    });
  }

  let bairroExists = await bairro.getAll();
  bairroExists = bairroExists.filter((b) => {
    let isSameName = b.nome.toUpperCase() == neighborhood.nome.toUpperCase();
    let isSameEstado = b.estado == neighborhood.estado;
    let cidadeArg = typeof b.cidade == "object" ? b.cidade.cidade : b.cidade;
    let cidadeFunc =
      typeof neighborhood.cidade == "object"
        ? neighborhood.cidade.cidade
        : neighborhood.cidade;
    let isSameCidade = cidadeArg == cidadeFunc;
    return isSameName && isSameEstado && isSameCidade;
  });

  if (bairroExists.length) {
    throw new BusinessError({
      message: "Um bairro com tais informações já existe.",
      title: "Falhar ao criar novo bairro!",
      type: "warning",
      method: "create",
    });
  }
  return await bairro.create(neighborhood);
}
