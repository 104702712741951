import React from "react";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { Grid } from "@material-ui/core";
import { debounce } from "debounce";
const styles = (theme) => ({
  search: {
    backgroundColor: "#F8F8FF",
    height: "60px",
    paddingLeft: 10,
  },
  input: {
    border: 0,
    backgroundColor: "transparent",
    height: "100%",
    width: "90%",
    outline: "none",
    fontSize: "1rem",
    fontFamily: "Roboto, sans-serif",
  },
});

/**
 * Componente que rederiza uma barra de pesquisa.
 */
class SearchBar extends React.Component {
  /**
   * @param {boolean} props.getInput Função em que seta no elemento pai o valor digitado no input
   * @property {string} this.state.input  Boleano que altera a exibição do menu lateral
   */
  constructor(props) {
    super(props);
    this.state = {
      input: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  /**Função que seta no estado 'input' o texto escrito no input.
   * @function
   * @param {event} e evento gerado pelo onChange
   */
  handleInputChange(e) {
    this.setState(
      { input: e.target.value },
      debounce(() => {
        this.props.getInput(this.state.input);
      }, 500)
    );
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Grid container alignItems="center" className={classes.search}>
          <SearchIcon />
          <input
            type="search"
            onChange={this.handleInputChange}
            placeholder="Buscar em todos os itens"
            className={classes.input}
          />
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(SearchBar);
