import DataAccess from "../DataAccessStrategy";

export default class ClienteDiaria extends DataAccess {
  constructor() {
    super("ClienteDiaria", {
      bairroOrder: Number,
      id: Number,
      nome: String,
      hash: String,
      saldo: Number,
      BairroId: Number,
      dailySchedules: Array,
    });
    this.put = this.put.bind(this);
    this.orderBy = this.orderBy.bind(this);
  }

  orderBy(order) {
    if (order.direction == "DESC")
      return this.model.orderBy(order.by).reverse().toArray();
    return this.model.orderBy(order.by).toArray();
  }

  async put(client) {
    const clientDB = await this.model.where("hash").equals(client.hash).first();
    client.DATABASE_ID = clientDB.DATABASE_ID;
    return await this.model.put(client);
  }
}
