import React from "react";
import { ProductContext } from "../../../../contexts/ProductsContext";

const saleProductsAmountMapper = function (cestaVenda) {
  const basketAmount = cestaVenda.quantidade;
  const productsAmount = cestaVenda.item.itens.map((item) => {
    const cloned = { ...item };
    cloned.quantidade = item.quantidade * basketAmount;
    return cloned;
  });
  return productsAmount;
};

export default function useAvaliableProducts(soldBaskets, isDetachedExchange) {
  const [products, setProducts] = React.useState([]);
  const productContext = React.useContext(ProductContext);

  const applyBusinessRule = React.useCallback(
    function () {
      const productsOnSale = soldBaskets.map(saleProductsAmountMapper).flat(1);
      const idsSet = [
        ...productsOnSale.reduce((memo, item) => {
          memo.add(item.product.id);
          return memo;
        }, new Set()),
      ];
      const productAmountOfSale = idsSet.map((ProdutoId) => {
        const sameProducts = productsOnSale.filter(
          (item) => item.product.id == ProdutoId
        );
        const total = sameProducts.reduce((memo, item) => {
          memo += parseInt(item.quantidade);
          return memo;
        }, 0);
        return {
          ...sameProducts[0].product,
          quantidade: total,
        };
      });

      return productAmountOfSale;
    },
    [soldBaskets]
  );

  React.useEffect(
    function () {
      if (isDetachedExchange) {
        setProducts(productContext.storedItems || []);
      } else {
        setProducts(applyBusinessRule());
      }
    },
    [applyBusinessRule, productContext, isDetachedExchange]
  );

  const isValidProductAmount = React.useCallback(
    function (productId, amount) {
      if (isDetachedExchange) return true;
      const product = products.find((el) => el.id == productId);

      return amount <= product.quantidade;
    },
    [isDetachedExchange, products]
  );

  return [products, isValidProductAmount, productContext.storedItems];
}
