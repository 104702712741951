import React from "react";
import ErrosNotificationCenter from "../service/notifications/errosNotificationCenter";
import {
  isAuthenticated,
  authenticate,
  logout,
  getCompany,
  getUser,
} from "../service/authentication";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import SyncDialog from "../components/Dialogs/SyncDialog";
import LocalStorage from "../utils/localStorage";
import { LAST_UPDATE_KEY } from "../service/sync/activeSync";
import * as moment from "moment";
import { MAIN_ROUTE } from "../service/route";
import { WatchApp } from "../service/WatchApp";
import {
  setConfigKey,
  getConfigKey,
  getLocalCongfigVar,
} from "../service/config";
import history from "../router/History";
import { downloadFotosWorker } from "../utils/DownloadClientFotoWorker";
import SyncNotificationCenter from "../service/notifications/syncNotificationCenter";
import { version } from "../../package.json";

const itemsToSync = [
  "syncRouteOpening",
  "syncMainRoute",
  "syncTeam",
  "syncVehicle",
  "syncDailyMap",
  "syncFuel",
  "syncDocuments",
  "syncCityNeighborhood",
  "syncPaymentForm",
  "syncPaymentMethod",
  "syncBasket",
  "syncValidators",
  "syncRequiredFields",
  "syncHoliday",
  "syncClients",
  "syncDailyMap",
  "syncVarEnvs",
];
const UserContext = React.createContext({ isLoading: true });

class UserContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.observable = this.observable.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.authenticateUser = this.authenticateUser.bind(this);
    this.handleSyncDialogClose = this.handleSyncDialogClose.bind(this);
    this.onOnline = this.onOnline.bind(this);
    this.unsubscribe = ErrosNotificationCenter.instance.subscribe(
      this.observable
    );
    this.state = {
      initiateSync: false,
      isBitboxEmployee: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    isAuthenticated()
      .then((resp) => {
        this.setState({ isAuthenticated: resp, isLoading: false });
        window.addEventListener("online", this.onOnline, false);
        if (navigator.onLine) {
          this.onOnline();
        }
        if (resp) window.requestIdleCallback(this.startBackgroundFotoSync);
      })
      .catch((err) => {
        this.setState({ isAuthenticated: false, isLoading: false });
      });
    this.removeOldEventsFromBD();
    this.defineUserType();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  async defineUserType() {
    //AQUI ESTOU COLOCANDO ESSE CÓDIGO PARA FORÇAR NUNCA ABRIR EM MODO DEV
    await setConfigKey("isBitboxEmployee", true);
    getConfigKey("isBitboxEmployee").then((envVar) => {
      const value = !!envVar;
      console.log("IS_BITBOXAPP", value);
      this.setState({ isBitboxEmployee: value });
    });
  }

  async startBackgroundFotoSync() {
    const shouldDownloadFoto = await getLocalCongfigVar("downloadFotos");
    if (shouldDownloadFoto) {
      const channel = new MessageChannel();
      const dbVersion = parseFloat(window.DATABASE.verno) * 10;

      downloadFotosWorker(dbVersion, channel.port1);
      channel.port2.onmessage = (message) => {
        SyncNotificationCenter.instance.notifyAll(
          "foto-download",
          message.data
        );
      };
    }
  }

  removeOldEventsFromBD() {
    setTimeout(() => {
      requestIdleCallback(() => {
        window.ERROR_LOG && window.ERROR_LOG.clearOldRecords();
      });
    }, 20 * 1000);
  }

  async verifyLastUpdate() {
    const twoMonthsLater = moment().subtract(2, "months");
    const lastUpdate = await LocalStorage.instance.getItem(LAST_UPDATE_KEY);

    if (!lastUpdate) {
      this.logoutUser(true);
    }

    const shouldLogout = twoMonthsLater.isSameOrAfter(lastUpdate);
    if (shouldLogout) {
      this.logoutUser(true);
      await this.context.addAsyncDialog({
        title: "Encontramos um problema com seu login",
        type: "error",
        message:
          "Seu token de acesso expirou. Você será redirecionado à pagina de login.",
        hasCloseButton: false,
        handleConfirm: () => {
          this.setState({ isAuthenticated: false }, () => history.push("/"));
          this.context.clearAllDialogs();
        },
      });
    }
  }

  async observable(msg, helperData) {
    if (this.state.isAuthenticated) {
      if (
        helperData.errorClassName == "InvalidAccessToken" ||
        helperData.errorClassName == "SessionDataLostError"
      ) {
        await logout("", true);
        await this.context.addAsyncDialog({
          title: "Encontramos um problema com seu login",
          type: msg,
          message: "Clique em Ok para ir para a página de login.",
          hasCloseButton: false,
          handleConfirm: () => {
            this.setState({ isAuthenticated: false }, () => history.push("/"));
            this.context.clearAllDialogs();
          },
        });
      }
    }
  }

  async logoutUser(forceLogout = false) {
    try {
      await logout("", forceLogout);
      this.setState({ isAuthenticated: false });
    } catch (error) {
      console.error(error);
    }
  }

  async authenticateUser(login, pwd, service, type) {
    try {
      await authenticate({ login, pwd, service });
      this.setState({ isAuthenticated: true, initiateSync: true });
      type === "pap"
        ? await setConfigKey("isBitboxEmployee", false)
        : await setConfigKey("isBitboxEmployee", true);
      this.defineUserType();
    } catch (err) {
      this.setState({ isAuthenticated: false });
      throw err;
    }
  }

  handleSyncDialogClose() {
    this.setState({ initiateSync: false });
  }

  onOnline() {
    const checkServer = true;
    if (this.state.isAuthenticated) {
      isAuthenticated(checkServer)
        .then((authenticated) => {
          if (!authenticated) {
            this.context.addDialog({
              title: "Encontramos um problema com seu login",
              type: "error",
              message: "Clique em Ok para ir para a página de login.",
              hasCloseButton: false,
              handleConfirm: () => {
                this.setState({ isAuthenticated: false }, () =>
                  history.push("/login")
                );
                this.context.clearAllDialogs();
              },
            });

            this.logoutUser(true).catch(() => {
              this.setState({ isAuthenticated: false });
            });
          } else {
            //this.verifyLastUpdate();
            this.subscribeToRemoteAccess();
          }
          window.removeEventListener("online", this.onOnline);
        })
        .catch(() => {
          this.context.addAsyncDialog({
            type: "warning",
            message: "Houve um problema ao verificarmos o seu token",
            title: "Problema com a conexão",
          });
        });
    }
  }

  subscribeToRemoteAccess() {
    const additionalData = async () => {
      let empresa = await getCompany();
      let rota = await LocalStorage.instance.getItem(MAIN_ROUTE);
      let user = await getUser();
      let name = `PAP - ${version} - ${
        (user && user.nome) || "USUARIO-DESLOGADO"
      }`;
      return {
        nome: name,
        identificador:
          ((empresa && empresa.nome) || "SEM EMPRESA") +
          " - " +
          ((rota && rota.nome) || "SEM ROTA"),
        empresa: empresa,
        rota: rota,
        version: version,
        onPage: window.location.hash,
      };
    };
    WatchApp.init({ additionalData });
  }

  render() {
    const { isAuthenticated, initiateSync, isBitboxEmployee, isLoading } =
      this.state;
    let { authenticateUser, logoutUser } = this;
    return (
      <UserContext.Provider
        value={{
          isAuthenticated,
          isBitboxEmployee,
          authenticateUser,
          logoutUser,
          isLoading,
        }}
      >
        {typeof this.props.children === "function"
          ? this.props.children()
          : this.props.children}
        {isAuthenticated && initiateSync ? (
          <SyncDialog
            open={initiateSync}
            preselectedItems={itemsToSync}
            autoSync={initiateSync}
            onClose={this.handleSyncDialogClose}
          ></SyncDialog>
        ) : null}
      </UserContext.Provider>
    );
  }
}

UserContextProvider.contextType = MessageDialogContext;

export { UserContextProvider, UserContext };
