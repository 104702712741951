import React from "react";
import imageCompression from "browser-image-compression";

export class Pictures extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.video = React.createRef();
    this.onFileSelected = this.onFileSelected.bind(this);
    this.createImage = this.createImage.bind(this);
    this.concatImages = this.concatImages.bind(this);
  }

  static onFileSelected({ target }) {
    return new Promise(async (resolve) => {
      if (target.files && target.files[0]) {
        const FR = new FileReader();

        FR.addEventListener("load", async (e) => {
          resolve(e.target.result);
        });

        const fileResized = await imageCompression(target.files[0], {
          maxSizeMB: 1,
          maxIteration: 15,
          maxWidthOrHeight: 480,
        });

        FR.readAsDataURL(fileResized);
      }
    });
  }
}
/**
 * @typedef {Object} ResizeOptions
 * @property {Number} width Largura final após o redimensionamento;
 * @property {('jpeg'|'png')} outputFormat
 * @property {Number} quality Qualidade ao final do redimensionamento, valor entre 0 e 1 (1 é o maior possivel)
 * @property {Boolean} shouldChangeHeight Caso true o redimensionamento na altura não será aplicado.
 */

/**
 *
 * @param {String} image Base64 imagem
 * @param {ResizeOptions} options Configurção do redimensionamento
 * @returns {Promise<File>} Imagem ao final do redimensionamento, no formato especificado em options
 */
const createImage = function (url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });
};

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0, format) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  return new Promise((resolve) => {
    resolve(canvas.toDataURL(format));
  });
}

/**
 * @description It will concat images in the vertical axis. It will set the width of the greatest width of images and sum the heights.
 * @param {Array} arrayImages - Array of string encoded in base24
 * @param {number} padding - Defaut is 5. Bottom padding when inserting an image.
 * @param {string} padding - Defaut is #FFF. Background color of generated image.
 */
export const concatImages = async function (
  arrayImages = [],
  padding = 5,
  backgroundColor = "#FFF"
) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  let heightIncrement = 0;

  const imagesProms = arrayImages.map((el) => createImage(el));
  const images = await Promise.all(imagesProms);
  const width = images.reduce(
    (greatest, el) => (el.width > greatest ? el.width : greatest),
    0
  );
  const height = images.reduce((sum, el) => sum + parseFloat(el.height), 0);
  canvas.width = width;
  canvas.height = height;
  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, 0, width, height);

  images.forEach((image, index) => {
    ctx.drawImage(image, 0, heightIncrement);
    heightIncrement += image.height + padding;
  });

  return canvas.toDataURL();
};

export function base64ToBlob(base64) {
  return fetch(base64).then((res) => res.blob());
}

export function resetOrientation(srcBase64, srcOrientation) {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = function () {
      const { width } = img;
      const { height } = img;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (srcOrientation > 4 && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      resolve(canvas.toDataURL());
    };

    img.src = srcBase64;
  });
}
