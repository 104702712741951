import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";

const styles = (theme) => ({});

/**
 * Botão redondo com box-shadow .
 * O componente utiliza este meio de CSS acima.
 */
class ButtonOptions extends React.Component {
  /**
   * @param {fuction} props.func Função acionada no 'click'
   * @param {function} props.color Cor definida para o botão
   * @param {icon} props.icon Icone definido para o botão
   *  @param {icon} props.colorIcon Cor definida para o icone
   * @param {object} props.classes Classes do css vindas do withStyles
   */
  constructor(props) {
    super(props);
  }

  /* Botão redondo */
  render() {
    return (
      <Fab
        size="small"
        onClick={this.props.func}
        disabled={this.props.disabled}
        style={{
          backgroundColor: this.props.color,
          color: this.props.colorIcon,
        }}
        data-testid="button-options"
      >
        {this.props.icon}
      </Fab>
    );
  }
}
export default withStyles(styles)(ButtonOptions);
