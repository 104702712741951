import React, { createContext } from "react";
import LocalStorage from "../utils/localStorage";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import {
  Cliente,
  ClienteAgendamento,
  ClienteRecebimento,
  MeioPagamento,
} from "../repository/models";
import { getClientLocation } from "../service/client";
import Moeda from "../utils/Moeda";
import {
  createScheduling,
  validateSchedule,
  removeScheduleFromDaily,
} from "../service/schedulings/index";
import moment from "moment";
import { AbstractContext } from "./AbstractContext";
import ClientnotificationCenter from "../service/notifications/clientNotificationCenter";
import BusinessError from "../errorDefinition/BusinessError";

const localStorage = LocalStorage.instance;
const ReceivementContext = createContext({
  receivement: [],
});

const eventEmitter = ClientnotificationCenter.instance;
const cliente = new Cliente();
const clienteAgendamento = new ClienteAgendamento();
const clienteRecebimento = new ClienteRecebimento();
const meioPagamento = new MeioPagamento();

/**
 * Contexto utilizado na pagina de configurações.
 */
const OBSERVED_MODELS = ["MeioPagamento"];
class ReceivementContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.observable = this.observable.bind(this);
    this.updateSelf = this.updateSelf.bind(this);
    this.getClientByHash = this.getClientByHash.bind(this);
    this.createReceivement = this.createReceivement.bind(this);
    this.getSchedulingsByDate = this.getSchedulingsByDate.bind(this);
    this.clientBackgroundSync = this.clientBackgroundSync.bind(this);

    this.state = {
      payments: [],
      syncOneClient: this.syncOneClient,
      getClientByHash: this.getClientByHash,
      createReceivement: this.createReceivement,
      getSchedulingsByDate: this.getSchedulingsByDate,
      clientBackgroundSync: this.clientBackgroundSync,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.updateSelf();
  }

  observable() {
    this.updateSelf().catch(console.error);
  }

  /**Função responsável atualizar dados do estado.
   * @function
   */
  async updateSelf() {
    this.setState({
      payments: await meioPagamento.getAll(),
    });
  }

  /**Função responsável por pegar os dados de um cliente pela hash do mesmo.
   * @function
   * @param {string} hash Hash do cliente
   */
  async getClientByHash(hash) {
    let client = await cliente.findByHash(hash);
    return {
      id: client.id,
      nome: client.nome,
      hash: client.hash,
      currentBalance: Moeda.create(client.saldoAtual).mount(),
      cpf: client.cpf,
    };
  }

  /**Função responsável por verificar se o cliente tem um recebimento numa determinada data.
   * @function
   * @param {string} id Id do cliente
   * @param {Date} date Respectiva data que será consultada
   */
  async getSchedulingsByDate(date, id) {
    let schedulingExist = await clienteAgendamento.getAll("ClienteId", id);
    schedulingExist = schedulingExist.filter(
      (a) =>
        a.ativo == true &&
        a.tipo.toLowerCase() == "Recebimento".toLowerCase() &&
        moment(a.dataRecebimento).isSame(date, "day")
    );
    if (schedulingExist.length > 0) return true;
  }

  /**Função responsável por criar um novo recebimento.
   * @function
   * @param {object} data Dado usado na criação de um recebimento.
   */
  async createReceivement(data, clientHash) {
    try {
      await this.isRouteOpen();
      let hashRotaAbertura = await localStorage.getItem("route-opening");
      if (!hashRotaAbertura) {
        throw new Error("Para concluir esta ação abra a rota!");
      }

      const { dataRecebimento, tipo, ...receivimentData } = data;
      if (dataRecebimento && tipo) {
        const { error, message } = await validateSchedule({ dataRecebimento });
        if (error)
          throw new BusinessError({
            message,
            type: "warning",
            title: "Problemas com a data do agendamento",
          });
      }

      const [client] = await cliente.getAll("hash", clientHash);
      const currentBalance = client.saldoAtual;
      const coords = await getClientLocation();

      receivimentData.shouldSync = true;
      receivimentData.ClienteId = client.id;
      receivimentData.latitude = coords.latitude;
      receivimentData.longitude = coords.longitude;
      receivimentData.tipoAtividade = "ClienteRecebimento";
      receivimentData.hashRotaAbertura = hashRotaAbertura.hash;

      receivimentData.saldo = Moeda.create(currentBalance)
        .add(receivimentData.valor)
        .mount();

      client.shouldSync = true;
      client.saldoAtual = Moeda.create(receivimentData.saldo).mount();

      receivimentData.dataRealizacao = new Date();

      const DATABASE_ID = await clienteRecebimento.create(receivimentData);

      await removeScheduleFromDaily(client.hash, "Recebimento");
      const receivementDB = await clienteRecebimento.getAll(
        "DATABASE_ID",
        DATABASE_ID
      );
      let info = { clienteHash: client.hash };

      if (dataRecebimento) {
        let newScheduling = {
          tipo: tipo,
          ativo: true,
          ClienteId: client.id,
          dataCriacao: new Date(),
          dataRecebimento: dataRecebimento,
        };

        if (tipo.toLowerCase() == "venda") {
          info = await createScheduling(client.hash, newScheduling);
        } else if (tipo.toLowerCase() == "recebimento") {
          newScheduling.ClienteRecebimentoId = receivementDB[0].id;
          info = await createScheduling(client.hash, newScheduling);
        }
      }

      await cliente.put(client);
      eventEmitter.notifyAll("addReceivement", info.clienteHash);

      return receivementDB[0].hash;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <ReceivementContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </ReceivementContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { ReceivementContext, ReceivementContextProvider };
