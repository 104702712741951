import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  root: {
    paddingBottom: "40px",
    marginTop: "5vh",
  },
  exchanges: {
    backgroundColor: "#32CD32",
  },
  containerText: {
    border: "1px solid grey",
    minHeight: "70vh",
    overflow: "scroll",
  },
  buttons: {
    paddingTop: "10px",
    paddingBottom: "20px",
  },
});

/**
 * Componente onde é exibido o relatório diário ou relatório de trocas.
 */
class CardDays extends React.Component {
  /**
   * @param {boolean} props.showButtonExchange Boleano que definirá a estrutura do componente a ser exibida.
   * @param {funcion} props.toggleExchange Função que mudará e exibição de componentes do elemento pai
   */
  render() {
    let { classes } = this.props;
    return (
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={10}>
          <Grid container className={classes.containerText} justify={"center"}>
            {this.props.children}
          </Grid>
          {this.props.showButtonExchange ? (
            <Grid container justify="space-between" className={classes.buttons}>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.props.toggleExchange}
              >
                TROCAS
              </Button>
              <Button variant="outlined" color="primary">
                IMPRIMIR
              </Button>
            </Grid>
          ) : (
            <Grid container justify="flex-end" className={classes.buttons}>
              <Button variant="outlined" color="primary">
                IMPRIMIR
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(CardDays);
