import React from "react";
import { Grid, Typography, withStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GreenButton from "./GreenButton";

const styles = (theme) => ({
  button_back: {
    paddingRight: "7px",
    "& > div": {
      height: "12vw",
      backgroundColor: "#DCDCDC",
    },
    "& svg": {
      height: "10vw",
      top: "50%",
    },
  },
  button_next: {
    "& > div": {
      height: "12vw",
    },
  },
});

/**
 * Componente que renderiza 2 botões, normalmente sendo eles botão de voltar e ir para o próximo.
 */
class BackNextButton extends React.Component {
  /**
   * @param {function} props.onClickGoBack Função responsável por voltar para a cena anterior.
   * @param {function} props.onClickGoNext Função responsável por ir para a cena seguinte.
   * @param {string} props.this.props.text2  Texto do segundo botão
   * @param {object} props.classes Classes do css vindas do withStyles
   */
  constructor() {
    super();
  }

  render() {
    let { classes } = this.props;
    return (
      <Grid container justify="center" className={classes.buttons}>
        <Grid item xs={11}>
          <Grid container>
            <Grid item xs={2} className={classes.button_back}>
              <GreenButton onClickButton={this.props.onClickGoBack}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  data-testid="back-button"
                >
                  <ArrowBackIcon fontSize="large" />
                </Grid>
              </GreenButton>
            </Grid>
            <Grid item xs={10} className={classes.button_next}>
              <GreenButton onClickButton={this.props.onClickGoNext}>
                {!this.props.text2 ? (
                  <Typography data-testid="next-button">PRÓXIMO</Typography>
                ) : (
                  <Typography data-testid="next-button">
                    {this.props.text2}
                  </Typography>
                )}
              </GreenButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(BackNextButton);
