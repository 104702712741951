import React, { createContext } from "react";
import { Cesta, Produto, CestaItens } from "../repository/models";
import { AbstractContext } from "./AbstractContext";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import Moeda from "../utils/Moeda";

const cesta = new Cesta();
const produto = new Produto();
const cestaItens = new CestaItens();

const ProductContext = createContext({
  products: [],
});

const OBSERVED_MODELS = ["Produto", "Cesta", "CestaItens"];
class ProductContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.setStoredValues = this.setStoredValues.bind(this);
    this.observable = this.observable.bind(this);
    this.updateSelf = this.updateSelf.bind(this);
    this.createBasket = this.createBasket.bind(this);
    this.updateBasket = this.updateBasket.bind(this);
    this.deleteBasket = this.deleteBasket.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.getBasketById = this.getBasketById.bind(this);
    this.getBasketByHash = this.getBasketByHash.bind(this);
    this.getItemByHash = this.getItemByHash.bind(this);
    this.state = {
      isLoading: true,
      storedBaskets: [],
      storedBasketsItems: [],
      storedItems: [],

      getAllBaskets: this.getAllBaskets,
      getAllBasketsItems: this.getAllBasketsItems,
      getAllItems: this.getAllItems,

      getBasketById: this.getBasketById,
      getItemByHash: this.getItemByHash,

      createBasket: this.createBasket,
      updateBasket: this.updateBasket,
      deleteBasket: this.deleteBasket,

      saveItem: this.saveItem,
      deleteItem: this.deleteItem,
    };
  }

  async setStoredValues() {
    this.setState({
      storedBaskets: await this.getAllBaskets(),
      storedBasketsItems: await this.getAllBasketsItems(),
      storedItems: await this.getAllItems(),
      isLoading: false,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.setStoredValues();
  }

  async observable(eventName, changedModels) {
    await this.setStoredValues();
  }

  async getAllBaskets() {
    return (await cesta.getAll()).filter((a) => a.ativo);
  }

  async getAllItems() {
    return await produto.filter((a) => a.ativo);
  }

  async getAllBasketsItems() {
    return (await cestaItens.getAll()).filter((a) => a.ativo);
  }

  async updateSelf(modelName) {
    if (modelName == "storedItems") {
      this.setState({
        storedItems: await this.getAllItems(),
      });
    }
    if (modelName == "storedBaskets") {
      this.setState({
        storedBaskets: await this.getAllBaskets(),
      });
    }
    if (modelName == "storedBasketsItems") {
      this.setState({
        storedBasketsItems: await this.getAllBasketsItems(),
      });
    }
  }

  async createBasket(basketData, isItemBasket = false) {
    try {
      let {
        name: nome,
        salePrice: valor,
        maxDiscount: maxDesconto,
        items: itens,
        costValue,
        ideal: contarIdeal,
      } = basketData;

      let cestaItemsToCreate = [];

      if (isItemBasket) {
        nome = basketData.identificador;
        valor = basketData.valor;
        itens = [];
      }

      if (!costValue) costValue = null;

      let newCesta_databaseId = await cesta.create({
        nome,
        valor,
        maxDesconto,
        contarIdeal,
        costValue,
        ativo: true,
        shouldSync: true,
      });

      let newBasket = await this.getAllBaskets();
      newBasket = newBasket.find(
        (cesta) => cesta.DATABASE_ID == newCesta_databaseId
      );

      itens.forEach((item) => {
        item.CestumId = newBasket.id;
      });

      if (isItemBasket) {
        let newProduct = {
          shouldSync: true,
          ativo: true,
          identificador: String(nome),
          valor: Moeda.create(valor).mount(),
          descricao: "",
        };
        let item = await produto.create(newProduct);
        const productId = (
          await produto.filter((prod) => prod.ativo && prod.DATABASE_ID == item)
        )[0].id;

        cestaItemsToCreate = [
          {
            quantidade: 1,
            CestumId: parseInt(newBasket.id),
            ProdutoId: parseInt(productId),
            shouldSync: true,
            ativo: true,
          },
        ];
      } else {
        cestaItemsToCreate = itens.map((cestaItem) => {
          return {
            quantidade: cestaItem.quantidade,
            CestumId: parseInt(cestaItem.CestumId),
            ProdutoId: parseInt(cestaItem.product.id),
            shouldSync: true,
            ativo: true,
          };
        });
      }

      for (const item of cestaItemsToCreate) {
        await cestaItens.create(item);
      }

      await this.context.addAsyncDialog({
        message: "Produto salvo com sucesso!",
        title: "Concluido",
        type: "success",
        hasCloseButton: false,
        okText: "OK",
      });

      await this.setStoredValues();

      return this.state.storedBaskets.find(
        (basket) => basket.DATABASE_ID == newBasket.DATABASE_ID
      );
    } catch (e) {
      await this.context.addAsyncDialog({
        message:
          "Tivemos um problema ao salvar os seus dados, caso ocorra com frequencia, procure o suporte",
        title: "Oops, houve um problema",
        type: "error",
        hasCloseButton: false,
      });
    } finally {
      await this.setStoredValues();
    }
  }

  async updateBasket(basket, isItemBasket = false) {
    try {
      let original = await this.getBasketById(basket.id);
      let { items, removedItems } = basket;
      const cestaItemsToCreate = items
        .filter((cestaItem) => cestaItem.created)
        .map((cestaItem) => {
          return {
            quantidade: cestaItem.quantidade,
            CestumId: parseInt(cestaItem.CestumId),
            ProdutoId: parseInt(cestaItem.product.id),
            shouldSync: true,
            ativo: true,
          };
        });

      let cestaItemsToEdit = items
        .filter((cestaItem) => cestaItem.edited && !cestaItem.created)
        .map((cestaItem) => {
          return {
            hash: cestaItem.hash,
            id: cestaItem.id,
            DATABASE_ID: cestaItem.DATABASE_ID,
            quantidade: cestaItem.quantidade,
            CestumId: parseInt(cestaItem.CestumId),
            ProdutoId: parseInt(cestaItem.product.id),
            shouldSync: true,
            ativo: true,
          };
        });

      const cestaItemsToRemove = removedItems.map((cestaItem) => {
        return {
          hash: cestaItem.hash,
          id: cestaItem.id,
          DATABASE_ID: cestaItem.DATABASE_ID,
          quantidade: cestaItem.quantidade,
          CestumId: parseInt(cestaItem.CestumId),
          ProdutoId: parseInt(cestaItem.product.id),
          shouldSync: true,
          ativo: false,
        };
      });

      let {
        name: nome,
        salePrice: valor,
        maxDiscount: maxDesconto,
        costValue,
        ideal: contarIdeal,
      } = basket;

      if (isItemBasket) {
        nome = basket.identificador;
        valor = basket.valor;
        cestaItemsToEdit = [
          {
            hash: basket.hash,
            id: basket.id,
            DATABASE_ID: basket.DATABASE_ID,
            quantidade: basket.quantidade,
            CestumId: parseInt(basket.CestumId),
            ProdutoId: parseInt(basket.product.id),
            shouldSync: true,
            ativo: true,
          },
        ];
      }

      for (const item of cestaItemsToCreate) {
        await cestaItens.create(item);
      }
      for (const item of cestaItemsToEdit) {
        await cestaItens.put(item);
      }
      for (const item of cestaItemsToRemove) {
        await cestaItens.put(item);
      }
      delete basket.items;

      const basketMerge = Object.assign(original, {
        nome,
        valor,
        maxDesconto,
        costValue,
        contarIdeal,
        shouldSync: true,
      });
      delete basketMerge.itens;
      delete basketMerge.createdAt;
      delete basketMerge.updatedAt;
      await cesta.put(basketMerge);

      await this.context.addAsyncDialog({
        message: "Produto salvo com sucesso!",
        title: "Concluido",
        type: "success",
        hasCloseButton: false,
        okText: "OK",
      });
    } catch (e) {
      console.log(e);
      await this.context.addAsyncDialog({
        message:
          "Tivemos um problema ao salvar os seus dados, caso ocorra com frequencia, procure o suporte",
        title: "Oops, houve um problema",
        type: "error",
        hasCloseButton: false,
      });
    } finally {
      await this.setStoredValues();
    }
  }

  async deleteBasket(basketHash) {
    try {
      let originalBasket = await this.getBasketByHash(basketHash);
      if (originalBasket) {
        originalBasket.ativo = false;
        originalBasket.shouldSync = true;
        cesta.put(originalBasket);
      }
      await this.context.addAsyncDialog({
        message: "Produto deletado com sucesso!",
        title: "Concluido",
        type: "success",
        hasCloseButton: false,
        okText: "OK",
      });

      this.setStoredValues();
      return true;
    } catch (e) {
      console.log(e);
      await this.context.addAsyncDialog({
        message:
          "Tivemos um problema ao salvar os seus dados, caso ocorra com frequencia, procure o suporte",
        title: "Oops, houve um problema",
        type: "error",
        hasCloseButton: false,
      });
      return false;
    } finally {
      await this.setStoredValues();
    }
  }

  async saveItem(itemData) {
    let itemDatabaseId;
    try {
      // Check whether it's a new or an existing item
      if (itemData.hash == -1) {
        let newProduct = {
          shouldSync: true,
          ativo: true,
          identificador: String(itemData.identificador),
          valor: Moeda.create(itemData.valor).mount(),
          descricao: "",
        };
        itemDatabaseId = await produto.create(newProduct);
      } else {
        await produto.put({
          identificador: itemData.identificador,
          valor: itemData.valor,
          DATABASE_ID: itemData.databaseId,
          hash: itemData.hash,
          id: itemData.id,
          shouldSync: true,
          ativo: true,
        });
        itemDatabaseId = itemData.databaseId;
      }
      this.context.addDialog({
        title: `Item ${itemData.hash == -1 ? "criado" : "salvo"} com sucesso!`,
        message: `Este item foi ${
          itemData.hash == -1 ? "criado" : "salvo"
        } com sucesso`,
        type: "success",
        hasCloseButton: false,
      });
      await this.setStoredValues();
      return this.state.storedItems.find(
        (i) => i.DATABASE_ID == itemDatabaseId
      );
    } catch (e) {
      await this.context.addAsyncDialog({
        title: "Oops, houve um problema",
        message:
          "Tivemos um problema ao salvar esse item, caso ocorra com frequencia, procure o suporte",
        type: "error",
        hasCloseButton: false,
      });
      return false;
    }
  }

  async deleteItem(databaseId) {
    try {
      let databaseItem = await this.getAllItems();
      databaseItem = databaseItem.find(
        (item) => item.DATABASE_ID == databaseId
      );
      databaseItem.shouldSync = true;
      databaseItem.ativo = false;

      // Check whether it's a new or an existing item
      produto.put(databaseItem);

      this.context.addDialog({
        title: `Item excluído`,
        message: `O item foi deletado do sistema com sucesso!`,
        type: "success",
        hasCloseButton: false,
      });
      return true;
    } catch (e) {
      await this.context.addAsyncDialog({
        title: "Oops, houve um problema",
        message:
          "Tivemos um problema ao salvar esse item, caso ocorra com frequencia, procure o suporte",
        type: "error",
        hasCloseButton: false,
      });
      return false;
    } finally {
      this.setStoredValues();
    }
  }

  popAlert(msgData) {
    this.context.addDialog(msgData);
  }

  async getBasketById(id) {
    let basket = await this.getAllBaskets();
    basket = basket.find((c) => c.id == id);
    return { ...basket };
  }

  async getBasketByHash(hash) {
    let basket = await this.getAllBaskets();
    basket = basket.find((c) => c.hash == hash);
    return { ...basket };
  }

  async getItemByHash(hash) {
    let item = await this.getAllItems();
    item = item.find((c) => c.hash == hash);
    return { ...item };
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <ProductContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </ProductContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { ProductContext, ProductContextProvider };
