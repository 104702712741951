import React from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  Button,
  withStyles,
} from "@material-ui/core";
import SearchBar from "../SearchBar/SearchBar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import RemoveIcon from "@material-ui/icons/Remove";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({});

class AllItemsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Structure of this.props.allItems MUST BE as follows
      // allItems: [
      //    {
      //     item:{ ...itemData},
      //     quantidade: INT
      //    },
      //    ...
      // ]
      loading: true,
      allItems: [],
      filteredItems: [],
      word: "",
    };
    this.handleCreateNewItemClick = this.handleCreateNewItemClick.bind(this);
    this.handleSearchItems = this.handleSearchItems.bind(this);
    this.handleAddItemClick = this.handleAddItemClick.bind(this);
    this.handeRemoveItemClick = this.handeRemoveItemClick.bind(this);
  }

  componentDidMount() {
    this.setState({ filteredItems: this.props.allItems || [] });
  }

  componentDidUpdate(oldProps, oldState) {
    if (this.state == oldState) {
      if (this.props.allItems && this.state.allItems != this.props.allItems) {
        this.setState({
          allItems: this.props.allItems,
          filteredItems:
            this.state.word == ""
              ? this.props.allItems
              : this.state.filteredItems,
        });
      }
    }
    // else{
    //     if(this.state.word != oldState.word){
    //         this.setState({filteredItems: this.props.allItems || []})

    // }
    // }
  }

  handleCreateNewItemClick() {
    if (this.props.onCreateNewItemClick) this.props.onCreateNewItemClick();
  }

  removeAccents(str) {
    let accents =
      "ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
    let accentsOut =
      "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    str = str.split("");
    str.forEach((letter, index) => {
      let i = accents.indexOf(letter);
      if (i != -1) {
        str[index] = accentsOut[i];
      }
    });
    return str.join("").toLowerCase();
  }

  handleSearchItems(searchWord) {
    let searchAllProducts = this.state.allItems;
    if (searchWord != "") {
      searchAllProducts = searchAllProducts.filter((a) => {
        if (
          a.item &&
          this.removeAccents(
            a.item["nome"] || a.item["identificador"] || a.item["name"]
          ).includes(this.removeAccents(searchWord))
        ) {
          return true;
        }
        return false;
      });
      this.setState({ filteredItems: searchAllProducts, word: searchWord });
    } else {
      this.setState({ filteredItems: searchAllProducts, word: searchWord });
    }
    // this.props.onSearch(searchWord)
  }

  handleAddItemClick(prod) {
    this.props.onAddItemclick(prod);
  }

  handeRemoveItemClick(prod) {
    this.props.onRemoveItemClick(prod);
  }

  render() {
    return (
      <Dialog
        {...this.props.fullScreen}
        onClose={this.props.onClose}
        fullWidth
        open={this.props.open}
      >
        <DialogTitle id="customized-dialog-title">
          <Grid container justify="space-between" direction="row">
            <Grid item>{this.props.dialogTitle}</Grid>
            <Grid item>
              {this.props.canCreateNew ? (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  classes={{
                    startIcon:
                      this.props.classes &&
                      this.props.classes.addNewItemButtonIcon,
                  }}
                  startIcon={
                    this.props.addItemBtnProps &&
                    this.props.addItemBtnProps.startIcon
                  }
                  style={{ fontSize: ".75rem" }}
                  onClick={this.handleCreateNewItemClick}
                >
                  {this.props.createNewItemBtnText
                    ? this.props.createNewItemBtnText
                    : "Criar item"}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers style={{ padding: "0px" }}>
          <SearchBar getInput={this.handleSearchItems} />
          <List>
            {this.props.loading ? (
              <Grid
                item
                container
                justify="center"
                style={{ padding: "15px 0" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              this.state.filteredItems.map((itemObjt, index) => {
                return (
                  <ListItem
                    key={index}
                    disableGutters={this.props.disableGutters}
                    divider
                  >
                    <Grid
                      container
                      justify="space-between"
                      direction="row"
                      wrap="nowrap"
                    >
                      <Grid item style={{ overflow: "hidden" }}>
                        <Typography
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {itemObjt.item.identificador ||
                            itemObjt.item.nome ||
                            itemObjt.item.name}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Grid
                          container
                          wrap="nowrap"
                          alignItems="center"
                          justify="flex-end"
                        >
                          <Grid
                            item
                            container
                            alignItems="center"
                            direction="row"
                            wrap="nowrap"
                          >
                            {itemObjt.quantidade > 0 ? (
                              <>
                                <Grid item style={{ color: "#999" }}>
                                  <RemoveIcon
                                    onClick={() =>
                                      this.handeRemoveItemClick(itemObjt)
                                    }
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    style={{
                                      color: "#999",
                                      fontSize: ".9rem",
                                      padding: "0 10px",
                                    }}
                                  >
                                    {itemObjt.quantidade}
                                  </Typography>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              disableElevation
                              style={{
                                maxHeight: "30px",
                                lineHeight: "inherit",
                                fontSize: ".7rem",
                                background: itemObjt.quantidade
                                  ? "rgba(60, 130, 241, 100%)"
                                  : "rgba(60, 130, 241, 65%)",
                              }}
                              onClick={() => this.handleAddItemClick(itemObjt)}
                            >
                              Adicionar
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Grid item onClick={this.props.onClose}>
            <Button color="primary">FECHAR</Button>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AllItemsDialog);
