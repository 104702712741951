import React from "react";
import HeaderMenu from "../../components/HeaderForAdd/HeaderMenu";
import { withStyles } from "@material-ui/core";
import CardSwitch from "../../components/CardComponents/CardSwitch/Card";
import CardSelect from "../../components/CardComponents/CardSelect/Card";
import { ConfigContext } from "../../contexts/ConfigContext";
import BackdropLoader from "../../components/Dialogs/BackdropLoader";

const styles = (theme) => ({
  text: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "1.1rem",
  },
  root: {
    height: "100%",
    backgroundColor: "#F5F5F5",
  },
  card: {
    backgroundColor: "#fff",
    position: "relative",
    top: "15px",
  },
});

/**
 * Tela de configuração.
 */
class Configuration extends React.Component {
  /**
     * @property {boolean} this.state.OnlineOffline Boleano que indica se o botão da opção de ficar OnlineOffline está ativado
     * @property {boolean} this.state.AutoSync  Boleano que indica se o botão da opção de AutoSync está ativado
     * @property {boolean} this.state.AutoSync_disable  Boleano que desabilita o botão de AutoSync caso seja true
     * @param {object} props.classes Objeto com todas as classes do CSS

    */
  constructor() {
    super();
    this.state = {
      AutoSync: true,
      isLoading: true,
      downloadFotos: true,
      OnlineOffline: true,
      ClientBlockSize: 50,
      isDragEnableOnClientCard: false,
      AutoSync_disable: false,
      EnableTransitions: true,
      DownloadType: true,
      DownloadMovementMonths: 2,
      closingReport: true,
      togglePersist: true,
      isOnline: navigator.onLine,
    };

    this.getConfigData = this.getConfigData.bind(this);
    this.handleToggleOnline = this.handleToggleOnline.bind(this);
    this.handleToggleSync = this.handleToggleSync.bind(this);
    this.handleToggleTransitions = this.handleToggleTransitions.bind(this);
    this.handleToggleDownloadType = this.handleToggleDownloadType.bind(this);
    this.handleToggleJumpAndRepass = this.handleToggleJumpAndRepass.bind(this);
    this.handleSelectMovsMonths = this.handleSelectMovsMonths.bind(this);
    this.handleSelectClientBlockSize =
      this.handleSelectClientBlockSize.bind(this);
    this.handleToggleFotoDownload = this.handleToggleFotoDownload.bind(this);
    this.handleToggleClosingReport = this.handleToggleClosingReport.bind(this);
    this.handleTogglePersist = this.handleTogglePersist.bind(this);
  }

  componentDidMount() {
    this.getConfigData();

    window.addEventListener("online", this.handleOnlineStatus);
    window.addEventListener("offline", this.handleOnlineStatus);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.handleOnlineStatus);
    window.removeEventListener("offline", this.handleOnlineStatus);
  }

  handleOnlineStatus = () => {
    this.setState({ isOnline: navigator.onLine });
  };

  async getConfigData() {
    let config = await this.context.getConfigData();
    this.setState({ ...config, isLoading: false });
  }

  async saveConfigSequential(keys, values) {
    for (let i = 0; i < keys.length; i++) {
      await this.saveConfig(keys[i], values[i]);
    }
  }

  async saveConfig(key, value) {
    if (this.state.isOnline) {
      await this.context.onClickSaveConfig(key, value);
    } else {
      console.log(
        "O aplicativo está offline. Os dados serão salvos apenas no dispositivo."
      );
    }
  }

  handleStateChange;

  /**Função que altera o estado do botão OnlineOffline.
   * @function
   * @param {event} e Evento passado para função gerando pelo onChange
   */
  async handleToggleOnline(e) {
    const newOnlineOfflineValue = e.target.checked;

    await this.setState({ OnlineOffline: newOnlineOfflineValue });

    if (!this.state.isOnline && newOnlineOfflineValue) {
      alert(
        "Não é possível ficar online enquanto estiver offline. Verifique sua conexão com a internet."
      );
      await this.setState({ OnlineOffline: false });
      return;
    }

    if (this.state.isOnline) {
      if (newOnlineOfflineValue === false) {
        await this.setState({ AutoSync_disable: true });
        await this.saveConfigSequential(["OnlineOffline", "AutoSync"], [0, 0]);
      } else {
        await this.setState({ AutoSync_disable: false });
        await this.saveConfigSequential(["OnlineOffline", "AutoSync"], [1, 1]);
      }

      console.log("OnlineOffline:", this.state.OnlineOffline);
      console.log("AutoSync_disable:", this.state.AutoSync_disable);
      console.log("AutoSync:", this.state.AutoSync);
    }
  }

  async handleSaveConfigOfflineMode(value) {
    await this.context.onClickSaveConfig("OnlineOffline", value);
    await this.context.onClickSaveConfig("AutoSync", value);
  }

  async handleSaveConfigOnlineMode(value) {
    await this.context.onClickSaveConfig("OnlineOffline", value);
    await this.context.onClickSaveConfig("AutoSync", value);
  }

  /**Função que altera o estado do botão de AutoSync.
   * @function
   * @param {event} e Evento passado para função gerando pelo onChange
   */
  async handleToggleSync(e) {
    this.setState({ AutoSync: e.target.checked });
    await this.context.onClickSaveConfig(
      "OnlineOffline",
      this.state.OnlineOffline ? 1 : 0
    );
    await this.context.onClickSaveConfig(
      "AutoSync",
      this.state.AutoSync ? 1 : 0
    );
  }

  /**Função que salva em memória a opção escolhida pelo usuário sobre as transitions da aplicação.
   * @function
   * @param {event} e Evento passado para função gerando pelo onChange
   */
  async handleToggleTransitions(e) {
    this.setState(
      { EnableTransitions: e.target.checked },
      async () =>
        await this.context.onClickSaveConfig(
          "EnableTransitions",
          this.state.EnableTransitions ? 1 : 0
        )
    );
  }

  /**Função que salva em memória a opção escolhida pelo usuário sobre o tipo de dowload dos clientes.
   * @function
   * @param {event} e Evento passado para função gerando pelo onChange
   */
  async handleToggleDownloadType(e) {
    const downloadType = e.target.checked ? "complete" : "partial";

    this.setState({ DownloadType: downloadType });
    await this.context.onClickSaveConfig("DownloadType", downloadType);
  }

  async handleToggleJumpAndRepass(e) {
    this.setState({ isDragEnableOnClientCard: e.target.checked });
    await this.context.onClickSaveConfig(
      "isDragEnableOnClientCard",
      e.target.checked
    );
  }

  async handleSelectMovsMonths(e) {
    this.setState({ DownloadMovementMonths: e.target.value });
    await this.context.onClickSaveConfig(
      "DownloadMovementMonths",
      e.target.value
    );
  }

  async handleSelectClientBlockSize(e) {
    this.setState({ ClientBlockSize: e.target.value });
    await this.context.onClickSaveConfig("ClientBlockSize", e.target.value);
  }

  async handleToggleFotoDownload(e) {
    this.setState({ downloadFotos: e.target.checked });
    await this.context.onClickSaveConfig("downloadFotos", e.target.checked);
  }

  async handleToggleClosingReport(e) {
    this.setState({ closingReport: e.target.checked });
    await this.context.onClickSaveConfig("closingReport", e.target.checked);
  }

  async handleTogglePersist(e) {
    this.setState({ togglePersist: e.target.checked });
    await this.context.onClickSaveConfig("togglePersist", e.target.checked);
  }

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <HeaderMenu>Configurações</HeaderMenu>
        <BackdropLoader
          open={this.state.isLoading}
          secondaryText="Estamos carregando suas configurações"
        />
        <CardSwitch
          title="Deseja trabalhar online"
          helperText={
            "Essa configuração ativa a possibilidade de cada ação ser enviada ao servidor, melhorando a segurança do sistema, assim cada ação fica registrada em um local seguro. Caso desmarcada, há uma melhora no desempenho do uso porém nada será enviado ao servidor."
          }
          checked={!!this.state.OnlineOffline}
          func={this.handleToggleOnline}
        />
        {/* <CardSwitch
          title="Sincronização automática"
          helperText={
            "Caso ligado, o aplicativo verifica-rá a todo o momento por possíveis alterações em outros aparelhos e fará a sincronização quando contectado a alguma rede."
          }
          disabled={this.state.AutoSync_disable}
          checked={!!this.state.AutoSync}
          func={this.handleToggleSync}
        /> */}
        <CardSwitch
          title="Habilitar transições"
          helperText="Permite que você habilite ou desabilite todas as transições e animações do aplicativo."
          checked={!!this.state.EnableTransitions}
          func={this.handleToggleTransitions}
        />
        <CardSwitch
          title="Baixar todos os clientes"
          helperText="Caso desabilitado, ao realizar uma sincronização, o sistema buscará do servidor central somente os clientes que tiveram alguma alteração."
          checked={this.state.DownloadType === "complete"}
          func={this.handleToggleDownloadType}
        />
        <CardSwitch
          title="Baixar fotos de clientes"
          helperText="Caso desabilitado o download das fotos do cliente não será realizado."
          checked={this.state.downloadFotos}
          func={this.handleToggleFotoDownload}
        />
        <CardSwitch
          title="Pular e repassar com arrasto"
          helperText="Caso habilitado é possivel pular e repassar os clientes do dia somente com arrasto na ficha do cliente."
          checked={!!this.state.isDragEnableOnClientCard}
          func={this.handleToggleJumpAndRepass}
        />
        <CardSwitch
          title="Gerar relatório de fechamento pelo servidor"
          helperText="Caso habilitado o relatório final de fechamento de rota será gerado pelo servidor e não pelo aplicativo"
          checked={!!this.state.closingReport}
          func={this.handleToggleClosingReport}
        />
        <CardSwitch
          title="Filtro persistente"
          helperText="Caso habilitado, o filtro irá persistir mesmo se abrir e fechar rota. Caso queira limpar o filtro a cada abertura, mantenha o filtro desativado"
          checked={!!this.state.togglePersist}
          func={this.handleTogglePersist}
        />
        <CardSelect
          title="Buscar movimentações"
          helperText="Selecione a quantidade de meses para trás que deseja buscar as movimentações."
          values={[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]}
          value={this.state.DownloadMovementMonths}
          func={this.handleSelectMovsMonths}
        />
        <CardSelect
          title="Bloco de clientes"
          helperText="Selecione o tamanho do bloco de clientes a buscar do servidor."
          values={[50, 20, 15, 10, 5]}
          value={this.state.ClientBlockSize}
          func={this.handleSelectClientBlockSize}
        />
      </div>
    );
  }
}
Configuration.contextType = ConfigContext;
export default withStyles(styles)(Configuration);
