import LocalStorage from "../utils/localStorage";
import ServiceNotification from "./serviceNotification";
import ConnectionErrorNotFound from "../errorDefinition/ConnectionErrorNotFound";
import ConnectionErrorFetchFaild from "../errorDefinition/ConnectionErrorFetchFaild";
import ConnectionErrorTimeOut from "../errorDefinition/ConnectionErrorTimeOut";
import ConnectionErrorServerSide from "../errorDefinition/ConnectionErrorServerSide";

export default class Abstract {
  constructor(serviceNotification) {
    this.localStorage = LocalStorage.instance;
    this.serviceNotification =
      serviceNotification || ServiceNotification.instance;
  }

  async httpGet(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          throw new ConnectionErrorNotFound({ url });
        } else if (response.status == 504) {
          throw new ConnectionErrorTimeOut({ url });
        } else {
          throw new ConnectionErrorServerSide({ url });
        }
      }
      return response;
    } catch (error) {
      if (error.message == "Failed to fetch")
        throw new ConnectionErrorFetchFaild({ url });
    }
  }

  async httpPost(url, options) {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          throw new ConnectionErrorNotFound({ url });
        } else if (response.status == 504) {
          throw new ConnectionErrorTimeOut({ url });
        } else {
          throw new ConnectionErrorServerSide({ url });
        }
      }
      return response;
    } catch (error) {
      if (error.message == "Failed to fetch")
        throw new ConnectionErrorFetchFaild({ url });
    }
  }
}

export async function fetchPollyfill(url, options) {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      if (response.status >= 400 && response.status < 500) {
        throw new ConnectionErrorNotFound({ url });
      } else if (response.status == 504) {
        throw new ConnectionErrorTimeOut({ url });
      } else {
        throw new ConnectionErrorServerSide({ url });
      }
    }
    return response;
  } catch (error) {
    if (error.message == "Failed to fetch")
      throw new ConnectionErrorFetchFaild({ url });
  }
}
