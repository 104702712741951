export async function getAllClientData(clientId) {
  const Cliente = window.DATABASE.Cliente;
  const associatedModels = [
    window.DATABASE.ClienteAgendamento,
    window.DATABASE.ClienteVenda,
    window.DATABASE.ClienteRecebimento,
    window.DATABASE.ClienteDocumento,
    window.DATABASE.ClienteTelefone,
  ];

  async function getAssociatedInfo(model, propName, id) {
    const data = await model.where(propName).equals(id).toArray();
    return {
      value: data,
      model: model.name,
    };
  }

  const proms = associatedModels.map((model) =>
    getAssociatedInfo(model, "ClienteId", clientId)
  );
  proms.push(getAssociatedInfo(Cliente, "id", clientId));
  const info = await Promise.all(proms);

  return info.reduce((memo, el) => {
    memo[el.model] = el.value;
    return memo;
  }, {});
}
