import DataAccess from "../DataAccessStrategy";
import Produto from "./Produto";
export default class Cesta extends DataAccess {
  constructor() {
    super("Cesta", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      nome: String,
      valor: String,
      maxDesconto: String,
    });
  }

  async getAll() {
    const produto = new Produto();
    const cestas = await this.model.toArray();
    const proms = cestas.map(async (cesta) => {
      const products = await produto.getProductsByBasket(cesta.id);

      cesta.itens = products;
      return cesta;
    });
    return await Promise.all(proms);
  }
}
