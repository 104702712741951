import {
  AppBar,
  Backdrop,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import PropTypes from "prop-types";
import qrcode from "qrcode";
import React from "react";
import { Link } from "react-router-dom";
import FormAddClient from "../../components/Client/ClientForm";
import PictureGetter from "../../components/Client/PictureGetter";
import BackdropLoader from "../../components/Dialogs/BackdropLoader";
import Exchanges from "../../components/Exchanges/ViewClient/Exchanges";
import NeighborhoodForm from "../../components/Forms/NeighborhoodForm";
import Header from "../../components/HeaderForAdd/Header";
import Movements from "../../components/Movements/Movements";
import AddPictures from "../../components/Pictures/AddPictures";
import Scheduling from "../../components/Scheduling/ViewClient/Scheduling";
import Signature from "../../components/Signature/Signature";
import { ClientContext } from "../../contexts/ClientContext";
import { MovementsContext } from "../../contexts/MovementsContext";
import { NeighborhoodContext } from "../../contexts/NeighborhoodContext";
import { PrinterContext } from "../../contexts/PrinterContext";
import { SchedulingsContext } from "../../contexts/SchedulingsContext";
import history from "../../router/History";
import { getCompany } from "../../service/authentication";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
  tabs: {
    backgroundColor: "#fff",
  },
  hidden: {
    display: "none",
  },
  appBar: {
    boxShadow: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    "& p": {
      color: theme.palette.text.secondary,
      fontSize: "1.8rem",
    },
  },
  fixedClass: {
    position: "fixed",
  },
});

class TabPanel extends React.Component {
  constructor() {
    super();
  }
  render() {
    const { children, value, index, disabled, ...other } = this.props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        //   hidden={mapListTypeToIndex(value) !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {mapListTypeToIndex(value) === index && !disabled && (
          <Box>{children}</Box>
        )}
      </Typography>
    );
  }
}
TabPanel.defaultProps = {
  disabled: false,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function mapListTypeToIndex(type) {
  const options = {
    data: 0,
    schedules: 1,
    exchanges: 2,
    movements: 3,
    default: 0,
  };

  const tabIndex = options[type] || options.default;
  return tabIndex;
}

/**
 * Tela de ver cliente.
 */
class ViewClient extends React.Component {
  constructor() {
    super();
    this.state = {
      header: true,
      signature: false,
      sigImage: null,
      image: null,
      client: null,
      neighborhoodFormIsOpen: false,
      clientAddress: null,
      isLoading: false,
      routeDialogIsOpen: false,
    };

    this.handleSignatureImage = this.handleSignatureImage.bind(this);
    this.saveClient = this.saveClient.bind(this);
    this.getClientData = this.getClientData.bind(this);
    this.onUploadImage = this.onUploadImage.bind(this);
    this.a11yProps = this.a11yProps.bind(this);
    this.handleToggleHeader = this.handleToggleHeader.bind(this);
    this.handleToggleSignature = this.handleToggleSignature.bind(this);
    this.handleToggleNeighborhoodForm =
      this.handleToggleNeighborhoodForm.bind(this);
    this.onQrCodeClick = this.onQrCodeClick.bind(this);
  }

  handleSignatureImage(signatureB64) {
    this.setState({
      sigImage: signatureB64,
    });
  }

  // handleToggleRoute() {
  //   this.setState({ routeDialogIsOpen: !this.state.routeDialogIsOpen });
  // }

  /**Função irá  salvar os dados do novo cliente no banco.
   * @function
   * @param {object} data Dados para criação do cliente
   */
  async saveClient(data) {
    try {
      this.setState({ isLoading: true });
      await this.context.updateClient(data);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getClientData(hash) {
    try {
      let clientData = await this.context.getClientById(hash);

      this.setState({ client: clientData }, () => {
        if (this.state.client.assinatura) {
          this.handleSignatureImage(this.state.client.assinatura);
          return;
        }
        this.onUploadImage(this.state.client.foto, false);
      });
    } catch (error) {
      history.push("/");
    }
  }

  /**Função irá armazenar a imagem de perfil do cliente.
   * @function
   * @param {string} imageBASE64 Imagem em base64
   */
  onUploadImage(imageBASE64, shouldUploadFoto = true) {
    this.setState({ image: imageBASE64, shouldUploadFoto });
  }

  /**Função acionada na montagem deste componente onde indicará qual abra irá abrir, pela informação passsada pela URL.
   * @function
   */
  componentDidMount() {
    this.getClientData(this.props.match.params.hash);
  }

  a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  /**Função alterna a exibição do header da tela.
   * @function
   */
  handleToggleHeader() {
    this.setState({ header: !this.state.header });
  }

  handleToggleSignature() {
    this.setState({ signatureIsOpen: !this.state.signatureIsOpen });
  }

  handleToggleNeighborhoodForm(clientAddress) {
    this.setState({
      neighborhoodFormIsOpen: !this.state.neighborhoodFormIsOpen,
      clientAddress: clientAddress,
    });
  }

  async onQrCodeClick() {
    let company = await getCompany();
    const clienteId = this.state.client.id;
    const dataEmpresa = {
      link: company.link,
      identificador: company.identificador,
    };

    const link = `https://app-cliente.maximussolucoes.com.br/?clienteId=${clienteId}&link=${dataEmpresa.link}&empresa=${dataEmpresa.identificador}#!/`;
    const base64QR = await qrcode.toDataURL(link);

    const dialogRenderOptions = {
      disableClientButton: true,
      content: base64QR,
      viaEmployee: base64QR,
      headerMainText: "QR Code",
      title: "QR Code do cliente",
    };
    (await this.printerContext) &&
      this.printerContext.callPrinterComponent(dialogRenderOptions);
  }

  render() {
    let { classes } = this.props;

    let classDisplayHeader = clsx({
      [classes.tabs]: true,
      [classes.hidden]: !this.state.header,
      // [classes.fixedClass]: true
    });

    let classRoot = clsx({
      [classes.root]: !this.state.signature,
      [classes.hidden]:
        this.state.signatureIsOpen || this.state.neighborhoodFormIsOpen,
    });
    return (
      <PrinterContext.Consumer>
        {(printerContext) => {
          if (this.printerContext != printerContext)
            this.printerContext = printerContext;
          return (
            <NeighborhoodContext.Consumer>
              {(neighborhood) => {
                return (
                  <>
                    <div className={classRoot}>
                      {this.state.header && (
                        <Header
                          position="fixed"
                          name={this.state.client && this.state.client.nome}
                          icon={<ExpandMoreIcon fontSize="large" />}
                          iconEnd={
                            <i
                              onClick={this.onQrCodeClick}
                              className="fas fa-qrcode"
                              style={{ color: "#333" }}
                            ></i>
                          }
                        >
                          Ver Cliente
                        </Header>
                      )}

                      {/* <Divider /> */}

                      <AppBar
                        color="default"
                        position="relative"
                        className={classes.fixedClass}
                      >
                        <Tabs
                          className={classDisplayHeader}
                          value={mapListTypeToIndex(
                            this.props.match.params.type
                          )}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                        >
                          <Link
                            replace
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={{
                              pathname: `/client/view/data/${this.props.match.params.hash}`,
                              state: { ...this.props.location.state },
                            }}
                          >
                            <Tab label="DADOS" {...this.a11yProps(0)} />
                          </Link>
                          <Link
                            replace
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={{
                              pathname: `/client/view/schedules/${this.props.match.params.hash}`,
                              state: { ...this.props.location.state },
                            }}
                          >
                            <Tab label="AGENDAMENTOS" {...this.a11yProps(1)} />
                          </Link>
                          <Link
                            replace
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={{
                              pathname: `/client/view/exchanges/${this.props.match.params.hash}`,
                              state: { ...this.props.location.state },
                            }}
                          >
                            <Tab label="TROCAS" {...this.a11yProps(2)} />
                          </Link>
                          <Link
                            replace
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={{
                              pathname: `/client/view/movements/${this.props.match.params.hash}`,
                              state: { ...this.props.location.state },
                            }}
                          >
                            <Tab label="MOVIMENTAÇÕES" {...this.a11yProps(3)} />
                          </Link>
                        </Tabs>
                      </AppBar>
                      {
                        <div
                          style={{
                            minHeight: "50px",
                            position: "relative",
                            width: "100%",
                          }}
                        ></div>
                      }

                      <TabPanel value={this.props.match.params.type} index={0}>
                        {
                          !this.state.client ? (
                            <Backdrop className={classes.backdrop} open={true}>
                              <CircularProgress />
                            </Backdrop>
                          ) : (
                            <>
                              <BackdropLoader open={this.state.isLoading} />
                              <PictureGetter id={this.state.client.id}>
                                {(picture) => {
                                  return (
                                    <AddPictures
                                      hash={this.state.client.hash}
                                      id={this.state.client.id}
                                      onUploadImage={this.onUploadImage}
                                      clickDeleteImage={this.clickDeleteImage}
                                      image={this.state.image || picture}
                                    />
                                  );
                                }}
                              </PictureGetter>

                              <FormAddClient
                                handleToggleSignature={
                                  this.handleToggleSignature
                                }
                                signatureImage={this.state.sigImage}
                                neighborhoodContext={neighborhood}
                                saveClient={this.saveClient}
                                shouldUploadFoto={this.state.shouldUploadFoto}
                                image={this.state.image}
                                handleToggleNeighborhoodForm={
                                  this.handleToggleNeighborhoodForm
                                }
                                context={this.context}
                                clientAddress={this.clientAddress}
                                clientData={this.state.client}
                              />
                            </>
                          )

                          // )}}
                        }
                        {/* </NeighborhoodContext.Consumer> */}
                      </TabPanel>

                      <TabPanel value={this.props.match.params.type} index={1}>
                        <SchedulingsContext.Consumer>
                          {(schedulings) => (
                            <Scheduling
                              hash={this.props.match.params.hash}
                              handleToggleHeader={this.handleToggleHeader}
                              schedulingsContext={schedulings}
                              currentBalance={
                                this.state.client
                                  ? this.state.client.saldoAtual
                                  : null
                              }
                            />
                          )}
                        </SchedulingsContext.Consumer>
                      </TabPanel>

                      <TabPanel value={this.props.match.params.type} index={2}>
                        <Exchanges
                          hash={this.props.match.params.hash}
                          handleToggleHeader={this.handleToggleHeader}
                        />
                      </TabPanel>

                      <TabPanel value={this.props.match.params.type} index={3}>
                        <MovementsContext.Consumer>
                          {(movements) => (
                            <Movements
                              hash={this.props.match.params.hash}
                              movementsContext={movements}
                              client={this.state.client || null}
                            />
                          )}
                        </MovementsContext.Consumer>
                      </TabPanel>
                    </div>

                    {this.state.signatureIsOpen ? (
                      <Signature
                        getTrimmedDataURL={this.handleSignatureImage}
                        handleToggleSignature={this.handleToggleSignature}
                      />
                    ) : null}

                    {this.state.neighborhoodFormIsOpen && (
                      <NeighborhoodForm
                        handleToggleNeighborhoodForm={
                          this.handleToggleNeighborhoodForm
                        }
                        neighborhoodContext={neighborhood}
                        formData={this.state.clientAddress}
                      />
                    )}
                  </>
                );
              }}
            </NeighborhoodContext.Consumer>
          );
        }}
      </PrinterContext.Consumer>
    );
  }
}
ViewClient.contextType = ClientContext;
export default withStyles(styles)(ViewClient);
