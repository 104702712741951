import React, { Component, createContext } from "react";
import {
  setLocalConfigVar,
  LOCAL_CONFIG,
  setEnvVar,
  getLocalCongfigVar,
} from "../service/config/index";
import LocalStorage from "../utils/localStorage";
import AppStorageError from "../errorDefinition/AppStorageError";

import { MessageDialogContext } from "../contexts/MessageDialogContext";

const localStorage = LocalStorage.instance;
const ConfigContext = createContext({
  isDragEnableOnClientCard: false,
  isOnline: navigator.onLine,
});

/**
 * Contexto utilizado na pagina de configurações.
 */
class ConfigContextProvider extends Component {
  constructor(props) {
    super(props);
    this.updateSelf = this.updateSelf.bind(this);
    this.onClickSaveConfig = this.onClickSaveConfig.bind(this);
    this.getConfigData = this.getConfigData.bind(this);

    this.state = {
      onClickSaveConfig: this.onClickSaveConfig,
      getConfigData: this.getConfigData,
      isDragEnableOnClientCard: false,
      isOnline: navigator.onLine,
    };
  }

  async updateSelf() {
    const configs = await this.getConfigData();
    this.setState({ ...this.state, ...configs, isOnline: navigator.onLine });
  }

  componentDidMount() {
    this.updateSelf();
    window.addEventListener("online", this.handleOnlineStatus);
    window.addEventListener("offline", this.handleOnlineStatus);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.handleOnlineStatus);
    window.removeEventListener("offline", this.handleOnlineStatus);
  }

  handleOnlineStatus = () => {
    this.setState({ isOnline: navigator.onLine });
  };

  /**Função responsável por desabilitar as transições do App.
   * @function
   * @param {boolean} enable Boleano que indicara o estado a ser setado.
   */
  handleTransitionOnOff(enable) {
    let htmlTag = document.getElementById("html-element");
    if (enable) {
      htmlTag.classList.remove("disableTransitions");
    } else {
      htmlTag.classList.add("disableTransitions");
    }
  }

  /**Função responsável por salvar no localStorage do dexie as configs escolhidas pelo usuário.
   * @function
   * @param {string} key Indicará a propriedade ser alterada.
   * @param {boolean} data Indicará para qual estado a proriedade se tornará.
   */
  async onClickSaveConfig(key, data) {
    try {
      const currentLocalConfig = await getLocalCongfigVar(key);

      if (currentLocalConfig === data) {
        console.log(
          `A configuração ${key} não foi alterada. Nenhum envio necessário.`
        );
        return;
      }

      await setLocalConfigVar(key, data);

      await this.updateSelf();

      if (navigator.onLine) {
        const serverConfigKeys = ["OnlineOffline", "AutoSync"];
        if (serverConfigKeys.includes(key)) {
          console.log(`Salvando ${key} no servidor: ${data}`);

          await setEnvVar(key, data);
        }
      } else {
        console.log(
          "O aplicativo está offline. Os dados serão salvos apenas no dispositivo."
        );
      }
    } catch (error) {
      console.error("Erro ao salvar configuração:", error);
    }
  }

  /**Função responsável buscar as configurações no localStorage do Dexie.
   * @function
   */
  async getConfigData() {
    try {
      return await localStorage.getItem(LOCAL_CONFIG);
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao salvar configuração!",
        type: "error",
        method: "SaveConfig",
      });
    }
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <ConfigContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </ConfigContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

function useConfigs() {
  const context = React.useContext(ConfigContext);
  return context;
}

export { ConfigContext, ConfigContextProvider, useConfigs };
