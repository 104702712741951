import React, { createContext } from "react";
import { Bairro, Cidade } from "../repository/models";
import { create } from "../service/neighborhood";
import AppStorageError from "../errorDefinition/AppStorageError";
import BusinessError from "../errorDefinition/BusinessError";
import { AbstractContext } from "./AbstractContext";
import LocalStorage from "../utils/localStorage";
import { MessageDialogContext } from "../contexts/MessageDialogContext";

const localStorage = LocalStorage.instance;

const NeighborhoodContext = createContext({
  neighborhood: [],
});

const success = (context, message) =>
  context.addAsyncDialog({
    message: message,
    title: "Sucesso!",
    type: "success",
    hasCloseButton: false,
  });

/**
 * Contexto utilizado na pagina de Novo cliente, Ver Cliente e Perfil.
 */
const OBSERVED_MODELS = [];
class NeighborhoodContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.observable = this.observable.bind(this);
    this.createNeighborhood = this.createNeighborhood.bind(this);
    this.update = this.update.bind(this);
    this.state = {
      isLoading: false,
      get neighborhood() {
        const bairroRepo = new Bairro();
        return bairroRepo.getAll();
      },
      get cities() {
        const cidadeRepo = new Cidade();
        return cidadeRepo.getAll();
      },
      get states() {
        return new Promise(async (resolve) => {
          const route = await localStorage.getItem("main-route");
          if (!route) return [];
          const states = [route.estado, ...(route.secondaryStates || [])];
          resolve(states.map((a) => ({ nome: a, id: a })));
        });
      },
      createNeighborhood: this.createNeighborhood,
      update: this.update,
      updateNeighborhoodOrder: this.updateNeighborhoodOrder.bind(this),
    };
  }

  observable() {}

  async updateNeighborhoodOrder(neighborhoodsToEdit) {
    await Promise.all(
      neighborhoodsToEdit.map((neighborhood) =>
        this.state.neighborhood.class.put(neighborhood)
      )
    );
    await this.notifyModelChange("activeSync", "Bairro");
  }

  /**Função responsável por criar um novo bairro.
   * @function
   * @param {object} data Dado usado na criação de um novo bairro.
   */
  async createNeighborhood(data) {
    try {
      data.index = 0;
      let createdNeighborhood = await create(data);
      await success(this.context, "Bairro criado com sucesso!");

      return createdNeighborhood;
    } catch (error) {
      if (!error instanceof BusinessError) {
        new AppStorageError({
          message: error.message,
          title: "Falha ao buscar novo bairro!",
          type: "error",
          method: "createNeighborhood",
        });
      }
      throw error;
    }
  }

  /**Função responsável por atualizar um bairro existente.
   * @function
   * @param {boolean} stateName Indicará o estado que será atualizado.
   * @param {object} data Dado usado para atualizar um bairro existente.
   */
  async update(stateName, data) {
    try {
      let repo;
      if (stateName == "neighborhood") {
        repo = new Bairro();
      } else if (stateName == "cities") {
        repo = new Cidade();
      }
      await repo.put(data);
      await success(this.context, "Bairro criado com sucesso!");
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao atualizar dados do bairro",
        type: "error",
        method: "update",
      });
      throw error;
    }
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <NeighborhoodContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </NeighborhoodContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { NeighborhoodContext, NeighborhoodContextProvider };
