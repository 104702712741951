import { Divider, Grid, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import moment from "moment";
import React from "react";
import GreenButton from "../../components/Buttons/GreenButton";
import Header from "../../components/HeaderForAdd/Header";
import Report from "../../components/Report/ReceivementFinalReport";
import { ClientContext } from "../../contexts/ClientContext";
import { PrinterContext } from "../../contexts/PrinterContext";
import History from "../../router/History";
import { generateExchangeInvoice } from "../../service/movements";

import RouteDialog from "../../components/Dialogs/RouteDialog";
const styles = (theme) => ({
  report: {
    paddingTop: "1vh",
  },
  buttons: {
    paddingTop: "5vh",
    paddingBottom: "2vh",
    "& div": {
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& > div": {
      paddingBottom: "16px",
    },
  },
  textArea: { border: "none", width: "100%", padding: "0 15px" },
});

class SaleReport extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   */
  constructor() {
    super();
    this.state = {
      clientName: "Carregando...",
    };
    this.goBackPage = this.goBackPage.bind(this);
    this.goBackStart = this.goBackStart.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
  }

  /**Função redireciona para página anterior.
   * @function
   * @param {string} name Nome do meio de Pagamento
   */
  goBackPage() {
    History.goBack();
  }

  /**Função redireciona a aba de movimentações do 'Ver Cliente'.
   * @function
   */
  goBackStart() {
    History.push(`/daily`);
  }

  componentDidMount() {
    this.context
      .getClientInfoToRenderCard(this.props.match.params.hash, "hash")
      .then((client) => {
        this.setState({ clientName: client.nome });
      });

    generateExchangeInvoice(this.props.match.params.exchangeHash)
      .then(({ viaClient: invoice }) => this.setState({ invoice }))
      .catch((error) => {
        console.error(error);
      });
  }

  handlePrint() {
    this.props.printerContext.callPrinterComponent({
      disableClientButton: false,
      content: this.state.invoice,
      title: "Comprovante de Troca",
      viaEmployee: this.state.invoice,
      headerSecondaryText: "Comprovante de Troca",
      headerMainText: moment().format("DD/MM/YYYY : HH:mm"),
    });
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Header
          icon={<CloseIcon fontSize="large" />}
          name={this.state.clientName}
          date={moment(new Date()).format("DD/MM/YYYY")}
          func={this.goBackPage}
        >
          Troca
        </Header>

        <Divider />
        <Grid container justify="center">
          <Grid item xs={10} className={classes.report}>
            <Report title="Troca Realizada">
              <textarea
                value={this.state.invoice}
                disabled
                className={classes.textArea}
              />
            </Report>
          </Grid>

          <Grid item xs={11} className={classes.buttons}>
            <Grid item>
              <GreenButton
                startIcon={<PrintIcon />}
                backgroundColor="#DCDCDC"
                onClickButton={this.handlePrint}
              >
                IMPRIMIR COMPROVANTE
              </GreenButton>
            </Grid>

            <RouteDialog text="VOLTAR AO INÍCIO" />
          </Grid>
        </Grid>
      </>
    );
  }
}

SaleReport.contextType = ClientContext;
const WithStylesReceivementReport = withStyles(styles)(SaleReport);
export default class SaleReportContext extends React.Component {
  render() {
    return (
      <PrinterContext.Consumer>
        {(printerContext) => (
          <WithStylesReceivementReport
            {...this.props}
            printerContext={printerContext}
          />
        )}
      </PrinterContext.Consumer>
    );
  }
}
