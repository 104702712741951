import React, { createContext } from "react";
import { Funcionario, Documento } from "../repository/models";
import LocalStorage from "../utils/localStorage";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import AppStorageError from "../errorDefinition/AppStorageError";
import { uploadEmployeePicture } from "../service/file/index";
import { getUser, setUser } from "../service/authentication";

const funcionario = new Funcionario();
const localStorage = LocalStorage.instance;
const documento = new Documento();

const success = (context, message) =>
  context.addAsyncDialog({
    message: message,
    title: "Sucesso!",
    type: "success",
    hasCloseButton: false,
  });

const ViewProfileContext = createContext({
  get viewProfile() {
    return [];
  },
});

/**
 * Contexto utilizado na pagina de Perfil.
 */
class ViewProfileContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.updateSelf = this.updateSelf.bind(this);
    this.getEmployeeData = this.getEmployeeData.bind(this);
    this.updateEmployee = this.updateEmployee.bind(this);
    this.state = {
      isLoading: false,
      requiredFields: [],
      recommendedFields: [],
      documents: [],
      updateSelf: this.updateSelf,
      getEmployeeData: this.getEmployeeData,
      updateEmployee: this.updateEmployee,
    };
  }

  componentDidMount() {
    this.updateSelf();
  }

  /**Função responsável atualizar os dados armazenados no estado.
   * @function
   */
  async updateSelf() {
    this.setState({
      documents: await documento.filter((a) => a.ativo != false),
    });
  }

  /**Função responsável por buscar os dados do funcionário.
   * @function
   * @returns {object} Dados do funcionário logado
   */
  async getEmployeeData() {
    try {
      let logged = await localStorage.getItem("logged");
      if (logged) {
        let employee = await funcionario.getAll("id", [logged.id]);
        return employee[0];
      } else {
        return {};
      }
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao buscar dados do funcionário!",
        type: "error",
        method: "getEmployeeData",
      });
    }
  }

  /**Função responsável por atualizar os dados do funcionário.
   * @function
   * @param {object} data Dado do funcionário.
   */
  async updateEmployee(data) {
    try {
      const { foto } = data;
      const user = await getUser();

      data.shouldSync = true;
      user.foto = foto;
      await funcionario.put(data);
      await setUser(user);
      if (foto) await uploadEmployeePicture(user, foto);
      await success(this.context, "Informações salvas com sucesso!");
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <ViewProfileContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </ViewProfileContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { ViewProfileContext, ViewProfileContextProvider };
