import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  text: {
    fontSize: "0.7rem",
  },
  uploadButton: {
    position: "relative",
    top: "10px",
  },
});

class UploadAndTakeImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Button
          variant="outlined"
          color="default"
          startIcon={<CameraAltIcon fontSize="small" />}
          onClick={this.props.clickTakeImage}
          data-testid="take-button"
        >
          <Typography data-testid="take-text" className={classes.text}>
            Tirar uma foto
          </Typography>
        </Button>

        <Button
          variant="outlined"
          color="primary"
          className={classes.uploadButton}
          startIcon={<ImageIcon fontSize="small" />}
          onClick={this.props.clickUploadImage}
          data-testid="upload-button"
        >
          <Typography data-testid="upload-text" className={classes.text}>
            Carregar uma foto
          </Typography>
        </Button>
      </>
    );
  }
}
export default withStyles(styles)(UploadAndTakeImage);
