import React from "react";
import { Grid, withStyles, Typography } from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import PaymentIcon from "@material-ui/icons/Payment";
import WarningIcon from "@material-ui/icons/Warning";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { ReactComponent as Picpaylogo } from "../../img/logoPicpayPreto.svg";
import { ReactComponent as PixLogo } from "../../img/logoPixPreto.svg";

const styles = (theme) => ({
  sectionTitle: {
    fontWeight: 400,
    paddingBottom: "20px",
  },
  card: {
    "& p": {
      fontFamily: "Roboto,sans-serif",
    },
    border: "1px solid #A9A9A9",
    height: "80px",
  },
  icon: {
    paddingTop: "4px",
  },
  cardName: {
    fontSize: ".8rem",
    fontFamily: "Roboto, sans-serif",
  },
  messageError: {
    fontSize: "1rem",
    color: "#FF0000",
  },
});

/**
 * Componente que renderiza a aba de movimentações do 'Ver Cliente'
 */
class PaymentMethods extends React.Component {
  /**
   * @param {object} props.id Id do usuário
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {string} props.paymentSelected Meio de pagamento selecionado
   * @param {boolean} props.error Erro gerado caso o o meio de pagamento não for selecionado
   * @property {object} this.state.paymentMethods  Dados do dos cards de formas de pagamentos
   */
  constructor(props) {
    super(props);
    this.state = {
      paymentMethods: [
        {
          name: "Cartão Crédito",
          icon: (
            <span>
              <i className="fas fa-credit-card fa-2x" />
            </span>
          ),
        },
        {
          name: "Dinheiro",
          icon: (
            <span>
              <i className="fas fa-money-bill-alt fa-2x" />
            </span>
          ),
        },
        {
          name: "Cartão Débito",
          icon: (
            <span>
              <i className="far fa-credit-card fa-2x" />
            </span>
          ),
        },
        {
          name: "Cheque",
          icon: (
            <span>
              <i className="fas fa-money-check-alt fa-2x" />
            </span>
          ),
        },
        {
          name: "Cartão Alimentação",
          icon: <CreditCardIcon fontSize="large" />,
        },
        {
          name: "Pagamento Online",
          icon: <PaymentIcon fontSize="large" />,
        },
        {
          name: "Pix",
          icon: (
            <span>
              <PixLogo />
            </span>
          ),
        },
        {
          name: "Picpay",
          icon: (
            <span>
              <Picpaylogo />
            </span>
          ),
        },
        {
          name: "Outros",
          icon: <MonetizationOnIcon fontSize="large" />,
        },
      ],
    };
    this.handleIcon = this.handleIcon.bind(this);
    this.handleSelectPayment = this.handleSelectPayment.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onSelectPayment = this.onSelectPayment.bind(this);
  }

  handleIcon(name) {
    let method = this.state.paymentMethods.find((el) => name == el.name);
    if (!method) {
      return (
        <WarningIcon fontSize="large" className={this.props.classes.icon} />
      );
    }
    return method.icon;
  }

  /**Função que muda a cor do card do meio de pagamento selecionado.
   * @function
   * @param {string} name Nome do meio de Pagamento
   * @returns {string} Cor em que o card irá ficar
   */
  handleSelectPayment(name) {
    if (this.props.paymentSelected == name) return "#6495ED";
  }

  /**Função que lida com o erro gerado ao não selecionar uma forma de pagamento.
   * @function
   * @returns {string} Cor em que o card irá ficar
   */
  handleError() {
    if (this.props.error) return "#FF0000";
  }

  /**Função que seta no componente pai o meio de pagamento selecionado.
   * @function
   * @param {string} paymentMethod Nome do meio de Pagamento
   */
  onSelectPayment(paymentMethod) {
    this.props.setData("payment", paymentMethod);
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Grid container justify="center">
          <Grid item xs={12} className={classes.title}>
            <Typography variant="h6" className={classes.sectionTitle}>
              MEIO DE PAGAMENTO
            </Typography>
          </Grid>
          {this.props.allPaymentMethods.map((el, index) => {
            let color, error, Icon;
            color = this.handleSelectPayment(el.nome);
            error = this.handleError();
            Icon = this.handleIcon(el.nome);
            return (
              <Grid
                item
                key={index}
                xs={4}
                className={classes.card}
                style={{ color: color, borderColor: error }}
                onClick={() => this.onSelectPayment(el.nome)}
              >
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  {Icon}
                  <Grid item xs={12}>
                    <Typography className={classes.cardName} align="center">
                      {el.nome}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          {this.props.error ? (
            <Typography align="center" className={classes.messageError}>
              Selecione um meio de pagamento!
            </Typography>
          ) : null}
        </Grid>
      </>
    );
  }
}
export default withStyles(styles)(PaymentMethods);
