import React from "react";
import { withRouter } from "react-router-dom";
import { OpenRouteContext } from "../contexts/OpenRouteContext";
import { MessageDialogContext } from "../contexts/MessageDialogContext";

const BLOCKED_URLS = [
  "/receivement/create",
  "/receivement/report",
  "/exchange/create",
  "/exchange/report",
  "/sale/create",
  "/sale/report",
  "/schedulings/create",
  "/route/close",
];

class PagesNavigationController extends React.Component {
  componentDidUpdate(prevProps, olsState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const isRouteOpen = this.props.openRouteContext.routeIsOpen;
      const URL = this.props.location.pathname;

      const isUrlBlocked = this._shouldValidate(URL);
      if (isUrlBlocked && !isRouteOpen) {
        this.props.messageDialogContext.addDialog({
          type: "warning",
          title: "Abra a rota primeiro!",
          message: "Não é possivel visualizar esta página com a rota fechada",
        });
        this.props.history.goBack();
      }
    }
  }

  _shouldValidate(url) {
    const isValidUrl = BLOCKED_URLS.some((el) => url.includes(el));
    return isValidUrl;
  }

  render() {
    return null;
  }
}

const WithRouterPagesNavigationController = withRouter(
  PagesNavigationController
);

export default class ContextAgregator extends React.Component {
  render() {
    return (
      <MessageDialogContext.Consumer>
        {(messageDialogContext) => (
          <OpenRouteContext.Consumer>
            {(openRouteContext) => (
              <WithRouterPagesNavigationController
                openRouteContext={openRouteContext}
                messageDialogContext={messageDialogContext}
                {...this.props}
              />
            )}
          </OpenRouteContext.Consumer>
        )}
      </MessageDialogContext.Consumer>
    );
  }
}

// eslint-disable-next-line no-lone-blocks
{
  /* <Route exact path='/report/daily' component={DailyReport}/> 
<Route exact path='/receivement/create/:hash' component={ Receivement }/>
<Route exact path='/receivement/report/:hash/:receivements' component={ ReceivementReport }/>
<Route exact path='/exchange/create/:hash' component={ Exchange }/>
<Route exact path='/exchange/report/:hash/:exchangeHash' component={ ExchangeReport }/>
<Route exact path='/sale/create/:hash' component={ Sale }/>
<Route exact path='/sale/report/:hash/:sale' component={ SaleReport }/>
<Route exact path='/schedulings/create/:hash' component={ CreateSchedulings }/>
<Route exact path='/route/close' component={ CloseRoute }/> */
}
