import React from "react";
import PropTypes from "prop-types";
import LibCameraPhoto, {
  FACING_MODES,
  IMAGE_TYPES,
} from "jslib-html5-camera-photo";
import clsx from "clsx";
import { withStyles } from "@material-ui/core";

const applyStyle = withStyles((theme) => ({
  root: {
    position: "relative",
    textAlign: "center",
    "& > video": {
      width: "100%",
    },
    "& > img": {
      width: "100%",
    },
  },

  fullScreenCamera: {
    "& > video": {
      width: "100vw",
      height: "100vh",
      objectFit: "cover",
    },
    "& > img": {
      width: "100vw",
      height: "100vh",
    },
  },
}));

class Camera extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.libCameraPhoto = null;
    this.videoRef = React.createRef();
    this.state = {
      dataUri: "",
      isCameraStarted: false,
    };
    this.handleTakePhoto = this.handleTakePhoto.bind(this);
  }

  async componentDidMount() {
    this.libCameraPhoto = new LibCameraPhoto(this.videoRef.current);
    const { idealFacingMode } = this.props;
    await this.startCameraMaxResolution(idealFacingMode);
    this.props.attachTakePhotoEvent(this.handleTakePhoto.bind(this));
  }

  async componentDidUpdate(oldprops) {
    if (this.shouldReStartCamera(oldprops, this.props)) {
      await this.stopCamera();
      await this.startCameraMaxResolution(this.props.idealFacingMode);
    }
  }

  shouldReStartCamera(oldProps, currentProps) {
    return (
      currentProps.idealFacingMode &&
      oldProps.idealFacingMode !== currentProps.idealFacingMode &&
      this.state.isCameraStarted
    );
  }

  componentWillUnmount() {
    this.stopCamera(true);
  }

  onCameraStart(stream) {
    this.setState({
      isCameraStarted: true,
    });
    if (typeof this.props.onCameraStart === "function") {
      this.props.onCameraStart(stream);
    }
  }

  onCameraStartError(error) {
    this.setState({
      isCameraStarted: false,
    });
    if (typeof this.props.onCameraError === "function") {
      this.props.onCameraError(error);
    }
  }

  async startCameraMaxResolution(idealFacingMode) {
    try {
      const stream = await this.libCameraPhoto.startCameraMaxResolution(
        idealFacingMode
      );
      this.onCameraStart(stream);
    } catch (error) {
      this.onCameraStartError(error);
    }
  }

  async stopCamera(isComponentWillUnmount = false) {
    try {
      if (this.state.isCameraStarted) await this.libCameraPhoto.stopCamera();

      if (!isComponentWillUnmount) {
        this.setState({ isCameraStarted: false });
      }
      if (typeof this.props.onCameraStop === "function") {
        this.props.onCameraStop();
      }
    } catch (error) {
      if (typeof this.props.onCameraError === "function") {
        this.props.onCameraError(error);
      }
    }
  }

  handleTakePhoto() {
    /**@description https://www.npmjs.com/package/jslib-html5-camera-photo#getdatauri */
    const { sizeFactor, imageType, imageCompression, isImageMirror } =
      this.props;
    const configDataUri = {
      sizeFactor,
      imageType,
      imageCompression,
      isImageMirror,
    };

    let dataUri = this.libCameraPhoto.getDataUri(configDataUri);

    if (this.props.onTakePhoto) {
      this.props.onTakePhoto(dataUri);
    }

    this.setState({
      dataUri,
    });
  }

  render() {
    const { isImageMirror, isFullscreen, classes } = this.props;

    const videoStyles = isImageMirror
      ? { transform: "rotateY(180deg)" }
      : { transform: "none" };

    const containerStyle = clsx(classes.root, {
      [classes.fullScreenCamera]: isFullscreen,
    });

    return (
      <div className={containerStyle}>
        <video
          style={videoStyles}
          ref={this.videoRef}
          autoPlay={true}
          muted="muted"
          playsInline
        />
      </div>
    );
  }
}

Camera.propTypes = {
  onTakePhoto: PropTypes.func.isRequired,
  onCameraError: PropTypes.func,
  idealFacingMode: PropTypes.string,
  imageType: PropTypes.string,
  isImageMirror: PropTypes.bool,
  imageCompression: PropTypes.number,
  isFullscreen: PropTypes.bool,
  sizeFactor: PropTypes.number,
  onCameraStart: PropTypes.func,
  onCameraStop: PropTypes.func,
  attachTakePhotoEvent: PropTypes.func.isRequired,
};

Camera.defaultProps = {
  isImageMirror: true,
  sizeFactor: 1,
  imageType: IMAGE_TYPES.JPG,
  imageCompression: 0,
  idealFacingMode: FACING_MODES.ENVIRONMENT,
  isFullscreen: true,
};

const CameraWithStyles = applyStyle(Camera);
export { CameraWithStyles as Camera, FACING_MODES, IMAGE_TYPES };

export default CameraWithStyles;
