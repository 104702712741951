/* eslint-disable eqeqeq */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Grid from "@material-ui/core/Grid";
import CardSkeleton from "../../../Skeleton/CardSkeleton";

const styles = (theme) => ({
  miniButtons: {
    width: "1.2em",
    height: "1.2em",
    borderRadius: "50%",
    color: "#ffffff",
    border: 0,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      fontSize: ".8rem",
    },
  },
  name: {
    fontSize: ".85rem",
    fontWeight: 600,
    wordWrap: "break-word",
  },
  address: {
    fontSize: ".8rem",
    color: "#A9A9A9",
    wordWrap: "break-word",
  },
  money: {
    color: "#d44444",
    fontSize: ".8rem",
    fontWeight: 600,
  },
  currentBalanceText: {
    fontSize: ".7rem",
    color: "#777777",
    marginTop: "-5px",
  },
  avatar: {
    width: "45px",
    height: "45px",
  },

  iconsContainer: {
    "& .MuiGrid-item": {
      padding: "0 2px",
    },
  },
});

/**
 * Card de cliente inicial, onde levará para os outros 2 estados de card
 */
class MiniCard extends React.Component {
  /**
   * @param {boolean} props.active Boelano que irá definirá se o card estará ativo ou não
   * @param {string} props.cardState Prop que tem como valor o card que deverá ser aberto
   * @param {function} props.showExpandCard Função que irá chamar o ExpandCard
   * @param {object} props.classes Objeto com todas as classes do CSS showExpandCard
   * @property {State} this.skeleton Estado que definirá se o skeleton do componente estará ativo ou não
   * @property {State} this.cardHeight Referencia ao elemento do componente, sendo usado para pegar a altura
   */
  constructor(props) {
    super(props);
    this.state = {
      skeleton: false,
    };
    this.cardHeight = React.createRef();
    this.renderDailyActions = this.renderDailyActions.bind(this);
  }

  renderDailyActions() {
    let { classes } = this.props;
    const shouldSell = this.props.Client.dailySchedules.some(
      (a) => a.type.toLowerCase() == "venda"
    );
    const shouldExchange = this.props.Client.dailySchedules.some(
      (a) => a.type.toLowerCase() == "troca"
    );
    const shouldReceiviment = this.props.Client.dailySchedules.some(
      (a) => a.type.toLowerCase() == "recebimento"
    );
    const display = { display: "none" };
    return (
      <>
        <Grid item>
          <span
            style={shouldReceiviment ? { backgroundColor: "#21A94F" } : display}
            className={classes.miniButtons}
          >
            <LocalAtmIcon />
          </span>
        </Grid>
        <Grid item>
          <span
            style={shouldSell ? { backgroundColor: "#F3C631" } : display}
            className={classes.miniButtons}
          >
            <ShoppingCartIcon />
          </span>
        </Grid>
        <Grid item>
          <span
            style={shouldExchange ? { backgroundColor: "#2383F0" } : display}
            className={classes.miniButtons}
          >
            <SyncAltIcon />
          </span>
        </Grid>
      </>
    );
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        {!this.state.skeleton ? (
          <Grid container justifyContent="flex-end">
            <Grid item xs={10}>
              <Grid
                container
                direction="row"
                className={classes.iconsContainer}
              >
                {this.renderDailyActions()}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            <CardSkeleton />
          </Grid>
        )}
      </>
    );
  }
}
export default withStyles(styles)(MiniCard);
