import React from "react";
import { withStyles, Divider, Typography, Grid } from "@material-ui/core";

const styles = (theme) => ({
  title: {
    "& .MuiTypography-body1": {
      fontFamily: "Roboto, sans-serif",
      fontSize: "1.45rem",
    },
    "& hr": {
      width: "100%",
    },
    paddingBottom: "10px",
  },
  data: {
    backgroundColor: "#f5f5f5",
    height: "60vh",
    overflow: "scroll",
  },
});

class ReceivementFinalReport extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Grid container className={classes.title} justify="center">
          <Typography align="center">{this.props.title}</Typography>
          <Divider />
        </Grid>

        <Grid
          container
          className={classes.data}
          style={{ minHeight: this.props.height }}
        >
          {this.props.children}
        </Grid>
      </>
    );
  }
}
export default withStyles(styles)(ReceivementFinalReport);
