import React from "react";
import { InputLabel, TextField, withStyles, MenuItem } from "@material-ui/core";
import SelectInput from "../Inputs/SelectInput";
import CpfMask from "../MaskedInputs/CpfMask";
import PropTypes from "prop-types";
import { NeighborhoodContext } from "../../contexts/NeighborhoodContext";

const applyStyles = withStyles((theme) => ({
  root: {
    "& > *": {
      padding: theme.spacing(1, 0),
    },
    "& > label": {
      fontWeight: "400",
      color: theme.palette.text.primary,
      margin: "-7px",
    },
  },
}));

class FilterListForm extends React.Component {
  static propTypes = {
    onInputChange: PropTypes.func,
    nome: PropTypes.string,
    cpf: PropTypes.string,
    bairroIdCache: PropTypes.string,
    city: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.onFieldChange = this.onFieldChange.bind(this);
    this.sortNeighborhoodsByName = this.sortNeighborhoodsByName.bind(this);
    this.mapItems = this.mapItems.bind(this);
  }

  onFieldChange(fieldName) {
    if (fieldName == "bairroIdCache" || fieldName == "cidade") {
      return (value) => {
        this.props.onInputChange(fieldName, value);
      };
    }
    return (event) => {
      this.props.onInputChange(fieldName, event.target.value);
    };
  }

  sortNeighborhoodsByName(a, b) {
    if (a.nome > b.nome) {
      return 1;
    }
    if (a.nome < b.nome) {
      return -1;
    }

    return 0;
  }

  mapItems(data, index) {
    return (
      <MenuItem
        key={`${index}-city-option`}
        value={data.cidade}
        selected={this.props.cidade == data.cidade}
      >
        {data.cidade}
      </MenuItem>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <form autoComplete="off" className={classes.root}>
          <InputLabel htmlFor="input-name">Nome (Busca parcial)</InputLabel>
          <TextField
            style={{ marginBottom: "4vh" }}
            autoComplete={false}
            fullWidth
            id="input-name"
            value={this.props.nome}
            onChange={this.onFieldChange("nome")}
          />
          <InputLabel htmlFor="input-cpf">CPF (Busca parcial)</InputLabel>
          <CpfMask
            value={this.props.cpf}
            onChange={this.onFieldChange("cpf")}
            style={{ marginBottom: "4vh" }}
            id="input-cpf"
            fullWidth
            type="tel"
            autoComplete={false}
          />
          <SelectInput
            label="Bairro"
            value={this.props.bairroIdCache}
            onChange={this.onFieldChange("bairroIdCache")}
            menuItem={this.context.neighborhood.then((neighborhoods) => {
              return neighborhoods.sort(this.sortNeighborhoodsByName);
            })}
            disabled={this.state.isLoading}
          />
          <SelectInput
            label="Cidade"
            value={this.props.cidade}
            onChange={this.onFieldChange("cidade")}
            menuItem={this.context.cities.then((cities) => {
              return cities.sort(this.sortNeighborhoodsByName);
            })}
            disabled={this.state.isLoading}
            mapper={this.mapItems}
          />
        </form>
      </>
    );
  }
}

FilterListForm.contextType = NeighborhoodContext;

export default applyStyles(FilterListForm);
