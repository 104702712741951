import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/pt-BR";
import { Grid, withStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const styles = {
  title: {
    fontSize: "1rem",
    fontFamily: "Roboto, sans-serif",
    fontWeigth: "bold",
  },
};

class DatePickerDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Typography
          align="center"
          display="inline"
          className={this.props.classes.title}
        >
          {this.props.message}
        </Typography>
        <Grid container justify="center">
          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
            <DatePicker
              format="dd/MM/yyyy"
              variant="dialog"
              inputVariant="outlined"
              placeholder="Inicio"
              value={this.state.value || this.props.defaultValue}
              onChange={(val) => {
                this.setState({ value: val });
                this.props.onChange(val);
              }}
              inputProps={{
                style: {
                  textAlign: "center",
                  fontSize: "1.1rem",
                  color: "grey",
                },
              }}
              helperText="Clique no campo a cima para selecionar a data"
              FormHelperTextProps={{
                style: {
                  textAlign: "center",
                  fontSize: "1rem",
                },
              }}
              disablePast
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </>
    );
  }
}

const DatePickerMessage = withStyles(styles)(DatePickerDialog);

function Title({ text, onClose }) {
  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item xs={10}>
        <Typography display="inline" variant="h6">
          {text}
        </Typography>
      </Grid>
      <CloseIcon onClick={onClose} />
    </Grid>
  );
}

const ConfirmMessage = { DatePickerMessage, Title };
export default ConfirmMessage;
