import { Divider, Grid, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import moment from "moment";
import React from "react";
import GreenButton from "../../components/Buttons/GreenButton";
import RouteDialog from "../../components/Dialogs/RouteDialog";
import Header from "../../components/HeaderForAdd/Header";
import Report from "../../components/Report/ReceivementFinalReport";
import { ClientContext } from "../../contexts/ClientContext";
import { PrinterContext } from "../../contexts/PrinterContext";
import History from "../../router/History";
import DOMPurify from 'dompurify';
import { generateSaleInvoice } from "../../service/movements";
const styles = (theme) => ({
  report: {
    paddingTop: "1vh",
  },
  buttons: {
    paddingTop: "5vh",
    paddingBottom: "2vh",
    "& div": {
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& > div": {
      paddingBottom: "16px",
    },
  },
  textArea: {
    width: "100%",
    border: "none",
    padding: "0 15px",
  },
});

/**
 * Tela do relatório de recebimento
 */
class SaleReport extends React.Component {
  static propTypes = {
    printerContext: PrinterContext,
  };
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   */
  constructor() {
    super();
    this.state = {
      clientName: "Carregando...",
      invoice: {
        viaClient: "",
        viaEmployee: "",
        imagemLogo: "",
      },
    };
    this.goBackPage = this.goBackPage.bind(this);
    this.goBackStart = this.goBackStart.bind(this);
    this.showDialogWarning = this.showDialogWarning.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
    this.divRef = React.createRef();
  }

  /**Função redireciona para página anterior.
   * @function
   */
  goBackPage() {
    History.goBack();
  }

  /**Função redireciona a aba de movimentações do 'Ver Cliente'.
   * @function
   */
  goBackStart() {
    History.push(`/daily`);
  }

  componentDidMount() {
    this.context
      .getClientInfoToRenderCard(this.props.match.params.hash, "hash")
      .then((client) => {
        this.setState({ clientName: client.nome });
      })
      .catch(console.error);
    generateSaleInvoice(this.props.match.params.sale)
      .then((vias) => {
        this.setState({ invoice: vias });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  showDialogWarning() {}

  handlePrint() {
    this.props.printerContext.callPrinterComponent({
      title: "Comprovante de venda",
      content: this.state.invoice.viaClient,
      headerMainText: moment().format("DD/MM/YYYY : HH:mm"),
      headerSecondaryText: "Comprovante de venda",
      viaEmployee: this.state.invoice.viaEmployee,
      viaClient: this.state.invoice.viaClient,
      imagemLogo: this.state.invoice.imagemLogo
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Header
          icon={<CloseIcon fontSize="large" />}
          name={this.state.clientName}
          date={moment(new Date()).format("DD/MM/YYYY")}
          func={this.goBackPage}
        >
          Recebimento
        </Header>

        <Divider />
        <Grid container justify="center">
        <Grid item xs={10} className={classes.report}>
          <Report title="Recebimento Realizado" height="70vh">
            <div
              alt="Recebimento Realizado"
              className={classes.textArea}
              disabled
              ref={this.divRef}
              style={{
                height: '100%',
                overflowY: 'scroll',  
                position: 'relative',
                whiteSpace: 'pre-wrap',
              }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(`
                  <div style="padding-right: 20px;">
                    ${this.state.invoice.viaClient}
                  </div>
                  <div style="text-align: center; padding: 10px;">
                  ${
                    this.state.invoice.imagemLogo 
                      ? `<img src="${this.state.invoice.imagemLogo}" style="max-width: 100%; height: auto;" />` 
                      : ''
                  }
                  </div>
                `)
              }}
            >
            </div>
    </Report>
  </Grid>

          <Grid item xs={11} className={classes.buttons}>
            <Grid item>
              <GreenButton
                onClickButton={this.handlePrint}
                startIcon={<PrintIcon />}
                backgroundColor="#CCC"
              >
                IMPRIMIR COMPROVANTE
              </GreenButton>
            </Grid>

            <RouteDialog text="VOLTAR AO INÍCIO" />
          </Grid>
        </Grid>
      </>
    );
  }
}

SaleReport.contextType = ClientContext;
const WithStylesReceivementReport = withStyles(styles)(SaleReport);

export default class SaleReportContext extends React.Component {
  render() {
    return (
      <PrinterContext.Consumer>
        {(printerContext) => (
          <WithStylesReceivementReport
            {...this.props}
            printerContext={printerContext}
          />
        )}
      </PrinterContext.Consumer>
    );
  }
}
