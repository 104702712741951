export function eventListenerBuilder(events, callback) {
  return async (event, data) => {
    let isValidEvent = false;
    if (Array.isArray(events)) {
      isValidEvent = events.some((acceptedEvent) => acceptedEvent === event);
    } else {
      isValidEvent = event === events || events === "any";
    }
    if (isValidEvent) {
      await callback(events, data);
    }
  };
}
