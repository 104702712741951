/* eslint-disable no-useless-constructor */
/* eslint-disable eqeqeq */
import React from "react";
import { Typography, withStyles, Grid, IconButton } from "@material-ui/core";
import Moeda from "../../../../utils/Moeda";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import clsx from "clsx";
import Moment from "moment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
  card: {
    backgroundColor: "#fff",
    padding: "10px",
    position: "relative",
  },
  amount: {
    fontFamily: "Roboto, sans-serif",
    color: "#C0C0C0",
  },
  movementValue: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  cancelMovement: {
    paddingTop: "8px",
    "& p": {
      fontSize: ".7rem",
      color: "#C0C0C0",
    },
  },
  movementTypeText: {
    textTransform: "uppercase",
    fontSize: "1rem",
    fontWeight: 400,
    color: "#333",
  },
  paymentMethod: {
    color: "#7c7c7c",
  },
  movementHour: {
    color: "#C0C0C0",
    fontSize: ".9rem",
    whiteSpace: "nowrap",
    fontWeight: 500,
  },
  moreSettingsIcon: {
    height: "0.8em",
    float: "right",
    color: "#a3a3a3",
    transition: "all .4s",
  },
  moreSettingsIcon_active: {
    transform: "rotate(90deg)",
  },
});

class CardMovements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseIsOpen: false,
      dialogIsOpen: false,
      showDropdownMenu: false,
    };
    this.onclickCancelMovement = this.onclickCancelMovement.bind(this);
    this.onclickOpenCard = this.onclickOpenCard.bind(this);
    this.handeleToggleDialog = this.handeleToggleDialog.bind(this);
    this.mapTipoAtividade = this.mapTipoAtividade.bind(this);
    this.onClickCard = this.onClickCard.bind(this);
    this.toggleDropdownMenu = this.toggleDropdownMenu.bind(this);
  }

  onclickCancelMovement() {
    this.props.clickCancelMovement(this.props.data);
    this.handeleToggleDialog();
  }

  onclickOpenCard() {
    this.props.handleToggleCardOpen(this.props.data.hash);
  }

  handeleToggleDialog() {
    this.setState({ dialogIsOpen: !this.state.dialogIsOpen });
  }

  mapTipoAtividade(tipoAtividade) {
    switch (tipoAtividade) {
      case "ClienteVenda":
        return "Venda";
      case "ClienteRecebimento":
        return "Recebimento";
      case "ClienteTroca":
        return "Troca";
      default:
        throw new Error("Tipo atividade não esperado");
    }
  }

  async onClickCard() {
    await this.props.onClickCard(this.props.data);
  }

  renderIconButton() {
    const { classes } = this.props;
    let moreSettingsIconClass = clsx({
      [classes.moreSettingsIcon]: true,
      [classes.moreSettingsIcon_active]: this.props.showDropdownMenu,
    });
    if (this.state.isPAP) {
      return (
        <MoreHorizIcon
          onClick={this.showEditCard}
          className={moreSettingsIconClass}
        />
      );
    } else {
      return (
        <IconButton
          className={moreSettingsIconClass}
          onClick={this.toggleDropdownMenu}
        >
          <MoreHorizIcon />
        </IconButton>
      );
    }
  }

  toggleDropdownMenu(event) {
    this.setState({
      showDropdownMenu: !this.state.showDropdownMenu,
      anchorEl: event.currentTarget,
    });
  }

  render() {
    let { classes } = this.props;
    return (
      <>
        <Grid
          container
          className={classes.card}
          direction="row"
          alignItems="stretch"
        >
          <Grid
            item
            xs={2}
            container
            alignItems="center"
            justify="center"
            style={{ position: "relative" }}
            onClick={this.onClickCard}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                background: "rgba(255, 255, 255, 0.15)",
              }}
            ></div>
            <IconButton onClick={this.onClickCard}>
              {this.props.icon}
            </IconButton>
          </Grid>
          <Grid
            item
            xs={7}
            container
            direction="column"
            alignItems="stretch"
            justify="space-between"
          >
            <Typography className={classes.movementTypeText}>
              {this.mapTipoAtividade(this.props.data.tipoAtividade)}
            </Typography>
            <Typography variant="body2" className={classes.paymentMethod}>
              {this.props.data.meioPagamento || ""}
            </Typography>
            <Typography variant="body2" className={classes.amount}>
              Saldo: {Moeda.create(this.props.data.saldo).format()}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-end"
          >
            <Grid item>
              <Typography
                variant="subtitle1"
                className={classes.movementHour}
                // align="right"
              >
                {Moment(this.props.data.dataRealizacao).format("hh:mm")}
              </Typography>
            </Grid>
            <Grid item onClick={this.onclickOpenCard}>
              {this.renderIconButton()}
            </Grid>
            <Grid item>
              <Typography
                // align="right"
                noWrap
                className={classes.movementValue}
                style={{
                  color:
                    this.props.data.tipoAtividade == "ClienteRecebimento"
                      ? "#32CD32"
                      : " #FF0000",
                }}
              >
                {Moeda.create(this.props.data.valor).format()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.showDropdownMenu}
          onClose={this.toggleDropdownMenu}
        >
          <MenuItem disabled onClick={this.onclickCancelMovement}>
            Cancelar
          </MenuItem>
          <MenuItem onClick={this.onClickCard}>Imprimir comprovante</MenuItem>
        </Menu>
      </>
    );
  }
}
export default withStyles(styles)(CardMovements);
