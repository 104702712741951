import AbstractDexieClass from "../abstractClasses/AbstractDexieErrorLog";

export default class RuntimmeError extends AbstractDexieClass {
  constructor() {
    super("RuntimeError", {
      employee: String,
      company: String,
      team: String,
      route: String,
      href: String,
      hint: String,
      errorString: String,
      timeStamp: Date,
    });
  }
}
