CanvasRenderingContext2D.prototype.wrapText = function (
  text,
  x,
  y,
  maxWidth,
  lineHeight
) {
  var lines = text.split("\n");

  for (var i = 0; i < lines.length; i++) {
    var words = lines[i].split(" ");
    var line = "";

    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + " ";
      var metrics = this.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        this.fillText(line, x, y);
        line = words[n] + " ";
        y += lineHeight;
      } else {
        line = testLine;
      }
    }

    this.fillText(line, x, y);
    y += lineHeight;
  }
};
