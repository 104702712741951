import DataAccess from "../DataAccessStrategy";

export default class ClienteCampoEdit extends DataAccess {
  constructor() {
    super("ClienteCampoEdit", {
      id: Number,
      attributeName: String,
      shouldSync: Boolean,
    });
  }
}
