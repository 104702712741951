import styles from "./Main.module.css";
import parentStyles from "./index.module.css";
import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import UnknownError from "../../errorDefinition/UnknownError";
import { UserContext } from "../../contexts/UserContext";
import Backdrop from "../../components/Dialogs/BackdropLoader";
class MainBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLocalhost: Boolean(
        window.location.hostname === "localhost" ||
          // [::1] is the IPv6 localhost address.
          window.location.hostname === "[::1]" ||
          // 127.0.0.1/8 is considered localhost for IPv4.
          window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
          )
      ),
    };
  }
  componentDidCatch(error) {
    new UnknownError(error);
  }

  render() {
    return !!this.context.isLoading ? (
      <Backdrop open={true} secondaryText="Estamos preparando tudo..." />
    ) : (
      <Grid container className={parentStyles.Btns_Container} spacing={2}>
        <Grid item xs={12} className={styles.Login_Btn}>
          <Link to={`/login/enterprise`}>
            <Button variant="outlined" size="large">
              Enterprise Login
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} className={styles.Login_Btn}>
          <Link to={`/login/pap`} className={styles.hide}>
            <Button variant="outlined" size="large">
              Fazer Login
            </Button>
          </Link>
        </Grid>
      </Grid>
    );
  }
}
MainBody.contextType = UserContext;
export default MainBody;
