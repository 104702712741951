import React from "react";
import "./App.css";
import AuthPage_Router from "./pages/Auth/index";
import { Router, Switch, Route } from "react-router-dom";
import AppRouter from "./router/AppRouter";
import history from "./router/History";
import { UserContextProvider, UserContext } from "./contexts/UserContext";
import { MessageDialogContextProvider } from "./contexts/MessageDialogContext";
import { OpenRouteContextProvider } from "./contexts/OpenRouteContext";
import MessageDialog from "./components/MessageDialog";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import PrinterDialog from "./components/Dialogs/PrinterDialog";
import ScrollControl from "./router/ScrollControl";
import { ExpensesContextProvider } from "./contexts/ExpensesContext";
import { PrinterContextProvider } from "./contexts/PrinterContext";
import { ProductContextProvider } from "./contexts/ProductsContext";
import { FuelContextProvider } from "./contexts/FuelContext";
import { SyncContextProvider } from "./contexts/SyncContext";
import { VehicleContextProvider } from "./contexts/VehicleContext";
import { NeighborhoodContextProvider } from "./contexts/NeighborhoodContext";
import { MovementsContextProvider } from "./contexts/MovementsContext";
import { SortContextProvider } from "./contexts/SortContext";
import { ConfigContextProvider } from "./contexts/ConfigContext";
import { SchedulingsContextProvider } from "./contexts/SchedulingsContext";
import { ClientContextProvider } from "./contexts/ClientContext";
import { ExchangeContextProvider } from "./contexts/ExchangeContext";
import { ReceivementContextProvider } from "./contexts/ReceivementContext";
import { DailyListContextProvider } from "./contexts/DailyListContext";
import { ViewProfileContextProvider } from "./contexts/ViewProfileContext";
import { DocumentContextProvider } from "./contexts/DocumentContext";
import { getLocalCongfigVar } from "./service/config";
import { SaleContextProvider } from "./contexts/SaleContext";
import PagesNavigationController from "./router/PagesNavigationController";
import DeviceWarningPage from "./pages/DeviceWarning";
import { FotoDownloadListener } from "./components/Snack/FotoDownloadListener";
import { RequirementsContextProvider } from "./contexts/RequirementsContext";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3c82f1",
    },
  },
});

const CONTEXTS_ARRAY = [
  ExpensesContextProvider,
  VehicleContextProvider,
  FuelContextProvider,
  ProductContextProvider,
  OpenRouteContextProvider,
  NeighborhoodContextProvider,
  ConfigContextProvider,
  ClientContextProvider,
  SortContextProvider,
  ExchangeContextProvider,
  DocumentContextProvider,
  ReceivementContextProvider,
  SaleContextProvider,
  MovementsContextProvider,
  ViewProfileContextProvider,
  SchedulingsContextProvider,
  UserContextProvider,
  SyncContextProvider,
  DailyListContextProvider,
  PrinterContextProvider,
  MessageDialogContextProvider,
  RequirementsContextProvider,
];

class App extends React.Component {
  constructor(props) {
    super(props);
    this.getConfigDataFromLocalstorage =
      this.getConfigDataFromLocalstorage.bind(this);
  }

  async getConfigDataFromLocalstorage() {
    try {
      let shouldEnableTransitions = await getLocalCongfigVar(
        "EnableTransitions"
      );

      if (!shouldEnableTransitions) {
        let htmlTag = document.getElementById("html-element");
        htmlTag.classList.add("disableTransitions");
      }
    } catch (error) {}
  }

  async componentDidMount() {
    try {
      await navigator.wakeLock.request("screen");
    } catch (err) {
      console.log(err);
    }
    await this.getConfigDataFromLocalstorage();
  }

  render() {
    const Inner = (
      <ThemeProvider theme={theme}>
        <UserContext.Consumer>
          {({ isAuthenticated }) => {
            return (
              <Router history={history}>
                <ScrollControl />
                <PagesNavigationController />
                <Switch>
                  {window.screen.width >= 992 ? (
                    <Route component={DeviceWarningPage} />
                  ) : (
                    <Route
                      component={isAuthenticated ? AppRouter : AuthPage_Router}
                    />
                  )}
                </Switch>
                <MessageDialog />
                <PrinterDialog />
                <FotoDownloadListener />
              </Router>
            );
          }}
        </UserContext.Consumer>
      </ThemeProvider>
    );

    const Context_Parent = CONTEXTS_ARRAY.reduce(
      (Memo, EL) => <EL>{Memo}</EL>,
      Inner
    );

    return Context_Parent;
  }
}

export default App;
