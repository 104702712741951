import { initializeApp } from "firebase/app";
import * as FireStoreFunctions from "firebase/firestore";
import { firebaseConfig } from "../utils/firebaseConfig";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const { initializeFirestore } = FireStoreFunctions;

// Inicializar o app do Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
signInWithEmailAndPassword(
  auth,
  process.env.REACT_APP_FIREBASE_EMAIL_LOGIN,
  process.env.REACT_APP_FIREBASE_PASSWORD_LOGIN
)
  .then((a) => console.log(a))
  .catch((a) => console.log(a));

// Obter a instância do Firestore
export default initializeFirestore(app, {
  experimentalForceLongPolling: true,
});

export * from "firebase/firestore";
