import { downloadBase64 } from "../file";
import RouteService from "../route";
import ConnectionErrorFetchFaild from "../../errorDefinition/ConnectionErrorFetchFaild";
import apiErrorResponse from "../../errorDefinition/ApiErrorResponse";
import BusinessError from "../../errorDefinition/BusinessError";
import UnknownError from "../../errorDefinition/UnknownError";
import SessionDataLostError from "../../errorDefinition/SessionDataLostError";
import InvalidAccessTokenError from "../../errorDefinition/InvalidAccessToken";
import LocalStorage from "../../utils/localStorage";
import { fetchPollyfill } from "../AbstractService";
import EventLog from "../../repository/errorsModels/EventLog";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
const routeService = new RouteService();

/**
 * @typedef {Object} Endereco Inteface dos dados de endereço da empresa
 * @property {String} logradouro
 * @property {String} numero
 * @property {String} bairro
 * @property {String} cidade
 * @property {String} estado
 * @property {String} sigla
 */

/**
 * @typedef {Object} Company Inteface dos dados da empresa
 * @property {String} identificador
 * @property {String} nome
 * @property {String} sigla
 * @property {Endereco} endereco
 * @property {String} telefones
 * @property {String} link
 * @property {String} quantidadeRotas
 */

/**
 * @typedef {Object} Session Inteface dos dados de sessão
 * @property {String} ativo
 * @property {String} id
 * @property {String} FuncionarioId
 * @property {String} dataAbertura
 * @property {String} imei
 * @property {String} ip
 * @property {String} mobile
 * @property {Date} updatedAt
 * @property {Date} createdAt
 * @property {String} token
 * @property {String} dataFechamento
 */

/**
 * @typedef {Object} User Interface de usuario
 * @property {Session} sessao
 * @property {String} id
 * @property {String} nome
 * @property {Date} dataNascimento
 * @property {String} foto
 * @property {String} cpf
 * @property {String} estado
 * @property {String} cidade
 * @property {String} bairro
 * @property {String} logradouro
 * @property {String} login
 * @property {String} password
 * @property {String} email
 * @property {String} telefone
 * @property {String} valeMaximo
 * @property {String} salarioCarteira
 * @property {String} percentCarteira
 * @property {String} salarioBase
 * @property {String} tipoSalario
 * @property {String} saldoAtual
 * @property {String} ativo
 * @property {String} isAdmin
 * @property {String} senhaMestra
 * @property {Date} createdAt
 * @property {Date} updatedAt
 * @property {String} CargoId
 * @property {String} EquipeId
 * @property {String} service
 */

export const TOKEN_CENTRAL = "DF74748A72FEE776A37BD566BB3CB";

/**@type {String} */
export const HOST_CENTRAL = "https://cesta-central-maximus.herokuapp.com";

/**@type {String} */
export const USER_LOGGED_STORAGE = "logged";

/**@type {String} */
export const EMPRESA_LOGGED_STORAGE = "empresa-logged";

/**
 * @description Responsavel por recuperar dados da empresa a qual o usuario logado ṕertence
 * @returns {Promise<Company>} Obejeto composto por informações da empresa
 * @throws {SessionDataLostError} Caso o usuario esteja logado e na tentativa de recuperar
 * os dados da empresa não se encontre armazenados.
 */
export async function getCompany() {
  const company = await LocalStorage.instance.getItem(EMPRESA_LOGGED_STORAGE);
  if (!company) {
    await logout("Dados da empresa não encontrados, favor re-logar", true);
    throw new SessionDataLostError();
  }
  return company;
}

/**
 * @description Resposavel por verificar o estado da autenticação do usuario
 * @param {Boolean} checkServer Caso true ira consultar o estado de autenticação do usuario no
 * servidor da empresa logo, é necessario que exista internet
 * @returns true quando o usuario está devidamente autenticado, ou false quando não está autenticdo
 */
export async function isAuthenticated(checkServer = false) {
  try {
    const user = await LocalStorage.instance.getItem(USER_LOGGED_STORAGE);
    const company = await LocalStorage.instance.getItem(EMPRESA_LOGGED_STORAGE);

    if (user && company) {
      if (!checkServer) return true;

      const URL = `${user.service}/api/verify/token?token=${user.sessao.token}`;
      let response;
      try {
        response = await fetch(URL);
      } catch (error) {
        return true;
      }

      if (response.status >= 400) {
        return true;
      }

      const data = await response.json();
      return !data.error;
    }
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * @description Setter dos dados de empresa
 * @param {Company} company
 */
export async function setCompany(company) {
  await LocalStorage.instance.setItem(EMPRESA_LOGGED_STORAGE, company);
}

/**
 * @description Setter dos dados de usuário
 * @param {User} user
 */
export async function setUser(user) {
  await LocalStorage.instance.setItem(USER_LOGGED_STORAGE, user);
  window.localStorage.setItem(USER_LOGGED_STORAGE, JSON.stringify(user));
}

/**
 * @description
 * @param {String} service Valor que será usado para consultar o servidor central a respeito dos dados
 * da empresa que "atende" pelo nome provido no parametro service, examplo: "homolog".
 */
export async function getCompanyInfoFromServer(service) {
  let companyInfo;
  // console.log(process.env.NODE_ENV)

  if (service) {
    const URL = `${HOST_CENTRAL}/empresa/${service}?token=${TOKEN_CENTRAL}`;
    let response;
    let data;
    try {
      response = await fetch(URL);
      data = await response.json();
    } catch (error) {
      if (error.message == "Failed to fetch") {
        throw new ConnectionErrorFetchFaild();
      }
      throw new UnknownError({
        error,
        hint: "AuthenticationService.getCompanyInfoFromServer",
      });
    }
    if (data.error) {
      throw new apiErrorResponse({
        message: data.msg,
        title: "Oops, houve um problema",
        type: "error",
        method: "getCompanyInfoFromServer",
      });
    }

    companyInfo = data.data.empresa;
  } else if (process.env.NODE_ENV === "development") {
    companyInfo = {
      identificador: "123",
      nome: "BB CESTA BÁSICA ",
      sigla: "BBCESTA",
      endereco: {
        logradouro: "Rua Amazonas",
        numero: "04",
        bairro: "Canaã",
        cidade: "Viana",
        estado: "Espírito Santo",
        sigla: "ES",
      },
      telefones: ["(27)3344-7580", "(27)99961-7543"],
      link: "http://localhost:3000",
    };
  } else {
    throw new apiErrorResponse({
      message: "É necessario informar o nome da empresa",
      title: "Oops, houve um problema",
      type: "error",
      method: "getCompanyInfoFromServer",
    });
  }
  return companyInfo;
}

async function FingerprintSearch() {
  let visitorId;
  const fpPromise = FingerprintJS.load();

  const fp = await fpPromise;
  const result = await fp.get();

  visitorId = result.visitorId;
  return visitorId;
}

/**
 *
 * @param {Object} params
 * @param {String} params.login O login do usuario
 * @param {String} params.pdw A senha do usuario
 * @param {String} params.service O nome da empresa do usuario
 *
 * @description Funcionalidade que permite autenticar o usuario e permitir seu
 * acesso ao sistema. #Dev:Lembre-se de verificar as configurações de modo a permitir
 * a alteração do comportamento para cada app diferente. Caso seja o portaAporta o parametro
 * params.service já tem que vir setado com a string correta, mesmo q o input não apareça
 */
export async function authenticate({ login, pwd, service }) {
  const IMEI = await getIMEI();
  const companyInfo = await getCompanyInfoFromServer(service);
  const authLink = `${companyInfo.link}/api/autenticate`;

  const visitorId = await FingerprintSearch();

  let response;
  try {
    response = await fetch(authLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        login: login,
        senha: pwd,
        imei: IMEI || "not found",
        shouldSendEquipe: true,
        userBrowserFingerprint: visitorId,
      }),
    });
  } catch (error) {
    if (error.message == "Failed to fetch") {
      throw new ConnectionErrorFetchFaild({
        error,
      });
    }
    throw new UnknownError({
      error,
    });
  }

  const data = await response.json();
  if (data.error) {
    throw new apiErrorResponse({
      message: data.msg,
      title: "Oops, houve um problema",
      type: "error",
      method: "authenticate",
    });
  }

  let result = data.funcionario;

  if (!result) {
    throw new apiErrorResponse({
      message: "Funcionário não encontrado",
      title: "Oops, houve um problema",
      type: "error",
      method: "authenticate",
    });
  }
  result.sessao = data.sessao;
  result.service = companyInfo.link;
  result.cargo = data.cargo;

  //@todo notifyAll(SEGURANCA_LOGIN, result);

  if (result.foto) {
    try {
      let fotoAsBase64 = await downloadBase64(result.foto);

      result.foto = fotoAsBase64;
    } catch (error) {
    } finally {
      await setUser(result);
      await setCompany(companyInfo);
      return;
    }
  }

  await setCompany(companyInfo);
  await setUser(result);
}
/**
 * @todo
 */
export function getIMEI() {
  return new Promise(function (resolve, reject) {
    if (!window.plugins) {
      resolve("111");
      return;
    }
    window.plugins.uniqueDeviceID.get(
      function (deviceid) {
        window.plugins.imei.get(
          function (imei) {
            resolve(imei);
          },
          function () {
            resolve();
          }
        );
      },
      function () {
        resolve();
      }
    );
  });
}

export function isValidURL(str) {
  const a = document.createElement("a");
  a.href = str;
  return a.host && a.host != window.location.host;
}

/**
 * @description Função que deve ser utilizada em todas os retornos de chamdas ao servidor
 * @param {Object} serverResponse Retorno padrão de chamadas de fetch
 * @param {Object} abort Função que irá realizar o abort de chamadas de fetch que já foram feitas
 * de forma a evitar side effects. Só é invocada quando um erro que impeça o funcionamento normal.
 * @throws {InvalidAccessToken} Quando existe um problema com o token de acesso do usuario.
 */
export function checkEmployeeToken(serverResponse, abort) {
  if (serverResponse && serverResponse.error) {
    if (
      serverResponse.msg == "Token de funcionário inválido" ||
      serverResponse.msg == "Token de funcionário expirado" ||
      serverResponse.msg == "Token de funcionário não encontrado!"
    ) {
      abort();
      throw new InvalidAccessTokenError({
        method: "checkEmployeeToken",
      });
    }
  }
}

/**
 * @description Getter dos dados do usuario logado
 * @returns {Promise<User>}
 */
export async function getUser() {
  let user;
  if (user) return JSON.parse(user);
  user = await LocalStorage.instance.getItem(USER_LOGGED_STORAGE);
  if (!user) {
    await logout("Dados do usuario não encontrado, favor re-logar", true);
    throw new SessionDataLostError();
  }
  window.localStorage.setItem(USER_LOGGED_STORAGE, JSON.stringify(user));
  return user;
}

/**
 * @description Realiza o logout do usuario do app e limpa os dados de sessão
 * @param {String} msg
 * @param {Boolean} forceLogout Caso true, o logout será realizado mesmo que o funcionario tenha
 * uma rota aberta. Caso falso será verificado o estado da abertura, caso a rota esteja aberta o
 * logout não será realizado
 */
export async function logout(msg, forceLogout = false) {
  //@todo adicionar o log de segurança notifyAll(SEGURANCA_LOGOUT, user);

  if (!forceLogout) {
    const rotaAbertura = await routeService.routeOpening();
    if (rotaAbertura) {
      throw new BusinessError({
        message: "Não é possivel encerrar a sessão com a rota aberta",
        title: "Rota aberta !!",
        method: "service/authentication.logout",
        type: "error",
      });
    }
    if (!navigator.onLine) {
      throw new BusinessError({
        message:
          "Não é possivel encerrar a sessão, voce deve esta conectado a internet",
        title: "Verifique sua conexão",
        method: "service/authentication.logout",
        type: "warning",
      });
    }
    await requestAuthToLogout();
  }
  await LocalStorage.instance.clear();
  // Isso aqui está causando problemas na hora de acessar o aplicativo
  // await window.DATABASE.clearDatabase();
  //Clear all filters
  window.localStorage.setItem("daily", "{}");
  window.localStorage.setItem("debtor", "{}");
  window.localStorage.setItem("defaulter", "{}");
  window.localStorage.setItem("settled", "{}");
  window.localStorage.setItem("repass", "{}");
  await new EventLog().registerEvent("LOGOUT", "logout", "logout", {});
}

async function requestAuthToLogout() {
  const user = await getUser();
  const URL = `${user.service}/api/funcionario/logout?token=${user.sessao.token}`;
  const response = await fetchPollyfill(URL);
  const { abort } = response;
  const data = await response.json();
  checkEmployeeToken(data, abort);

  if (data.error)
    throw new apiErrorResponse({
      title: "Erro ao deslogar!",
      message: data.msg,
      type: "error",
    });

  return true;
}
