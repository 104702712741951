import {
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import BlockIcon from "@material-ui/icons/Block";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React from "react";
import { Link } from "react-router-dom";
import history from "../../../../router/History";
import ButtonsOptions from "../../../Buttons/RoundButton";

const styles = (theme) => ({
  buttonMovimentations: {
    fontSize: ".75rem",
    lineHeight: 1.5,
    borderRadius: 2,
    height: "33px",
    color: "#fff",
  },
  roundButton: {
    textAlign: "center",
    "& button": {
      color: "#fff",
    },
  },
  titleRoundButton: {
    fontSize: ".7rem",
    alignText: "center",
  },
  name: {
    fontSize: ".85rem",
    fontWeight: 600,
    wordWrap: "break-word",
  },
  address: {
    fontSize: "0.8rem",
    color: "#A9A9A9",
    wordWrap: "break-word",
  },
  currentBalanceText: {
    fontSize: ".7rem",
    color: "#777777",
    marginTop: "-5px",
  },
  money: {
    color: "#d44444",
    fontSize: ".8rem",
    fontWeight: 600,
  },
  divider: {
    margin: "10px 0 15px 0",
  },
  phoneNumber: {
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
    fontFamily: "Roboto, sans-serif",
  },
  listBorder: {
    borderRadius: "7px",
    "border-right-width": "5px",
    "border-image": "linear-gradient(to bottom, #0b9a8a, #0b9a8a42) 1 100%",
    "border-right-style": "solid",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  menuItem: {},
  IconButton: {},
  Icon: {
    color: "#075e54",
  },
  divWrapper: {
    "& > a": {
      padding: theme.spacing(0, 2),
    },
  },
});

/**
 * Card do estado de 'Editar'
 */
class ActionsCard extends React.Component {
  /**
   * @param {object} props.Client Objeto com os dados do cliente
   * @param {boolean} props.active Boleano que setará o fade do card
   * @param {function} props.closeActionsCard Função que trocará o estados dos cards e 'fechará' o ActionsCard
   * @param {object} props.classes Objeto com todas as classes do CSS
   */
  constructor(props) {
    super(props);
    this.state = {
      anchorMenu: null,
    };
    this.handleToggleMenuPhoneNumber =
      this.handleToggleMenuPhoneNumber.bind(this);
    this.handleRenderPhoneNumbers = this.handleRenderPhoneNumbers.bind(this);
    this.onClickCloseActionsCard = this.onClickCloseActionsCard.bind(this);
    this.phoneOption = this.phoneOption.bind(this);
  }

  handleToggleMenuPhoneNumber(event) {
    if (!this.state.anchorMenu) {
      this.setState({ anchorMenu: event.currentTarget });
    } else {
      this.setState({ anchorMenu: null });
    }
  }

  handleRenderPhoneNumbers() {
    const phones = this.props.Client.telefones?.length
      ? this.props.Client.telefones
      : [{}];

    return (
      <Menu
        id="fade-menu"
        anchorEl={this.state.anchorMenu}
        keepMounted
        open={!!this.state.anchorMenu}
        onClose={this.handleToggleMenuPhoneNumber}
        TransitionComponent={Fade}
        className={this.props.classes.phoneNumber}
        classes={{
          list: this.props.classes.listBorder,
        }}
      >
        {phones.map(this.phoneOption)}
      </Menu>
    );
  }

  phoneOption({ numero = "" }, index) {
    const classes = this.props.classes;
    const formattedNumber = !!numero
      ? numero
        .replace(/\s/g, "")
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
      : "";

    return (
      <MenuItem
        disabled={!numero}
        onClick={this.handleToggleMenuPhoneNumber}
        className={classes.menuItem}
        key={formattedNumber + index}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className={classes.divWrapper}
        >
          <a href={`tel:0${formattedNumber}`}>
            <span>{numero || "Sem número de contato"}</span>
          </a>
          <Divider orientation="vertical" flexItem />
          <IconButton
            href={`https://wa.me/55${formattedNumber}`}
            className={classes.IconButton}
            target="_blank"
            disabled={!numero}
          >
            {!!numero ? (
              <WhatsAppIcon className={classes.Icon} />
            ) : (
              <BlockIcon className={classes.Icon} />
            )}
          </IconButton>
        </Grid>
      </MenuItem>
    );
  }

  /**Função que executará no click para o fechamento do card
                  <MenuItem onClick={this.handleToggleMenuPhoneNumber}>
   * @function
   */
  onClickCloseActionsCard() {
    this.props.closeActionsCard();
  }

  render() {
    let { classes } = this.props;
    return (
      <Grid item>
        <Divider className={classes.divider} />
        <Grid
          container
          spacing={1}
          alignItems="center"
          style={{ padding: "0 5px" }}
        >
          <Grid item xs={7}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              {this.props.veacoBtn ? (
                <Grid item xs={4} className={classes.roundButton}>
                  <ButtonsOptions
                    color="#FFA500"
                    icon={<DirectionsRunIcon />}
                    func={this.props.handleClickVeaco}
                  />
                  <Typography className={classes.titleRoundButton}>
                    Veaco
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xs={4} className={classes.roundButton}>
                <ButtonsOptions
                  func={this.handleToggleMenuPhoneNumber}
                  color="#1E90FF"
                  icon={<PhoneInTalkIcon />}
                />
                <Typography className={classes.titleRoundButton}>
                  Ligar
                </Typography>
                {this.handleRenderPhoneNumbers()}
              </Grid>
              <Grid item xs={4} className={classes.roundButton}>
                <Link
                  to={{
                    pathname: `/client/view/data/${this.props.Client.hash}`,
                    state: { prevUrl: history.location.pathname },
                  }}
                >
                  <ButtonsOptions color="#6B8E23" icon={<BorderColorIcon />} />
                </Link>
                <Typography className={classes.titleRoundButton}>
                  Editar
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.roundButton}>
                <Link
                  to={{
                    pathname: `/client/view/schedules/${this.props.Client.hash}`,
                    state: { prevUrl: history.location.pathname },
                  }}
                >
                  <ButtonsOptions color="#B22222" icon={<QueryBuilderIcon />} />
                </Link>
                <Typography className={classes.titleRoundButton}>
                  Agendar
                </Typography>
              </Grid>
              {this.props.pularBtn ? (
                <Grid item xs={4} className={classes.roundButton}>
                  <ButtonsOptions
                    func={async () => {
                      await this.props.onClickPular(this.props.Client.hash);
                      this.onClickCloseActionsCard();
                    }}
                    color="#FF4500"
                    icon={<SkipNextIcon />}
                  />
                  <Typography className={classes.titleRoundButton}>
                    Pular
                  </Typography>
                </Grid>
              ) : null}
              {this.props.repasseBtn ? (
                <Grid item xs={4} className={classes.roundButton}>
                  <ButtonsOptions
                    func={() => {
                      this.props.onClickRepass(this.props.Client.hash);
                    }}
                    color="#008080"
                    icon={<ArrowForwardIcon />}
                  />
                  <Typography className={classes.titleRoundButton}>
                    Repassar
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid
              container
              direction="column"
              spacing={1}
              alignItems="stretch"
              justifyContent="flex-start"
            >
              <Grid item>
                <Link
                  to={{
                    pathname: `/receivement/create/${this.props.Client.hash}`,
                    state: { prevUrl: history.location.pathname },
                  }}
                  className={classes.link}
                >
                  <Button
                    size="small"
                    fullWidth={true}
                    variant="contained"
                    className={classes.buttonMovimentations}
                    style={{ backgroundColor: "#21A94F" }}
                  >
                    RECEBIMENTO
                  </Button>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to={{
                    pathname: `/sale/create/${this.props.Client.hash}`,
                    state: { prevUrl: history.location.pathname },
                  }}
                  className={classes.link}
                >
                  <Button
                    size="small"
                    fullWidth={true}
                    variant="contained"
                    className={classes.buttonMovimentations}
                    style={{ backgroundColor: "#F3C631" }}
                  >
                    VENDA
                  </Button>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to={{
                    pathname: `/exchange/create/${this.props.Client.hash}`,
                    state: { prevUrl: history.location.pathname },
                  }}
                  className={classes.link}
                >
                  <Button
                    size="small"
                    fullWidth={true}
                    variant="contained"
                    className={classes.buttonMovimentations}
                    style={{ backgroundColor: "#2383F0" }}
                  >
                    TROCA
                  </Button>
                </Link>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ActionsCard);
