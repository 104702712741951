import DataAccess from "../DataAccessStrategy";

export default class DespesaDeleted extends DataAccess {
  constructor() {
    super("DespesaDeleted", {
      modelName: String,
      hash: String,
      shouldSync: Boolean,
    });
  }
}
