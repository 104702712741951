import React from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const style = (theme) => ({
  cardProducts: {
    border: "1px solid #DCDCDC",
    padding: "10px",
    position: "relative",
    marginTop: "10px",
    zIndex: 3,
    "& div": {
      height: "100%",
    },
  },
  amount: {
    borderRadius: 100,
    width: "2vh",
    height: "2vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px",
    backgroundColor: "#D3D3D3",
    color: "#000000",
  },
  text: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingLeft: "10px",
    fontSize: "1.1rem",
    fontFamily: "Roboto, sans-serif",
    "& span": {
      fontWeight: "bold",
    },
  },
});

/**
 * Card de um produto exibido na página de produtos.
 */
class ItemCard extends React.Component {
  /**
   * @param {number} props.index Índice onde o card se encontra na lista
   * @param {object} props.itemData Objeto contendo os dados do card
   * @param {funcion} props.handleItemSub Diminui quantidade do item
   * @param {funcion} props.handleItemAdd Aumenta a quantidade do item
   * @param {object} props.classes Objeto com todas as classes do CSS
   */
  constructor(props) {
    super(props);
    this.handleItemAdd = this.handleItemAdd.bind(this);
    this.handleItemSub = this.handleItemSub.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
  }

  /**Função responsável por adicionar a quantidade de um item .
   * @function
   */
  handleItemAdd() {
    this.props.handleItemAdd(this.props.index);
  }

  /**Função responsável por diminuir a quantidade de um item .
   * @function
   */
  handleItemSub() {
    this.props.handleItemSub(this.props.index);
  }

  /**Função responsável por remover um item .
   * @function
   */
  handleRemoveItem() {
    this.props.handleRemoveItem(this.props.index);
  }

  render() {
    let { classes } = this.props;

    return (
      <Grid
        container
        justify="space-between"
        className={classes.cardProducts}
        alignItems="center"
      >
        <Grid item xs={7}>
          <Typography noWrap className={classes.text}>
            <span>{this.props.itemData.identificador}</span> {this.props.points}{" "}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Grid container>
            <Grid item xs={9}>
              <Grid
                container
                justify="space-evenly"
                wrap="nowrap"
                alignItems="center"
              >
                <span className={classes.amount} onClick={this.handleItemSub}>
                  <RemoveIcon fontSize="small" />
                </span>
                <Typography align="center">
                  {this.props.itemData.quantidade}
                </Typography>
                <span className={classes.amount} onClick={this.handleItemAdd}>
                  <AddIcon fontSize="small" />
                </span>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container alignItems="center" justify="flex-end">
                <CloseIcon fontSize="default" onClick={this.handleRemoveItem} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(style)(ItemCard);
