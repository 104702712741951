/* eslint-disable eqeqeq */
import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/pt";
import moment from "moment";
import TodayIcon from "@material-ui/icons/Today";
import EventIcon from "@material-ui/icons/Event";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";

const styles = (theme) => ({
  tableContainer: {
    "& td:nth-child(odd)": {
      borderRight: "1px solid #e0e0e0",
    },
    "& td:nth-child(-n+2)": {
      borderTop: "1px solid #e0e0e0",
    },
  },
  presetDateSelectedClass: {
    color: "#2196f3",
    fontWeight: "bold",
  },
  tableCell: {
    width: "50%",
  },
  startDateClass: {},
  endDateClass: {},
  dateInputClass: {},
  mainContainer: {},
});

class TwoCalendarInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datePickerStartIsOpen: false,
      datePickerEndIsOpen: false,
      startDate: this.props.startDate || null,
      endDate: this.props.endDate || null,
      presetDateSelected: null,
      activePreset: this.props.activePreset || "schedulings",
    };
  }

  async handleClick(presetDate) {
    let startDate = null;
    let endDate = null;
    let currentMonth = moment().get("month");
    let currentYear = moment().get("year");
    let currentDate = moment().get("date");

    if (presetDate == "thisMonth") {
      startDate = moment()
        .set({ year: currentYear, month: currentMonth, date: 1 })
        .format();
      endDate = moment()
        .set({ year: currentYear, month: currentMonth + 1, date: 0 })
        .format();
    } else if (presetDate == "lastMonth") {
      startDate = moment()
        .set({ year: currentYear, month: currentMonth - 1, date: 1 })
        .format();
      endDate = moment()
        .set({ year: currentYear, month: currentMonth, date: 0 })
        .format();
    } else if (presetDate == "thisYear") {
      startDate = moment()
        .set({ year: currentYear, month: 0, date: 1 })
        .format();
      endDate = moment()
        .set({ year: currentYear, month: 12, date: 0 })
        .format();
    } else if (presetDate == "lastYear") {
      startDate = moment()
        .set({ year: currentYear - 1, month: 0, date: 1 })
        .format();
      endDate = moment()
        .set({ year: currentYear - 1, month: 12, date: 0 })
        .format();
    } else if (presetDate == "1day") {
      startDate = moment().set("date", currentDate).format();
      endDate = moment().set("date", currentDate).format();
    } else if (presetDate == "2day") {
      startDate = moment().set("date", currentDate).format();
      endDate = moment()
        .set("date", currentDate + 1)
        .format();
    } else if (presetDate == "3day") {
      startDate = moment().set("date", currentDate).format();
      endDate = moment()
        .set("date", currentDate + 2)
        .format();
    } else if (presetDate == "4day") {
      startDate = moment().set("date", currentDate).format();
      endDate = moment()
        .set("date", currentDate + 3)
        .format();
    }

    await this.setState({
      startDate: startDate,
      endDate: endDate,
      presetDateSelected: presetDate,
    });

    this.props.onChange(this.state.startDate, this.state.endDate);
  }

  async handleDateChange(dateType, value) {
    this.setState({ [dateType]: value, presetDateSelected: null }, () => {
      this.props.onChange(this.state.startDate, this.state.endDate);
    });
  }

  render() {
    let { classes } = this.props;
    let { errors } = this.props;
    if (!classes) classes = {};

    return (
      <>
        <Grid container className={classes.mainContainer}>
          <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
            <Grid container justify="space-evenly" alignItems="stretch">
              <Grid item xs={5}>
                <DatePicker
                  format="dd/MM/yyyy"
                  variant="dialog"
                  inputVariant="outlined"
                  placeholder="Inicio"
                  value={this.state.startDate}
                  className={classes.startDateClass}
                  disablePast={this.props.disablePast ? true : false}
                  error={errors.errorStart}
                  maxDate={this.state.endDate}
                  maxDateMessage="Data não deve ultrapassar a data final"
                  onChange={(val) => this.handleDateChange("startDate", val)}
                  helperText={
                    errors.errorStart ? "Selecione a data inicial!" : null
                  }
                  InputProps={{
                    startAdornment: <TodayIcon color="action" />,
                    fullWidth: true,
                    classes: { input: classes.dateInputClass },
                  }}
                />
              </Grid>
              <Grid item xs={1} container alignItems="center" justify="center">
                <div
                  style={{
                    width: "10px",
                    height: "1px",
                    background: "#a3a3a3",
                  }}
                ></div>
              </Grid>
              <Grid item xs={5}>
                <DatePicker
                  format="dd/MM/yyyy"
                  variant="dialog"
                  inputVariant="outlined"
                  placeholder="Fim"
                  value={this.state.endDate}
                  className={classes.endDateClass}
                  onChange={(val) => this.handleDateChange("endDate", val)}
                  error={errors.errorEnd}
                  minDate={this.state.startDate}
                  minDateMessage="Data não deve ultrapassar a data inicial"
                  helperText={
                    errors.errorEnd ? "Selecione a data final!" : null
                  }
                  InputProps={{
                    startAdornment: <EventIcon color="action" />,
                    fullWidth: true,
                    classes: { input: classes.dateInputClass },
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>

          <TableContainer className={classes.tableContainer}>
            <Table size="small">
              <TableBody>
                {this.props.activePreset == "schedulings" ? (
                  <>
                    <TableRow>
                      <TableCell
                        className={clsx({
                          [classes.presetDateSelectedClass]:
                            this.state.presetDateSelected == "thisMonth",
                          [classes.tableCell]: true,
                        })}
                        onClick={() => this.handleClick("thisMonth")}
                      >
                        Este mês
                      </TableCell>
                      <TableCell
                        className={clsx({
                          [classes.presetDateSelectedClass]:
                            this.state.presetDateSelected == "lastMonth",
                          [classes.tableCell]: true,
                        })}
                        onClick={() => this.handleClick("lastMonth")}
                      >
                        Mês passado
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={clsx({
                          [classes.presetDateSelectedClass]:
                            this.state.presetDateSelected == "thisYear",
                          [classes.tableCell]: true,
                        })}
                        onClick={() => this.handleClick("thisYear")}
                      >
                        Este ano
                      </TableCell>
                      <TableCell
                        className={clsx({
                          [classes.presetDateSelectedClass]:
                            this.state.presetDateSelected == "lastYear",
                          [classes.tableCell]: true,
                        })}
                        onClick={() => this.handleClick("lastYear")}
                      >
                        Ano passado
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}
                {this.props.activePreset == "openRoute" ? (
                  <>
                    <TableRow>
                      <TableCell
                        className={clsx({
                          [classes.presetDateSelectedClass]:
                            this.state.presetDateSelected == "1day",
                          [classes.tableCell]: true,
                        })}
                        onClick={() => this.handleClick("1day")}
                      >
                        Hoje
                      </TableCell>
                      <TableCell
                        className={clsx({
                          [classes.presetDateSelectedClass]:
                            this.state.presetDateSelected == "2day",
                          [classes.tableCell]: true,
                        })}
                        onClick={() => this.handleClick("2day")}
                      >
                        Hoje e amanhã
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={clsx({
                          [classes.presetDateSelectedClass]:
                            this.state.presetDateSelected == "3day",
                          [classes.tableCell]: true,
                        })}
                        onClick={() => this.handleClick("3day")}
                      >
                        3 dias
                      </TableCell>
                      <TableCell
                        className={clsx({
                          [classes.presetDateSelectedClass]:
                            this.state.presetDateSelected == "4day",
                          [classes.tableCell]: true,
                        })}
                        onClick={() => this.handleClick("4day")}
                      >
                        4 dias
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(TwoCalendarInputs);
