import React from "react";
import { withStyles, Divider, Grid } from "@material-ui/core";
import Header from "../HeaderForAdd/Header";
import CloseIcon from "@material-ui/icons/Close";
import History from "../../router/History";
import Report from "../Report/ReceivementFinalReport";
import PrintOutAndNext from "../Buttons/PrintOutAndNext";
import Slide from "@material-ui/core/Slide";
import { PrinterContext } from "../../contexts/PrinterContext";
import { OpenRouteContext } from "../../contexts/OpenRouteContext";
import LocalStorage from "../../utils/localStorage";

const styles = (theme) => ({
  report: {
    paddingTop: "1vh",
  },
  buttons: {
    position: "fixed",
    bottom: "20px",
  },
  button_next: {
    paddingLeft: "5px",
  },
  textArea: { border: "none", width: "100%", padding: "0 15px" },
});

const localStorage = LocalStorage.instance;
/**
 * Tela do relatório de recebimento
 */
class FinalReport extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {function} props.provideNextScene Função responsável por prover para a próxima cena.
   * @param {string} props.slideDirection Direção em que o slide irá se movimentar.
   */
  constructor() {
    super();
    this.state = { invoice: "" };
    this.onClickGoNext = this.onClickGoNext.bind(this);
    this.showPrinterComponent = this.showPrinterComponent.bind(this);
  }

  /**Função onde ocorre o redirecionamento para a página inicial.
   * @function
   */
  onClickGoNext() {
    History.push("/");
  }

  async componentDidMount() {
    const localConfig = await localStorage.getItem("local-config");
    const invoice = localConfig.closingReport
      ? await this.props.openRouteContext.getRouteOpeningInvoiceServer(
          this.props.defaultValues.hashRotaAbertura
        )
      : this.props.defaultValues.closureInvoice;

    this.setState({ invoice });
  }

  showPrinterComponent() {
    const dialogRenderOptions = {
      disableClientButton: true,
      content: this.state.invoice,
      viaClient: this.state.invoice,
      viaEmployee: this.state.invoice,
      headerMainText: "Fechamento de rota",
      title: "Relatório fechamento de rota",
    };
    this.context.callPrinterComponent(dialogRenderOptions);
  }
  render() {
    let { classes } = this.props;
    return (
      <Slide
        direction="left"
        in={true}
        mountOnEnter
        unmountOnExit
        style={{ minHeight: "100%" }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Header
              icon={<CloseIcon fontSize="large" />}
              name={"Finalizada"}
              link="/"
            >
              Fechar Rota
            </Header>

            <Divider />

            <Grid container justify="center">
              <Grid item xs={10} className={classes.report}>
                <Report title="Relatório de fechamento de rota">
                  <textarea
                    value={this.state.invoice}
                    disabled
                    className={classes.textArea}
                  />
                </Report>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.buttons}>
            <PrintOutAndNext
              onClickPrint={this.showPrinterComponent}
              title="FINALIZAR"
              onClickGoNext={this.onClickGoNext}
            />
          </Grid>
        </Grid>
      </Slide>
    );
  }
}
FinalReport.contextType = PrinterContext;
const FinalReportWithStyles = withStyles(styles)(FinalReport);

export default function Wrapper(props) {
  return (
    <OpenRouteContext.Consumer>
      {(openRouteContext) => (
        <FinalReportWithStyles {...props} openRouteContext={openRouteContext} />
      )}
    </OpenRouteContext.Consumer>
  );
}
