import DataAccess from "../DataAccessStrategy";

export default class ClienteCampoObrigatorio extends DataAccess {
  constructor() {
    super("ClienteCampoObrigatorio", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      attributeName: String,
    });
  }
}
