import DataAccess from "../DataAccessStrategy";

export default class Feriado extends DataAccess {
  constructor() {
    super("Feriado", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      nome: String,
      data: Date,
    });
  }
}
