import React from "react";
import moment from "moment";
import { withStyles, Typography, Grid, Divider } from "@material-ui/core";
import Moeda from "../../utils/Moeda";

const styles = (theme) => ({
  th: {
    border: "1px solid black",
  },
});

class GenerateReport extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   */

  constructor(props) {
    super(props);
  }

  render() {
    let { data } = this.props;
    const vouchers = data?.relatorio?.adiantamentos;
    const total =
      vouchers &&
      vouchers.length &&
      vouchers.reduce(
        (memo, voucher) => Moeda.create(memo).add(voucher.valor).mount(),
        Moeda.create("0,00").mount()
      );

    if (Moeda.create(total).isZero()) {
      return (
        <Grid item>
          <Typography
            fullWidth
            style={{
              fontSize: "1.1rem",
              fontFamily: "Roboto, sans-serif",
              padding: "20px 15px 5px 20px",
            }}
          >
            <b>Nenhum dado encontrado</b>
          </Typography>
        </Grid>
      );
    }
    return (
      <>
        {/* <VoucherReport GenerateReport/> */}
        <Grid item>
          <Typography
            fullWidth
            style={{
              fontSize: "1.1rem",
              fontFamily: "Roboto, sans-serif",
              padding: "20px 15px 5px 20px",
            }}
          >
            <b>Dados do Funcionário Referente ao Mês: </b>{" "}
            {moment(vouchers[0].data).format("MM/YYYY")}
          </Typography>
        </Grid>

        <Grid
          item
          style={{ padding: "15px", position: "relative", top: "5px" }}
        >
          <Divider />
        </Grid>

        <Grid item>
          <Typography
            fullWidth
            style={{
              marginTop: 3,
              fontSize: "1.1rem",
              padding: "20px 15px 5px 20px",
            }}
          >
            <b>Total: </b>
            {Moeda.create(total).format()}
          </Typography>
        </Grid>

        <Grid
          item
          style={{ padding: "15px", position: "relative", top: "5px" }}
        >
          <Divider />
        </Grid>
        <Grid
          style={{
            maxWidth: "100%",
            overflow: "auto",
            padding: "3px  0px ",
            width: "100%",
          }}
        >
          <table>
            <thead>
              <tr>
                <th style={{ padding: "20px" }}>Data</th>
                <th style={{ padding: "20px" }}>Hora</th>
                <th style={{ padding: "20px" }}>Valor</th>
                <th style={{ padding: "20px" }}>Descrição</th>
              </tr>
            </thead>
            <tbody>
              {vouchers &&
                vouchers.length &&
                vouchers.map((voucher) => (
                  <tr>
                    <td style={{ padding: "20px" }}>
                      {moment(voucher.data).format("DD/MM/YYYY")}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {moment(voucher.data).format("HH:mm:ss")}
                    </td>
                    <td style={{ padding: "20px" }}>
                      {Moeda.create(voucher.valor).format()}
                    </td>
                    <td style={{ padding: "20px" }}>{voucher.descricao}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Grid>
      </>
    );
  }
}

GenerateReport.contextType = {};
export default withStyles(styles)(GenerateReport);
