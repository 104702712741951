import Error_db from "../DexieCollections/errorsdb";
import LocalStorage from "../../utils/localStorage";

export default class DexieErrorLog {
  static get database() {
    if (!Error_db.database)
      throw new Error(
        "Dexie database definition must be initiated before accessing it."
      );
    return Error_db.database;
  }

  static define(DATABASE, className, fields = {}) {
    fields = DexieErrorLog.addDefaultClassProperty(fields);
    if (!DexieErrorLog[className]) {
      DATABASE[className].defineClass(fields);
      DexieErrorLog[className] = true;
    }
    return DATABASE[className];
  }

  #model = undefined;

  set model(model) {
    if (this.#model) return;
    this.#model = model;
  }

  get model() {
    return this.#model;
  }

  constructor(className, fields = {}) {
    if (!className) throw new Error("Dexie model requires a name!");
    if (!window.ERROR_LOG) new Error_db();
    this.model = DexieErrorLog.define(Error_db.database, className, fields);
    this.LocalStorage = LocalStorage.instance;
    this.getAll = this.getAll.bind(this);
    this.store = this.store.bind(this);
  }

  static addDefaultClassProperty(props) {
    return Object.assign(props, {
      employee: String,
      company: String,
      team: String,
      route: String,
      href: String,
      hint: String,
      errorString: String,
      timeStamp: Date,
    });
  }

  getAll() {
    return this.model.toArray();
  }

  async store(data) {
    let user;
    let company;
    let team;
    let route;
    let timeStamp;
    try {
      timeStamp = new Date();
      user = await this.LocalStorage.getItem("logged");
      user = {
        nome: user.nome,
        hash: user.hash,
        id: user.id,
      };
      route = await this.LocalStorage.getItem("main-route");

      route = {
        nome: route.nome,
        hash: route.hash,
      };

      team = await this.LocalStorage.getItem("main-team");
      team = {
        nome: team.nome,
        hash: team.hash,
        id: team.id,
      };

      company = await this.LocalStorage.getItem("empresa-logged");
      company = {
        nome: company.nome,
        hash: company.hash,
      };
    } catch (error) {}
    const DEFAULT_PROPS = {
      timeStamp,
      user,
      company,
      team,
      route,
    };

    const dataToStore = Object.assign(data, DEFAULT_PROPS);

    try {
      const id = await this.model.add(dataToStore);
      return id;
    } catch (error) {
      console.error(error);
    }
  }
}
