import React from "react";
import ButtonForAdd from "../Buttons/ButtonForAdd";
import Header from "../HeaderForAdd/Header";
import { Grid, withStyles, TextField, Dialog, Slide } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  inputsList: {
    paddingBottom: "40px",
  },
  inputContainer: {
    backgroundColor: "#fff",
    position: "relative",
    top: "10px",
    paddingTop: "20px",
  },
  body: {
    backgroundColor: "#F5F5F5",
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 4,
  },
  button: {
    position: "relative",
    top: "30px",
  },
  title: {
    fontSize: "1rem",
    fontFamily: "Roboto, sans-serif",
    wordBreak: "break-all",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Componente do formulário de combústivel.
 */
class FuelForm extends React.Component {
  /**
   * @param {string} props.fuelContext Contexto de combustiveis.
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @param {function} props.handleToggleFuelForm Função que alterna e exibição deste componente no componente pai.
   * @property {string} this.state.fuel Propriedade onde será armazenado o nome do novo combústivel.
   */
  constructor(props) {
    super(props);
    this.state = {
      fuel: { value: null, error: false },
    };
    this.checkFiels = this.checkFiels.bind(this);
    this.saveData = this.saveData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  /**Função onde ocorre a validação dos campos e seta os erros.
   * @function
   */
  checkFiels() {
    let check = true;
    if (this.state.fuel.value === null || this.state.fuel.value.length < 1) {
      this.setState({ fuel: { ...this.state.fuel, error: true } });
      check = false;
    } else {
      this.setState({ fuel: { ...this.state.fuel, error: false } });
    }

    if (check) return true;
    else return false;
  }

  /**Função onde ocorre a criação do novo combustivel no banco.
   * @function
   */
  async saveData() {
    try {
      if (!this.checkFiels()) return false;

      let newFuel = { nome: this.state.fuel.value };
      newFuel = await this.props.fuelContext.create(newFuel);
      this.props.setNewFuel(newFuel, "CombustivelId");
      this.props.handleToggleFuelForm();
    } catch (error) {
      console.log(error);
    }
  }

  /**Função que lida salva o que for escrito nos estados.
   * @function
   * @param {event} event Evento gerado
   */
  handleChange(event) {
    this.setState({ fuel: { ...this.state.fuel, value: event.target.value } });
  }

  render() {
    let { classes } = this.props;
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.handleClose}
        TransitionComponent={Transition}
      >
        <div className={classes.body}>
          <Header func={this.props.handleToggleFuelForm} icon={<CloseIcon />}>
            Criar combústivel
          </Header>
          <Grid container justify="center" className={classes.inputContainer}>
            <Grid item xs={9} className={classes.inputsList}>
              <TextField
                label="Nome combústivel"
                fullWidth
                error={this.state.fuel.error}
                helperText={
                  this.state.fuel.error && "Informe o nome do combustivel!"
                }
                required
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container justify="center" className={classes.button}>
            <ButtonForAdd func={this.saveData}>Salvar combústivel</ButtonForAdd>
          </Grid>
        </div>
      </Dialog>
    );
  }
}
export default withStyles(styles)(FuelForm);
