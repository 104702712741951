import DataAccess from "../DataAccessStrategy";

export default class CachedError extends DataAccess {
  constructor() {
    super("CachedError", {
      id: Number,
      updatedDate: Date,
      message: String,
      stack: String,
      date: Date,
      data: Object,
    });
  }

  catch(error, data) {
    const storeData = {
      ...error,
      data,
    };
    return this.model.add(storeData);
  }
}
