import React from "react";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";

/**
 * Componente utilizado como parametro de props.InputProps de componentes <TextField/> do Material-uipara defnição de mascara do tipo CEP
 * que é dada pelo seguinte padrão #####-### onde '#' indica um numero inteiro
 */
class LicenseMask extends React.Component {
  constructor(props) {
    super(props);
    this.render.propTypes = {
      inputRef: PropTypes.func.isRequired,
    };
  }

  render() {
    const { inputRef, ...other } = this.props;
    return (
      <InputMask {...other} mask="aaa-9999" ref={inputRef} maskChar={null} />
    );
  }
}
export default LicenseMask;
