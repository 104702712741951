import React from "react";
import AppBar from "../../components/MenuSideBar/AppBar.js";
import { FormControl, withStyles } from "@material-ui/core";
import CalendarPicker from "../../components/Calendar/HeaderDate";
import SelectInput from "../../components/Inputs/SelectInput.js";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { ExpensesContext } from "../../contexts/ExpensesContext.js";
import GenerateReport from "../../components/GenerateReport/index.js";
import moment from "moment";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    fontSize: "1.1rem",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 300,
  },
  gerarButton: {
    backgroundColor: "#1473cc",
    color: "#fff",
  },
});

/**
 * Tela de relatório de Vale.
 */
class VoucherReport extends React.Component {
  /**
   * @param {object} props.classes Objeto com todas as classes do CSS
   */
  constructor(props) {
    super(props);
    this.state = {
      emploeeyId: "",
      username: "",
      password: "",
      date: new Date(),
      view: "form",
      data: null,
    };
    this.setDate = this.setDate.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateFields = this.updateFields.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onClickGerar = this.onClickGerar.bind(this);
    this.onClickVoltar = this.onClickVoltar.bind(this);
  }

  async componentDidMount() {
    try {
      await this.getBasketSalesInfo(this.state.date);
      await this.getListMonthOpeningList();
      this.setState({ isLoading: false });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  /**Função onde irá armazenar a nova data escolhida.
   * @function
   * @param {date} date Data escolhida pelo usuário
   */
  async setDate(date) {
    try {
      this.setState({ isLoading: true, date: date });
      await this.getBasketSalesInfo(date);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleInputChange(event) {
    this.setState({ emploeeyId: event });
  }

  updateFields(event) {
    const user = { ...this.state };
    user[event.target.name] = event.target.value;
    this.setState(user);
  }

  onChangeDate(e) {
    this.setState({ date: e }, () => this.props.setData(this.state.date));
  }

  async onClickGerar() {
    this.setState({ data: null });
    const mes = moment(this.state.date).format("MM");
    const ano = moment(this.state.date).format("YYYY");
    const data = await this.context.fetchVoucherEmploy(
      this.state.emploeeyId,
      mes,
      ano,
      this.state.username,
      this.state.password
    );
    this.setState({ view: "list", data });
  }

  onClickVoltar() {
    this.setState({ view: "form", data: null });
  }

  render() {
    const emploeeyId = this.state.emploeeyId;
    let { classes } = this.props;
    let expensesContext = this.context;

    return (
      <>
        <AppBar title="Relatório de Vale" />
        {this.state.view == "list" && <GenerateReport data={this.state.data} />}
        {this.state.view == "form" && (
          <>
            <FormControl
              fullWidth
              style={{
                paddingBottom: 15,
                maxWidth: "330px",
                padding: "20px 15px 5px 15px",
              }}
            >
              <SelectInput
                label="Escolha um Funcionário"
                value={emploeeyId}
                onChange={this.handleInputChange}
                menuItem={expensesContext.employees.value}
              />
            </FormControl>
            <>
              <CalendarPicker
                setData={this.setDate}
                onChange={this.onChangeDate}
              />
            </>

            <FormControl
              fullWidth
              style={{
                paddingBottom: 15,
                maxWidth: "330px",
                padding: "20px 15px 5px 15px",
              }}
            >
              <TextField
                name="username"
                onChange={(e) => this.updateFields(e)}
                className={classes.UsernameInput}
                label="Login"
                variant="filled"
                type="text"
              />

              <TextField
                name="password"
                onChange={(e) => this.updateFields(e)}
                className={classes.PasswordInput}
                label="Senha"
                variant="filled"
                type="password"
                value={this.state.password}
              />
            </FormControl>

            <Grid
              container
              justify="flex-end"
              fullWidth
              style={{
                paddingBottom: 15,
                maxWidth: "350px",
                padding: "20px 15px 5px 15px",
              }}
            >
              <Button
                className={classes.gerarButton}
                variant="contained"
                onClick={this.onClickGerar}
              >
                Gerar
              </Button>
            </Grid>
          </>
        )}
        {this.state.view == "list" && (
          <Grid
            container
            justify="center"
            className={classes.voltarButton}
            style={{}}
          >
            <Button variant="outlined" onClick={this.onClickVoltar}>
              Voltar
            </Button>
          </Grid>
        )}
      </>
    );
  }
}
VoucherReport.contextType = ExpensesContext;
export default withStyles(styles)(VoucherReport);
