import React, { createContext } from "react";
import { ClienteRecebimento, ClienteVenda } from "../repository/models";
import {
  fetchMovements,
  fetchMovementsFromServer,
} from "../service/movements/index";
import AppStorageError from "../errorDefinition/AppStorageError";
import { AbstractContext } from "./AbstractContext";
import { MessageDialogContext } from "../contexts/MessageDialogContext";
import {
  generateReceivementInvoice,
  generateSaleInvoice,
} from "../service/movements";
import * as R from "ramda";
import moment from "moment";
import models from "../repository/models";

const clienteRecebimento = new ClienteRecebimento();
const clienteVenda = new ClienteVenda();

const MovementsContext = createContext({
  movements: [],
});

const OBSERVED_MODELS = ["ClienteRecebimento", "ClienteVenda"];
/**
 * Contexto utilizado na página de ver cliente.
 */
class MovementsContextProvider extends AbstractContext {
  constructor(props) {
    super(props, OBSERVED_MODELS);
    this.fetchMovementsFromServer = this.fetchMovementsFromServer.bind(this);
    this.getRouteSales = this.getRouteSales.bind(this);
    this.generateInvoice = this.generateInvoice.bind(this);
    this.fetchMovements = this.fetchMovements.bind(this);
    this.observable = this.observable.bind(this);
    this.handleCancelMovements = this.handleCancelMovements.bind(this);
    this.state = {
      movements: [],
      fetchMovements: this.fetchMovements,
      fetchMovementsFromServer: this.fetchMovementsFromServer,
      handleCancelMovements: this.handleCancelMovements,
      lastRequieredInfo: null,
      getRouteSales: this.getRouteSales,
      generateInvoice: this.generateInvoice,
    };
  }

  async fetchMovementsFromServer(clientHash) {
    try {
      const allMovements = await fetchMovementsFromServer(clientHash);
      let hasCreatedSome = false;
      if (!allMovements || !allMovements.length) return;

      let stateMovs = [...this.state.movements];
      stateMovs = stateMovs.filter((mov) => typeof mov !== "string");
      stateMovs = stateMovs.concat(allMovements);
      stateMovs = R.uniqWith(
        (m1, m2) =>
          m1.formaPagamento == m2.formaPagamento && m1.hash == m2.hash,
        stateMovs
      );

      let createProms = [];

      const promsMovimentacao = allMovements.map(async (movement) => {
        const tipo = movement.tipoAtividade;
        movement.dataRealizacao = moment(movement.dataRealizacao).toDate();
        movement.hash = String(movement.hash);
        const exists = await models[tipo].getAll("hash", movement.hash);
        if (!exists.length) hasCreatedSome = true;
        return !!exists.length ? null : models[tipo].create(movement, false);
      });
      createProms = createProms.concat(promsMovimentacao);
      await Promise.all(createProms);

      if (!hasCreatedSome) return;
      // Transform array for front-end -- ***TEMPORARY***
      if (stateMovs.length > 0) {
        stateMovs.sort((a, b) => {
          const dateA = a.dataRealizacao;
          const dateB = b.dataRealizacao;
          return dateA - dateB;
        });

        stateMovs = stateMovs.reverse();

        let count = 0;
        let dates = stateMovs.map((el, index) => {
          el.dataRealizacao = moment(el.dataRealizacao);
          count++;
          return {
            date: el.dataRealizacao.format("DD MMM YYYY"),
            index: index + count,
          };
        });
        dates.forEach((el) => {
          if (el != undefined) stateMovs.splice(el.index - 1, 0, el.date);
        });
      }

      this.setState({ movements: stateMovs });
    } catch (err) {
      throw new Error(err);
    }
  }

  async getRouteSales(startDate, endDate) {
    return await clienteVenda.getSalesByInterval(startDate, endDate);
  }

  async generateInvoice(metaData) {
    const options = {
      ClienteRecebimento: (receivements) => {
        const receivementsArgs = [].concat(receivements);
        const stringifiedReceivements = JSON.stringify(receivementsArgs);
        return generateReceivementInvoice(stringifiedReceivements);
      },
      ClienteVenda: generateSaleInvoice,
      default: () => {
        throw new Error("Invalid mov type on MovementsContext.printMovement");
      },
    };

    const invoice = await (options[metaData.tipoAtividade] || options.default)(
      metaData.hash
    );
    return invoice;
  }

  /**Função responsável buscar as movimentações de um cliente.
   * @function
   * @param {boolean} hash Hash do cliente.
   * @param {Date} startDate data de início da busca.
   * @param {Date} endDate data final da busca.
   * @param {boolean} reset Boleano que caso true esvazia o estado que armazena as movimentações.
   */
  async fetchMovements(hash, startDate, endDate, reset) {
    if (reset)
      this.setState({
        movements: [],
        lastRequieredInfo: { hash, startDate, endDate },
      });
    try {
      if (this.state.isFetching) {
        return [];
      } else {
        let movements = await fetchMovements(hash, startDate, endDate);
        this.setState({
          movements: movements,
          lastRequieredInfo: { hash, startDate, endDate },
        });
      }
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao buscar as movimentações!",
        type: "error",
        method: "MovementsContextProvider.fetchMovements",
      });
    }
  }

  observable() {
    if (this.state.lastRequieredInfo) {
      const { hash, startDate, endDate } = this.state.lastRequieredInfo;
      this.fetchMovements(hash, startDate, endDate);
    }
  }

  /**Função responsável por cancelar uma deterninada movimentação.
   * @function
   * @param {object} data Dado da movimentação ser cancelada.
   * @param {string} hash Do cliente que terá a movimentação cancelada
   * @param {Date} startDate data de início da busca.
   * @param {Date} endDate data final da busca.
   */
  async handleCancelMovements(data, hashClient, startDate, endDate) {
    try {
      await this.isRouteOpen();
      if (data.tipo.toLowerCase() == "Recebimento".toLowerCase()) {
        data.cancelado = true;
        data.shouldSync = true;
        await clienteRecebimento.put(data);
        this.fetchMovements(hashClient, startDate, endDate, true);
      }

      if (data.tipo.toLowerCase() == "Venda".toLowerCase()) {
        data.cancelado = true;
        data.shouldSync = true;
        await clienteVenda.put(data);
        this.fetchMovements(hashClient, startDate, endDate, true);
      }
    } catch (error) {
      new AppStorageError({
        message: error.message,
        title: "Falha ao cancelar a movimentação!",
        type: "error",
        method: "handleCancelMovements",
      });
    }
  }

  render() {
    return (
      <MessageDialogContext.Consumer>
        {(context) => {
          if (this.context !== context) this.context = context;
          return (
            <MovementsContext.Provider value={this.state}>
              {typeof this.props.children === "function"
                ? this.props.children()
                : this.props.children}
            </MovementsContext.Provider>
          );
        }}
      </MessageDialogContext.Consumer>
    );
  }
}

export { MovementsContext, MovementsContextProvider };
