import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { MoreHoriz, Create } from "@material-ui/icons";
import { CSSTransition } from "react-transition-group";
import clsx from "clsx";

const styles = (theme) => ({
  upstraightIcon: {
    transform: "rotate(90deg)",
    transition: "all .3s",
  },
  downsideIcon: {
    transform: "rotate(0deg)",
    transition: "all .3s",
  },

  onShowIcons: {
    maxHeight: "30px",
  },
  onHideIcons: {
    maxHeight: "100px",
  },

  // SlideFade transition
  "slideFade-enter": {
    transform: "translate(0, 40px)",
    opacity: 0,
  },
  "slideFade-enter-active": {
    opacity: 1,
    transform: "translate(0, 0)",
    transition: "all 300ms",
  },
  "slideFade-enter-done": {
    opacity: 1,
  },
  "slideFade-exit": {
    // transform: 'translate(0, 0)',
    opacity: 1,
  },
  "slideFade-exit-active": {
    opacity: 0,
    transform: "translate(0, 40px)",
    transition: "all 300ms",
  },
  "slideFade-exit-done": {
    opacity: 0,
  },
});

/**
 * Componente com 2 botões para editar e mudar de estado.
 * O componente utiliza este meio de CSS acima.
 */
class VerticalEdit extends React.Component {
  /**
   * @param {function} props.func1 Função acionada no 'click' para mudar o estado de um item
   * @param {object} props.classes Classes do css vindas do withStyles
   * @property {State} this.state
   */
  constructor(props) {
    super(props);
    this.state = {};
    this.handleDisplaySettings = this.handleDisplaySettings.bind(this);
  }

  handleDisplaySettings() {
    this.props.toggleCardSettings();
  }

  render() {
    let { classes } = this.props;

    let moreHorizClass = clsx({
      [classes.upstraightIcon]: !this.props.isOpen,
      [classes.downsideIcon]: this.props.isOpen,
    });
    let moreSettingsIconsClass = clsx({
      [classes.onShowIcons]: !this.props.isOpen,
      [classes.onHideIcons]: this.props.isOpen,
    });

    return (
      <>
        <Grid container justify="center" alignItems="center">
          <MoreHoriz
            className={moreHorizClass}
            fontSize="default"
            onClick={this.handleDisplaySettings}
          />
          <CSSTransition
            in={this.props.isOpen}
            timeout={300}
            classNames={{
              enter: classes["slideFade-enter"],
              enterActive: classes["slideFade-enter-active"],
              enterDone: classes["slideFade-enter-done"],
              exit: classes["slideFade-exit"],
              exitActive: classes["slideFade-exit-active"],
              exitDone: classes["slideFade-exit-done"],
            }}
            unmountOnExit
            // mountOnEnter
          >
            <div className={moreSettingsIconsClass}>
              <Divider style={{ width: "100%", margin: "5px 0" }} />
              <Create fontSize="default" />
            </div>
          </CSSTransition>
        </Grid>
      </>
    );
  }
}
export default withStyles(styles)(VerticalEdit);
