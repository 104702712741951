import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, IconButton } from "@material-ui/core";
import EditRemove from "../Buttons/EditRemove";
import ConfirmDelete from "../Buttons/ConfirmDelete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import { CSSTransition } from "react-transition-group";
import Moeda from "../../utils/Moeda";
const styles = (theme) => ({
  editRemoveOff: {
    animationName: "$fadeOut",
    animationDuration: ".3s",
    animationTimingFunction: "ease-out",
  },
  editRemoveOn: {
    animationName: "$fadeIn",
    animationDuration: ".4s",
    animationTimingFunction: "ease-out",
  },
  buttonRotateOpen: {
    transform: "rotate(90deg)",
    transition: " 0.3s ",
    color: "#A9A9A9",
  },
  buttonRotateClose: {
    transform: "rotate(0 deg)",
    transition: " 0.3s ",
    color: "#A9A9A9",
  },
  cardContainer: {
    transition: "all 1s",
    position: "relative",
  },
  cardTitleColumn: {
    paddingLeft: "25px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  cardSettingsColumn: {
    padding: "15px 10px",
    flexWrap: "nowrap",
    flexGrow: 0,
  },
  productLabel: {
    lineHeight: 1.3,
    fontSize: ".95rem",
    color: "#4a4a4a",
  },
  productSubTitle: {
    fontFamily: "Segoe UI",
    whiteSpace: "nowrap",
    color: "#808080",
    fontSize: ".9rem",
  },
  price: {
    fontSize: ".9rem",
    transition: "all 1s ease-in-out",
  },

  // Edit and Remove Product CSS
  confirmDelete_on: {
    animationName: "$fadeIn",
    animationDuration: ".5s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  confirmDelete_off: {
    animationName: "$fadeOut",
    animationDuration: ".5s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  innerContainer_on: {
    animationName: "$slideLeft",
    animationDuration: ".28s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  innerContainer_off: {
    animationName: "$slideRight",
    animationDuration: ".18s",
    animationTimingFunction: "ease-out",
    animationFillMode: "forwards",
  },
  "@keyframes slideLeft": {
    from: { transform: "translate(100%, 0)" },
    to: { transform: "translate(0, 0)" },
  },
  "@keyframes slideRight": {
    from: { transform: "translate(0, 0)" },
    to: { transform: "translate(100%, 0)" },
  },
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  "@keyframes fadeOut": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },

  "fade-enter": {
    opacity: 0,
  },
  "fade-enter-active": {
    opacity: 1,
    transition: "opacity 300ms",
  },
  "fade-exit": {
    opacity: 1,
  },
  "fade-exit-active": {
    opacity: 0,
    transition: "opacity 300ms",
  },
});

/**
 * Componente que renderiza um card de um produto exibido na listagem de produtos.
 */
class Products extends React.Component {
  /**
   * @property {object} props.data Informações do produto.
   * @property {object} props.classes Informações do produto.
   * @property {function} props.handleToggleViewActions Funcão que altera a exibição dos botões de editar e remover
   * @property {function} props.handleToggleRemoveConfirm Funcão que altera a exibição da mensagem que confirma a exclusão de um produto
   * @property {fucntion} props.handleRemoveProduct Funcão que exclui um produto da lista
   * @property {boolean} props.showRemoveConfirm Boleano que define a exibição da mensagem que confirma a exclusão de um produto
   * @property {boolean} this.state.stateFadeOut Boleano que que define a exibilçao do preço do produto no card
   */
  constructor(props) {
    super(props);
    this.state = {
      showPrice: true,
    };
    this.handleToggleViewActions = this.handleToggleViewActions.bind(this);
    this.handleToggleRemoveConfirm = this.handleToggleRemoveConfirm.bind(this);
    this.handleRemoveProduct = this.handleRemoveProduct.bind(this);
    this.handleToggleShowPrice = this.handleToggleShowPrice.bind(this);
  }

  handleToggleViewActions() {
    this.props.handleToggleViewActions(
      this.props.data.hash,
      this.props.showEditRemove
    );
  }

  handleToggleRemoveConfirm() {
    this.props.handleToggleRemoveConfirm(
      this.props.data.hash,
      this.props.showRemoveConfirm
    );
  }

  handleRemoveProduct() {
    this.props.handleRemoveProduct(this.props.data.hash);
  }

  handleToggleShowPrice() {
    this.setState({ showPrice: !this.state.showPrice });
  }

  render() {
    let { classes } = this.props;

    let classButtonRotate = clsx({
      [classes.buttonRotateOpen]: this.props.showEditRemove,
      [classes.buttonRotateClose]: !this.props.showEditRemove,
    });

    return (
      <>
        <Grid
          container
          className={classes.cardContainer}
          alignItems="stretch"
          wrap="nowrap"
          justify="space-between"
        >
          <Grid item xs className={classes.cardTitleColumn}>
            <Typography className={classes.productLabel}>
              {this.props.data.nome}
              <br></br>
              <span className={classes.productSubTitle}>
                &#9679; {this.props.data.itens.length} itens
              </span>
            </Typography>
          </Grid>

          <Grid
            item
            xs
            className={classes.cardSettingsColumn}
            container
            alignItems="center"
            direction="row"
            justify="flex-end"
            wrap="nowrap"
          >
            <CSSTransition
              in={this.props.showEditRemove}
              timeout={{
                enter: 300,
                exit: 150,
              }}
              classNames={{
                enter: classes["fade-enter"],
                enterActive: classes["fade-enter-active"],
                exit: classes["fade-exit"],
                exitActive: classes["fade-exit-active"],
              }}
              unmountOnExit
              onEnter={this.handleToggleShowPrice}
              onExited={this.handleToggleShowPrice}
            >
              <Grid item>
                <Grid container justify="center">
                  <EditRemove
                    link={`/products/edit/${this.props.data.id}`}
                    onDeleteItem={this.handleToggleRemoveConfirm}
                  />
                </Grid>
              </Grid>
            </CSSTransition>
            {this.state.showPrice ? (
              <Grid item>
                <Typography className={classes.price} noWrap>
                  {Moeda.create(this.props.data.valor).format()}
                </Typography>
              </Grid>
            ) : null}

            <Grid item>
              <IconButton
                size="small"
                onClick={this.handleToggleViewActions}
                edge="end"
              >
                <MoreVertIcon className={classButtonRotate} />
              </IconButton>
            </Grid>
          </Grid>
          <CSSTransition
            in={this.props.showRemoveConfirm}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <ConfirmDelete
              cancelDelete={this.handleToggleRemoveConfirm}
              confirmDelete={this.handleRemoveProduct}
              confirmText="Deseja mesmo deletar esse produto?"
              classes={classes}
              transitionStatus={this.props.showRemoveConfirm}
            />
          </CSSTransition>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(Products);
