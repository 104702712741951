import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import AddEditRemoveDocuments from "../pages/Documents/index";
import AddDocuments from "../pages/Documents/WindowForAdd";
import EditDocuments from "../pages/Documents/WindowForEdit";
import Products from "../pages/Products/index";
import ProductView from "../pages/Products/ProductView";
import ListClients from "../components/Lists/List";
import GoalsReport from "../pages/GoalsReport/index";
import VoucherReport from "../pages/VoucherReport";
import DailyReport from "../pages/DailyReport/index";
import NewClient from "../pages/NewClient/index";
import ViewProfile from "../pages/ViewProfile/index";
import ViewClient from "../pages/ViewClient/index";
import Configuration from "../pages/Configuration/index";
import Expenses from "../pages/Expenses/index";
import Support from "../pages/Support/index";
import HelperContent from "../pages/Support/HelperContent";
import SortNeighborhoods from "../pages/SortNeighborhoods/index";
import SortClients from "../pages/SortClients/index";
import Schedulings from "../pages/Schedulings/index";
import CreateSchedulings from "../pages/Schedulings/WindowForAdd";
import EditSchedulings from "../pages/Schedulings/WindowForEdit";
import DailyLists from "../pages/DailyLists/index";
import Receivement from "../pages/Receivement/index";
import ReceivementReport from "../pages/ReceivementReport/index";
import Exchange from "../pages/Exchange/index";
import ExchangeReport from "../pages/ExchangeReport/index";
import Sale from "../pages/Sale/index";
import SaleReport from "../pages/SaleReport/index";
import OpenRoute from "../pages/OpenRoute/index";
import CloseRoute from "../pages/CloseRoute/index";

function AppRouter() {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/daily" } };
  const redirectStr = (from && from.pathname) || "/daily";

  return (
    <Switch>
      <Route exact path="/documents" component={AddEditRemoveDocuments} />
      <Route exact path="/documents/create" component={AddDocuments} />
      <Route exact path="/documents/edit/:hash" component={EditDocuments} />
      <Route exact path="/products" component={Products} />
      <Route
        exact
        path="/products/(create|edit)/:id?"
        component={ProductView}
      />
      <Route exact path="/listclients" component={ListClients} />
      <Route exact path="/report/goals" component={GoalsReport} />
      <Route exact path="/report/voucher" component={VoucherReport} />
      <Route exact path="/report/daily" component={DailyReport} />
      <Route exact path="/client/new" component={NewClient} />
      <Route exact path="/receivement/create/:hash" component={Receivement} />
      <Route
        exact
        path="/receivement/report/:hash/:receivements"
        component={ReceivementReport}
      />
      <Route exact path="/exchange/create/:hash" component={Exchange} />
      <Route
        exact
        path="/exchange/report/:hash/:exchangeHash"
        component={ExchangeReport}
      />
      <Route exact path="/sale/create/:hash" component={Sale} />
      <Route exact path="/sale/report/:hash/:sale" component={SaleReport} />
      <Route exact path="/profile" component={ViewProfile} />
      <Route exact path="/client/view/:type/:hash" component={ViewClient} />
      <Route exact path="/configuration" component={Configuration} />
      <Route exact path="/expenses" component={Expenses} />
      <Route exact path="/support" component={Support} />
      <Route exact path="/support/:pageName" component={HelperContent} />
      <Route exact path="/sort/neighborhoods" component={SortNeighborhoods} />
      <Route exact path="/sort/clients" component={SortClients} />
      <Route exact path="/schedulings" component={Schedulings} />
      <Route
        exact
        path="/schedulings/create/:hash"
        component={CreateSchedulings}
      />
      <Route
        exact
        path="/schedulings/edit/:clientHash/:hash"
        component={EditSchedulings}
      />
      <Route exact path="/route/lists" component={DailyLists} />
      <Route exact path="/route/open" component={OpenRoute} />
      <Route exact path="/route/close" component={CloseRoute} />
      <Route exact path="/:listType" component={DailyLists} />
      <Redirect to={redirectStr} />
    </Switch>
  );
}

export default AppRouter;
