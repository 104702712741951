/* eslint-disable no-useless-escape */
/* eslint-disable no-useless-constructor */
/* eslint-disable eqeqeq */
import * as moment from "moment";
import * as R from "ramda";
import AppStorageError from "../../errorDefinition/AppStorageError";
import {
  Bairro,
  Cliente,
  ClienteAgendamento,
  ClienteDiaria,
  ClienteTelefone,
} from "../../repository/models";
import Moeda from "../../utils/Moeda";
import LocalStorage from "../../utils/localStorage";
import RouteOpening, { team } from "../route/index";
const modelBairro = new Bairro();
const modelCliente = new Cliente();
const clienteDiaria = new ClienteDiaria();
const clienteTelefone = new ClienteTelefone();
const routeOpeningService = new RouteOpening();
const modelClienteAgendamento = new ClienteAgendamento();
/**
 *@description Recupera clientes baseado nos paramentros recebidos e retorna somente os dados necessarios para a criação do cache de diaria
 *@param {String} listaDiariaInicio String no formato "DD/MM/YYYY" que representa o inicio do intervalo desejado (inclusivo)
 *@param {String}listaDiariaFinal String no formato "DD/MM/YYYY" que representa o final do intervalo (inclusivo)
 *
 */
export async function getClientsOfOpening(
  listaDiariaInicio,
  listaDiariaFinal,
  separar
) {
  const schedulings = await modelClienteAgendamento.getScheduleByInteval(
    "01/01/1999", //forçar pegar todos os agendamentos para tras
    listaDiariaFinal
  );
  const currentTeam = await team();
  let clients = await modelCliente.getAll(
    "id",
    R.uniq(schedulings.map((e) => e.ClienteId))
  );
  const clientesUnicos = R.uniqBy(R.prop("id"), clients);
  clients = clientesUnicos;
  if (separar) {
    clients = clients.filter((client) => client.EquipeId == currentTeam.id);
  }

  const shouldCacheBairroId = clients.some((c) => !c.bairroIdCache);
  if (shouldCacheBairroId) {
    await Promise.all(
      clients.map(async (c) => {
        c.bairroIdCache = (await modelBairro.getClientNeighborhood(c.id)).id;
        return c;
      })
    );
  }

  const phones = await clienteTelefone.getAll(
    "ClienteId",
    clients.map((c) => c.id)
  );

  return clients.map((c) => {
    c.schedules = schedulings
      .filter((schedule) => schedule.ClienteId == c.id)
      .map((schedule) => ({
        hash: schedule.hash,
        id: schedule.id,
        type: schedule.tipo,
        createdAt: schedule.createdAt,
      }));
    c.telefones = phones.filter(
      (phone) => phone.ClienteId == c.id && phone.ativo
    );
    return c;
  });
}

export async function generateServerDailyMap(orderedClients) {
  const routeOpening = await routeOpeningService.routeOpening();
  const forceRecalculate = false;
  if (!orderedClients)
    orderedClients = (await getAllDailyMap(routeOpening, forceRecalculate))
      .orderedClients;

  const reduceToObject = (memo, client, index) => {
    if (!client) return memo;

    if (!memo[client.BairroId]) memo[client.BairroId] = {};
    memo[client.BairroId][client.id] = index + 1;
    return memo;
  };
  const dailyMapServerObj = orderedClients.reduce(reduceToObject, {});
  return dailyMapServerObj;
}

/**
 *@description Recupera ou cria a lista diaria caso não esteja cacheada.
 *@param {Object} routeOpening Contem as informações de data de abertura e fechamento da diaria para que seja montada a listagem
 *
 */
export async function getAllDailyMap(
  routeOpening,
  forceRecalculate = true,
  shouldSaveDailyMap = false,
  isNeighborhoodOrder = false
) {
  if (routeOpening.palma) {
    return {
      orderedClients: [],
      neighborhoodCount: {},
      total: 0,
    };
  }
  const openingCacheInfo = await LocalStorage.instance.getItem(
    "daily-list-metadata"
  );
  const isSameOpening = !!openingCacheInfo
    ? routeOpening.listaDiariaInicio == openingCacheInfo.listaDiariaInicio &&
      routeOpening.listaDiariaFinal == openingCacheInfo.listaDiariaFinal
    : false;

  if (!isSameOpening || forceRecalculate) {
    return await calculateDailyMap(
      routeOpening,
      shouldSaveDailyMap,
      isNeighborhoodOrder
    );
  }

  const orderedClients = await clienteDiaria.orderBy({
    by: "position",
    direction: "ASC",
  });

  if (!orderedClients.length) {
    return await calculateDailyMap(
      routeOpening,
      shouldSaveDailyMap,
      isNeighborhoodOrder
    );
  }

  const total = orderedClients.length;
  const neighborhoodCount = orderedClients.reduce((memo, clientInfo) => {
    if (!memo[clientInfo.BairroId]) memo[clientInfo.BairroId] = 0;
    if (clientInfo.isRepass) return memo;

    memo[clientInfo.BairroId]++;
    return memo;
  }, {});
  return {
    orderedClients,
    neighborhoodCount,
    total,
  };
}

/**
 *@description Calcula a lista diaria
 *@param {Object} routeOpening Dados de abertura
 *@param {String} routeOpening.listaDiariaInicio Respeita o seguinte formato DD/MM/YYYY
 *@param {String} routeOpening.listaDiariaFinal Respeita o seguinte formato DD/MM/YYYY
 */
export async function calculateDailyMap(
  routeOpening,
  shouldSaveDailyMap = false,
  isNeighborhoodOrder = false
) {
  const { listaDiariaInicio, listaDiariaFinal, separar } = routeOpening;
  const clientOrderInsideNeighbor = await LocalStorage.instance.getItem(
    "dailyMap"
  );

  const [clients, neighborhoods] = await Promise.all([
    getClientsOfOpening(listaDiariaInicio, listaDiariaFinal, separar),
    modelBairro.getOrderedNeighborhoodByIndex(),
  ]);
  let orderedClients = [];
  const neighborhoodCount = {};

  for (const neighborhood of neighborhoods) {
    const clientsOfNeighborhood = clients.filter(
      (c) => c.bairroIdCache == neighborhood.id
    );
    const repass = clientsOfNeighborhood.filter((c) => c.isRepass);

    if (clientsOfNeighborhood.length) {
      neighborhoodCount[neighborhood.id] =
        clientsOfNeighborhood.length - repass.length;
    }
    orderedClients = orderedClients.concat(
      clientsOfNeighborhood.map((c) => {
        c.bairro = neighborhood.name;
        c.estado = neighborhood.state;
        return buildClientData(c);
      })
    );

    if (isNeighborhoodOrder && clientOrderInsideNeighbor) {
      const neighborhoodOrder = clientOrderInsideNeighbor[neighborhood.id];
      if (neighborhoodOrder) {
        const getIndex = (client) => neighborhoodOrder[client.id] || -9999;
        orderedClients.sort((a, b) => getIndex(a) - getIndex(b));
      }
    }
  }
  if (shouldSaveDailyMap) await storeDailyMap(orderedClients);

  const oldDailyList = await clienteDiaria.getAll();
  orderedClients = await addClientsToRepassList(orderedClients, oldDailyList);
  orderedClients = await orderByDailyMap(orderedClients, neighborhoods);

  await clienteDiaria.removeAll();
  await clienteDiaria.bulkCreate(orderedClients);
  try {
    await LocalStorage.instance.setItem("daily-list-metadata", {
      listaDiariaInicio,
      listaDiariaFinal,
    });
  } catch (error) {
    new AppStorageError({
      method: "service.daily.calculateDailyMap",
      error,
    });
  }
  return {
    total: orderedClients.length,
    neighborhoodCount,
    orderedClients,
  };
}

function addClientsToRepassList(clients, oldClientesDiaria) {
  if (!clients.length || !oldClientesDiaria) return clients;
  if (!oldClientesDiaria || !oldClientesDiaria.length) return clients;
  const repassClients = oldClientesDiaria.filter((el) => el.isRepass);

  clients.forEach((newClient) => {
    const oldClient = repassClients.find((el) => el.id === newClient.id);
    if (!!oldClient) newClient.isRepass = true;
  });

  return clients;
}

export async function removeClientFromDailyList(clientHash) {
  const client = await clienteDiaria.getAll("hash", [clientHash]);
  if (!client.length) return;
  await clienteDiaria.delete(client[0].DATABASE_ID);
}

export async function updateClientDataOnDailyList(clientData) {
  const client = await clienteDiaria.getAll("hash", [clientData.hash]);
  if (client.length) {
    clientData.DATABASE_ID = client[0].DATABASE_ID;
    await clienteDiaria.put(clientData, false);
  } else {
    await clienteDiaria.create(clientData, false);
  }
}

export async function calculateOneClientDailyList(clientHash) {
  const routeOpening = await routeOpeningService.routeOpening();

  const endDaily = moment(routeOpening.listaDiariaFinal, "DD/MM/YYYY")
    .endOf("day")
    .toDate();
  let client = await modelCliente.getAll("hash", [clientHash]);

  // Checks if the customer was found before accessing its id property
  if (client && client.length > 0) {
    client = client[0];
    const neighborhood = await modelBairro.getClientNeighborhood(client.id);
    let clientSchedules = await modelClienteAgendamento.getAll("ClienteId", [
      client.id,
    ]);

    clientSchedules = clientSchedules.filter(
      (schedule) =>
        schedule &&
        schedule.ativo &&
        moment(schedule.dataRecebimento).isSameOrBefore(endDaily)
    );

    if (!clientSchedules.length) {
      return {
        shouldRemoveFromDailyList: true,
        clientId: client.id,
        clientHash: client.hash,
      };
    }
    const telephones = await clienteTelefone.getAll("ClienteId", [client.id]);
    const clientsOfNeighborhood = await clienteDiaria.getAll("BairroId", [
      neighborhood.id,
    ]);

    let positionToIsert = 9999999;

    const lastIndex = clientsOfNeighborhood.length - 1;
    if (lastIndex > -1)
      positionToIsert = clientsOfNeighborhood[lastIndex].position;
    if (lastIndex <= -1) positionToIsert = 0;

    client.bairro = neighborhood.nome;
    client.bairroIdCache = neighborhood.id;
    client.schedules = clientSchedules.map((schedule) => ({
      hash: schedule.hash,
      id: schedule.id !== undefined ? schedule.id : null,
      type: schedule.tipo,
      createdAt: schedule.createdAt,
    }));
    client.telefones = telephones;

    const oldClientData = await clienteDiaria.getAll("hash", [client.hash]);
    client.isRepass = oldClientData[0] ? oldClientData[0].isRepass : false;
    const clientData = buildClientData(client, positionToIsert);
    return clientData;
  } else {
    // Handle the case that the customer was not found
    console.log("Cliente não encontrado.");
    return {
      shouldRemoveFromDailyList: false,
      clientId: null,
      clientHash,
    };
  }
}

export async function storeDailyMap(dailyList) {
  const dailyMap = await generateServerDailyMap(dailyList);
  await LocalStorage.instance.setItem("dailyMap", dailyMap);
}

function orderNeighborhoodByDailyMap(dailyMap = {}, neighborhoods = []) {
  const unsortedNeighborhoods = Object.keys(dailyMap).map((neighborId) => {
    const clientId = Object.keys(dailyMap[neighborId])[0];
    return {
      position: parseInt(dailyMap[neighborId][clientId]),
      id: parseInt(neighborId),
    };
  });
  let neighborhoodsSorted = unsortedNeighborhoods.sort(
    (a, b) => a.position - b.position
  );
  neighborhoodsSorted = neighborhoodsSorted
    .map((el) => {
      return neighborhoods.find((neighbor) => neighbor.id == el.id);
    })
    .filter((el) => !!el);

  const neighborhoodsOutOfDailyMap = neighborhoods.filter(
    (el) => !neighborhoodsSorted.some((el2) => el2.id === el.id)
  );

  const orderedNeighborhoods = neighborhoodsSorted.concat(
    neighborhoodsOutOfDailyMap
  );
  return orderedNeighborhoods;
}

/**
 * @param {Array} dailyList
 */
export async function orderByDailyMap(dailyList, neighborhoods) {
  const dailyMap = await LocalStorage.instance.getItem("dailyMap");
  if (!dailyMap) return dailyList;
  let sortedClients = [];
  let absolutePosition = 1;
  const orderedNeighborhoods = orderNeighborhoodByDailyMap(
    dailyMap,
    neighborhoods
  );

  for (const neighbor of orderedNeighborhoods) {
    const BairroId = neighbor.id;
    const clientsOfneighbor = dailyList.filter((el) => el.BairroId == BairroId);
    clientsOfneighbor.forEach((el) => {
      if (!dailyMap[BairroId]) {
        el.position = 999999;
        return;
      }
      el.position = dailyMap[BairroId][el.id] || 999999;
    });

    const sortedClientsOfNeighbor = clientsOfneighbor.sort(
      (a, b) => parseInt(a.position) - parseInt(b.position)
    );

    for (const client of sortedClientsOfNeighbor) {
      client.position = absolutePosition;
      absolutePosition++;
    }
    sortedClients = sortedClients.concat(sortedClientsOfNeighbor);
  }

  return sortedClients;
}

/**
 *@description Função normalizadora dos dados necessarios para criação da lista diaria
 *@param {Object} client Dados de cliente
 *@param {String || Number} position Valor Numérico que representa a posição do cliente de forma absoluta na lista
 *
 */
export function buildClientData(client) {
  return {
    id: client.id,
    cep: client.cep,
    cpf: client.cpf,
    nome: client.nome,
    lowerCaseNome: client.lowerCaseNome,
    hash: client.hash,
    veaco: client.veaco,
    estado: client.estado,
    numero: client.numero,
    cidade: client.cidade,
    bairro: client.bairro,
    bairroIdCache: client.bairroIdCache,
    logradouro: client.logradouro,
    saldoAtual: client.saldoAtual,
    BairroId: client.bairroIdCache,
    telefones: client.telefones || [],
    isRepass: client.isRepass || false,
    dailySchedules: client.schedules || [],
    referencePoint: client.pontoReferencia,
    saldo: parseFloat(Moeda.create(client.saldoAtual).mount()),
  };
}
