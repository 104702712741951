import DataAccess from "../DataAccessStrategy";

export default class ClienteTelefone extends DataAccess {
  constructor() {
    super("ClienteTelefone", {
      id: Number,
      shouldSync: Boolean,
      updatedDate: Date,
      numero: String,
      ClienteId: Number,
    });
  }
}
