import DataAccess from "../DataAccessStrategy";

export default class ClienteDocumento extends DataAccess {
  constructor() {
    super("ClienteDocumento", {
      id: Number,
      dataCriacao: Date,
      foto: String,
      ativo: Boolean,
      hash: String,
      hashRotaAbertura: String,
      ClienteId: Number,
      shouldUploadFoto: Boolean,
      DocumentoId: Number,
      shouldSync: Boolean,
    });
  }
}
