import React from "react";
import { withStyles, Divider } from "@material-ui/core";
import Header from "../../components/HeaderForAdd/Header";
import InfoDayliReport from "../../components/Report/InfoDayliReport";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const styles = (theme) => ({});

/**
 * Que exibe e alterna os relatórios finais e de trocas.
 */
class DayliReport extends React.Component {
  /**
   * @property {boolean} this.state.exchange Boleano que altera a exibição do relatório de trocas.
   * @param {object} props.classes Boleano que definirá se o título da barra superior será a logo da aplicação
   */
  constructor() {
    super();
    this.state = {
      exchange: false,
    };
    this.handleToggleExchange = this.handleToggleExchange.bind(this);
  }

  /**Função que altera o estado de exibição do relatório de trocas.
   * @function
   */
  handleToggleExchange() {
    this.setState({ exchange: !this.state.exchange });
  }

  render() {
    return (
      <>
        {!this.state.exchange ? (
          <>
            <Header link="/report/goals" icon={<CloseIcon />}>
              Movimentação
            </Header>
            <Divider style={{ top: "-3px", position: "relative" }} />
            <InfoDayliReport
              exchange={false}
              toggleExchange={this.handleToggleExchange}
            />
          </>
        ) : (
          <>
            <Header
              link="/report/daily"
              icon={<ArrowBackIcon />}
              func={this.handleToggleExchange}
            >
              Trocas realizadas
            </Header>
            <InfoDayliReport exchange={true} />
          </>
        )}
      </>
    );
  }
}
export default withStyles(styles)(DayliReport);
