import { Component } from "react";
import SyncNotificationCenter from "../service/notifications/syncNotificationCenter";
import UnknownError from "../errorDefinition/UnknownError";
import LocalStorage from "../utils/localStorage";
import { ROUTE_OPENING } from "../service/route";
import BusinessError from "../errorDefinition/BusinessError";
import { getUser } from "../service/authentication";
import { WorkerDownloadClients } from "../utils/workerDownloadClients";

/**
 * @description Classe utilitária para fornecer
 * metodos convenientes para sincronização
 * entre os contextos e o banco de dados
 * do app
 *
 * @param {Object} props Propriedades padrão dos componentes de react
 * @param {Array} allowedModels Conjunto de strings para manipulação dos
 * estados internos no app
 *
 * @example
 * const OBSERVED_MODELS = ['Cidade', 'Cliente', 'Bairro'];
 * class YourClassName extends AbstractContext {
 *  constructor(props){
 *    super(props, ALLOWED_MODELS)
 *    //your code
 *  }
 * }
 */
export class AbstractContext extends Component {
  constructor(props, observedModels, localStorageKeys = []) {
    super(props);
    this.isRouteOpen = this.isRouteOpen.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.componentDidCatch = this.componentDidCatch.bind(this);
    this.shouldCallObservable = this.shouldCallObservable.bind(this);
    this.observedModels = observedModels;
    this.localStorageKeys = localStorageKeys;
  }

  componentDidMount() {
    this.unsubscribe = SyncNotificationCenter.instance.subscribe(
      this.shouldCallObservable
    );
    this.unsubscribeLocalStorage = LocalStorage.subscribe(
      this.localStorageKeys,
      this.observable
    );
  }
  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
    this.unsubscribeLocalStorage && this.unsubscribeLocalStorage();
  }

  componentDidCatch(error) {
    new UnknownError({
      type: "error",
      message: error.message,
      title: "Um erro inexperado ocorreu",
      error: error,
    });
  }

  async isRouteOpen(shouldThrowError = true) {
    const routeOpening = await LocalStorage.instance.getItem(ROUTE_OPENING);

    if (shouldThrowError && !routeOpening) {
      throw new BusinessError({
        message: "Não é possivel realizar esta operação com a rota fechada.",
        title: "Operação não permitida!",
        type: "error",
        method: "isRouteOpen",
      });
    }
    return !!routeOpening;
  }

  shouldCallObservable(eventName, changedModels) {
    if (eventName !== "activeSync") return;
    const isObserved = changedModels.some((modelName) =>
      this.observedModels.some(
        (observedModelName) => modelName == observedModelName
      )
    );

    if (isObserved) this.observable(eventName, changedModels);
  }

  observable(eventName, changedModels) {
    throw new Error(
      "Você deve implementar este médoto na classe que extende AbstractContext"
    );
  }

  async clientBackgroundSync() {
    try {
      const localConfig = await LocalStorage.instance.getItem("local-config");

      // Verificar se o aplicativo está configurado para trabalhar online
      if (localConfig && localConfig.OnlineOffline && navigator.onLine) {
        const channel = new MessageChannel();
        const dbVersion = parseFloat(window.DATABASE.verno) * 10;
        const user = await getUser();
        const route = await window.LocalStorage.getItem("main-route");

        const options = {
          dbVersion,
          routeId: route.id,
          baseUrl: user.service,
          token: user.sessao.token,
        };

        channel.port1.onmessage = (event) => {
          if (["success", "error"].includes(event.data.status)) {
            SyncNotificationCenter.instance.notifyAll("activeSync", [
              "Cliente",
            ]);
          }
          SyncNotificationCenter.instance.notifyAll(
            "client-background-sync",
            event.data
          );
        };

        WorkerDownloadClients(options, channel.port2);
      } else {
        // Aplicativo está offline, não inicie a sincronização.
        console.log(
          "O aplicativo está offline. A sincronização não será iniciada."
        );
      }
    } catch (error) {
      console.error(error);
      // Não lança erro para quem o chama, somente exibe na tela que houve problemas
    }
  }

  async notifyModelChange(eventName, changedModel) {
    await SyncNotificationCenter.instance.notifyAll(eventName, [changedModel]);
  }
}
