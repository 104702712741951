import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import { Paper } from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import SwapVertIcon from "@material-ui/icons/SwapVert";

const styles = (theme) => ({
  refCardClass: {
    background: "#dcecff",
    color: "#000",
  },
  cardWrapper: {
    padding: "10px 0px 10px 15px",
    borderLeft: "4px solid",
    borderColor: "#4e76e3",
  },
  container: {
    boxShadow: "1px 1px 4px 0px rgba(163,150,163,1)",
    padding: "10px 0px 10px 15px",
    position: "relative",
  },
  text: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "1rem",
    "white-space": "nowrap",
    overflow: "hidden",
    "text-overflow": "ellipsis",
  },
  status: {
    height: "100%",
    width: "5px",
    position: "absolute",
    left: 0,
  },
  avatar: {
    width: "50px",
    height: "50px",
  },
  address: {
    fontSize: ".8rem",
    color: theme.palette.text.secondary,
  },
  reference: {
    fontSize: ".8rem",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  },

  selectedCard: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: "4px",
  },

  modifiedCard: {
    borderColor: "#32CD32",
    background: "#00ff0054",
  },
  radioButtonClass: {
    color: theme.palette.primary.main,
  },
});
/**
 * Card utilizado na ordenação de bairros e clientes, onde muda totalmente sua forma dependendo da prop logradouro.
 * O componente utiliza este meio de CSS acima.
 */
class CardNeighborhood extends React.Component {
  /**
   * @param {string} props.index Índice do card na Array
   * @param {object} props.data Prop onde se tem os dados do bairro ou cliente exibidos no card
   * @param {number} props.selected Prop onde é se tem a id do card selecionado no momento
   * @param {boolean} props.modified Prop onde é informado se o card foi modificado
   * @param {function} props.onClickSecondSelected Função que ao ativada seta o segundo card selecionado(usado no 'inserir depois de')
   * @param {object} props.classes Objeto com todas as classes do CSS
   * @property {State} this.state Estado onde será armazenado a data
   */

  constructor(props) {
    super(props);
    this.handleSelectSecondItem = this.handleSelectSecondItem.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
  }

  handleSelectSecondItem() {
    this.props.onCardClick(this.props.index);
  }

  handleSelectItem() {
    this.props.onCardClick(this.props.index);
  }

  getAdressText() {
    const { logradouro, numero, bairro, cidade, estado } = this.props.data;
    return [logradouro, numero, bairro, cidade, estado]
      .filter((el) => !!el)
      .join(", ");
  }

  render() {
    const { classes } = this.props;
    const paperClass = clsx({
      [classes.refCardClass]: this.props.refCard,
    });
    const cardClass = clsx({
      [classes.cardWrapper]: true,
      [classes.modifiedCard]: this.props.modified,
      [classes.selectedCard]: this.props.selected,
    });
    return (
      <Paper
        className={paperClass}
        elevation={this.props.selected ? 7 : 2}
        style={{ width: "100%", position: "relative" }}
      >
        <Grid className={cardClass} item xs={12} container alignItems="center">
          <Grid item xs={2}>
            <Avatar
              className={classes.avatar}
              src={
                typeof this.props.data.foto == "string"
                  ? this.props.data.foto
                  : null
              }
            ></Avatar>
          </Grid>
          <Grid item xs={7}>
            <Typography className={classes.text}>
              {this.props.data.nome}
            </Typography>
            <Typography className={classes.reference}>
              {this.props.data.referencePoint}
            </Typography>
            <Typography className={classes.address}>
              {this.getAdressText()}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Grid container alignItems="center" justify="center">
              {this.props.enableSecondClick ? (
                this.props.selected ? (
                  <SwapVertIcon
                    className={classes.radioButtonClass}
                    fontSize="large"
                    onClick={this.handleSelectItem}
                  />
                ) : (
                  <RadioButtonUncheckedIcon
                    className={classes.radioButtonClass}
                    fontSize="default"
                    onClick={this.handleSelectItem}
                  />
                )
              ) : (
                <MenuIcon
                  htmlColor="#696969"
                  fontSize="default"
                  onClick={this.handleSelectItem}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
export default withStyles(styles)(CardNeighborhood);
